/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Attachment:{
		ProfilePictureFor:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Attachment_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Attachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Attachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_bool_exp:{
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		contentType:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_max_order_by:{
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_min_order_by:{
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_order_by:{
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor_aggregate:{
			type:"Person_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_select_column: "enum",
	Attachment_stream_cursor_input:{
		initial_value:{
			type:"Attachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_stream_cursor_value_input:{
		contentType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResultSource_enum: "enum",
	AvailabilityResult_bool_exp:{
		_and:{
			type:"AvailabilityResult_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AvailabilityResult_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AvailabilityResult_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_insert_input:{
		creationSource:{
			type:"CreationSourceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_select_column: "enum",
	AvailabilityResult_stream_cursor_input:{
		initial_value:{
			type:"AvailabilityResult_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Availability_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Availability_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Availability_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Availability_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Availability_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		beginsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"geometry_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_max_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_min_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_select_column: "enum",
	Availability_stream_cursor_input:{
		initial_value:{
			type:"Availability_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_stream_cursor_value_input:{
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Boolean_comparison_exp:{
		_eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ContentBlock_bool_exp:{
		_and:{
			type:"ContentBlock_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ContentBlock_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ContentBlock_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		body:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_select_column: "enum",
	ContentBlock_stream_cursor_input:{
		initial_value:{
			type:"ContentBlock_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_stream_cursor_value_input:{
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CreationSourceType_enum: "enum",
	GeographicRegion:{
		GeographicRegionHandymen:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhoods:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GeographicRegionHandyman_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionHandyman_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionHandyman_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_select_column: "enum",
	GeographicRegionHandyman_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionHandyman_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionNeighborhood_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionNeighborhood_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_select_column: "enum",
	GeographicRegionNeighborhood_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionNeighborhood_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_bool_exp:{
		GeographicRegionHandymen:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		availableStartingAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String_array_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_by_zipCode_args:{
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_order_by:{
		GeographicRegionHandymen_aggregate:{
			type:"GeographicRegionHandyman_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods_aggregate:{
			type:"GeographicRegionNeighborhood_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_select_column: "enum",
	GeographicRegion_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegion_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_stream_cursor_value_input:{
		availableStartingAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Int_comparison_exp:{
		_eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	MarketingLead_bool_exp:{
		_and:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_constraint: "enum",
	MarketingLead_insert_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentBeginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentEndsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeAccountCreated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organicSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumberVerified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmCampaign:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmMedium:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmSourcePlatform:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmTerm:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_on_conflict:{
		constraint:{
			type:"MarketingLead_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"MarketingLead_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MarketingLead_select_column: "enum",
	MarketingLead_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentBeginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentEndsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeAccountCreated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organicSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumberVerified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmCampaign:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmMedium:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmSourcePlatform:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utmTerm:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_input:{
		initial_value:{
			type:"MarketingLead_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_update_column: "enum",
	MarketingLead_updates:{
		_set:{
			type:"MarketingLead_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Person:{
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedAttachments:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Person_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Person_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Person_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_bool_exp:{
		Availability:{
			type:"Availability_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAttachments:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		firstName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_max_order_by:{
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_min_order_by:{
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_order_by:{
		Availability_aggregate:{
			type:"Availability_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAttachments_aggregate:{
			type:"Attachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_select_column: "enum",
	Person_stream_cursor_input:{
		initial_value:{
			type:"Person_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_stream_cursor_value_input:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_bool_exp:{
		_and:{
			type:"ReferralProgram_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ReferralProgram_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ReferralProgram_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		creditAmount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_order_by:{
		creditAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_select_column: "enum",
	ReferralProgram_stream_cursor_input:{
		initial_value:{
			type:"ReferralProgram_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_stream_cursor_value_input:{
		creditAmount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	String_array_comparison_exp:{
		_contained_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_contains:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_eq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_lte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_neq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	cursor_ordering: "enum",
	find_Person_by_phone_args:{
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_bool_exp:{
		_and:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"find_Person_response_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		present:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_order_by:{
		present:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_select_column: "enum",
	find_Person_response_stream_cursor_input:{
		initial_value:{
			type:"find_Person_response_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_stream_cursor_value_input:{
		present:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geography: "String",
	geography_cast_exp:{
		geometry:{
			type:"geometry_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geography_comparison_exp:{
		_cast:{
			type:"geography_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"geography",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"geography",
			array:true,
			arrayRequired:false,
			required:true
		},
		_st_d_within:{
			type:"st_d_within_geography_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_intersects:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geometry: "String",
	geometry_cast_exp:{
		geography:{
			type:"geography_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geometry_comparison_exp:{
		_cast:{
			type:"geometry_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"geometry",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"geometry",
			array:true,
			arrayRequired:false,
			required:true
		},
		_st_3d_d_within:{
			type:"st_d_within_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_3d_intersects:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_contains:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_crosses:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_d_within:{
			type:"st_d_within_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_equals:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_intersects:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_overlaps:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_touches:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_within:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	jsonb: "String",
	mutation_root:{
		insert_AvailabilityResult:{
			objects:{
				type:"AvailabilityResult_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_AvailabilityResult_one:{
			object:{
				type:"AvailabilityResult_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_MarketingLead:{
			objects:{
				type:"MarketingLead_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"MarketingLead_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_MarketingLead_one:{
			object:{
				type:"MarketingLead_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"MarketingLead_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		update_MarketingLead:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_by_pk:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"MarketingLead_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_many:{
			updates:{
				type:"MarketingLead_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		}
	},
	order_by: "enum",
	query_root:{
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult:{
			distinct_on:{
				type:"AvailabilityResult_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailabilityResult_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock:{
			distinct_on:{
				type:"ContentBlock_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ContentBlock_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock_by_pk:{
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReferralProgram:{
			distinct_on:{
				type:"ReferralProgram_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ReferralProgram_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	st_d_within_geography_input:{
		distance:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		from:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:true
		},
		use_spheroid:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	st_d_within_input:{
		distance:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		from:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	subscription_root:{
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Attachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Attachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult:{
			distinct_on:{
				type:"AvailabilityResult_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailabilityResult_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AvailabilityResult_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Availability_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Availability_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock:{
			distinct_on:{
				type:"ContentBlock_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ContentBlock_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock_by_pk:{
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ContentBlock_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ContentBlock_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionHandyman_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionHandyman_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionNeighborhood_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegion_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MarketingLead_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"MarketingLead_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Person_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram:{
			distinct_on:{
				type:"ReferralProgram_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ReferralProgram_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ReferralProgram_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"find_Person_response_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	Attachment:{
		CreatedBy:"Person",
		ProfilePictureFor:"Person",
		contentType:"String",
		id:"uuid",
		publicUrl:"String"
	},
	Availability:{
		Person:"Person",
		beginsAt:"timestamptz",
		endsAt:"timestamptz",
		personId:"uuid",
		serviceArea:"geometry"
	},
	AvailabilityResult:{
		id:"uuid"
	},
	AvailabilityResult_mutation_response:{
		affected_rows:"Int",
		returning:"AvailabilityResult"
	},
	ContentBlock:{
		body:"String",
		createdAt:"timestamptz",
		key:"String",
		updatedAt:"timestamptz"
	},
	GeographicRegion:{
		GeographicRegionHandymen:"GeographicRegionHandyman",
		GeographicRegionNeighborhoods:"GeographicRegionNeighborhood",
		availableStartingAt:"timestamptz",
		id:"uuid",
		name:"String",
		servicedZipCodes:"String"
	},
	GeographicRegionHandyman:{
		GeographicRegion:"GeographicRegion",
		Handyman:"Person",
		id:"uuid"
	},
	GeographicRegionNeighborhood:{
		GeographicRegion:"GeographicRegion",
		id:"uuid",
		name:"String",
		zipCode:"String"
	},
	MarketingLead:{
		id:"uuid"
	},
	MarketingLead_mutation_response:{
		affected_rows:"Int",
		returning:"MarketingLead"
	},
	Person:{
		Availability:"Availability",
		CreatedAttachments:"Attachment",
		ProfilePicture:"Attachment",
		firstName:"String",
		id:"uuid"
	},
	ReferralProgram:{
		creditAmount:"Int"
	},
	find_Person_response:{
		present:"Boolean"
	},
	mutation_root:{
		insert_AvailabilityResult:"AvailabilityResult_mutation_response",
		insert_AvailabilityResult_one:"AvailabilityResult",
		insert_MarketingLead:"MarketingLead_mutation_response",
		insert_MarketingLead_one:"MarketingLead",
		update_MarketingLead:"MarketingLead_mutation_response",
		update_MarketingLead_by_pk:"MarketingLead",
		update_MarketingLead_many:"MarketingLead_mutation_response"
	},
	query_root:{
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		Availability:"Availability",
		AvailabilityResult:"AvailabilityResult",
		ContentBlock:"ContentBlock",
		ContentBlock_by_pk:"ContentBlock",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		Person:"Person",
		Person_by_pk:"Person",
		ReferralProgram:"ReferralProgram",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response"
	},
	subscription_root:{
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		Attachment_stream:"Attachment",
		Availability:"Availability",
		AvailabilityResult:"AvailabilityResult",
		AvailabilityResult_stream:"AvailabilityResult",
		Availability_stream:"Availability",
		ContentBlock:"ContentBlock",
		ContentBlock_by_pk:"ContentBlock",
		ContentBlock_stream:"ContentBlock",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionHandyman_stream:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_stream:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		GeographicRegion_stream:"GeographicRegion",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		MarketingLead_stream:"MarketingLead",
		Person:"Person",
		Person_by_pk:"Person",
		Person_stream:"Person",
		ReferralProgram:"ReferralProgram",
		ReferralProgram_stream:"ReferralProgram",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response",
		find_Person_response_stream:"find_Person_response"
	}
}