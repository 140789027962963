import { getRegions } from "../helpers/Utils";
import { abstractView } from "./AbstractView";

interface Region {
  regionId: string;
  neighborhoods: string;
}

export function neighborhoodView() {
  let regions: Region[] = [];
  const data = getRegions();
  if (data) {
    regions = data
      .map((region) => {
        return {
          regionId: region.id,
          neighborhoods: region.neighborhoods[0]
            ? region.neighborhoods[0].name
            : region.name,
        };
      })
      .sort((a, b) =>
        a.neighborhoods < b.neighborhoods
          ? -1
          : a.neighborhoods > b.neighborhoods
            ? 1
            : 0,
      );
  }

  const view = abstractView("NeighborhoodView", {
    regions: regions,
  });
  view.setTitle("Honey Homes - Find Your Neighborhood");
  return view;
}
