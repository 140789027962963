import {
  getAvailableSlots,
  displayOverlappingApptAlert,
} from "../helpers/Utils";
import { abstractView } from "./AbstractView";
import { DateTime } from "luxon";

export function scheduleAppointmentView() {
  const view = abstractView("ScheduleAppointmentView", {
    pages: processAppointments(),
  });
  view.setTitle("Honey Homes - Book Home Walk-Thru");
  view.onload = () => {
    const qs = new URLSearchParams(window.location.search);
    const overlappingApptError = qs.get("error") === "overlapping-appointment";
    if (overlappingApptError) {
      displayOverlappingApptAlert();
    }
    setUpCarouselListeners();
  };
  return view;
}

interface Page {
  days: Day[];
  class: string;
}

interface Day {
  heading: string;
  day: string;
  appointments: Appointment[];
}

interface Appointment {
  start: string;
  end: string;
  displayValue: string;
}

/*
Turns the array of appointments into a format that the template will
use to build the UI
*/
function processAppointments(): Page[] {
  const pages: Page[] = [];
  const daysPerPage = window.innerWidth <= 768 ? 2 : 3;
  const slots = getAvailableSlots();

  if (!slots) {
    return [];
  }

  let page: Page = { days: [], class: "active" };
  let day: Day;

  slots.forEach((ele) => {
    const start = DateTime.fromISO(ele.beginsAt?.toString() ?? "");
    const end = DateTime.fromISO(ele.endsAt?.toString() ?? "");
    const heading = `${start.monthLong} ${start.day}`;

    if (day === undefined) {
      day = createDay(heading, start, end);
      page.days.push(day);
    } else if (day.heading === heading) {
      addAppointment(day, start, end);
    } else {
      day = createDay(heading, start, end);
      if (page.days.length < daysPerPage) {
        page.days.push(day);
      } else {
        pages.push(page);
        page = { days: [day], class: "" };
      }
    }
  });

  pages.push(page);
  return pages;
}

function createDay(heading: string, start: DateTime, end: DateTime): Day {
  const day = {
    heading: heading,
    day: start.weekdayLong,
    appointments: [],
  };
  addAppointment(day, start, end);
  return day;
}

function addAppointment(day: Day, start: DateTime, end: DateTime) {
  day.appointments.push({
    start: start.toString(),
    end: end.toString(),
    displayValue: start.toLocaleString(DateTime.TIME_SIMPLE),
  });
}

interface CarouselEvent extends Event {
  direction: string;
  from: number;
  to: number;
}

function setUpCarouselListeners() {
  const carousel = document.getElementById("scheduleCarousel");
  if (!carousel) {
    return;
  }

  const pages = document.querySelectorAll(".carousel-item");
  const prevBtn = document.querySelector(
    ".carousel-control-prev",
  ) as HTMLElement;
  const nextBtn = document.querySelector(
    ".carousel-control-next",
  ) as HTMLElement;
  const prevIcon = document.querySelector(".prev-icon");
  const nextIcon = document.querySelector(".next-icon");

  carousel.addEventListener("slid.bs.carousel", (event) => {
    const carouselEvent = event as CarouselEvent;
    if (!prevIcon || !nextIcon) {
      return;
    }
    if (carouselEvent.from === 0) {
      prevIcon.classList.remove("hidden");
      prevBtn.removeAttribute("disabled");
    }
    if (carouselEvent.from === pages.length - 1) {
      nextIcon.classList.remove("hidden");
      nextBtn.removeAttribute("disabled");
    }
    if (carouselEvent.to === pages.length - 1) {
      nextIcon.classList.add("hidden");
      nextBtn.setAttribute("disabled", "");
    }
    if (carouselEvent.to === 0) {
      prevIcon.classList.add("hidden");
      prevBtn.setAttribute("disabled", "");
    }
  });
}
