import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://249429e4cfd34bb2aaaf03978a470b19@o1416701.ingest.sentry.io/4504555538546688",
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [/https?:\/\/www.gstatic.com/],
  });
}

import { readFileSync } from "fs";
import {
  validateForm,
  validateFieldIfRequired,
} from "./helpers/FormValidation";
import { initialiseFirebaseApp } from "./helpers/Firebase";
import {
  processFormSubmission,
  resendOTP,
  confirmAppointment,
} from "./helpers/FormSubmission";
import { navigateTo, router } from "./helpers/Router";
import {
  clearUserSession,
  getUserData,
  getZipCodeStatus,
  saveContactPreference,
} from "./helpers/UserData";
import {
  listOptionSelected,
  addToCalendar,
  toggleSingleChoiceListSubmitButton,
  displayErrorMessage,
} from "./helpers/Utils";
import * as firebase from "./helpers/Firebase";
import { strings } from "./helpers/Strings";

export const templates: { [id: string]: string } = {};

// App Startup
loadTemplates();
initialiseFirebaseApp();

// Need to load all templates into memory as fs.readFileSync() only works at build time
// So we're unable to read templates at run time which is what we would do prefer to do
function loadTemplates() {
  try {
    templates["LandingView"] = readFileSync(
      "src/views/templates/LandingView.html",
      { encoding: "utf-8" },
    );
    templates["LandingViewAddress"] = readFileSync(
      "src/views/templates/LandingViewAddress.html",
      { encoding: "utf-8" },
    );
    templates["ZipCodeRouter"] = readFileSync(
      "src/views/templates/ZipCodeRouter.html",
      { encoding: "utf-8" },
    );
    templates["NeighborhoodView"] = readFileSync(
      "src/views/templates/NeighborhoodView.html",
      { encoding: "utf-8" },
    );
    templates["HearAboutUsView"] = readFileSync(
      "src/views/templates/HearAboutUsView.html",
      { encoding: "utf-8" },
    );
    templates["ScheduleAppointmentView"] = readFileSync(
      "src/views/templates/ScheduleAppointmentView.html",
      { encoding: "utf-8" },
    );
    templates["PersonalInfoView"] = readFileSync(
      "src/views/templates/PersonalInfoView.html",
      { encoding: "utf-8" },
    );
    templates["PhoneNumberVerificationView"] = readFileSync(
      "src/views/templates/PhoneNumberVerificationView.html",
      { encoding: "utf-8" },
    );
    templates["WaitListView"] = readFileSync(
      "src/views/templates/WaitListView.html",
      { encoding: "utf-8" },
    );
    templates["NoAppointmentsView"] = readFileSync(
      "src/views/templates/NoAppointmentsView.html",
      { encoding: "utf-8" },
    );
    templates["NoAppointmentsConfirmationView"] = readFileSync(
      "src/views/templates/NoAppointmentsConfirmationView.html",
      { encoding: "utf-8" },
    );
    templates["ExistingCustomerView"] = readFileSync(
      "src/views/templates/ExistingCustomerView.html",
      { encoding: "utf-8" },
    );
    templates["ConfirmationView"] = readFileSync(
      "src/views/templates/ConfirmationView.html",
      { encoding: "utf-8" },
    );
    templates["NotFoundView"] = readFileSync(
      "src/views/templates/NotFoundView.html",
      { encoding: "utf-8" },
    );
    templates["ConfirmationWidget"] = readFileSync(
      "src/views/templates/widgets/ConfirmationWidget.html",
      { encoding: "utf-8" },
    );
  } catch (e) {
    console.log(e);
  }
}

// Listener Functions
async function handleClicks(event: MouseEvent) {
  // Regular <a> links
  const target = event.target as HTMLLinkElement;
  if (target.matches("[data-link]")) {
    event.preventDefault();
    navigateTo(target.href);
  }

  if (target.matches("[data-resendOTP]")) {
    event.preventDefault();
    await resendOTP();
  }

  if (target.matches("[clear-storage]")) {
    event.preventDefault();
    clearUserSession();
    router();
  }

  if (target.matches("[data-skipAppointmentSelection]")) {
    event.preventDefault();
    saveContactPreference("phone");
    sessionStorage.removeItem("honeyHomesUserAppointmentDetails");
    const userData = getUserData();
    if (userData) {
      const signInAttempt = await firebase.attemptSignin(
        firebase.firebaseAuth,
        userData.phoneNumber,
        "recaptcha-container",
      );
      if (signInAttempt.ok) {
        const zipCodeStatusOption = getZipCodeStatus();
        if (
          zipCodeStatusOption.some &&
          zipCodeStatusOption.val === "waitlisted"
        ) {
          navigateTo("/waitlist-verify-phone-number");
        } else {
          navigateTo("/verify-phone-number");
        }
      } else {
        displayErrorMessage(strings.genericError);
      }
    }
  }

  // Form submissions, form submit buttons are disabled during submission to avoid multiple clicks
  const formButton = event.target as HTMLButtonElement;
  if (formButton.type === "submit") {
    event.preventDefault();
    const form = formButton.form;
    if (form) {
      if (validateForm(form)) {
        await processFormSubmission(form);
      }
    }
  }

  // Div selections, those that fire a request are checked for the presence of a .loader class to prevent
  // multiple clicks during a request.
  const element = event.target as HTMLElement;

  if (
    element.matches(".single-option") ||
    (element.parentElement?.matches(".single-option") ?? false)
  ) {
    if (!element.matches(".single-option")) {
      const parent = element.parentElement;
      if (parent) {
        listOptionSelected(parent);
      }
    } else {
      listOptionSelected(element);
    }
  }
  if (element.matches("#confirm")) {
    await confirmAppointment(element);
  }
  if (element.matches(".add-to-calendar")) {
    addToCalendar(element);
  }
}

function handleInputs(event: Event) {
  const inputField = event.target as HTMLInputElement;
  if (inputField) {
    if (inputField.matches("#other")) {
      toggleSingleChoiceListSubmitButton(inputField.value !== "");
    }

    const form = inputField.form;
    if (form) {
      if (form.matches(".requires-validation.was-validated")) {
        validateFieldIfRequired(inputField);
      }
    }
  }
}
// Listeners
window.addEventListener("popstate", () => {
  router(true);
});
document.addEventListener("DOMContentLoaded", () => {
  document.body.addEventListener("click", handleClicks);
  document.body.addEventListener("input", handleInputs);
});
// Clear session storage to eliminate wonky behavior stemming from previous site visits
window.addEventListener("beforeunload", function () {
  const referrer = document.referrer;
  if (!referrer) {
    // User is navigating to the site for the first time
    clearUserSession();
    return;
  }
  const currentHost = window.location.host;
  const referrerHost = new URL(referrer).host;

  if (referrerHost !== currentHost) {
    // User is navigating away from the website
    clearUserSession();
  }
});

router();
