import { isValidPhoneNumber } from "libphonenumber-js";

export function validateForm(form: HTMLFormElement): boolean {
  if (form.matches(".requires-validation")) {
    const elements = form.elements;
    let formValid = true;

    Array.from(elements).forEach((element) => {
      const input = element as HTMLInputElement;
      const fieldValid = validateFieldIfRequired(input);
      if (formValid) {
        formValid = fieldValid;
      }
    });

    form.classList.add("was-validated");
    return formValid;
  }
  return true;
}

export function validateFieldIfRequired(field: HTMLInputElement): boolean {
  if (field.hasAttribute("required")) {
    let fieldValid = true;
    const type = field.getAttribute("type") ?? "text";
    switch (type) {
      case "email":
        fieldValid = isEmailValid(field.value);
        break;
      case "tel":
        fieldValid = checkPhoneNumber(field.value);
        break;
      default:
        fieldValid = textValidated(field.value);
        break;
    }

    const pattern = field.getAttribute("pattern");
    if (pattern) {
      fieldValid = isPatternValid(field.value, pattern);
    }

    toggleFieldValidityState(field, fieldValid);
    return fieldValid;
  }

  return true;
}

function toggleFieldValidityState(field: HTMLInputElement, valid: boolean) {
  const parent = field.parentElement;
  if (parent) {
    valid
      ? parent.classList.remove("section-invalid")
      : parent.classList.add("section-invalid");
  }
}

function textValidated(value: string): boolean {
  return value !== "" ? true : false;
}

function isEmailValid(email: string): boolean {
  // Regex from https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(email);
}

function checkPhoneNumber(phoneNumber: string): boolean {
  return isValidPhoneNumber(phoneNumber, "US");
}

function isPatternValid(textInput: string, pattern: string): boolean {
  return new RegExp(pattern).test(textInput);
}
