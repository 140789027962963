/* eslint-disable */

import { AllTypesProps, ReturnTypes } from "./const";
type ZEUS_INTERFACES = never;
type ZEUS_UNIONS = never;

export type ValueTypes = {
  /** File attachments to any other object. */
  ["Attachment"]: AliasType<{
    /** An object relationship */
    CreatedBy?: ValueTypes["Person"];
    ProfilePictureFor?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Person_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Person_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Person_bool_exp"] | null;
      },
      ValueTypes["Person"],
    ];
    contentType?: boolean;
    /** Not auto generated, must be provided */
    id?: boolean;
    publicUrl?: boolean;
    __typename?: boolean;
  }>;
  /** order by aggregate values of table "Attachment" */
  ["Attachment_aggregate_order_by"]: {
    count?: ValueTypes["order_by"] | null;
    max?: ValueTypes["Attachment_max_order_by"] | null;
    min?: ValueTypes["Attachment_min_order_by"] | null;
  };
  /** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
  ["Attachment_bool_exp"]: {
    CreatedBy?: ValueTypes["Person_bool_exp"] | null;
    ProfilePictureFor?: ValueTypes["Person_bool_exp"] | null;
    _and?: ValueTypes["Attachment_bool_exp"][];
    _not?: ValueTypes["Attachment_bool_exp"] | null;
    _or?: ValueTypes["Attachment_bool_exp"][];
    contentType?: ValueTypes["String_comparison_exp"] | null;
    id?: ValueTypes["uuid_comparison_exp"] | null;
    publicUrl?: ValueTypes["String_comparison_exp"] | null;
  };
  /** order by max() on columns of table "Attachment" */
  ["Attachment_max_order_by"]: {
    contentType?: ValueTypes["order_by"] | null;
    /** Not auto generated, must be provided */
    id?: ValueTypes["order_by"] | null;
    publicUrl?: ValueTypes["order_by"] | null;
  };
  /** order by min() on columns of table "Attachment" */
  ["Attachment_min_order_by"]: {
    contentType?: ValueTypes["order_by"] | null;
    /** Not auto generated, must be provided */
    id?: ValueTypes["order_by"] | null;
    publicUrl?: ValueTypes["order_by"] | null;
  };
  /** Ordering options when selecting data from "Attachment". */
  ["Attachment_order_by"]: {
    CreatedBy?: ValueTypes["Person_order_by"] | null;
    ProfilePictureFor_aggregate?:
      | ValueTypes["Person_aggregate_order_by"]
      | null;
    contentType?: ValueTypes["order_by"] | null;
    id?: ValueTypes["order_by"] | null;
    publicUrl?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "Attachment" */
  ["Attachment_select_column"]: Attachment_select_column;
  /** Streaming cursor of the table "Attachment" */
  ["Attachment_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["Attachment_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["Attachment_stream_cursor_value_input"]: {
    contentType?: string | null;
    /** Not auto generated, must be provided */
    id?: ValueTypes["uuid"] | null;
    publicUrl?: string | null;
  };
  /** columns and relationships of "Availability" */
  ["Availability"]: AliasType<{
    /** An object relationship */
    Person?: ValueTypes["Person"];
    beginsAt?: boolean;
    endsAt?: boolean;
    personId?: boolean;
    serviceArea?: boolean;
    __typename?: boolean;
  }>;
  /** Result of an Availability query by the perspective of a Home */
  ["AvailabilityResult"]: AliasType<{
    id?: boolean;
    __typename?: boolean;
  }>;
  ["AvailabilityResultSource_enum"]: AvailabilityResultSource_enum;
  /** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
  ["AvailabilityResult_bool_exp"]: {
    _and?: ValueTypes["AvailabilityResult_bool_exp"][];
    _not?: ValueTypes["AvailabilityResult_bool_exp"] | null;
    _or?: ValueTypes["AvailabilityResult_bool_exp"][];
    id?: ValueTypes["uuid_comparison_exp"] | null;
  };
  /** input type for inserting data into table "AvailabilityResult" */
  ["AvailabilityResult_insert_input"]: {
    creationSource?: ValueTypes["CreationSourceType_enum"] | null;
    data?: ValueTypes["jsonb"] | null;
    source?: ValueTypes["AvailabilityResultSource_enum"] | null;
  };
  /** response of any mutation on the table "AvailabilityResult" */
  ["AvailabilityResult_mutation_response"]: AliasType<{
    /** number of rows affected by the mutation */
    affected_rows?: boolean;
    /** data from the rows affected by the mutation */
    returning?: ValueTypes["AvailabilityResult"];
    __typename?: boolean;
  }>;
  /** Ordering options when selecting data from "AvailabilityResult". */
  ["AvailabilityResult_order_by"]: {
    id?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "AvailabilityResult" */
  ["AvailabilityResult_select_column"]: AvailabilityResult_select_column;
  /** Streaming cursor of the table "AvailabilityResult" */
  ["AvailabilityResult_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["AvailabilityResult_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["AvailabilityResult_stream_cursor_value_input"]: {
    id?: ValueTypes["uuid"] | null;
  };
  /** order by aggregate values of table "Availability" */
  ["Availability_aggregate_order_by"]: {
    count?: ValueTypes["order_by"] | null;
    max?: ValueTypes["Availability_max_order_by"] | null;
    min?: ValueTypes["Availability_min_order_by"] | null;
  };
  /** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
  ["Availability_bool_exp"]: {
    Person?: ValueTypes["Person_bool_exp"] | null;
    _and?: ValueTypes["Availability_bool_exp"][];
    _not?: ValueTypes["Availability_bool_exp"] | null;
    _or?: ValueTypes["Availability_bool_exp"][];
    beginsAt?: ValueTypes["timestamptz_comparison_exp"] | null;
    endsAt?: ValueTypes["timestamptz_comparison_exp"] | null;
    personId?: ValueTypes["uuid_comparison_exp"] | null;
    serviceArea?: ValueTypes["geometry_comparison_exp"] | null;
  };
  /** order by max() on columns of table "Availability" */
  ["Availability_max_order_by"]: {
    beginsAt?: ValueTypes["order_by"] | null;
    endsAt?: ValueTypes["order_by"] | null;
    personId?: ValueTypes["order_by"] | null;
  };
  /** order by min() on columns of table "Availability" */
  ["Availability_min_order_by"]: {
    beginsAt?: ValueTypes["order_by"] | null;
    endsAt?: ValueTypes["order_by"] | null;
    personId?: ValueTypes["order_by"] | null;
  };
  /** Ordering options when selecting data from "Availability". */
  ["Availability_order_by"]: {
    Person?: ValueTypes["Person_order_by"] | null;
    beginsAt?: ValueTypes["order_by"] | null;
    endsAt?: ValueTypes["order_by"] | null;
    personId?: ValueTypes["order_by"] | null;
    serviceArea?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "Availability" */
  ["Availability_select_column"]: Availability_select_column;
  /** Streaming cursor of the table "Availability" */
  ["Availability_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["Availability_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["Availability_stream_cursor_value_input"]: {
    beginsAt?: ValueTypes["timestamptz"] | null;
    endsAt?: ValueTypes["timestamptz"] | null;
    personId?: ValueTypes["uuid"] | null;
    serviceArea?: ValueTypes["geometry"] | null;
  };
  /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
  ["Boolean_comparison_exp"]: {
    _eq?: boolean | null;
    _gt?: boolean | null;
    _gte?: boolean | null;
    _in?: boolean[];
    _is_null?: boolean | null;
    _lt?: boolean | null;
    _lte?: boolean | null;
    _neq?: boolean | null;
    _nin?: boolean[];
  };
  /** columns and relationships of "ContentBlock" */
  ["ContentBlock"]: AliasType<{
    body?: boolean;
    createdAt?: boolean;
    key?: boolean;
    updatedAt?: boolean;
    __typename?: boolean;
  }>;
  /** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
  ["ContentBlock_bool_exp"]: {
    _and?: ValueTypes["ContentBlock_bool_exp"][];
    _not?: ValueTypes["ContentBlock_bool_exp"] | null;
    _or?: ValueTypes["ContentBlock_bool_exp"][];
    body?: ValueTypes["String_comparison_exp"] | null;
    createdAt?: ValueTypes["timestamptz_comparison_exp"] | null;
    key?: ValueTypes["String_comparison_exp"] | null;
    updatedAt?: ValueTypes["timestamptz_comparison_exp"] | null;
  };
  /** Ordering options when selecting data from "ContentBlock". */
  ["ContentBlock_order_by"]: {
    body?: ValueTypes["order_by"] | null;
    createdAt?: ValueTypes["order_by"] | null;
    key?: ValueTypes["order_by"] | null;
    updatedAt?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "ContentBlock" */
  ["ContentBlock_select_column"]: ContentBlock_select_column;
  /** Streaming cursor of the table "ContentBlock" */
  ["ContentBlock_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["ContentBlock_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["ContentBlock_stream_cursor_value_input"]: {
    body?: string | null;
    createdAt?: ValueTypes["timestamptz"] | null;
    key?: string | null;
    updatedAt?: ValueTypes["timestamptz"] | null;
  };
  ["CreationSourceType_enum"]: CreationSourceType_enum;
  /** Definition of a service area */
  ["GeographicRegion"]: AliasType<{
    GeographicRegionHandymen?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionHandyman_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionHandyman_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionHandyman_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionNeighborhoods?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionNeighborhood_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionNeighborhood_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    availableStartingAt?: boolean;
    id?: boolean;
    name?: boolean;
    servicedZipCodes?: boolean;
    __typename?: boolean;
  }>;
  /** Handymen assigned to a geographic region */
  ["GeographicRegionHandyman"]: AliasType<{
    /** An object relationship */
    GeographicRegion?: ValueTypes["GeographicRegion"];
    /** An object relationship */
    Handyman?: ValueTypes["Person"];
    id?: boolean;
    __typename?: boolean;
  }>;
  /** order by aggregate values of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_aggregate_order_by"]: {
    count?: ValueTypes["order_by"] | null;
    max?: ValueTypes["GeographicRegionHandyman_max_order_by"] | null;
    min?: ValueTypes["GeographicRegionHandyman_min_order_by"] | null;
  };
  /** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
  ["GeographicRegionHandyman_bool_exp"]: {
    GeographicRegion?: ValueTypes["GeographicRegion_bool_exp"] | null;
    Handyman?: ValueTypes["Person_bool_exp"] | null;
    _and?: ValueTypes["GeographicRegionHandyman_bool_exp"][];
    _not?: ValueTypes["GeographicRegionHandyman_bool_exp"] | null;
    _or?: ValueTypes["GeographicRegionHandyman_bool_exp"][];
    id?: ValueTypes["uuid_comparison_exp"] | null;
  };
  /** order by max() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_max_order_by"]: {
    id?: ValueTypes["order_by"] | null;
  };
  /** order by min() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_min_order_by"]: {
    id?: ValueTypes["order_by"] | null;
  };
  /** Ordering options when selecting data from "GeographicRegionHandyman". */
  ["GeographicRegionHandyman_order_by"]: {
    GeographicRegion?: ValueTypes["GeographicRegion_order_by"] | null;
    Handyman?: ValueTypes["Person_order_by"] | null;
    id?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_select_column"]: GeographicRegionHandyman_select_column;
  /** Streaming cursor of the table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["GeographicRegionHandyman_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionHandyman_stream_cursor_value_input"]: {
    id?: ValueTypes["uuid"] | null;
  };
  /** Separates large population zip codes into regions. Used for first time onboarding. */
  ["GeographicRegionNeighborhood"]: AliasType<{
    /** An object relationship */
    GeographicRegion?: ValueTypes["GeographicRegion"];
    id?: boolean;
    name?: boolean;
    zipCode?: boolean;
    __typename?: boolean;
  }>;
  /** order by aggregate values of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_aggregate_order_by"]: {
    count?: ValueTypes["order_by"] | null;
    max?: ValueTypes["GeographicRegionNeighborhood_max_order_by"] | null;
    min?: ValueTypes["GeographicRegionNeighborhood_min_order_by"] | null;
  };
  /** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
  ["GeographicRegionNeighborhood_bool_exp"]: {
    GeographicRegion?: ValueTypes["GeographicRegion_bool_exp"] | null;
    _and?: ValueTypes["GeographicRegionNeighborhood_bool_exp"][];
    _not?: ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null;
    _or?: ValueTypes["GeographicRegionNeighborhood_bool_exp"][];
    id?: ValueTypes["uuid_comparison_exp"] | null;
    name?: ValueTypes["String_comparison_exp"] | null;
    zipCode?: ValueTypes["String_comparison_exp"] | null;
  };
  /** order by max() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_max_order_by"]: {
    id?: ValueTypes["order_by"] | null;
    name?: ValueTypes["order_by"] | null;
    zipCode?: ValueTypes["order_by"] | null;
  };
  /** order by min() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_min_order_by"]: {
    id?: ValueTypes["order_by"] | null;
    name?: ValueTypes["order_by"] | null;
    zipCode?: ValueTypes["order_by"] | null;
  };
  /** Ordering options when selecting data from "GeographicRegionNeighborhood". */
  ["GeographicRegionNeighborhood_order_by"]: {
    GeographicRegion?: ValueTypes["GeographicRegion_order_by"] | null;
    id?: ValueTypes["order_by"] | null;
    name?: ValueTypes["order_by"] | null;
    zipCode?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_select_column"]: GeographicRegionNeighborhood_select_column;
  /** Streaming cursor of the table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["GeographicRegionNeighborhood_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionNeighborhood_stream_cursor_value_input"]: {
    id?: ValueTypes["uuid"] | null;
    name?: string | null;
    zipCode?: string | null;
  };
  /** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
  ["GeographicRegion_bool_exp"]: {
    GeographicRegionHandymen?:
      | ValueTypes["GeographicRegionHandyman_bool_exp"]
      | null;
    GeographicRegionNeighborhoods?:
      | ValueTypes["GeographicRegionNeighborhood_bool_exp"]
      | null;
    _and?: ValueTypes["GeographicRegion_bool_exp"][];
    _not?: ValueTypes["GeographicRegion_bool_exp"] | null;
    _or?: ValueTypes["GeographicRegion_bool_exp"][];
    availableStartingAt?: ValueTypes["timestamptz_comparison_exp"] | null;
    id?: ValueTypes["uuid_comparison_exp"] | null;
    name?: ValueTypes["String_comparison_exp"] | null;
    servicedZipCodes?: ValueTypes["String_array_comparison_exp"] | null;
  };
  ["GeographicRegion_by_zipCode_args"]: {
    zipCode?: string | null;
  };
  /** Ordering options when selecting data from "GeographicRegion". */
  ["GeographicRegion_order_by"]: {
    GeographicRegionHandymen_aggregate?:
      | ValueTypes["GeographicRegionHandyman_aggregate_order_by"]
      | null;
    GeographicRegionNeighborhoods_aggregate?:
      | ValueTypes["GeographicRegionNeighborhood_aggregate_order_by"]
      | null;
    availableStartingAt?: ValueTypes["order_by"] | null;
    id?: ValueTypes["order_by"] | null;
    name?: ValueTypes["order_by"] | null;
    servicedZipCodes?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "GeographicRegion" */
  ["GeographicRegion_select_column"]: GeographicRegion_select_column;
  /** Streaming cursor of the table "GeographicRegion" */
  ["GeographicRegion_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["GeographicRegion_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegion_stream_cursor_value_input"]: {
    availableStartingAt?: ValueTypes["timestamptz"] | null;
    id?: ValueTypes["uuid"] | null;
    name?: string | null;
    servicedZipCodes?: string[];
  };
  /** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
  ["Int_comparison_exp"]: {
    _eq?: number | null;
    _gt?: number | null;
    _gte?: number | null;
    _in?: number[];
    _is_null?: boolean | null;
    _lt?: number | null;
    _lte?: number | null;
    _neq?: number | null;
    _nin?: number[];
  };
  /** columns and relationships of "MarketingLead" */
  ["MarketingLead"]: AliasType<{
    id?: boolean;
    __typename?: boolean;
  }>;
  /** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
  ["MarketingLead_bool_exp"]: {
    _and?: ValueTypes["MarketingLead_bool_exp"][];
    _not?: ValueTypes["MarketingLead_bool_exp"] | null;
    _or?: ValueTypes["MarketingLead_bool_exp"][];
    id?: ValueTypes["uuid_comparison_exp"] | null;
  };
  /** unique or primary key constraints on table "MarketingLead" */
  ["MarketingLead_constraint"]: MarketingLead_constraint;
  /** input type for inserting data into table "MarketingLead" */
  ["MarketingLead_insert_input"]: {
    city?: string | null;
    email?: string | null;
    firstAppointmentBeginsAt?: ValueTypes["timestamptz"] | null;
    firstAppointmentEndsAt?: ValueTypes["timestamptz"] | null;
    firstName?: string | null;
    homeAccountCreated?: boolean | null;
    lastName?: string | null;
    organicSource?: string | null;
    phoneNumber?: string | null;
    phoneNumberVerified?: boolean | null;
    state?: string | null;
    streetAddress?: string | null;
    utmCampaign?: string | null;
    utmContent?: string | null;
    utmId?: string | null;
    utmMedium?: string | null;
    utmSource?: string | null;
    utmSourcePlatform?: string | null;
    utmTerm?: string | null;
    zipCode?: string | null;
  };
  /** response of any mutation on the table "MarketingLead" */
  ["MarketingLead_mutation_response"]: AliasType<{
    /** number of rows affected by the mutation */
    affected_rows?: boolean;
    /** data from the rows affected by the mutation */
    returning?: ValueTypes["MarketingLead"];
    __typename?: boolean;
  }>;
  /** on_conflict condition type for table "MarketingLead" */
  ["MarketingLead_on_conflict"]: {
    constraint: ValueTypes["MarketingLead_constraint"];
    update_columns: ValueTypes["MarketingLead_update_column"][];
    where?: ValueTypes["MarketingLead_bool_exp"] | null;
  };
  /** Ordering options when selecting data from "MarketingLead". */
  ["MarketingLead_order_by"]: {
    id?: ValueTypes["order_by"] | null;
  };
  /** primary key columns input for table: MarketingLead */
  ["MarketingLead_pk_columns_input"]: {
    id: ValueTypes["uuid"];
  };
  /** select columns of table "MarketingLead" */
  ["MarketingLead_select_column"]: MarketingLead_select_column;
  /** input type for updating data in table "MarketingLead" */
  ["MarketingLead_set_input"]: {
    city?: string | null;
    email?: string | null;
    firstAppointmentBeginsAt?: ValueTypes["timestamptz"] | null;
    firstAppointmentEndsAt?: ValueTypes["timestamptz"] | null;
    firstName?: string | null;
    homeAccountCreated?: boolean | null;
    lastName?: string | null;
    organicSource?: string | null;
    phoneNumber?: string | null;
    phoneNumberVerified?: boolean | null;
    state?: string | null;
    streetAddress?: string | null;
    utmCampaign?: string | null;
    utmContent?: string | null;
    utmId?: string | null;
    utmMedium?: string | null;
    utmSource?: string | null;
    utmSourcePlatform?: string | null;
    utmTerm?: string | null;
    zipCode?: string | null;
  };
  /** Streaming cursor of the table "MarketingLead" */
  ["MarketingLead_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["MarketingLead_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["MarketingLead_stream_cursor_value_input"]: {
    id?: ValueTypes["uuid"] | null;
  };
  /** update columns of table "MarketingLead" */
  ["MarketingLead_update_column"]: MarketingLead_update_column;
  ["MarketingLead_updates"]: {
    /** sets the columns of the filtered rows to the given values */
    _set?: ValueTypes["MarketingLead_set_input"] | null;
    /** filter the rows which have to be updated */
    where: ValueTypes["MarketingLead_bool_exp"];
  };
  /** columns and relationships of "Person" */
  ["Person"]: AliasType<{
    Availability?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Availability_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Availability_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Availability_bool_exp"] | null;
      },
      ValueTypes["Availability"],
    ];
    CreatedAttachments?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Attachment_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Attachment_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Attachment_bool_exp"] | null;
      },
      ValueTypes["Attachment"],
    ];
    /** An object relationship */
    ProfilePicture?: ValueTypes["Attachment"];
    firstName?: boolean;
    id?: boolean;
    __typename?: boolean;
  }>;
  /** order by aggregate values of table "Person" */
  ["Person_aggregate_order_by"]: {
    count?: ValueTypes["order_by"] | null;
    max?: ValueTypes["Person_max_order_by"] | null;
    min?: ValueTypes["Person_min_order_by"] | null;
  };
  /** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
  ["Person_bool_exp"]: {
    Availability?: ValueTypes["Availability_bool_exp"] | null;
    CreatedAttachments?: ValueTypes["Attachment_bool_exp"] | null;
    ProfilePicture?: ValueTypes["Attachment_bool_exp"] | null;
    _and?: ValueTypes["Person_bool_exp"][];
    _not?: ValueTypes["Person_bool_exp"] | null;
    _or?: ValueTypes["Person_bool_exp"][];
    firstName?: ValueTypes["String_comparison_exp"] | null;
    id?: ValueTypes["uuid_comparison_exp"] | null;
  };
  /** order by max() on columns of table "Person" */
  ["Person_max_order_by"]: {
    firstName?: ValueTypes["order_by"] | null;
    id?: ValueTypes["order_by"] | null;
  };
  /** order by min() on columns of table "Person" */
  ["Person_min_order_by"]: {
    firstName?: ValueTypes["order_by"] | null;
    id?: ValueTypes["order_by"] | null;
  };
  /** Ordering options when selecting data from "Person". */
  ["Person_order_by"]: {
    Availability_aggregate?:
      | ValueTypes["Availability_aggregate_order_by"]
      | null;
    CreatedAttachments_aggregate?:
      | ValueTypes["Attachment_aggregate_order_by"]
      | null;
    ProfilePicture?: ValueTypes["Attachment_order_by"] | null;
    firstName?: ValueTypes["order_by"] | null;
    id?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "Person" */
  ["Person_select_column"]: Person_select_column;
  /** Streaming cursor of the table "Person" */
  ["Person_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["Person_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["Person_stream_cursor_value_input"]: {
    firstName?: string | null;
    id?: ValueTypes["uuid"] | null;
  };
  /** Currently active and historical referral programs */
  ["ReferralProgram"]: AliasType<{
    creditAmount?: boolean;
    __typename?: boolean;
  }>;
  /** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
  ["ReferralProgram_bool_exp"]: {
    _and?: ValueTypes["ReferralProgram_bool_exp"][];
    _not?: ValueTypes["ReferralProgram_bool_exp"] | null;
    _or?: ValueTypes["ReferralProgram_bool_exp"][];
    creditAmount?: ValueTypes["Int_comparison_exp"] | null;
  };
  /** Ordering options when selecting data from "ReferralProgram". */
  ["ReferralProgram_order_by"]: {
    creditAmount?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "ReferralProgram" */
  ["ReferralProgram_select_column"]: ReferralProgram_select_column;
  /** Streaming cursor of the table "ReferralProgram" */
  ["ReferralProgram_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["ReferralProgram_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["ReferralProgram_stream_cursor_value_input"]: {
    creditAmount?: number | null;
  };
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_array_comparison_exp"]: {
    /** is the array contained in the given array value */
    _contained_in?: string[];
    /** does the array contain the given value */
    _contains?: string[];
    _eq?: string[];
    _gt?: string[];
    _gte?: string[];
    _in: string[];
    _is_null?: boolean | null;
    _lt?: string[];
    _lte?: string[];
    _neq?: string[];
    _nin: string[];
  };
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_comparison_exp"]: {
    _eq?: string | null;
    _gt?: string | null;
    _gte?: string | null;
    /** does the column match the given case-insensitive pattern */
    _ilike?: string | null;
    _in?: string[];
    /** does the column match the given POSIX regular expression, case insensitive */
    _iregex?: string | null;
    _is_null?: boolean | null;
    /** does the column match the given pattern */
    _like?: string | null;
    _lt?: string | null;
    _lte?: string | null;
    _neq?: string | null;
    /** does the column NOT match the given case-insensitive pattern */
    _nilike?: string | null;
    _nin?: string[];
    /** does the column NOT match the given POSIX regular expression, case insensitive */
    _niregex?: string | null;
    /** does the column NOT match the given pattern */
    _nlike?: string | null;
    /** does the column NOT match the given POSIX regular expression, case sensitive */
    _nregex?: string | null;
    /** does the column NOT match the given SQL regular expression */
    _nsimilar?: string | null;
    /** does the column match the given POSIX regular expression, case sensitive */
    _regex?: string | null;
    /** does the column match the given SQL regular expression */
    _similar?: string | null;
  };
  /** ordering argument of a cursor */
  ["cursor_ordering"]: cursor_ordering;
  ["find_Person_by_phone_args"]: {
    number?: string | null;
  };
  /** columns and relationships of "find_Person_response" */
  ["find_Person_response"]: AliasType<{
    present?: boolean;
    __typename?: boolean;
  }>;
  /** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
  ["find_Person_response_bool_exp"]: {
    _and?: ValueTypes["find_Person_response_bool_exp"][];
    _not?: ValueTypes["find_Person_response_bool_exp"] | null;
    _or?: ValueTypes["find_Person_response_bool_exp"][];
    present?: ValueTypes["Boolean_comparison_exp"] | null;
  };
  /** Ordering options when selecting data from "find_Person_response". */
  ["find_Person_response_order_by"]: {
    present?: ValueTypes["order_by"] | null;
  };
  /** select columns of table "find_Person_response" */
  ["find_Person_response_select_column"]: find_Person_response_select_column;
  /** Streaming cursor of the table "find_Person_response" */
  ["find_Person_response_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: ValueTypes["find_Person_response_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: ValueTypes["cursor_ordering"] | null;
  };
  /** Initial value of the column from where the streaming should start */
  ["find_Person_response_stream_cursor_value_input"]: {
    present?: boolean | null;
  };
  ["geography"]: unknown;
  ["geography_cast_exp"]: {
    geometry?: ValueTypes["geometry_comparison_exp"] | null;
  };
  /** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
  ["geography_comparison_exp"]: {
    _cast?: ValueTypes["geography_cast_exp"] | null;
    _eq?: ValueTypes["geography"] | null;
    _gt?: ValueTypes["geography"] | null;
    _gte?: ValueTypes["geography"] | null;
    _in?: ValueTypes["geography"][];
    _is_null?: boolean | null;
    _lt?: ValueTypes["geography"] | null;
    _lte?: ValueTypes["geography"] | null;
    _neq?: ValueTypes["geography"] | null;
    _nin?: ValueTypes["geography"][];
    /** is the column within a given distance from the given geography value */
    _st_d_within?: ValueTypes["st_d_within_geography_input"] | null;
    /** does the column spatially intersect the given geography value */
    _st_intersects?: ValueTypes["geography"] | null;
  };
  ["geometry"]: unknown;
  ["geometry_cast_exp"]: {
    geography?: ValueTypes["geography_comparison_exp"] | null;
  };
  /** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
  ["geometry_comparison_exp"]: {
    _cast?: ValueTypes["geometry_cast_exp"] | null;
    _eq?: ValueTypes["geometry"] | null;
    _gt?: ValueTypes["geometry"] | null;
    _gte?: ValueTypes["geometry"] | null;
    _in?: ValueTypes["geometry"][];
    _is_null?: boolean | null;
    _lt?: ValueTypes["geometry"] | null;
    _lte?: ValueTypes["geometry"] | null;
    _neq?: ValueTypes["geometry"] | null;
    _nin?: ValueTypes["geometry"][];
    /** is the column within a given 3D distance from the given geometry value */
    _st_3d_d_within?: ValueTypes["st_d_within_input"] | null;
    /** does the column spatially intersect the given geometry value in 3D */
    _st_3d_intersects?: ValueTypes["geometry"] | null;
    /** does the column contain the given geometry value */
    _st_contains?: ValueTypes["geometry"] | null;
    /** does the column cross the given geometry value */
    _st_crosses?: ValueTypes["geometry"] | null;
    /** is the column within a given distance from the given geometry value */
    _st_d_within?: ValueTypes["st_d_within_input"] | null;
    /** is the column equal to given geometry value (directionality is ignored) */
    _st_equals?: ValueTypes["geometry"] | null;
    /** does the column spatially intersect the given geometry value */
    _st_intersects?: ValueTypes["geometry"] | null;
    /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
    _st_overlaps?: ValueTypes["geometry"] | null;
    /** does the column have atleast one point in common with the given geometry value */
    _st_touches?: ValueTypes["geometry"] | null;
    /** is the column contained in the given geometry value */
    _st_within?: ValueTypes["geometry"] | null;
  };
  ["jsonb"]: unknown;
  /** mutation root */
  ["mutation_root"]: AliasType<{
    insert_AvailabilityResult?: [
      {
        /** the rows to be inserted */
        objects: ValueTypes["AvailabilityResult_insert_input"][];
      },
      ValueTypes["AvailabilityResult_mutation_response"],
    ];
    insert_AvailabilityResult_one?: [
      {
        /** the row to be inserted */
        object: ValueTypes["AvailabilityResult_insert_input"];
      },
      ValueTypes["AvailabilityResult"],
    ];
    insert_MarketingLead?: [
      {
        /** the rows to be inserted */
        objects: ValueTypes["MarketingLead_insert_input"][] /** upsert condition */;
        on_conflict?: ValueTypes["MarketingLead_on_conflict"] | null;
      },
      ValueTypes["MarketingLead_mutation_response"],
    ];
    insert_MarketingLead_one?: [
      {
        /** the row to be inserted */
        object: ValueTypes["MarketingLead_insert_input"] /** upsert condition */;
        on_conflict?: ValueTypes["MarketingLead_on_conflict"] | null;
      },
      ValueTypes["MarketingLead"],
    ];
    update_MarketingLead?: [
      {
        /** sets the columns of the filtered rows to the given values */
        _set?:
          | ValueTypes["MarketingLead_set_input"]
          | null /** filter the rows which have to be updated */;
        where: ValueTypes["MarketingLead_bool_exp"];
      },
      ValueTypes["MarketingLead_mutation_response"],
    ];
    update_MarketingLead_by_pk?: [
      {
        /** sets the columns of the filtered rows to the given values */
        _set?: ValueTypes["MarketingLead_set_input"] | null;
        pk_columns: ValueTypes["MarketingLead_pk_columns_input"];
      },
      ValueTypes["MarketingLead"],
    ];
    update_MarketingLead_many?: [
      {
        /** updates to execute, in order */
        updates: ValueTypes["MarketingLead_updates"][];
      },
      ValueTypes["MarketingLead_mutation_response"],
    ];
    __typename?: boolean;
  }>;
  /** column ordering options */
  ["order_by"]: order_by;
  ["query_root"]: AliasType<{
    Attachment?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Attachment_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Attachment_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Attachment_bool_exp"] | null;
      },
      ValueTypes["Attachment"],
    ];
    Attachment_by_pk?: [
      {
        /** Not auto generated, must be provided */ id: ValueTypes["uuid"];
      },
      ValueTypes["Attachment"],
    ];
    Availability?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Availability_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Availability_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Availability_bool_exp"] | null;
      },
      ValueTypes["Availability"],
    ];
    AvailabilityResult?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["AvailabilityResult_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["AvailabilityResult_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["AvailabilityResult_bool_exp"] | null;
      },
      ValueTypes["AvailabilityResult"],
    ];
    ContentBlock?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["ContentBlock_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["ContentBlock_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["ContentBlock_bool_exp"] | null;
      },
      ValueTypes["ContentBlock"],
    ];
    ContentBlock_by_pk?: [{ key: string }, ValueTypes["ContentBlock"]];
    GeographicRegion?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegion_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegion_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegion_bool_exp"] | null;
      },
      ValueTypes["GeographicRegion"],
    ];
    GeographicRegionHandyman?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionHandyman_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionHandyman_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionHandyman_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionHandyman_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionNeighborhood?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionNeighborhood_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionNeighborhood_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    GeographicRegionNeighborhood_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    GeographicRegion_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegion"],
    ];
    GeographicRegion_by_zipCode?: [
      {
        /** input parameters for function "GeographicRegion_by_zipCode" */
        args: ValueTypes["GeographicRegion_by_zipCode_args"] /** distinct select on columns */;
        distinct_on?: ValueTypes["GeographicRegion_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegion_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegion_bool_exp"] | null;
      },
      ValueTypes["GeographicRegion"],
    ];
    MarketingLead?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["MarketingLead_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["MarketingLead_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["MarketingLead_bool_exp"] | null;
      },
      ValueTypes["MarketingLead"],
    ];
    MarketingLead_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["MarketingLead"],
    ];
    Person?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Person_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Person_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Person_bool_exp"] | null;
      },
      ValueTypes["Person"],
    ];
    Person_by_pk?: [{ id: ValueTypes["uuid"] }, ValueTypes["Person"]];
    ReferralProgram?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["ReferralProgram_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["ReferralProgram_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["ReferralProgram_bool_exp"] | null;
      },
      ValueTypes["ReferralProgram"],
    ];
    find_Person_by_phone?: [
      {
        /** input parameters for function "find_Person_by_phone" */
        args: ValueTypes["find_Person_by_phone_args"] /** distinct select on columns */;
        distinct_on?: ValueTypes["find_Person_response_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["find_Person_response_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["find_Person_response_bool_exp"] | null;
      },
      ValueTypes["find_Person_response"],
    ];
    find_Person_response?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["find_Person_response_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["find_Person_response_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["find_Person_response_bool_exp"] | null;
      },
      ValueTypes["find_Person_response"],
    ];
    __typename?: boolean;
  }>;
  ["st_d_within_geography_input"]: {
    distance: number;
    from: ValueTypes["geography"];
    use_spheroid?: boolean | null;
  };
  ["st_d_within_input"]: {
    distance: number;
    from: ValueTypes["geometry"];
  };
  ["subscription_root"]: AliasType<{
    Attachment?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Attachment_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Attachment_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Attachment_bool_exp"] | null;
      },
      ValueTypes["Attachment"],
    ];
    Attachment_by_pk?: [
      {
        /** Not auto generated, must be provided */ id: ValueTypes["uuid"];
      },
      ValueTypes["Attachment"],
    ];
    Attachment_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["Attachment_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["Attachment_bool_exp"] | null;
      },
      ValueTypes["Attachment"],
    ];
    Availability?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Availability_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Availability_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Availability_bool_exp"] | null;
      },
      ValueTypes["Availability"],
    ];
    AvailabilityResult?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["AvailabilityResult_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["AvailabilityResult_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["AvailabilityResult_bool_exp"] | null;
      },
      ValueTypes["AvailabilityResult"],
    ];
    AvailabilityResult_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["AvailabilityResult_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["AvailabilityResult_bool_exp"] | null;
      },
      ValueTypes["AvailabilityResult"],
    ];
    Availability_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["Availability_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["Availability_bool_exp"] | null;
      },
      ValueTypes["Availability"],
    ];
    ContentBlock?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["ContentBlock_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["ContentBlock_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["ContentBlock_bool_exp"] | null;
      },
      ValueTypes["ContentBlock"],
    ];
    ContentBlock_by_pk?: [{ key: string }, ValueTypes["ContentBlock"]];
    ContentBlock_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["ContentBlock_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["ContentBlock_bool_exp"] | null;
      },
      ValueTypes["ContentBlock"],
    ];
    GeographicRegion?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegion_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegion_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegion_bool_exp"] | null;
      },
      ValueTypes["GeographicRegion"],
    ];
    GeographicRegionHandyman?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionHandyman_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionHandyman_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionHandyman_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionHandyman_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionHandyman_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["GeographicRegionHandyman_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionHandyman_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionHandyman"],
    ];
    GeographicRegionNeighborhood?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["GeographicRegionNeighborhood_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegionNeighborhood_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    GeographicRegionNeighborhood_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    GeographicRegionNeighborhood_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["GeographicRegionNeighborhood_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null;
      },
      ValueTypes["GeographicRegionNeighborhood"],
    ];
    GeographicRegion_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["GeographicRegion"],
    ];
    GeographicRegion_by_zipCode?: [
      {
        /** input parameters for function "GeographicRegion_by_zipCode" */
        args: ValueTypes["GeographicRegion_by_zipCode_args"] /** distinct select on columns */;
        distinct_on?: ValueTypes["GeographicRegion_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["GeographicRegion_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegion_bool_exp"] | null;
      },
      ValueTypes["GeographicRegion"],
    ];
    GeographicRegion_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["GeographicRegion_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["GeographicRegion_bool_exp"] | null;
      },
      ValueTypes["GeographicRegion"],
    ];
    MarketingLead?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["MarketingLead_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["MarketingLead_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["MarketingLead_bool_exp"] | null;
      },
      ValueTypes["MarketingLead"],
    ];
    MarketingLead_by_pk?: [
      { id: ValueTypes["uuid"] },
      ValueTypes["MarketingLead"],
    ];
    MarketingLead_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["MarketingLead_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["MarketingLead_bool_exp"] | null;
      },
      ValueTypes["MarketingLead"],
    ];
    Person?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["Person_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["Person_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["Person_bool_exp"] | null;
      },
      ValueTypes["Person"],
    ];
    Person_by_pk?: [{ id: ValueTypes["uuid"] }, ValueTypes["Person"]];
    Person_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["Person_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["Person_bool_exp"] | null;
      },
      ValueTypes["Person"],
    ];
    ReferralProgram?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["ReferralProgram_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["ReferralProgram_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["ReferralProgram_bool_exp"] | null;
      },
      ValueTypes["ReferralProgram"],
    ];
    ReferralProgram_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["ReferralProgram_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["ReferralProgram_bool_exp"] | null;
      },
      ValueTypes["ReferralProgram"],
    ];
    find_Person_by_phone?: [
      {
        /** input parameters for function "find_Person_by_phone" */
        args: ValueTypes["find_Person_by_phone_args"] /** distinct select on columns */;
        distinct_on?: ValueTypes["find_Person_response_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["find_Person_response_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["find_Person_response_bool_exp"] | null;
      },
      ValueTypes["find_Person_response"],
    ];
    find_Person_response?: [
      {
        /** distinct select on columns */
        distinct_on?: ValueTypes["find_Person_response_select_column"][] /** limit the number of rows returned */;
        limit?:
          | number
          | null /** skip the first n rows. Use only with order_by */;
        offset?: number | null /** sort the rows by one or more columns */;
        order_by?: ValueTypes["find_Person_response_order_by"][] /** filter the rows returned */;
        where?: ValueTypes["find_Person_response_bool_exp"] | null;
      },
      ValueTypes["find_Person_response"],
    ];
    find_Person_response_stream?: [
      {
        /** maximum number of rows returned in a single batch */
        batch_size: number /** cursor to stream the results returned by the query */;
        cursor?: ValueTypes["find_Person_response_stream_cursor_input"][] /** filter the rows returned */;
        where?: ValueTypes["find_Person_response_bool_exp"] | null;
      },
      ValueTypes["find_Person_response"],
    ];
    __typename?: boolean;
  }>;
  ["timestamptz"]: unknown;
  /** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
  ["timestamptz_comparison_exp"]: {
    _eq?: ValueTypes["timestamptz"] | null;
    _gt?: ValueTypes["timestamptz"] | null;
    _gte?: ValueTypes["timestamptz"] | null;
    _in?: ValueTypes["timestamptz"][];
    _is_null?: boolean | null;
    _lt?: ValueTypes["timestamptz"] | null;
    _lte?: ValueTypes["timestamptz"] | null;
    _neq?: ValueTypes["timestamptz"] | null;
    _nin?: ValueTypes["timestamptz"][];
  };
  ["uuid"]: unknown;
  /** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
  ["uuid_comparison_exp"]: {
    _eq?: ValueTypes["uuid"] | null;
    _gt?: ValueTypes["uuid"] | null;
    _gte?: ValueTypes["uuid"] | null;
    _in?: ValueTypes["uuid"][];
    _is_null?: boolean | null;
    _lt?: ValueTypes["uuid"] | null;
    _lte?: ValueTypes["uuid"] | null;
    _neq?: ValueTypes["uuid"] | null;
    _nin?: ValueTypes["uuid"][];
  };
};

export type ModelTypes = {
  /** File attachments to any other object. */
  ["Attachment"]: {
    /** An object relationship */
    CreatedBy: ModelTypes["Person"];
    /** An array relationship */
    ProfilePictureFor: ModelTypes["Person"][];
    contentType?: string;
    /** Not auto generated, must be provided */
    id: ModelTypes["uuid"];
    publicUrl?: string;
  };
  /** order by aggregate values of table "Attachment" */
  ["Attachment_aggregate_order_by"]: GraphQLTypes["Attachment_aggregate_order_by"];
  /** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
  ["Attachment_bool_exp"]: GraphQLTypes["Attachment_bool_exp"];
  /** order by max() on columns of table "Attachment" */
  ["Attachment_max_order_by"]: GraphQLTypes["Attachment_max_order_by"];
  /** order by min() on columns of table "Attachment" */
  ["Attachment_min_order_by"]: GraphQLTypes["Attachment_min_order_by"];
  /** Ordering options when selecting data from "Attachment". */
  ["Attachment_order_by"]: GraphQLTypes["Attachment_order_by"];
  /** select columns of table "Attachment" */
  ["Attachment_select_column"]: GraphQLTypes["Attachment_select_column"];
  /** Streaming cursor of the table "Attachment" */
  ["Attachment_stream_cursor_input"]: GraphQLTypes["Attachment_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["Attachment_stream_cursor_value_input"]: GraphQLTypes["Attachment_stream_cursor_value_input"];
  /** columns and relationships of "Availability" */
  ["Availability"]: {
    /** An object relationship */
    Person?: ModelTypes["Person"];
    beginsAt?: ModelTypes["timestamptz"];
    endsAt?: ModelTypes["timestamptz"];
    personId?: ModelTypes["uuid"];
    serviceArea?: ModelTypes["geometry"];
  };
  /** Result of an Availability query by the perspective of a Home */
  ["AvailabilityResult"]: {
    id: ModelTypes["uuid"];
  };
  ["AvailabilityResultSource_enum"]: GraphQLTypes["AvailabilityResultSource_enum"];
  /** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
  ["AvailabilityResult_bool_exp"]: GraphQLTypes["AvailabilityResult_bool_exp"];
  /** input type for inserting data into table "AvailabilityResult" */
  ["AvailabilityResult_insert_input"]: GraphQLTypes["AvailabilityResult_insert_input"];
  /** response of any mutation on the table "AvailabilityResult" */
  ["AvailabilityResult_mutation_response"]: {
    /** number of rows affected by the mutation */
    affected_rows: number;
    /** data from the rows affected by the mutation */
    returning: ModelTypes["AvailabilityResult"][];
  };
  /** Ordering options when selecting data from "AvailabilityResult". */
  ["AvailabilityResult_order_by"]: GraphQLTypes["AvailabilityResult_order_by"];
  /** select columns of table "AvailabilityResult" */
  ["AvailabilityResult_select_column"]: GraphQLTypes["AvailabilityResult_select_column"];
  /** Streaming cursor of the table "AvailabilityResult" */
  ["AvailabilityResult_stream_cursor_input"]: GraphQLTypes["AvailabilityResult_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["AvailabilityResult_stream_cursor_value_input"]: GraphQLTypes["AvailabilityResult_stream_cursor_value_input"];
  /** order by aggregate values of table "Availability" */
  ["Availability_aggregate_order_by"]: GraphQLTypes["Availability_aggregate_order_by"];
  /** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
  ["Availability_bool_exp"]: GraphQLTypes["Availability_bool_exp"];
  /** order by max() on columns of table "Availability" */
  ["Availability_max_order_by"]: GraphQLTypes["Availability_max_order_by"];
  /** order by min() on columns of table "Availability" */
  ["Availability_min_order_by"]: GraphQLTypes["Availability_min_order_by"];
  /** Ordering options when selecting data from "Availability". */
  ["Availability_order_by"]: GraphQLTypes["Availability_order_by"];
  /** select columns of table "Availability" */
  ["Availability_select_column"]: GraphQLTypes["Availability_select_column"];
  /** Streaming cursor of the table "Availability" */
  ["Availability_stream_cursor_input"]: GraphQLTypes["Availability_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["Availability_stream_cursor_value_input"]: GraphQLTypes["Availability_stream_cursor_value_input"];
  /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
  ["Boolean_comparison_exp"]: GraphQLTypes["Boolean_comparison_exp"];
  /** columns and relationships of "ContentBlock" */
  ["ContentBlock"]: {
    body: string;
    createdAt: ModelTypes["timestamptz"];
    key: string;
    updatedAt: ModelTypes["timestamptz"];
  };
  /** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
  ["ContentBlock_bool_exp"]: GraphQLTypes["ContentBlock_bool_exp"];
  /** Ordering options when selecting data from "ContentBlock". */
  ["ContentBlock_order_by"]: GraphQLTypes["ContentBlock_order_by"];
  /** select columns of table "ContentBlock" */
  ["ContentBlock_select_column"]: GraphQLTypes["ContentBlock_select_column"];
  /** Streaming cursor of the table "ContentBlock" */
  ["ContentBlock_stream_cursor_input"]: GraphQLTypes["ContentBlock_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["ContentBlock_stream_cursor_value_input"]: GraphQLTypes["ContentBlock_stream_cursor_value_input"];
  ["CreationSourceType_enum"]: GraphQLTypes["CreationSourceType_enum"];
  /** Definition of a service area */
  ["GeographicRegion"]: {
    /** An array relationship */
    GeographicRegionHandymen: ModelTypes["GeographicRegionHandyman"][];
    /** An array relationship */
    GeographicRegionNeighborhoods: ModelTypes["GeographicRegionNeighborhood"][];
    availableStartingAt: ModelTypes["timestamptz"];
    id: ModelTypes["uuid"];
    name: string;
    servicedZipCodes: string[];
  };
  /** Handymen assigned to a geographic region */
  ["GeographicRegionHandyman"]: {
    /** An object relationship */
    GeographicRegion: ModelTypes["GeographicRegion"];
    /** An object relationship */
    Handyman: ModelTypes["Person"];
    id: ModelTypes["uuid"];
  };
  /** order by aggregate values of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_aggregate_order_by"]: GraphQLTypes["GeographicRegionHandyman_aggregate_order_by"];
  /** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
  ["GeographicRegionHandyman_bool_exp"]: GraphQLTypes["GeographicRegionHandyman_bool_exp"];
  /** order by max() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_max_order_by"]: GraphQLTypes["GeographicRegionHandyman_max_order_by"];
  /** order by min() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_min_order_by"]: GraphQLTypes["GeographicRegionHandyman_min_order_by"];
  /** Ordering options when selecting data from "GeographicRegionHandyman". */
  ["GeographicRegionHandyman_order_by"]: GraphQLTypes["GeographicRegionHandyman_order_by"];
  /** select columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_select_column"]: GraphQLTypes["GeographicRegionHandyman_select_column"];
  /** Streaming cursor of the table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_stream_cursor_input"]: GraphQLTypes["GeographicRegionHandyman_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionHandyman_stream_cursor_value_input"]: GraphQLTypes["GeographicRegionHandyman_stream_cursor_value_input"];
  /** Separates large population zip codes into regions. Used for first time onboarding. */
  ["GeographicRegionNeighborhood"]: {
    /** An object relationship */
    GeographicRegion: ModelTypes["GeographicRegion"];
    id: ModelTypes["uuid"];
    name: string;
    zipCode: string;
  };
  /** order by aggregate values of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_aggregate_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_aggregate_order_by"];
  /** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
  ["GeographicRegionNeighborhood_bool_exp"]: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"];
  /** order by max() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_max_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_max_order_by"];
  /** order by min() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_min_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_min_order_by"];
  /** Ordering options when selecting data from "GeographicRegionNeighborhood". */
  ["GeographicRegionNeighborhood_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_order_by"];
  /** select columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_select_column"]: GraphQLTypes["GeographicRegionNeighborhood_select_column"];
  /** Streaming cursor of the table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_stream_cursor_input"]: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionNeighborhood_stream_cursor_value_input"]: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_value_input"];
  /** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
  ["GeographicRegion_bool_exp"]: GraphQLTypes["GeographicRegion_bool_exp"];
  ["GeographicRegion_by_zipCode_args"]: GraphQLTypes["GeographicRegion_by_zipCode_args"];
  /** Ordering options when selecting data from "GeographicRegion". */
  ["GeographicRegion_order_by"]: GraphQLTypes["GeographicRegion_order_by"];
  /** select columns of table "GeographicRegion" */
  ["GeographicRegion_select_column"]: GraphQLTypes["GeographicRegion_select_column"];
  /** Streaming cursor of the table "GeographicRegion" */
  ["GeographicRegion_stream_cursor_input"]: GraphQLTypes["GeographicRegion_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegion_stream_cursor_value_input"]: GraphQLTypes["GeographicRegion_stream_cursor_value_input"];
  /** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
  ["Int_comparison_exp"]: GraphQLTypes["Int_comparison_exp"];
  /** columns and relationships of "MarketingLead" */
  ["MarketingLead"]: {
    id: ModelTypes["uuid"];
  };
  /** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
  ["MarketingLead_bool_exp"]: GraphQLTypes["MarketingLead_bool_exp"];
  /** unique or primary key constraints on table "MarketingLead" */
  ["MarketingLead_constraint"]: GraphQLTypes["MarketingLead_constraint"];
  /** input type for inserting data into table "MarketingLead" */
  ["MarketingLead_insert_input"]: GraphQLTypes["MarketingLead_insert_input"];
  /** response of any mutation on the table "MarketingLead" */
  ["MarketingLead_mutation_response"]: {
    /** number of rows affected by the mutation */
    affected_rows: number;
    /** data from the rows affected by the mutation */
    returning: ModelTypes["MarketingLead"][];
  };
  /** on_conflict condition type for table "MarketingLead" */
  ["MarketingLead_on_conflict"]: GraphQLTypes["MarketingLead_on_conflict"];
  /** Ordering options when selecting data from "MarketingLead". */
  ["MarketingLead_order_by"]: GraphQLTypes["MarketingLead_order_by"];
  /** primary key columns input for table: MarketingLead */
  ["MarketingLead_pk_columns_input"]: GraphQLTypes["MarketingLead_pk_columns_input"];
  /** select columns of table "MarketingLead" */
  ["MarketingLead_select_column"]: GraphQLTypes["MarketingLead_select_column"];
  /** input type for updating data in table "MarketingLead" */
  ["MarketingLead_set_input"]: GraphQLTypes["MarketingLead_set_input"];
  /** Streaming cursor of the table "MarketingLead" */
  ["MarketingLead_stream_cursor_input"]: GraphQLTypes["MarketingLead_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["MarketingLead_stream_cursor_value_input"]: GraphQLTypes["MarketingLead_stream_cursor_value_input"];
  /** update columns of table "MarketingLead" */
  ["MarketingLead_update_column"]: GraphQLTypes["MarketingLead_update_column"];
  ["MarketingLead_updates"]: GraphQLTypes["MarketingLead_updates"];
  /** columns and relationships of "Person" */
  ["Person"]: {
    /** An array relationship */
    Availability: ModelTypes["Availability"][];
    /** An array relationship */
    CreatedAttachments: ModelTypes["Attachment"][];
    /** An object relationship */
    ProfilePicture?: ModelTypes["Attachment"];
    firstName: string;
    id: ModelTypes["uuid"];
  };
  /** order by aggregate values of table "Person" */
  ["Person_aggregate_order_by"]: GraphQLTypes["Person_aggregate_order_by"];
  /** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
  ["Person_bool_exp"]: GraphQLTypes["Person_bool_exp"];
  /** order by max() on columns of table "Person" */
  ["Person_max_order_by"]: GraphQLTypes["Person_max_order_by"];
  /** order by min() on columns of table "Person" */
  ["Person_min_order_by"]: GraphQLTypes["Person_min_order_by"];
  /** Ordering options when selecting data from "Person". */
  ["Person_order_by"]: GraphQLTypes["Person_order_by"];
  /** select columns of table "Person" */
  ["Person_select_column"]: GraphQLTypes["Person_select_column"];
  /** Streaming cursor of the table "Person" */
  ["Person_stream_cursor_input"]: GraphQLTypes["Person_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["Person_stream_cursor_value_input"]: GraphQLTypes["Person_stream_cursor_value_input"];
  /** Currently active and historical referral programs */
  ["ReferralProgram"]: {
    creditAmount: number;
  };
  /** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
  ["ReferralProgram_bool_exp"]: GraphQLTypes["ReferralProgram_bool_exp"];
  /** Ordering options when selecting data from "ReferralProgram". */
  ["ReferralProgram_order_by"]: GraphQLTypes["ReferralProgram_order_by"];
  /** select columns of table "ReferralProgram" */
  ["ReferralProgram_select_column"]: GraphQLTypes["ReferralProgram_select_column"];
  /** Streaming cursor of the table "ReferralProgram" */
  ["ReferralProgram_stream_cursor_input"]: GraphQLTypes["ReferralProgram_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["ReferralProgram_stream_cursor_value_input"]: GraphQLTypes["ReferralProgram_stream_cursor_value_input"];
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_array_comparison_exp"]: GraphQLTypes["String_array_comparison_exp"];
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_comparison_exp"]: GraphQLTypes["String_comparison_exp"];
  /** ordering argument of a cursor */
  ["cursor_ordering"]: GraphQLTypes["cursor_ordering"];
  ["find_Person_by_phone_args"]: GraphQLTypes["find_Person_by_phone_args"];
  /** columns and relationships of "find_Person_response" */
  ["find_Person_response"]: {
    present?: boolean;
  };
  /** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
  ["find_Person_response_bool_exp"]: GraphQLTypes["find_Person_response_bool_exp"];
  /** Ordering options when selecting data from "find_Person_response". */
  ["find_Person_response_order_by"]: GraphQLTypes["find_Person_response_order_by"];
  /** select columns of table "find_Person_response" */
  ["find_Person_response_select_column"]: GraphQLTypes["find_Person_response_select_column"];
  /** Streaming cursor of the table "find_Person_response" */
  ["find_Person_response_stream_cursor_input"]: GraphQLTypes["find_Person_response_stream_cursor_input"];
  /** Initial value of the column from where the streaming should start */
  ["find_Person_response_stream_cursor_value_input"]: GraphQLTypes["find_Person_response_stream_cursor_value_input"];
  ["geography"]: any;
  ["geography_cast_exp"]: GraphQLTypes["geography_cast_exp"];
  /** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
  ["geography_comparison_exp"]: GraphQLTypes["geography_comparison_exp"];
  ["geometry"]: any;
  ["geometry_cast_exp"]: GraphQLTypes["geometry_cast_exp"];
  /** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
  ["geometry_comparison_exp"]: GraphQLTypes["geometry_comparison_exp"];
  ["jsonb"]: any;
  /** mutation root */
  ["mutation_root"]: {
    /** insert data into the table: "AvailabilityResult" */
    insert_AvailabilityResult?: ModelTypes["AvailabilityResult_mutation_response"];
    /** insert a single row into the table: "AvailabilityResult" */
    insert_AvailabilityResult_one?: ModelTypes["AvailabilityResult"];
    /** insert data into the table: "MarketingLead" */
    insert_MarketingLead?: ModelTypes["MarketingLead_mutation_response"];
    /** insert a single row into the table: "MarketingLead" */
    insert_MarketingLead_one?: ModelTypes["MarketingLead"];
    /** update data of the table: "MarketingLead" */
    update_MarketingLead?: ModelTypes["MarketingLead_mutation_response"];
    /** update single row of the table: "MarketingLead" */
    update_MarketingLead_by_pk?: ModelTypes["MarketingLead"];
    /** update multiples rows of table: "MarketingLead" */
    update_MarketingLead_many?: (
      | ModelTypes["MarketingLead_mutation_response"]
      | undefined
    )[];
  };
  /** column ordering options */
  ["order_by"]: GraphQLTypes["order_by"];
  ["query_root"]: {
    /** fetch data from the table: "Attachment" */
    Attachment: ModelTypes["Attachment"][];
    /** fetch data from the table: "Attachment" using primary key columns */
    Attachment_by_pk?: ModelTypes["Attachment"];
    /** An array relationship */
    Availability: ModelTypes["Availability"][];
    /** fetch data from the table: "AvailabilityResult" */
    AvailabilityResult: ModelTypes["AvailabilityResult"][];
    /** fetch data from the table: "ContentBlock" */
    ContentBlock: ModelTypes["ContentBlock"][];
    /** fetch data from the table: "ContentBlock" using primary key columns */
    ContentBlock_by_pk?: ModelTypes["ContentBlock"];
    /** fetch data from the table: "GeographicRegion" */
    GeographicRegion: ModelTypes["GeographicRegion"][];
    /** fetch data from the table: "GeographicRegionHandyman" */
    GeographicRegionHandyman: ModelTypes["GeographicRegionHandyman"][];
    /** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
    GeographicRegionHandyman_by_pk?: ModelTypes["GeographicRegionHandyman"];
    /** fetch data from the table: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood: ModelTypes["GeographicRegionNeighborhood"][];
    /** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
    GeographicRegionNeighborhood_by_pk?: ModelTypes["GeographicRegionNeighborhood"];
    /** fetch data from the table: "GeographicRegion" using primary key columns */
    GeographicRegion_by_pk?: ModelTypes["GeographicRegion"];
    /** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
    GeographicRegion_by_zipCode: ModelTypes["GeographicRegion"][];
    /** fetch data from the table: "MarketingLead" */
    MarketingLead: ModelTypes["MarketingLead"][];
    /** fetch data from the table: "MarketingLead" using primary key columns */
    MarketingLead_by_pk?: ModelTypes["MarketingLead"];
    /** fetch data from the table: "Person" */
    Person: ModelTypes["Person"][];
    /** fetch data from the table: "Person" using primary key columns */
    Person_by_pk?: ModelTypes["Person"];
    /** fetch data from the table: "ReferralProgram" */
    ReferralProgram: ModelTypes["ReferralProgram"][];
    /** execute function "find_Person_by_phone" which returns "find_Person_response" */
    find_Person_by_phone?: ModelTypes["find_Person_response"];
    /** fetch data from the table: "find_Person_response" */
    find_Person_response: ModelTypes["find_Person_response"][];
  };
  ["st_d_within_geography_input"]: GraphQLTypes["st_d_within_geography_input"];
  ["st_d_within_input"]: GraphQLTypes["st_d_within_input"];
  ["subscription_root"]: {
    /** fetch data from the table: "Attachment" */
    Attachment: ModelTypes["Attachment"][];
    /** fetch data from the table: "Attachment" using primary key columns */
    Attachment_by_pk?: ModelTypes["Attachment"];
    /** fetch data from the table in a streaming manner: "Attachment" */
    Attachment_stream: ModelTypes["Attachment"][];
    /** An array relationship */
    Availability: ModelTypes["Availability"][];
    /** fetch data from the table: "AvailabilityResult" */
    AvailabilityResult: ModelTypes["AvailabilityResult"][];
    /** fetch data from the table in a streaming manner: "AvailabilityResult" */
    AvailabilityResult_stream: ModelTypes["AvailabilityResult"][];
    /** fetch data from the table in a streaming manner: "Availability" */
    Availability_stream: ModelTypes["Availability"][];
    /** fetch data from the table: "ContentBlock" */
    ContentBlock: ModelTypes["ContentBlock"][];
    /** fetch data from the table: "ContentBlock" using primary key columns */
    ContentBlock_by_pk?: ModelTypes["ContentBlock"];
    /** fetch data from the table in a streaming manner: "ContentBlock" */
    ContentBlock_stream: ModelTypes["ContentBlock"][];
    /** fetch data from the table: "GeographicRegion" */
    GeographicRegion: ModelTypes["GeographicRegion"][];
    /** fetch data from the table: "GeographicRegionHandyman" */
    GeographicRegionHandyman: ModelTypes["GeographicRegionHandyman"][];
    /** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
    GeographicRegionHandyman_by_pk?: ModelTypes["GeographicRegionHandyman"];
    /** fetch data from the table in a streaming manner: "GeographicRegionHandyman" */
    GeographicRegionHandyman_stream: ModelTypes["GeographicRegionHandyman"][];
    /** fetch data from the table: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood: ModelTypes["GeographicRegionNeighborhood"][];
    /** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
    GeographicRegionNeighborhood_by_pk?: ModelTypes["GeographicRegionNeighborhood"];
    /** fetch data from the table in a streaming manner: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood_stream: ModelTypes["GeographicRegionNeighborhood"][];
    /** fetch data from the table: "GeographicRegion" using primary key columns */
    GeographicRegion_by_pk?: ModelTypes["GeographicRegion"];
    /** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
    GeographicRegion_by_zipCode: ModelTypes["GeographicRegion"][];
    /** fetch data from the table in a streaming manner: "GeographicRegion" */
    GeographicRegion_stream: ModelTypes["GeographicRegion"][];
    /** fetch data from the table: "MarketingLead" */
    MarketingLead: ModelTypes["MarketingLead"][];
    /** fetch data from the table: "MarketingLead" using primary key columns */
    MarketingLead_by_pk?: ModelTypes["MarketingLead"];
    /** fetch data from the table in a streaming manner: "MarketingLead" */
    MarketingLead_stream: ModelTypes["MarketingLead"][];
    /** fetch data from the table: "Person" */
    Person: ModelTypes["Person"][];
    /** fetch data from the table: "Person" using primary key columns */
    Person_by_pk?: ModelTypes["Person"];
    /** fetch data from the table in a streaming manner: "Person" */
    Person_stream: ModelTypes["Person"][];
    /** fetch data from the table: "ReferralProgram" */
    ReferralProgram: ModelTypes["ReferralProgram"][];
    /** fetch data from the table in a streaming manner: "ReferralProgram" */
    ReferralProgram_stream: ModelTypes["ReferralProgram"][];
    /** execute function "find_Person_by_phone" which returns "find_Person_response" */
    find_Person_by_phone?: ModelTypes["find_Person_response"];
    /** fetch data from the table: "find_Person_response" */
    find_Person_response: ModelTypes["find_Person_response"][];
    /** fetch data from the table in a streaming manner: "find_Person_response" */
    find_Person_response_stream: ModelTypes["find_Person_response"][];
  };
  ["timestamptz"]: any;
  /** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
  ["timestamptz_comparison_exp"]: GraphQLTypes["timestamptz_comparison_exp"];
  ["uuid"]: any;
  /** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
  ["uuid_comparison_exp"]: GraphQLTypes["uuid_comparison_exp"];
};

export type GraphQLTypes = {
  /** File attachments to any other object. */
  ["Attachment"]: {
    __typename: "Attachment";
    /** An object relationship */
    CreatedBy: GraphQLTypes["Person"];
    /** An array relationship */
    ProfilePictureFor: Array<GraphQLTypes["Person"]>;
    contentType?: string;
    /** Not auto generated, must be provided */
    id: GraphQLTypes["uuid"];
    publicUrl?: string;
  };
  /** order by aggregate values of table "Attachment" */
  ["Attachment_aggregate_order_by"]: {
    count?: GraphQLTypes["order_by"];
    max?: GraphQLTypes["Attachment_max_order_by"];
    min?: GraphQLTypes["Attachment_min_order_by"];
  };
  /** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
  ["Attachment_bool_exp"]: {
    CreatedBy?: GraphQLTypes["Person_bool_exp"];
    ProfilePictureFor?: GraphQLTypes["Person_bool_exp"];
    _and?: Array<GraphQLTypes["Attachment_bool_exp"]>;
    _not?: GraphQLTypes["Attachment_bool_exp"];
    _or?: Array<GraphQLTypes["Attachment_bool_exp"]>;
    contentType?: GraphQLTypes["String_comparison_exp"];
    id?: GraphQLTypes["uuid_comparison_exp"];
    publicUrl?: GraphQLTypes["String_comparison_exp"];
  };
  /** order by max() on columns of table "Attachment" */
  ["Attachment_max_order_by"]: {
    contentType?: GraphQLTypes["order_by"];
    /** Not auto generated, must be provided */
    id?: GraphQLTypes["order_by"];
    publicUrl?: GraphQLTypes["order_by"];
  };
  /** order by min() on columns of table "Attachment" */
  ["Attachment_min_order_by"]: {
    contentType?: GraphQLTypes["order_by"];
    /** Not auto generated, must be provided */
    id?: GraphQLTypes["order_by"];
    publicUrl?: GraphQLTypes["order_by"];
  };
  /** Ordering options when selecting data from "Attachment". */
  ["Attachment_order_by"]: {
    CreatedBy?: GraphQLTypes["Person_order_by"];
    ProfilePictureFor_aggregate?: GraphQLTypes["Person_aggregate_order_by"];
    contentType?: GraphQLTypes["order_by"];
    id?: GraphQLTypes["order_by"];
    publicUrl?: GraphQLTypes["order_by"];
  };
  /** select columns of table "Attachment" */
  ["Attachment_select_column"]: Attachment_select_column;
  /** Streaming cursor of the table "Attachment" */
  ["Attachment_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["Attachment_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["Attachment_stream_cursor_value_input"]: {
    contentType?: string;
    /** Not auto generated, must be provided */
    id?: GraphQLTypes["uuid"];
    publicUrl?: string;
  };
  /** columns and relationships of "Availability" */
  ["Availability"]: {
    __typename: "Availability";
    /** An object relationship */
    Person?: GraphQLTypes["Person"];
    beginsAt?: GraphQLTypes["timestamptz"];
    endsAt?: GraphQLTypes["timestamptz"];
    personId?: GraphQLTypes["uuid"];
    serviceArea?: GraphQLTypes["geometry"];
  };
  /** Result of an Availability query by the perspective of a Home */
  ["AvailabilityResult"]: {
    __typename: "AvailabilityResult";
    id: GraphQLTypes["uuid"];
  };
  ["AvailabilityResultSource_enum"]: AvailabilityResultSource_enum;
  /** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
  ["AvailabilityResult_bool_exp"]: {
    _and?: Array<GraphQLTypes["AvailabilityResult_bool_exp"]>;
    _not?: GraphQLTypes["AvailabilityResult_bool_exp"];
    _or?: Array<GraphQLTypes["AvailabilityResult_bool_exp"]>;
    id?: GraphQLTypes["uuid_comparison_exp"];
  };
  /** input type for inserting data into table "AvailabilityResult" */
  ["AvailabilityResult_insert_input"]: {
    creationSource?: GraphQLTypes["CreationSourceType_enum"];
    data?: GraphQLTypes["jsonb"];
    source?: GraphQLTypes["AvailabilityResultSource_enum"];
  };
  /** response of any mutation on the table "AvailabilityResult" */
  ["AvailabilityResult_mutation_response"]: {
    __typename: "AvailabilityResult_mutation_response";
    /** number of rows affected by the mutation */
    affected_rows: number;
    /** data from the rows affected by the mutation */
    returning: Array<GraphQLTypes["AvailabilityResult"]>;
  };
  /** Ordering options when selecting data from "AvailabilityResult". */
  ["AvailabilityResult_order_by"]: {
    id?: GraphQLTypes["order_by"];
  };
  /** select columns of table "AvailabilityResult" */
  ["AvailabilityResult_select_column"]: AvailabilityResult_select_column;
  /** Streaming cursor of the table "AvailabilityResult" */
  ["AvailabilityResult_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["AvailabilityResult_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["AvailabilityResult_stream_cursor_value_input"]: {
    id?: GraphQLTypes["uuid"];
  };
  /** order by aggregate values of table "Availability" */
  ["Availability_aggregate_order_by"]: {
    count?: GraphQLTypes["order_by"];
    max?: GraphQLTypes["Availability_max_order_by"];
    min?: GraphQLTypes["Availability_min_order_by"];
  };
  /** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
  ["Availability_bool_exp"]: {
    Person?: GraphQLTypes["Person_bool_exp"];
    _and?: Array<GraphQLTypes["Availability_bool_exp"]>;
    _not?: GraphQLTypes["Availability_bool_exp"];
    _or?: Array<GraphQLTypes["Availability_bool_exp"]>;
    beginsAt?: GraphQLTypes["timestamptz_comparison_exp"];
    endsAt?: GraphQLTypes["timestamptz_comparison_exp"];
    personId?: GraphQLTypes["uuid_comparison_exp"];
    serviceArea?: GraphQLTypes["geometry_comparison_exp"];
  };
  /** order by max() on columns of table "Availability" */
  ["Availability_max_order_by"]: {
    beginsAt?: GraphQLTypes["order_by"];
    endsAt?: GraphQLTypes["order_by"];
    personId?: GraphQLTypes["order_by"];
  };
  /** order by min() on columns of table "Availability" */
  ["Availability_min_order_by"]: {
    beginsAt?: GraphQLTypes["order_by"];
    endsAt?: GraphQLTypes["order_by"];
    personId?: GraphQLTypes["order_by"];
  };
  /** Ordering options when selecting data from "Availability". */
  ["Availability_order_by"]: {
    Person?: GraphQLTypes["Person_order_by"];
    beginsAt?: GraphQLTypes["order_by"];
    endsAt?: GraphQLTypes["order_by"];
    personId?: GraphQLTypes["order_by"];
    serviceArea?: GraphQLTypes["order_by"];
  };
  /** select columns of table "Availability" */
  ["Availability_select_column"]: Availability_select_column;
  /** Streaming cursor of the table "Availability" */
  ["Availability_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["Availability_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["Availability_stream_cursor_value_input"]: {
    beginsAt?: GraphQLTypes["timestamptz"];
    endsAt?: GraphQLTypes["timestamptz"];
    personId?: GraphQLTypes["uuid"];
    serviceArea?: GraphQLTypes["geometry"];
  };
  /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
  ["Boolean_comparison_exp"]: {
    _eq?: boolean;
    _gt?: boolean;
    _gte?: boolean;
    _in?: Array<boolean>;
    _is_null?: boolean;
    _lt?: boolean;
    _lte?: boolean;
    _neq?: boolean;
    _nin?: Array<boolean>;
  };
  /** columns and relationships of "ContentBlock" */
  ["ContentBlock"]: {
    __typename: "ContentBlock";
    body: string;
    createdAt: GraphQLTypes["timestamptz"];
    key: string;
    updatedAt: GraphQLTypes["timestamptz"];
  };
  /** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
  ["ContentBlock_bool_exp"]: {
    _and?: Array<GraphQLTypes["ContentBlock_bool_exp"]>;
    _not?: GraphQLTypes["ContentBlock_bool_exp"];
    _or?: Array<GraphQLTypes["ContentBlock_bool_exp"]>;
    body?: GraphQLTypes["String_comparison_exp"];
    createdAt?: GraphQLTypes["timestamptz_comparison_exp"];
    key?: GraphQLTypes["String_comparison_exp"];
    updatedAt?: GraphQLTypes["timestamptz_comparison_exp"];
  };
  /** Ordering options when selecting data from "ContentBlock". */
  ["ContentBlock_order_by"]: {
    body?: GraphQLTypes["order_by"];
    createdAt?: GraphQLTypes["order_by"];
    key?: GraphQLTypes["order_by"];
    updatedAt?: GraphQLTypes["order_by"];
  };
  /** select columns of table "ContentBlock" */
  ["ContentBlock_select_column"]: ContentBlock_select_column;
  /** Streaming cursor of the table "ContentBlock" */
  ["ContentBlock_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["ContentBlock_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["ContentBlock_stream_cursor_value_input"]: {
    body?: string;
    createdAt?: GraphQLTypes["timestamptz"];
    key?: string;
    updatedAt?: GraphQLTypes["timestamptz"];
  };
  ["CreationSourceType_enum"]: CreationSourceType_enum;
  /** Definition of a service area */
  ["GeographicRegion"]: {
    __typename: "GeographicRegion";
    /** An array relationship */
    GeographicRegionHandymen: Array<GraphQLTypes["GeographicRegionHandyman"]>;
    /** An array relationship */
    GeographicRegionNeighborhoods: Array<
      GraphQLTypes["GeographicRegionNeighborhood"]
    >;
    availableStartingAt: GraphQLTypes["timestamptz"];
    id: GraphQLTypes["uuid"];
    name: string;
    servicedZipCodes: Array<string>;
  };
  /** Handymen assigned to a geographic region */
  ["GeographicRegionHandyman"]: {
    __typename: "GeographicRegionHandyman";
    /** An object relationship */
    GeographicRegion: GraphQLTypes["GeographicRegion"];
    /** An object relationship */
    Handyman: GraphQLTypes["Person"];
    id: GraphQLTypes["uuid"];
  };
  /** order by aggregate values of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_aggregate_order_by"]: {
    count?: GraphQLTypes["order_by"];
    max?: GraphQLTypes["GeographicRegionHandyman_max_order_by"];
    min?: GraphQLTypes["GeographicRegionHandyman_min_order_by"];
  };
  /** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
  ["GeographicRegionHandyman_bool_exp"]: {
    GeographicRegion?: GraphQLTypes["GeographicRegion_bool_exp"];
    Handyman?: GraphQLTypes["Person_bool_exp"];
    _and?: Array<GraphQLTypes["GeographicRegionHandyman_bool_exp"]>;
    _not?: GraphQLTypes["GeographicRegionHandyman_bool_exp"];
    _or?: Array<GraphQLTypes["GeographicRegionHandyman_bool_exp"]>;
    id?: GraphQLTypes["uuid_comparison_exp"];
  };
  /** order by max() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_max_order_by"]: {
    id?: GraphQLTypes["order_by"];
  };
  /** order by min() on columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_min_order_by"]: {
    id?: GraphQLTypes["order_by"];
  };
  /** Ordering options when selecting data from "GeographicRegionHandyman". */
  ["GeographicRegionHandyman_order_by"]: {
    GeographicRegion?: GraphQLTypes["GeographicRegion_order_by"];
    Handyman?: GraphQLTypes["Person_order_by"];
    id?: GraphQLTypes["order_by"];
  };
  /** select columns of table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_select_column"]: GeographicRegionHandyman_select_column;
  /** Streaming cursor of the table "GeographicRegionHandyman" */
  ["GeographicRegionHandyman_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["GeographicRegionHandyman_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionHandyman_stream_cursor_value_input"]: {
    id?: GraphQLTypes["uuid"];
  };
  /** Separates large population zip codes into regions. Used for first time onboarding. */
  ["GeographicRegionNeighborhood"]: {
    __typename: "GeographicRegionNeighborhood";
    /** An object relationship */
    GeographicRegion: GraphQLTypes["GeographicRegion"];
    id: GraphQLTypes["uuid"];
    name: string;
    zipCode: string;
  };
  /** order by aggregate values of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_aggregate_order_by"]: {
    count?: GraphQLTypes["order_by"];
    max?: GraphQLTypes["GeographicRegionNeighborhood_max_order_by"];
    min?: GraphQLTypes["GeographicRegionNeighborhood_min_order_by"];
  };
  /** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
  ["GeographicRegionNeighborhood_bool_exp"]: {
    GeographicRegion?: GraphQLTypes["GeographicRegion_bool_exp"];
    _and?: Array<GraphQLTypes["GeographicRegionNeighborhood_bool_exp"]>;
    _not?: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"];
    _or?: Array<GraphQLTypes["GeographicRegionNeighborhood_bool_exp"]>;
    id?: GraphQLTypes["uuid_comparison_exp"];
    name?: GraphQLTypes["String_comparison_exp"];
    zipCode?: GraphQLTypes["String_comparison_exp"];
  };
  /** order by max() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_max_order_by"]: {
    id?: GraphQLTypes["order_by"];
    name?: GraphQLTypes["order_by"];
    zipCode?: GraphQLTypes["order_by"];
  };
  /** order by min() on columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_min_order_by"]: {
    id?: GraphQLTypes["order_by"];
    name?: GraphQLTypes["order_by"];
    zipCode?: GraphQLTypes["order_by"];
  };
  /** Ordering options when selecting data from "GeographicRegionNeighborhood". */
  ["GeographicRegionNeighborhood_order_by"]: {
    GeographicRegion?: GraphQLTypes["GeographicRegion_order_by"];
    id?: GraphQLTypes["order_by"];
    name?: GraphQLTypes["order_by"];
    zipCode?: GraphQLTypes["order_by"];
  };
  /** select columns of table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_select_column"]: GeographicRegionNeighborhood_select_column;
  /** Streaming cursor of the table "GeographicRegionNeighborhood" */
  ["GeographicRegionNeighborhood_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegionNeighborhood_stream_cursor_value_input"]: {
    id?: GraphQLTypes["uuid"];
    name?: string;
    zipCode?: string;
  };
  /** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
  ["GeographicRegion_bool_exp"]: {
    GeographicRegionHandymen?: GraphQLTypes["GeographicRegionHandyman_bool_exp"];
    GeographicRegionNeighborhoods?: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"];
    _and?: Array<GraphQLTypes["GeographicRegion_bool_exp"]>;
    _not?: GraphQLTypes["GeographicRegion_bool_exp"];
    _or?: Array<GraphQLTypes["GeographicRegion_bool_exp"]>;
    availableStartingAt?: GraphQLTypes["timestamptz_comparison_exp"];
    id?: GraphQLTypes["uuid_comparison_exp"];
    name?: GraphQLTypes["String_comparison_exp"];
    servicedZipCodes?: GraphQLTypes["String_array_comparison_exp"];
  };
  ["GeographicRegion_by_zipCode_args"]: {
    zipCode?: string;
  };
  /** Ordering options when selecting data from "GeographicRegion". */
  ["GeographicRegion_order_by"]: {
    GeographicRegionHandymen_aggregate?: GraphQLTypes["GeographicRegionHandyman_aggregate_order_by"];
    GeographicRegionNeighborhoods_aggregate?: GraphQLTypes["GeographicRegionNeighborhood_aggregate_order_by"];
    availableStartingAt?: GraphQLTypes["order_by"];
    id?: GraphQLTypes["order_by"];
    name?: GraphQLTypes["order_by"];
    servicedZipCodes?: GraphQLTypes["order_by"];
  };
  /** select columns of table "GeographicRegion" */
  ["GeographicRegion_select_column"]: GeographicRegion_select_column;
  /** Streaming cursor of the table "GeographicRegion" */
  ["GeographicRegion_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["GeographicRegion_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["GeographicRegion_stream_cursor_value_input"]: {
    availableStartingAt?: GraphQLTypes["timestamptz"];
    id?: GraphQLTypes["uuid"];
    name?: string;
    servicedZipCodes?: Array<string>;
  };
  /** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
  ["Int_comparison_exp"]: {
    _eq?: number;
    _gt?: number;
    _gte?: number;
    _in?: Array<number>;
    _is_null?: boolean;
    _lt?: number;
    _lte?: number;
    _neq?: number;
    _nin?: Array<number>;
  };
  /** columns and relationships of "MarketingLead" */
  ["MarketingLead"]: {
    __typename: "MarketingLead";
    id: GraphQLTypes["uuid"];
  };
  /** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
  ["MarketingLead_bool_exp"]: {
    _and?: Array<GraphQLTypes["MarketingLead_bool_exp"]>;
    _not?: GraphQLTypes["MarketingLead_bool_exp"];
    _or?: Array<GraphQLTypes["MarketingLead_bool_exp"]>;
    id?: GraphQLTypes["uuid_comparison_exp"];
  };
  /** unique or primary key constraints on table "MarketingLead" */
  ["MarketingLead_constraint"]: MarketingLead_constraint;
  /** input type for inserting data into table "MarketingLead" */
  ["MarketingLead_insert_input"]: {
    city?: string;
    email?: string;
    firstAppointmentBeginsAt?: GraphQLTypes["timestamptz"];
    firstAppointmentEndsAt?: GraphQLTypes["timestamptz"];
    firstName?: string;
    homeAccountCreated?: boolean;
    lastName?: string;
    organicSource?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    state?: string;
    streetAddress?: string;
    utmCampaign?: string;
    utmContent?: string;
    utmId?: string;
    utmMedium?: string;
    utmSource?: string;
    utmSourcePlatform?: string;
    utmTerm?: string;
    zipCode?: string;
  };
  /** response of any mutation on the table "MarketingLead" */
  ["MarketingLead_mutation_response"]: {
    __typename: "MarketingLead_mutation_response";
    /** number of rows affected by the mutation */
    affected_rows: number;
    /** data from the rows affected by the mutation */
    returning: Array<GraphQLTypes["MarketingLead"]>;
  };
  /** on_conflict condition type for table "MarketingLead" */
  ["MarketingLead_on_conflict"]: {
    constraint: GraphQLTypes["MarketingLead_constraint"];
    update_columns: Array<GraphQLTypes["MarketingLead_update_column"]>;
    where?: GraphQLTypes["MarketingLead_bool_exp"];
  };
  /** Ordering options when selecting data from "MarketingLead". */
  ["MarketingLead_order_by"]: {
    id?: GraphQLTypes["order_by"];
  };
  /** primary key columns input for table: MarketingLead */
  ["MarketingLead_pk_columns_input"]: {
    id: GraphQLTypes["uuid"];
  };
  /** select columns of table "MarketingLead" */
  ["MarketingLead_select_column"]: MarketingLead_select_column;
  /** input type for updating data in table "MarketingLead" */
  ["MarketingLead_set_input"]: {
    city?: string;
    email?: string;
    firstAppointmentBeginsAt?: GraphQLTypes["timestamptz"];
    firstAppointmentEndsAt?: GraphQLTypes["timestamptz"];
    firstName?: string;
    homeAccountCreated?: boolean;
    lastName?: string;
    organicSource?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    state?: string;
    streetAddress?: string;
    utmCampaign?: string;
    utmContent?: string;
    utmId?: string;
    utmMedium?: string;
    utmSource?: string;
    utmSourcePlatform?: string;
    utmTerm?: string;
    zipCode?: string;
  };
  /** Streaming cursor of the table "MarketingLead" */
  ["MarketingLead_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["MarketingLead_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["MarketingLead_stream_cursor_value_input"]: {
    id?: GraphQLTypes["uuid"];
  };
  /** update columns of table "MarketingLead" */
  ["MarketingLead_update_column"]: MarketingLead_update_column;
  ["MarketingLead_updates"]: {
    /** sets the columns of the filtered rows to the given values */
    _set?: GraphQLTypes["MarketingLead_set_input"];
    /** filter the rows which have to be updated */
    where: GraphQLTypes["MarketingLead_bool_exp"];
  };
  /** columns and relationships of "Person" */
  ["Person"]: {
    __typename: "Person";
    /** An array relationship */
    Availability: Array<GraphQLTypes["Availability"]>;
    /** An array relationship */
    CreatedAttachments: Array<GraphQLTypes["Attachment"]>;
    /** An object relationship */
    ProfilePicture?: GraphQLTypes["Attachment"];
    firstName: string;
    id: GraphQLTypes["uuid"];
  };
  /** order by aggregate values of table "Person" */
  ["Person_aggregate_order_by"]: {
    count?: GraphQLTypes["order_by"];
    max?: GraphQLTypes["Person_max_order_by"];
    min?: GraphQLTypes["Person_min_order_by"];
  };
  /** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
  ["Person_bool_exp"]: {
    Availability?: GraphQLTypes["Availability_bool_exp"];
    CreatedAttachments?: GraphQLTypes["Attachment_bool_exp"];
    ProfilePicture?: GraphQLTypes["Attachment_bool_exp"];
    _and?: Array<GraphQLTypes["Person_bool_exp"]>;
    _not?: GraphQLTypes["Person_bool_exp"];
    _or?: Array<GraphQLTypes["Person_bool_exp"]>;
    firstName?: GraphQLTypes["String_comparison_exp"];
    id?: GraphQLTypes["uuid_comparison_exp"];
  };
  /** order by max() on columns of table "Person" */
  ["Person_max_order_by"]: {
    firstName?: GraphQLTypes["order_by"];
    id?: GraphQLTypes["order_by"];
  };
  /** order by min() on columns of table "Person" */
  ["Person_min_order_by"]: {
    firstName?: GraphQLTypes["order_by"];
    id?: GraphQLTypes["order_by"];
  };
  /** Ordering options when selecting data from "Person". */
  ["Person_order_by"]: {
    Availability_aggregate?: GraphQLTypes["Availability_aggregate_order_by"];
    CreatedAttachments_aggregate?: GraphQLTypes["Attachment_aggregate_order_by"];
    ProfilePicture?: GraphQLTypes["Attachment_order_by"];
    firstName?: GraphQLTypes["order_by"];
    id?: GraphQLTypes["order_by"];
  };
  /** select columns of table "Person" */
  ["Person_select_column"]: Person_select_column;
  /** Streaming cursor of the table "Person" */
  ["Person_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["Person_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["Person_stream_cursor_value_input"]: {
    firstName?: string;
    id?: GraphQLTypes["uuid"];
  };
  /** Currently active and historical referral programs */
  ["ReferralProgram"]: {
    __typename: "ReferralProgram";
    creditAmount: number;
  };
  /** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
  ["ReferralProgram_bool_exp"]: {
    _and?: Array<GraphQLTypes["ReferralProgram_bool_exp"]>;
    _not?: GraphQLTypes["ReferralProgram_bool_exp"];
    _or?: Array<GraphQLTypes["ReferralProgram_bool_exp"]>;
    creditAmount?: GraphQLTypes["Int_comparison_exp"];
  };
  /** Ordering options when selecting data from "ReferralProgram". */
  ["ReferralProgram_order_by"]: {
    creditAmount?: GraphQLTypes["order_by"];
  };
  /** select columns of table "ReferralProgram" */
  ["ReferralProgram_select_column"]: ReferralProgram_select_column;
  /** Streaming cursor of the table "ReferralProgram" */
  ["ReferralProgram_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["ReferralProgram_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["ReferralProgram_stream_cursor_value_input"]: {
    creditAmount?: number;
  };
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_array_comparison_exp"]: {
    /** is the array contained in the given array value */
    _contained_in?: Array<string>;
    /** does the array contain the given value */
    _contains?: Array<string>;
    _eq?: Array<string>;
    _gt?: Array<string>;
    _gte?: Array<string>;
    _in: Array<string>;
    _is_null?: boolean;
    _lt?: Array<string>;
    _lte?: Array<string>;
    _neq?: Array<string>;
    _nin: Array<string>;
  };
  /** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
  ["String_comparison_exp"]: {
    _eq?: string;
    _gt?: string;
    _gte?: string;
    /** does the column match the given case-insensitive pattern */
    _ilike?: string;
    _in?: Array<string>;
    /** does the column match the given POSIX regular expression, case insensitive */
    _iregex?: string;
    _is_null?: boolean;
    /** does the column match the given pattern */
    _like?: string;
    _lt?: string;
    _lte?: string;
    _neq?: string;
    /** does the column NOT match the given case-insensitive pattern */
    _nilike?: string;
    _nin?: Array<string>;
    /** does the column NOT match the given POSIX regular expression, case insensitive */
    _niregex?: string;
    /** does the column NOT match the given pattern */
    _nlike?: string;
    /** does the column NOT match the given POSIX regular expression, case sensitive */
    _nregex?: string;
    /** does the column NOT match the given SQL regular expression */
    _nsimilar?: string;
    /** does the column match the given POSIX regular expression, case sensitive */
    _regex?: string;
    /** does the column match the given SQL regular expression */
    _similar?: string;
  };
  /** ordering argument of a cursor */
  ["cursor_ordering"]: cursor_ordering;
  ["find_Person_by_phone_args"]: {
    number?: string;
  };
  /** columns and relationships of "find_Person_response" */
  ["find_Person_response"]: {
    __typename: "find_Person_response";
    present?: boolean;
  };
  /** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
  ["find_Person_response_bool_exp"]: {
    _and?: Array<GraphQLTypes["find_Person_response_bool_exp"]>;
    _not?: GraphQLTypes["find_Person_response_bool_exp"];
    _or?: Array<GraphQLTypes["find_Person_response_bool_exp"]>;
    present?: GraphQLTypes["Boolean_comparison_exp"];
  };
  /** Ordering options when selecting data from "find_Person_response". */
  ["find_Person_response_order_by"]: {
    present?: GraphQLTypes["order_by"];
  };
  /** select columns of table "find_Person_response" */
  ["find_Person_response_select_column"]: find_Person_response_select_column;
  /** Streaming cursor of the table "find_Person_response" */
  ["find_Person_response_stream_cursor_input"]: {
    /** Stream column input with initial value */
    initial_value: GraphQLTypes["find_Person_response_stream_cursor_value_input"];
    /** cursor ordering */
    ordering?: GraphQLTypes["cursor_ordering"];
  };
  /** Initial value of the column from where the streaming should start */
  ["find_Person_response_stream_cursor_value_input"]: {
    present?: boolean;
  };
  ["geography"]: any;
  ["geography_cast_exp"]: {
    geometry?: GraphQLTypes["geometry_comparison_exp"];
  };
  /** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
  ["geography_comparison_exp"]: {
    _cast?: GraphQLTypes["geography_cast_exp"];
    _eq?: GraphQLTypes["geography"];
    _gt?: GraphQLTypes["geography"];
    _gte?: GraphQLTypes["geography"];
    _in?: Array<GraphQLTypes["geography"]>;
    _is_null?: boolean;
    _lt?: GraphQLTypes["geography"];
    _lte?: GraphQLTypes["geography"];
    _neq?: GraphQLTypes["geography"];
    _nin?: Array<GraphQLTypes["geography"]>;
    /** is the column within a given distance from the given geography value */
    _st_d_within?: GraphQLTypes["st_d_within_geography_input"];
    /** does the column spatially intersect the given geography value */
    _st_intersects?: GraphQLTypes["geography"];
  };
  ["geometry"]: any;
  ["geometry_cast_exp"]: {
    geography?: GraphQLTypes["geography_comparison_exp"];
  };
  /** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
  ["geometry_comparison_exp"]: {
    _cast?: GraphQLTypes["geometry_cast_exp"];
    _eq?: GraphQLTypes["geometry"];
    _gt?: GraphQLTypes["geometry"];
    _gte?: GraphQLTypes["geometry"];
    _in?: Array<GraphQLTypes["geometry"]>;
    _is_null?: boolean;
    _lt?: GraphQLTypes["geometry"];
    _lte?: GraphQLTypes["geometry"];
    _neq?: GraphQLTypes["geometry"];
    _nin?: Array<GraphQLTypes["geometry"]>;
    /** is the column within a given 3D distance from the given geometry value */
    _st_3d_d_within?: GraphQLTypes["st_d_within_input"];
    /** does the column spatially intersect the given geometry value in 3D */
    _st_3d_intersects?: GraphQLTypes["geometry"];
    /** does the column contain the given geometry value */
    _st_contains?: GraphQLTypes["geometry"];
    /** does the column cross the given geometry value */
    _st_crosses?: GraphQLTypes["geometry"];
    /** is the column within a given distance from the given geometry value */
    _st_d_within?: GraphQLTypes["st_d_within_input"];
    /** is the column equal to given geometry value (directionality is ignored) */
    _st_equals?: GraphQLTypes["geometry"];
    /** does the column spatially intersect the given geometry value */
    _st_intersects?: GraphQLTypes["geometry"];
    /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
    _st_overlaps?: GraphQLTypes["geometry"];
    /** does the column have atleast one point in common with the given geometry value */
    _st_touches?: GraphQLTypes["geometry"];
    /** is the column contained in the given geometry value */
    _st_within?: GraphQLTypes["geometry"];
  };
  ["jsonb"]: any;
  /** mutation root */
  ["mutation_root"]: {
    __typename: "mutation_root";
    /** insert data into the table: "AvailabilityResult" */
    insert_AvailabilityResult?: GraphQLTypes["AvailabilityResult_mutation_response"];
    /** insert a single row into the table: "AvailabilityResult" */
    insert_AvailabilityResult_one?: GraphQLTypes["AvailabilityResult"];
    /** insert data into the table: "MarketingLead" */
    insert_MarketingLead?: GraphQLTypes["MarketingLead_mutation_response"];
    /** insert a single row into the table: "MarketingLead" */
    insert_MarketingLead_one?: GraphQLTypes["MarketingLead"];
    /** update data of the table: "MarketingLead" */
    update_MarketingLead?: GraphQLTypes["MarketingLead_mutation_response"];
    /** update single row of the table: "MarketingLead" */
    update_MarketingLead_by_pk?: GraphQLTypes["MarketingLead"];
    /** update multiples rows of table: "MarketingLead" */
    update_MarketingLead_many?: Array<
      GraphQLTypes["MarketingLead_mutation_response"] | undefined
    >;
  };
  /** column ordering options */
  ["order_by"]: order_by;
  ["query_root"]: {
    __typename: "query_root";
    /** fetch data from the table: "Attachment" */
    Attachment: Array<GraphQLTypes["Attachment"]>;
    /** fetch data from the table: "Attachment" using primary key columns */
    Attachment_by_pk?: GraphQLTypes["Attachment"];
    /** An array relationship */
    Availability: Array<GraphQLTypes["Availability"]>;
    /** fetch data from the table: "AvailabilityResult" */
    AvailabilityResult: Array<GraphQLTypes["AvailabilityResult"]>;
    /** fetch data from the table: "ContentBlock" */
    ContentBlock: Array<GraphQLTypes["ContentBlock"]>;
    /** fetch data from the table: "ContentBlock" using primary key columns */
    ContentBlock_by_pk?: GraphQLTypes["ContentBlock"];
    /** fetch data from the table: "GeographicRegion" */
    GeographicRegion: Array<GraphQLTypes["GeographicRegion"]>;
    /** fetch data from the table: "GeographicRegionHandyman" */
    GeographicRegionHandyman: Array<GraphQLTypes["GeographicRegionHandyman"]>;
    /** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
    GeographicRegionHandyman_by_pk?: GraphQLTypes["GeographicRegionHandyman"];
    /** fetch data from the table: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood: Array<
      GraphQLTypes["GeographicRegionNeighborhood"]
    >;
    /** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
    GeographicRegionNeighborhood_by_pk?: GraphQLTypes["GeographicRegionNeighborhood"];
    /** fetch data from the table: "GeographicRegion" using primary key columns */
    GeographicRegion_by_pk?: GraphQLTypes["GeographicRegion"];
    /** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
    GeographicRegion_by_zipCode: Array<GraphQLTypes["GeographicRegion"]>;
    /** fetch data from the table: "MarketingLead" */
    MarketingLead: Array<GraphQLTypes["MarketingLead"]>;
    /** fetch data from the table: "MarketingLead" using primary key columns */
    MarketingLead_by_pk?: GraphQLTypes["MarketingLead"];
    /** fetch data from the table: "Person" */
    Person: Array<GraphQLTypes["Person"]>;
    /** fetch data from the table: "Person" using primary key columns */
    Person_by_pk?: GraphQLTypes["Person"];
    /** fetch data from the table: "ReferralProgram" */
    ReferralProgram: Array<GraphQLTypes["ReferralProgram"]>;
    /** execute function "find_Person_by_phone" which returns "find_Person_response" */
    find_Person_by_phone?: GraphQLTypes["find_Person_response"];
    /** fetch data from the table: "find_Person_response" */
    find_Person_response: Array<GraphQLTypes["find_Person_response"]>;
  };
  ["st_d_within_geography_input"]: {
    distance: number;
    from: GraphQLTypes["geography"];
    use_spheroid?: boolean;
  };
  ["st_d_within_input"]: {
    distance: number;
    from: GraphQLTypes["geometry"];
  };
  ["subscription_root"]: {
    __typename: "subscription_root";
    /** fetch data from the table: "Attachment" */
    Attachment: Array<GraphQLTypes["Attachment"]>;
    /** fetch data from the table: "Attachment" using primary key columns */
    Attachment_by_pk?: GraphQLTypes["Attachment"];
    /** fetch data from the table in a streaming manner: "Attachment" */
    Attachment_stream: Array<GraphQLTypes["Attachment"]>;
    /** An array relationship */
    Availability: Array<GraphQLTypes["Availability"]>;
    /** fetch data from the table: "AvailabilityResult" */
    AvailabilityResult: Array<GraphQLTypes["AvailabilityResult"]>;
    /** fetch data from the table in a streaming manner: "AvailabilityResult" */
    AvailabilityResult_stream: Array<GraphQLTypes["AvailabilityResult"]>;
    /** fetch data from the table in a streaming manner: "Availability" */
    Availability_stream: Array<GraphQLTypes["Availability"]>;
    /** fetch data from the table: "ContentBlock" */
    ContentBlock: Array<GraphQLTypes["ContentBlock"]>;
    /** fetch data from the table: "ContentBlock" using primary key columns */
    ContentBlock_by_pk?: GraphQLTypes["ContentBlock"];
    /** fetch data from the table in a streaming manner: "ContentBlock" */
    ContentBlock_stream: Array<GraphQLTypes["ContentBlock"]>;
    /** fetch data from the table: "GeographicRegion" */
    GeographicRegion: Array<GraphQLTypes["GeographicRegion"]>;
    /** fetch data from the table: "GeographicRegionHandyman" */
    GeographicRegionHandyman: Array<GraphQLTypes["GeographicRegionHandyman"]>;
    /** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
    GeographicRegionHandyman_by_pk?: GraphQLTypes["GeographicRegionHandyman"];
    /** fetch data from the table in a streaming manner: "GeographicRegionHandyman" */
    GeographicRegionHandyman_stream: Array<
      GraphQLTypes["GeographicRegionHandyman"]
    >;
    /** fetch data from the table: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood: Array<
      GraphQLTypes["GeographicRegionNeighborhood"]
    >;
    /** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
    GeographicRegionNeighborhood_by_pk?: GraphQLTypes["GeographicRegionNeighborhood"];
    /** fetch data from the table in a streaming manner: "GeographicRegionNeighborhood" */
    GeographicRegionNeighborhood_stream: Array<
      GraphQLTypes["GeographicRegionNeighborhood"]
    >;
    /** fetch data from the table: "GeographicRegion" using primary key columns */
    GeographicRegion_by_pk?: GraphQLTypes["GeographicRegion"];
    /** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
    GeographicRegion_by_zipCode: Array<GraphQLTypes["GeographicRegion"]>;
    /** fetch data from the table in a streaming manner: "GeographicRegion" */
    GeographicRegion_stream: Array<GraphQLTypes["GeographicRegion"]>;
    /** fetch data from the table: "MarketingLead" */
    MarketingLead: Array<GraphQLTypes["MarketingLead"]>;
    /** fetch data from the table: "MarketingLead" using primary key columns */
    MarketingLead_by_pk?: GraphQLTypes["MarketingLead"];
    /** fetch data from the table in a streaming manner: "MarketingLead" */
    MarketingLead_stream: Array<GraphQLTypes["MarketingLead"]>;
    /** fetch data from the table: "Person" */
    Person: Array<GraphQLTypes["Person"]>;
    /** fetch data from the table: "Person" using primary key columns */
    Person_by_pk?: GraphQLTypes["Person"];
    /** fetch data from the table in a streaming manner: "Person" */
    Person_stream: Array<GraphQLTypes["Person"]>;
    /** fetch data from the table: "ReferralProgram" */
    ReferralProgram: Array<GraphQLTypes["ReferralProgram"]>;
    /** fetch data from the table in a streaming manner: "ReferralProgram" */
    ReferralProgram_stream: Array<GraphQLTypes["ReferralProgram"]>;
    /** execute function "find_Person_by_phone" which returns "find_Person_response" */
    find_Person_by_phone?: GraphQLTypes["find_Person_response"];
    /** fetch data from the table: "find_Person_response" */
    find_Person_response: Array<GraphQLTypes["find_Person_response"]>;
    /** fetch data from the table in a streaming manner: "find_Person_response" */
    find_Person_response_stream: Array<GraphQLTypes["find_Person_response"]>;
  };
  ["timestamptz"]: any;
  /** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
  ["timestamptz_comparison_exp"]: {
    _eq?: GraphQLTypes["timestamptz"];
    _gt?: GraphQLTypes["timestamptz"];
    _gte?: GraphQLTypes["timestamptz"];
    _in?: Array<GraphQLTypes["timestamptz"]>;
    _is_null?: boolean;
    _lt?: GraphQLTypes["timestamptz"];
    _lte?: GraphQLTypes["timestamptz"];
    _neq?: GraphQLTypes["timestamptz"];
    _nin?: Array<GraphQLTypes["timestamptz"]>;
  };
  ["uuid"]: any;
  /** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
  ["uuid_comparison_exp"]: {
    _eq?: GraphQLTypes["uuid"];
    _gt?: GraphQLTypes["uuid"];
    _gte?: GraphQLTypes["uuid"];
    _in?: Array<GraphQLTypes["uuid"]>;
    _is_null?: boolean;
    _lt?: GraphQLTypes["uuid"];
    _lte?: GraphQLTypes["uuid"];
    _neq?: GraphQLTypes["uuid"];
    _nin?: Array<GraphQLTypes["uuid"]>;
  };
};
/** select columns of table "Attachment" */
export const enum Attachment_select_column {
  contentType = "contentType",
  id = "id",
  publicUrl = "publicUrl",
}
export const enum AvailabilityResultSource_enum {
  newAppointment = "newAppointment",
  newCustomer = "newCustomer",
  reschedule = "reschedule",
}
/** select columns of table "AvailabilityResult" */
export const enum AvailabilityResult_select_column {
  id = "id",
}
/** select columns of table "Availability" */
export const enum Availability_select_column {
  beginsAt = "beginsAt",
  endsAt = "endsAt",
  personId = "personId",
  serviceArea = "serviceArea",
}
/** select columns of table "ContentBlock" */
export const enum ContentBlock_select_column {
  body = "body",
  createdAt = "createdAt",
  key = "key",
  updatedAt = "updatedAt",
}
export const enum CreationSourceType_enum {
  admin = "admin",
  android = "android",
  ios = "ios",
  web = "web",
}
/** select columns of table "GeographicRegionHandyman" */
export const enum GeographicRegionHandyman_select_column {
  id = "id",
}
/** select columns of table "GeographicRegionNeighborhood" */
export const enum GeographicRegionNeighborhood_select_column {
  id = "id",
  name = "name",
  zipCode = "zipCode",
}
/** select columns of table "GeographicRegion" */
export const enum GeographicRegion_select_column {
  availableStartingAt = "availableStartingAt",
  id = "id",
  name = "name",
  servicedZipCodes = "servicedZipCodes",
}
/** unique or primary key constraints on table "MarketingLead" */
export const enum MarketingLead_constraint {
  MarketingLead_pkey = "MarketingLead_pkey",
}
/** select columns of table "MarketingLead" */
export const enum MarketingLead_select_column {
  id = "id",
}
/** update columns of table "MarketingLead" */
export const enum MarketingLead_update_column {
  city = "city",
  email = "email",
  firstAppointmentBeginsAt = "firstAppointmentBeginsAt",
  firstAppointmentEndsAt = "firstAppointmentEndsAt",
  firstName = "firstName",
  homeAccountCreated = "homeAccountCreated",
  lastName = "lastName",
  organicSource = "organicSource",
  phoneNumber = "phoneNumber",
  phoneNumberVerified = "phoneNumberVerified",
  state = "state",
  streetAddress = "streetAddress",
  utmCampaign = "utmCampaign",
  utmContent = "utmContent",
  utmId = "utmId",
  utmMedium = "utmMedium",
  utmSource = "utmSource",
  utmSourcePlatform = "utmSourcePlatform",
  utmTerm = "utmTerm",
  zipCode = "zipCode",
}
/** select columns of table "Person" */
export const enum Person_select_column {
  firstName = "firstName",
  id = "id",
}
/** select columns of table "ReferralProgram" */
export const enum ReferralProgram_select_column {
  creditAmount = "creditAmount",
}
/** ordering argument of a cursor */
export const enum cursor_ordering {
  ASC = "ASC",
  DESC = "DESC",
}
/** select columns of table "find_Person_response" */
export const enum find_Person_response_select_column {
  present = "present",
}
/** column ordering options */
export const enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}
export class GraphQLError extends Error {
  static response: any;
  constructor(public response: GraphQLResponse) {
    super("");
    console.error(response);
  }
  toString() {
    return "GraphQL Response Error";
  }
}

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> =
  T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> =
  FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
    ? {
        [P in keyof SRC]: SRC[P] extends "__union" & infer R
          ? P extends keyof DST
            ? IsArray<
                R,
                "__typename" extends keyof DST
                  ? DST[P] & { __typename: true }
                  : DST[P]
              >
            : {}
          : never;
      }[keyof DST] & {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends "__union" & infer R ? never : P;
            }[keyof DST]
          >,
          "__typename"
        >]: IsPayLoad<DST[P]> extends boolean
          ? SRC[P]
          : IsArray<SRC[P], DST[P]>;
      }
    : {
        [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean
          ? SRC[P]
          : IsArray<SRC[P], DST[P]>;
      };

export type MapType<SRC, DST> =
  SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> =
  IsPayLoad<DST> extends { __alias: infer R }
    ? {
        [P in keyof R]: MapType<SRC, R[P]>;
      } & MapType<SRC, Omit<IsPayLoad<DST>, "__alias">>
    : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> =
  F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (
    fn: (e: {
      data?: InputType<T, Z>;
      code?: number;
      reason?: string;
      message?: string;
    }) => void,
  ) => void;
  error: (
    fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void,
  ) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<
  F extends [infer ARGS, any] ? ARGS : undefined
>;

export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case "String":
      return `${JSON.stringify(value)}`;
    case "Int":
      return `${value}`;
    case "Float":
      return `${value}`;
    case "Boolean":
      return `${value}`;
    case "ID":
      return `"${value}"`;
    case "enum":
      return `${value}`;
    case "scalar":
      return `${value}`;
    default:
      return false;
  }
};

export const TypesPropsResolver = ({
  value,
  type,
  name,
  key,
  blockArrays,
}: {
  value: any;
  type: string;
  name: string;
  key?: string;
  blockArrays?: boolean;
}): string => {
  if (value === null) {
    return `null`;
  }
  let resolvedValue = AllTypesProps[type][name];
  if (key) {
    resolvedValue = resolvedValue[key];
  }
  if (!resolvedValue) {
    throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ""}`);
  }
  const typeResolved = resolvedValue.type;
  const isArray = resolvedValue.array;
  const isArrayRequired = resolvedValue.arrayRequired;
  if (typeof value === "string" && value.startsWith(`ZEUS_VAR$`)) {
    const isRequired = resolvedValue.required ? "!" : "";
    let t = `${typeResolved}`;
    if (isArray) {
      if (isRequired) {
        t = `${t}!`;
      }
      t = `[${t}]`;
      if (isArrayRequired) {
        t = `${t}!`;
      }
    } else {
      if (isRequired) {
        t = `${t}!`;
      }
    }
    return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
  }
  if (isArray && !blockArrays) {
    return `[${value
      .map((v: any) =>
        TypesPropsResolver({ value: v, type, name, key, blockArrays: true }),
      )
      .join(",")}]`;
  }
  const reslovedScalar = ScalarResolver(typeResolved, value);
  if (!reslovedScalar) {
    const resolvedType = AllTypesProps[typeResolved];
    if (typeof resolvedType === "object") {
      const argsKeys = Object.keys(resolvedType);
      return `{${argsKeys
        .filter((ak) => value[ak] !== undefined)
        .map(
          (ak) =>
            `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`,
        )}}`;
    }
    return ScalarResolver(AllTypesProps[typeResolved], value) as string;
  }
  return reslovedScalar;
};

const isArrayFunction = (parent: string[], a: any[]) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter(
    (k) => typeof values[k] !== "undefined",
  );

  if (!keys.length) {
    return keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: mainKey,
                name: key,
                key: v,
              })}`,
          )
          .join(",")})${r ? traverseToSeekArrays(parent, r) : ""}`
      : traverseToSeekArrays(parent, r);
  }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v,
              })}`,
          )
          .join(",")})${r ? traverseToSeekArrays(parent, r) : ""}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};

const resolveKV = (
  k: string,
  v: boolean | string | { [x: string]: boolean | string },
) =>
  typeof v === "boolean"
    ? k
    : typeof v === "object"
      ? `${k}{${objectToTree(v)}}`
      : `${k}${v}`;

const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o)
    .map((k) => `${resolveKV(k, o[k])}`)
    .join(" ")}}`;

const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return "";
  if (Object.keys(a).length === 0) {
    return "";
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === "object") {
      Object.keys(a)
        .filter((k) => typeof a[k] !== "undefined")
        .forEach((k) => {
          if (k === "__alias") {
            Object.keys(a[k]).forEach((aliasKey) => {
              const aliasOperations = a[k][aliasKey];
              const aliasOperationName = Object.keys(aliasOperations)[0];
              const aliasOperation = aliasOperations[aliasOperationName];
              b[
                `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
              ] = traverseToSeekArrays(
                [...parent, aliasOperationName],
                aliasOperation,
              );
            });
          } else {
            b[k] = traverseToSeekArrays([...parent, k], a[k]);
          }
        });
    } else {
      return "";
    }
  }
  return objectToTree(b);
};

const buildQuery = (type: string, a?: Record<any, any>) =>
  traverseToSeekArrays([type], a);

const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(
        variable,
        variable.split("__ZEUS_VAR__")[0],
      );
    }
  });
  return `(${AllVariables.map((a) => a.split("__ZEUS_VAR__"))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(", ")})${filteredQuery}`;
};

export const queryConstruct =
  (
    t: "query" | "mutation" | "subscription",
    tName: string,
    operationName?: string,
  ) =>
  (o: Record<any, any>) =>
    `${t.toLowerCase()}${operationName ? " " + operationName : ""}${inspectVariables(buildQuery(tName, o))}`;

export const fullChainConstruct =
  (fn: FetchFunction) =>
  (t: "query" | "mutation" | "subscription", tName: string) =>
  (o: Record<any, any>, options?: OperationOptions) =>
    fn(
      queryConstruct(t, tName, options?.operationName)(o),
      options?.variables,
    ).then((r: any) => {
      seekForAliases(r);
      return r;
    });

export const fullSubscriptionConstruct =
  (fn: SubscriptionFunction) =>
  (t: "query" | "mutation" | "subscription", tName: string) =>
  (o: Record<any, any>, options?: OperationOptions) =>
    fn(queryConstruct(t, tName, options?.operationName)(o));

const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === "object") {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === "object" && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf("__alias__") !== -1) {
        const [operation, alias] = k.split("__alias__");
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};

export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join("")}`;

export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T]
    ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X
    : any,
) => fn as (args?: any, source?: any) => any;

const handleFetchResponse = (
  response: Parameters<
    Extract<Parameters<ReturnType<typeof fetch>["then"]>[0], Function>
  >[0],
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === "GET") {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + "?query=" + encodeURIComponent(query);
    const wsString = queryString.replace("http", "ws");
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            if (data) {
              seekForAliases(data);
            }
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error("No websockets implemented");
  }
};

const allOperations = {
  query: "query_root",
  mutation: "mutation_root",
  subscription: "subscription_root",
};

export type GenericOperation<O> = O extends "query"
  ? "query_root"
  : O extends "mutation"
    ? "mutation_root"
    : "subscription_root";

export const Thunder =
  (fn: FetchFunction) =>
  <
    O extends "query" | "mutation" | "subscription",
    R extends keyof ValueTypes = GenericOperation<O>,
  >(
    operation: O,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
    fullChainConstruct(fn)(operation, allOperations[operation])(
      o as any,
      ops,
    ) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <
    O extends "query" | "mutation" | "subscription",
    R extends keyof ValueTypes = GenericOperation<O>,
  >(
    operation: O,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
    fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
      o as any,
      ops,
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) =>
  SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends "query" | "mutation" | "subscription",
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) =>
  queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) =>
  ZeusSelect<ValueTypes[T]>();

export const Gql = Chain("https://apini.honeyhomes.com/v1/graphql");
