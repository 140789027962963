import { abstractView } from "./AbstractView";
import {
  getUserData,
  getUserZipCode,
  getZipCodeStatus,
  getUserAddress,
} from "../helpers/UserData";
import { handleInput, OnboardingData } from "../helpers/Utils";
import * as firebase from "../helpers/Firebase";

export function personalInfoView() {
  const heading = determineHeading();
  const subheader = determineSubheader();
  const view = abstractView("PersonalInfoView", {
    heading: heading,
    subheader,
  });

  view.setTitle("Honey Homes - Account Information");

  view.onload = (): void => {
    prepopulateUserData();
    attachEventHandlers();
    const params = new URLSearchParams(window.location.search);
    if (params.has("edit-phone-number")) {
      (
        document.getElementById("mobilePhoneNumber") as HTMLInputElement
      ).focus();
    }
  };
  return view;
}

function prepopulateUserData() {
  const zipCodeOption = getUserZipCode();
  const addressOption = getUserAddress();

  if (zipCodeOption.some) {
    const zipCodeField = document.getElementById("zipCode") as HTMLInputElement;
    if (zipCodeField) {
      zipCodeField.value = zipCodeOption.val;
      handleInput.bind(zipCodeField)();
    }
  }
  if (addressOption.some) {
    firebase.logUserAddress(addressOption.val.streetAddress);
    const addressField = document.getElementById(
      "streetAddress",
    ) as HTMLInputElement;
    const cityField = document.getElementById("city") as HTMLInputElement;
    const stateField = document.getElementById("state") as HTMLSelectElement;
    if (addressField) {
      addressField.value = addressOption.val.streetLine;
      handleInput.bind(addressField)();
    }
    if (cityField) {
      cityField.value = addressOption.val.city;
      handleInput.bind(cityField)();
    }
    if (stateField) {
      stateField.value = addressOption.val.state;
      handleInput.bind(stateField)();
    }
  }

  const userData = getUserData();
  if (userData) {
    const fieldIdArray = [
      "firstName",
      "lastName",
      "email",
      "city",
      "state",
      "mobilePhoneNumber",
    ];
    const userDataPropArray = [
      "firstName",
      "lastName",
      "email",
      "city",
      "state",
      "displayedPhoneNumber",
      "streetAddress",
    ];
    // A zip code or street address saved in session storage from the landing view
    // should override a zip code or street address stored with the user data object
    if (zipCodeOption.none) {
      fieldIdArray.push("zipCode");
    }
    if (addressOption.none) {
      fieldIdArray.push("streetAddress");
    }

    fieldIdArray.forEach((elementId, index) => {
      const field = document.getElementById(elementId) as HTMLInputElement;
      const savedDataPoint =
        userData[userDataPropArray[index] as keyof OnboardingData];
      if (field) {
        if (savedDataPoint) {
          field.value = savedDataPoint;
        }
        handleInput.bind(field)();
      }
    });
  }
}

function determineHeading(): string {
  let heading =
    "<span class='line'>Tell us a little more</span> <span class='line'>about yourself</span>";

  const zipCodeStatus = getZipCodeStatus();
  if (zipCodeStatus.some && zipCodeStatus.val === "waitlisted") {
    heading = `We're not in your area yet, but join our waitlist to stay updated.`;
  }
  return heading;
}

function determineSubheader(): string {
  let subheader =
    "Next, we’ll show you available times to book a free home walk-thru.";
  const zipCodeStatus = getZipCodeStatus();
  if (zipCodeStatus.some && zipCodeStatus.val === "waitlisted") {
    subheader = `We're expanding to new markets every day. Sign up and be the first to know when we are available in your area`;
  }
  return subheader;
}

function showInfoBubble(infoBubbleElement: HTMLElement) {
  infoBubbleElement.style.display = "block";
}

function hideInfoBubble(infoBubbleElement: HTMLElement) {
  infoBubbleElement.style.display = "none";
}

function attachEventHandlers(): void {
  const arrInputFields = document.querySelectorAll(
    "input",
  ) as NodeListOf<HTMLInputElement>;
  arrInputFields.forEach((inputField) => {
    inputField.addEventListener("keyup", handleInput);
  });

  const selectElement = document.querySelector("select") as HTMLSelectElement;
  selectElement.addEventListener("change", handleInput);

  const infoIcon = document.querySelector(".info-icon") as HTMLElement;
  const infoBubble = document.querySelector(".info-bubble") as HTMLElement;
  if (infoIcon && infoBubble) {
    infoIcon.addEventListener("mouseover", () => showInfoBubble(infoBubble));
    infoIcon.addEventListener("mouseout", () => hideInfoBubble(infoBubble));
  }

  const form: HTMLFormElement = document.querySelector(
    ".personal-info",
  ) as HTMLFormElement;

  // Include both input and select elements in the NodeList
  const formElements: NodeListOf<HTMLInputElement | HTMLSelectElement> =
    form.querySelectorAll("input, select");

  const handleKeyDown = (event: KeyboardEvent, index: number): void => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action (form submission)
      // Focus the next form field
      const nextElement: HTMLInputElement | HTMLSelectElement | null =
        formElements[index + 1] || null;
      if (nextElement) {
        nextElement.focus();
      } else {
        // If there's no next element, focus the submit button or do something else
        const submitButton: HTMLInputElement | null = form.querySelector(
          'input[type="submit"]',
        );
        submitButton?.focus();
      }
    }
  };

  formElements.forEach((element, index) => {
    // Only add the keydown event listener to input elements
    if (element.tagName === "INPUT") {
      element.addEventListener("keydown", (event) =>
        handleKeyDown(event as KeyboardEvent, index),
      );
    }
  });
}
