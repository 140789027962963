import { abstractView } from "./AbstractView";
import { getUserData, getPricingData } from "../helpers/UserData";
import {
  appDownloads,
  attachConfirmationPageEventHandlers,
  configureAppBanner,
  getConfirmationPageImageFileURLs,
  getDownloadAppLink,
  getFormattedAppointmentTimeFromISOString,
} from "../helpers/Utils";

export function confirmationView() {
  configureAppBanner();

  const imageURLsObject = getConfirmationPageImageFileURLs();

  const downloadAppLink = getDownloadAppLink();

  const userData = getUserData();

  let formattedAppointmentTime = "";
  let whatsNextSecondStepTimeText = "";
  const appointmentStartTime = userData?.appointmentStartTime;
  if (appointmentStartTime) {
    formattedAppointmentTime =
      getFormattedAppointmentTimeFromISOString(appointmentStartTime);

    const appointmentDate = new Date(appointmentStartTime);

    const now = new Date();

    const timeDelta = appointmentDate.getTime() - now.getTime();
    const differenceInDays = Math.round(timeDelta / (1000 * 60 * 60 * 24));

    whatsNextSecondStepTimeText = `IN ${differenceInDays} DAY`;
    whatsNextSecondStepTimeText =
      differenceInDays !== 1
        ? `${whatsNextSecondStepTimeText}S`
        : whatsNextSecondStepTimeText;
  }

  let yearDiscountedPrice = "$3,950";
  let monthlyDiscountedPrice = "$329";
  let yearSaving = "$790";
  let monthPrice = "$395";
  const pricingData = getPricingData();
  if (pricingData.some) {
    yearDiscountedPrice = pricingData.val.yearDiscountedPrice || "$3,950";
    monthlyDiscountedPrice = pricingData.val.monthlyDiscountedPrice || "$329";
    yearSaving = pricingData.val.yearSaving || "$790";
    monthPrice = pricingData.val.monthPrice || "$395";
  }

  const view = abstractView("ConfirmationView", {
    ...imageURLsObject,
    yearDiscountedPrice,
    monthlyDiscountedPrice,
    yearSaving,
    monthPrice,
    downloadAppLink: downloadAppLink,
    whatsNextSecondStepTimeText: whatsNextSecondStepTimeText,
    appLinks: appDownloads(),
    appointment: formattedAppointmentTime,
    startDateString: appointmentStartTime ?? "",
    endDateString: userData?.appointmentEndTime ?? "",
  });
  view.setTitle("Honey Homes - Confirmation");
  view.onload = () => {
    populateSubheader();
    attachConfirmationPageEventHandlers();
  };
  return view;
}

function populateSubheader() {
  const userData = getUserData();
  const subheader = document.querySelector(".subheader");
  if (subheader && userData?.email) {
    subheader.innerHTML = `An email confirmation will be sent to ${userData.email}.`;
  }
}
