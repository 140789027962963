export const strings: { [key: string]: string } = {
  genericError:
    "Sorry, we've run into an issue. Please try again or text us at (510) 930-0732.",
  phoneConfirmError:
    "We're unable to confirm your verification code. Please try again or text us at (510) 930-0732.",
  missingotpConfirmError:
    "There was an issue confirming your phone number, Please go back and try again or text us at (510) 930-0732.",
  userDetailsError:
    "Couldn't retrieve user details. Please try again or text us at (510) 930-0732.",
  phoneVerifyError:
    "We're having trouble verifying your phone number. Please try again or text us at (510) 930-0732.",
  regionLookupError:
    "We're having trouble finding your region. Please try again or text us at (510) 930-0732.",
  accountCreationError:
    "We're having trouble creating your account. Please try again or text us at (510) 930-0732.",
  handyManError:
    "Couldn't determine your handyman. Please text us at (510) 930-0732.",
  appointmentLookupError:
    "Sorry, we've run into an issue. Please message us in the app to schedule your appointment.",
  appointmentTimeError:
    "Couldn't determine appointment times from selection. Please message us in the app to schedule your appointment.",
  appointmentTokenError:
    "Missing required data to create appointment. Please message us in the app to schedule your appointment.",
  appointmentCreationError:
    "Sorry, we're unable to confirm your appointment. Please message us in the app to schedule.",
  appointmentRegistrationError:
    "There was a problem registering your appointment. Please message us in the app to schedule your appointment.",
  waitingListError:
    "We're having trouble adding you to our waiting list. Please try again or text us at (510) 930-0732",
  stripeSetupError:
    "Sorry, we ran into an issue. Please try again or text us at (510) 930-0732.",
  stripeTokenError:
    "Missing required data to show payment details. Please message us in the app for help.",
  stripeProductsRequestError:
    "We're having trouble returning our payment choices. Please try again or text us at (510) 930-0732.",
  stripeMissingProductError:
    "Sorry there was an issue displaying our payment choices. Please try again or text us at (510) 930-0732.",
  storeContactPreferenceError:
    "Sorry, we ran into an issue. Please try once more or message us in the app for help.",
  determineContactPreferenceError:
    "Couldn't determine contact preference. Please message us in the app for help.",
  contactTokenError:
    "Missing required data to store contact preference. Please message us in the app for help.",
  missingUserDataError:
    "Missing local user data. Please message us in the app for help.",
  CodeExpiredError:
    "This code has expired. Please select Resend Code below to try again, or text us at (510) 930-0732.",
  InvalidCodeError:
    "The code you entered is incorrect. Please try again or text us at (510) 930-0732.",
};
