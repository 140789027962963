import { abstractView } from "./AbstractView";
import { getUserData, clearUserSession } from "../helpers/UserData";
import { initializeShareButton } from "../helpers/Utils";

export function waitListView() {
  initializeShareButton();

  const userData = getUserData();

  let email = "";
  if (userData) {
    email = userData.email;
  }
  const view = abstractView("WaitListView", {
    email: email,
  });
  view.setTitle("Honey Homes - Waiting List");
  view.onload = clearUserSession;
  return view;
}
