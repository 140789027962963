import {
  loadStripe,
  Stripe,
  StripeElementsOptions,
  StripeElements,
} from "@stripe/stripe-js";
import {
  CreatePaymentIntentResult,
  CreateSetupIntentResult,
  StripeSubscriptionProductPrice,
} from "../schema/operations";
import { navigateTo } from "./Router";
import { startLoading, stopLoading } from "./Utils";

let stripe: Stripe | null;
let elements: StripeElements | null;

export function storeSetupIntent(intent: CreateSetupIntentResult) {
  sessionStorage.setItem("stripeSetupIntent", JSON.stringify(intent));
}

export function getSetupIntent(): CreateSetupIntentResult | undefined {
  const data = sessionStorage.getItem("stripeSetupIntent");
  if (data) {
    return JSON.parse(data) as CreateSetupIntentResult;
  }
  return;
}

export function storeStripeSubscriptionPrices(
  prices: StripeSubscriptionProductPrice[],
) {
  sessionStorage.setItem("stripeSubscriptionPrices", JSON.stringify(prices));
}

export function getStripeSubscriptionPrices():
  | StripeSubscriptionProductPrice[]
  | undefined {
  const data = sessionStorage.getItem("stripeSubscriptionPrices");
  if (data) {
    return JSON.parse(data) as StripeSubscriptionProductPrice[];
  }
  return;
}

export function storePaymentIntent(intent: CreatePaymentIntentResult) {
  sessionStorage.setItem("stripePaymentIntent", JSON.stringify(intent));
}

export function getPaymentIntent(): CreatePaymentIntentResult | undefined {
  const data = sessionStorage.getItem("stripePaymentIntent");
  if (data) {
    return JSON.parse(data) as CreatePaymentIntentResult;
  }
  return;
}

export async function initialiseStripe(publishableKey: string) {
  stripe = await loadStripe(publishableKey);
}

export async function renderStripeForm(
  containerId: string,
  clientSecret: string,
) {
  if (!stripe) {
    console.log("No stripe object found");
    return;
  }
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        fontSizeSm: "13px",
        fontWeightNormal: "600",
        colorDanger: "#D44027",
      },
    },
  };

  elements = stripe.elements(options);
  const paymentDetailsElement = elements.create("payment");
  paymentDetailsElement.mount(`#${containerId}`);
  await stripe.confirmSetup({ elements: elements, redirect: "if_required" });
}

export async function confirmPaymentIntent() {
  if (!stripe || !elements) {
    console.log("Missing required elements to confirm setup intent");
    return;
  }

  const originalButtonValue = startLoading("confirmPaymentDetails");
  const errorContainer = document.querySelector("#stripe-error-message");
  if (errorContainer) {
    errorContainer.textContent = "";
  }

  const { error } = await stripe.confirmPayment({
    elements,
    redirect: "if_required",
  });
  stopLoading("confirmPaymentDetails", originalButtonValue);

  if (error) {
    if (errorContainer && error.type !== "validation_error") {
      errorContainer.textContent = error.message ?? "";
    }
  } else {
    navigateTo("/confirmation");
  }
}

export interface Subscriptions {
  yearId: string;
  yearFullPrice: string;
  yearDiscountedPrice: string;
  yearSaving: string;
  monthId: string;
  monthPrice: string;
  monthlyDiscountedPrice: string;
}

export function subscriptionPrices(
  prices: StripeSubscriptionProductPrice[],
): Subscriptions | undefined {
  if (!prices || prices.length === 0) {
    return;
  }

  const yearly = prices.find((e) => e.interval === "year");
  const monthly = prices.find((e) => e.interval === "month");

  if (!yearly || !monthly) {
    return;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const yearFullPrice = (monthly.amount * 12) / 100;
  const yearDiscountedPrice = yearly.amount / 100;
  const yearlySaving = yearFullPrice - yearDiscountedPrice;
  const monthlyDiscountedPrice = yearDiscountedPrice / 12;

  return {
    yearId: yearly.id,
    yearFullPrice: `$${formatter.format(yearFullPrice)}`,
    yearDiscountedPrice: `$${formatter.format(yearDiscountedPrice)}`,
    yearSaving: `$${formatter.format(yearlySaving)}`,
    monthId: monthly.id,
    monthPrice: `$${formatter.format(monthly.amount / 100)}`,
    monthlyDiscountedPrice: `$${formatter.format(monthlyDiscountedPrice)}`,
  };
}
