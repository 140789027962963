import { abstractView } from "./AbstractView";
import {
  getAppointmentDetails,
  getContactPreference,
  getUserData,
  getZipCodeStatus,
} from "../helpers/UserData";
import { handleInput } from "../helpers/Utils";

export function phoneNumberVerificationView() {
  const userData = getUserData();
  let phoneNumber = "";
  if (userData) {
    phoneNumber = userData.displayedPhoneNumber;
  }

  const { header, subheader, buttonText, personalInfoPath } =
    getPageText(phoneNumber);

  const view = abstractView("PhoneNumberVerificationView", {
    phoneNumber: phoneNumber,
    header: header,
    subheader: subheader,
    buttonText: buttonText,
    personalInfoPath: personalInfoPath,
  });
  view.setTitle("Honey Homes - Verify Phone Number");
  view.onload = (): void => {
    const inputField = document.querySelector("input") as HTMLInputElement;
    inputField?.addEventListener("keyup", handleInput);
  };
  return view;
}

function getPageText(phoneNumber: string): {
  header: string;
  subheader: string;
  buttonText: string;
  personalInfoPath: string;
} {
  let header = "";
  let subheader = "";
  let buttonText = "";
  let personalInfoPath = "personal-info";
  const zipCodeStatusOption = getZipCodeStatus();
  const appointmentDetailsOption = getAppointmentDetails();
  if (appointmentDetailsOption.some) {
    header = "Confirm your visit: Enter the 6-digit code";
    subheader = `To finalize your visit, enter the 6-digit code we sent to ${phoneNumber}.`;
    buttonText = "Confirm Visit";
  } else {
    buttonText = "Submit";
    if (zipCodeStatusOption.some && zipCodeStatusOption.val === "waitlisted") {
      header = "Confirm your spot: Enter the 6-digit code";
      subheader = `To finalize your spot on the waitlist, enter the 6-digit code we sent to ${phoneNumber}.`;
    } else {
      const contactPreferenceOption = getContactPreference();
      if (contactPreferenceOption.some) {
        const contactPreference = contactPreferenceOption.val;
        let contactPreferenceText = contactPreference;
        if (contactPreference === "phone") {
          contactPreferenceText = "phone call";
        }
        header = `Confirm your ${contactPreferenceText}: Enter the 6-digit code`;
        subheader = `To finalize your ${contactPreferenceText}, enter the 6-digit code we sent to ${phoneNumber}.`;
      }
    }
  }

  if (zipCodeStatusOption.some && zipCodeStatusOption.val === "waitlisted") {
    personalInfoPath = "waitlist-personal-info";
  }

  return {
    header: header,
    subheader: subheader,
    buttonText: buttonText,
    personalInfoPath: personalInfoPath,
  };
}
