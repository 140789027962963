import { FirebaseError, initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserProperties,
} from "firebase/analytics";
import {
  Auth,
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
  signOut,
  Unsubscribe,
} from "firebase/auth";
import {
  onSnapshot,
  getFirestore,
  doc,
  deleteDoc,
  DocumentSnapshot,
  DocumentData,
} from "@firebase/firestore";
import { Ok, Result, Err } from "ts-results";
import { normalizeError } from "../schema/operations";
import * as luxon from "luxon";

export let firebaseAuth: Auth;
export let firebaseAnalytics: Analytics;
export let otpConfirmation: ConfirmationResult | undefined;

export function initialiseFirebaseApp() {
  const firebaseConfig = {
    apiKey: "AIzaSyAP7Dc3Q9R9U6rBAB9R1cbqTuYXX_cpSLc",
    authDomain: "honey-homes-fa0d5.firebaseapp.com",
    projectId: "honey-homes-fa0d5",
    storageBucket: "honey-homes-fa0d5.appspot.com",
    messagingSenderId: "801852009482",
    appId: "1:801852009482:web:b939d8fe49c186c24603f3",
    measurementId: "G-Z6Q9VQQDVE",
  };

  const app = initializeApp(firebaseConfig);
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(
      "6Legk4onAAAAAApjWst6pv5T6JC072-YfpwENobn",
    ),
    isTokenAutoRefreshEnabled: true,
  });
  firebaseAnalytics = getAnalytics(app);
  firebaseAuth = getAuth(app);
}

export function saveAccessToken(token: string) {
  sessionStorage.setItem("honeyHomesAccessToken", token);
}

export function getAccessToken(): string | null {
  return sessionStorage.getItem("honeyHomesAccessToken");
}

export function logUserAddress(streetAddress: string) {
  setUserProperties(firebaseAnalytics, { streetAddress });
  logEvent(firebaseAnalytics, "address_entered");
}

export function logUserEmail(email: string) {
  setUserProperties(firebaseAnalytics, { user_email: email });
}

export async function attemptSignin(
  auth: Auth,
  phoneNumber: string,
  elementId: string,
): Promise<Result<void, Error>> {
  const verifier = new RecaptchaVerifier(
    elementId,
    { size: "invisible" },
    auth,
  );

  try {
    otpConfirmation = await signInWithPhoneNumber(auth, phoneNumber, verifier);
    return Ok.EMPTY;
  } catch (e) {
    return normalizeError(e);
  }
}

export class InvalidCodeError extends Error {
  readonly _tag = "InvalidCodeError";
}
export class CodeExpiredError extends Error {
  readonly _tag = "CodeExpiredError";
}
export class UnknownAuthError extends Error {
  readonly _tag = "UnknownAuthError";
}
export async function confirmOTP(
  confirmationResult: ConfirmationResult,
  passcode: string,
): Promise<
  Result<string, InvalidCodeError | CodeExpiredError | UnknownAuthError>
> {
  try {
    const confirm = await confirmationResult.confirm(passcode);
    const accessToken = await confirm.user.getIdToken(true);
    otpConfirmation = undefined;
    saveAccessToken(accessToken);
    return Ok(accessToken);
  } catch (e) {
    const err = e as FirebaseError;
    switch (err.code) {
      case "auth/invalid-verification-code": {
        return Err(new InvalidCodeError());
      }
      case "auth/code-expired": {
        return Err(new CodeExpiredError());
      }
      default: {
        return Err(new UnknownAuthError());
      }
    }
  }
}

export function firebaseSignOut() {
  void signOut(firebaseAuth);
}

/* eslint-disable */
export async function tokenTransition(
  onTransition: (res: Result<luxon.DateTime, Error>) => void,
): Promise<void> {
  try {
    const db = getFirestore();
    const uid = firebaseAuth.currentUser?.uid;
    const ref = doc(db, `person-complete-signal/${uid}`);
    let unsubscribeFn: Unsubscribe | null = null;
    const snapshotCallback: (
      snapshot: DocumentSnapshot<DocumentData>,
    ) => void = (ss) => {
      if (ss.exists()) {
        const dt = luxon.DateTime.fromISO(ss.data().completedAt);
        if (unsubscribeFn !== null) {
          unsubscribeFn();
        }
        deleteDoc(ref);
        onTransition(Ok(dt));
      }
    };
    unsubscribeFn = onSnapshot(ref, snapshotCallback);
  } catch (e: unknown) {
    onTransition(normalizeError(e));
  }
}
