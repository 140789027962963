import { abstractView } from "./AbstractView";
import { getUserData, getPricingData } from "../helpers/UserData";
import {
  appDownloads,
  attachConfirmationPageEventHandlers,
  getConfirmationPageImageFileURLs,
  getDownloadAppLink,
  configureAppBanner,
} from "../helpers/Utils";

export function noAppointmentsConfirmationView() {
  configureAppBanner();

  const imageURLsObject = getConfirmationPageImageFileURLs();

  const downloadAppLink = getDownloadAppLink();

  const userData = getUserData();

  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  let message = "";

  if (userData && type) {
    switch (type) {
      case "email": {
        message = `We'll email you at ${userData.email}.`;
        break;
      }
      case "text": {
        message = `We'll send you a text at ${userData.displayedPhoneNumber}.`;
        break;
      }
      case "phone": {
        message = `We'll call you at ${userData.displayedPhoneNumber}.`;
        break;
      }
    }
  }

  let yearDiscountedPrice = "$3,950";
  let monthlyDiscountedPrice = "$329";
  let yearSaving = "$790";
  let monthPrice = "$395";
  const pricingData = getPricingData();
  if (pricingData.some) {
    yearDiscountedPrice = pricingData.val.yearDiscountedPrice || "$3,950";
    monthlyDiscountedPrice = pricingData.val.monthlyDiscountedPrice || "$329";
    yearSaving = pricingData.val.yearSaving || "$790";
    monthPrice = pricingData.val.monthPrice || "$395";
  }

  const view = abstractView("NoAppointmentsConfirmationView", {
    ...imageURLsObject,
    yearDiscountedPrice,
    monthlyDiscountedPrice,
    yearSaving,
    monthPrice,
    downloadAppLink: downloadAppLink,
    whatsNextSecondStepTimeText: "IN A FEW DAYS",
    appLinks: appDownloads(),
    message: message,
  });
  view.setTitle("Honey Homes - No Appointments Confirmation");
  view.onload = () => {
    attachConfirmationPageEventHandlers();
  };
  return view;
}
