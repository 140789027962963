import { OnboardingData, getRegions } from "./Utils";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { firebaseSignOut, logUserEmail } from "./Firebase";
import {
  Handyman,
  CreatePersonAndHomeResult,
  createMarketingLead,
  MarketingLead,
} from "../schema/operations";
import { Some, None, Option } from "ts-results";

export interface ReferralData {
  referredById: string;
  referredBy: string;
}

interface UTMParameterData {
  utmCampaign?: string;
  utmContent?: string;
  utmId?: string;
  utmMedium?: string;
  utmSource?: string;
  utmSourcePlatform?: string;
  utmTerm?: string;
}

type HoneyHomesUserStreetAddress = {
  secondary: string;
  streetLine: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
};

type PricingData = {
  yearDiscountedPrice: string;
  monthlyDiscountedPrice: string;
  yearSaving: string;
  monthPrice: string;
};

export function getUserZipCode(): Option<string> {
  const zipCode = sessionStorage.getItem("honeyHomesUserZipCode");
  if (zipCode) {
    return Some(zipCode);
  }
  return None;
}

export function saveUserZipCode(zipCode: string) {
  sessionStorage.setItem("honeyHomesUserZipCode", zipCode);
}

export const USER_STATES = {
  REBOOKING: "rebooking",
  WAITLIST: "waitlist",
  CONFIRMATION: "confirmation",
};

export function getUserState(): Option<string> {
  const state = sessionStorage.getItem("honeyHomesUserState");
  if (state) {
    return Some(state);
  }
  return None;
}

export function saveUserState(state: string | null) {
  sessionStorage.setItem("honeyHomesUserState", state || "");
}

export function saveUserAddress(streetAddress: string) {
  // Ensure that object is handled properly when stored in session storage
  const address =
    typeof streetAddress === "string"
      ? streetAddress
      : JSON.parse(streetAddress);
  sessionStorage.setItem("honeyHomesUserStreetAddress", address);
}

export function getUserAddress(): Option<HoneyHomesUserStreetAddress> {
  const streetAddress = sessionStorage.getItem("honeyHomesUserStreetAddress");
  return streetAddress
    ? Some(JSON.parse(streetAddress) as HoneyHomesUserStreetAddress)
    : None;
}

export function savePricingData(priceData: PricingData) {
  sessionStorage.setItem("honeyHomesPricingData", JSON.stringify(priceData));
}

export function getPricingData(): Option<PricingData> {
  const pricingData = sessionStorage.getItem("honeyHomesPricingData");
  if (pricingData) {
    return Some(JSON.parse(pricingData));
  }
  return None;
}

export function getUserRegionId(): Option<string> {
  const regionId = sessionStorage.getItem("honeyHomesUserRegionId");
  if (regionId) {
    return Some(regionId);
  }
  return None;
}

export function saveUserRegionId(regionId: string) {
  sessionStorage.setItem("honeyHomesUserRegionId", regionId);
}

export function getAppointmentDetails(): Option<string[]> {
  const appointmentDetails = sessionStorage.getItem(
    "honeyHomesUserAppointmentDetails",
  );
  if (appointmentDetails) {
    return Some(JSON.parse(appointmentDetails));
  }
  return None;
}

export function saveAppointmentDetails(appointmentDetailsArray: string[]) {
  sessionStorage.setItem(
    "honeyHomesUserAppointmentDetails",
    JSON.stringify(appointmentDetailsArray),
  );
}

export function saveUserSource(userSource: string) {
  sessionStorage.setItem("honeyHomesUserSource", userSource);
}

export function getUserSource(): Option<string> {
  const userSource = sessionStorage.getItem("honeyHomesUserSource");
  if (userSource) {
    return Some(userSource);
  }
  return None;
}

export function getMarketingLeadId(): Option<string> {
  const marketingLeadId = sessionStorage.getItem("honeyHomesMarketingLeadId");
  if (marketingLeadId) {
    return Some(marketingLeadId);
  }
  return None;
}

function saveMarketingLeadId(id: string): void {
  sessionStorage.setItem("honeyHomesMarketingLeadId", id);
}

export function getUserData(): OnboardingData | undefined {
  const data = sessionStorage.getItem("honeyHomesUserData");
  if (data) {
    const obj = JSON.parse(data) as OnboardingData;
    return obj;
  }
  return;
}

export function saveUserData(userDetails: OnboardingData) {
  sessionStorage.setItem("honeyHomesUserData", JSON.stringify(userDetails));
}

export function getZipCodeStatus(): Option<string> {
  const zipCodeStatus = sessionStorage.getItem("honeyHomesUserZipCodeStatus");
  if (zipCodeStatus) {
    return Some(zipCodeStatus);
  }
  return None;
}

export function saveZipCodeStatus(
  zipCodeStatus: "live" | "upcoming" | "waitlisted",
) {
  sessionStorage.setItem("honeyHomesUserZipCodeStatus", zipCodeStatus);
}

export function getReferralData(): Option<ReferralData> {
  const referralData = sessionStorage.getItem("honeyHomesUserReferralData");
  if (referralData) {
    return Some(JSON.parse(referralData));
  }
  return None;
}

export function saveReferralData(referralData: ReferralData) {
  sessionStorage.setItem(
    "honeyHomesUserReferralData",
    JSON.stringify(referralData),
  );
}

export function getContactPreference(): Option<string> {
  const contactPreference = sessionStorage.getItem(
    "honeyHomesUserContactPreference",
  );
  if (contactPreference) {
    return Some(contactPreference);
  }
  return None;
}

export function saveContactPreference(contactPreference: string) {
  sessionStorage.setItem("honeyHomesUserContactPreference", contactPreference);
}

export function getUTMParameterData(): Option<UTMParameterData> {
  const utmParameterData = sessionStorage.getItem("honeyHomesUTMParameterData");
  if (utmParameterData) {
    return Some(JSON.parse(utmParameterData));
  }
  return None;
}

function saveUTMParameterData(utmParameterData: UTMParameterData) {
  sessionStorage.setItem(
    "honeyHomesUTMParameterData",
    JSON.stringify(utmParameterData),
  );
}

export function clearUserSession() {
  firebaseSignOut();
  sessionStorage.clear();
}

export function storeUserRegionId(regionId: string) {
  updateUserData((data) => {
    data.geographicRegionId = regionId;
  });
}

export function storePersonAndHomeIds(result: CreatePersonAndHomeResult) {
  updateUserData((data) => {
    data.homeId = result.homeId;
    data.personId = result.personId;
  });
}

export function storeAppointment(start: string, end: string) {
  updateUserData((data) => {
    data.appointmentStartTime = start;
    data.appointmentEndTime = end;
  });
}

function updateUserData(callback: (data: OnboardingData) => void) {
  const data = getUserData();
  if (data) {
    callback(data);
    saveUserData(data);
  }
}

export function getHandyMan(): Handyman | undefined {
  const regions = getRegions();
  if (regions) {
    if (regions.length === 1) {
      return regions[0].handyman;
    } else {
      const userRegionIdOption = getUserRegionId();
      if (userRegionIdOption.some) {
        const result = regions.find((region) => {
          return region.id === userRegionIdOption.val;
        });
        return result?.handyman;
      }
    }
  }
  return;
}

export async function storeUserData(form: HTMLFormElement) {
  const phoneNumberInput =
    (form.querySelector("#mobilePhoneNumber") as HTMLInputElement).value ?? "";
  let phoneNumber = "";
  let displayedPhoneNumber = phoneNumberInput;
  if (isValidPhoneNumber(phoneNumberInput, "US")) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumberInput, "US");
    phoneNumber = parsedPhoneNumber.number;
    displayedPhoneNumber = parsedPhoneNumber.formatNational();
  }

  let zipCode =
    (form.querySelector("#zipCode") as HTMLInputElement).value ?? "";
  zipCode = zipCode.split("-")[0] ?? "";
  const email = (form.querySelector("#email") as HTMLInputElement).value ?? "";
  logUserEmail(email);
  window.gtag("set", {
    user_email: email,
  });
  const data: OnboardingData = {
    firstName:
      (form.querySelector("#firstName") as HTMLInputElement).value ?? "",
    lastName: (form.querySelector("#lastName") as HTMLInputElement).value ?? "",
    email,
    phoneNumber: phoneNumber,
    displayedPhoneNumber: displayedPhoneNumber,
    streetAddress:
      (form.querySelector("#streetAddress") as HTMLInputElement).value ?? "",
    city: (form.querySelector("#city") as HTMLInputElement).value ?? "",
    state: (form.querySelector("#state") as HTMLInputElement).value ?? "",
    zipCode: zipCode,
  };

  const userSourceOption = getUserSource();
  if (userSourceOption.some) {
    data.organicSource = userSourceOption.val;
  }

  // if the user came to the signup flow with a URL that included
  // a utm_source querystring parameter, save the utm_source value
  // as the way the user heard about Honey Homes
  const utmParameterDataOption = getUTMParameterData();
  if (utmParameterDataOption.some && utmParameterDataOption.val.utmSource) {
    data.organicSource = utmParameterDataOption.val.utmSource;
  }

  const referralDataOption = getReferralData();
  if (referralDataOption.some) {
    const referralData = referralDataOption.val;
    data.referredById = referralData.referredById;
    data.referredBy = referralData.referredBy;
    data.organicSource = "Referral";
  }

  saveUserData(data);

  const marketingLeadIdOption = getMarketingLeadId();
  if (marketingLeadIdOption.none) {
    let marketingLeadData: MarketingLead = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      streetAddress: data.streetAddress,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      organicSource: data.organicSource,
    };

    if (utmParameterDataOption.some) {
      marketingLeadData = {
        ...marketingLeadData,
        ...utmParameterDataOption.val,
      };
    }

    try {
      const result = await createMarketingLead(marketingLeadData);

      if (result.ok) {
        saveMarketingLeadId(result.val);
      } else {
        console.log(result.val);
        throw result.val;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export function savePromoCode(promoCode: string) {
  const userDetails = getUserData();
  if (userDetails) {
    saveUserData({
      ...userDetails,
      redeemedPromoCode: promoCode,
    });
  }
}

export function checkForUTMParameters() {
  const utmParameterNames = [
    "utm_campaign",
    "utm_content",
    "utm_id",
    "utm_medium",
    "utm_source",
    "utm_source_platform",
    "utm_term",
  ];
  const params = new URLSearchParams(window.location.search);
  const lowercaseParams = new URLSearchParams(
    [...params].map(([key, value]) => [key.toLowerCase(), value]),
  );
  const utmParameterData: UTMParameterData = {};
  for (const key of lowercaseParams.keys()) {
    if (utmParameterNames.includes(key)) {
      const propertyName = key.replace(/[-_][a-z]/g, (group) =>
        group.slice(-1).toUpperCase(),
      ) as keyof UTMParameterData;
      const paramValue = lowercaseParams.get(key);
      if (propertyName && paramValue) {
        utmParameterData[propertyName] = paramValue;
      }
    }
  }
  if (Object.keys(utmParameterData).length > 0) {
    saveUTMParameterData(utmParameterData);
  }
}
