/* eslint-disable @typescript-eslint/no-var-requires */
import { atcb_action } from "add-to-calendar-button";
import { getAccessToken } from "./Firebase";
import {
  AvailableSlot,
  GeographicRegion,
  CreatePersonAndHomeParams,
  getReferralCode,
  getContentBlocksFromKeys,
} from "../schema/operations";
import { getUserData, saveUserRegionId, saveUserSource } from "./UserData";
import { DateTime } from "luxon";

export interface OnboardingData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  displayedPhoneNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  size?: string;
  referredById?: string;
  referredBy?: string;
  organicSource?: string;
  geographicRegionId?: string;
  homeId?: string;
  personId?: string;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  redeemedPromoCode?: string;
}

export interface Tokens {
  accessToken: string;
  homeId: string;
  personId: string;
}

export function saveRegions(regions: GeographicRegion[]) {
  sessionStorage.setItem("honeyHomesRegions", JSON.stringify(regions));
}

export function getRegions(): GeographicRegion[] | undefined {
  const data = sessionStorage.getItem("honeyHomesRegions");
  if (data) {
    return JSON.parse(data) as [GeographicRegion];
  }
  return;
}

export function saveAvailableSlots(slots: Readonly<AvailableSlot[]>) {
  sessionStorage.setItem("honeyHomesSlots", JSON.stringify(slots));
}

export function saveAvailabilityResultId(id: string) {
  sessionStorage.setItem("availabilityResultId", id);
}

export function getAvailableSlots(): AvailableSlot[] | undefined {
  const data = sessionStorage.getItem("honeyHomesSlots");
  if (data) {
    return JSON.parse(data) as [AvailableSlot];
  }
  return;
}

export function getAvailabilityResultId(): string | null {
  return sessionStorage.getItem("availabilityResultId");
}

export function listOptionSelected(element: HTMLElement) {
  removeSelectorFromCollection("selected", ".single-choice-list .selected");
  if (element.matches(".other")) {
    toggleOtherOption(element, true);
  } else {
    element.classList.add("selected");

    if (element.matches(".confirmable")) {
      displayListOptionConfirmFor(element);
    }

    const otherOption = document.querySelector(
      ".single-choice-list .other",
    ) as HTMLDivElement;
    if (otherOption) {
      toggleOtherOption(otherOption, false);
    }
    toggleSingleChoiceListSubmitButton(true);
  }
}

export function toggleSingleChoiceListSubmitButton(enabled: boolean) {
  const submitButton = document.querySelector(
    ".single-choice-list button[type='submit']",
  );
  if (submitButton) {
    enabled
      ? submitButton.classList.remove("disabled")
      : submitButton.classList.add("disabled");
    enabled
      ? submitButton.removeAttribute("disabled")
      : submitButton.setAttribute("disabled", "");
  }
}

function toggleOtherOption(element: HTMLElement, selected: boolean) {
  const label = element.querySelector("span");
  const input = element.querySelector("#other") as HTMLInputElement;
  const tinyLabel = element.querySelector("label");

  selected
    ? element.classList.add("other-selected")
    : element.classList.remove("other-selected");

  if (label && input) {
    selected ? label.classList.add("hidden") : label.classList.remove("hidden");
    selected ? input.classList.remove("hidden") : input.classList.add("hidden");
    selected && input.value !== ""
      ? tinyLabel?.classList.remove("hidden")
      : tinyLabel?.classList.add("hidden");

    if (selected) {
      toggleSingleChoiceListSubmitButton(input.value !== "");
      input.focus();
    }
  }
}

function displayListOptionConfirmFor(element: HTMLElement) {
  const elements = document.querySelectorAll(".single-choice-list #confirm");
  Array.from(elements).forEach(function (el) {
    el.remove();
  });

  const confirm = document.createElement("div");
  confirm.id = "confirm";
  confirm.textContent = "Confirm";
  element.appendChild(confirm);
}

function removeSelectorFromCollection(selector: string, collection: string) {
  const elements = document.querySelectorAll(collection);
  Array.from(elements).forEach(function (el) {
    el.classList.remove(selector);
  });
}

export function addToCalendar(trigger: HTMLElement) {
  const start = trigger.getAttribute("data-start");
  const end = trigger.getAttribute("data-end");

  if (!start || !end) {
    return;
  }

  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  type ListStyle = "overlay" | "modal";
  let listStyle = "modal" as ListStyle;
  if (isIOS() || isAndroid()) {
    listStyle = "overlay";
  }

  atcb_action(
    {
      name: "Honey Homes Onboarding Visit",
      description: `During this visit, you'll meet your handyman to walk-thru and discuss items on your to-do list. He'll also complete an initial set of maintenance tasks.Please download the Honey Homes app and add tasks to your "Honey-do list" tab before your visit. If you need to make changes to your appointment, you can do so from the app. Thanks!`,
      startDate: startDate.toFormat("yyyy-LL-dd"),
      endDate: endDate.toFormat("yyyy-LL-dd"),
      startTime: startDate.toLocaleString(DateTime.TIME_24_SIMPLE),
      endTime: endDate.toLocaleString(DateTime.TIME_24_SIMPLE),
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo",
      ],
      timeZone: timezone,
      listStyle: listStyle,
      iCalFileName: "Honey Homes Onboarding Visit",
    },
    trigger,
  );
}

export function selectSource(form: HTMLFormElement) {
  const source = selectedOptionFromSingleChoiceList(form);
  saveUserSource(source);
}

export function selectNeighborhood(form: HTMLFormElement) {
  const selectedRegionId = selectedOptionFromSingleChoiceList(
    form,
    "data-region-id",
  );
  saveUserRegionId(selectedRegionId);
}

export function selectedOptionFromSingleChoiceList(
  form: HTMLFormElement,
  attribute: string | undefined = undefined,
): string {
  const selectedDiv = form.querySelector(".selected") as HTMLDivElement;
  let source = "";
  if (selectedDiv) {
    if (attribute) {
      source = selectedDiv.getAttribute(attribute) ?? "";
    } else {
      source = selectedDiv.innerHTML.trim();
    }
  } else {
    const otherOption = form.querySelector("#other") as HTMLInputElement;
    if (otherOption) {
      source = otherOption.value;
    }
  }
  return source;
}

function getOS(): string {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return "iOS";
  }
  return "Other";
}

function isIOS(): boolean {
  return getOS() === "iOS";
}

function isAndroid(): boolean {
  return getOS() === "Android";
}

function isDesktop(): boolean {
  return getOS() === "Other";
}

export type AppDownload = {
  appLink: string;
  storeBadge: string;
};

export function appDownloads(): AppDownload[] {
  const iOS: AppDownload = {
    appLink: "https://apps.apple.com/us/app/honey-homes/id1579938186",
    storeBadge: "apple-badge",
  };

  const android: AppDownload = {
    appLink:
      "https://play.google.com/store/apps/details?id=com.honeyhomes.consumer",
    storeBadge: "google-badge",
  };

  if (isDesktop()) {
    return [iOS, android];
  } else {
    return isIOS() ? [iOS] : [android];
  }
}

export function hasReferral(): boolean {
  const data = getUserData();
  if (data?.referredById) {
    return true;
  }
  return false;
}

export function startLoading(
  elementId: string,
  smallLoader = false,
  greenLoader = false,
  errorId = "error-message",
): string | undefined {
  const element = document.querySelector(`#${elementId}`) as HTMLElement;
  if (!element) {
    return;
  }

  displayErrorMessage("", errorId);

  const originalValue = element.innerHTML.trim();
  element.innerHTML = "";
  const loader = document.createElement("div");
  loader.classList.add("loader");
  if (smallLoader) {
    loader.classList.add("loader-small");
  }
  if (greenLoader) {
    loader.classList.add("loader-green");
  }

  if (element instanceof HTMLButtonElement) {
    element.setAttribute("disabled", "");
  }

  element.appendChild(loader);
  return originalValue;
}

export function stopLoading(
  elementId: string,
  originalValue: string | undefined,
  errorMsg: string | null = null,
  errorId = "error-message",
) {
  const element = document.querySelector(`#${elementId}`) as HTMLElement;
  if (!element) {
    return;
  }

  if (errorMsg !== null) {
    displayErrorMessage(errorMsg, errorId);
  }

  if (element instanceof HTMLButtonElement) {
    element.removeAttribute("disabled");
  }

  const loader = element.querySelector(".loader") as HTMLDivElement;
  if (loader) {
    loader.remove();
  }
  if (element && originalValue) {
    element.innerHTML = originalValue;
  }
}

export function displayErrorMessage(
  message: string,
  containerId = "error-message",
) {
  const errorContainer = document.querySelector(`#${containerId}`);
  if (errorContainer) {
    errorContainer.textContent = message;
  }
}

export function createPersonParamsFrom(
  data: OnboardingData,
): CreatePersonAndHomeParams {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    email: data.email,
    city: data.city,
    state: data.state,
    streetAddress: data.streetAddress,
    zipCode: data.zipCode,
    geographicRegionId: data.geographicRegionId,
    organicSource: data.organicSource,
    size: "medium",
  };
}

export function divContainsALoader(div: HTMLElement): boolean {
  const loader = div.querySelector(".loader");
  return loader !== null;
}

export function appointmentTimesFrom(
  div: HTMLElement,
): [start: string, end: string] | undefined {
  const start = div.getAttribute("data-start");
  const end = div.getAttribute("data-end");

  if (!start || !end) {
    return;
  }

  return [start, end];
}

export function getTokens(): Tokens | undefined {
  const accessToken = getAccessToken();
  const homeId = getUserData()?.homeId;
  const personId = getUserData()?.personId;

  if (!accessToken || !homeId || !personId) {
    return;
  }

  return { accessToken: accessToken, homeId: homeId, personId: personId };
}

function showCopyConfirmation() {
  const copyConfirmationElement = document.querySelector(
    ".copy-confirmation",
  ) as HTMLElement;
  if (copyConfirmationElement) {
    copyConfirmationElement.classList.add("show");
    setTimeout(() => copyConfirmationElement.classList.remove("show"), 3000);
  }
}

export function initializeShareButton() {
  const userData = getUserData();
  const accessToken = getAccessToken();
  if (userData?.homeId && accessToken) {
    let referralCode = "default-code";
    const intervalID = setInterval(() => {
      if (userData.homeId) {
        getReferralCode(userData.homeId, accessToken)
          .then((result) => {
            if (result.ok) {
              const option = result.val;
              if (option.some) {
                referralCode = option.val;
                if (referralCode !== "default-code") {
                  const referralURL = `https://honeyh.me/${referralCode}`;
                  const submitButton = document.querySelector(
                    ".share-button",
                  ) as HTMLButtonElement;
                  if (submitButton) {
                    submitButton.insertAdjacentText(
                      "beforeend",
                      `honeyh.me/${referralCode}`,
                    );
                    submitButton.onclick = () => {
                      if (navigator.share) {
                        navigator
                          .share({
                            url: referralURL,
                          })
                          .catch(console.error);
                      } else {
                        navigator.clipboard
                          .writeText(referralURL)
                          .then(() => showCopyConfirmation())
                          .catch((error) => console.error(error));
                      }
                    };
                  }
                  configureSocialMediaLinks(referralURL);
                  clearInterval(intervalID);
                }
              }
            }
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      } else {
        clearInterval(intervalID);
      }
    }, 250);
  }
}

export function configureSocialMediaLinks(referralURL: string) {
  const bodyText = `Honey Homes makes knocking off home to-do's simple and stress-free. As a member, you get one dedicated handyman to care for your home. The link below will save you $50 off your membership. Enjoy! ${referralURL}`;
  const encodedBodyText = encodeURI(bodyText);

  const nextDoorIcon = document.querySelector(
    ".social-media-icon--nextdoor",
  ) as HTMLAnchorElement;
  if (nextDoorIcon) {
    const nextDoorTitle = encodeURI(`Amazing new handyman service`);
    nextDoorIcon.href = `https://nextdoor.com/share?title=${nextDoorTitle}&body=${encodedBodyText}`;
  }

  const facebookIcon = document.querySelector(
    ".social-media-icon--facebook",
  ) as HTMLAnchorElement;
  if (facebookIcon) {
    facebookIcon.href = `https://www.facebook.com/dialog/share?app_id=1022751458380778&href=${referralURL}`;
  }

  const whatsappIcon = document.querySelector(
    ".social-media-icon--whatsapp",
  ) as HTMLAnchorElement;
  if (whatsappIcon) {
    whatsappIcon.href = `https://wa.me/?text=${encodedBodyText}`;
  }

  const twitterIcon = document.querySelector(
    ".social-media-icon--twitter",
  ) as HTMLAnchorElement;
  if (twitterIcon) {
    twitterIcon.href = `https://twitter.com/intent/tweet?text=${encodedBodyText}`;
  }

  const emailIcon = document.querySelector(
    ".social-media-icon--email",
  ) as HTMLAnchorElement;
  if (emailIcon) {
    emailIcon.href = `mailto:email@example.com?subject=Sharing a great new handyman service&body=${bodyText}`;
  }
}

export function handleInput(this: HTMLInputElement | HTMLSelectElement) {
  const label = this.previousElementSibling as HTMLLabelElement;
  if (this.value === "") {
    this.classList.remove("holding-text");
    label.classList.add("hidden");
  } else {
    this.classList.add("holding-text");
    label.classList.remove("hidden");
  }
}

export function getFormattedAppointmentTimeFromISOString(
  appointmentStartTime: string,
) {
  const startDate = DateTime.fromISO(appointmentStartTime);
  // Date format of "Wednesday, August 30, 2022 at 9:30am"
  return (
    startDate.toFormat("cccc',' LLLL d',' y' at' h':'mm") +
    startDate.toFormat("a").toLowerCase()
  );
}

export function populateReferralText() {
  const contentBlockKeys = ["referrals_page_title", "referrals_page_body"];
  getContentBlocksFromKeys(contentBlockKeys)
    .then((contentBlocksResult) => {
      if (contentBlocksResult.ok) {
        const contentBlocks = contentBlocksResult.val;
        const referralHeaderElement =
          document.querySelector(".referral-header");
        if (referralHeaderElement) {
          const referralHeader = contentBlocks.filter(
            (value) => value.key === contentBlockKeys[0],
          )[0].body;
          referralHeaderElement.innerHTML = referralHeader;
        }

        const referralTextElement = document.querySelector(
          ".referral-info-text",
        );
        if (referralTextElement) {
          const referraltext = contentBlocks.filter(
            (value) => value.key === contentBlockKeys[1],
          )[0].body;
          referralTextElement.innerHTML = referraltext;
        }
      }
    })
    .catch(console.error);
}

export function attachConfirmationPageEventHandlers() {
  const qrCodeButton = document.querySelector(".qr-code-button");
  qrCodeButton?.addEventListener("click", openModal);

  const modalCloseButton = document.querySelector(".modal-close-button");
  modalCloseButton?.addEventListener("click", closeModal);

  const modal = document.querySelector(".modal");
  if (modal) {
    window.addEventListener("click", (event) => {
      if (event.target === modal) {
        closeModal();
      }
    });
  }
}

function openModal() {
  const modal = document.querySelector(".modal") as HTMLElement;
  if (modal) {
    modal.style.display = "flex";
  }
}

function closeModal() {
  const modal = document.querySelector(".modal") as HTMLElement;
  if (modal) {
    modal.style.display = "none";
  }
}

export function getConfirmationPageImageFileURLs() {
  const checkmarkBulletPointImageURL = require("../static/img/white-check-mark-in-a-teal-circle.svg");
  const appInUseImageURL = require("../static/img/app-in-use.png");
  const appInUseWithLogoImageURL = require("../static/img/app-in-use-with-logo.png");
  const homeLogoURL = require("../static/img/hh-logo.svg");
  const darkGreenTopCurveURL = require("../static/img/dark-green-top-curve.svg");
  const qrCodeButtonImageFileURL = require("../static/img/qr-code-button.svg");
  const darkGreenBottomCurveURL = require("../static/img/dark-green-bottom-curve.svg");
  const modalCloseButtonImageURL = require("../static/img/modal-close-button.svg");

  return {
    checkmarkBulletPointImageURL: checkmarkBulletPointImageURL,
    appInUseImageURL: appInUseImageURL,
    appInUseWithLogoImageURL: appInUseWithLogoImageURL,
    homeLogoURL: homeLogoURL,
    darkGreenTopCurveURL: darkGreenTopCurveURL,
    qrCodeButtonImageFileURL: qrCodeButtonImageFileURL,
    darkGreenBottomCurveURL: darkGreenBottomCurveURL,
    modalCloseButtonImageURL: modalCloseButtonImageURL,
  };
}

export function getDownloadAppLink() {
  const os = getOS();
  if (os === "iOS") {
    return "https://apps.apple.com/us/app/honey-homes/id1579938186";
  } else if (os === "Android") {
    return "https://play.google.com/store/apps/details?id=com.honeyhomes.consumer";
  }
  return `javascript:document.getElementById('app-download').scrollIntoView();`;
}

export function isZipCodeInSF(zipCode: string) {
  const sfZipCodes = [
    "94101",
    "94102",
    "94103",
    "94104",
    "94105",
    "94107",
    "94108",
    "94109",
    "94110",
    "94111",
    "94112",
    "94114",
    "94115",
    "94116",
    "94117",
    "94118",
    "94121",
    "94122",
    "94123",
    "94124",
    "94127",
    "94129",
    "94130",
    "94131",
    "94132",
    "94133",
    "94134",
    "94141",
    "94143",
    "94158",
    "94188",
  ];
  return sfZipCodes.includes(zipCode);
}

export function isZipCodeInNorthBay(zipCode: string) {
  const northBayZipCodes = ["94920", "94925", "94939", "94941", "94965"];
  return northBayZipCodes.includes(zipCode);
}

export function configureAppBanner() {
  const storeTextSpan = document.querySelector(
    ".store-text",
  ) as HTMLSpanElement;
  if (storeTextSpan) {
    if (isAndroid()) {
      storeTextSpan.innerText = "In Google Play";
    } else {
      storeTextSpan.innerText = "On the App Store";
    }
  }
}

export function saveGeoLocation(loc: {
  latitude: number;
  longitude: number;
}): void {
  sessionStorage.setItem("geoLocation", JSON.stringify(loc));
}

export function getGeoLocation(): {
  latitude: number;
  longitude: number;
} | null {
  const loc = sessionStorage.getItem("geoLocation");
  if (loc !== null) {
    return JSON.parse(loc);
  } else {
    return null;
  }
}

export function displayOverlappingApptAlert() {
  const alertElement = document.getElementById("alert-message");
  if (alertElement) {
    (alertElement.innerHTML =
      "Someone else " +
      "<i>just</i>" +
      " booked that visit time. Please select another time below:"),
      (alertElement.style.visibility = "visible");
    alertElement.style.display = "block";
  }
}
