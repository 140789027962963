import {
  homeownerClient,
  guestClient,
  $,
  publicClient,
  GraphQLError,
} from "./clients";
import { Ok, Err, Result, Option, Some, None } from "ts-results";
import {
  AvailabilityResultSource_enum,
  CreationSourceType_enum,
} from "./public";
import { DateTime } from "luxon";
import { PersonContactPreference_enum } from "./homeowner";
import { order_by as Public_order_by } from "./public";

// helper function for dealing with typescript/eslint/javascript
// error handling madness.
export function normalizeError(e: unknown): Err<Error> {
  if (e instanceof GraphQLError) {
    e.message = e.response.errors?.map((ee) => ee.message).join("\n") ?? "";
    return Err(e);
  } else if (e instanceof Error) {
    return Err(e);
  } else {
    return Err(new Error(`Unknown error ${e}`));
  }
}

export interface Handyman {
  firstName: string;
  id: string;
  profilePictureUrl: string | null;
}
interface Neighborhood {
  name: string;
}
export interface GeographicRegion {
  id: string;
  name: string;
  handyman: Handyman;
  neighborhoods: Neighborhood[];
  availableStartingAt: DateTime;
}
export async function geographicRegionsForZipCode(
  zipCode: string,
): Promise<Result<GeographicRegion[], Error>> {
  const client = publicClient("query");
  try {
    const res = await client(
      {
        GeographicRegion: [
          {},
          {
            id: true,
            servicedZipCodes: true,
            name: true,
            availableStartingAt: true,
            GeographicRegionNeighborhoods: [
              {},
              {
                name: true,
              },
            ],
            GeographicRegionHandymen: [
              {},
              {
                Handyman: {
                  id: true,
                  firstName: true,
                  ProfilePicture: {
                    publicUrl: true,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        operationName: "GeographicRegionsForZipCode",
      },
    );

    // this is absolutely not normal.
    // normally we want to do a query that's like
    // select id from "GeographicRegion" where '${zipCode}' = any("servicedZipCodes")
    // unfortunately hasura still has not added support postgres array operators like any()
    // this is such a small dataset we can pull all the records and just filter in
    // the client
    const foundRegions = res.GeographicRegion.filter((r) => {
      return (r.servicedZipCodes as string[]).includes(zipCode);
    });

    const results: GeographicRegion[] = foundRegions
      .map((e) => {
        const h = e.GeographicRegionHandymen[0].Handyman;
        const region: GeographicRegion = {
          id: e.id,
          name: e.name,
          neighborhoods: e.GeographicRegionNeighborhoods?.map((n) => ({
            name: n.name,
          })),
          availableStartingAt: DateTime.fromISO(e.availableStartingAt),
          handyman: {
            firstName: h.firstName,
            id: h.id as string,
            profilePictureUrl: h.ProfilePicture?.publicUrl ?? null,
          },
        };
        return region;
      })
      .filter((e) => e);

    return Ok(results);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function availableDateForRegion(
  zipCode: string,
): Promise<Result<Option<DateTime>, Error>> {
  const client = publicClient("query");
  try {
    const res = await client(
      {
        GeographicRegion: [
          {},
          {
            availableStartingAt: true,
            servicedZipCodes: true,
          },
        ],
      },
      {
        operationName: "AvailableDateForRegion",
      },
    );
    const region = res.GeographicRegion?.find((e) =>
      (e.servicedZipCodes as string[]).includes(zipCode),
    );
    if (!region) {
      return Ok(None);
    }
    return Ok(Some(DateTime.fromISO(region.availableStartingAt)));
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function phoneNumberExists(
  phoneNumber: string,
  guestJwt: string,
): Promise<Result<boolean, Error>> {
  const client = guestClient("query", guestJwt);
  try {
    const res = await client(
      {
        find_Person_by_phone: [
          {
            args: {
              number: $`phoneNumber`,
            },
          },
          {
            present: true,
          },
        ],
      },
      {
        operationName: "PhoneNumberExists",
        variables: {
          phoneNumber: phoneNumber,
        },
      },
    );

    return Ok(res.find_Person_by_phone?.present ?? false);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export interface CreatePersonAndHomeResult {
  homeId: string;
  personId: string;
}

export interface CreatePersonAndHomeParams {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  city: string;
  state: string;
  streetAddress: string;
  zipCode: string;
  geographicRegionId?: string;
  organicSource?: string;
  size?: "small" | "medium" | "large";
}

export async function createPersonAndHome(
  guestJwt: string,
  params: CreatePersonAndHomeParams,
): Promise<Result<CreatePersonAndHomeResult, Error>> {
  const client = guestClient("mutation", guestJwt);
  try {
    const resp = await client(
      {
        create_Person_and_Home: [
          {
            args: {
              city: $`city`,
              email: $`email`,
              firstName: $`firstName`,
              geographicRegionId: $`geographicRegionId`,
              lastName: $`lastName`,
              phoneNumber: $`phoneNumber`,
              state: $`state`,
              streetAddress: $`streetAddress`,
              zipCode: $`zipCode`,
              organicSource: $`organicSource`,
              creationSource: "web",
              size: $`size`,
            },
          },
          {
            homeId: true,
            personId: true,
          },
        ],
      },
      {
        operationName: "CreatePersonAndHome",
        variables: {
          ...params,
        },
      },
    );
    return Ok({
      personId: resp.create_Person_and_Home?.personId ?? "",
      homeId: resp.create_Person_and_Home?.homeId ?? "",
    });
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export interface AvailableTimesResult {
  availabilityResultId: Option<string>;
  availableSlots: ReadonlyArray<AvailableSlot>;
}
export interface AvailableSlot {
  beginsAt?: luxon.DateTime;
  endsAt?: luxon.DateTime;
}
export async function availableAppointmentTimes(
  personId: string,
  loc?: {
    // name location can conflict with window.location
    latitude: number;
    longitude: number;
  },
): Promise<Result<AvailableTimesResult, Error>> {
  const client = publicClient("query");
  const now = DateTime.now();
  const twoDays = now.plus({ days: 2 });
  const twoDaysFourWeeks = now.plus({ days: 2, weeks: 8 });
  try {
    let resp;
    // I think with Zeus > 5 there is a way to build the query without repeating
    if (loc) {
      resp = await client(
        {
          Availability: [
            {
              where: {
                personId: { _eq: $`personId` },
                beginsAt: { _gte: $`startTime`, _lte: $`endTime` },
                _or: [
                  {
                    serviceArea: {
                      _st_contains: $`location`,
                    },
                  },
                  {
                    serviceArea: {
                      _is_null: true,
                    },
                  },
                ],
              },
              order_by: [
                {
                  beginsAt: Public_order_by.asc,
                },
              ],
            },
            {
              personId: true,
              beginsAt: true,
              endsAt: true,
            },
          ],
        },
        {
          operationName: "AvailableAppointmentsWithLocation",
          variables: {
            personId: personId,
            startTime: twoDays.toISO(),
            endTime: twoDaysFourWeeks.toISO(),
            location: {
              type: "Point",
              coordinates: [loc.longitude, loc.latitude],
            },
          },
        },
      );
    } else {
      resp = await client(
        {
          Availability: [
            {
              where: {
                personId: { _eq: $`personId` },
                beginsAt: { _gte: $`startTime`, _lte: $`endTime` },
                serviceArea: { _is_null: true },
              },
              order_by: [
                {
                  beginsAt: Public_order_by.asc,
                },
              ],
            },
            {
              beginsAt: true,
              endsAt: true,
            },
          ],
        },
        {
          operationName: "AvailableAppointmentsWithoutLocation",
          variables: {
            personId: personId,
            startTime: twoDays.toISO(),
            endTime: twoDaysFourWeeks.toISO(),
          },
        },
      );
    }
    const trackResult = await createAvailabilityResult(resp.Availability);
    const r: AvailableTimesResult = {
      availabilityResultId: trackResult.ok ? Some(trackResult.val) : None,
      availableSlots: resp.Availability.map((e) => {
        return {
          beginsAt: DateTime.fromISO(e.beginsAt),
          endsAt: DateTime.fromISO(e.endsAt),
          personId: personId,
        };
      }),
    };
    return Ok(r);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

class OverlappingAppointmentError extends Error {
  readonly _tag = "OverlappingAppointmentError" as const;
}

class UnknownError extends Error {
  readonly _tag = "UnknownError" as const;
}

export type SystemError = OverlappingAppointmentError | UnknownError;

export async function createAppointment(
  homeId: string,
  beginsAt: string,
  endsAt: string,
  homeownerJwt: string,
): Promise<Result<string, SystemError>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    const res = await client(
      {
        insert_Appointment_one: [
          {
            object: {
              beginsAt: $`beginsAt`,
              endsAt: $`endsAt`,
              homeId: $`homeId`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "CreateAppointment",
        variables: {
          homeId: homeId,
          beginsAt: beginsAt,
          endsAt: endsAt,
        },
      },
    );
    if (res.insert_Appointment_one?.id === undefined) {
      return Err(new UnknownError(`Could not create appointment`));
    }
    return Ok(res.insert_Appointment_one.id);
  } catch (e) {
    if (
      (e as typeof GraphQLError).response.errors?.some(
        (ee: { message: string | string[] }) =>
          ee.message.includes("OverlappingAppointmentError"),
      )
    ) {
      return Err(new OverlappingAppointmentError());
    } else {
      return Err(new UnknownError(JSON.stringify(e)));
    }
  }
}

export interface CreateSetupIntentResult {
  clientSecret: string;
  ephemeralKey: string;
  publishableKey: string;
}
export async function createStripeSetupIntent(
  homeownerJwt: string,
): Promise<Result<CreateSetupIntentResult, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    const resp = await client(
      {
        create_Stripe_SetupIntent: {
          setupIntentClientSecret: true,
          ephemeralKey: true,
          publishableKey: true,
        },
      },
      {
        operationName: "CreateStripeSetupIntentForOnboarding",
      },
    );
    const result: CreateSetupIntentResult = {
      clientSecret: resp.create_Stripe_SetupIntent.setupIntentClientSecret,
      ephemeralKey: resp.create_Stripe_SetupIntent.ephemeralKey,
      publishableKey: resp.create_Stripe_SetupIntent.publishableKey,
    };
    return Ok(result);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export interface CreatePaymentIntentResult {
  ephemeralKey?: string;
  paymentIntentClientSecret?: string;
  publishableKey: string;
}
export async function createStripePaymentIntent(
  priceId: string,
  homeownerJwt: string,
): Promise<Result<CreatePaymentIntentResult, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    const res = await client(
      {
        create_Stripe_SubscriptionIntent: [
          {
            object: {
              priceId: $`priceId`,
            },
          },
          {
            ephemeralKey: true,
            paymentIntentClientSecret: true,
            publishableKey: true,
          },
        ],
      },
      {
        operationName: "PaymentIntentForOnboarding",
        variables: {
          priceId: priceId,
        },
      },
    );
    return Ok(res.create_Stripe_SubscriptionIntent);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export interface StripeSubscriptionProductPrice {
  amount: number;
  id: string;
  interval?: string | undefined;
  nickname: string;
}
export interface StripeSubscription {
  description: string;
  id: string;
  name: string;
  prices: StripeSubscriptionProductPrice[];
}
export async function stripeSubscriptionProducts(
  homeownerJwt: string,
): Promise<Result<StripeSubscription[], Error>> {
  const client = homeownerClient("query", homeownerJwt);
  try {
    const res = await client(
      {
        find_Stripe_SubscriptionProducts: {
          id: true,
          description: true,
          name: true,
          prices: {
            id: true,
            amount: true,
            interval: true,
            nickname: true,
          },
        },
      },
      {
        operationName: "StripeSubscriptionProductsForOnboarding",
      },
    );
    const r: StripeSubscription[] = res.find_Stripe_SubscriptionProducts.map(
      (e) => {
        return {
          ...e,
          prices: e.prices.map((ee) => {
            return { ...ee };
          }),
        };
      },
    );
    return Ok(r);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export type ContactPreference = "email" | "text" | "phone" | "app";
export async function setContactPreference(
  preference: ContactPreference,
  personId: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);

  // rough garbage for type safety because typescript still does not have pattern matching
  const preferenceSelection = ((
    pref: ContactPreference,
  ): PersonContactPreference_enum => {
    switch (pref) {
      case "email":
        return PersonContactPreference_enum.email;
      case "app":
        return PersonContactPreference_enum.app;
      case "phone":
        return PersonContactPreference_enum.phone;
      case "text":
        return PersonContactPreference_enum.text;
    }
  })(preference);

  try {
    await client(
      {
        update_Person_by_pk: [
          {
            _set: {
              contactPreference: $`preferenceSelection`,
            },
            pk_columns: { id: $`personId` },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "SetContactPreferenceForWaitlist",
        variables: {
          personId: personId,
          preferenceSelection: preferenceSelection,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function addToWaitlist(
  reason: "noAvailability" | "noServiceArea",
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    await client(
      {
        insert_HomeWaitlist_one: [
          {
            object: {
              reason: $`reason`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "AddToWaitlistFromOnboarding",
        variables: {
          reason: reason,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function setStripeSubscriptionPriceId(
  homeId: string,
  priceId: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    await client(
      {
        update_Home_by_pk: [
          {
            pk_columns: { id: $`homeId` },
            _set: {
              stripePriceId: $`priceId`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "SetStripePriceIdFromOnboarding",
        variables: {
          priceId: priceId,
          homeId: homeId,
        },
      },
    );
    return Promise.resolve(Ok.EMPTY);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function createReferral(
  invitedById: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    await client(
      {
        insert_Referral_one: [
          {
            object: {
              invitedById: $`invitedById`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "CreateReferralFromOnboarding",
        variables: {
          invitedById: invitedById,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function currentReferralProgramAmount(): Promise<
  Result<number, Error>
> {
  const client = publicClient("query");
  try {
    const res = await client(
      {
        ReferralProgram: [
          {},
          {
            creditAmount: true,
          },
        ],
      },
      {
        operationName: "CurrentReferallProgramForOnboarding",
      },
    );

    if (res.ReferralProgram.length === 0) {
      return Err(new Error("No current active referral programs"));
    }
    const creditAmount = res.ReferralProgram[0]?.creditAmount;
    if (creditAmount === undefined) {
      return Err(new Error("Credit amount undefined"));
    }
    return Ok(creditAmount);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export interface MarketingLead {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumberVerified?: boolean;
  homeAccountCreated?: boolean;
  firstAppointmentBeginsAt?: string;
  firstAppointmentEndsAt?: string;
  organicSource?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmId?: string;
  utmMedium?: string;
  utmSource?: string;
  utmSourcePlatform?: string;
  utmTerm?: string;
}
export async function createMarketingLead(
  lead: MarketingLead,
): Promise<Result<string, Error>> {
  const client = publicClient("mutation");
  const params = {
    phoneNumberVerified: false,
    homeAccountCreated: false,
    ...lead,
  };
  try {
    const res = await client(
      {
        insert_MarketingLead_one: [
          {
            object: {
              firstName: $`firstName`,
              lastName: $`lastName`,
              email: $`email`,
              phoneNumber: $`phoneNumber`,
              streetAddress: $`streetAddress`,
              city: $`city`,
              state: $`state`,
              zipCode: $`zipCode`,
              phoneNumberVerified: $`phoneNumberVerified`,
              homeAccountCreated: $`homeAccountCreated`,
              firstAppointmentBeginsAt: $`firstAppointmentBeginsAt`,
              firstAppointmentEndsAt: $`firstAppointmentEndsAt`,
              organicSource: $`organicSource`,
              utmCampaign: $`utmCampaign`,
              utmContent: $`utmContent`,
              utmId: $`utmId`,
              utmMedium: $`utmMedium`,
              utmSource: $`utmSource`,
              utmSourcePlatform: $`utmSourcePlatform`,
              utmTerm: $`utmTerm`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "CreateMarketingLeadFromOnboarding",
        variables: {
          ...params,
        },
      },
    );
    const id = res.insert_MarketingLead_one?.id;
    if (id === undefined) {
      return Err(new Error("Unknown error creating marketing lead"));
    }
    return Ok(id);
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function updateMarketingLead(
  id: string,
  lead: MarketingLead,
): Promise<Result<void, Error>> {
  const client = publicClient("mutation");
  const params = {
    phoneNumberVerified: false,
    homeAccountCreated: false,
    ...lead,
  };

  try {
    await client(
      {
        update_MarketingLead_by_pk: [
          {
            pk_columns: { id: $`id` },
            _set: {
              firstName: $`firstName`,
              lastName: $`lastName`,
              email: $`email`,
              phoneNumber: $`phoneNumber`,
              streetAddress: $`streetAddress`,
              city: $`city`,
              state: $`state`,
              zipCode: $`zipCode`,
              phoneNumberVerified: $`phoneNumberVerified`,
              homeAccountCreated: $`homeAccountCreated`,
              firstAppointmentBeginsAt: $`firstAppointmentBeginsAt`,
              firstAppointmentEndsAt: $`firstAppointmentEndsAt`,
              organicSource: $`organicSource`,
              utmCampaign: $`utmCampaign`,
              utmContent: $`utmContent`,
              utmId: $`utmId`,
              utmMedium: $`utmMedium`,
              utmSource: $`utmSource`,
              utmSourcePlatform: $`utmSourcePlatform`,
              utmTerm: $`utmTerm`,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "UpdateMarketingLeadFromOnboarding",
        variables: {
          id: id,
          ...params,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function checkStripePromoCode(
  code: string,
  homeownerJwt: string,
): Promise<Result<Option<string>, Error>> {
  const client = homeownerClient("query", homeownerJwt);
  try {
    const res = await client(
      {
        check_Stripe_PromoCode: [
          {
            code: $`code`,
          },
          {
            coupon: {
              name: true,
            },
          },
        ],
      },
      {
        operationName: "CheckStripePromoCodeForOnboarding",
        variables: {
          code: code,
        },
      },
    );
    if (res.check_Stripe_PromoCode.coupon) {
      return Ok(Some(res.check_Stripe_PromoCode.coupon.name));
    } else {
      return Ok(None);
    }
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function setHomePromoCode(
  promoCode: string,
  homeId: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    await client(
      {
        update_Home_by_pk: [
          {
            pk_columns: { id: $`homeId` },
            _set: { stripePromoCode: $`stripePromoCode` },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "SetStripePromoCodeFromOnboarding",
        variables: {
          stripePromoCode: promoCode,
          homeId: homeId,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function getReferralCode(
  homeId: string,
  homeownerJwt: string,
): Promise<Result<Option<string>, Error>> {
  const client = homeownerClient("query", homeownerJwt);
  try {
    const resp = await client(
      {
        Home_by_pk: [
          {
            id: $`homeId`,
          },
          {
            referralCode: true,
          },
        ],
      },
      {
        operationName: "HomeReferralCode",
        variables: {
          homeId: homeId,
        },
      },
    );
    if (resp.Home_by_pk?.referralCode) {
      return Ok(Some(resp.Home_by_pk.referralCode));
    } else {
      return Ok(None);
    }
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function updateHomeOrganicSource(
  homeId: string,
  organicSource: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> {
  const client = homeownerClient("mutation", homeownerJwt);
  try {
    await client(
      {
        update_Home_by_pk: [
          {
            pk_columns: { id: $`homeId` },
            _set: { organicSource: $`organicSource` },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "UpdateOrganicSource",
        variables: {
          homeId: homeId,
          organicSource: organicSource,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export async function getContentBlockContentByKey(
  key: string,
): Promise<Result<Option<string>, Error>> {
  const client = publicClient("query");
  try {
    const res = await client(
      {
        ContentBlock_by_pk: [
          { key: $`key` },
          {
            body: true,
          },
        ],
      },
      {
        operationName: "ContentBlockByKey",
        variables: {
          key: key,
        },
      },
    );
    const body = res.ContentBlock_by_pk?.body;
    if (body === undefined || body === null) {
      return Ok(None);
    } else {
      return Ok(Some(body));
    }
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

interface ContentBlock {
  readonly key: string;
  readonly body: string;
}
export async function getContentBlocksFromKeys(
  keys: string[],
): Promise<Result<ReadonlyArray<ContentBlock>, Error>> {
  const client = publicClient("query");
  try {
    const res = await client(
      {
        ContentBlock: [
          { where: { key: { _in: $`keys` } } },
          {
            key: true,
            body: true,
          },
        ],
      },
      {
        operationName: "ContentBlocksByKey",
        variables: {
          keys: keys,
        },
      },
    );
    return Ok(res.ContentBlock?.map((r) => r));
  } catch (e: unknown) {
    return normalizeError(e);
  }
}

export const createAvailabilityResult = async (
  results: ReadonlyArray<AvailableSlot>,
): Promise<Result<string, Error>> => {
  //eslint-disable-line
  const cl = publicClient("mutation");
  try {
    const res = await cl(
      {
        insert_AvailabilityResult_one: [
          {
            object: {
              source: AvailabilityResultSource_enum.newCustomer,
              data: $`data`,
              creationSource: CreationSourceType_enum.web,
            },
          },
          {
            id: true,
          },
        ],
      },
      {
        operationName: "CreateAvailabilityResult",
        variables: {
          data: results,
        },
      },
    );
    if (res.insert_AvailabilityResult_one?.id) {
      return Ok(res.insert_AvailabilityResult_one.id);
    } else {
      return Err(new Error("Did not create availability row"));
    }
  } catch (e: unknown) {
    return normalizeError(e);
  }
};

export const updateAvailabilityResult = async (
  availabilityResultId: string,
  appointmentId: string,
  homeownerJwt: string,
): Promise<Result<void, Error>> => {
  const cl = homeownerClient("mutation", homeownerJwt);
  try {
    await cl(
      {
        update_AvailabilityResult: [
          {
            where: { id: { _eq: $`id` } },
            _set: {
              appointmentId: $`appointmentId`,
            },
          },
          {
            returning: {
              id: true,
            },
          },
        ],
      },
      {
        operationName: "UpdateAvailabilityResult",
        variables: {
          appointmentId: appointmentId,
          id: availabilityResultId,
        },
      },
    );
    return Ok.EMPTY;
  } catch (e: unknown) {
    return normalizeError(e);
  }
};
