import { handleInput } from "../helpers/Utils";
import { abstractView } from "./AbstractView";

export function hearAboutUsView() {
  const view = abstractView("HearAboutUsView");
  view.setTitle("Honey Homes - How did you hear about us");
  view.onload = () => {
    const otherInput = document.querySelector("#other");
    otherInput?.addEventListener("keyup", handleInput);
  };
  return view;
}
