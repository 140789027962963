/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Attachment:{
		ProfilePictureFor:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Attachment_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Attachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Attachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_bool_exp:{
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_order_by:{
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor_aggregate:{
			type:"Person_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_select_column: "enum",
	Attachment_stream_cursor_input:{
		initial_value:{
			type:"Attachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Boolean_comparison_exp:{
		_eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	GeographicRegion:{
		GeographicRegionHandymen:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhoods:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GeographicRegionHandyman_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionHandyman_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionHandyman_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_select_column: "enum",
	GeographicRegionHandyman_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionHandyman_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionNeighborhood_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionNeighborhood_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		geographicRegionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_max_order_by:{
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_min_order_by:{
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_select_column: "enum",
	GeographicRegionNeighborhood_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionNeighborhood_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_stream_cursor_value_input:{
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_bool_exp:{
		GeographicRegionHandymen:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		availableStartingAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String_array_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_by_zipCode_args:{
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_order_by:{
		GeographicRegionHandymen_aggregate:{
			type:"GeographicRegionHandyman_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods_aggregate:{
			type:"GeographicRegionNeighborhood_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_select_column: "enum",
	GeographicRegion_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegion_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_stream_cursor_value_input:{
		availableStartingAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Home_bool_exp:{
		_and:{
			type:"Home_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Home_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Home_constraint: "enum",
	Home_insert_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		organicSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_obj_rel_insert_input:{
		data:{
			type:"Home_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"Home_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_on_conflict:{
		constraint:{
			type:"Home_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Home_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_update_column: "enum",
	MarketingLead_bool_exp:{
		_and:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_constraint: "enum",
	MarketingLead_insert_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentBeginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentEndsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeAccountCreated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumberVerified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_on_conflict:{
		constraint:{
			type:"MarketingLead_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"MarketingLead_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MarketingLead_select_column: "enum",
	MarketingLead_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentBeginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentEndsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeAccountCreated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumberVerified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_input:{
		initial_value:{
			type:"MarketingLead_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_update_column: "enum",
	MarketingLead_updates:{
		_set:{
			type:"MarketingLead_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Person:{
		CreatedAttachments:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PersonType_enum: "enum",
	Person_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Person_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Person_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_bool_exp:{
		CreatedAttachments:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		firstName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_constraint: "enum",
	Person_insert_input:{
		PrimaryHome:{
			type:"Home_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"PersonType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_max_order_by:{
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_min_order_by:{
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_on_conflict:{
		constraint:{
			type:"Person_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Person_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_order_by:{
		CreatedAttachments_aggregate:{
			type:"Attachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_select_column: "enum",
	Person_stream_cursor_input:{
		initial_value:{
			type:"Person_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_stream_cursor_value_input:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_update_column: "enum",
	String_array_comparison_exp:{
		_contained_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_contains:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_eq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_lte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_neq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Person_and_Home_args:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		creationSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organicSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		waitlist:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Person_and_Home_response_bool_exp:{
		_and:{
			type:"create_Person_and_Home_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"create_Person_and_Home_response_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"create_Person_and_Home_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Person_and_Home_response_order_by:{
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Person_and_Home_response_select_column: "enum",
	create_Person_and_Home_response_stream_cursor_input:{
		initial_value:{
			type:"create_Person_and_Home_response_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Person_and_Home_response_stream_cursor_value_input:{
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	cursor_ordering: "enum",
	find_Person_by_phone_args:{
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_bool_exp:{
		_and:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"find_Person_response_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		present:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_order_by:{
		present:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_select_column: "enum",
	find_Person_response_stream_cursor_input:{
		initial_value:{
			type:"find_Person_response_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_stream_cursor_value_input:{
		present:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mutation_root:{
		create_Person_and_Home:{
			args:{
				type:"create_Person_and_Home_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"create_Person_and_Home_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"create_Person_and_Home_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"create_Person_and_Home_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Home:{
			objects:{
				type:"Home_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Home_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_MarketingLead:{
			objects:{
				type:"MarketingLead_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"MarketingLead_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_MarketingLead_one:{
			object:{
				type:"MarketingLead_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"MarketingLead_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Person:{
			objects:{
				type:"Person_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Person_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Person_one:{
			object:{
				type:"Person_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Person_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		update_MarketingLead:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_by_pk:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"MarketingLead_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_many:{
			updates:{
				type:"MarketingLead_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		}
	},
	order_by: "enum",
	query_root:{
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		create_Person_and_Home_response:{
			distinct_on:{
				type:"create_Person_and_Home_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"create_Person_and_Home_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"create_Person_and_Home_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		create_Person_and_Home_response_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	subscription_root:{
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Attachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Attachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionHandyman_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionHandyman_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionNeighborhood_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegion_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MarketingLead_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"MarketingLead_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Person_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		create_Person_and_Home_response:{
			distinct_on:{
				type:"create_Person_and_Home_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"create_Person_and_Home_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"create_Person_and_Home_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		create_Person_and_Home_response_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		create_Person_and_Home_response_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"create_Person_and_Home_response_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"create_Person_and_Home_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"find_Person_response_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	Attachment:{
		CreatedBy:"Person",
		ProfilePictureFor:"Person",
		id:"uuid",
		publicUrl:"String"
	},
	GeographicRegion:{
		GeographicRegionHandymen:"GeographicRegionHandyman",
		GeographicRegionNeighborhoods:"GeographicRegionNeighborhood",
		availableStartingAt:"timestamptz",
		id:"uuid",
		name:"String",
		servicedZipCodes:"String"
	},
	GeographicRegionHandyman:{
		GeographicRegion:"GeographicRegion",
		Handyman:"Person",
		id:"uuid"
	},
	GeographicRegionNeighborhood:{
		GeographicRegion:"GeographicRegion",
		geographicRegionId:"uuid",
		id:"uuid",
		name:"String",
		zipCode:"String"
	},
	Home_mutation_response:{
		affected_rows:"Int"
	},
	MarketingLead:{
		id:"uuid"
	},
	MarketingLead_mutation_response:{
		affected_rows:"Int",
		returning:"MarketingLead"
	},
	Person:{
		CreatedAttachments:"Attachment",
		ProfilePicture:"Attachment",
		firstName:"String",
		id:"uuid"
	},
	Person_mutation_response:{
		affected_rows:"Int",
		returning:"Person"
	},
	create_Person_and_Home_response:{
		homeId:"uuid",
		id:"uuid",
		personId:"uuid"
	},
	find_Person_response:{
		present:"Boolean"
	},
	mutation_root:{
		create_Person_and_Home:"create_Person_and_Home_response",
		insert_Home:"Home_mutation_response",
		insert_MarketingLead:"MarketingLead_mutation_response",
		insert_MarketingLead_one:"MarketingLead",
		insert_Person:"Person_mutation_response",
		insert_Person_one:"Person",
		update_MarketingLead:"MarketingLead_mutation_response",
		update_MarketingLead_by_pk:"MarketingLead",
		update_MarketingLead_many:"MarketingLead_mutation_response"
	},
	query_root:{
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		Person:"Person",
		Person_by_pk:"Person",
		create_Person_and_Home_response:"create_Person_and_Home_response",
		create_Person_and_Home_response_by_pk:"create_Person_and_Home_response",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response"
	},
	subscription_root:{
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		Attachment_stream:"Attachment",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionHandyman_stream:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_stream:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		GeographicRegion_stream:"GeographicRegion",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		MarketingLead_stream:"MarketingLead",
		Person:"Person",
		Person_by_pk:"Person",
		Person_stream:"Person",
		create_Person_and_Home_response:"create_Person_and_Home_response",
		create_Person_and_Home_response_by_pk:"create_Person_and_Home_response",
		create_Person_and_Home_response_stream:"create_Person_and_Home_response",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response",
		find_Person_response_stream:"find_Person_response"
	}
}