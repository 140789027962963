import { abstractView } from "./AbstractView";
import { appDownloads, configureAppBanner } from "../helpers/Utils";

export function existingCustomerView() {
  configureAppBanner();

  const view = abstractView("ExistingCustomerView", {
    appLinks: appDownloads(),
  });
  view.setTitle("Honey Homes - Existing Customer");
  return view;
}
