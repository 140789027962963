/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    /** columns and relationships of "Appointment" */
["Appointment"]: AliasType<{
AppointmentGrades?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGrade_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGrade_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
AppointmentProviders?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider"]],
AppointmentProviders_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider_aggregate"]],
	/** An object relationship */
	AppointmentReport?:ValueTypes["AppointmentReport"],
	/** An object relationship */
	CreatedBy?:ValueTypes["Person"],
	/** An object relationship */
	Home?:ValueTypes["Home"],
Tasks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
Tasks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task_aggregate"]],
	beginsAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	endsAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	/** When true, beginsAt and endsAt define a window of time, not an exact range */
	isWindowTimes?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Homeowner review of a completed Appointment */
["AppointmentGrade"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
AppointmentGradeSelections?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGradeSelection_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGradeSelection_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null},ValueTypes["AppointmentGradeSelection"]],
	/** An object relationship */
	Home?:ValueTypes["Home"],
	/** An object relationship */
	Person?:ValueTypes["Person"],
	appointmentId?:boolean,
	createdAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	negativeComments?:boolean,
	personId?:boolean,
	positiveComments?:boolean,
	score?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Person selected categories of feedback for an AppointmentGrade */
["AppointmentGradeSelection"]: AliasType<{
	/** An object relationship */
	AppointmentGrade?:ValueTypes["AppointmentGrade"],
	/** An object relationship */
	AppointmentGradeSelectionCategory?:ValueTypes["AppointmentGradeSelectionCategory"],
	appointmentGradeId?:boolean,
	appointmentGradeSelectionCategoryId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Defines the options presented when asking for feedback from a homeowner */
["AppointmentGradeSelectionCategory"]: AliasType<{
	/** An object relationship */
	ImageAssetAttachment?:ValueTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment?:ValueTypes["Attachment"],
	createdAt?:boolean,
	description?:boolean,
	/** Text label displayed to the person for this category. */
	displayText?:boolean,
	/** Human readable unique identifier */
	friendlyAssetId?:boolean,
	id?:boolean,
	imageAssetAttachmentId?:boolean,
	updatedAt?:boolean,
	vectorAssetAttachmentId?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "AppointmentGradeSelectionCategory". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelectionCategory_bool_exp"]: {
	ImageAssetAttachment?:ValueTypes["Attachment_bool_exp"] | null,
	VectorAssetAttachment?:ValueTypes["Attachment_bool_exp"] | null,
	_and?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"][],
	_not?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"] | null,
	_or?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	description?:ValueTypes["String_comparison_exp"] | null,
	displayText?:ValueTypes["String_comparison_exp"] | null,
	friendlyAssetId?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	imageAssetAttachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	vectorAssetAttachmentId?:ValueTypes["uuid_comparison_exp"] | null
};
	/** Ordering options when selecting data from "AppointmentGradeSelectionCategory". */
["AppointmentGradeSelectionCategory_order_by"]: {
	ImageAssetAttachment?:ValueTypes["Attachment_order_by"] | null,
	VectorAssetAttachment?:ValueTypes["Attachment_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	displayText?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	imageAssetAttachmentId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	vectorAssetAttachmentId?:ValueTypes["order_by"] | null
};
	/** select columns of table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_select_column"]:AppointmentGradeSelectionCategory_select_column;
	/** Streaming cursor of the table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AppointmentGradeSelectionCategory_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelectionCategory_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	description?:string | null,
	/** Text label displayed to the person for this category. */
	displayText?:string | null,
	/** Human readable unique identifier */
	friendlyAssetId?:string | null,
	id?:ValueTypes["uuid"] | null,
	imageAssetAttachmentId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	vectorAssetAttachmentId?:ValueTypes["uuid"] | null
};
	/** order by aggregate values of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["AppointmentGradeSelection_max_order_by"] | null,
	min?:ValueTypes["AppointmentGradeSelection_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "AppointmentGradeSelection" */
["AppointmentGradeSelection_arr_rel_insert_input"]: {
	data:ValueTypes["AppointmentGradeSelection_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGradeSelection_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "AppointmentGradeSelection". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelection_bool_exp"]: {
	AppointmentGrade?:ValueTypes["AppointmentGrade_bool_exp"] | null,
	AppointmentGradeSelectionCategory?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"] | null,
	_and?:ValueTypes["AppointmentGradeSelection_bool_exp"][],
	_not?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null,
	_or?:ValueTypes["AppointmentGradeSelection_bool_exp"][],
	appointmentGradeId?:ValueTypes["uuid_comparison_exp"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "AppointmentGradeSelection" */
["AppointmentGradeSelection_constraint"]:AppointmentGradeSelection_constraint;
	/** input type for inserting data into table "AppointmentGradeSelection" */
["AppointmentGradeSelection_insert_input"]: {
	AppointmentGrade?:ValueTypes["AppointmentGrade_obj_rel_insert_input"] | null,
	appointmentGradeId?:ValueTypes["uuid"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["uuid"] | null,
	/** True means selection is a category that went well. */
	isPositive?:boolean | null
};
	/** order by max() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_max_order_by"]: {
	appointmentGradeId?:ValueTypes["order_by"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_min_order_by"]: {
	appointmentGradeId?:ValueTypes["order_by"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["AppointmentGradeSelection"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "AppointmentGradeSelection" */
["AppointmentGradeSelection_on_conflict"]: {
	constraint:ValueTypes["AppointmentGradeSelection_constraint"],
	update_columns:ValueTypes["AppointmentGradeSelection_update_column"][],
	where?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null
};
	/** Ordering options when selecting data from "AppointmentGradeSelection". */
["AppointmentGradeSelection_order_by"]: {
	AppointmentGrade?:ValueTypes["AppointmentGrade_order_by"] | null,
	AppointmentGradeSelectionCategory?:ValueTypes["AppointmentGradeSelectionCategory_order_by"] | null,
	appointmentGradeId?:ValueTypes["order_by"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_select_column"]:AppointmentGradeSelection_select_column;
	/** Streaming cursor of the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AppointmentGradeSelection_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelection_stream_cursor_value_input"]: {
	appointmentGradeId?:ValueTypes["uuid"] | null,
	appointmentGradeSelectionCategoryId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** placeholder for update columns of table "AppointmentGradeSelection" (current role has no relevant permissions) */
["AppointmentGradeSelection_update_column"]:AppointmentGradeSelection_update_column;
	/** order by aggregate values of table "AppointmentGrade" */
["AppointmentGrade_aggregate_order_by"]: {
	avg?:ValueTypes["AppointmentGrade_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["AppointmentGrade_max_order_by"] | null,
	min?:ValueTypes["AppointmentGrade_min_order_by"] | null,
	stddev?:ValueTypes["AppointmentGrade_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["AppointmentGrade_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["AppointmentGrade_stddev_samp_order_by"] | null,
	sum?:ValueTypes["AppointmentGrade_sum_order_by"] | null,
	var_pop?:ValueTypes["AppointmentGrade_var_pop_order_by"] | null,
	var_samp?:ValueTypes["AppointmentGrade_var_samp_order_by"] | null,
	variance?:ValueTypes["AppointmentGrade_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "AppointmentGrade" */
["AppointmentGrade_arr_rel_insert_input"]: {
	data:ValueTypes["AppointmentGrade_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGrade_on_conflict"] | null
};
	/** order by avg() on columns of table "AppointmentGrade" */
["AppointmentGrade_avg_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "AppointmentGrade". All fields are combined with a logical 'AND'. */
["AppointmentGrade_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	AppointmentGradeSelections?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null,
	Home?:ValueTypes["Home_bool_exp"] | null,
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["AppointmentGrade_bool_exp"][],
	_not?:ValueTypes["AppointmentGrade_bool_exp"] | null,
	_or?:ValueTypes["AppointmentGrade_bool_exp"][],
	appointmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	negativeComments?:ValueTypes["String_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	positiveComments?:ValueTypes["String_comparison_exp"] | null,
	score?:ValueTypes["smallint_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "AppointmentGrade" */
["AppointmentGrade_constraint"]:AppointmentGrade_constraint;
	/** input type for inserting data into table "AppointmentGrade" */
["AppointmentGrade_insert_input"]: {
	Appointment?:ValueTypes["Appointment_obj_rel_insert_input"] | null,
	AppointmentGradeSelections?:ValueTypes["AppointmentGradeSelection_arr_rel_insert_input"] | null,
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	appointmentId?:ValueTypes["uuid"] | null,
	negativeComments?:string | null,
	positiveComments?:string | null,
	score?:ValueTypes["smallint"] | null
};
	/** order by max() on columns of table "AppointmentGrade" */
["AppointmentGrade_max_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	negativeComments?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	positiveComments?:ValueTypes["order_by"] | null,
	score?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "AppointmentGrade" */
["AppointmentGrade_min_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	negativeComments?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	positiveComments?:ValueTypes["order_by"] | null,
	score?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "AppointmentGrade" */
["AppointmentGrade_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["AppointmentGrade"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "AppointmentGrade" */
["AppointmentGrade_obj_rel_insert_input"]: {
	data:ValueTypes["AppointmentGrade_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGrade_on_conflict"] | null
};
	/** on_conflict condition type for table "AppointmentGrade" */
["AppointmentGrade_on_conflict"]: {
	constraint:ValueTypes["AppointmentGrade_constraint"],
	update_columns:ValueTypes["AppointmentGrade_update_column"][],
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null
};
	/** Ordering options when selecting data from "AppointmentGrade". */
["AppointmentGrade_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	AppointmentGradeSelections_aggregate?:ValueTypes["AppointmentGradeSelection_aggregate_order_by"] | null,
	Home?:ValueTypes["Home_order_by"] | null,
	Person?:ValueTypes["Person_order_by"] | null,
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	negativeComments?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	positiveComments?:ValueTypes["order_by"] | null,
	score?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "AppointmentGrade" */
["AppointmentGrade_select_column"]:AppointmentGrade_select_column;
	/** order by stddev() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_pop_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_samp_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "AppointmentGrade" */
["AppointmentGrade_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AppointmentGrade_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGrade_stream_cursor_value_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	negativeComments?:string | null,
	personId?:ValueTypes["uuid"] | null,
	positiveComments?:string | null,
	score?:ValueTypes["smallint"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "AppointmentGrade" */
["AppointmentGrade_sum_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** placeholder for update columns of table "AppointmentGrade" (current role has no relevant permissions) */
["AppointmentGrade_update_column"]:AppointmentGrade_update_column;
	/** order by var_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_pop_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_samp_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "AppointmentGrade" */
["AppointmentGrade_variance_order_by"]: {
	score?:ValueTypes["order_by"] | null
};
	/** Associates people and organizations that provide service for an Appointment */
["AppointmentProvider"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
	/** An object relationship */
	Person?:ValueTypes["Person"],
	/** An object relationship */
	ServiceProvider?:ValueTypes["ServiceProvider"],
	appointmentId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	serviceProviderId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** aggregated selection of "AppointmentProvider" */
["AppointmentProvider_aggregate"]: AliasType<{
	aggregate?:ValueTypes["AppointmentProvider_aggregate_fields"],
	nodes?:ValueTypes["AppointmentProvider"],
		__typename?: boolean
}>;
	["AppointmentProvider_aggregate_bool_exp"]: {
	count?:ValueTypes["AppointmentProvider_aggregate_bool_exp_count"] | null
};
	["AppointmentProvider_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["AppointmentProvider_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["AppointmentProvider_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "AppointmentProvider" */
["AppointmentProvider_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["AppointmentProvider_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["AppointmentProvider_max_fields"],
	min?:ValueTypes["AppointmentProvider_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "AppointmentProvider" */
["AppointmentProvider_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["AppointmentProvider_max_order_by"] | null,
	min?:ValueTypes["AppointmentProvider_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "AppointmentProvider" */
["AppointmentProvider_arr_rel_insert_input"]: {
	data:ValueTypes["AppointmentProvider_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentProvider_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "AppointmentProvider". All fields are combined with a logical 'AND'. */
["AppointmentProvider_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	Person?:ValueTypes["Person_bool_exp"] | null,
	ServiceProvider?:ValueTypes["ServiceProvider_bool_exp"] | null,
	_and?:ValueTypes["AppointmentProvider_bool_exp"][],
	_not?:ValueTypes["AppointmentProvider_bool_exp"] | null,
	_or?:ValueTypes["AppointmentProvider_bool_exp"][],
	appointmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	serviceProviderId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "AppointmentProvider" */
["AppointmentProvider_constraint"]:AppointmentProvider_constraint;
	/** input type for inserting data into table "AppointmentProvider" */
["AppointmentProvider_insert_input"]: {
	Appointment?:ValueTypes["Appointment_obj_rel_insert_input"] | null,
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	appointmentId?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null,
	serviceProviderId?:ValueTypes["uuid"] | null
};
	/** aggregate max on columns */
["AppointmentProvider_max_fields"]: AliasType<{
	appointmentId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	serviceProviderId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "AppointmentProvider" */
["AppointmentProvider_max_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	serviceProviderId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["AppointmentProvider_min_fields"]: AliasType<{
	appointmentId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	serviceProviderId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "AppointmentProvider" */
["AppointmentProvider_min_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	serviceProviderId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "AppointmentProvider" */
["AppointmentProvider_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["AppointmentProvider"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "AppointmentProvider" */
["AppointmentProvider_on_conflict"]: {
	constraint:ValueTypes["AppointmentProvider_constraint"],
	update_columns:ValueTypes["AppointmentProvider_update_column"][],
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null
};
	/** Ordering options when selecting data from "AppointmentProvider". */
["AppointmentProvider_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	Person?:ValueTypes["Person_order_by"] | null,
	ServiceProvider?:ValueTypes["ServiceProvider_order_by"] | null,
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	serviceProviderId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "AppointmentProvider" */
["AppointmentProvider_select_column"]:AppointmentProvider_select_column;
	/** Streaming cursor of the table "AppointmentProvider" */
["AppointmentProvider_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AppointmentProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AppointmentProvider_stream_cursor_value_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null,
	serviceProviderId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** placeholder for update columns of table "AppointmentProvider" (current role has no relevant permissions) */
["AppointmentProvider_update_column"]:AppointmentProvider_update_column;
	/** Information about work completed in an appointment */
["AppointmentReport"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
	/** An object relationship */
	CreatedBy?:ValueTypes["Person"],
	/** An object relationship */
	ReviewedBy?:ValueTypes["Person"],
TaskUpdateLogs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
TaskUpdateLogs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog_aggregate"]],
	appointmentId?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	id?:boolean,
	reviewCompletedAt?:boolean,
	reviewedById?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "AppointmentReport" */
["AppointmentReport_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["AppointmentReport_max_order_by"] | null,
	min?:ValueTypes["AppointmentReport_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "AppointmentReport". All fields are combined with a logical 'AND'. */
["AppointmentReport_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	CreatedBy?:ValueTypes["Person_bool_exp"] | null,
	ReviewedBy?:ValueTypes["Person_bool_exp"] | null,
	TaskUpdateLogs?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_bool_exp"] | null,
	_and?:ValueTypes["AppointmentReport_bool_exp"][],
	_not?:ValueTypes["AppointmentReport_bool_exp"] | null,
	_or?:ValueTypes["AppointmentReport_bool_exp"][],
	appointmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdById?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	reviewCompletedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	reviewedById?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "AppointmentReport" */
["AppointmentReport_max_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	reviewCompletedAt?:ValueTypes["order_by"] | null,
	reviewedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "AppointmentReport" */
["AppointmentReport_min_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	reviewCompletedAt?:ValueTypes["order_by"] | null,
	reviewedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "AppointmentReport". */
["AppointmentReport_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	CreatedBy?:ValueTypes["Person_order_by"] | null,
	ReviewedBy?:ValueTypes["Person_order_by"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_order_by"] | null,
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	reviewCompletedAt?:ValueTypes["order_by"] | null,
	reviewedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "AppointmentReport" */
["AppointmentReport_select_column"]:AppointmentReport_select_column;
	/** Streaming cursor of the table "AppointmentReport" */
["AppointmentReport_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AppointmentReport_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AppointmentReport_stream_cursor_value_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	createdById?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	reviewCompletedAt?:ValueTypes["timestamptz"] | null,
	reviewedById?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** aggregated selection of "Appointment" */
["Appointment_aggregate"]: AliasType<{
	aggregate?:ValueTypes["Appointment_aggregate_fields"],
	nodes?:ValueTypes["Appointment"],
		__typename?: boolean
}>;
	["Appointment_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["Appointment_aggregate_bool_exp_bool_and"] | null,
	bool_or?:ValueTypes["Appointment_aggregate_bool_exp_bool_or"] | null,
	count?:ValueTypes["Appointment_aggregate_bool_exp_count"] | null
};
	["Appointment_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["Appointment_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["Appointment_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["Appointment_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["Appointment_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["Appointment_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["Appointment_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Appointment" */
["Appointment_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["Appointment_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["Appointment_max_fields"],
	min?:ValueTypes["Appointment_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "Appointment" */
["Appointment_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Appointment_max_order_by"] | null,
	min?:ValueTypes["Appointment_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "Appointment" */
["Appointment_arr_rel_insert_input"]: {
	data:ValueTypes["Appointment_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["Appointment_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "Appointment". All fields are combined with a logical 'AND'. */
["Appointment_bool_exp"]: {
	AppointmentGrades?:ValueTypes["AppointmentGrade_bool_exp"] | null,
	AppointmentProviders?:ValueTypes["AppointmentProvider_bool_exp"] | null,
	AppointmentProviders_aggregate?:ValueTypes["AppointmentProvider_aggregate_bool_exp"] | null,
	AppointmentReport?:ValueTypes["AppointmentReport_bool_exp"] | null,
	CreatedBy?:ValueTypes["Person_bool_exp"] | null,
	Home?:ValueTypes["Home_bool_exp"] | null,
	Tasks?:ValueTypes["Task_bool_exp"] | null,
	Tasks_aggregate?:ValueTypes["Task_aggregate_bool_exp"] | null,
	_and?:ValueTypes["Appointment_bool_exp"][],
	_not?:ValueTypes["Appointment_bool_exp"] | null,
	_or?:ValueTypes["Appointment_bool_exp"][],
	beginsAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdById?:ValueTypes["uuid_comparison_exp"] | null,
	endsAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	isWindowTimes?:ValueTypes["Boolean_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Appointment" */
["Appointment_constraint"]:Appointment_constraint;
	/** input type for inserting data into table "Appointment" */
["Appointment_insert_input"]: {
	AppointmentGrades?:ValueTypes["AppointmentGrade_arr_rel_insert_input"] | null,
	AppointmentProviders?:ValueTypes["AppointmentProvider_arr_rel_insert_input"] | null,
	CreatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	Tasks?:ValueTypes["Task_arr_rel_insert_input"] | null,
	beginsAt?:ValueTypes["timestamptz"] | null,
	endsAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	/** When true, automatically create an AppointmentProvider with the default Person assigned to the Home */
	usesDefaultProvider?:boolean | null
};
	/** aggregate max on columns */
["Appointment_max_fields"]: AliasType<{
	beginsAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	endsAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "Appointment" */
["Appointment_max_order_by"]: {
	beginsAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["Appointment_min_fields"]: AliasType<{
	beginsAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	endsAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "Appointment" */
["Appointment_min_order_by"]: {
	beginsAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Appointment" */
["Appointment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Appointment"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "Appointment" */
["Appointment_obj_rel_insert_input"]: {
	data:ValueTypes["Appointment_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["Appointment_on_conflict"] | null
};
	/** on_conflict condition type for table "Appointment" */
["Appointment_on_conflict"]: {
	constraint:ValueTypes["Appointment_constraint"],
	update_columns:ValueTypes["Appointment_update_column"][],
	where?:ValueTypes["Appointment_bool_exp"] | null
};
	/** Ordering options when selecting data from "Appointment". */
["Appointment_order_by"]: {
	AppointmentGrades_aggregate?:ValueTypes["AppointmentGrade_aggregate_order_by"] | null,
	AppointmentProviders_aggregate?:ValueTypes["AppointmentProvider_aggregate_order_by"] | null,
	AppointmentReport?:ValueTypes["AppointmentReport_order_by"] | null,
	CreatedBy?:ValueTypes["Person_order_by"] | null,
	Home?:ValueTypes["Home_order_by"] | null,
	Tasks_aggregate?:ValueTypes["Task_aggregate_order_by"] | null,
	beginsAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	isWindowTimes?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Appointment */
["Appointment_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "Appointment" */
["Appointment_select_column"]:Appointment_select_column;
	/** select "Appointment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"]:Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "Appointment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"]:Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "Appointment" */
["Appointment_set_input"]: {
	beginsAt?:ValueTypes["timestamptz"] | null,
	endsAt?:ValueTypes["timestamptz"] | null
};
	/** Streaming cursor of the table "Appointment" */
["Appointment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Appointment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Appointment_stream_cursor_value_input"]: {
	beginsAt?:ValueTypes["timestamptz"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	createdById?:ValueTypes["uuid"] | null,
	endsAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	/** When true, beginsAt and endsAt define a window of time, not an exact range */
	isWindowTimes?:boolean | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** update columns of table "Appointment" */
["Appointment_update_column"]:Appointment_update_column;
	["Appointment_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Appointment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Appointment_bool_exp"]
};
	/** File attachments to any other object. */
["Attachment"]: AliasType<{
	/** An object relationship */
	CreatedBy?:ValueTypes["Person"],
HomeInventoryItemAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
ProfilePictureFor?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person"]],
ProfilePictureFor_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person_aggregate"]],
TaskAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskAttachment_bool_exp"] | null},ValueTypes["TaskAttachment"]],
TaskUpdateLogAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLogAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLogAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null},ValueTypes["TaskUpdateLogAttachment"]],
	/** User provided caption */
	caption?:boolean,
	contentType?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	/** Not auto generated, must be provided */
	id?:boolean,
	publicUrl?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "Attachment" */
["Attachment_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Attachment_max_order_by"] | null,
	min?:ValueTypes["Attachment_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "Attachment" */
["Attachment_arr_rel_insert_input"]: {
	data:ValueTypes["Attachment_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["Attachment_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
["Attachment_bool_exp"]: {
	CreatedBy?:ValueTypes["Person_bool_exp"] | null,
	HomeInventoryItemAttachments?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null,
	ProfilePictureFor?:ValueTypes["Person_bool_exp"] | null,
	ProfilePictureFor_aggregate?:ValueTypes["Person_aggregate_bool_exp"] | null,
	TaskAttachments?:ValueTypes["TaskAttachment_bool_exp"] | null,
	TaskUpdateLogAttachments?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null,
	_and?:ValueTypes["Attachment_bool_exp"][],
	_not?:ValueTypes["Attachment_bool_exp"] | null,
	_or?:ValueTypes["Attachment_bool_exp"][],
	caption?:ValueTypes["String_comparison_exp"] | null,
	contentType?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdById?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	publicUrl?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Attachment" */
["Attachment_constraint"]:Attachment_constraint;
	/** input type for inserting data into table "Attachment" */
["Attachment_insert_input"]: {
	CreatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	HomeInventoryItemAttachments?:ValueTypes["HomeInventoryItemAttachment_arr_rel_insert_input"] | null,
	ProfilePictureFor?:ValueTypes["Person_arr_rel_insert_input"] | null,
	TaskAttachments?:ValueTypes["TaskAttachment_arr_rel_insert_input"] | null,
	TaskUpdateLogAttachments?:ValueTypes["TaskUpdateLogAttachment_arr_rel_insert_input"] | null,
	/** User provided caption */
	caption?:string | null,
	/** Not auto generated, must be provided */
	id?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "Attachment" */
["Attachment_max_order_by"]: {
	/** User provided caption */
	caption?:ValueTypes["order_by"] | null,
	contentType?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	/** Not auto generated, must be provided */
	id?:ValueTypes["order_by"] | null,
	publicUrl?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "Attachment" */
["Attachment_min_order_by"]: {
	/** User provided caption */
	caption?:ValueTypes["order_by"] | null,
	contentType?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	/** Not auto generated, must be provided */
	id?:ValueTypes["order_by"] | null,
	publicUrl?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Attachment" */
["Attachment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Attachment"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "Attachment" */
["Attachment_obj_rel_insert_input"]: {
	data:ValueTypes["Attachment_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["Attachment_on_conflict"] | null
};
	/** on_conflict condition type for table "Attachment" */
["Attachment_on_conflict"]: {
	constraint:ValueTypes["Attachment_constraint"],
	update_columns:ValueTypes["Attachment_update_column"][],
	where?:ValueTypes["Attachment_bool_exp"] | null
};
	/** Ordering options when selecting data from "Attachment". */
["Attachment_order_by"]: {
	CreatedBy?:ValueTypes["Person_order_by"] | null,
	HomeInventoryItemAttachments_aggregate?:ValueTypes["HomeInventoryItemAttachment_aggregate_order_by"] | null,
	ProfilePictureFor_aggregate?:ValueTypes["Person_aggregate_order_by"] | null,
	TaskAttachments_aggregate?:ValueTypes["TaskAttachment_aggregate_order_by"] | null,
	TaskUpdateLogAttachments_aggregate?:ValueTypes["TaskUpdateLogAttachment_aggregate_order_by"] | null,
	caption?:ValueTypes["order_by"] | null,
	contentType?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	publicUrl?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Attachment */
["Attachment_pk_columns_input"]: {
	/** Not auto generated, must be provided */
	id:ValueTypes["uuid"]
};
	/** select columns of table "Attachment" */
["Attachment_select_column"]:Attachment_select_column;
	/** input type for updating data in table "Attachment" */
["Attachment_set_input"]: {
	/** User provided caption */
	caption?:string | null,
	/** Not auto generated, must be provided */
	id?:ValueTypes["uuid"] | null
};
	/** Streaming cursor of the table "Attachment" */
["Attachment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Attachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Attachment_stream_cursor_value_input"]: {
	/** User provided caption */
	caption?:string | null,
	contentType?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	createdById?:ValueTypes["uuid"] | null,
	/** Not auto generated, must be provided */
	id?:ValueTypes["uuid"] | null,
	publicUrl?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** update columns of table "Attachment" */
["Attachment_update_column"]:Attachment_update_column;
	["Attachment_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Attachment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Attachment_bool_exp"]
};
	/** columns and relationships of "Availability" */
["Availability"]: AliasType<{
	/** An object relationship */
	Person?:ValueTypes["Person"],
	beginsAt?:boolean,
	endsAt?:boolean,
	personId?:boolean,
	serviceArea?:boolean,
		__typename?: boolean
}>;
	/** Result of an Availability query by the perspective of a Home */
["AvailabilityResult"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
	/** An object relationship */
	Home?:ValueTypes["Home"],
	appointmentId?:boolean,
	createdAt?:boolean,
	creationSource?:boolean,
data?: [{	/** JSON select path */
	path?:string | null},boolean],
	homeId?:boolean,
	id?:boolean,
	source?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["AvailabilityResultSource_enum"]:AvailabilityResultSource_enum;
	/** Boolean expression to compare columns of type "AvailabilityResultSource_enum". All fields are combined with logical 'AND'. */
["AvailabilityResultSource_enum_comparison_exp"]: {
	_eq?:ValueTypes["AvailabilityResultSource_enum"] | null,
	_in?:ValueTypes["AvailabilityResultSource_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["AvailabilityResultSource_enum"] | null,
	_nin?:ValueTypes["AvailabilityResultSource_enum"][]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_append_input"]: {
	data?:ValueTypes["jsonb"] | null
};
	/** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
["AvailabilityResult_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	Home?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["AvailabilityResult_bool_exp"][],
	_not?:ValueTypes["AvailabilityResult_bool_exp"] | null,
	_or?:ValueTypes["AvailabilityResult_bool_exp"][],
	appointmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	creationSource?:ValueTypes["CreationSourceType_enum_comparison_exp"] | null,
	data?:ValueTypes["jsonb_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	source?:ValueTypes["AvailabilityResultSource_enum_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["AvailabilityResult_delete_at_path_input"]: {
	data?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["AvailabilityResult_delete_elem_input"]: {
	data?:number | null
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["AvailabilityResult_delete_key_input"]: {
	data?:string | null
};
	/** input type for inserting data into table "AvailabilityResult" */
["AvailabilityResult_insert_input"]: {
	Appointment?:ValueTypes["Appointment_obj_rel_insert_input"] | null,
	appointmentId?:ValueTypes["uuid"] | null,
	creationSource?:ValueTypes["CreationSourceType_enum"] | null,
	data?:ValueTypes["jsonb"] | null,
	source?:ValueTypes["AvailabilityResultSource_enum"] | null
};
	/** response of any mutation on the table "AvailabilityResult" */
["AvailabilityResult_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["AvailabilityResult"],
		__typename?: boolean
}>;
	/** Ordering options when selecting data from "AvailabilityResult". */
["AvailabilityResult_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	Home?:ValueTypes["Home_order_by"] | null,
	appointmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	creationSource?:ValueTypes["order_by"] | null,
	data?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	source?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_prepend_input"]: {
	data?:ValueTypes["jsonb"] | null
};
	/** select columns of table "AvailabilityResult" */
["AvailabilityResult_select_column"]:AvailabilityResult_select_column;
	/** input type for updating data in table "AvailabilityResult" */
["AvailabilityResult_set_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	data?:ValueTypes["jsonb"] | null,
	source?:ValueTypes["AvailabilityResultSource_enum"] | null
};
	/** Streaming cursor of the table "AvailabilityResult" */
["AvailabilityResult_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AvailabilityResult_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AvailabilityResult_stream_cursor_value_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	creationSource?:ValueTypes["CreationSourceType_enum"] | null,
	data?:ValueTypes["jsonb"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	source?:ValueTypes["AvailabilityResultSource_enum"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	["AvailabilityResult_updates"]: {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["AvailabilityResult_append_input"] | null,
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["AvailabilityResult_delete_at_path_input"] | null,
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["AvailabilityResult_delete_elem_input"] | null,
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["AvailabilityResult_delete_key_input"] | null,
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["AvailabilityResult_prepend_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["AvailabilityResult_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["AvailabilityResult_bool_exp"]
};
	/** order by aggregate values of table "Availability" */
["Availability_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Availability_max_order_by"] | null,
	min?:ValueTypes["Availability_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
["Availability_bool_exp"]: {
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["Availability_bool_exp"][],
	_not?:ValueTypes["Availability_bool_exp"] | null,
	_or?:ValueTypes["Availability_bool_exp"][],
	beginsAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	endsAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	serviceArea?:ValueTypes["geometry_comparison_exp"] | null
};
	/** order by max() on columns of table "Availability" */
["Availability_max_order_by"]: {
	beginsAt?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "Availability" */
["Availability_min_order_by"]: {
	beginsAt?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "Availability". */
["Availability_order_by"]: {
	Person?:ValueTypes["Person_order_by"] | null,
	beginsAt?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	serviceArea?:ValueTypes["order_by"] | null
};
	/** select columns of table "Availability" */
["Availability_select_column"]:Availability_select_column;
	/** Streaming cursor of the table "Availability" */
["Availability_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Availability_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Availability_stream_cursor_value_input"]: {
	beginsAt?:ValueTypes["timestamptz"] | null,
	endsAt?:ValueTypes["timestamptz"] | null,
	personId?:ValueTypes["uuid"] | null,
	serviceArea?:ValueTypes["geometry"] | null
};
	/** columns and relationships of "AvailableAppointment" */
["AvailableAppointment"]: AliasType<{
	beginsAt?:boolean,
	endsAt?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "AvailableAppointment". All fields are combined with a logical 'AND'. */
["AvailableAppointment_bool_exp"]: {
	_and?:ValueTypes["AvailableAppointment_bool_exp"][],
	_not?:ValueTypes["AvailableAppointment_bool_exp"] | null,
	_or?:ValueTypes["AvailableAppointment_bool_exp"][],
	beginsAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	endsAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** Ordering options when selecting data from "AvailableAppointment". */
["AvailableAppointment_order_by"]: {
	beginsAt?:ValueTypes["order_by"] | null,
	endsAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "AvailableAppointment" */
["AvailableAppointment_select_column"]:AvailableAppointment_select_column;
	/** Streaming cursor of the table "AvailableAppointment" */
["AvailableAppointment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["AvailableAppointment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["AvailableAppointment_stream_cursor_value_input"]: {
	beginsAt?:ValueTypes["timestamptz"] | null,
	endsAt?:ValueTypes["timestamptz"] | null
};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
	_eq?:boolean | null,
	_gt?:boolean | null,
	_gte?:boolean | null,
	_in?:boolean[],
	_is_null?:boolean | null,
	_lt?:boolean | null,
	_lte?:boolean | null,
	_neq?:boolean | null,
	_nin?:boolean[]
};
	/** columns and relationships of "ClientRelease" */
["ClientRelease"]: AliasType<{
	build?:boolean,
	client?:boolean,
	createdAt?:boolean,
	expirationDate?:boolean,
	expirationMessage?:boolean,
	id?:boolean,
	releaseDate?:boolean,
	updatedAt?:boolean,
	version?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ClientRelease". All fields are combined with a logical 'AND'. */
["ClientRelease_bool_exp"]: {
	_and?:ValueTypes["ClientRelease_bool_exp"][],
	_not?:ValueTypes["ClientRelease_bool_exp"] | null,
	_or?:ValueTypes["ClientRelease_bool_exp"][],
	build?:ValueTypes["String_comparison_exp"] | null,
	client?:ValueTypes["ClientType_enum_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	expirationDate?:ValueTypes["timestamptz_comparison_exp"] | null,
	expirationMessage?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	releaseDate?:ValueTypes["timestamptz_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	version?:ValueTypes["String_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ClientRelease". */
["ClientRelease_order_by"]: {
	build?:ValueTypes["order_by"] | null,
	client?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	expirationDate?:ValueTypes["order_by"] | null,
	expirationMessage?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	releaseDate?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	version?:ValueTypes["order_by"] | null
};
	/** select columns of table "ClientRelease" */
["ClientRelease_select_column"]:ClientRelease_select_column;
	/** Streaming cursor of the table "ClientRelease" */
["ClientRelease_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ClientRelease_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ClientRelease_stream_cursor_value_input"]: {
	build?:string | null,
	client?:ValueTypes["ClientType_enum"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	expirationDate?:ValueTypes["timestamptz"] | null,
	expirationMessage?:string | null,
	id?:ValueTypes["uuid"] | null,
	releaseDate?:ValueTypes["timestamptz"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	version?:string | null
};
	/** columns and relationships of "ClientType" */
["ClientType"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ClientType". All fields are combined with a logical 'AND'. */
["ClientType_bool_exp"]: {
	_and?:ValueTypes["ClientType_bool_exp"][],
	_not?:ValueTypes["ClientType_bool_exp"] | null,
	_or?:ValueTypes["ClientType_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["ClientType_enum"]:ClientType_enum;
	/** Boolean expression to compare columns of type "ClientType_enum". All fields are combined with logical 'AND'. */
["ClientType_enum_comparison_exp"]: {
	_eq?:ValueTypes["ClientType_enum"] | null,
	_in?:ValueTypes["ClientType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["ClientType_enum"] | null,
	_nin?:ValueTypes["ClientType_enum"][]
};
	/** Ordering options when selecting data from "ClientType". */
["ClientType_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "ClientType" */
["ClientType_select_column"]:ClientType_select_column;
	/** Streaming cursor of the table "ClientType" */
["ClientType_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ClientType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ClientType_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** columns and relationships of "ContentBlock" */
["ContentBlock"]: AliasType<{
	body?:boolean,
	key?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
["ContentBlock_bool_exp"]: {
	_and?:ValueTypes["ContentBlock_bool_exp"][],
	_not?:ValueTypes["ContentBlock_bool_exp"] | null,
	_or?:ValueTypes["ContentBlock_bool_exp"][],
	body?:ValueTypes["String_comparison_exp"] | null,
	key?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ContentBlock". */
["ContentBlock_order_by"]: {
	body?:ValueTypes["order_by"] | null,
	key?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "ContentBlock" */
["ContentBlock_select_column"]:ContentBlock_select_column;
	/** Streaming cursor of the table "ContentBlock" */
["ContentBlock_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ContentBlock_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ContentBlock_stream_cursor_value_input"]: {
	body?:string | null,
	key?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	["CreationSourceType_enum"]:CreationSourceType_enum;
	/** Boolean expression to compare columns of type "CreationSourceType_enum". All fields are combined with logical 'AND'. */
["CreationSourceType_enum_comparison_exp"]: {
	_eq?:ValueTypes["CreationSourceType_enum"] | null,
	_in?:ValueTypes["CreationSourceType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["CreationSourceType_enum"] | null,
	_nin?:ValueTypes["CreationSourceType_enum"][]
};
	/** Tracks a request from a Person to delete all of their data */
["DataDeletionRequest"]: AliasType<{
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "DataDeletionRequest". All fields are combined with a logical 'AND'. */
["DataDeletionRequest_bool_exp"]: {
	_and?:ValueTypes["DataDeletionRequest_bool_exp"][],
	_not?:ValueTypes["DataDeletionRequest_bool_exp"] | null,
	_or?:ValueTypes["DataDeletionRequest_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "DataDeletionRequest" */
["DataDeletionRequest_constraint"]:DataDeletionRequest_constraint;
	/** input type for inserting data into table "DataDeletionRequest" */
["DataDeletionRequest_insert_input"]: {
	id?:ValueTypes["uuid"] | null
};
	/** response of any mutation on the table "DataDeletionRequest" */
["DataDeletionRequest_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["DataDeletionRequest"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "DataDeletionRequest" */
["DataDeletionRequest_on_conflict"]: {
	constraint:ValueTypes["DataDeletionRequest_constraint"],
	update_columns:ValueTypes["DataDeletionRequest_update_column"][],
	where?:ValueTypes["DataDeletionRequest_bool_exp"] | null
};
	/** Ordering options when selecting data from "DataDeletionRequest". */
["DataDeletionRequest_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "DataDeletionRequest" */
["DataDeletionRequest_select_column"]:DataDeletionRequest_select_column;
	/** Streaming cursor of the table "DataDeletionRequest" */
["DataDeletionRequest_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["DataDeletionRequest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["DataDeletionRequest_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** placeholder for update columns of table "DataDeletionRequest" (current role has no relevant permissions) */
["DataDeletionRequest_update_column"]:DataDeletionRequest_update_column;
	["DecryptedMessage"]: AliasType<{
	data?:boolean,
		__typename?: boolean
}>;
	/** Tracks devices used by a Person */
["Device"]: AliasType<{
	/** An object relationship */
	Person?:ValueTypes["Person"],
	appBuildNumber?:boolean,
	brand?:boolean,
	createdAt?:boolean,
	id?:boolean,
	model?:boolean,
	osVersion?:boolean,
	personId?:boolean,
	type?:boolean,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["DeviceType_enum"]:DeviceType_enum;
	/** Boolean expression to compare columns of type "DeviceType_enum". All fields are combined with logical 'AND'. */
["DeviceType_enum_comparison_exp"]: {
	_eq?:ValueTypes["DeviceType_enum"] | null,
	_in?:ValueTypes["DeviceType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["DeviceType_enum"] | null,
	_nin?:ValueTypes["DeviceType_enum"][]
};
	/** aggregated selection of "Device" */
["Device_aggregate"]: AliasType<{
	aggregate?:ValueTypes["Device_aggregate_fields"],
	nodes?:ValueTypes["Device"],
		__typename?: boolean
}>;
	["Device_aggregate_bool_exp"]: {
	count?:ValueTypes["Device_aggregate_bool_exp_count"] | null
};
	["Device_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["Device_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["Device_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Device" */
["Device_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["Device_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["Device_max_fields"],
	min?:ValueTypes["Device_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "Device" */
["Device_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Device_max_order_by"] | null,
	min?:ValueTypes["Device_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "Device" */
["Device_arr_rel_insert_input"]: {
	data:ValueTypes["Device_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["Device_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "Device". All fields are combined with a logical 'AND'. */
["Device_bool_exp"]: {
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["Device_bool_exp"][],
	_not?:ValueTypes["Device_bool_exp"] | null,
	_or?:ValueTypes["Device_bool_exp"][],
	appBuildNumber?:ValueTypes["String_comparison_exp"] | null,
	brand?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	model?:ValueTypes["String_comparison_exp"] | null,
	osVersion?:ValueTypes["String_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	type?:ValueTypes["DeviceType_enum_comparison_exp"] | null,
	uniqueIdentifier?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Device" */
["Device_constraint"]:Device_constraint;
	/** input type for inserting data into table "Device" */
["Device_insert_input"]: {
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	appBuildNumber?:string | null,
	brand?:string | null,
	model?:string | null,
	osVersion?:string | null,
	type?:ValueTypes["DeviceType_enum"] | null,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:string | null
};
	/** aggregate max on columns */
["Device_max_fields"]: AliasType<{
	appBuildNumber?:boolean,
	brand?:boolean,
	createdAt?:boolean,
	id?:boolean,
	model?:boolean,
	osVersion?:boolean,
	personId?:boolean,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "Device" */
["Device_max_order_by"]: {
	appBuildNumber?:ValueTypes["order_by"] | null,
	brand?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	model?:ValueTypes["order_by"] | null,
	osVersion?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["Device_min_fields"]: AliasType<{
	appBuildNumber?:boolean,
	brand?:boolean,
	createdAt?:boolean,
	id?:boolean,
	model?:boolean,
	osVersion?:boolean,
	personId?:boolean,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "Device" */
["Device_min_order_by"]: {
	appBuildNumber?:ValueTypes["order_by"] | null,
	brand?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	model?:ValueTypes["order_by"] | null,
	osVersion?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Device" */
["Device_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Device"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "Device" */
["Device_on_conflict"]: {
	constraint:ValueTypes["Device_constraint"],
	update_columns:ValueTypes["Device_update_column"][],
	where?:ValueTypes["Device_bool_exp"] | null
};
	/** Ordering options when selecting data from "Device". */
["Device_order_by"]: {
	Person?:ValueTypes["Person_order_by"] | null,
	appBuildNumber?:ValueTypes["order_by"] | null,
	brand?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	model?:ValueTypes["order_by"] | null,
	osVersion?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	uniqueIdentifier?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Device */
["Device_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "Device" */
["Device_select_column"]:Device_select_column;
	/** input type for updating data in table "Device" */
["Device_set_input"]: {
	appBuildNumber?:string | null,
	brand?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	model?:string | null,
	osVersion?:string | null,
	type?:ValueTypes["DeviceType_enum"] | null,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:string | null
};
	/** Streaming cursor of the table "Device" */
["Device_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Device_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Device_stream_cursor_value_input"]: {
	appBuildNumber?:string | null,
	brand?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	model?:string | null,
	osVersion?:string | null,
	personId?:ValueTypes["uuid"] | null,
	type?:ValueTypes["DeviceType_enum"] | null,
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** update columns of table "Device" */
["Device_update_column"]:Device_update_column;
	["Device_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Device_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Device_bool_exp"]
};
	["EncryptedMessage"]: AliasType<{
	message?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "ExternalIdentifier" */
["ExternalIdentifier"]: AliasType<{
	/** An object relationship */
	Person?:ValueTypes["Person"],
	androidIntercomDigest?:boolean,
	createdAt?:boolean,
	id?:boolean,
	iosIntercomDigest?:boolean,
	personId?:boolean,
	sendbirdToken?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ExternalIdentifier". All fields are combined with a logical 'AND'. */
["ExternalIdentifier_bool_exp"]: {
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["ExternalIdentifier_bool_exp"][],
	_not?:ValueTypes["ExternalIdentifier_bool_exp"] | null,
	_or?:ValueTypes["ExternalIdentifier_bool_exp"][],
	androidIntercomDigest?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	iosIntercomDigest?:ValueTypes["String_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	sendbirdToken?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ExternalIdentifier". */
["ExternalIdentifier_order_by"]: {
	Person?:ValueTypes["Person_order_by"] | null,
	androidIntercomDigest?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	iosIntercomDigest?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	sendbirdToken?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "ExternalIdentifier" */
["ExternalIdentifier_select_column"]:ExternalIdentifier_select_column;
	/** Streaming cursor of the table "ExternalIdentifier" */
["ExternalIdentifier_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ExternalIdentifier_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ExternalIdentifier_stream_cursor_value_input"]: {
	androidIntercomDigest?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	iosIntercomDigest?:string | null,
	personId?:ValueTypes["uuid"] | null,
	sendbirdToken?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** Definition of a service area */
["GeographicRegion"]: AliasType<{
GeographicRegionHandymen?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionHandyman_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionHandyman_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionNeighborhoods?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionNeighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionNeighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null},ValueTypes["GeographicRegionNeighborhood"]],
Homes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Home_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Home_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Home_bool_exp"] | null},ValueTypes["Home"]],
	availableStartingAt?:boolean,
	createdAt?:boolean,
	id?:boolean,
	name?:boolean,
	servicedZipCodes?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Handymen assigned to a geographic region */
["GeographicRegionHandyman"]: AliasType<{
	/** An object relationship */
	GeographicRegion?:ValueTypes["GeographicRegion"],
	/** An object relationship */
	Handyman?:ValueTypes["Person"],
	createdAt?:boolean,
	geographicRegionId?:boolean,
	id?:boolean,
	isPrimary?:boolean,
	personId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["GeographicRegionHandyman_max_order_by"] | null,
	min?:ValueTypes["GeographicRegionHandyman_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
["GeographicRegionHandyman_bool_exp"]: {
	GeographicRegion?:ValueTypes["GeographicRegion_bool_exp"] | null,
	Handyman?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["GeographicRegionHandyman_bool_exp"][],
	_not?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null,
	_or?:ValueTypes["GeographicRegionHandyman_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	geographicRegionId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	isPrimary?:ValueTypes["Boolean_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "GeographicRegionHandyman". */
["GeographicRegionHandyman_order_by"]: {
	GeographicRegion?:ValueTypes["GeographicRegion_order_by"] | null,
	Handyman?:ValueTypes["Person_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	isPrimary?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_select_column"]:GeographicRegionHandyman_select_column;
	/** Streaming cursor of the table "GeographicRegionHandyman" */
["GeographicRegionHandyman_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["GeographicRegionHandyman_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegionHandyman_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	geographicRegionId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	isPrimary?:boolean | null,
	personId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** Separates large population zip codes into regions. Used for first time onboarding. */
["GeographicRegionNeighborhood"]: AliasType<{
	/** An object relationship */
	GeographicRegion?:ValueTypes["GeographicRegion"],
	createdAt?:boolean,
	geographicRegionId?:boolean,
	id?:boolean,
	name?:boolean,
	updatedAt?:boolean,
	zipCode?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["GeographicRegionNeighborhood_max_order_by"] | null,
	min?:ValueTypes["GeographicRegionNeighborhood_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
["GeographicRegionNeighborhood_bool_exp"]: {
	GeographicRegion?:ValueTypes["GeographicRegion_bool_exp"] | null,
	_and?:ValueTypes["GeographicRegionNeighborhood_bool_exp"][],
	_not?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null,
	_or?:ValueTypes["GeographicRegionNeighborhood_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	geographicRegionId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	zipCode?:ValueTypes["String_comparison_exp"] | null
};
	/** order by max() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "GeographicRegionNeighborhood". */
["GeographicRegionNeighborhood_order_by"]: {
	GeographicRegion?:ValueTypes["GeographicRegion_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** select columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_select_column"]:GeographicRegionNeighborhood_select_column;
	/** Streaming cursor of the table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["GeographicRegionNeighborhood_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegionNeighborhood_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	geographicRegionId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	zipCode?:string | null
};
	/** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
["GeographicRegion_bool_exp"]: {
	GeographicRegionHandymen?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null,
	GeographicRegionNeighborhoods?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null,
	Homes?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["GeographicRegion_bool_exp"][],
	_not?:ValueTypes["GeographicRegion_bool_exp"] | null,
	_or?:ValueTypes["GeographicRegion_bool_exp"][],
	availableStartingAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	servicedZipCodes?:ValueTypes["String_array_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	["GeographicRegion_by_zipCode_args"]: {
	zipCode?:string | null
};
	/** Ordering options when selecting data from "GeographicRegion". */
["GeographicRegion_order_by"]: {
	GeographicRegionHandymen_aggregate?:ValueTypes["GeographicRegionHandyman_aggregate_order_by"] | null,
	GeographicRegionNeighborhoods_aggregate?:ValueTypes["GeographicRegionNeighborhood_aggregate_order_by"] | null,
	Homes_aggregate?:ValueTypes["Home_aggregate_order_by"] | null,
	availableStartingAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	servicedZipCodes?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "GeographicRegion" */
["GeographicRegion_select_column"]:GeographicRegion_select_column;
	/** Streaming cursor of the table "GeographicRegion" */
["GeographicRegion_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["GeographicRegion_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegion_stream_cursor_value_input"]: {
	availableStartingAt?:ValueTypes["timestamptz"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	servicedZipCodes?:string[],
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** columns and relationships of "Home" */
["Home"]: AliasType<{
AcceptedReferrals?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Referral_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Referral_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Referral_bool_exp"] | null},ValueTypes["Referral"]],
AppointmentGrades?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGrade_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGrade_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
Appointments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment"]],
Appointments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment_aggregate"]],
	/** An object relationship */
	GeographicRegion?:ValueTypes["GeographicRegion"],
HomeInventoryItems?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null},ValueTypes["HomeInventoryItem"]],
InvitedReferrals?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Referral_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Referral_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Referral_bool_exp"] | null},ValueTypes["Referral"]],
PersonHomes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonHome_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonHome_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonHome_bool_exp"] | null},ValueTypes["PersonHome"]],
	/** An object relationship */
	SchedulingRegion?:ValueTypes["Region"],
ServiceOfferResponses?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponse_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponse_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
StripePaymentMethods?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripePaymentMethod_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripePaymentMethod_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripePaymentMethod_bool_exp"] | null},ValueTypes["StripePaymentMethod"]],
StripeSubscriptions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripeSubscription_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripeSubscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripeSubscription_bool_exp"] | null},ValueTypes["StripeSubscription"]],
Tasks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
Tasks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task_aggregate"]],
	/** If the home is currently an active member of the service */
	activeMember?:boolean,
	city?:boolean,
	createdAt?:boolean,
	geographicRegionId?:boolean,
	id?:boolean,
	location?:boolean,
	referralCode?:boolean,
	schedulingRegionId?:boolean,
	sendbirdChannelId?:boolean,
	state?:boolean,
	status?:boolean,
	streetAddress?:boolean,
	stripeDefaultSourcePresent?:boolean,
	stripeId?:boolean,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:boolean,
	stripePromoCode?:boolean,
	/** IANA time zone */
	timeZone?:boolean,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:boolean,
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:boolean,
	updatedAt?:boolean,
	wifiCredentialsE?:boolean,
	zipCode?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "HomeInventoryItem" */
["HomeInventoryItem"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
HomeInventoryItemAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
	/** An object relationship */
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory"],
	createdAt?:boolean,
	homeId?:boolean,
	homeInventoryItemCategoryId?:boolean,
	id?:boolean,
	isArchived?:boolean,
	lastServicedAt?:boolean,
	name?:boolean,
	notes?:boolean,
	serviceFrequency?:boolean,
	serviceProvider?:boolean,
	serviceType?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment"]: AliasType<{
	/** An object relationship */
	Attachment?:ValueTypes["Attachment"],
	/** An object relationship */
	HomeInventoryItem?:ValueTypes["HomeInventoryItem"],
	/** An object relationship */
	HomeInventoryItemCategoryPrompt?:ValueTypes["HomeInventoryItemCategoryPrompt"],
	attachmentId?:boolean,
	homeInventoryCategoryPromptId?:boolean,
	homeInventoryItemId?:boolean,
	id?:boolean,
	rank?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_aggregate_order_by"]: {
	avg?:ValueTypes["HomeInventoryItemAttachment_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["HomeInventoryItemAttachment_max_order_by"] | null,
	min?:ValueTypes["HomeInventoryItemAttachment_min_order_by"] | null,
	stddev?:ValueTypes["HomeInventoryItemAttachment_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["HomeInventoryItemAttachment_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["HomeInventoryItemAttachment_stddev_samp_order_by"] | null,
	sum?:ValueTypes["HomeInventoryItemAttachment_sum_order_by"] | null,
	var_pop?:ValueTypes["HomeInventoryItemAttachment_var_pop_order_by"] | null,
	var_samp?:ValueTypes["HomeInventoryItemAttachment_var_samp_order_by"] | null,
	variance?:ValueTypes["HomeInventoryItemAttachment_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_arr_rel_insert_input"]: {
	data:ValueTypes["HomeInventoryItemAttachment_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItemAttachment_on_conflict"] | null
};
	/** order by avg() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_avg_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemAttachment". All fields are combined with a logical 'AND'. */
["HomeInventoryItemAttachment_bool_exp"]: {
	Attachment?:ValueTypes["Attachment_bool_exp"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	HomeInventoryItemCategoryPrompt?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryItemAttachment_bool_exp"][],
	_not?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryItemAttachment_bool_exp"][],
	attachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["uuid_comparison_exp"] | null,
	homeInventoryItemId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	rank?:ValueTypes["Int_comparison_exp"] | null
};
	/** unique or primary key constraints on table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_constraint"]:HomeInventoryItemAttachment_constraint;
	/** input type for incrementing numeric columns in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_inc_input"]: {
	rank?:number | null
};
	/** input type for inserting data into table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_insert_input"]: {
	Attachment?:ValueTypes["Attachment_obj_rel_insert_input"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_obj_rel_insert_input"] | null,
	attachmentId?:ValueTypes["uuid"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	rank?:number | null
};
	/** order by max() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_max_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_min_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["HomeInventoryItemAttachment"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_on_conflict"]: {
	constraint:ValueTypes["HomeInventoryItemAttachment_constraint"],
	update_columns:ValueTypes["HomeInventoryItemAttachment_update_column"][],
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null
};
	/** Ordering options when selecting data from "HomeInventoryItemAttachment". */
["HomeInventoryItemAttachment_order_by"]: {
	Attachment?:ValueTypes["Attachment_order_by"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_order_by"] | null,
	HomeInventoryItemCategoryPrompt?:ValueTypes["HomeInventoryItemCategoryPrompt_order_by"] | null,
	attachmentId?:ValueTypes["order_by"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: HomeInventoryItemAttachment */
["HomeInventoryItemAttachment_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_select_column"]:HomeInventoryItemAttachment_select_column;
	/** input type for updating data in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_set_input"]: {
	attachmentId?:ValueTypes["uuid"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	rank?:number | null
};
	/** order by stddev() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryItemAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemAttachment_stream_cursor_value_input"]: {
	attachmentId?:ValueTypes["uuid"] | null,
	homeInventoryCategoryPromptId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	rank?:number | null
};
	/** order by sum() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_sum_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** update columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_update_column"]:HomeInventoryItemAttachment_update_column;
	["HomeInventoryItemAttachment_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItemAttachment_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItemAttachment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["HomeInventoryItemAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_variance_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "HomeInventoryItemCategory" */
["HomeInventoryItemCategory"]: AliasType<{
HomeInventoryItemCategoryIcons?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryIcon_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryIcon_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryPrompts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryPrompt_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryPrompt_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItems?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null},ValueTypes["HomeInventoryItem"]],
	description?:boolean,
	id?:boolean,
	serviceDescription?:boolean,
	serviceFrequency?:boolean,
	servicePartName?:boolean,
	servicePartPartyResponsible?:boolean,
	servicePartsRequired?:boolean,
	serviceProvider?:boolean,
	serviceType?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon"]: AliasType<{
	/** An object relationship */
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory"],
	/** An object relationship */
	ImageAssetAttachment?:ValueTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment?:ValueTypes["Attachment"],
	createdAt?:boolean,
	friendlyAssetId?:boolean,
	homeInventoryItemCategoryId?:boolean,
	id?:boolean,
	imageAssetAttachmentId?:boolean,
	updatedAt?:boolean,
	vectorAssetAttachmentId?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["HomeInventoryItemCategoryIcon_max_order_by"] | null,
	min?:ValueTypes["HomeInventoryItemCategoryIcon_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryIcon". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryIcon_bool_exp"]: {
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null,
	ImageAssetAttachment?:ValueTypes["Attachment_bool_exp"] | null,
	VectorAssetAttachment?:ValueTypes["Attachment_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"][],
	_not?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	friendlyAssetId?:ValueTypes["String_comparison_exp"] | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	imageAssetAttachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	vectorAssetAttachmentId?:ValueTypes["uuid_comparison_exp"] | null
};
	/** order by max() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	imageAssetAttachmentId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	vectorAssetAttachmentId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	imageAssetAttachmentId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	vectorAssetAttachmentId?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "HomeInventoryItemCategoryIcon". */
["HomeInventoryItemCategoryIcon_order_by"]: {
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_order_by"] | null,
	ImageAssetAttachment?:ValueTypes["Attachment_order_by"] | null,
	VectorAssetAttachment?:ValueTypes["Attachment_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	imageAssetAttachmentId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	vectorAssetAttachmentId?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_select_column"]:HomeInventoryItemCategoryIcon_select_column;
	/** Streaming cursor of the table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryItemCategoryIcon_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryIcon_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	friendlyAssetId?:string | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	imageAssetAttachmentId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	vectorAssetAttachmentId?:ValueTypes["uuid"] | null
};
	/** columns and relationships of "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt"]: AliasType<{
	/** An object relationship */
	ExampleAttachment?:ValueTypes["Attachment"],
HomeInventoryItemAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
	/** An object relationship */
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory"],
	body?:boolean,
	createdAt?:boolean,
	defaultCaption?:boolean,
	exampleAttachmentId?:boolean,
	friendlyAssetId?:boolean,
	homeInventoryItemCategoryId?:boolean,
	id?:boolean,
	inputHint?:boolean,
	rank?:boolean,
	title?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_aggregate_order_by"]: {
	avg?:ValueTypes["HomeInventoryItemCategoryPrompt_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["HomeInventoryItemCategoryPrompt_max_order_by"] | null,
	min?:ValueTypes["HomeInventoryItemCategoryPrompt_min_order_by"] | null,
	stddev?:ValueTypes["HomeInventoryItemCategoryPrompt_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"] | null,
	sum?:ValueTypes["HomeInventoryItemCategoryPrompt_sum_order_by"] | null,
	var_pop?:ValueTypes["HomeInventoryItemCategoryPrompt_var_pop_order_by"] | null,
	var_samp?:ValueTypes["HomeInventoryItemCategoryPrompt_var_samp_order_by"] | null,
	variance?:ValueTypes["HomeInventoryItemCategoryPrompt_variance_order_by"] | null
};
	/** order by avg() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_avg_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryPrompt". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryPrompt_bool_exp"]: {
	ExampleAttachment?:ValueTypes["Attachment_bool_exp"] | null,
	HomeInventoryItemAttachments?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null,
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"][],
	_not?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"][],
	body?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	defaultCaption?:ValueTypes["String_comparison_exp"] | null,
	exampleAttachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	friendlyAssetId?:ValueTypes["String_comparison_exp"] | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	inputHint?:ValueTypes["String_comparison_exp"] | null,
	rank?:ValueTypes["Int_comparison_exp"] | null,
	title?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_max_order_by"]: {
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	defaultCaption?:ValueTypes["order_by"] | null,
	exampleAttachmentId?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	inputHint?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_min_order_by"]: {
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	defaultCaption?:ValueTypes["order_by"] | null,
	exampleAttachmentId?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	inputHint?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "HomeInventoryItemCategoryPrompt". */
["HomeInventoryItemCategoryPrompt_order_by"]: {
	ExampleAttachment?:ValueTypes["Attachment_order_by"] | null,
	HomeInventoryItemAttachments_aggregate?:ValueTypes["HomeInventoryItemAttachment_aggregate_order_by"] | null,
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_order_by"] | null,
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	defaultCaption?:ValueTypes["order_by"] | null,
	exampleAttachmentId?:ValueTypes["order_by"] | null,
	friendlyAssetId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	inputHint?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_select_column"]:HomeInventoryItemCategoryPrompt_select_column;
	/** order by stddev() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"]: {
	body?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	defaultCaption?:string | null,
	exampleAttachmentId?:ValueTypes["uuid"] | null,
	friendlyAssetId?:string | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	inputHint?:string | null,
	rank?:number | null,
	title?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_sum_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_variance_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategory". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategory_bool_exp"]: {
	HomeInventoryItemCategoryIcons?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null,
	HomeInventoryItemCategoryPrompts?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null,
	HomeInventoryItems?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryItemCategory_bool_exp"][],
	_not?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryItemCategory_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	serviceDescription?:ValueTypes["String_comparison_exp"] | null,
	serviceFrequency?:ValueTypes["Int_comparison_exp"] | null,
	servicePartName?:ValueTypes["String_comparison_exp"] | null,
	servicePartPartyResponsible?:ValueTypes["TaskPartPartyResponsible_enum_comparison_exp"] | null,
	servicePartsRequired?:ValueTypes["Boolean_comparison_exp"] | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum_comparison_exp"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum_comparison_exp"] | null
};
	/** Ordering options when selecting data from "HomeInventoryItemCategory". */
["HomeInventoryItemCategory_order_by"]: {
	HomeInventoryItemCategoryIcons_aggregate?:ValueTypes["HomeInventoryItemCategoryIcon_aggregate_order_by"] | null,
	HomeInventoryItemCategoryPrompts_aggregate?:ValueTypes["HomeInventoryItemCategoryPrompt_aggregate_order_by"] | null,
	HomeInventoryItems_aggregate?:ValueTypes["HomeInventoryItem_aggregate_order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	serviceDescription?:ValueTypes["order_by"] | null,
	serviceFrequency?:ValueTypes["order_by"] | null,
	servicePartName?:ValueTypes["order_by"] | null,
	servicePartPartyResponsible?:ValueTypes["order_by"] | null,
	servicePartsRequired?:ValueTypes["order_by"] | null,
	serviceProvider?:ValueTypes["order_by"] | null,
	serviceType?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_select_column"]:HomeInventoryItemCategory_select_column;
	/** Streaming cursor of the table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryItemCategory_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategory_stream_cursor_value_input"]: {
	description?:string | null,
	id?:ValueTypes["uuid"] | null,
	serviceDescription?:string | null,
	serviceFrequency?:number | null,
	servicePartName?:string | null,
	servicePartPartyResponsible?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	servicePartsRequired?:boolean | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum"] | null
};
	/** order by aggregate values of table "HomeInventoryItem" */
["HomeInventoryItem_aggregate_order_by"]: {
	avg?:ValueTypes["HomeInventoryItem_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["HomeInventoryItem_max_order_by"] | null,
	min?:ValueTypes["HomeInventoryItem_min_order_by"] | null,
	stddev?:ValueTypes["HomeInventoryItem_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["HomeInventoryItem_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["HomeInventoryItem_stddev_samp_order_by"] | null,
	sum?:ValueTypes["HomeInventoryItem_sum_order_by"] | null,
	var_pop?:ValueTypes["HomeInventoryItem_var_pop_order_by"] | null,
	var_samp?:ValueTypes["HomeInventoryItem_var_samp_order_by"] | null,
	variance?:ValueTypes["HomeInventoryItem_variance_order_by"] | null
};
	/** order by avg() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_avg_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "HomeInventoryItem". All fields are combined with a logical 'AND'. */
["HomeInventoryItem_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	HomeInventoryItemAttachments?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null,
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryItem_bool_exp"][],
	_not?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryItem_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	isArchived?:ValueTypes["Boolean_comparison_exp"] | null,
	lastServicedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	notes?:ValueTypes["String_comparison_exp"] | null,
	serviceFrequency?:ValueTypes["Int_comparison_exp"] | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum_comparison_exp"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "HomeInventoryItem" */
["HomeInventoryItem_constraint"]:HomeInventoryItem_constraint;
	/** input type for incrementing numeric columns in table "HomeInventoryItem" */
["HomeInventoryItem_inc_input"]: {
	serviceFrequency?:number | null
};
	/** input type for inserting data into table "HomeInventoryItem" */
["HomeInventoryItem_insert_input"]: {
	HomeInventoryItemAttachments?:ValueTypes["HomeInventoryItemAttachment_arr_rel_insert_input"] | null,
	homeId?:ValueTypes["uuid"] | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid"] | null,
	isArchived?:boolean | null,
	lastServicedAt?:ValueTypes["timestamptz"] | null,
	name?:string | null,
	notes?:string | null,
	serviceFrequency?:number | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum"] | null
};
	/** order by max() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastServicedAt?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	serviceFrequency?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastServicedAt?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	serviceFrequency?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "HomeInventoryItem" */
["HomeInventoryItem_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["HomeInventoryItem"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "HomeInventoryItem" */
["HomeInventoryItem_obj_rel_insert_input"]: {
	data:ValueTypes["HomeInventoryItem_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItem_on_conflict"] | null
};
	/** on_conflict condition type for table "HomeInventoryItem" */
["HomeInventoryItem_on_conflict"]: {
	constraint:ValueTypes["HomeInventoryItem_constraint"],
	update_columns:ValueTypes["HomeInventoryItem_update_column"][],
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null
};
	/** Ordering options when selecting data from "HomeInventoryItem". */
["HomeInventoryItem_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	HomeInventoryItemAttachments_aggregate?:ValueTypes["HomeInventoryItemAttachment_aggregate_order_by"] | null,
	HomeInventoryItemCategory?:ValueTypes["HomeInventoryItemCategory_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemCategoryId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	isArchived?:ValueTypes["order_by"] | null,
	lastServicedAt?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	serviceFrequency?:ValueTypes["order_by"] | null,
	serviceProvider?:ValueTypes["order_by"] | null,
	serviceType?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: HomeInventoryItem */
["HomeInventoryItem_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "HomeInventoryItem" */
["HomeInventoryItem_select_column"]:HomeInventoryItem_select_column;
	/** input type for updating data in table "HomeInventoryItem" */
["HomeInventoryItem_set_input"]: {
	homeInventoryItemCategoryId?:ValueTypes["uuid"] | null,
	isArchived?:boolean | null,
	lastServicedAt?:ValueTypes["timestamptz"] | null,
	name?:string | null,
	notes?:string | null,
	serviceFrequency?:number | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum"] | null
};
	/** order by stddev() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_pop_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_samp_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "HomeInventoryItem" */
["HomeInventoryItem_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryItem_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItem_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	homeInventoryItemCategoryId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	isArchived?:boolean | null,
	lastServicedAt?:ValueTypes["timestamptz"] | null,
	name?:string | null,
	notes?:string | null,
	serviceFrequency?:number | null,
	serviceProvider?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	serviceType?:ValueTypes["HomeInventoryServiceType_enum"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_sum_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** update columns of table "HomeInventoryItem" */
["HomeInventoryItem_update_column"]:HomeInventoryItem_update_column;
	["HomeInventoryItem_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItem_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItem_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["HomeInventoryItem_bool_exp"]
};
	/** order by var_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_pop_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_samp_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_variance_order_by"]: {
	serviceFrequency?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?:ValueTypes["HomeInventoryItem"],
	dueBy?:boolean,
	homeId?:boolean,
	homeInventoryItemId?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "HomeInventoryMaintenanceSchedule". All fields are combined with a logical 'AND'. */
["HomeInventoryMaintenanceSchedule_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	_and?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"][],
	_not?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"][],
	dueBy?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	homeInventoryItemId?:ValueTypes["uuid_comparison_exp"] | null
};
	/** Ordering options when selecting data from "HomeInventoryMaintenanceSchedule". */
["HomeInventoryMaintenanceSchedule_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_order_by"] | null,
	dueBy?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_select_column"]:HomeInventoryMaintenanceSchedule_select_column;
	/** Streaming cursor of the table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"]: {
	dueBy?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null
};
	/** Enum of the provider for a HomeInventoryItemCategory or HomeInventoryItem */
["HomeInventoryServiceProvider"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "HomeInventoryServiceProvider". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceProvider_bool_exp"]: {
	_and?:ValueTypes["HomeInventoryServiceProvider_bool_exp"][],
	_not?:ValueTypes["HomeInventoryServiceProvider_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryServiceProvider_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["HomeInventoryServiceProvider_enum"]:HomeInventoryServiceProvider_enum;
	/** Boolean expression to compare columns of type "HomeInventoryServiceProvider_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceProvider_enum_comparison_exp"]: {
	_eq?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	_in?:ValueTypes["HomeInventoryServiceProvider_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["HomeInventoryServiceProvider_enum"] | null,
	_nin?:ValueTypes["HomeInventoryServiceProvider_enum"][]
};
	/** Ordering options when selecting data from "HomeInventoryServiceProvider". */
["HomeInventoryServiceProvider_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_select_column"]:HomeInventoryServiceProvider_select_column;
	/** Streaming cursor of the table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryServiceProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceProvider_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** Enum of the type of service which a HomeInventoryItemCategory or HomeInventoryItem need during maintenance */
["HomeInventoryServiceType"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "HomeInventoryServiceType". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceType_bool_exp"]: {
	_and?:ValueTypes["HomeInventoryServiceType_bool_exp"][],
	_not?:ValueTypes["HomeInventoryServiceType_bool_exp"] | null,
	_or?:ValueTypes["HomeInventoryServiceType_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["HomeInventoryServiceType_enum"]:HomeInventoryServiceType_enum;
	/** Boolean expression to compare columns of type "HomeInventoryServiceType_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceType_enum_comparison_exp"]: {
	_eq?:ValueTypes["HomeInventoryServiceType_enum"] | null,
	_in?:ValueTypes["HomeInventoryServiceType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["HomeInventoryServiceType_enum"] | null,
	_nin?:ValueTypes["HomeInventoryServiceType_enum"][]
};
	/** Ordering options when selecting data from "HomeInventoryServiceType". */
["HomeInventoryServiceType_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeInventoryServiceType" */
["HomeInventoryServiceType_select_column"]:HomeInventoryServiceType_select_column;
	/** Streaming cursor of the table "HomeInventoryServiceType" */
["HomeInventoryServiceType_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeInventoryServiceType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceType_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	["HomeStatus_enum"]:HomeStatus_enum;
	/** Boolean expression to compare columns of type "HomeStatus_enum". All fields are combined with logical 'AND'. */
["HomeStatus_enum_comparison_exp"]: {
	_eq?:ValueTypes["HomeStatus_enum"] | null,
	_in?:ValueTypes["HomeStatus_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["HomeStatus_enum"] | null,
	_nin?:ValueTypes["HomeStatus_enum"][]
};
	/** columns and relationships of "HomeWaitlist" */
["HomeWaitlist"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	createdAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["HomeWaitlistReason_enum"]:HomeWaitlistReason_enum;
	/** Boolean expression to filter rows from the table "HomeWaitlist". All fields are combined with a logical 'AND'. */
["HomeWaitlist_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["HomeWaitlist_bool_exp"][],
	_not?:ValueTypes["HomeWaitlist_bool_exp"] | null,
	_or?:ValueTypes["HomeWaitlist_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "HomeWaitlist" */
["HomeWaitlist_constraint"]:HomeWaitlist_constraint;
	/** input type for inserting data into table "HomeWaitlist" */
["HomeWaitlist_insert_input"]: {
	id?:ValueTypes["uuid"] | null,
	reason?:ValueTypes["HomeWaitlistReason_enum"] | null
};
	/** response of any mutation on the table "HomeWaitlist" */
["HomeWaitlist_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["HomeWaitlist"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "HomeWaitlist" */
["HomeWaitlist_on_conflict"]: {
	constraint:ValueTypes["HomeWaitlist_constraint"],
	update_columns:ValueTypes["HomeWaitlist_update_column"][],
	where?:ValueTypes["HomeWaitlist_bool_exp"] | null
};
	/** Ordering options when selecting data from "HomeWaitlist". */
["HomeWaitlist_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "HomeWaitlist" */
["HomeWaitlist_select_column"]:HomeWaitlist_select_column;
	/** Streaming cursor of the table "HomeWaitlist" */
["HomeWaitlist_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["HomeWaitlist_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["HomeWaitlist_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** placeholder for update columns of table "HomeWaitlist" (current role has no relevant permissions) */
["HomeWaitlist_update_column"]:HomeWaitlist_update_column;
	/** order by aggregate values of table "Home" */
["Home_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Home_max_order_by"] | null,
	min?:ValueTypes["Home_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "Home". All fields are combined with a logical 'AND'. */
["Home_bool_exp"]: {
	AcceptedReferrals?:ValueTypes["Referral_bool_exp"] | null,
	AppointmentGrades?:ValueTypes["AppointmentGrade_bool_exp"] | null,
	Appointments?:ValueTypes["Appointment_bool_exp"] | null,
	Appointments_aggregate?:ValueTypes["Appointment_aggregate_bool_exp"] | null,
	GeographicRegion?:ValueTypes["GeographicRegion_bool_exp"] | null,
	HomeInventoryItems?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	InvitedReferrals?:ValueTypes["Referral_bool_exp"] | null,
	PersonHomes?:ValueTypes["PersonHome_bool_exp"] | null,
	SchedulingRegion?:ValueTypes["Region_bool_exp"] | null,
	ServiceOfferResponses?:ValueTypes["ServiceOfferResponse_bool_exp"] | null,
	StripePaymentMethods?:ValueTypes["StripePaymentMethod_bool_exp"] | null,
	StripeSubscriptions?:ValueTypes["StripeSubscription_bool_exp"] | null,
	Tasks?:ValueTypes["Task_bool_exp"] | null,
	Tasks_aggregate?:ValueTypes["Task_aggregate_bool_exp"] | null,
	_and?:ValueTypes["Home_bool_exp"][],
	_not?:ValueTypes["Home_bool_exp"] | null,
	_or?:ValueTypes["Home_bool_exp"][],
	activeMember?:ValueTypes["Boolean_comparison_exp"] | null,
	city?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	geographicRegionId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	location?:ValueTypes["geography_comparison_exp"] | null,
	referralCode?:ValueTypes["String_comparison_exp"] | null,
	schedulingRegionId?:ValueTypes["uuid_comparison_exp"] | null,
	sendbirdChannelId?:ValueTypes["String_comparison_exp"] | null,
	state?:ValueTypes["String_comparison_exp"] | null,
	status?:ValueTypes["HomeStatus_enum_comparison_exp"] | null,
	streetAddress?:ValueTypes["String_comparison_exp"] | null,
	stripeDefaultSourcePresent?:ValueTypes["Boolean_comparison_exp"] | null,
	stripeId?:ValueTypes["String_comparison_exp"] | null,
	stripePriceId?:ValueTypes["String_comparison_exp"] | null,
	stripePromoCode?:ValueTypes["String_comparison_exp"] | null,
	timeZone?:ValueTypes["String_comparison_exp"] | null,
	unavailableEndingAt?:ValueTypes["date_comparison_exp"] | null,
	unavailableStartingAt?:ValueTypes["date_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	wifiCredentialsE?:ValueTypes["String_comparison_exp"] | null,
	zipCode?:ValueTypes["String_comparison_exp"] | null
};
	/** order by max() on columns of table "Home" */
["Home_max_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	referralCode?:ValueTypes["order_by"] | null,
	schedulingRegionId?:ValueTypes["order_by"] | null,
	sendbirdChannelId?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	streetAddress?:ValueTypes["order_by"] | null,
	stripeId?:ValueTypes["order_by"] | null,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:ValueTypes["order_by"] | null,
	stripePromoCode?:ValueTypes["order_by"] | null,
	/** IANA time zone */
	timeZone?:ValueTypes["order_by"] | null,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:ValueTypes["order_by"] | null,
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	wifiCredentialsE?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "Home" */
["Home_min_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	referralCode?:ValueTypes["order_by"] | null,
	schedulingRegionId?:ValueTypes["order_by"] | null,
	sendbirdChannelId?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	streetAddress?:ValueTypes["order_by"] | null,
	stripeId?:ValueTypes["order_by"] | null,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:ValueTypes["order_by"] | null,
	stripePromoCode?:ValueTypes["order_by"] | null,
	/** IANA time zone */
	timeZone?:ValueTypes["order_by"] | null,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:ValueTypes["order_by"] | null,
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	wifiCredentialsE?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Home" */
["Home_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Home"],
		__typename?: boolean
}>;
	/** Ordering options when selecting data from "Home". */
["Home_order_by"]: {
	AcceptedReferrals_aggregate?:ValueTypes["Referral_aggregate_order_by"] | null,
	AppointmentGrades_aggregate?:ValueTypes["AppointmentGrade_aggregate_order_by"] | null,
	Appointments_aggregate?:ValueTypes["Appointment_aggregate_order_by"] | null,
	GeographicRegion?:ValueTypes["GeographicRegion_order_by"] | null,
	HomeInventoryItems_aggregate?:ValueTypes["HomeInventoryItem_aggregate_order_by"] | null,
	InvitedReferrals_aggregate?:ValueTypes["Referral_aggregate_order_by"] | null,
	PersonHomes_aggregate?:ValueTypes["PersonHome_aggregate_order_by"] | null,
	SchedulingRegion?:ValueTypes["Region_order_by"] | null,
	ServiceOfferResponses_aggregate?:ValueTypes["ServiceOfferResponse_aggregate_order_by"] | null,
	StripePaymentMethods_aggregate?:ValueTypes["StripePaymentMethod_aggregate_order_by"] | null,
	StripeSubscriptions_aggregate?:ValueTypes["StripeSubscription_aggregate_order_by"] | null,
	Tasks_aggregate?:ValueTypes["Task_aggregate_order_by"] | null,
	activeMember?:ValueTypes["order_by"] | null,
	city?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	geographicRegionId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	location?:ValueTypes["order_by"] | null,
	referralCode?:ValueTypes["order_by"] | null,
	schedulingRegionId?:ValueTypes["order_by"] | null,
	sendbirdChannelId?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null,
	streetAddress?:ValueTypes["order_by"] | null,
	stripeDefaultSourcePresent?:ValueTypes["order_by"] | null,
	stripeId?:ValueTypes["order_by"] | null,
	stripePriceId?:ValueTypes["order_by"] | null,
	stripePromoCode?:ValueTypes["order_by"] | null,
	timeZone?:ValueTypes["order_by"] | null,
	unavailableEndingAt?:ValueTypes["order_by"] | null,
	unavailableStartingAt?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	wifiCredentialsE?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Home */
["Home_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "Home" */
["Home_select_column"]:Home_select_column;
	/** input type for updating data in table "Home" */
["Home_set_input"]: {
	city?:string | null,
	/** Where the homeowners reported they learned of the service. */
	organicSource?:string | null,
	state?:string | null,
	streetAddress?:string | null,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:string | null,
	stripePromoCode?:string | null,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:ValueTypes["date"] | null,
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:ValueTypes["date"] | null,
	wifiCredentialsE?:string | null,
	zipCode?:string | null
};
	/** Streaming cursor of the table "Home" */
["Home_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Home_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Home_stream_cursor_value_input"]: {
	/** If the home is currently an active member of the service */
	activeMember?:boolean | null,
	city?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	geographicRegionId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	location?:ValueTypes["geography"] | null,
	referralCode?:string | null,
	schedulingRegionId?:ValueTypes["uuid"] | null,
	sendbirdChannelId?:string | null,
	state?:string | null,
	status?:ValueTypes["HomeStatus_enum"] | null,
	streetAddress?:string | null,
	stripeDefaultSourcePresent?:boolean | null,
	stripeId?:string | null,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:string | null,
	stripePromoCode?:string | null,
	/** IANA time zone */
	timeZone?:string | null,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:ValueTypes["date"] | null,
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:ValueTypes["date"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	wifiCredentialsE?:string | null,
	zipCode?:string | null
};
	["Home_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Home_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Home_bool_exp"]
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
	_eq?:number | null,
	_gt?:number | null,
	_gte?:number | null,
	_in?:number[],
	_is_null?:boolean | null,
	_lt?:number | null,
	_lte?:number | null,
	_neq?:number | null,
	_nin?:number[]
};
	/** columns and relationships of "MarketingLead" */
["MarketingLead"]: AliasType<{
	id?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
["MarketingLead_bool_exp"]: {
	_and?:ValueTypes["MarketingLead_bool_exp"][],
	_not?:ValueTypes["MarketingLead_bool_exp"] | null,
	_or?:ValueTypes["MarketingLead_bool_exp"][],
	id?:ValueTypes["uuid_comparison_exp"] | null
};
	/** response of any mutation on the table "MarketingLead" */
["MarketingLead_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["MarketingLead"],
		__typename?: boolean
}>;
	/** Ordering options when selecting data from "MarketingLead". */
["MarketingLead_order_by"]: {
	id?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: MarketingLead */
["MarketingLead_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "MarketingLead" */
["MarketingLead_select_column"]:MarketingLead_select_column;
	/** input type for updating data in table "MarketingLead" */
["MarketingLead_set_input"]: {
	city?:string | null,
	email?:string | null,
	firstAppointmentBeginsAt?:ValueTypes["timestamptz"] | null,
	firstAppointmentEndsAt?:ValueTypes["timestamptz"] | null,
	firstName?:string | null,
	homeAccountCreated?:boolean | null,
	lastName?:string | null,
	phoneNumber?:string | null,
	phoneNumberVerified?:boolean | null,
	state?:string | null,
	streetAddress?:string | null,
	zipCode?:string | null
};
	/** Streaming cursor of the table "MarketingLead" */
["MarketingLead_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["MarketingLead_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["MarketingLead_stream_cursor_value_input"]: {
	id?:ValueTypes["uuid"] | null
};
	["MarketingLead_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["MarketingLead_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["MarketingLead_bool_exp"]
};
	/** Service area for one handyman */
["Neighborhood"]: AliasType<{
NeighborhoodRegions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["NeighborhoodRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["NeighborhoodRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["NeighborhoodRegion_bool_exp"] | null},ValueTypes["NeighborhoodRegion"]],
	/** An object relationship */
	Person?:ValueTypes["Person"],
	availableStartingAt?:boolean,
	createdAt?:boolean,
	displayName?:boolean,
	handymanPersonId?:boolean,
	id?:boolean,
	updatedAt?:boolean,
	zipCode?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "NeighborhoodRegion" */
["NeighborhoodRegion"]: AliasType<{
	/** An object relationship */
	Neighborhood?:ValueTypes["Neighborhood"],
	/** An object relationship */
	Region?:ValueTypes["Region"],
	createdAt?:boolean,
	id?:boolean,
	neighborhoodId?:boolean,
	regionId?:boolean,
	type?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["NeighborhoodRegionType_enum"]:NeighborhoodRegionType_enum;
	/** Boolean expression to compare columns of type "NeighborhoodRegionType_enum". All fields are combined with logical 'AND'. */
["NeighborhoodRegionType_enum_comparison_exp"]: {
	_eq?:ValueTypes["NeighborhoodRegionType_enum"] | null,
	_in?:ValueTypes["NeighborhoodRegionType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["NeighborhoodRegionType_enum"] | null,
	_nin?:ValueTypes["NeighborhoodRegionType_enum"][]
};
	/** order by aggregate values of table "NeighborhoodRegion" */
["NeighborhoodRegion_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["NeighborhoodRegion_max_order_by"] | null,
	min?:ValueTypes["NeighborhoodRegion_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "NeighborhoodRegion". All fields are combined with a logical 'AND'. */
["NeighborhoodRegion_bool_exp"]: {
	Neighborhood?:ValueTypes["Neighborhood_bool_exp"] | null,
	Region?:ValueTypes["Region_bool_exp"] | null,
	_and?:ValueTypes["NeighborhoodRegion_bool_exp"][],
	_not?:ValueTypes["NeighborhoodRegion_bool_exp"] | null,
	_or?:ValueTypes["NeighborhoodRegion_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	neighborhoodId?:ValueTypes["uuid_comparison_exp"] | null,
	regionId?:ValueTypes["uuid_comparison_exp"] | null,
	type?:ValueTypes["NeighborhoodRegionType_enum_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	neighborhoodId?:ValueTypes["order_by"] | null,
	regionId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	neighborhoodId?:ValueTypes["order_by"] | null,
	regionId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "NeighborhoodRegion". */
["NeighborhoodRegion_order_by"]: {
	Neighborhood?:ValueTypes["Neighborhood_order_by"] | null,
	Region?:ValueTypes["Region_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	neighborhoodId?:ValueTypes["order_by"] | null,
	regionId?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_select_column"]:NeighborhoodRegion_select_column;
	/** Streaming cursor of the table "NeighborhoodRegion" */
["NeighborhoodRegion_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["NeighborhoodRegion_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["NeighborhoodRegion_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	neighborhoodId?:ValueTypes["uuid"] | null,
	regionId?:ValueTypes["uuid"] | null,
	type?:ValueTypes["NeighborhoodRegionType_enum"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by aggregate values of table "Neighborhood" */
["Neighborhood_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Neighborhood_max_order_by"] | null,
	min?:ValueTypes["Neighborhood_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "Neighborhood". All fields are combined with a logical 'AND'. */
["Neighborhood_bool_exp"]: {
	NeighborhoodRegions?:ValueTypes["NeighborhoodRegion_bool_exp"] | null,
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["Neighborhood_bool_exp"][],
	_not?:ValueTypes["Neighborhood_bool_exp"] | null,
	_or?:ValueTypes["Neighborhood_bool_exp"][],
	availableStartingAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	displayName?:ValueTypes["String_comparison_exp"] | null,
	handymanPersonId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	zipCode?:ValueTypes["String_comparison_exp"] | null
};
	/** order by max() on columns of table "Neighborhood" */
["Neighborhood_max_order_by"]: {
	availableStartingAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	displayName?:ValueTypes["order_by"] | null,
	handymanPersonId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "Neighborhood" */
["Neighborhood_min_order_by"]: {
	availableStartingAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	displayName?:ValueTypes["order_by"] | null,
	handymanPersonId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "Neighborhood". */
["Neighborhood_order_by"]: {
	NeighborhoodRegions_aggregate?:ValueTypes["NeighborhoodRegion_aggregate_order_by"] | null,
	Person?:ValueTypes["Person_order_by"] | null,
	availableStartingAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	displayName?:ValueTypes["order_by"] | null,
	handymanPersonId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	zipCode?:ValueTypes["order_by"] | null
};
	/** select columns of table "Neighborhood" */
["Neighborhood_select_column"]:Neighborhood_select_column;
	/** Streaming cursor of the table "Neighborhood" */
["Neighborhood_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Neighborhood_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Neighborhood_stream_cursor_value_input"]: {
	availableStartingAt?:ValueTypes["timestamptz"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	displayName?:string | null,
	handymanPersonId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	zipCode?:string | null
};
	/** columns and relationships of "Person" */
["Person"]: AliasType<{
AppointmentGrades?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGrade_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGrade_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
AppointmentProviders?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider"]],
AppointmentProviders_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider_aggregate"]],
AppointmentReports?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentReport_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentReport_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentReport_bool_exp"] | null},ValueTypes["AppointmentReport"]],
Availability?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Availability_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Availability_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Availability_bool_exp"] | null},ValueTypes["Availability"]],
CreatedAppointmentReports?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentReport_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentReport_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentReport_bool_exp"] | null},ValueTypes["AppointmentReport"]],
CreatedAppointments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment"]],
CreatedAppointments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment_aggregate"]],
CreatedAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Attachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Attachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Attachment_bool_exp"] | null},ValueTypes["Attachment"]],
CreatedTasks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
CreatedTasks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task_aggregate"]],
Devices?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device"]],
Devices_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device_aggregate"]],
	/** An object relationship */
	ExternalIdentifiers?:ValueTypes["ExternalIdentifier"],
Neighborhoods?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Neighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Neighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Neighborhood_bool_exp"] | null},ValueTypes["Neighborhood"]],
PersonBiographyItems?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonBiographyItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonBiographyItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonBiographyItem_bool_exp"] | null},ValueTypes["PersonBiographyItem"]],
PersonHomes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonHome_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonHome_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonHome_bool_exp"] | null},ValueTypes["PersonHome"]],
	/** An object relationship */
	PrimaryHome?:ValueTypes["Home"],
	/** An object relationship */
	ProfilePicture?:ValueTypes["Attachment"],
TaskComments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment"]],
TaskComments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment_aggregate"]],
TaskUpdateLogs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
TaskUpdateLogs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog_aggregate"]],
	biography?:boolean,
	contactPreference?:boolean,
	/** IANA Time Zone */
	currentTimeZone?:boolean,
	email?:boolean,
	firstName?:boolean,
	id?:boolean,
	lastName?:boolean,
	phoneNumber?:boolean,
	type?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "PersonBiographyItem" */
["PersonBiographyItem"]: AliasType<{
	/** An object relationship */
	Person?:ValueTypes["Person"],
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	rank?:boolean,
	title?:boolean,
	updatedAt?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "PersonBiographyItem" */
["PersonBiographyItem_aggregate_order_by"]: {
	avg?:ValueTypes["PersonBiographyItem_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["PersonBiographyItem_max_order_by"] | null,
	min?:ValueTypes["PersonBiographyItem_min_order_by"] | null,
	stddev?:ValueTypes["PersonBiographyItem_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["PersonBiographyItem_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["PersonBiographyItem_stddev_samp_order_by"] | null,
	sum?:ValueTypes["PersonBiographyItem_sum_order_by"] | null,
	var_pop?:ValueTypes["PersonBiographyItem_var_pop_order_by"] | null,
	var_samp?:ValueTypes["PersonBiographyItem_var_samp_order_by"] | null,
	variance?:ValueTypes["PersonBiographyItem_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "PersonBiographyItem" */
["PersonBiographyItem_arr_rel_insert_input"]: {
	data:ValueTypes["PersonBiographyItem_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["PersonBiographyItem_on_conflict"] | null
};
	/** order by avg() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_avg_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "PersonBiographyItem". All fields are combined with a logical 'AND'. */
["PersonBiographyItem_bool_exp"]: {
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["PersonBiographyItem_bool_exp"][],
	_not?:ValueTypes["PersonBiographyItem_bool_exp"] | null,
	_or?:ValueTypes["PersonBiographyItem_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	rank?:ValueTypes["Int_comparison_exp"] | null,
	title?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	/** unique or primary key constraints on table "PersonBiographyItem" */
["PersonBiographyItem_constraint"]:PersonBiographyItem_constraint;
	/** input type for incrementing numeric columns in table "PersonBiographyItem" */
["PersonBiographyItem_inc_input"]: {
	rank?:number | null
};
	/** input type for inserting data into table "PersonBiographyItem" */
["PersonBiographyItem_insert_input"]: {
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	rank?:number | null,
	title?:string | null,
	value?:string | null
};
	/** order by max() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "PersonBiographyItem" */
["PersonBiographyItem_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["PersonBiographyItem"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "PersonBiographyItem" */
["PersonBiographyItem_on_conflict"]: {
	constraint:ValueTypes["PersonBiographyItem_constraint"],
	update_columns:ValueTypes["PersonBiographyItem_update_column"][],
	where?:ValueTypes["PersonBiographyItem_bool_exp"] | null
};
	/** Ordering options when selecting data from "PersonBiographyItem". */
["PersonBiographyItem_order_by"]: {
	Person?:ValueTypes["Person_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: PersonBiographyItem */
["PersonBiographyItem_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "PersonBiographyItem" */
["PersonBiographyItem_select_column"]:PersonBiographyItem_select_column;
	/** input type for updating data in table "PersonBiographyItem" */
["PersonBiographyItem_set_input"]: {
	rank?:number | null,
	title?:string | null,
	value?:string | null
};
	/** order by stddev() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "PersonBiographyItem" */
["PersonBiographyItem_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["PersonBiographyItem_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["PersonBiographyItem_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null,
	rank?:number | null,
	title?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null,
	value?:string | null
};
	/** order by sum() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_sum_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** update columns of table "PersonBiographyItem" */
["PersonBiographyItem_update_column"]:PersonBiographyItem_update_column;
	["PersonBiographyItem_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["PersonBiographyItem_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["PersonBiographyItem_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["PersonBiographyItem_bool_exp"]
};
	/** order by var_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_variance_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	["PersonContactPreference_enum"]:PersonContactPreference_enum;
	/** Boolean expression to compare columns of type "PersonContactPreference_enum". All fields are combined with logical 'AND'. */
["PersonContactPreference_enum_comparison_exp"]: {
	_eq?:ValueTypes["PersonContactPreference_enum"] | null,
	_in?:ValueTypes["PersonContactPreference_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["PersonContactPreference_enum"] | null,
	_nin?:ValueTypes["PersonContactPreference_enum"][]
};
	/** columns and relationships of "PersonHome" */
["PersonHome"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	/** An object relationship */
	Person?:ValueTypes["Person"],
	homeId?:boolean,
	personId?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "PersonHome" */
["PersonHome_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["PersonHome_max_order_by"] | null,
	min?:ValueTypes["PersonHome_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "PersonHome" */
["PersonHome_arr_rel_insert_input"]: {
	data:ValueTypes["PersonHome_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["PersonHome_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "PersonHome". All fields are combined with a logical 'AND'. */
["PersonHome_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	Person?:ValueTypes["Person_bool_exp"] | null,
	_and?:ValueTypes["PersonHome_bool_exp"][],
	_not?:ValueTypes["PersonHome_bool_exp"] | null,
	_or?:ValueTypes["PersonHome_bool_exp"][],
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null
};
	/** unique or primary key constraints on table "PersonHome" */
["PersonHome_constraint"]:PersonHome_constraint;
	/** input type for inserting data into table "PersonHome" */
["PersonHome_insert_input"]: {
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	personId?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "PersonHome" */
["PersonHome_max_order_by"]: {
	homeId?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "PersonHome" */
["PersonHome_min_order_by"]: {
	homeId?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "PersonHome" */
["PersonHome_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["PersonHome"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "PersonHome" */
["PersonHome_on_conflict"]: {
	constraint:ValueTypes["PersonHome_constraint"],
	update_columns:ValueTypes["PersonHome_update_column"][],
	where?:ValueTypes["PersonHome_bool_exp"] | null
};
	/** Ordering options when selecting data from "PersonHome". */
["PersonHome_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	Person?:ValueTypes["Person_order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null
};
	/** select columns of table "PersonHome" */
["PersonHome_select_column"]:PersonHome_select_column;
	/** Streaming cursor of the table "PersonHome" */
["PersonHome_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["PersonHome_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["PersonHome_stream_cursor_value_input"]: {
	homeId?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null
};
	/** placeholder for update columns of table "PersonHome" (current role has no relevant permissions) */
["PersonHome_update_column"]:PersonHome_update_column;
	["PersonType_enum"]:PersonType_enum;
	/** Boolean expression to compare columns of type "PersonType_enum". All fields are combined with logical 'AND'. */
["PersonType_enum_comparison_exp"]: {
	_eq?:ValueTypes["PersonType_enum"] | null,
	_in?:ValueTypes["PersonType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["PersonType_enum"] | null,
	_nin?:ValueTypes["PersonType_enum"][]
};
	/** aggregated selection of "Person" */
["Person_aggregate"]: AliasType<{
	aggregate?:ValueTypes["Person_aggregate_fields"],
	nodes?:ValueTypes["Person"],
		__typename?: boolean
}>;
	["Person_aggregate_bool_exp"]: {
	count?:ValueTypes["Person_aggregate_bool_exp_count"] | null
};
	["Person_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["Person_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["Person_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Person" */
["Person_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["Person_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["Person_max_fields"],
	min?:ValueTypes["Person_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "Person" */
["Person_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Person_max_order_by"] | null,
	min?:ValueTypes["Person_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "Person" */
["Person_arr_rel_insert_input"]: {
	data:ValueTypes["Person_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["Person_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
["Person_bool_exp"]: {
	AppointmentGrades?:ValueTypes["AppointmentGrade_bool_exp"] | null,
	AppointmentProviders?:ValueTypes["AppointmentProvider_bool_exp"] | null,
	AppointmentProviders_aggregate?:ValueTypes["AppointmentProvider_aggregate_bool_exp"] | null,
	AppointmentReports?:ValueTypes["AppointmentReport_bool_exp"] | null,
	Availability?:ValueTypes["Availability_bool_exp"] | null,
	CreatedAppointmentReports?:ValueTypes["AppointmentReport_bool_exp"] | null,
	CreatedAppointments?:ValueTypes["Appointment_bool_exp"] | null,
	CreatedAppointments_aggregate?:ValueTypes["Appointment_aggregate_bool_exp"] | null,
	CreatedAttachments?:ValueTypes["Attachment_bool_exp"] | null,
	CreatedTasks?:ValueTypes["Task_bool_exp"] | null,
	CreatedTasks_aggregate?:ValueTypes["Task_aggregate_bool_exp"] | null,
	Devices?:ValueTypes["Device_bool_exp"] | null,
	Devices_aggregate?:ValueTypes["Device_aggregate_bool_exp"] | null,
	ExternalIdentifiers?:ValueTypes["ExternalIdentifier_bool_exp"] | null,
	Neighborhoods?:ValueTypes["Neighborhood_bool_exp"] | null,
	PersonBiographyItems?:ValueTypes["PersonBiographyItem_bool_exp"] | null,
	PersonHomes?:ValueTypes["PersonHome_bool_exp"] | null,
	PrimaryHome?:ValueTypes["Home_bool_exp"] | null,
	ProfilePicture?:ValueTypes["Attachment_bool_exp"] | null,
	TaskComments?:ValueTypes["TaskComment_bool_exp"] | null,
	TaskComments_aggregate?:ValueTypes["TaskComment_aggregate_bool_exp"] | null,
	TaskUpdateLogs?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_bool_exp"] | null,
	_and?:ValueTypes["Person_bool_exp"][],
	_not?:ValueTypes["Person_bool_exp"] | null,
	_or?:ValueTypes["Person_bool_exp"][],
	biography?:ValueTypes["String_comparison_exp"] | null,
	contactPreference?:ValueTypes["PersonContactPreference_enum_comparison_exp"] | null,
	currentTimeZone?:ValueTypes["String_comparison_exp"] | null,
	email?:ValueTypes["String_comparison_exp"] | null,
	firstName?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	lastName?:ValueTypes["String_comparison_exp"] | null,
	phoneNumber?:ValueTypes["String_comparison_exp"] | null,
	type?:ValueTypes["PersonType_enum_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Person" */
["Person_constraint"]:Person_constraint;
	/** input type for inserting data into table "Person" */
["Person_insert_input"]: {
	AppointmentGrades?:ValueTypes["AppointmentGrade_arr_rel_insert_input"] | null,
	AppointmentProviders?:ValueTypes["AppointmentProvider_arr_rel_insert_input"] | null,
	CreatedAppointments?:ValueTypes["Appointment_arr_rel_insert_input"] | null,
	CreatedAttachments?:ValueTypes["Attachment_arr_rel_insert_input"] | null,
	CreatedTasks?:ValueTypes["Task_arr_rel_insert_input"] | null,
	Devices?:ValueTypes["Device_arr_rel_insert_input"] | null,
	PersonBiographyItems?:ValueTypes["PersonBiographyItem_arr_rel_insert_input"] | null,
	PersonHomes?:ValueTypes["PersonHome_arr_rel_insert_input"] | null,
	ProfilePicture?:ValueTypes["Attachment_obj_rel_insert_input"] | null,
	TaskComments?:ValueTypes["TaskComment_arr_rel_insert_input"] | null,
	TaskUpdateLogs?:ValueTypes["TaskUpdateLog_arr_rel_insert_input"] | null,
	/** IANA Time Zone */
	currentTimeZone?:string | null,
	email?:string | null,
	firstName?:string | null,
	lastName?:string | null,
	phoneNumber?:string | null
};
	/** aggregate max on columns */
["Person_max_fields"]: AliasType<{
	biography?:boolean,
	/** IANA Time Zone */
	currentTimeZone?:boolean,
	email?:boolean,
	firstName?:boolean,
	id?:boolean,
	lastName?:boolean,
	phoneNumber?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "Person" */
["Person_max_order_by"]: {
	biography?:ValueTypes["order_by"] | null,
	/** IANA Time Zone */
	currentTimeZone?:ValueTypes["order_by"] | null,
	email?:ValueTypes["order_by"] | null,
	firstName?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastName?:ValueTypes["order_by"] | null,
	phoneNumber?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["Person_min_fields"]: AliasType<{
	biography?:boolean,
	/** IANA Time Zone */
	currentTimeZone?:boolean,
	email?:boolean,
	firstName?:boolean,
	id?:boolean,
	lastName?:boolean,
	phoneNumber?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "Person" */
["Person_min_order_by"]: {
	biography?:ValueTypes["order_by"] | null,
	/** IANA Time Zone */
	currentTimeZone?:ValueTypes["order_by"] | null,
	email?:ValueTypes["order_by"] | null,
	firstName?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastName?:ValueTypes["order_by"] | null,
	phoneNumber?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Person" */
["Person_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Person"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "Person" */
["Person_obj_rel_insert_input"]: {
	data:ValueTypes["Person_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["Person_on_conflict"] | null
};
	/** on_conflict condition type for table "Person" */
["Person_on_conflict"]: {
	constraint:ValueTypes["Person_constraint"],
	update_columns:ValueTypes["Person_update_column"][],
	where?:ValueTypes["Person_bool_exp"] | null
};
	/** Ordering options when selecting data from "Person". */
["Person_order_by"]: {
	AppointmentGrades_aggregate?:ValueTypes["AppointmentGrade_aggregate_order_by"] | null,
	AppointmentProviders_aggregate?:ValueTypes["AppointmentProvider_aggregate_order_by"] | null,
	AppointmentReports_aggregate?:ValueTypes["AppointmentReport_aggregate_order_by"] | null,
	Availability_aggregate?:ValueTypes["Availability_aggregate_order_by"] | null,
	CreatedAppointmentReports_aggregate?:ValueTypes["AppointmentReport_aggregate_order_by"] | null,
	CreatedAppointments_aggregate?:ValueTypes["Appointment_aggregate_order_by"] | null,
	CreatedAttachments_aggregate?:ValueTypes["Attachment_aggregate_order_by"] | null,
	CreatedTasks_aggregate?:ValueTypes["Task_aggregate_order_by"] | null,
	Devices_aggregate?:ValueTypes["Device_aggregate_order_by"] | null,
	ExternalIdentifiers?:ValueTypes["ExternalIdentifier_order_by"] | null,
	Neighborhoods_aggregate?:ValueTypes["Neighborhood_aggregate_order_by"] | null,
	PersonBiographyItems_aggregate?:ValueTypes["PersonBiographyItem_aggregate_order_by"] | null,
	PersonHomes_aggregate?:ValueTypes["PersonHome_aggregate_order_by"] | null,
	PrimaryHome?:ValueTypes["Home_order_by"] | null,
	ProfilePicture?:ValueTypes["Attachment_order_by"] | null,
	TaskComments_aggregate?:ValueTypes["TaskComment_aggregate_order_by"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_order_by"] | null,
	biography?:ValueTypes["order_by"] | null,
	contactPreference?:ValueTypes["order_by"] | null,
	currentTimeZone?:ValueTypes["order_by"] | null,
	email?:ValueTypes["order_by"] | null,
	firstName?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastName?:ValueTypes["order_by"] | null,
	phoneNumber?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Person */
["Person_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "Person" */
["Person_select_column"]:Person_select_column;
	/** input type for updating data in table "Person" */
["Person_set_input"]: {
	biography?:string | null,
	contactPreference?:ValueTypes["PersonContactPreference_enum"] | null,
	/** IANA Time Zone */
	currentTimeZone?:string | null,
	email?:string | null,
	firstName?:string | null,
	lastName?:string | null,
	phoneNumber?:string | null,
	profilePictureId?:ValueTypes["uuid"] | null
};
	/** Streaming cursor of the table "Person" */
["Person_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Person_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Person_stream_cursor_value_input"]: {
	biography?:string | null,
	contactPreference?:ValueTypes["PersonContactPreference_enum"] | null,
	/** IANA Time Zone */
	currentTimeZone?:string | null,
	email?:string | null,
	firstName?:string | null,
	id?:ValueTypes["uuid"] | null,
	lastName?:string | null,
	phoneNumber?:string | null,
	type?:ValueTypes["PersonType_enum"] | null
};
	/** update columns of table "Person" */
["Person_update_column"]:Person_update_column;
	["Person_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Person_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Person_bool_exp"]
};
	/** Tracks referrals between homes */
["Referral"]: AliasType<{
	/** An object relationship */
	Accepted?:ValueTypes["Home"],
	/** An object relationship */
	Invited?:ValueTypes["Home"],
	/** An object relationship */
	ReferralProgram?:ValueTypes["ReferralProgram"],
	acceptedById?:boolean,
	createdAt?:boolean,
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:boolean,
	id?:boolean,
	invitedById?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Currently active and historical referral programs */
["ReferralProgram"]: AliasType<{
	creditAmount?:boolean,
	id?:boolean,
	isActive?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
["ReferralProgram_bool_exp"]: {
	_and?:ValueTypes["ReferralProgram_bool_exp"][],
	_not?:ValueTypes["ReferralProgram_bool_exp"] | null,
	_or?:ValueTypes["ReferralProgram_bool_exp"][],
	creditAmount?:ValueTypes["Int_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	isActive?:ValueTypes["Boolean_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ReferralProgram". */
["ReferralProgram_order_by"]: {
	creditAmount?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	isActive?:ValueTypes["order_by"] | null
};
	/** select columns of table "ReferralProgram" */
["ReferralProgram_select_column"]:ReferralProgram_select_column;
	/** Streaming cursor of the table "ReferralProgram" */
["ReferralProgram_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ReferralProgram_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ReferralProgram_stream_cursor_value_input"]: {
	creditAmount?:number | null,
	id?:ValueTypes["uuid"] | null,
	isActive?:boolean | null
};
	/** order by aggregate values of table "Referral" */
["Referral_aggregate_order_by"]: {
	avg?:ValueTypes["Referral_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Referral_max_order_by"] | null,
	min?:ValueTypes["Referral_min_order_by"] | null,
	stddev?:ValueTypes["Referral_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["Referral_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["Referral_stddev_samp_order_by"] | null,
	sum?:ValueTypes["Referral_sum_order_by"] | null,
	var_pop?:ValueTypes["Referral_var_pop_order_by"] | null,
	var_samp?:ValueTypes["Referral_var_samp_order_by"] | null,
	variance?:ValueTypes["Referral_variance_order_by"] | null
};
	/** order by avg() on columns of table "Referral" */
["Referral_avg_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "Referral". All fields are combined with a logical 'AND'. */
["Referral_bool_exp"]: {
	Accepted?:ValueTypes["Home_bool_exp"] | null,
	Invited?:ValueTypes["Home_bool_exp"] | null,
	ReferralProgram?:ValueTypes["ReferralProgram_bool_exp"] | null,
	_and?:ValueTypes["Referral_bool_exp"][],
	_not?:ValueTypes["Referral_bool_exp"] | null,
	_or?:ValueTypes["Referral_bool_exp"][],
	acceptedById?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	creditAppliedToInviter?:ValueTypes["Int_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	invitedById?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Referral" */
["Referral_constraint"]:Referral_constraint;
	/** input type for inserting data into table "Referral" */
["Referral_insert_input"]: {
	invitedById?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "Referral" */
["Referral_max_order_by"]: {
	acceptedById?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	invitedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "Referral" */
["Referral_min_order_by"]: {
	acceptedById?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	invitedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Referral" */
["Referral_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Referral"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "Referral" */
["Referral_on_conflict"]: {
	constraint:ValueTypes["Referral_constraint"],
	update_columns:ValueTypes["Referral_update_column"][],
	where?:ValueTypes["Referral_bool_exp"] | null
};
	/** Ordering options when selecting data from "Referral". */
["Referral_order_by"]: {
	Accepted?:ValueTypes["Home_order_by"] | null,
	Invited?:ValueTypes["Home_order_by"] | null,
	ReferralProgram?:ValueTypes["ReferralProgram_order_by"] | null,
	acceptedById?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	creditAppliedToInviter?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	invitedById?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "Referral" */
["Referral_select_column"]:Referral_select_column;
	/** order by stddev() on columns of table "Referral" */
["Referral_stddev_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "Referral" */
["Referral_stddev_pop_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "Referral" */
["Referral_stddev_samp_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "Referral" */
["Referral_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Referral_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Referral_stream_cursor_value_input"]: {
	acceptedById?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:number | null,
	id?:ValueTypes["uuid"] | null,
	invitedById?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "Referral" */
["Referral_sum_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** placeholder for update columns of table "Referral" (current role has no relevant permissions) */
["Referral_update_column"]:Referral_update_column;
	/** order by var_pop() on columns of table "Referral" */
["Referral_var_pop_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "Referral" */
["Referral_var_samp_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "Referral" */
["Referral_variance_order_by"]: {
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?:ValueTypes["order_by"] | null
};
	/** Hierarchical groupings of Neighborhood */
["Region"]: AliasType<{
NeighborhoodRegions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["NeighborhoodRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["NeighborhoodRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["NeighborhoodRegion_bool_exp"] | null},ValueTypes["NeighborhoodRegion"]],
SchedulingHomes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Home_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Home_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Home_bool_exp"] | null},ValueTypes["Home"]],
	createdAt?:boolean,
	/** Friendly name for the regions shown in UI */
	displayName?:boolean,
	id?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "Region". All fields are combined with a logical 'AND'. */
["Region_bool_exp"]: {
	NeighborhoodRegions?:ValueTypes["NeighborhoodRegion_bool_exp"] | null,
	SchedulingHomes?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["Region_bool_exp"][],
	_not?:ValueTypes["Region_bool_exp"] | null,
	_or?:ValueTypes["Region_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	displayName?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** Ordering options when selecting data from "Region". */
["Region_order_by"]: {
	NeighborhoodRegions_aggregate?:ValueTypes["NeighborhoodRegion_aggregate_order_by"] | null,
	SchedulingHomes_aggregate?:ValueTypes["Home_aggregate_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	displayName?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "Region" */
["Region_select_column"]:Region_select_column;
	/** Streaming cursor of the table "Region" */
["Region_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Region_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Region_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	/** Friendly name for the regions shown in UI */
	displayName?:string | null,
	id?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** Schedule of events offered to members */
["ServiceOffer"]: AliasType<{
ServiceOfferResponses?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponse_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponse_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
	/** An object relationship */
	ServiceOfferType?:ValueTypes["ServiceOfferType"],
	additionalChargeAmount?:boolean,
	beginsAtDate?:boolean,
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?:boolean,
	confirmBy?:boolean,
	/** Description about the additional charge for the service */
	costExplaination?:boolean,
	createdAt?:boolean,
	description?:boolean,
	endsAtDate?:boolean,
	endsAtTime?:boolean,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:boolean,
	id?:boolean,
	requiresAdditionalCharge?:boolean,
	requiresConfirmation?:boolean,
	scheduleBy?:boolean,
	title?:boolean,
	type?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** RSVP status of a home to a service offering */
["ServiceOfferResponse"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	/** An object relationship */
	ServiceOffer?:ValueTypes["ServiceOffer"],
	/** An object relationship */
	ServiceOfferResponseStatus?:ValueTypes["ServiceOfferResponseStatus"],
	homeId?:boolean,
	id?:boolean,
	serviceOfferId?:boolean,
	status?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus"]: AliasType<{
ServiceOfferResponses?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponse_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponse_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ServiceOfferResponseStatus". All fields are combined with a logical 'AND'. */
["ServiceOfferResponseStatus_bool_exp"]: {
	ServiceOfferResponses?:ValueTypes["ServiceOfferResponse_bool_exp"] | null,
	_and?:ValueTypes["ServiceOfferResponseStatus_bool_exp"][],
	_not?:ValueTypes["ServiceOfferResponseStatus_bool_exp"] | null,
	_or?:ValueTypes["ServiceOfferResponseStatus_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["ServiceOfferResponseStatus_enum"]:ServiceOfferResponseStatus_enum;
	/** Boolean expression to compare columns of type "ServiceOfferResponseStatus_enum". All fields are combined with logical 'AND'. */
["ServiceOfferResponseStatus_enum_comparison_exp"]: {
	_eq?:ValueTypes["ServiceOfferResponseStatus_enum"] | null,
	_in?:ValueTypes["ServiceOfferResponseStatus_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["ServiceOfferResponseStatus_enum"] | null,
	_nin?:ValueTypes["ServiceOfferResponseStatus_enum"][]
};
	/** Ordering options when selecting data from "ServiceOfferResponseStatus". */
["ServiceOfferResponseStatus_order_by"]: {
	ServiceOfferResponses_aggregate?:ValueTypes["ServiceOfferResponse_aggregate_order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_select_column"]:ServiceOfferResponseStatus_select_column;
	/** Streaming cursor of the table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceOfferResponseStatus_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponseStatus_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** order by aggregate values of table "ServiceOfferResponse" */
["ServiceOfferResponse_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["ServiceOfferResponse_max_order_by"] | null,
	min?:ValueTypes["ServiceOfferResponse_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "ServiceOfferResponse". All fields are combined with a logical 'AND'. */
["ServiceOfferResponse_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	ServiceOffer?:ValueTypes["ServiceOffer_bool_exp"] | null,
	ServiceOfferResponseStatus?:ValueTypes["ServiceOfferResponseStatus_bool_exp"] | null,
	_and?:ValueTypes["ServiceOfferResponse_bool_exp"][],
	_not?:ValueTypes["ServiceOfferResponse_bool_exp"] | null,
	_or?:ValueTypes["ServiceOfferResponse_bool_exp"][],
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	serviceOfferId?:ValueTypes["uuid_comparison_exp"] | null,
	status?:ValueTypes["ServiceOfferResponseStatus_enum_comparison_exp"] | null
};
	/** unique or primary key constraints on table "ServiceOfferResponse" */
["ServiceOfferResponse_constraint"]:ServiceOfferResponse_constraint;
	/** input type for inserting data into table "ServiceOfferResponse" */
["ServiceOfferResponse_insert_input"]: {
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	serviceOfferId?:ValueTypes["uuid"] | null,
	status?:ValueTypes["ServiceOfferResponseStatus_enum"] | null
};
	/** order by max() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_max_order_by"]: {
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	serviceOfferId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_min_order_by"]: {
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	serviceOfferId?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "ServiceOfferResponse" */
["ServiceOfferResponse_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["ServiceOfferResponse"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "ServiceOfferResponse" */
["ServiceOfferResponse_on_conflict"]: {
	constraint:ValueTypes["ServiceOfferResponse_constraint"],
	update_columns:ValueTypes["ServiceOfferResponse_update_column"][],
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null
};
	/** Ordering options when selecting data from "ServiceOfferResponse". */
["ServiceOfferResponse_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	ServiceOffer?:ValueTypes["ServiceOffer_order_by"] | null,
	ServiceOfferResponseStatus?:ValueTypes["ServiceOfferResponseStatus_order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	serviceOfferId?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: ServiceOfferResponse */
["ServiceOfferResponse_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_select_column"]:ServiceOfferResponse_select_column;
	/** input type for updating data in table "ServiceOfferResponse" */
["ServiceOfferResponse_set_input"]: {
	status?:ValueTypes["ServiceOfferResponseStatus_enum"] | null
};
	/** Streaming cursor of the table "ServiceOfferResponse" */
["ServiceOfferResponse_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceOfferResponse_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponse_stream_cursor_value_input"]: {
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	serviceOfferId?:ValueTypes["uuid"] | null,
	status?:ValueTypes["ServiceOfferResponseStatus_enum"] | null
};
	/** update columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_update_column"]:ServiceOfferResponse_update_column;
	["ServiceOfferResponse_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["ServiceOfferResponse_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["ServiceOfferResponse_bool_exp"]
};
	/** columns and relationships of "ServiceOfferType" */
["ServiceOfferType"]: AliasType<{
ServiceOffers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOffer_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOffer_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOffer_bool_exp"] | null},ValueTypes["ServiceOffer"]],
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ServiceOfferType". All fields are combined with a logical 'AND'. */
["ServiceOfferType_bool_exp"]: {
	ServiceOffers?:ValueTypes["ServiceOffer_bool_exp"] | null,
	_and?:ValueTypes["ServiceOfferType_bool_exp"][],
	_not?:ValueTypes["ServiceOfferType_bool_exp"] | null,
	_or?:ValueTypes["ServiceOfferType_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ServiceOfferType". */
["ServiceOfferType_order_by"]: {
	ServiceOffers_aggregate?:ValueTypes["ServiceOffer_aggregate_order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceOfferType" */
["ServiceOfferType_select_column"]:ServiceOfferType_select_column;
	/** Streaming cursor of the table "ServiceOfferType" */
["ServiceOfferType_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceOfferType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferType_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** order by aggregate values of table "ServiceOffer" */
["ServiceOffer_aggregate_order_by"]: {
	avg?:ValueTypes["ServiceOffer_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["ServiceOffer_max_order_by"] | null,
	min?:ValueTypes["ServiceOffer_min_order_by"] | null,
	stddev?:ValueTypes["ServiceOffer_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["ServiceOffer_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["ServiceOffer_stddev_samp_order_by"] | null,
	sum?:ValueTypes["ServiceOffer_sum_order_by"] | null,
	var_pop?:ValueTypes["ServiceOffer_var_pop_order_by"] | null,
	var_samp?:ValueTypes["ServiceOffer_var_samp_order_by"] | null,
	variance?:ValueTypes["ServiceOffer_variance_order_by"] | null
};
	/** order by avg() on columns of table "ServiceOffer" */
["ServiceOffer_avg_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "ServiceOffer". All fields are combined with a logical 'AND'. */
["ServiceOffer_bool_exp"]: {
	ServiceOfferResponses?:ValueTypes["ServiceOfferResponse_bool_exp"] | null,
	ServiceOfferType?:ValueTypes["ServiceOfferType_bool_exp"] | null,
	_and?:ValueTypes["ServiceOffer_bool_exp"][],
	_not?:ValueTypes["ServiceOffer_bool_exp"] | null,
	_or?:ValueTypes["ServiceOffer_bool_exp"][],
	additionalChargeAmount?:ValueTypes["Int_comparison_exp"] | null,
	beginsAtDate?:ValueTypes["date_comparison_exp"] | null,
	beginsAtTime?:ValueTypes["timetz_comparison_exp"] | null,
	confirmBy?:ValueTypes["date_comparison_exp"] | null,
	costExplaination?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	description?:ValueTypes["String_comparison_exp"] | null,
	endsAtDate?:ValueTypes["date_comparison_exp"] | null,
	endsAtTime?:ValueTypes["timetz_comparison_exp"] | null,
	estimatedDuration?:ValueTypes["Int_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	requiresAdditionalCharge?:ValueTypes["Boolean_comparison_exp"] | null,
	requiresConfirmation?:ValueTypes["Boolean_comparison_exp"] | null,
	scheduleBy?:ValueTypes["date_comparison_exp"] | null,
	title?:ValueTypes["String_comparison_exp"] | null,
	type?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "ServiceOffer" */
["ServiceOffer_max_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	beginsAtDate?:ValueTypes["order_by"] | null,
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?:ValueTypes["order_by"] | null,
	confirmBy?:ValueTypes["order_by"] | null,
	/** Description about the additional charge for the service */
	costExplaination?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	endsAtDate?:ValueTypes["order_by"] | null,
	endsAtTime?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	scheduleBy?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "ServiceOffer" */
["ServiceOffer_min_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	beginsAtDate?:ValueTypes["order_by"] | null,
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?:ValueTypes["order_by"] | null,
	confirmBy?:ValueTypes["order_by"] | null,
	/** Description about the additional charge for the service */
	costExplaination?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	endsAtDate?:ValueTypes["order_by"] | null,
	endsAtTime?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	scheduleBy?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "ServiceOffer". */
["ServiceOffer_order_by"]: {
	ServiceOfferResponses_aggregate?:ValueTypes["ServiceOfferResponse_aggregate_order_by"] | null,
	ServiceOfferType?:ValueTypes["ServiceOfferType_order_by"] | null,
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	beginsAtDate?:ValueTypes["order_by"] | null,
	beginsAtTime?:ValueTypes["order_by"] | null,
	confirmBy?:ValueTypes["order_by"] | null,
	costExplaination?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	endsAtDate?:ValueTypes["order_by"] | null,
	endsAtTime?:ValueTypes["order_by"] | null,
	estimatedDuration?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	requiresAdditionalCharge?:ValueTypes["order_by"] | null,
	requiresConfirmation?:ValueTypes["order_by"] | null,
	scheduleBy?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceOffer" */
["ServiceOffer_select_column"]:ServiceOffer_select_column;
	/** order by stddev() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_pop_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_samp_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "ServiceOffer" */
["ServiceOffer_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceOffer_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceOffer_stream_cursor_value_input"]: {
	additionalChargeAmount?:number | null,
	beginsAtDate?:ValueTypes["date"] | null,
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?:ValueTypes["timetz"] | null,
	confirmBy?:ValueTypes["date"] | null,
	/** Description about the additional charge for the service */
	costExplaination?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	description?:string | null,
	endsAtDate?:ValueTypes["date"] | null,
	endsAtTime?:ValueTypes["timetz"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:number | null,
	id?:ValueTypes["uuid"] | null,
	requiresAdditionalCharge?:boolean | null,
	requiresConfirmation?:boolean | null,
	scheduleBy?:ValueTypes["date"] | null,
	title?:string | null,
	type?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "ServiceOffer" */
["ServiceOffer_sum_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** order by var_pop() on columns of table "ServiceOffer" */
["ServiceOffer_var_pop_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "ServiceOffer" */
["ServiceOffer_var_samp_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "ServiceOffer" */
["ServiceOffer_variance_order_by"]: {
	additionalChargeAmount?:ValueTypes["order_by"] | null,
	/** Expected time to complete, in minutes. */
	estimatedDuration?:ValueTypes["order_by"] | null
};
	/** Organization providing service to a home */
["ServiceProvider"]: AliasType<{
	createdAt?:boolean,
	email?:boolean,
	id?:boolean,
	name?:boolean,
	phoneNumber?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "ServiceProviderRequest" */
["ServiceProviderRequest"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
	/** An object relationship */
	ServiceProvider?:ValueTypes["ServiceProvider"],
	/** An object relationship */
	Task?:ValueTypes["Task"],
	id?:boolean,
	stage?:boolean,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:boolean,
	taskId?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "ServiceProviderRequestStage" */
["ServiceProviderRequestStage"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "ServiceProviderRequestStage". All fields are combined with a logical 'AND'. */
["ServiceProviderRequestStage_bool_exp"]: {
	_and?:ValueTypes["ServiceProviderRequestStage_bool_exp"][],
	_not?:ValueTypes["ServiceProviderRequestStage_bool_exp"] | null,
	_or?:ValueTypes["ServiceProviderRequestStage_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["ServiceProviderRequestStage_enum"]:ServiceProviderRequestStage_enum;
	/** Boolean expression to compare columns of type "ServiceProviderRequestStage_enum". All fields are combined with logical 'AND'. */
["ServiceProviderRequestStage_enum_comparison_exp"]: {
	_eq?:ValueTypes["ServiceProviderRequestStage_enum"] | null,
	_in?:ValueTypes["ServiceProviderRequestStage_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["ServiceProviderRequestStage_enum"] | null,
	_nin?:ValueTypes["ServiceProviderRequestStage_enum"][]
};
	/** Ordering options when selecting data from "ServiceProviderRequestStage". */
["ServiceProviderRequestStage_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_select_column"]:ServiceProviderRequestStage_select_column;
	/** Streaming cursor of the table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceProviderRequestStage_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequestStage_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** order by aggregate values of table "ServiceProviderRequest" */
["ServiceProviderRequest_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["ServiceProviderRequest_max_order_by"] | null,
	min?:ValueTypes["ServiceProviderRequest_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_arr_rel_insert_input"]: {
	data:ValueTypes["ServiceProviderRequest_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["ServiceProviderRequest_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "ServiceProviderRequest". All fields are combined with a logical 'AND'. */
["ServiceProviderRequest_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	ServiceProvider?:ValueTypes["ServiceProvider_bool_exp"] | null,
	Task?:ValueTypes["Task_bool_exp"] | null,
	_and?:ValueTypes["ServiceProviderRequest_bool_exp"][],
	_not?:ValueTypes["ServiceProviderRequest_bool_exp"] | null,
	_or?:ValueTypes["ServiceProviderRequest_bool_exp"][],
	id?:ValueTypes["uuid_comparison_exp"] | null,
	stage?:ValueTypes["ServiceProviderRequestStage_enum_comparison_exp"] | null,
	startedProcessingRequestAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	taskId?:ValueTypes["uuid_comparison_exp"] | null
};
	/** unique or primary key constraints on table "ServiceProviderRequest" */
["ServiceProviderRequest_constraint"]:ServiceProviderRequest_constraint;
	/** input type for inserting data into table "ServiceProviderRequest" */
["ServiceProviderRequest_insert_input"]: {
	Appointment?:ValueTypes["Appointment_obj_rel_insert_input"] | null,
	Task?:ValueTypes["Task_obj_rel_insert_input"] | null,
	taskId?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_max_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_min_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "ServiceProviderRequest" */
["ServiceProviderRequest_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["ServiceProviderRequest"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_obj_rel_insert_input"]: {
	data:ValueTypes["ServiceProviderRequest_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["ServiceProviderRequest_on_conflict"] | null
};
	/** on_conflict condition type for table "ServiceProviderRequest" */
["ServiceProviderRequest_on_conflict"]: {
	constraint:ValueTypes["ServiceProviderRequest_constraint"],
	update_columns:ValueTypes["ServiceProviderRequest_update_column"][],
	where?:ValueTypes["ServiceProviderRequest_bool_exp"] | null
};
	/** Ordering options when selecting data from "ServiceProviderRequest". */
["ServiceProviderRequest_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	ServiceProvider?:ValueTypes["ServiceProvider_order_by"] | null,
	Task?:ValueTypes["Task_order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	stage?:ValueTypes["order_by"] | null,
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_select_column"]:ServiceProviderRequest_select_column;
	/** Streaming cursor of the table "ServiceProviderRequest" */
["ServiceProviderRequest_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceProviderRequest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequest_stream_cursor_value_input"]: {
	id?:ValueTypes["uuid"] | null,
	stage?:ValueTypes["ServiceProviderRequestStage_enum"] | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["timestamptz"] | null,
	taskId?:ValueTypes["uuid"] | null
};
	/** placeholder for update columns of table "ServiceProviderRequest" (current role has no relevant permissions) */
["ServiceProviderRequest_update_column"]:ServiceProviderRequest_update_column;
	/** Boolean expression to filter rows from the table "ServiceProvider". All fields are combined with a logical 'AND'. */
["ServiceProvider_bool_exp"]: {
	_and?:ValueTypes["ServiceProvider_bool_exp"][],
	_not?:ValueTypes["ServiceProvider_bool_exp"] | null,
	_or?:ValueTypes["ServiceProvider_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	email?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	phoneNumber?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** Ordering options when selecting data from "ServiceProvider". */
["ServiceProvider_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	email?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	phoneNumber?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "ServiceProvider" */
["ServiceProvider_select_column"]:ServiceProvider_select_column;
	/** Streaming cursor of the table "ServiceProvider" */
["ServiceProvider_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["ServiceProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["ServiceProvider_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	email?:string | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	phoneNumber?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_array_comparison_exp"]: {
	/** is the array contained in the given array value */
	_contained_in?:string[],
	/** does the array contain the given value */
	_contains?:string[],
	_eq?:string[],
	_gt?:string[],
	_gte?:string[],
	_in:string[],
	_is_null?:boolean | null,
	_lt?:string[],
	_lte?:string[],
	_neq?:string[],
	_nin:string[]
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
	_eq?:string | null,
	_gt?:string | null,
	_gte?:string | null,
	/** does the column match the given case-insensitive pattern */
	_ilike?:string | null,
	_in?:string[],
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?:string | null,
	_is_null?:boolean | null,
	/** does the column match the given pattern */
	_like?:string | null,
	_lt?:string | null,
	_lte?:string | null,
	_neq?:string | null,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?:string | null,
	_nin?:string[],
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?:string | null,
	/** does the column NOT match the given pattern */
	_nlike?:string | null,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?:string | null,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?:string | null,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?:string | null,
	/** does the column match the given SQL regular expression */
	_similar?:string | null
};
	/** Tracks Stripe PaymentMethods for a Home */
["StripePaymentMethod"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	createdAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	paymentMethodId?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "StripePaymentMethod" */
["StripePaymentMethod_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["StripePaymentMethod_max_order_by"] | null,
	min?:ValueTypes["StripePaymentMethod_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "StripePaymentMethod". All fields are combined with a logical 'AND'. */
["StripePaymentMethod_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["StripePaymentMethod_bool_exp"][],
	_not?:ValueTypes["StripePaymentMethod_bool_exp"] | null,
	_or?:ValueTypes["StripePaymentMethod_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	paymentMethodId?:ValueTypes["String_comparison_exp"] | null
};
	/** order by max() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	paymentMethodId?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	paymentMethodId?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "StripePaymentMethod". */
["StripePaymentMethod_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	paymentMethodId?:ValueTypes["order_by"] | null
};
	/** select columns of table "StripePaymentMethod" */
["StripePaymentMethod_select_column"]:StripePaymentMethod_select_column;
	/** Streaming cursor of the table "StripePaymentMethod" */
["StripePaymentMethod_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["StripePaymentMethod_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["StripePaymentMethod_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	paymentMethodId?:string | null
};
	/** columns and relationships of "StripeSubscription" */
["StripeSubscription"]: AliasType<{
	/** An object relationship */
	Home?:ValueTypes["Home"],
	canceledAt?:boolean,
	createdAt?:boolean,
	homeId?:boolean,
	id?:boolean,
	interval?:boolean,
	status?:boolean,
	subscriptionId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["StripeSubscriptionStatus_enum"]:StripeSubscriptionStatus_enum;
	/** Boolean expression to compare columns of type "StripeSubscriptionStatus_enum". All fields are combined with logical 'AND'. */
["StripeSubscriptionStatus_enum_comparison_exp"]: {
	_eq?:ValueTypes["StripeSubscriptionStatus_enum"] | null,
	_in?:ValueTypes["StripeSubscriptionStatus_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["StripeSubscriptionStatus_enum"] | null,
	_nin?:ValueTypes["StripeSubscriptionStatus_enum"][]
};
	/** order by aggregate values of table "StripeSubscription" */
["StripeSubscription_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["StripeSubscription_max_order_by"] | null,
	min?:ValueTypes["StripeSubscription_min_order_by"] | null
};
	/** Boolean expression to filter rows from the table "StripeSubscription". All fields are combined with a logical 'AND'. */
["StripeSubscription_bool_exp"]: {
	Home?:ValueTypes["Home_bool_exp"] | null,
	_and?:ValueTypes["StripeSubscription_bool_exp"][],
	_not?:ValueTypes["StripeSubscription_bool_exp"] | null,
	_or?:ValueTypes["StripeSubscription_bool_exp"][],
	canceledAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	interval?:ValueTypes["String_comparison_exp"] | null,
	status?:ValueTypes["StripeSubscriptionStatus_enum_comparison_exp"] | null,
	subscriptionId?:ValueTypes["String_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** order by max() on columns of table "StripeSubscription" */
["StripeSubscription_max_order_by"]: {
	canceledAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	interval?:ValueTypes["order_by"] | null,
	subscriptionId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "StripeSubscription" */
["StripeSubscription_min_order_by"]: {
	canceledAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	interval?:ValueTypes["order_by"] | null,
	subscriptionId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** Ordering options when selecting data from "StripeSubscription". */
["StripeSubscription_order_by"]: {
	Home?:ValueTypes["Home_order_by"] | null,
	canceledAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	interval?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null,
	subscriptionId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "StripeSubscription" */
["StripeSubscription_select_column"]:StripeSubscription_select_column;
	/** Streaming cursor of the table "StripeSubscription" */
["StripeSubscription_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["StripeSubscription_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["StripeSubscription_stream_cursor_value_input"]: {
	canceledAt?:ValueTypes["timestamptz"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	interval?:string | null,
	status?:ValueTypes["StripeSubscriptionStatus_enum"] | null,
	subscriptionId?:string | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	["Stripe_Coupon"]: AliasType<{
	amountOff?:boolean,
	duration?:boolean,
	durationInMonths?:boolean,
	id?:boolean,
	name?:boolean,
	percentOff?:boolean,
		__typename?: boolean
}>;
	["Stripe_Coupon_Duration"]:Stripe_Coupon_Duration;
	["Stripe_FindPromoCodeResult"]: AliasType<{
	coupon?:ValueTypes["Stripe_Coupon"],
		__typename?: boolean
}>;
	["Stripe_Portal"]: AliasType<{
	url?:boolean,
		__typename?: boolean
}>;
	["Stripe_SubscriptionProduct"]: AliasType<{
	description?:boolean,
	id?:boolean,
	name?:boolean,
	prices?:ValueTypes["Stripe_SubscriptionProductPrice"],
		__typename?: boolean
}>;
	["Stripe_SubscriptionProductPrice"]: AliasType<{
	/** Pricing amount in cents */
	amount?:boolean,
	id?:boolean,
	interval?:boolean,
	nickname?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "Task" */
["Task"]: AliasType<{
	/** An object relationship */
	Appointment?:ValueTypes["Appointment"],
Comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment"]],
Comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment_aggregate"]],
	/** An object relationship */
	CreatedBy?:ValueTypes["Person"],
	/** An object relationship */
	Home?:ValueTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?:ValueTypes["HomeInventoryItem"],
	/** An object relationship */
	LastUpdatedBy?:ValueTypes["Person"],
ServiceProviderRequests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProviderRequest_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProviderRequest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequest_bool_exp"] | null},ValueTypes["ServiceProviderRequest"]],
TaskAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskAttachment_bool_exp"] | null},ValueTypes["TaskAttachment"]],
TaskParts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPart_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPart_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPart_bool_exp"] | null},ValueTypes["TaskPart"]],
TaskUpdateLogs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
TaskUpdateLogs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog_aggregate"]],
	appointmentId?:boolean,
	completedAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	description?:boolean,
	dueAt?:boolean,
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	homeId?:boolean,
	homeInventoryItemId?:boolean,
	id?:boolean,
	lastUpdatedById?:boolean,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:boolean,
	parentTaskId?:boolean,
	partsRequired?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
	requiresOperationalReview?:boolean,
	status?:boolean,
	thirdPartyRequired?:boolean,
	title?:boolean,
	type?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "TaskAttachment" */
["TaskAttachment"]: AliasType<{
	/** An object relationship */
	Attachment?:ValueTypes["Attachment"],
	/** An object relationship */
	Task?:ValueTypes["Task"],
	attachmentId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	rank?:boolean,
	taskId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "TaskAttachment" */
["TaskAttachment_aggregate_order_by"]: {
	avg?:ValueTypes["TaskAttachment_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["TaskAttachment_max_order_by"] | null,
	min?:ValueTypes["TaskAttachment_min_order_by"] | null,
	stddev?:ValueTypes["TaskAttachment_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["TaskAttachment_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["TaskAttachment_stddev_samp_order_by"] | null,
	sum?:ValueTypes["TaskAttachment_sum_order_by"] | null,
	var_pop?:ValueTypes["TaskAttachment_var_pop_order_by"] | null,
	var_samp?:ValueTypes["TaskAttachment_var_samp_order_by"] | null,
	variance?:ValueTypes["TaskAttachment_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "TaskAttachment" */
["TaskAttachment_arr_rel_insert_input"]: {
	data:ValueTypes["TaskAttachment_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskAttachment_on_conflict"] | null
};
	/** order by avg() on columns of table "TaskAttachment" */
["TaskAttachment_avg_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "TaskAttachment". All fields are combined with a logical 'AND'. */
["TaskAttachment_bool_exp"]: {
	Attachment?:ValueTypes["Attachment_bool_exp"] | null,
	Task?:ValueTypes["Task_bool_exp"] | null,
	_and?:ValueTypes["TaskAttachment_bool_exp"][],
	_not?:ValueTypes["TaskAttachment_bool_exp"] | null,
	_or?:ValueTypes["TaskAttachment_bool_exp"][],
	attachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	rank?:ValueTypes["Int_comparison_exp"] | null,
	taskId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "TaskAttachment" */
["TaskAttachment_constraint"]:TaskAttachment_constraint;
	/** input type for incrementing numeric columns in table "TaskAttachment" */
["TaskAttachment_inc_input"]: {
	rank?:number | null
};
	/** input type for inserting data into table "TaskAttachment" */
["TaskAttachment_insert_input"]: {
	Attachment?:ValueTypes["Attachment_obj_rel_insert_input"] | null,
	Task?:ValueTypes["Task_obj_rel_insert_input"] | null,
	attachmentId?:ValueTypes["uuid"] | null,
	rank?:number | null,
	taskId?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "TaskAttachment" */
["TaskAttachment_max_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "TaskAttachment" */
["TaskAttachment_min_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "TaskAttachment" */
["TaskAttachment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["TaskAttachment"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "TaskAttachment" */
["TaskAttachment_on_conflict"]: {
	constraint:ValueTypes["TaskAttachment_constraint"],
	update_columns:ValueTypes["TaskAttachment_update_column"][],
	where?:ValueTypes["TaskAttachment_bool_exp"] | null
};
	/** Ordering options when selecting data from "TaskAttachment". */
["TaskAttachment_order_by"]: {
	Attachment?:ValueTypes["Attachment_order_by"] | null,
	Task?:ValueTypes["Task_order_by"] | null,
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: TaskAttachment */
["TaskAttachment_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "TaskAttachment" */
["TaskAttachment_select_column"]:TaskAttachment_select_column;
	/** input type for updating data in table "TaskAttachment" */
["TaskAttachment_set_input"]: {
	rank?:number | null
};
	/** order by stddev() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "TaskAttachment" */
["TaskAttachment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskAttachment_stream_cursor_value_input"]: {
	attachmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	rank?:number | null,
	taskId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "TaskAttachment" */
["TaskAttachment_sum_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** update columns of table "TaskAttachment" */
["TaskAttachment_update_column"]:TaskAttachment_update_column;
	["TaskAttachment_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskAttachment_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskAttachment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["TaskAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "TaskAttachment" */
["TaskAttachment_var_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "TaskAttachment" */
["TaskAttachment_var_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "TaskAttachment" */
["TaskAttachment_variance_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "TaskComment" */
["TaskComment"]: AliasType<{
	/** An object relationship */
	Person?:ValueTypes["Person"],
	/** An object relationship */
	Task?:ValueTypes["Task"],
	body?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	taskId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** aggregated selection of "TaskComment" */
["TaskComment_aggregate"]: AliasType<{
	aggregate?:ValueTypes["TaskComment_aggregate_fields"],
	nodes?:ValueTypes["TaskComment"],
		__typename?: boolean
}>;
	["TaskComment_aggregate_bool_exp"]: {
	count?:ValueTypes["TaskComment_aggregate_bool_exp_count"] | null
};
	["TaskComment_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["TaskComment_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["TaskComment_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "TaskComment" */
["TaskComment_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["TaskComment_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["TaskComment_max_fields"],
	min?:ValueTypes["TaskComment_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "TaskComment" */
["TaskComment_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["TaskComment_max_order_by"] | null,
	min?:ValueTypes["TaskComment_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "TaskComment" */
["TaskComment_arr_rel_insert_input"]: {
	data:ValueTypes["TaskComment_insert_input"][]
};
	/** Boolean expression to filter rows from the table "TaskComment". All fields are combined with a logical 'AND'. */
["TaskComment_bool_exp"]: {
	Person?:ValueTypes["Person_bool_exp"] | null,
	Task?:ValueTypes["Task_bool_exp"] | null,
	_and?:ValueTypes["TaskComment_bool_exp"][],
	_not?:ValueTypes["TaskComment_bool_exp"] | null,
	_or?:ValueTypes["TaskComment_bool_exp"][],
	body?:ValueTypes["String_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	personId?:ValueTypes["uuid_comparison_exp"] | null,
	taskId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** input type for inserting data into table "TaskComment" */
["TaskComment_insert_input"]: {
	Person?:ValueTypes["Person_obj_rel_insert_input"] | null,
	Task?:ValueTypes["Task_obj_rel_insert_input"] | null,
	body?:string | null,
	taskId?:ValueTypes["uuid"] | null
};
	/** aggregate max on columns */
["TaskComment_max_fields"]: AliasType<{
	body?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	taskId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "TaskComment" */
["TaskComment_max_order_by"]: {
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["TaskComment_min_fields"]: AliasType<{
	body?:boolean,
	createdAt?:boolean,
	id?:boolean,
	personId?:boolean,
	taskId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "TaskComment" */
["TaskComment_min_order_by"]: {
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "TaskComment" */
["TaskComment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["TaskComment"],
		__typename?: boolean
}>;
	/** Ordering options when selecting data from "TaskComment". */
["TaskComment_order_by"]: {
	Person?:ValueTypes["Person_order_by"] | null,
	Task?:ValueTypes["Task_order_by"] | null,
	body?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	personId?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** select columns of table "TaskComment" */
["TaskComment_select_column"]:TaskComment_select_column;
	/** input type for updating data in table "TaskComment" */
["TaskComment_set_input"]: {
	body?:string | null
};
	/** Streaming cursor of the table "TaskComment" */
["TaskComment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskComment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskComment_stream_cursor_value_input"]: {
	body?:string | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	personId?:ValueTypes["uuid"] | null,
	taskId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	["TaskComment_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskComment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["TaskComment_bool_exp"]
};
	/** Tracks status of parts required for a Task */
["TaskPart"]: AliasType<{
	/** An object relationship */
	ActiveAgent?:ValueTypes["Person"],
	/** An object relationship */
	LastUpdatedBy?:ValueTypes["Person"],
	/** An object relationship */
	OrderedBy?:ValueTypes["Person"],
	/** An object relationship */
	Task?:ValueTypes["Task"],
	createdAt?:boolean,
	estimatedDeliveryBy?:boolean,
	id?:boolean,
	/** When member asks to procure part. */
	memberStartedRequestAt?:boolean,
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?:boolean,
	/** When the part came into posession by member or operations. */
	partAcquiredAt?:boolean,
	partDeliveredAt?:boolean,
	partDeliveryLocation?:boolean,
	partName?:boolean,
	/** If the part is in the companies or member's posession. */
	partOnHand?:boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?:boolean,
	/** Internal member who purchased part. */
	personOrderedId?:boolean,
	requiresReview?:boolean,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:boolean,
	status?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["TaskPartDeliveryLocation_enum"]:TaskPartDeliveryLocation_enum;
	/** Boolean expression to compare columns of type "TaskPartDeliveryLocation_enum". All fields are combined with logical 'AND'. */
["TaskPartDeliveryLocation_enum_comparison_exp"]: {
	_eq?:ValueTypes["TaskPartDeliveryLocation_enum"] | null,
	_in?:ValueTypes["TaskPartDeliveryLocation_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["TaskPartDeliveryLocation_enum"] | null,
	_nin?:ValueTypes["TaskPartDeliveryLocation_enum"][]
};
	/** columns and relationships of "TaskPartPartyResponsible" */
["TaskPartPartyResponsible"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "TaskPartPartyResponsible". All fields are combined with a logical 'AND'. */
["TaskPartPartyResponsible_bool_exp"]: {
	_and?:ValueTypes["TaskPartPartyResponsible_bool_exp"][],
	_not?:ValueTypes["TaskPartPartyResponsible_bool_exp"] | null,
	_or?:ValueTypes["TaskPartPartyResponsible_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["TaskPartPartyResponsible_enum"]:TaskPartPartyResponsible_enum;
	/** Boolean expression to compare columns of type "TaskPartPartyResponsible_enum". All fields are combined with logical 'AND'. */
["TaskPartPartyResponsible_enum_comparison_exp"]: {
	_eq?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	_in?:ValueTypes["TaskPartPartyResponsible_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	_nin?:ValueTypes["TaskPartPartyResponsible_enum"][]
};
	/** Ordering options when selecting data from "TaskPartPartyResponsible". */
["TaskPartPartyResponsible_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_select_column"]:TaskPartPartyResponsible_select_column;
	/** Streaming cursor of the table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskPartPartyResponsible_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskPartPartyResponsible_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** columns and relationships of "TaskPartStatus" */
["TaskPartStatus"]: AliasType<{
	description?:boolean,
	value?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "TaskPartStatus". All fields are combined with a logical 'AND'. */
["TaskPartStatus_bool_exp"]: {
	_and?:ValueTypes["TaskPartStatus_bool_exp"][],
	_not?:ValueTypes["TaskPartStatus_bool_exp"] | null,
	_or?:ValueTypes["TaskPartStatus_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	value?:ValueTypes["String_comparison_exp"] | null
};
	["TaskPartStatus_enum"]:TaskPartStatus_enum;
	/** Boolean expression to compare columns of type "TaskPartStatus_enum". All fields are combined with logical 'AND'. */
["TaskPartStatus_enum_comparison_exp"]: {
	_eq?:ValueTypes["TaskPartStatus_enum"] | null,
	_in?:ValueTypes["TaskPartStatus_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["TaskPartStatus_enum"] | null,
	_nin?:ValueTypes["TaskPartStatus_enum"][]
};
	/** Ordering options when selecting data from "TaskPartStatus". */
["TaskPartStatus_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	value?:ValueTypes["order_by"] | null
};
	/** select columns of table "TaskPartStatus" */
["TaskPartStatus_select_column"]:TaskPartStatus_select_column;
	/** Streaming cursor of the table "TaskPartStatus" */
["TaskPartStatus_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskPartStatus_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskPartStatus_stream_cursor_value_input"]: {
	description?:string | null,
	value?:string | null
};
	/** order by aggregate values of table "TaskPart" */
["TaskPart_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["TaskPart_max_order_by"] | null,
	min?:ValueTypes["TaskPart_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "TaskPart" */
["TaskPart_arr_rel_insert_input"]: {
	data:ValueTypes["TaskPart_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskPart_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "TaskPart". All fields are combined with a logical 'AND'. */
["TaskPart_bool_exp"]: {
	ActiveAgent?:ValueTypes["Person_bool_exp"] | null,
	LastUpdatedBy?:ValueTypes["Person_bool_exp"] | null,
	OrderedBy?:ValueTypes["Person_bool_exp"] | null,
	Task?:ValueTypes["Task_bool_exp"] | null,
	_and?:ValueTypes["TaskPart_bool_exp"][],
	_not?:ValueTypes["TaskPart_bool_exp"] | null,
	_or?:ValueTypes["TaskPart_bool_exp"][],
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	estimatedDeliveryBy?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	memberStartedRequestAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	orderedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	partAcquiredAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	partDeliveredAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	partDeliveryLocation?:ValueTypes["TaskPartDeliveryLocation_enum_comparison_exp"] | null,
	partName?:ValueTypes["String_comparison_exp"] | null,
	partOnHand?:ValueTypes["Boolean_comparison_exp"] | null,
	partyResponsible?:ValueTypes["TaskPartPartyResponsible_enum_comparison_exp"] | null,
	personOrderedId?:ValueTypes["uuid_comparison_exp"] | null,
	requiresReview?:ValueTypes["Boolean_comparison_exp"] | null,
	startedProcessingRequestAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	status?:ValueTypes["TaskPartStatus_enum_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "TaskPart" */
["TaskPart_constraint"]:TaskPart_constraint;
	/** input type for inserting data into table "TaskPart" */
["TaskPart_insert_input"]: {
	ActiveAgent?:ValueTypes["Person_obj_rel_insert_input"] | null,
	LastUpdatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	OrderedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	Task?:ValueTypes["Task_obj_rel_insert_input"] | null,
	id?:ValueTypes["uuid"] | null,
	partName?:string | null,
	/** If the part is in the companies or member's posession. */
	partOnHand?:boolean | null,
	/** Who is responsible for procuring the part. */
	partyResponsible?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	/** Internal member who purchased part. */
	personOrderedId?:ValueTypes["uuid"] | null,
	taskId?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "TaskPart" */
["TaskPart_max_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	estimatedDeliveryBy?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	/** When member asks to procure part. */
	memberStartedRequestAt?:ValueTypes["order_by"] | null,
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?:ValueTypes["order_by"] | null,
	/** When the part came into posession by member or operations. */
	partAcquiredAt?:ValueTypes["order_by"] | null,
	partDeliveredAt?:ValueTypes["order_by"] | null,
	partName?:ValueTypes["order_by"] | null,
	/** Internal member who purchased part. */
	personOrderedId?:ValueTypes["order_by"] | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "TaskPart" */
["TaskPart_min_order_by"]: {
	createdAt?:ValueTypes["order_by"] | null,
	estimatedDeliveryBy?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	/** When member asks to procure part. */
	memberStartedRequestAt?:ValueTypes["order_by"] | null,
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?:ValueTypes["order_by"] | null,
	/** When the part came into posession by member or operations. */
	partAcquiredAt?:ValueTypes["order_by"] | null,
	partDeliveredAt?:ValueTypes["order_by"] | null,
	partName?:ValueTypes["order_by"] | null,
	/** Internal member who purchased part. */
	personOrderedId?:ValueTypes["order_by"] | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "TaskPart" */
["TaskPart_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["TaskPart"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "TaskPart" */
["TaskPart_obj_rel_insert_input"]: {
	data:ValueTypes["TaskPart_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskPart_on_conflict"] | null
};
	/** on_conflict condition type for table "TaskPart" */
["TaskPart_on_conflict"]: {
	constraint:ValueTypes["TaskPart_constraint"],
	update_columns:ValueTypes["TaskPart_update_column"][],
	where?:ValueTypes["TaskPart_bool_exp"] | null
};
	/** Ordering options when selecting data from "TaskPart". */
["TaskPart_order_by"]: {
	ActiveAgent?:ValueTypes["Person_order_by"] | null,
	LastUpdatedBy?:ValueTypes["Person_order_by"] | null,
	OrderedBy?:ValueTypes["Person_order_by"] | null,
	Task?:ValueTypes["Task_order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	estimatedDeliveryBy?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	memberStartedRequestAt?:ValueTypes["order_by"] | null,
	orderedAt?:ValueTypes["order_by"] | null,
	partAcquiredAt?:ValueTypes["order_by"] | null,
	partDeliveredAt?:ValueTypes["order_by"] | null,
	partDeliveryLocation?:ValueTypes["order_by"] | null,
	partName?:ValueTypes["order_by"] | null,
	partOnHand?:ValueTypes["order_by"] | null,
	partyResponsible?:ValueTypes["order_by"] | null,
	personOrderedId?:ValueTypes["order_by"] | null,
	requiresReview?:ValueTypes["order_by"] | null,
	startedProcessingRequestAt?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: TaskPart */
["TaskPart_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "TaskPart" */
["TaskPart_select_column"]:TaskPart_select_column;
	/** input type for updating data in table "TaskPart" */
["TaskPart_set_input"]: {
	id?:ValueTypes["uuid"] | null,
	partName?:string | null,
	/** If the part is in the companies or member's posession. */
	partOnHand?:boolean | null,
	/** Who is responsible for procuring the part. */
	partyResponsible?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	/** Internal member who purchased part. */
	personOrderedId?:ValueTypes["uuid"] | null
};
	/** Streaming cursor of the table "TaskPart" */
["TaskPart_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskPart_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskPart_stream_cursor_value_input"]: {
	createdAt?:ValueTypes["timestamptz"] | null,
	estimatedDeliveryBy?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	/** When member asks to procure part. */
	memberStartedRequestAt?:ValueTypes["timestamptz"] | null,
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?:ValueTypes["timestamptz"] | null,
	/** When the part came into posession by member or operations. */
	partAcquiredAt?:ValueTypes["timestamptz"] | null,
	partDeliveredAt?:ValueTypes["timestamptz"] | null,
	partDeliveryLocation?:ValueTypes["TaskPartDeliveryLocation_enum"] | null,
	partName?:string | null,
	/** If the part is in the companies or member's posession. */
	partOnHand?:boolean | null,
	/** Who is responsible for procuring the part. */
	partyResponsible?:ValueTypes["TaskPartPartyResponsible_enum"] | null,
	/** Internal member who purchased part. */
	personOrderedId?:ValueTypes["uuid"] | null,
	requiresReview?:boolean | null,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ValueTypes["timestamptz"] | null,
	status?:ValueTypes["TaskPartStatus_enum"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** update columns of table "TaskPart" */
["TaskPart_update_column"]:TaskPart_update_column;
	["TaskPart_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskPart_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["TaskPart_bool_exp"]
};
	["TaskStatus_enum"]:TaskStatus_enum;
	/** Boolean expression to compare columns of type "TaskStatus_enum". All fields are combined with logical 'AND'. */
["TaskStatus_enum_comparison_exp"]: {
	_eq?:ValueTypes["TaskStatus_enum"] | null,
	_in?:ValueTypes["TaskStatus_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["TaskStatus_enum"] | null,
	_nin?:ValueTypes["TaskStatus_enum"][]
};
	["TaskType_enum"]:TaskType_enum;
	/** Boolean expression to compare columns of type "TaskType_enum". All fields are combined with logical 'AND'. */
["TaskType_enum_comparison_exp"]: {
	_eq?:ValueTypes["TaskType_enum"] | null,
	_in?:ValueTypes["TaskType_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["TaskType_enum"] | null,
	_nin?:ValueTypes["TaskType_enum"][]
};
	/** Data points at a moment in time about a Task */
["TaskUpdateLog"]: AliasType<{
	/** An object relationship */
	AppointmentReport?:ValueTypes["AppointmentReport"],
	/** An object relationship */
	Author?:ValueTypes["Person"],
	/** An object relationship */
	CreatedBy?:ValueTypes["Person"],
	/** An object relationship */
	ServiceProviderRequest?:ValueTypes["ServiceProviderRequest"],
	/** An object relationship */
	Task?:ValueTypes["Task"],
	/** An object relationship */
	TaskPart?:ValueTypes["TaskPart"],
TaskUpdateLogAttachments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLogAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLogAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null},ValueTypes["TaskUpdateLogAttachment"]],
	appointmentReportId?:boolean,
	authorId?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	id?:boolean,
	notes?:boolean,
	/** DEPRECATED */
	partsDescription?:boolean,
	/** DEPRECATED */
	partsRequired?:boolean,
	ranOutOfTime?:boolean,
	serviceProviderRequestId?:boolean,
	status?:boolean,
	taskId?:boolean,
	taskPartId?:boolean,
	/** DEPRECATED */
	thirdPartyDescription?:boolean,
	/** DEPRECATED */
	thirdPartyRequired?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** Links file uploads to task updates */
["TaskUpdateLogAttachment"]: AliasType<{
	/** An object relationship */
	Attachment?:ValueTypes["Attachment"],
	/** An object relationship */
	TaskUpdateLog?:ValueTypes["TaskUpdateLog"],
	attachmentId?:boolean,
	createdAt?:boolean,
	id?:boolean,
	rank?:boolean,
	taskUpdateLogId?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by aggregate values of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_aggregate_order_by"]: {
	avg?:ValueTypes["TaskUpdateLogAttachment_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["TaskUpdateLogAttachment_max_order_by"] | null,
	min?:ValueTypes["TaskUpdateLogAttachment_min_order_by"] | null,
	stddev?:ValueTypes["TaskUpdateLogAttachment_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["TaskUpdateLogAttachment_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["TaskUpdateLogAttachment_stddev_samp_order_by"] | null,
	sum?:ValueTypes["TaskUpdateLogAttachment_sum_order_by"] | null,
	var_pop?:ValueTypes["TaskUpdateLogAttachment_var_pop_order_by"] | null,
	var_samp?:ValueTypes["TaskUpdateLogAttachment_var_samp_order_by"] | null,
	variance?:ValueTypes["TaskUpdateLogAttachment_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_arr_rel_insert_input"]: {
	data:ValueTypes["TaskUpdateLogAttachment_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLogAttachment_on_conflict"] | null
};
	/** order by avg() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_avg_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "TaskUpdateLogAttachment". All fields are combined with a logical 'AND'. */
["TaskUpdateLogAttachment_bool_exp"]: {
	Attachment?:ValueTypes["Attachment_bool_exp"] | null,
	TaskUpdateLog?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	_and?:ValueTypes["TaskUpdateLogAttachment_bool_exp"][],
	_not?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null,
	_or?:ValueTypes["TaskUpdateLogAttachment_bool_exp"][],
	attachmentId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	rank?:ValueTypes["Int_comparison_exp"] | null,
	taskUpdateLogId?:ValueTypes["uuid_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_constraint"]:TaskUpdateLogAttachment_constraint;
	/** input type for incrementing numeric columns in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_inc_input"]: {
	rank?:number | null
};
	/** input type for inserting data into table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_insert_input"]: {
	Attachment?:ValueTypes["Attachment_obj_rel_insert_input"] | null,
	TaskUpdateLog?:ValueTypes["TaskUpdateLog_obj_rel_insert_input"] | null,
	attachmentId?:ValueTypes["uuid"] | null,
	rank?:number | null,
	taskUpdateLogId?:ValueTypes["uuid"] | null
};
	/** order by max() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_max_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskUpdateLogId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** order by min() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_min_order_by"]: {
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskUpdateLogId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["TaskUpdateLogAttachment"],
		__typename?: boolean
}>;
	/** on_conflict condition type for table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_on_conflict"]: {
	constraint:ValueTypes["TaskUpdateLogAttachment_constraint"],
	update_columns:ValueTypes["TaskUpdateLogAttachment_update_column"][],
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null
};
	/** Ordering options when selecting data from "TaskUpdateLogAttachment". */
["TaskUpdateLogAttachment_order_by"]: {
	Attachment?:ValueTypes["Attachment_order_by"] | null,
	TaskUpdateLog?:ValueTypes["TaskUpdateLog_order_by"] | null,
	attachmentId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rank?:ValueTypes["order_by"] | null,
	taskUpdateLogId?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: TaskUpdateLogAttachment */
["TaskUpdateLogAttachment_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_select_column"]:TaskUpdateLogAttachment_select_column;
	/** input type for updating data in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_set_input"]: {
	rank?:number | null
};
	/** order by stddev() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by stddev_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskUpdateLogAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLogAttachment_stream_cursor_value_input"]: {
	attachmentId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	rank?:number | null,
	taskUpdateLogId?:ValueTypes["uuid"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** order by sum() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_sum_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** update columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_update_column"]:TaskUpdateLogAttachment_update_column;
	["TaskUpdateLogAttachment_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskUpdateLogAttachment_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLogAttachment_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["TaskUpdateLogAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_pop_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by var_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_samp_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** order by variance() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_variance_order_by"]: {
	rank?:ValueTypes["order_by"] | null
};
	/** aggregated selection of "TaskUpdateLog" */
["TaskUpdateLog_aggregate"]: AliasType<{
	aggregate?:ValueTypes["TaskUpdateLog_aggregate_fields"],
	nodes?:ValueTypes["TaskUpdateLog"],
		__typename?: boolean
}>;
	["TaskUpdateLog_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["TaskUpdateLog_aggregate_bool_exp_bool_and"] | null,
	bool_or?:ValueTypes["TaskUpdateLog_aggregate_bool_exp_bool_or"] | null,
	count?:ValueTypes["TaskUpdateLog_aggregate_bool_exp_count"] | null
};
	["TaskUpdateLog_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["TaskUpdateLog_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["TaskUpdateLog_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["TaskUpdateLog_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "TaskUpdateLog" */
["TaskUpdateLog_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["TaskUpdateLog_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["TaskUpdateLog_max_fields"],
	min?:ValueTypes["TaskUpdateLog_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "TaskUpdateLog" */
["TaskUpdateLog_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["TaskUpdateLog_max_order_by"] | null,
	min?:ValueTypes["TaskUpdateLog_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_arr_rel_insert_input"]: {
	data:ValueTypes["TaskUpdateLog_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLog_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "TaskUpdateLog". All fields are combined with a logical 'AND'. */
["TaskUpdateLog_bool_exp"]: {
	AppointmentReport?:ValueTypes["AppointmentReport_bool_exp"] | null,
	Author?:ValueTypes["Person_bool_exp"] | null,
	CreatedBy?:ValueTypes["Person_bool_exp"] | null,
	ServiceProviderRequest?:ValueTypes["ServiceProviderRequest_bool_exp"] | null,
	Task?:ValueTypes["Task_bool_exp"] | null,
	TaskPart?:ValueTypes["TaskPart_bool_exp"] | null,
	TaskUpdateLogAttachments?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null,
	_and?:ValueTypes["TaskUpdateLog_bool_exp"][],
	_not?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	_or?:ValueTypes["TaskUpdateLog_bool_exp"][],
	appointmentReportId?:ValueTypes["uuid_comparison_exp"] | null,
	authorId?:ValueTypes["uuid_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdById?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	notes?:ValueTypes["String_comparison_exp"] | null,
	partsDescription?:ValueTypes["String_comparison_exp"] | null,
	partsRequired?:ValueTypes["Boolean_comparison_exp"] | null,
	ranOutOfTime?:ValueTypes["Boolean_comparison_exp"] | null,
	serviceProviderRequestId?:ValueTypes["uuid_comparison_exp"] | null,
	status?:ValueTypes["TaskStatus_enum_comparison_exp"] | null,
	taskId?:ValueTypes["uuid_comparison_exp"] | null,
	taskPartId?:ValueTypes["uuid_comparison_exp"] | null,
	thirdPartyDescription?:ValueTypes["String_comparison_exp"] | null,
	thirdPartyRequired?:ValueTypes["Boolean_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "TaskUpdateLog" */
["TaskUpdateLog_constraint"]:TaskUpdateLog_constraint;
	/** input type for inserting data into table "TaskUpdateLog" */
["TaskUpdateLog_insert_input"]: {
	Author?:ValueTypes["Person_obj_rel_insert_input"] | null,
	CreatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	ServiceProviderRequest?:ValueTypes["ServiceProviderRequest_obj_rel_insert_input"] | null,
	Task?:ValueTypes["Task_obj_rel_insert_input"] | null,
	TaskPart?:ValueTypes["TaskPart_obj_rel_insert_input"] | null,
	TaskUpdateLogAttachments?:ValueTypes["TaskUpdateLogAttachment_arr_rel_insert_input"] | null,
	notes?:string | null,
	/** DEPRECATED */
	partsDescription?:string | null,
	/** DEPRECATED */
	partsRequired?:boolean | null,
	serviceProviderRequestId?:ValueTypes["uuid"] | null,
	status?:ValueTypes["TaskStatus_enum"] | null,
	taskId?:ValueTypes["uuid"] | null,
	taskPartId?:ValueTypes["uuid"] | null,
	/** DEPRECATED */
	thirdPartyDescription?:string | null,
	/** DEPRECATED */
	thirdPartyRequired?:boolean | null
};
	/** aggregate max on columns */
["TaskUpdateLog_max_fields"]: AliasType<{
	appointmentReportId?:boolean,
	authorId?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	id?:boolean,
	notes?:boolean,
	/** DEPRECATED */
	partsDescription?:boolean,
	serviceProviderRequestId?:boolean,
	taskId?:boolean,
	taskPartId?:boolean,
	/** DEPRECATED */
	thirdPartyDescription?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_max_order_by"]: {
	appointmentReportId?:ValueTypes["order_by"] | null,
	authorId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	/** DEPRECATED */
	partsDescription?:ValueTypes["order_by"] | null,
	serviceProviderRequestId?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	taskPartId?:ValueTypes["order_by"] | null,
	/** DEPRECATED */
	thirdPartyDescription?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["TaskUpdateLog_min_fields"]: AliasType<{
	appointmentReportId?:boolean,
	authorId?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	id?:boolean,
	notes?:boolean,
	/** DEPRECATED */
	partsDescription?:boolean,
	serviceProviderRequestId?:boolean,
	taskId?:boolean,
	taskPartId?:boolean,
	/** DEPRECATED */
	thirdPartyDescription?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_min_order_by"]: {
	appointmentReportId?:ValueTypes["order_by"] | null,
	authorId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	/** DEPRECATED */
	partsDescription?:ValueTypes["order_by"] | null,
	serviceProviderRequestId?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	taskPartId?:ValueTypes["order_by"] | null,
	/** DEPRECATED */
	thirdPartyDescription?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "TaskUpdateLog" */
["TaskUpdateLog_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["TaskUpdateLog"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_obj_rel_insert_input"]: {
	data:ValueTypes["TaskUpdateLog_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLog_on_conflict"] | null
};
	/** on_conflict condition type for table "TaskUpdateLog" */
["TaskUpdateLog_on_conflict"]: {
	constraint:ValueTypes["TaskUpdateLog_constraint"],
	update_columns:ValueTypes["TaskUpdateLog_update_column"][],
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null
};
	/** Ordering options when selecting data from "TaskUpdateLog". */
["TaskUpdateLog_order_by"]: {
	AppointmentReport?:ValueTypes["AppointmentReport_order_by"] | null,
	Author?:ValueTypes["Person_order_by"] | null,
	CreatedBy?:ValueTypes["Person_order_by"] | null,
	ServiceProviderRequest?:ValueTypes["ServiceProviderRequest_order_by"] | null,
	Task?:ValueTypes["Task_order_by"] | null,
	TaskPart?:ValueTypes["TaskPart_order_by"] | null,
	TaskUpdateLogAttachments_aggregate?:ValueTypes["TaskUpdateLogAttachment_aggregate_order_by"] | null,
	appointmentReportId?:ValueTypes["order_by"] | null,
	authorId?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	partsDescription?:ValueTypes["order_by"] | null,
	partsRequired?:ValueTypes["order_by"] | null,
	ranOutOfTime?:ValueTypes["order_by"] | null,
	serviceProviderRequestId?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null,
	taskId?:ValueTypes["order_by"] | null,
	taskPartId?:ValueTypes["order_by"] | null,
	thirdPartyDescription?:ValueTypes["order_by"] | null,
	thirdPartyRequired?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: TaskUpdateLog */
["TaskUpdateLog_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column"]:TaskUpdateLog_select_column;
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"]:TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"]:TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TaskUpdateLog" */
["TaskUpdateLog_set_input"]: {
	notes?:string | null,
	/** DEPRECATED */
	partsDescription?:string | null,
	/** DEPRECATED */
	partsRequired?:boolean | null,
	serviceProviderRequestId?:ValueTypes["uuid"] | null,
	status?:ValueTypes["TaskStatus_enum"] | null,
	taskPartId?:ValueTypes["uuid"] | null,
	/** DEPRECATED */
	thirdPartyDescription?:string | null,
	/** DEPRECATED */
	thirdPartyRequired?:boolean | null
};
	/** Streaming cursor of the table "TaskUpdateLog" */
["TaskUpdateLog_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TaskUpdateLog_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLog_stream_cursor_value_input"]: {
	appointmentReportId?:ValueTypes["uuid"] | null,
	authorId?:ValueTypes["uuid"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	createdById?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	notes?:string | null,
	/** DEPRECATED */
	partsDescription?:string | null,
	/** DEPRECATED */
	partsRequired?:boolean | null,
	ranOutOfTime?:boolean | null,
	serviceProviderRequestId?:ValueTypes["uuid"] | null,
	status?:ValueTypes["TaskStatus_enum"] | null,
	taskId?:ValueTypes["uuid"] | null,
	taskPartId?:ValueTypes["uuid"] | null,
	/** DEPRECATED */
	thirdPartyDescription?:string | null,
	/** DEPRECATED */
	thirdPartyRequired?:boolean | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** update columns of table "TaskUpdateLog" */
["TaskUpdateLog_update_column"]:TaskUpdateLog_update_column;
	["TaskUpdateLog_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLog_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["TaskUpdateLog_bool_exp"]
};
	/** aggregated selection of "Task" */
["Task_aggregate"]: AliasType<{
	aggregate?:ValueTypes["Task_aggregate_fields"],
	nodes?:ValueTypes["Task"],
		__typename?: boolean
}>;
	["Task_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["Task_aggregate_bool_exp_bool_and"] | null,
	bool_or?:ValueTypes["Task_aggregate_bool_exp_bool_or"] | null,
	count?:ValueTypes["Task_aggregate_bool_exp_count"] | null
};
	["Task_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["Task_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["Task_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean | null,
	filter?:ValueTypes["Task_bool_exp"] | null,
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["Task_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["Task_select_column"][],
	distinct?:boolean | null,
	filter?:ValueTypes["Task_bool_exp"] | null,
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Task" */
["Task_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["Task_avg_fields"],
count?: [{	columns?:ValueTypes["Task_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["Task_max_fields"],
	min?:ValueTypes["Task_min_fields"],
	stddev?:ValueTypes["Task_stddev_fields"],
	stddev_pop?:ValueTypes["Task_stddev_pop_fields"],
	stddev_samp?:ValueTypes["Task_stddev_samp_fields"],
	sum?:ValueTypes["Task_sum_fields"],
	var_pop?:ValueTypes["Task_var_pop_fields"],
	var_samp?:ValueTypes["Task_var_samp_fields"],
	variance?:ValueTypes["Task_variance_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "Task" */
["Task_aggregate_order_by"]: {
	avg?:ValueTypes["Task_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["Task_max_order_by"] | null,
	min?:ValueTypes["Task_min_order_by"] | null,
	stddev?:ValueTypes["Task_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["Task_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["Task_stddev_samp_order_by"] | null,
	sum?:ValueTypes["Task_sum_order_by"] | null,
	var_pop?:ValueTypes["Task_var_pop_order_by"] | null,
	var_samp?:ValueTypes["Task_var_samp_order_by"] | null,
	variance?:ValueTypes["Task_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "Task" */
["Task_arr_rel_insert_input"]: {
	data:ValueTypes["Task_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["Task_on_conflict"] | null
};
	/** aggregate avg on columns */
["Task_avg_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by avg() on columns of table "Task" */
["Task_avg_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "Task". All fields are combined with a logical 'AND'. */
["Task_bool_exp"]: {
	Appointment?:ValueTypes["Appointment_bool_exp"] | null,
	Comments?:ValueTypes["TaskComment_bool_exp"] | null,
	Comments_aggregate?:ValueTypes["TaskComment_aggregate_bool_exp"] | null,
	CreatedBy?:ValueTypes["Person_bool_exp"] | null,
	Home?:ValueTypes["Home_bool_exp"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_bool_exp"] | null,
	LastUpdatedBy?:ValueTypes["Person_bool_exp"] | null,
	ServiceProviderRequests?:ValueTypes["ServiceProviderRequest_bool_exp"] | null,
	TaskAttachments?:ValueTypes["TaskAttachment_bool_exp"] | null,
	TaskParts?:ValueTypes["TaskPart_bool_exp"] | null,
	TaskUpdateLogs?:ValueTypes["TaskUpdateLog_bool_exp"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_bool_exp"] | null,
	_and?:ValueTypes["Task_bool_exp"][],
	_not?:ValueTypes["Task_bool_exp"] | null,
	_or?:ValueTypes["Task_bool_exp"][],
	appointmentId?:ValueTypes["uuid_comparison_exp"] | null,
	completedAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	createdById?:ValueTypes["uuid_comparison_exp"] | null,
	description?:ValueTypes["String_comparison_exp"] | null,
	dueAt?:ValueTypes["timestamptz_comparison_exp"] | null,
	estimatedCompletionTime?:ValueTypes["Int_comparison_exp"] | null,
	homeId?:ValueTypes["uuid_comparison_exp"] | null,
	homeInventoryItemId?:ValueTypes["uuid_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	lastUpdatedById?:ValueTypes["uuid_comparison_exp"] | null,
	notes?:ValueTypes["String_comparison_exp"] | null,
	parentTaskId?:ValueTypes["uuid_comparison_exp"] | null,
	partsRequired?:ValueTypes["Boolean_comparison_exp"] | null,
	priorityLevel?:ValueTypes["Int_comparison_exp"] | null,
	requiresOperationalReview?:ValueTypes["Boolean_comparison_exp"] | null,
	status?:ValueTypes["TaskStatus_enum_comparison_exp"] | null,
	thirdPartyRequired?:ValueTypes["Boolean_comparison_exp"] | null,
	title?:ValueTypes["String_comparison_exp"] | null,
	type?:ValueTypes["TaskType_enum_comparison_exp"] | null,
	updatedAt?:ValueTypes["timestamptz_comparison_exp"] | null
};
	/** unique or primary key constraints on table "Task" */
["Task_constraint"]:Task_constraint;
	/** input type for incrementing numeric columns in table "Task" */
["Task_inc_input"]: {
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number | null
};
	/** input type for inserting data into table "Task" */
["Task_insert_input"]: {
	Appointment?:ValueTypes["Appointment_obj_rel_insert_input"] | null,
	Comments?:ValueTypes["TaskComment_arr_rel_insert_input"] | null,
	CreatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_obj_rel_insert_input"] | null,
	LastUpdatedBy?:ValueTypes["Person_obj_rel_insert_input"] | null,
	ServiceProviderRequests?:ValueTypes["ServiceProviderRequest_arr_rel_insert_input"] | null,
	TaskAttachments?:ValueTypes["TaskAttachment_arr_rel_insert_input"] | null,
	TaskParts?:ValueTypes["TaskPart_arr_rel_insert_input"] | null,
	TaskUpdateLogs?:ValueTypes["TaskUpdateLog_arr_rel_insert_input"] | null,
	appointmentId?:ValueTypes["uuid"] | null,
	completedAt?:ValueTypes["timestamptz"] | null,
	description?:string | null,
	dueAt?:ValueTypes["timestamptz"] | null,
	homeId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	parentTaskId?:ValueTypes["uuid"] | null,
	requiresOperationalReview?:boolean | null,
	title?:string | null,
	type?:ValueTypes["TaskType_enum"] | null
};
	/** aggregate max on columns */
["Task_max_fields"]: AliasType<{
	appointmentId?:boolean,
	completedAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	description?:boolean,
	dueAt?:boolean,
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	homeId?:boolean,
	homeInventoryItemId?:boolean,
	id?:boolean,
	lastUpdatedById?:boolean,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:boolean,
	parentTaskId?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
	title?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "Task" */
["Task_max_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	completedAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	dueAt?:ValueTypes["order_by"] | null,
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastUpdatedById?:ValueTypes["order_by"] | null,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:ValueTypes["order_by"] | null,
	parentTaskId?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["Task_min_fields"]: AliasType<{
	appointmentId?:boolean,
	completedAt?:boolean,
	createdAt?:boolean,
	createdById?:boolean,
	description?:boolean,
	dueAt?:boolean,
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	homeId?:boolean,
	homeInventoryItemId?:boolean,
	id?:boolean,
	lastUpdatedById?:boolean,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:boolean,
	parentTaskId?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
	title?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "Task" */
["Task_min_order_by"]: {
	appointmentId?:ValueTypes["order_by"] | null,
	completedAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	dueAt?:ValueTypes["order_by"] | null,
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastUpdatedById?:ValueTypes["order_by"] | null,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:ValueTypes["order_by"] | null,
	parentTaskId?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "Task" */
["Task_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["Task"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "Task" */
["Task_obj_rel_insert_input"]: {
	data:ValueTypes["Task_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["Task_on_conflict"] | null
};
	/** on_conflict condition type for table "Task" */
["Task_on_conflict"]: {
	constraint:ValueTypes["Task_constraint"],
	update_columns:ValueTypes["Task_update_column"][],
	where?:ValueTypes["Task_bool_exp"] | null
};
	/** Ordering options when selecting data from "Task". */
["Task_order_by"]: {
	Appointment?:ValueTypes["Appointment_order_by"] | null,
	Comments_aggregate?:ValueTypes["TaskComment_aggregate_order_by"] | null,
	CreatedBy?:ValueTypes["Person_order_by"] | null,
	Home?:ValueTypes["Home_order_by"] | null,
	HomeInventoryItem?:ValueTypes["HomeInventoryItem_order_by"] | null,
	LastUpdatedBy?:ValueTypes["Person_order_by"] | null,
	ServiceProviderRequests_aggregate?:ValueTypes["ServiceProviderRequest_aggregate_order_by"] | null,
	TaskAttachments_aggregate?:ValueTypes["TaskAttachment_aggregate_order_by"] | null,
	TaskParts_aggregate?:ValueTypes["TaskPart_aggregate_order_by"] | null,
	TaskUpdateLogs_aggregate?:ValueTypes["TaskUpdateLog_aggregate_order_by"] | null,
	appointmentId?:ValueTypes["order_by"] | null,
	completedAt?:ValueTypes["order_by"] | null,
	createdAt?:ValueTypes["order_by"] | null,
	createdById?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	dueAt?:ValueTypes["order_by"] | null,
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	homeId?:ValueTypes["order_by"] | null,
	homeInventoryItemId?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lastUpdatedById?:ValueTypes["order_by"] | null,
	notes?:ValueTypes["order_by"] | null,
	parentTaskId?:ValueTypes["order_by"] | null,
	partsRequired?:ValueTypes["order_by"] | null,
	priorityLevel?:ValueTypes["order_by"] | null,
	requiresOperationalReview?:ValueTypes["order_by"] | null,
	status?:ValueTypes["order_by"] | null,
	thirdPartyRequired?:ValueTypes["order_by"] | null,
	title?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	updatedAt?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: Task */
["Task_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "Task" */
["Task_select_column"]:Task_select_column;
	/** select "Task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"]:Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "Task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"]:Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "Task" */
["Task_set_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	description?:string | null,
	dueAt?:ValueTypes["timestamptz"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:string | null,
	parentTaskId?:ValueTypes["uuid"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number | null,
	requiresOperationalReview?:boolean | null,
	status?:ValueTypes["TaskStatus_enum"] | null,
	title?:string | null
};
	/** aggregate stddev on columns */
["Task_stddev_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by stddev() on columns of table "Task" */
["Task_stddev_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_pop on columns */
["Task_stddev_pop_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_pop() on columns of table "Task" */
["Task_stddev_pop_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_samp on columns */
["Task_stddev_samp_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_samp() on columns of table "Task" */
["Task_stddev_samp_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** Streaming cursor of the table "Task" */
["Task_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["Task_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["Task_stream_cursor_value_input"]: {
	appointmentId?:ValueTypes["uuid"] | null,
	completedAt?:ValueTypes["timestamptz"] | null,
	createdAt?:ValueTypes["timestamptz"] | null,
	createdById?:ValueTypes["uuid"] | null,
	description?:string | null,
	dueAt?:ValueTypes["timestamptz"] | null,
	/** Value is number of minutes. */
	estimatedCompletionTime?:number | null,
	homeId?:ValueTypes["uuid"] | null,
	homeInventoryItemId?:ValueTypes["uuid"] | null,
	id?:ValueTypes["uuid"] | null,
	lastUpdatedById?:ValueTypes["uuid"] | null,
	/** Additional unstructured data about the Task. Internal use only */
	notes?:string | null,
	parentTaskId?:ValueTypes["uuid"] | null,
	partsRequired?:boolean | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number | null,
	requiresOperationalReview?:boolean | null,
	status?:ValueTypes["TaskStatus_enum"] | null,
	thirdPartyRequired?:boolean | null,
	title?:string | null,
	type?:ValueTypes["TaskType_enum"] | null,
	updatedAt?:ValueTypes["timestamptz"] | null
};
	/** aggregate sum on columns */
["Task_sum_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by sum() on columns of table "Task" */
["Task_sum_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** update columns of table "Task" */
["Task_update_column"]:Task_update_column;
	["Task_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["Task_inc_input"] | null,
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Task_set_input"] | null,
	/** filter the rows which have to be updated */
	where:ValueTypes["Task_bool_exp"]
};
	/** aggregate var_pop on columns */
["Task_var_pop_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by var_pop() on columns of table "Task" */
["Task_var_pop_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** aggregate var_samp on columns */
["Task_var_samp_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by var_samp() on columns of table "Task" */
["Task_var_samp_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** aggregate variance on columns */
["Task_variance_fields"]: AliasType<{
	/** Value is number of minutes. */
	estimatedCompletionTime?:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:boolean,
		__typename?: boolean
}>;
	/** order by variance() on columns of table "Task" */
["Task_variance_order_by"]: {
	/** Value is number of minutes. */
	estimatedCompletionTime?:ValueTypes["order_by"] | null,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "TimeZone" */
["TimeZone"]: AliasType<{
	abbrev?:boolean,
	is_dst?:boolean,
	name?:boolean,
	utc_offset?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "TimeZone". All fields are combined with a logical 'AND'. */
["TimeZone_bool_exp"]: {
	_and?:ValueTypes["TimeZone_bool_exp"][],
	_not?:ValueTypes["TimeZone_bool_exp"] | null,
	_or?:ValueTypes["TimeZone_bool_exp"][],
	abbrev?:ValueTypes["String_comparison_exp"] | null,
	is_dst?:ValueTypes["Boolean_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	utc_offset?:ValueTypes["interval_comparison_exp"] | null
};
	/** Ordering options when selecting data from "TimeZone". */
["TimeZone_order_by"]: {
	abbrev?:ValueTypes["order_by"] | null,
	is_dst?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	utc_offset?:ValueTypes["order_by"] | null
};
	/** select columns of table "TimeZone" */
["TimeZone_select_column"]:TimeZone_select_column;
	/** Streaming cursor of the table "TimeZone" */
["TimeZone_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["TimeZone_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["TimeZone_stream_cursor_value_input"]: {
	abbrev?:string | null,
	is_dst?:boolean | null,
	name?:string | null,
	utc_offset?:ValueTypes["interval"] | null
};
	["create_Stripe_SetupIntent_output"]: AliasType<{
	ephemeralKey?:boolean,
	publishableKey?:boolean,
	setupIntentClientSecret?:boolean,
		__typename?: boolean
}>;
	["create_Stripe_SubscriptionIntent_input"]: {
	couponId?:string | null,
	priceId:string
};
	["create_Stripe_SubscriptionIntent_output"]: AliasType<{
	ephemeralKey?:boolean,
	paymentIntentClientSecret?:boolean,
	publishableKey?:boolean,
		__typename?: boolean
}>;
	/** ordering argument of a cursor */
["cursor_ordering"]:cursor_ordering;
	["date"]:unknown;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
	_eq?:ValueTypes["date"] | null,
	_gt?:ValueTypes["date"] | null,
	_gte?:ValueTypes["date"] | null,
	_in?:ValueTypes["date"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["date"] | null,
	_lte?:ValueTypes["date"] | null,
	_neq?:ValueTypes["date"] | null,
	_nin?:ValueTypes["date"][]
};
	["find_Person_by_phone_args"]: {
	number?:string | null
};
	/** columns and relationships of "find_Person_response" */
["find_Person_response"]: AliasType<{
	present?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
["find_Person_response_bool_exp"]: {
	_and?:ValueTypes["find_Person_response_bool_exp"][],
	_not?:ValueTypes["find_Person_response_bool_exp"] | null,
	_or?:ValueTypes["find_Person_response_bool_exp"][],
	present?:ValueTypes["Boolean_comparison_exp"] | null
};
	/** Ordering options when selecting data from "find_Person_response". */
["find_Person_response_order_by"]: {
	present?:ValueTypes["order_by"] | null
};
	/** select columns of table "find_Person_response" */
["find_Person_response_select_column"]:find_Person_response_select_column;
	/** Streaming cursor of the table "find_Person_response" */
["find_Person_response_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["find_Person_response_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"] | null
};
	/** Initial value of the column from where the streaming should start */
["find_Person_response_stream_cursor_value_input"]: {
	present?:boolean | null
};
	["geography"]:unknown;
	["geography_cast_exp"]: {
	geometry?:ValueTypes["geometry_comparison_exp"] | null
};
	/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
["geography_comparison_exp"]: {
	_cast?:ValueTypes["geography_cast_exp"] | null,
	_eq?:ValueTypes["geography"] | null,
	_gt?:ValueTypes["geography"] | null,
	_gte?:ValueTypes["geography"] | null,
	_in?:ValueTypes["geography"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["geography"] | null,
	_lte?:ValueTypes["geography"] | null,
	_neq?:ValueTypes["geography"] | null,
	_nin?:ValueTypes["geography"][],
	/** is the column within a given distance from the given geography value */
	_st_d_within?:ValueTypes["st_d_within_geography_input"] | null,
	/** does the column spatially intersect the given geography value */
	_st_intersects?:ValueTypes["geography"] | null
};
	["geometry"]:unknown;
	["geometry_cast_exp"]: {
	geography?:ValueTypes["geography_comparison_exp"] | null
};
	/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
["geometry_comparison_exp"]: {
	_cast?:ValueTypes["geometry_cast_exp"] | null,
	_eq?:ValueTypes["geometry"] | null,
	_gt?:ValueTypes["geometry"] | null,
	_gte?:ValueTypes["geometry"] | null,
	_in?:ValueTypes["geometry"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["geometry"] | null,
	_lte?:ValueTypes["geometry"] | null,
	_neq?:ValueTypes["geometry"] | null,
	_nin?:ValueTypes["geometry"][],
	/** is the column within a given 3D distance from the given geometry value */
	_st_3d_d_within?:ValueTypes["st_d_within_input"] | null,
	/** does the column spatially intersect the given geometry value in 3D */
	_st_3d_intersects?:ValueTypes["geometry"] | null,
	/** does the column contain the given geometry value */
	_st_contains?:ValueTypes["geometry"] | null,
	/** does the column cross the given geometry value */
	_st_crosses?:ValueTypes["geometry"] | null,
	/** is the column within a given distance from the given geometry value */
	_st_d_within?:ValueTypes["st_d_within_input"] | null,
	/** is the column equal to given geometry value (directionality is ignored) */
	_st_equals?:ValueTypes["geometry"] | null,
	/** does the column spatially intersect the given geometry value */
	_st_intersects?:ValueTypes["geometry"] | null,
	/** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
	_st_overlaps?:ValueTypes["geometry"] | null,
	/** does the column have atleast one point in common with the given geometry value */
	_st_touches?:ValueTypes["geometry"] | null,
	/** is the column contained in the given geometry value */
	_st_within?:ValueTypes["geometry"] | null
};
	["interval"]:unknown;
	/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
["interval_comparison_exp"]: {
	_eq?:ValueTypes["interval"] | null,
	_gt?:ValueTypes["interval"] | null,
	_gte?:ValueTypes["interval"] | null,
	_in?:ValueTypes["interval"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["interval"] | null,
	_lte?:ValueTypes["interval"] | null,
	_neq?:ValueTypes["interval"] | null,
	_nin?:ValueTypes["interval"][]
};
	["jsonb"]:unknown;
	["jsonb_cast_exp"]: {
	String?:ValueTypes["String_comparison_exp"] | null
};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
	_cast?:ValueTypes["jsonb_cast_exp"] | null,
	/** is the column contained in the given json value */
	_contained_in?:ValueTypes["jsonb"] | null,
	/** does the column contain the given json value at the top level */
	_contains?:ValueTypes["jsonb"] | null,
	_eq?:ValueTypes["jsonb"] | null,
	_gt?:ValueTypes["jsonb"] | null,
	_gte?:ValueTypes["jsonb"] | null,
	/** does the string exist as a top-level key in the column */
	_has_key?:string | null,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?:string[],
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?:string[],
	_in?:ValueTypes["jsonb"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["jsonb"] | null,
	_lte?:ValueTypes["jsonb"] | null,
	_neq?:ValueTypes["jsonb"] | null,
	_nin?:ValueTypes["jsonb"][]
};
	/** mutation root */
["mutation_root"]: AliasType<{
	create_Stripe_Portal?:ValueTypes["Stripe_Portal"],
	/** create_Stripe_SetupIntent */
	create_Stripe_SetupIntent?:ValueTypes["create_Stripe_SetupIntent_output"],
create_Stripe_SubscriptionIntent?: [{	object:ValueTypes["create_Stripe_SubscriptionIntent_input"]},ValueTypes["create_Stripe_SubscriptionIntent_output"]],
delete_Appointment?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["Appointment_bool_exp"]},ValueTypes["Appointment_mutation_response"]],
delete_AppointmentProvider?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["AppointmentProvider_bool_exp"]},ValueTypes["AppointmentProvider_mutation_response"]],
delete_AppointmentProvider_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentProvider"]],
delete_Appointment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Appointment"]],
delete_Attachment?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["Attachment_bool_exp"]},ValueTypes["Attachment_mutation_response"]],
delete_Attachment_by_pk?: [{	/** Not auto generated, must be provided */
	id:ValueTypes["uuid"]},ValueTypes["Attachment"]],
delete_HomeInventoryItemAttachment?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["HomeInventoryItemAttachment_bool_exp"]},ValueTypes["HomeInventoryItemAttachment_mutation_response"]],
delete_HomeInventoryItemAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemAttachment"]],
delete_Task?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["Task_bool_exp"]},ValueTypes["Task_mutation_response"]],
delete_TaskAttachment?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["TaskAttachment_bool_exp"]},ValueTypes["TaskAttachment_mutation_response"]],
delete_TaskAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskAttachment"]],
delete_Task_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Task"]],
encryptDataHomeowner?: [{	data:string},ValueTypes["EncryptedMessage"]],
insert_Appointment?: [{	/** the rows to be inserted */
	objects:ValueTypes["Appointment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Appointment_on_conflict"] | null},ValueTypes["Appointment_mutation_response"]],
insert_AppointmentGrade?: [{	/** the rows to be inserted */
	objects:ValueTypes["AppointmentGrade_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGrade_on_conflict"] | null},ValueTypes["AppointmentGrade_mutation_response"]],
insert_AppointmentGradeSelection?: [{	/** the rows to be inserted */
	objects:ValueTypes["AppointmentGradeSelection_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGradeSelection_on_conflict"] | null},ValueTypes["AppointmentGradeSelection_mutation_response"]],
insert_AppointmentGradeSelection_one?: [{	/** the row to be inserted */
	object:ValueTypes["AppointmentGradeSelection_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGradeSelection_on_conflict"] | null},ValueTypes["AppointmentGradeSelection"]],
insert_AppointmentGrade_one?: [{	/** the row to be inserted */
	object:ValueTypes["AppointmentGrade_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentGrade_on_conflict"] | null},ValueTypes["AppointmentGrade"]],
insert_AppointmentProvider?: [{	/** the rows to be inserted */
	objects:ValueTypes["AppointmentProvider_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentProvider_on_conflict"] | null},ValueTypes["AppointmentProvider_mutation_response"]],
insert_AppointmentProvider_one?: [{	/** the row to be inserted */
	object:ValueTypes["AppointmentProvider_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["AppointmentProvider_on_conflict"] | null},ValueTypes["AppointmentProvider"]],
insert_Appointment_one?: [{	/** the row to be inserted */
	object:ValueTypes["Appointment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Appointment_on_conflict"] | null},ValueTypes["Appointment"]],
insert_Attachment?: [{	/** the rows to be inserted */
	objects:ValueTypes["Attachment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Attachment_on_conflict"] | null},ValueTypes["Attachment_mutation_response"]],
insert_Attachment_one?: [{	/** the row to be inserted */
	object:ValueTypes["Attachment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Attachment_on_conflict"] | null},ValueTypes["Attachment"]],
insert_AvailabilityResult?: [{	/** the rows to be inserted */
	objects:ValueTypes["AvailabilityResult_insert_input"][]},ValueTypes["AvailabilityResult_mutation_response"]],
insert_AvailabilityResult_one?: [{	/** the row to be inserted */
	object:ValueTypes["AvailabilityResult_insert_input"]},ValueTypes["AvailabilityResult"]],
insert_DataDeletionRequest?: [{	/** the rows to be inserted */
	objects:ValueTypes["DataDeletionRequest_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["DataDeletionRequest_on_conflict"] | null},ValueTypes["DataDeletionRequest_mutation_response"]],
insert_DataDeletionRequest_one?: [{	/** the row to be inserted */
	object:ValueTypes["DataDeletionRequest_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["DataDeletionRequest_on_conflict"] | null},ValueTypes["DataDeletionRequest"]],
insert_Device?: [{	/** the rows to be inserted */
	objects:ValueTypes["Device_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Device_on_conflict"] | null},ValueTypes["Device_mutation_response"]],
insert_Device_one?: [{	/** the row to be inserted */
	object:ValueTypes["Device_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Device_on_conflict"] | null},ValueTypes["Device"]],
insert_HomeInventoryItem?: [{	/** the rows to be inserted */
	objects:ValueTypes["HomeInventoryItem_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItem_on_conflict"] | null},ValueTypes["HomeInventoryItem_mutation_response"]],
insert_HomeInventoryItemAttachment?: [{	/** the rows to be inserted */
	objects:ValueTypes["HomeInventoryItemAttachment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItemAttachment_on_conflict"] | null},ValueTypes["HomeInventoryItemAttachment_mutation_response"]],
insert_HomeInventoryItemAttachment_one?: [{	/** the row to be inserted */
	object:ValueTypes["HomeInventoryItemAttachment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItemAttachment_on_conflict"] | null},ValueTypes["HomeInventoryItemAttachment"]],
insert_HomeInventoryItem_one?: [{	/** the row to be inserted */
	object:ValueTypes["HomeInventoryItem_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["HomeInventoryItem_on_conflict"] | null},ValueTypes["HomeInventoryItem"]],
insert_HomeWaitlist?: [{	/** the rows to be inserted */
	objects:ValueTypes["HomeWaitlist_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["HomeWaitlist_on_conflict"] | null},ValueTypes["HomeWaitlist_mutation_response"]],
insert_HomeWaitlist_one?: [{	/** the row to be inserted */
	object:ValueTypes["HomeWaitlist_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["HomeWaitlist_on_conflict"] | null},ValueTypes["HomeWaitlist"]],
insert_Person?: [{	/** the rows to be inserted */
	objects:ValueTypes["Person_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Person_on_conflict"] | null},ValueTypes["Person_mutation_response"]],
insert_PersonBiographyItem?: [{	/** the rows to be inserted */
	objects:ValueTypes["PersonBiographyItem_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["PersonBiographyItem_on_conflict"] | null},ValueTypes["PersonBiographyItem_mutation_response"]],
insert_PersonBiographyItem_one?: [{	/** the row to be inserted */
	object:ValueTypes["PersonBiographyItem_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["PersonBiographyItem_on_conflict"] | null},ValueTypes["PersonBiographyItem"]],
insert_PersonHome?: [{	/** the rows to be inserted */
	objects:ValueTypes["PersonHome_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["PersonHome_on_conflict"] | null},ValueTypes["PersonHome_mutation_response"]],
insert_PersonHome_one?: [{	/** the row to be inserted */
	object:ValueTypes["PersonHome_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["PersonHome_on_conflict"] | null},ValueTypes["PersonHome"]],
insert_Person_one?: [{	/** the row to be inserted */
	object:ValueTypes["Person_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Person_on_conflict"] | null},ValueTypes["Person"]],
insert_Referral?: [{	/** the rows to be inserted */
	objects:ValueTypes["Referral_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Referral_on_conflict"] | null},ValueTypes["Referral_mutation_response"]],
insert_Referral_one?: [{	/** the row to be inserted */
	object:ValueTypes["Referral_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Referral_on_conflict"] | null},ValueTypes["Referral"]],
insert_ServiceOfferResponse?: [{	/** the rows to be inserted */
	objects:ValueTypes["ServiceOfferResponse_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["ServiceOfferResponse_on_conflict"] | null},ValueTypes["ServiceOfferResponse_mutation_response"]],
insert_ServiceOfferResponse_one?: [{	/** the row to be inserted */
	object:ValueTypes["ServiceOfferResponse_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["ServiceOfferResponse_on_conflict"] | null},ValueTypes["ServiceOfferResponse"]],
insert_ServiceProviderRequest?: [{	/** the rows to be inserted */
	objects:ValueTypes["ServiceProviderRequest_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["ServiceProviderRequest_on_conflict"] | null},ValueTypes["ServiceProviderRequest_mutation_response"]],
insert_ServiceProviderRequest_one?: [{	/** the row to be inserted */
	object:ValueTypes["ServiceProviderRequest_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["ServiceProviderRequest_on_conflict"] | null},ValueTypes["ServiceProviderRequest"]],
insert_Task?: [{	/** the rows to be inserted */
	objects:ValueTypes["Task_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["Task_on_conflict"] | null},ValueTypes["Task_mutation_response"]],
insert_TaskAttachment?: [{	/** the rows to be inserted */
	objects:ValueTypes["TaskAttachment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["TaskAttachment_on_conflict"] | null},ValueTypes["TaskAttachment_mutation_response"]],
insert_TaskAttachment_one?: [{	/** the row to be inserted */
	object:ValueTypes["TaskAttachment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["TaskAttachment_on_conflict"] | null},ValueTypes["TaskAttachment"]],
insert_TaskComment?: [{	/** the rows to be inserted */
	objects:ValueTypes["TaskComment_insert_input"][]},ValueTypes["TaskComment_mutation_response"]],
insert_TaskComment_one?: [{	/** the row to be inserted */
	object:ValueTypes["TaskComment_insert_input"]},ValueTypes["TaskComment"]],
insert_TaskPart?: [{	/** the rows to be inserted */
	objects:ValueTypes["TaskPart_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["TaskPart_on_conflict"] | null},ValueTypes["TaskPart_mutation_response"]],
insert_TaskPart_one?: [{	/** the row to be inserted */
	object:ValueTypes["TaskPart_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["TaskPart_on_conflict"] | null},ValueTypes["TaskPart"]],
insert_TaskUpdateLog?: [{	/** the rows to be inserted */
	objects:ValueTypes["TaskUpdateLog_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLog_on_conflict"] | null},ValueTypes["TaskUpdateLog_mutation_response"]],
insert_TaskUpdateLogAttachment?: [{	/** the rows to be inserted */
	objects:ValueTypes["TaskUpdateLogAttachment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLogAttachment_on_conflict"] | null},ValueTypes["TaskUpdateLogAttachment_mutation_response"]],
insert_TaskUpdateLogAttachment_one?: [{	/** the row to be inserted */
	object:ValueTypes["TaskUpdateLogAttachment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLogAttachment_on_conflict"] | null},ValueTypes["TaskUpdateLogAttachment"]],
insert_TaskUpdateLog_one?: [{	/** the row to be inserted */
	object:ValueTypes["TaskUpdateLog_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["TaskUpdateLog_on_conflict"] | null},ValueTypes["TaskUpdateLog"]],
insert_Task_one?: [{	/** the row to be inserted */
	object:ValueTypes["Task_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["Task_on_conflict"] | null},ValueTypes["Task"]],
update_Appointment?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Appointment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Appointment_bool_exp"]},ValueTypes["Appointment_mutation_response"]],
update_Appointment_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Appointment_set_input"] | null,	pk_columns:ValueTypes["Appointment_pk_columns_input"]},ValueTypes["Appointment"]],
update_Appointment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Appointment_updates"][]},ValueTypes["Appointment_mutation_response"]],
update_Attachment?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Attachment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Attachment_bool_exp"]},ValueTypes["Attachment_mutation_response"]],
update_Attachment_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Attachment_set_input"] | null,	pk_columns:ValueTypes["Attachment_pk_columns_input"]},ValueTypes["Attachment"]],
update_Attachment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Attachment_updates"][]},ValueTypes["Attachment_mutation_response"]],
update_AvailabilityResult?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["AvailabilityResult_append_input"] | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["AvailabilityResult_delete_at_path_input"] | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["AvailabilityResult_delete_elem_input"] | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["AvailabilityResult_delete_key_input"] | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["AvailabilityResult_prepend_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["AvailabilityResult_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["AvailabilityResult_bool_exp"]},ValueTypes["AvailabilityResult_mutation_response"]],
update_AvailabilityResult_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["AvailabilityResult_updates"][]},ValueTypes["AvailabilityResult_mutation_response"]],
update_Device?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Device_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Device_bool_exp"]},ValueTypes["Device_mutation_response"]],
update_Device_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Device_set_input"] | null,	pk_columns:ValueTypes["Device_pk_columns_input"]},ValueTypes["Device"]],
update_Device_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Device_updates"][]},ValueTypes["Device_mutation_response"]],
update_Home?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Home_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Home_bool_exp"]},ValueTypes["Home_mutation_response"]],
update_HomeInventoryItem?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItem_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItem_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["HomeInventoryItem_bool_exp"]},ValueTypes["HomeInventoryItem_mutation_response"]],
update_HomeInventoryItemAttachment?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItemAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItemAttachment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["HomeInventoryItemAttachment_bool_exp"]},ValueTypes["HomeInventoryItemAttachment_mutation_response"]],
update_HomeInventoryItemAttachment_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItemAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItemAttachment_set_input"] | null,	pk_columns:ValueTypes["HomeInventoryItemAttachment_pk_columns_input"]},ValueTypes["HomeInventoryItemAttachment"]],
update_HomeInventoryItemAttachment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["HomeInventoryItemAttachment_updates"][]},ValueTypes["HomeInventoryItemAttachment_mutation_response"]],
update_HomeInventoryItem_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["HomeInventoryItem_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["HomeInventoryItem_set_input"] | null,	pk_columns:ValueTypes["HomeInventoryItem_pk_columns_input"]},ValueTypes["HomeInventoryItem"]],
update_HomeInventoryItem_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["HomeInventoryItem_updates"][]},ValueTypes["HomeInventoryItem_mutation_response"]],
update_Home_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Home_set_input"] | null,	pk_columns:ValueTypes["Home_pk_columns_input"]},ValueTypes["Home"]],
update_Home_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Home_updates"][]},ValueTypes["Home_mutation_response"]],
update_MarketingLead?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["MarketingLead_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["MarketingLead_bool_exp"]},ValueTypes["MarketingLead_mutation_response"]],
update_MarketingLead_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["MarketingLead_set_input"] | null,	pk_columns:ValueTypes["MarketingLead_pk_columns_input"]},ValueTypes["MarketingLead"]],
update_MarketingLead_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["MarketingLead_updates"][]},ValueTypes["MarketingLead_mutation_response"]],
update_Person?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Person_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Person_bool_exp"]},ValueTypes["Person_mutation_response"]],
update_PersonBiographyItem?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["PersonBiographyItem_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["PersonBiographyItem_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["PersonBiographyItem_bool_exp"]},ValueTypes["PersonBiographyItem_mutation_response"]],
update_PersonBiographyItem_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["PersonBiographyItem_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["PersonBiographyItem_set_input"] | null,	pk_columns:ValueTypes["PersonBiographyItem_pk_columns_input"]},ValueTypes["PersonBiographyItem"]],
update_PersonBiographyItem_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["PersonBiographyItem_updates"][]},ValueTypes["PersonBiographyItem_mutation_response"]],
update_Person_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Person_set_input"] | null,	pk_columns:ValueTypes["Person_pk_columns_input"]},ValueTypes["Person"]],
update_Person_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Person_updates"][]},ValueTypes["Person_mutation_response"]],
update_ServiceOfferResponse?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["ServiceOfferResponse_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["ServiceOfferResponse_bool_exp"]},ValueTypes["ServiceOfferResponse_mutation_response"]],
update_ServiceOfferResponse_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["ServiceOfferResponse_set_input"] | null,	pk_columns:ValueTypes["ServiceOfferResponse_pk_columns_input"]},ValueTypes["ServiceOfferResponse"]],
update_ServiceOfferResponse_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["ServiceOfferResponse_updates"][]},ValueTypes["ServiceOfferResponse_mutation_response"]],
update_Task?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["Task_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Task_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["Task_bool_exp"]},ValueTypes["Task_mutation_response"]],
update_TaskAttachment?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskAttachment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["TaskAttachment_bool_exp"]},ValueTypes["TaskAttachment_mutation_response"]],
update_TaskAttachment_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskAttachment_set_input"] | null,	pk_columns:ValueTypes["TaskAttachment_pk_columns_input"]},ValueTypes["TaskAttachment"]],
update_TaskAttachment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["TaskAttachment_updates"][]},ValueTypes["TaskAttachment_mutation_response"]],
update_TaskComment?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskComment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["TaskComment_bool_exp"]},ValueTypes["TaskComment_mutation_response"]],
update_TaskComment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["TaskComment_updates"][]},ValueTypes["TaskComment_mutation_response"]],
update_TaskPart?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskPart_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["TaskPart_bool_exp"]},ValueTypes["TaskPart_mutation_response"]],
update_TaskPart_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskPart_set_input"] | null,	pk_columns:ValueTypes["TaskPart_pk_columns_input"]},ValueTypes["TaskPart"]],
update_TaskPart_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["TaskPart_updates"][]},ValueTypes["TaskPart_mutation_response"]],
update_TaskUpdateLog?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLog_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["TaskUpdateLog_bool_exp"]},ValueTypes["TaskUpdateLog_mutation_response"]],
update_TaskUpdateLogAttachment?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskUpdateLogAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLogAttachment_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["TaskUpdateLogAttachment_bool_exp"]},ValueTypes["TaskUpdateLogAttachment_mutation_response"]],
update_TaskUpdateLogAttachment_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["TaskUpdateLogAttachment_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLogAttachment_set_input"] | null,	pk_columns:ValueTypes["TaskUpdateLogAttachment_pk_columns_input"]},ValueTypes["TaskUpdateLogAttachment"]],
update_TaskUpdateLogAttachment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["TaskUpdateLogAttachment_updates"][]},ValueTypes["TaskUpdateLogAttachment_mutation_response"]],
update_TaskUpdateLog_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["TaskUpdateLog_set_input"] | null,	pk_columns:ValueTypes["TaskUpdateLog_pk_columns_input"]},ValueTypes["TaskUpdateLog"]],
update_TaskUpdateLog_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["TaskUpdateLog_updates"][]},ValueTypes["TaskUpdateLog_mutation_response"]],
update_Task_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["Task_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["Task_set_input"] | null,	pk_columns:ValueTypes["Task_pk_columns_input"]},ValueTypes["Task"]],
update_Task_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["Task_updates"][]},ValueTypes["Task_mutation_response"]],
		__typename?: boolean
}>;
	/** column ordering options */
["order_by"]:order_by;
	["query_root"]: AliasType<{
Appointment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment"]],
AppointmentGrade?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGrade_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGrade_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
AppointmentGradeSelection?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGradeSelection_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGradeSelection_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null},ValueTypes["AppointmentGradeSelection"]],
AppointmentGradeSelectionCategory?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGradeSelectionCategory_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGradeSelectionCategory_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"] | null},ValueTypes["AppointmentGradeSelectionCategory"]],
AppointmentGradeSelectionCategory_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGradeSelectionCategory"]],
AppointmentGradeSelection_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGradeSelection"]],
AppointmentGrade_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGrade"]],
AppointmentProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider"]],
AppointmentProvider_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider_aggregate"]],
AppointmentProvider_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentProvider"]],
AppointmentReport?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentReport_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentReport_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentReport_bool_exp"] | null},ValueTypes["AppointmentReport"]],
AppointmentReport_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentReport"]],
Appointment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment_aggregate"]],
Appointment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Appointment"]],
Attachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Attachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Attachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Attachment_bool_exp"] | null},ValueTypes["Attachment"]],
Attachment_by_pk?: [{	/** Not auto generated, must be provided */
	id:ValueTypes["uuid"]},ValueTypes["Attachment"]],
Availability?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Availability_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Availability_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Availability_bool_exp"] | null},ValueTypes["Availability"]],
AvailabilityResult?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AvailabilityResult_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AvailabilityResult_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AvailabilityResult_bool_exp"] | null},ValueTypes["AvailabilityResult"]],
AvailableAppointment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AvailableAppointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AvailableAppointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AvailableAppointment_bool_exp"] | null},ValueTypes["AvailableAppointment"]],
ClientRelease?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ClientRelease_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ClientRelease_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ClientRelease_bool_exp"] | null},ValueTypes["ClientRelease"]],
ClientRelease_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ClientRelease"]],
ClientType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ClientType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ClientType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ClientType_bool_exp"] | null},ValueTypes["ClientType"]],
ClientType_by_pk?: [{	value:string},ValueTypes["ClientType"]],
ContentBlock?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ContentBlock_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ContentBlock_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ContentBlock_bool_exp"] | null},ValueTypes["ContentBlock"]],
ContentBlock_by_pk?: [{	key:string},ValueTypes["ContentBlock"]],
DataDeletionRequest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["DataDeletionRequest_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["DataDeletionRequest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["DataDeletionRequest_bool_exp"] | null},ValueTypes["DataDeletionRequest"]],
DataDeletionRequest_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["DataDeletionRequest"]],
Device?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device"]],
Device_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device_aggregate"]],
Device_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Device"]],
ExternalIdentifier?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ExternalIdentifier_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ExternalIdentifier_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ExternalIdentifier_bool_exp"] | null},ValueTypes["ExternalIdentifier"]],
ExternalIdentifier_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ExternalIdentifier"]],
GeographicRegion?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegion_bool_exp"] | null},ValueTypes["GeographicRegion"]],
GeographicRegionHandyman?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionHandyman_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionHandyman_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionHandyman_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionNeighborhood?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionNeighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionNeighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null},ValueTypes["GeographicRegionNeighborhood"]],
GeographicRegionNeighborhood_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegionNeighborhood"]],
GeographicRegion_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegion"]],
GeographicRegion_by_zipCode?: [{	/** input parameters for function "GeographicRegion_by_zipCode" */
	args:ValueTypes["GeographicRegion_by_zipCode_args"],	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegion_bool_exp"] | null},ValueTypes["GeographicRegion"]],
Home?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Home_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Home_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Home_bool_exp"] | null},ValueTypes["Home"]],
HomeInventoryItem?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null},ValueTypes["HomeInventoryItem"]],
HomeInventoryItemAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
HomeInventoryItemAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemAttachment"]],
HomeInventoryItemCategory?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategory_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategory_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null},ValueTypes["HomeInventoryItemCategory"]],
HomeInventoryItemCategoryIcon?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryIcon_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryIcon_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryIcon_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryPrompt?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryPrompt_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryPrompt_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItemCategoryPrompt_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItemCategory_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategory"]],
HomeInventoryItem_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItem"]],
HomeInventoryMaintenanceSchedule?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryMaintenanceSchedule_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryMaintenanceSchedule_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"] | null},ValueTypes["HomeInventoryMaintenanceSchedule"]],
HomeInventoryServiceProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryServiceProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryServiceProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceProvider_bool_exp"] | null},ValueTypes["HomeInventoryServiceProvider"]],
HomeInventoryServiceProvider_by_pk?: [{	value:string},ValueTypes["HomeInventoryServiceProvider"]],
HomeInventoryServiceType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryServiceType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryServiceType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceType_bool_exp"] | null},ValueTypes["HomeInventoryServiceType"]],
HomeInventoryServiceType_by_pk?: [{	value:string},ValueTypes["HomeInventoryServiceType"]],
HomeWaitlist?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeWaitlist_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeWaitlist_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeWaitlist_bool_exp"] | null},ValueTypes["HomeWaitlist"]],
HomeWaitlist_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeWaitlist"]],
Home_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Home"]],
MarketingLead?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["MarketingLead_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["MarketingLead_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["MarketingLead_bool_exp"] | null},ValueTypes["MarketingLead"]],
MarketingLead_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["MarketingLead"]],
Neighborhood?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Neighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Neighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Neighborhood_bool_exp"] | null},ValueTypes["Neighborhood"]],
NeighborhoodRegion?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["NeighborhoodRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["NeighborhoodRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["NeighborhoodRegion_bool_exp"] | null},ValueTypes["NeighborhoodRegion"]],
NeighborhoodRegion_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["NeighborhoodRegion"]],
Neighborhood_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Neighborhood"]],
Person?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person"]],
PersonBiographyItem?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonBiographyItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonBiographyItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonBiographyItem_bool_exp"] | null},ValueTypes["PersonBiographyItem"]],
PersonBiographyItem_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["PersonBiographyItem"]],
PersonHome?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonHome_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonHome_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonHome_bool_exp"] | null},ValueTypes["PersonHome"]],
Person_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person_aggregate"]],
Person_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Person"]],
Referral?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Referral_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Referral_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Referral_bool_exp"] | null},ValueTypes["Referral"]],
ReferralProgram?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ReferralProgram_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ReferralProgram_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ReferralProgram_bool_exp"] | null},ValueTypes["ReferralProgram"]],
ReferralProgram_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ReferralProgram"]],
Referral_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Referral"]],
Region?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Region_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Region_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Region_bool_exp"] | null},ValueTypes["Region"]],
Region_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Region"]],
ServiceOffer?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOffer_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOffer_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOffer_bool_exp"] | null},ValueTypes["ServiceOffer"]],
ServiceOfferResponse?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponse_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponse_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
ServiceOfferResponseStatus?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponseStatus_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponseStatus_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponseStatus_bool_exp"] | null},ValueTypes["ServiceOfferResponseStatus"]],
ServiceOfferResponseStatus_by_pk?: [{	value:string},ValueTypes["ServiceOfferResponseStatus"]],
ServiceOfferResponse_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceOfferResponse"]],
ServiceOfferType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferType_bool_exp"] | null},ValueTypes["ServiceOfferType"]],
ServiceOfferType_by_pk?: [{	value:string},ValueTypes["ServiceOfferType"]],
ServiceOffer_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceOffer"]],
ServiceProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProvider_bool_exp"] | null},ValueTypes["ServiceProvider"]],
ServiceProviderRequest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProviderRequest_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProviderRequest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequest_bool_exp"] | null},ValueTypes["ServiceProviderRequest"]],
ServiceProviderRequestStage?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProviderRequestStage_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProviderRequestStage_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequestStage_bool_exp"] | null},ValueTypes["ServiceProviderRequestStage"]],
ServiceProviderRequestStage_by_pk?: [{	value:string},ValueTypes["ServiceProviderRequestStage"]],
ServiceProviderRequest_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceProviderRequest"]],
ServiceProvider_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceProvider"]],
StripePaymentMethod?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripePaymentMethod_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripePaymentMethod_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripePaymentMethod_bool_exp"] | null},ValueTypes["StripePaymentMethod"]],
StripePaymentMethod_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["StripePaymentMethod"]],
StripeSubscription?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripeSubscription_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripeSubscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripeSubscription_bool_exp"] | null},ValueTypes["StripeSubscription"]],
StripeSubscription_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["StripeSubscription"]],
Task?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
TaskAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskAttachment_bool_exp"] | null},ValueTypes["TaskAttachment"]],
TaskAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskAttachment"]],
TaskComment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment"]],
TaskComment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment_aggregate"]],
TaskPart?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPart_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPart_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPart_bool_exp"] | null},ValueTypes["TaskPart"]],
TaskPartPartyResponsible?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPartPartyResponsible_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPartPartyResponsible_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartPartyResponsible_bool_exp"] | null},ValueTypes["TaskPartPartyResponsible"]],
TaskPartPartyResponsible_by_pk?: [{	value:string},ValueTypes["TaskPartPartyResponsible"]],
TaskPartStatus?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPartStatus_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPartStatus_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartStatus_bool_exp"] | null},ValueTypes["TaskPartStatus"]],
TaskPartStatus_by_pk?: [{	value:string},ValueTypes["TaskPartStatus"]],
TaskPart_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskPart"]],
TaskUpdateLog?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
TaskUpdateLogAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLogAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLogAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null},ValueTypes["TaskUpdateLogAttachment"]],
TaskUpdateLogAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskUpdateLogAttachment"]],
TaskUpdateLog_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog_aggregate"]],
TaskUpdateLog_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskUpdateLog"]],
Task_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task_aggregate"]],
Task_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Task"]],
TimeZone?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TimeZone_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TimeZone_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TimeZone_bool_exp"] | null},ValueTypes["TimeZone"]],
check_Stripe_PromoCode?: [{	code:string},ValueTypes["Stripe_FindPromoCodeResult"]],
decryptMessageHomeowner?: [{	message:string},ValueTypes["DecryptedMessage"]],
find_Person_by_phone?: [{	/** input parameters for function "find_Person_by_phone" */
	args:ValueTypes["find_Person_by_phone_args"],	/** distinct select on columns */
	distinct_on?:ValueTypes["find_Person_response_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["find_Person_response_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["find_Person_response_bool_exp"] | null},ValueTypes["find_Person_response"]],
find_Person_response?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["find_Person_response_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["find_Person_response_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["find_Person_response_bool_exp"] | null},ValueTypes["find_Person_response"]],
	/** find_Stripe_SubscriptionProducts */
	find_Stripe_SubscriptionProducts?:ValueTypes["Stripe_SubscriptionProduct"],
		__typename?: boolean
}>;
	["smallint"]:unknown;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: {
	_eq?:ValueTypes["smallint"] | null,
	_gt?:ValueTypes["smallint"] | null,
	_gte?:ValueTypes["smallint"] | null,
	_in?:ValueTypes["smallint"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["smallint"] | null,
	_lte?:ValueTypes["smallint"] | null,
	_neq?:ValueTypes["smallint"] | null,
	_nin?:ValueTypes["smallint"][]
};
	["st_d_within_geography_input"]: {
	distance:number,
	from:ValueTypes["geography"],
	use_spheroid?:boolean | null
};
	["st_d_within_input"]: {
	distance:number,
	from:ValueTypes["geometry"]
};
	["subscription_root"]: AliasType<{
Appointment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment"]],
AppointmentGrade?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGrade_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGrade_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
AppointmentGradeSelection?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGradeSelection_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGradeSelection_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null},ValueTypes["AppointmentGradeSelection"]],
AppointmentGradeSelectionCategory?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentGradeSelectionCategory_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentGradeSelectionCategory_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"] | null},ValueTypes["AppointmentGradeSelectionCategory"]],
AppointmentGradeSelectionCategory_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGradeSelectionCategory"]],
AppointmentGradeSelectionCategory_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AppointmentGradeSelectionCategory_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelectionCategory_bool_exp"] | null},ValueTypes["AppointmentGradeSelectionCategory"]],
AppointmentGradeSelection_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGradeSelection"]],
AppointmentGradeSelection_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AppointmentGradeSelection_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGradeSelection_bool_exp"] | null},ValueTypes["AppointmentGradeSelection"]],
AppointmentGrade_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentGrade"]],
AppointmentGrade_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AppointmentGrade_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentGrade_bool_exp"] | null},ValueTypes["AppointmentGrade"]],
AppointmentProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider"]],
AppointmentProvider_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider_aggregate"]],
AppointmentProvider_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentProvider"]],
AppointmentProvider_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AppointmentProvider_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentProvider_bool_exp"] | null},ValueTypes["AppointmentProvider"]],
AppointmentReport?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AppointmentReport_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AppointmentReport_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentReport_bool_exp"] | null},ValueTypes["AppointmentReport"]],
AppointmentReport_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["AppointmentReport"]],
AppointmentReport_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AppointmentReport_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AppointmentReport_bool_exp"] | null},ValueTypes["AppointmentReport"]],
Appointment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Appointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Appointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment_aggregate"]],
Appointment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Appointment"]],
Appointment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Appointment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Appointment_bool_exp"] | null},ValueTypes["Appointment"]],
Attachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Attachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Attachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Attachment_bool_exp"] | null},ValueTypes["Attachment"]],
Attachment_by_pk?: [{	/** Not auto generated, must be provided */
	id:ValueTypes["uuid"]},ValueTypes["Attachment"]],
Attachment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Attachment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Attachment_bool_exp"] | null},ValueTypes["Attachment"]],
Availability?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Availability_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Availability_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Availability_bool_exp"] | null},ValueTypes["Availability"]],
AvailabilityResult?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AvailabilityResult_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AvailabilityResult_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AvailabilityResult_bool_exp"] | null},ValueTypes["AvailabilityResult"]],
AvailabilityResult_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AvailabilityResult_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AvailabilityResult_bool_exp"] | null},ValueTypes["AvailabilityResult"]],
Availability_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Availability_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Availability_bool_exp"] | null},ValueTypes["Availability"]],
AvailableAppointment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["AvailableAppointment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["AvailableAppointment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["AvailableAppointment_bool_exp"] | null},ValueTypes["AvailableAppointment"]],
AvailableAppointment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["AvailableAppointment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["AvailableAppointment_bool_exp"] | null},ValueTypes["AvailableAppointment"]],
ClientRelease?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ClientRelease_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ClientRelease_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ClientRelease_bool_exp"] | null},ValueTypes["ClientRelease"]],
ClientRelease_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ClientRelease"]],
ClientRelease_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ClientRelease_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ClientRelease_bool_exp"] | null},ValueTypes["ClientRelease"]],
ClientType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ClientType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ClientType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ClientType_bool_exp"] | null},ValueTypes["ClientType"]],
ClientType_by_pk?: [{	value:string},ValueTypes["ClientType"]],
ClientType_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ClientType_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ClientType_bool_exp"] | null},ValueTypes["ClientType"]],
ContentBlock?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ContentBlock_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ContentBlock_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ContentBlock_bool_exp"] | null},ValueTypes["ContentBlock"]],
ContentBlock_by_pk?: [{	key:string},ValueTypes["ContentBlock"]],
ContentBlock_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ContentBlock_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ContentBlock_bool_exp"] | null},ValueTypes["ContentBlock"]],
DataDeletionRequest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["DataDeletionRequest_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["DataDeletionRequest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["DataDeletionRequest_bool_exp"] | null},ValueTypes["DataDeletionRequest"]],
DataDeletionRequest_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["DataDeletionRequest"]],
DataDeletionRequest_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["DataDeletionRequest_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["DataDeletionRequest_bool_exp"] | null},ValueTypes["DataDeletionRequest"]],
Device?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device"]],
Device_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Device_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Device_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device_aggregate"]],
Device_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Device"]],
Device_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Device_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Device_bool_exp"] | null},ValueTypes["Device"]],
ExternalIdentifier?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ExternalIdentifier_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ExternalIdentifier_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ExternalIdentifier_bool_exp"] | null},ValueTypes["ExternalIdentifier"]],
ExternalIdentifier_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ExternalIdentifier"]],
ExternalIdentifier_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ExternalIdentifier_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ExternalIdentifier_bool_exp"] | null},ValueTypes["ExternalIdentifier"]],
GeographicRegion?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegion_bool_exp"] | null},ValueTypes["GeographicRegion"]],
GeographicRegionHandyman?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionHandyman_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionHandyman_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionHandyman_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionHandyman_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["GeographicRegionHandyman_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionHandyman_bool_exp"] | null},ValueTypes["GeographicRegionHandyman"]],
GeographicRegionNeighborhood?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegionNeighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegionNeighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null},ValueTypes["GeographicRegionNeighborhood"]],
GeographicRegionNeighborhood_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegionNeighborhood"]],
GeographicRegionNeighborhood_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["GeographicRegionNeighborhood_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegionNeighborhood_bool_exp"] | null},ValueTypes["GeographicRegionNeighborhood"]],
GeographicRegion_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["GeographicRegion"]],
GeographicRegion_by_zipCode?: [{	/** input parameters for function "GeographicRegion_by_zipCode" */
	args:ValueTypes["GeographicRegion_by_zipCode_args"],	/** distinct select on columns */
	distinct_on?:ValueTypes["GeographicRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["GeographicRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegion_bool_exp"] | null},ValueTypes["GeographicRegion"]],
GeographicRegion_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["GeographicRegion_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["GeographicRegion_bool_exp"] | null},ValueTypes["GeographicRegion"]],
Home?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Home_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Home_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Home_bool_exp"] | null},ValueTypes["Home"]],
HomeInventoryItem?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null},ValueTypes["HomeInventoryItem"]],
HomeInventoryItemAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
HomeInventoryItemAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemAttachment"]],
HomeInventoryItemAttachment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryItemAttachment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemAttachment_bool_exp"] | null},ValueTypes["HomeInventoryItemAttachment"]],
HomeInventoryItemCategory?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategory_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategory_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null},ValueTypes["HomeInventoryItemCategory"]],
HomeInventoryItemCategoryIcon?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryIcon_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryIcon_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryIcon_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryIcon_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryItemCategoryIcon_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryIcon_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryIcon"]],
HomeInventoryItemCategoryPrompt?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryItemCategoryPrompt_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryItemCategoryPrompt_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItemCategoryPrompt_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItemCategoryPrompt_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryItemCategoryPrompt_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategoryPrompt_bool_exp"] | null},ValueTypes["HomeInventoryItemCategoryPrompt"]],
HomeInventoryItemCategory_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItemCategory"]],
HomeInventoryItemCategory_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryItemCategory_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItemCategory_bool_exp"] | null},ValueTypes["HomeInventoryItemCategory"]],
HomeInventoryItem_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeInventoryItem"]],
HomeInventoryItem_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryItem_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryItem_bool_exp"] | null},ValueTypes["HomeInventoryItem"]],
HomeInventoryMaintenanceSchedule?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryMaintenanceSchedule_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryMaintenanceSchedule_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"] | null},ValueTypes["HomeInventoryMaintenanceSchedule"]],
HomeInventoryMaintenanceSchedule_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryMaintenanceSchedule_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryMaintenanceSchedule_bool_exp"] | null},ValueTypes["HomeInventoryMaintenanceSchedule"]],
HomeInventoryServiceProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryServiceProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryServiceProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceProvider_bool_exp"] | null},ValueTypes["HomeInventoryServiceProvider"]],
HomeInventoryServiceProvider_by_pk?: [{	value:string},ValueTypes["HomeInventoryServiceProvider"]],
HomeInventoryServiceProvider_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryServiceProvider_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceProvider_bool_exp"] | null},ValueTypes["HomeInventoryServiceProvider"]],
HomeInventoryServiceType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeInventoryServiceType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeInventoryServiceType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceType_bool_exp"] | null},ValueTypes["HomeInventoryServiceType"]],
HomeInventoryServiceType_by_pk?: [{	value:string},ValueTypes["HomeInventoryServiceType"]],
HomeInventoryServiceType_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeInventoryServiceType_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeInventoryServiceType_bool_exp"] | null},ValueTypes["HomeInventoryServiceType"]],
HomeWaitlist?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["HomeWaitlist_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["HomeWaitlist_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["HomeWaitlist_bool_exp"] | null},ValueTypes["HomeWaitlist"]],
HomeWaitlist_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["HomeWaitlist"]],
HomeWaitlist_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["HomeWaitlist_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["HomeWaitlist_bool_exp"] | null},ValueTypes["HomeWaitlist"]],
Home_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Home"]],
Home_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Home_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Home_bool_exp"] | null},ValueTypes["Home"]],
MarketingLead?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["MarketingLead_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["MarketingLead_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["MarketingLead_bool_exp"] | null},ValueTypes["MarketingLead"]],
MarketingLead_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["MarketingLead"]],
MarketingLead_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["MarketingLead_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["MarketingLead_bool_exp"] | null},ValueTypes["MarketingLead"]],
Neighborhood?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Neighborhood_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Neighborhood_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Neighborhood_bool_exp"] | null},ValueTypes["Neighborhood"]],
NeighborhoodRegion?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["NeighborhoodRegion_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["NeighborhoodRegion_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["NeighborhoodRegion_bool_exp"] | null},ValueTypes["NeighborhoodRegion"]],
NeighborhoodRegion_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["NeighborhoodRegion"]],
NeighborhoodRegion_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["NeighborhoodRegion_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["NeighborhoodRegion_bool_exp"] | null},ValueTypes["NeighborhoodRegion"]],
Neighborhood_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Neighborhood"]],
Neighborhood_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Neighborhood_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Neighborhood_bool_exp"] | null},ValueTypes["Neighborhood"]],
Person?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person"]],
PersonBiographyItem?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonBiographyItem_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonBiographyItem_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonBiographyItem_bool_exp"] | null},ValueTypes["PersonBiographyItem"]],
PersonBiographyItem_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["PersonBiographyItem"]],
PersonBiographyItem_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["PersonBiographyItem_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["PersonBiographyItem_bool_exp"] | null},ValueTypes["PersonBiographyItem"]],
PersonHome?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["PersonHome_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["PersonHome_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["PersonHome_bool_exp"] | null},ValueTypes["PersonHome"]],
PersonHome_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["PersonHome_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["PersonHome_bool_exp"] | null},ValueTypes["PersonHome"]],
Person_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Person_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Person_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person_aggregate"]],
Person_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Person"]],
Person_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Person_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Person_bool_exp"] | null},ValueTypes["Person"]],
Referral?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Referral_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Referral_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Referral_bool_exp"] | null},ValueTypes["Referral"]],
ReferralProgram?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ReferralProgram_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ReferralProgram_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ReferralProgram_bool_exp"] | null},ValueTypes["ReferralProgram"]],
ReferralProgram_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ReferralProgram"]],
ReferralProgram_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ReferralProgram_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ReferralProgram_bool_exp"] | null},ValueTypes["ReferralProgram"]],
Referral_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Referral"]],
Referral_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Referral_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Referral_bool_exp"] | null},ValueTypes["Referral"]],
Region?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Region_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Region_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Region_bool_exp"] | null},ValueTypes["Region"]],
Region_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Region"]],
Region_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Region_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Region_bool_exp"] | null},ValueTypes["Region"]],
ServiceOffer?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOffer_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOffer_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOffer_bool_exp"] | null},ValueTypes["ServiceOffer"]],
ServiceOfferResponse?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponse_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponse_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
ServiceOfferResponseStatus?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferResponseStatus_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferResponseStatus_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponseStatus_bool_exp"] | null},ValueTypes["ServiceOfferResponseStatus"]],
ServiceOfferResponseStatus_by_pk?: [{	value:string},ValueTypes["ServiceOfferResponseStatus"]],
ServiceOfferResponseStatus_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceOfferResponseStatus_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponseStatus_bool_exp"] | null},ValueTypes["ServiceOfferResponseStatus"]],
ServiceOfferResponse_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceOfferResponse"]],
ServiceOfferResponse_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceOfferResponse_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferResponse_bool_exp"] | null},ValueTypes["ServiceOfferResponse"]],
ServiceOfferType?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceOfferType_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceOfferType_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferType_bool_exp"] | null},ValueTypes["ServiceOfferType"]],
ServiceOfferType_by_pk?: [{	value:string},ValueTypes["ServiceOfferType"]],
ServiceOfferType_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceOfferType_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOfferType_bool_exp"] | null},ValueTypes["ServiceOfferType"]],
ServiceOffer_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceOffer"]],
ServiceOffer_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceOffer_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceOffer_bool_exp"] | null},ValueTypes["ServiceOffer"]],
ServiceProvider?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProvider_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProvider_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProvider_bool_exp"] | null},ValueTypes["ServiceProvider"]],
ServiceProviderRequest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProviderRequest_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProviderRequest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequest_bool_exp"] | null},ValueTypes["ServiceProviderRequest"]],
ServiceProviderRequestStage?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["ServiceProviderRequestStage_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["ServiceProviderRequestStage_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequestStage_bool_exp"] | null},ValueTypes["ServiceProviderRequestStage"]],
ServiceProviderRequestStage_by_pk?: [{	value:string},ValueTypes["ServiceProviderRequestStage"]],
ServiceProviderRequestStage_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceProviderRequestStage_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequestStage_bool_exp"] | null},ValueTypes["ServiceProviderRequestStage"]],
ServiceProviderRequest_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceProviderRequest"]],
ServiceProviderRequest_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceProviderRequest_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProviderRequest_bool_exp"] | null},ValueTypes["ServiceProviderRequest"]],
ServiceProvider_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["ServiceProvider"]],
ServiceProvider_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["ServiceProvider_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["ServiceProvider_bool_exp"] | null},ValueTypes["ServiceProvider"]],
StripePaymentMethod?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripePaymentMethod_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripePaymentMethod_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripePaymentMethod_bool_exp"] | null},ValueTypes["StripePaymentMethod"]],
StripePaymentMethod_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["StripePaymentMethod"]],
StripePaymentMethod_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["StripePaymentMethod_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["StripePaymentMethod_bool_exp"] | null},ValueTypes["StripePaymentMethod"]],
StripeSubscription?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["StripeSubscription_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["StripeSubscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["StripeSubscription_bool_exp"] | null},ValueTypes["StripeSubscription"]],
StripeSubscription_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["StripeSubscription"]],
StripeSubscription_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["StripeSubscription_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["StripeSubscription_bool_exp"] | null},ValueTypes["StripeSubscription"]],
Task?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
TaskAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskAttachment_bool_exp"] | null},ValueTypes["TaskAttachment"]],
TaskAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskAttachment"]],
TaskAttachment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskAttachment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskAttachment_bool_exp"] | null},ValueTypes["TaskAttachment"]],
TaskComment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment"]],
TaskComment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskComment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskComment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment_aggregate"]],
TaskComment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskComment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskComment_bool_exp"] | null},ValueTypes["TaskComment"]],
TaskPart?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPart_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPart_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPart_bool_exp"] | null},ValueTypes["TaskPart"]],
TaskPartPartyResponsible?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPartPartyResponsible_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPartPartyResponsible_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartPartyResponsible_bool_exp"] | null},ValueTypes["TaskPartPartyResponsible"]],
TaskPartPartyResponsible_by_pk?: [{	value:string},ValueTypes["TaskPartPartyResponsible"]],
TaskPartPartyResponsible_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskPartPartyResponsible_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartPartyResponsible_bool_exp"] | null},ValueTypes["TaskPartPartyResponsible"]],
TaskPartStatus?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskPartStatus_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskPartStatus_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartStatus_bool_exp"] | null},ValueTypes["TaskPartStatus"]],
TaskPartStatus_by_pk?: [{	value:string},ValueTypes["TaskPartStatus"]],
TaskPartStatus_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskPartStatus_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPartStatus_bool_exp"] | null},ValueTypes["TaskPartStatus"]],
TaskPart_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskPart"]],
TaskPart_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskPart_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskPart_bool_exp"] | null},ValueTypes["TaskPart"]],
TaskUpdateLog?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
TaskUpdateLogAttachment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLogAttachment_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLogAttachment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null},ValueTypes["TaskUpdateLogAttachment"]],
TaskUpdateLogAttachment_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskUpdateLogAttachment"]],
TaskUpdateLogAttachment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskUpdateLogAttachment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLogAttachment_bool_exp"] | null},ValueTypes["TaskUpdateLogAttachment"]],
TaskUpdateLog_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TaskUpdateLog_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TaskUpdateLog_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog_aggregate"]],
TaskUpdateLog_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["TaskUpdateLog"]],
TaskUpdateLog_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TaskUpdateLog_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TaskUpdateLog_bool_exp"] | null},ValueTypes["TaskUpdateLog"]],
Task_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["Task_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["Task_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task_aggregate"]],
Task_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["Task"]],
Task_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["Task_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["Task_bool_exp"] | null},ValueTypes["Task"]],
TimeZone?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["TimeZone_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["TimeZone_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["TimeZone_bool_exp"] | null},ValueTypes["TimeZone"]],
TimeZone_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["TimeZone_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["TimeZone_bool_exp"] | null},ValueTypes["TimeZone"]],
find_Person_by_phone?: [{	/** input parameters for function "find_Person_by_phone" */
	args:ValueTypes["find_Person_by_phone_args"],	/** distinct select on columns */
	distinct_on?:ValueTypes["find_Person_response_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["find_Person_response_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["find_Person_response_bool_exp"] | null},ValueTypes["find_Person_response"]],
find_Person_response?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["find_Person_response_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["find_Person_response_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["find_Person_response_bool_exp"] | null},ValueTypes["find_Person_response"]],
find_Person_response_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["find_Person_response_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["find_Person_response_bool_exp"] | null},ValueTypes["find_Person_response"]],
		__typename?: boolean
}>;
	["timestamptz"]:unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
	_eq?:ValueTypes["timestamptz"] | null,
	_gt?:ValueTypes["timestamptz"] | null,
	_gte?:ValueTypes["timestamptz"] | null,
	_in?:ValueTypes["timestamptz"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["timestamptz"] | null,
	_lte?:ValueTypes["timestamptz"] | null,
	_neq?:ValueTypes["timestamptz"] | null,
	_nin?:ValueTypes["timestamptz"][]
};
	["timetz"]:unknown;
	/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
["timetz_comparison_exp"]: {
	_eq?:ValueTypes["timetz"] | null,
	_gt?:ValueTypes["timetz"] | null,
	_gte?:ValueTypes["timetz"] | null,
	_in?:ValueTypes["timetz"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["timetz"] | null,
	_lte?:ValueTypes["timetz"] | null,
	_neq?:ValueTypes["timetz"] | null,
	_nin?:ValueTypes["timetz"][]
};
	["uuid"]:unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
	_eq?:ValueTypes["uuid"] | null,
	_gt?:ValueTypes["uuid"] | null,
	_gte?:ValueTypes["uuid"] | null,
	_in?:ValueTypes["uuid"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["uuid"] | null,
	_lte?:ValueTypes["uuid"] | null,
	_neq?:ValueTypes["uuid"] | null,
	_nin?:ValueTypes["uuid"][]
}
  }

export type ModelTypes = {
    /** columns and relationships of "Appointment" */
["Appointment"]: {
		/** An array relationship */
	AppointmentGrades:ModelTypes["AppointmentGrade"][],
	/** An array relationship */
	AppointmentProviders:ModelTypes["AppointmentProvider"][],
	/** An aggregate relationship */
	AppointmentProviders_aggregate:ModelTypes["AppointmentProvider_aggregate"],
	/** An object relationship */
	AppointmentReport?:ModelTypes["AppointmentReport"],
	/** An object relationship */
	CreatedBy:ModelTypes["Person"],
	/** An object relationship */
	Home:ModelTypes["Home"],
	/** An array relationship */
	Tasks:ModelTypes["Task"][],
	/** An aggregate relationship */
	Tasks_aggregate:ModelTypes["Task_aggregate"],
	beginsAt:ModelTypes["timestamptz"],
	createdAt:ModelTypes["timestamptz"],
	createdById:ModelTypes["uuid"],
	endsAt:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	/** When true, beginsAt and endsAt define a window of time, not an exact range */
	isWindowTimes:boolean,
	updatedAt:ModelTypes["timestamptz"]
};
	/** Homeowner review of a completed Appointment */
["AppointmentGrade"]: {
		/** An object relationship */
	Appointment:ModelTypes["Appointment"],
	/** An array relationship */
	AppointmentGradeSelections:ModelTypes["AppointmentGradeSelection"][],
	/** An object relationship */
	Home:ModelTypes["Home"],
	/** An object relationship */
	Person:ModelTypes["Person"],
	appointmentId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	negativeComments?:string,
	personId:ModelTypes["uuid"],
	positiveComments?:string,
	score:ModelTypes["smallint"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Person selected categories of feedback for an AppointmentGrade */
["AppointmentGradeSelection"]: {
		/** An object relationship */
	AppointmentGrade:ModelTypes["AppointmentGrade"],
	/** An object relationship */
	AppointmentGradeSelectionCategory:ModelTypes["AppointmentGradeSelectionCategory"],
	appointmentGradeId:ModelTypes["uuid"],
	appointmentGradeSelectionCategoryId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Defines the options presented when asking for feedback from a homeowner */
["AppointmentGradeSelectionCategory"]: {
		/** An object relationship */
	ImageAssetAttachment:ModelTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment:ModelTypes["Attachment"],
	createdAt:ModelTypes["timestamptz"],
	description?:string,
	/** Text label displayed to the person for this category. */
	displayText:string,
	/** Human readable unique identifier */
	friendlyAssetId:string,
	id:ModelTypes["uuid"],
	imageAssetAttachmentId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"],
	vectorAssetAttachmentId:ModelTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "AppointmentGradeSelectionCategory". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelectionCategory_bool_exp"]: GraphQLTypes["AppointmentGradeSelectionCategory_bool_exp"];
	/** Ordering options when selecting data from "AppointmentGradeSelectionCategory". */
["AppointmentGradeSelectionCategory_order_by"]: GraphQLTypes["AppointmentGradeSelectionCategory_order_by"];
	/** select columns of table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_select_column"]: GraphQLTypes["AppointmentGradeSelectionCategory_select_column"];
	/** Streaming cursor of the table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_stream_cursor_input"]: GraphQLTypes["AppointmentGradeSelectionCategory_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelectionCategory_stream_cursor_value_input"]: GraphQLTypes["AppointmentGradeSelectionCategory_stream_cursor_value_input"];
	/** order by aggregate values of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_aggregate_order_by"]: GraphQLTypes["AppointmentGradeSelection_aggregate_order_by"];
	/** input type for inserting array relation for remote table "AppointmentGradeSelection" */
["AppointmentGradeSelection_arr_rel_insert_input"]: GraphQLTypes["AppointmentGradeSelection_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "AppointmentGradeSelection". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelection_bool_exp"]: GraphQLTypes["AppointmentGradeSelection_bool_exp"];
	/** unique or primary key constraints on table "AppointmentGradeSelection" */
["AppointmentGradeSelection_constraint"]: GraphQLTypes["AppointmentGradeSelection_constraint"];
	/** input type for inserting data into table "AppointmentGradeSelection" */
["AppointmentGradeSelection_insert_input"]: GraphQLTypes["AppointmentGradeSelection_insert_input"];
	/** order by max() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_max_order_by"]: GraphQLTypes["AppointmentGradeSelection_max_order_by"];
	/** order by min() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_min_order_by"]: GraphQLTypes["AppointmentGradeSelection_min_order_by"];
	/** response of any mutation on the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["AppointmentGradeSelection"][]
};
	/** on_conflict condition type for table "AppointmentGradeSelection" */
["AppointmentGradeSelection_on_conflict"]: GraphQLTypes["AppointmentGradeSelection_on_conflict"];
	/** Ordering options when selecting data from "AppointmentGradeSelection". */
["AppointmentGradeSelection_order_by"]: GraphQLTypes["AppointmentGradeSelection_order_by"];
	/** select columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_select_column"]: GraphQLTypes["AppointmentGradeSelection_select_column"];
	/** Streaming cursor of the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_stream_cursor_input"]: GraphQLTypes["AppointmentGradeSelection_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelection_stream_cursor_value_input"]: GraphQLTypes["AppointmentGradeSelection_stream_cursor_value_input"];
	/** placeholder for update columns of table "AppointmentGradeSelection" (current role has no relevant permissions) */
["AppointmentGradeSelection_update_column"]: GraphQLTypes["AppointmentGradeSelection_update_column"];
	/** order by aggregate values of table "AppointmentGrade" */
["AppointmentGrade_aggregate_order_by"]: GraphQLTypes["AppointmentGrade_aggregate_order_by"];
	/** input type for inserting array relation for remote table "AppointmentGrade" */
["AppointmentGrade_arr_rel_insert_input"]: GraphQLTypes["AppointmentGrade_arr_rel_insert_input"];
	/** order by avg() on columns of table "AppointmentGrade" */
["AppointmentGrade_avg_order_by"]: GraphQLTypes["AppointmentGrade_avg_order_by"];
	/** Boolean expression to filter rows from the table "AppointmentGrade". All fields are combined with a logical 'AND'. */
["AppointmentGrade_bool_exp"]: GraphQLTypes["AppointmentGrade_bool_exp"];
	/** unique or primary key constraints on table "AppointmentGrade" */
["AppointmentGrade_constraint"]: GraphQLTypes["AppointmentGrade_constraint"];
	/** input type for inserting data into table "AppointmentGrade" */
["AppointmentGrade_insert_input"]: GraphQLTypes["AppointmentGrade_insert_input"];
	/** order by max() on columns of table "AppointmentGrade" */
["AppointmentGrade_max_order_by"]: GraphQLTypes["AppointmentGrade_max_order_by"];
	/** order by min() on columns of table "AppointmentGrade" */
["AppointmentGrade_min_order_by"]: GraphQLTypes["AppointmentGrade_min_order_by"];
	/** response of any mutation on the table "AppointmentGrade" */
["AppointmentGrade_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["AppointmentGrade"][]
};
	/** input type for inserting object relation for remote table "AppointmentGrade" */
["AppointmentGrade_obj_rel_insert_input"]: GraphQLTypes["AppointmentGrade_obj_rel_insert_input"];
	/** on_conflict condition type for table "AppointmentGrade" */
["AppointmentGrade_on_conflict"]: GraphQLTypes["AppointmentGrade_on_conflict"];
	/** Ordering options when selecting data from "AppointmentGrade". */
["AppointmentGrade_order_by"]: GraphQLTypes["AppointmentGrade_order_by"];
	/** select columns of table "AppointmentGrade" */
["AppointmentGrade_select_column"]: GraphQLTypes["AppointmentGrade_select_column"];
	/** order by stddev() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_order_by"]: GraphQLTypes["AppointmentGrade_stddev_order_by"];
	/** order by stddev_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_pop_order_by"]: GraphQLTypes["AppointmentGrade_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_samp_order_by"]: GraphQLTypes["AppointmentGrade_stddev_samp_order_by"];
	/** Streaming cursor of the table "AppointmentGrade" */
["AppointmentGrade_stream_cursor_input"]: GraphQLTypes["AppointmentGrade_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AppointmentGrade_stream_cursor_value_input"]: GraphQLTypes["AppointmentGrade_stream_cursor_value_input"];
	/** order by sum() on columns of table "AppointmentGrade" */
["AppointmentGrade_sum_order_by"]: GraphQLTypes["AppointmentGrade_sum_order_by"];
	/** placeholder for update columns of table "AppointmentGrade" (current role has no relevant permissions) */
["AppointmentGrade_update_column"]: GraphQLTypes["AppointmentGrade_update_column"];
	/** order by var_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_pop_order_by"]: GraphQLTypes["AppointmentGrade_var_pop_order_by"];
	/** order by var_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_samp_order_by"]: GraphQLTypes["AppointmentGrade_var_samp_order_by"];
	/** order by variance() on columns of table "AppointmentGrade" */
["AppointmentGrade_variance_order_by"]: GraphQLTypes["AppointmentGrade_variance_order_by"];
	/** Associates people and organizations that provide service for an Appointment */
["AppointmentProvider"]: {
		/** An object relationship */
	Appointment:ModelTypes["Appointment"],
	/** An object relationship */
	Person?:ModelTypes["Person"],
	/** An object relationship */
	ServiceProvider:ModelTypes["ServiceProvider"],
	appointmentId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	serviceProviderId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** aggregated selection of "AppointmentProvider" */
["AppointmentProvider_aggregate"]: {
		aggregate?:ModelTypes["AppointmentProvider_aggregate_fields"],
	nodes:ModelTypes["AppointmentProvider"][]
};
	["AppointmentProvider_aggregate_bool_exp"]: GraphQLTypes["AppointmentProvider_aggregate_bool_exp"];
	["AppointmentProvider_aggregate_bool_exp_count"]: GraphQLTypes["AppointmentProvider_aggregate_bool_exp_count"];
	/** aggregate fields of "AppointmentProvider" */
["AppointmentProvider_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["AppointmentProvider_max_fields"],
	min?:ModelTypes["AppointmentProvider_min_fields"]
};
	/** order by aggregate values of table "AppointmentProvider" */
["AppointmentProvider_aggregate_order_by"]: GraphQLTypes["AppointmentProvider_aggregate_order_by"];
	/** input type for inserting array relation for remote table "AppointmentProvider" */
["AppointmentProvider_arr_rel_insert_input"]: GraphQLTypes["AppointmentProvider_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "AppointmentProvider". All fields are combined with a logical 'AND'. */
["AppointmentProvider_bool_exp"]: GraphQLTypes["AppointmentProvider_bool_exp"];
	/** unique or primary key constraints on table "AppointmentProvider" */
["AppointmentProvider_constraint"]: GraphQLTypes["AppointmentProvider_constraint"];
	/** input type for inserting data into table "AppointmentProvider" */
["AppointmentProvider_insert_input"]: GraphQLTypes["AppointmentProvider_insert_input"];
	/** aggregate max on columns */
["AppointmentProvider_max_fields"]: {
		appointmentId?:ModelTypes["uuid"],
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	serviceProviderId?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "AppointmentProvider" */
["AppointmentProvider_max_order_by"]: GraphQLTypes["AppointmentProvider_max_order_by"];
	/** aggregate min on columns */
["AppointmentProvider_min_fields"]: {
		appointmentId?:ModelTypes["uuid"],
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	serviceProviderId?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "AppointmentProvider" */
["AppointmentProvider_min_order_by"]: GraphQLTypes["AppointmentProvider_min_order_by"];
	/** response of any mutation on the table "AppointmentProvider" */
["AppointmentProvider_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["AppointmentProvider"][]
};
	/** on_conflict condition type for table "AppointmentProvider" */
["AppointmentProvider_on_conflict"]: GraphQLTypes["AppointmentProvider_on_conflict"];
	/** Ordering options when selecting data from "AppointmentProvider". */
["AppointmentProvider_order_by"]: GraphQLTypes["AppointmentProvider_order_by"];
	/** select columns of table "AppointmentProvider" */
["AppointmentProvider_select_column"]: GraphQLTypes["AppointmentProvider_select_column"];
	/** Streaming cursor of the table "AppointmentProvider" */
["AppointmentProvider_stream_cursor_input"]: GraphQLTypes["AppointmentProvider_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AppointmentProvider_stream_cursor_value_input"]: GraphQLTypes["AppointmentProvider_stream_cursor_value_input"];
	/** placeholder for update columns of table "AppointmentProvider" (current role has no relevant permissions) */
["AppointmentProvider_update_column"]: GraphQLTypes["AppointmentProvider_update_column"];
	/** Information about work completed in an appointment */
["AppointmentReport"]: {
		/** An object relationship */
	Appointment:ModelTypes["Appointment"],
	/** An object relationship */
	CreatedBy:ModelTypes["Person"],
	/** An object relationship */
	ReviewedBy?:ModelTypes["Person"],
	/** An array relationship */
	TaskUpdateLogs:ModelTypes["TaskUpdateLog"][],
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate:ModelTypes["TaskUpdateLog_aggregate"],
	appointmentId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	createdById:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	reviewCompletedAt?:ModelTypes["timestamptz"],
	reviewedById?:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "AppointmentReport" */
["AppointmentReport_aggregate_order_by"]: GraphQLTypes["AppointmentReport_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "AppointmentReport". All fields are combined with a logical 'AND'. */
["AppointmentReport_bool_exp"]: GraphQLTypes["AppointmentReport_bool_exp"];
	/** order by max() on columns of table "AppointmentReport" */
["AppointmentReport_max_order_by"]: GraphQLTypes["AppointmentReport_max_order_by"];
	/** order by min() on columns of table "AppointmentReport" */
["AppointmentReport_min_order_by"]: GraphQLTypes["AppointmentReport_min_order_by"];
	/** Ordering options when selecting data from "AppointmentReport". */
["AppointmentReport_order_by"]: GraphQLTypes["AppointmentReport_order_by"];
	/** select columns of table "AppointmentReport" */
["AppointmentReport_select_column"]: GraphQLTypes["AppointmentReport_select_column"];
	/** Streaming cursor of the table "AppointmentReport" */
["AppointmentReport_stream_cursor_input"]: GraphQLTypes["AppointmentReport_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AppointmentReport_stream_cursor_value_input"]: GraphQLTypes["AppointmentReport_stream_cursor_value_input"];
	/** aggregated selection of "Appointment" */
["Appointment_aggregate"]: {
		aggregate?:ModelTypes["Appointment_aggregate_fields"],
	nodes:ModelTypes["Appointment"][]
};
	["Appointment_aggregate_bool_exp"]: GraphQLTypes["Appointment_aggregate_bool_exp"];
	["Appointment_aggregate_bool_exp_bool_and"]: GraphQLTypes["Appointment_aggregate_bool_exp_bool_and"];
	["Appointment_aggregate_bool_exp_bool_or"]: GraphQLTypes["Appointment_aggregate_bool_exp_bool_or"];
	["Appointment_aggregate_bool_exp_count"]: GraphQLTypes["Appointment_aggregate_bool_exp_count"];
	/** aggregate fields of "Appointment" */
["Appointment_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["Appointment_max_fields"],
	min?:ModelTypes["Appointment_min_fields"]
};
	/** order by aggregate values of table "Appointment" */
["Appointment_aggregate_order_by"]: GraphQLTypes["Appointment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "Appointment" */
["Appointment_arr_rel_insert_input"]: GraphQLTypes["Appointment_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "Appointment". All fields are combined with a logical 'AND'. */
["Appointment_bool_exp"]: GraphQLTypes["Appointment_bool_exp"];
	/** unique or primary key constraints on table "Appointment" */
["Appointment_constraint"]: GraphQLTypes["Appointment_constraint"];
	/** input type for inserting data into table "Appointment" */
["Appointment_insert_input"]: GraphQLTypes["Appointment_insert_input"];
	/** aggregate max on columns */
["Appointment_max_fields"]: {
		beginsAt?:ModelTypes["timestamptz"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	endsAt?:ModelTypes["timestamptz"],
	homeId?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "Appointment" */
["Appointment_max_order_by"]: GraphQLTypes["Appointment_max_order_by"];
	/** aggregate min on columns */
["Appointment_min_fields"]: {
		beginsAt?:ModelTypes["timestamptz"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	endsAt?:ModelTypes["timestamptz"],
	homeId?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "Appointment" */
["Appointment_min_order_by"]: GraphQLTypes["Appointment_min_order_by"];
	/** response of any mutation on the table "Appointment" */
["Appointment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Appointment"][]
};
	/** input type for inserting object relation for remote table "Appointment" */
["Appointment_obj_rel_insert_input"]: GraphQLTypes["Appointment_obj_rel_insert_input"];
	/** on_conflict condition type for table "Appointment" */
["Appointment_on_conflict"]: GraphQLTypes["Appointment_on_conflict"];
	/** Ordering options when selecting data from "Appointment". */
["Appointment_order_by"]: GraphQLTypes["Appointment_order_by"];
	/** primary key columns input for table: Appointment */
["Appointment_pk_columns_input"]: GraphQLTypes["Appointment_pk_columns_input"];
	/** select columns of table "Appointment" */
["Appointment_select_column"]: GraphQLTypes["Appointment_select_column"];
	/** select "Appointment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "Appointment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "Appointment" */
["Appointment_set_input"]: GraphQLTypes["Appointment_set_input"];
	/** Streaming cursor of the table "Appointment" */
["Appointment_stream_cursor_input"]: GraphQLTypes["Appointment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Appointment_stream_cursor_value_input"]: GraphQLTypes["Appointment_stream_cursor_value_input"];
	/** update columns of table "Appointment" */
["Appointment_update_column"]: GraphQLTypes["Appointment_update_column"];
	["Appointment_updates"]: GraphQLTypes["Appointment_updates"];
	/** File attachments to any other object. */
["Attachment"]: {
		/** An object relationship */
	CreatedBy:ModelTypes["Person"],
	/** An array relationship */
	HomeInventoryItemAttachments:ModelTypes["HomeInventoryItemAttachment"][],
	/** An array relationship */
	ProfilePictureFor:ModelTypes["Person"][],
	/** An aggregate relationship */
	ProfilePictureFor_aggregate:ModelTypes["Person_aggregate"],
	/** An array relationship */
	TaskAttachments:ModelTypes["TaskAttachment"][],
	/** An array relationship */
	TaskUpdateLogAttachments:ModelTypes["TaskUpdateLogAttachment"][],
	/** User provided caption */
	caption?:string,
	contentType?:string,
	createdAt:ModelTypes["timestamptz"],
	createdById:ModelTypes["uuid"],
	/** Not auto generated, must be provided */
	id:ModelTypes["uuid"],
	publicUrl?:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "Attachment" */
["Attachment_aggregate_order_by"]: GraphQLTypes["Attachment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "Attachment" */
["Attachment_arr_rel_insert_input"]: GraphQLTypes["Attachment_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
["Attachment_bool_exp"]: GraphQLTypes["Attachment_bool_exp"];
	/** unique or primary key constraints on table "Attachment" */
["Attachment_constraint"]: GraphQLTypes["Attachment_constraint"];
	/** input type for inserting data into table "Attachment" */
["Attachment_insert_input"]: GraphQLTypes["Attachment_insert_input"];
	/** order by max() on columns of table "Attachment" */
["Attachment_max_order_by"]: GraphQLTypes["Attachment_max_order_by"];
	/** order by min() on columns of table "Attachment" */
["Attachment_min_order_by"]: GraphQLTypes["Attachment_min_order_by"];
	/** response of any mutation on the table "Attachment" */
["Attachment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Attachment"][]
};
	/** input type for inserting object relation for remote table "Attachment" */
["Attachment_obj_rel_insert_input"]: GraphQLTypes["Attachment_obj_rel_insert_input"];
	/** on_conflict condition type for table "Attachment" */
["Attachment_on_conflict"]: GraphQLTypes["Attachment_on_conflict"];
	/** Ordering options when selecting data from "Attachment". */
["Attachment_order_by"]: GraphQLTypes["Attachment_order_by"];
	/** primary key columns input for table: Attachment */
["Attachment_pk_columns_input"]: GraphQLTypes["Attachment_pk_columns_input"];
	/** select columns of table "Attachment" */
["Attachment_select_column"]: GraphQLTypes["Attachment_select_column"];
	/** input type for updating data in table "Attachment" */
["Attachment_set_input"]: GraphQLTypes["Attachment_set_input"];
	/** Streaming cursor of the table "Attachment" */
["Attachment_stream_cursor_input"]: GraphQLTypes["Attachment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Attachment_stream_cursor_value_input"]: GraphQLTypes["Attachment_stream_cursor_value_input"];
	/** update columns of table "Attachment" */
["Attachment_update_column"]: GraphQLTypes["Attachment_update_column"];
	["Attachment_updates"]: GraphQLTypes["Attachment_updates"];
	/** columns and relationships of "Availability" */
["Availability"]: {
		/** An object relationship */
	Person?:ModelTypes["Person"],
	beginsAt?:ModelTypes["timestamptz"],
	endsAt?:ModelTypes["timestamptz"],
	personId?:ModelTypes["uuid"],
	serviceArea?:ModelTypes["geometry"]
};
	/** Result of an Availability query by the perspective of a Home */
["AvailabilityResult"]: {
		/** An object relationship */
	Appointment?:ModelTypes["Appointment"],
	/** An object relationship */
	Home?:ModelTypes["Home"],
	appointmentId?:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	creationSource?:ModelTypes["CreationSourceType_enum"],
	data:ModelTypes["jsonb"],
	homeId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	source:ModelTypes["AvailabilityResultSource_enum"],
	updatedAt:ModelTypes["timestamptz"]
};
	["AvailabilityResultSource_enum"]: GraphQLTypes["AvailabilityResultSource_enum"];
	/** Boolean expression to compare columns of type "AvailabilityResultSource_enum". All fields are combined with logical 'AND'. */
["AvailabilityResultSource_enum_comparison_exp"]: GraphQLTypes["AvailabilityResultSource_enum_comparison_exp"];
	/** append existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_append_input"]: GraphQLTypes["AvailabilityResult_append_input"];
	/** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
["AvailabilityResult_bool_exp"]: GraphQLTypes["AvailabilityResult_bool_exp"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["AvailabilityResult_delete_at_path_input"]: GraphQLTypes["AvailabilityResult_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["AvailabilityResult_delete_elem_input"]: GraphQLTypes["AvailabilityResult_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["AvailabilityResult_delete_key_input"]: GraphQLTypes["AvailabilityResult_delete_key_input"];
	/** input type for inserting data into table "AvailabilityResult" */
["AvailabilityResult_insert_input"]: GraphQLTypes["AvailabilityResult_insert_input"];
	/** response of any mutation on the table "AvailabilityResult" */
["AvailabilityResult_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["AvailabilityResult"][]
};
	/** Ordering options when selecting data from "AvailabilityResult". */
["AvailabilityResult_order_by"]: GraphQLTypes["AvailabilityResult_order_by"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_prepend_input"]: GraphQLTypes["AvailabilityResult_prepend_input"];
	/** select columns of table "AvailabilityResult" */
["AvailabilityResult_select_column"]: GraphQLTypes["AvailabilityResult_select_column"];
	/** input type for updating data in table "AvailabilityResult" */
["AvailabilityResult_set_input"]: GraphQLTypes["AvailabilityResult_set_input"];
	/** Streaming cursor of the table "AvailabilityResult" */
["AvailabilityResult_stream_cursor_input"]: GraphQLTypes["AvailabilityResult_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AvailabilityResult_stream_cursor_value_input"]: GraphQLTypes["AvailabilityResult_stream_cursor_value_input"];
	["AvailabilityResult_updates"]: GraphQLTypes["AvailabilityResult_updates"];
	/** order by aggregate values of table "Availability" */
["Availability_aggregate_order_by"]: GraphQLTypes["Availability_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
["Availability_bool_exp"]: GraphQLTypes["Availability_bool_exp"];
	/** order by max() on columns of table "Availability" */
["Availability_max_order_by"]: GraphQLTypes["Availability_max_order_by"];
	/** order by min() on columns of table "Availability" */
["Availability_min_order_by"]: GraphQLTypes["Availability_min_order_by"];
	/** Ordering options when selecting data from "Availability". */
["Availability_order_by"]: GraphQLTypes["Availability_order_by"];
	/** select columns of table "Availability" */
["Availability_select_column"]: GraphQLTypes["Availability_select_column"];
	/** Streaming cursor of the table "Availability" */
["Availability_stream_cursor_input"]: GraphQLTypes["Availability_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Availability_stream_cursor_value_input"]: GraphQLTypes["Availability_stream_cursor_value_input"];
	/** columns and relationships of "AvailableAppointment" */
["AvailableAppointment"]: {
		beginsAt:ModelTypes["timestamptz"],
	endsAt:ModelTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "AvailableAppointment". All fields are combined with a logical 'AND'. */
["AvailableAppointment_bool_exp"]: GraphQLTypes["AvailableAppointment_bool_exp"];
	/** Ordering options when selecting data from "AvailableAppointment". */
["AvailableAppointment_order_by"]: GraphQLTypes["AvailableAppointment_order_by"];
	/** select columns of table "AvailableAppointment" */
["AvailableAppointment_select_column"]: GraphQLTypes["AvailableAppointment_select_column"];
	/** Streaming cursor of the table "AvailableAppointment" */
["AvailableAppointment_stream_cursor_input"]: GraphQLTypes["AvailableAppointment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["AvailableAppointment_stream_cursor_value_input"]: GraphQLTypes["AvailableAppointment_stream_cursor_value_input"];
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: GraphQLTypes["Boolean_comparison_exp"];
	/** columns and relationships of "ClientRelease" */
["ClientRelease"]: {
		build:string,
	client:ModelTypes["ClientType_enum"],
	createdAt:ModelTypes["timestamptz"],
	expirationDate?:ModelTypes["timestamptz"],
	expirationMessage?:string,
	id:ModelTypes["uuid"],
	releaseDate:ModelTypes["timestamptz"],
	updatedAt:ModelTypes["timestamptz"],
	version:string
};
	/** Boolean expression to filter rows from the table "ClientRelease". All fields are combined with a logical 'AND'. */
["ClientRelease_bool_exp"]: GraphQLTypes["ClientRelease_bool_exp"];
	/** Ordering options when selecting data from "ClientRelease". */
["ClientRelease_order_by"]: GraphQLTypes["ClientRelease_order_by"];
	/** select columns of table "ClientRelease" */
["ClientRelease_select_column"]: GraphQLTypes["ClientRelease_select_column"];
	/** Streaming cursor of the table "ClientRelease" */
["ClientRelease_stream_cursor_input"]: GraphQLTypes["ClientRelease_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ClientRelease_stream_cursor_value_input"]: GraphQLTypes["ClientRelease_stream_cursor_value_input"];
	/** columns and relationships of "ClientType" */
["ClientType"]: {
		description?:string,
	value:string
};
	/** Boolean expression to filter rows from the table "ClientType". All fields are combined with a logical 'AND'. */
["ClientType_bool_exp"]: GraphQLTypes["ClientType_bool_exp"];
	["ClientType_enum"]: GraphQLTypes["ClientType_enum"];
	/** Boolean expression to compare columns of type "ClientType_enum". All fields are combined with logical 'AND'. */
["ClientType_enum_comparison_exp"]: GraphQLTypes["ClientType_enum_comparison_exp"];
	/** Ordering options when selecting data from "ClientType". */
["ClientType_order_by"]: GraphQLTypes["ClientType_order_by"];
	/** select columns of table "ClientType" */
["ClientType_select_column"]: GraphQLTypes["ClientType_select_column"];
	/** Streaming cursor of the table "ClientType" */
["ClientType_stream_cursor_input"]: GraphQLTypes["ClientType_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ClientType_stream_cursor_value_input"]: GraphQLTypes["ClientType_stream_cursor_value_input"];
	/** columns and relationships of "ContentBlock" */
["ContentBlock"]: {
		body:string,
	key:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
["ContentBlock_bool_exp"]: GraphQLTypes["ContentBlock_bool_exp"];
	/** Ordering options when selecting data from "ContentBlock". */
["ContentBlock_order_by"]: GraphQLTypes["ContentBlock_order_by"];
	/** select columns of table "ContentBlock" */
["ContentBlock_select_column"]: GraphQLTypes["ContentBlock_select_column"];
	/** Streaming cursor of the table "ContentBlock" */
["ContentBlock_stream_cursor_input"]: GraphQLTypes["ContentBlock_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ContentBlock_stream_cursor_value_input"]: GraphQLTypes["ContentBlock_stream_cursor_value_input"];
	["CreationSourceType_enum"]: GraphQLTypes["CreationSourceType_enum"];
	/** Boolean expression to compare columns of type "CreationSourceType_enum". All fields are combined with logical 'AND'. */
["CreationSourceType_enum_comparison_exp"]: GraphQLTypes["CreationSourceType_enum_comparison_exp"];
	/** Tracks a request from a Person to delete all of their data */
["DataDeletionRequest"]: {
		createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	personId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "DataDeletionRequest". All fields are combined with a logical 'AND'. */
["DataDeletionRequest_bool_exp"]: GraphQLTypes["DataDeletionRequest_bool_exp"];
	/** unique or primary key constraints on table "DataDeletionRequest" */
["DataDeletionRequest_constraint"]: GraphQLTypes["DataDeletionRequest_constraint"];
	/** input type for inserting data into table "DataDeletionRequest" */
["DataDeletionRequest_insert_input"]: GraphQLTypes["DataDeletionRequest_insert_input"];
	/** response of any mutation on the table "DataDeletionRequest" */
["DataDeletionRequest_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["DataDeletionRequest"][]
};
	/** on_conflict condition type for table "DataDeletionRequest" */
["DataDeletionRequest_on_conflict"]: GraphQLTypes["DataDeletionRequest_on_conflict"];
	/** Ordering options when selecting data from "DataDeletionRequest". */
["DataDeletionRequest_order_by"]: GraphQLTypes["DataDeletionRequest_order_by"];
	/** select columns of table "DataDeletionRequest" */
["DataDeletionRequest_select_column"]: GraphQLTypes["DataDeletionRequest_select_column"];
	/** Streaming cursor of the table "DataDeletionRequest" */
["DataDeletionRequest_stream_cursor_input"]: GraphQLTypes["DataDeletionRequest_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["DataDeletionRequest_stream_cursor_value_input"]: GraphQLTypes["DataDeletionRequest_stream_cursor_value_input"];
	/** placeholder for update columns of table "DataDeletionRequest" (current role has no relevant permissions) */
["DataDeletionRequest_update_column"]: GraphQLTypes["DataDeletionRequest_update_column"];
	["DecryptedMessage"]: {
		data:string
};
	/** Tracks devices used by a Person */
["Device"]: {
		/** An object relationship */
	Person:ModelTypes["Person"],
	appBuildNumber:string,
	brand:string,
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	model:string,
	osVersion:string,
	personId:ModelTypes["uuid"],
	type:ModelTypes["DeviceType_enum"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier:string,
	updatedAt:ModelTypes["timestamptz"]
};
	["DeviceType_enum"]: GraphQLTypes["DeviceType_enum"];
	/** Boolean expression to compare columns of type "DeviceType_enum". All fields are combined with logical 'AND'. */
["DeviceType_enum_comparison_exp"]: GraphQLTypes["DeviceType_enum_comparison_exp"];
	/** aggregated selection of "Device" */
["Device_aggregate"]: {
		aggregate?:ModelTypes["Device_aggregate_fields"],
	nodes:ModelTypes["Device"][]
};
	["Device_aggregate_bool_exp"]: GraphQLTypes["Device_aggregate_bool_exp"];
	["Device_aggregate_bool_exp_count"]: GraphQLTypes["Device_aggregate_bool_exp_count"];
	/** aggregate fields of "Device" */
["Device_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["Device_max_fields"],
	min?:ModelTypes["Device_min_fields"]
};
	/** order by aggregate values of table "Device" */
["Device_aggregate_order_by"]: GraphQLTypes["Device_aggregate_order_by"];
	/** input type for inserting array relation for remote table "Device" */
["Device_arr_rel_insert_input"]: GraphQLTypes["Device_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "Device". All fields are combined with a logical 'AND'. */
["Device_bool_exp"]: GraphQLTypes["Device_bool_exp"];
	/** unique or primary key constraints on table "Device" */
["Device_constraint"]: GraphQLTypes["Device_constraint"];
	/** input type for inserting data into table "Device" */
["Device_insert_input"]: GraphQLTypes["Device_insert_input"];
	/** aggregate max on columns */
["Device_max_fields"]: {
		appBuildNumber?:string,
	brand?:string,
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	model?:string,
	osVersion?:string,
	personId?:ModelTypes["uuid"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "Device" */
["Device_max_order_by"]: GraphQLTypes["Device_max_order_by"];
	/** aggregate min on columns */
["Device_min_fields"]: {
		appBuildNumber?:string,
	brand?:string,
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	model?:string,
	osVersion?:string,
	personId?:ModelTypes["uuid"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "Device" */
["Device_min_order_by"]: GraphQLTypes["Device_min_order_by"];
	/** response of any mutation on the table "Device" */
["Device_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Device"][]
};
	/** on_conflict condition type for table "Device" */
["Device_on_conflict"]: GraphQLTypes["Device_on_conflict"];
	/** Ordering options when selecting data from "Device". */
["Device_order_by"]: GraphQLTypes["Device_order_by"];
	/** primary key columns input for table: Device */
["Device_pk_columns_input"]: GraphQLTypes["Device_pk_columns_input"];
	/** select columns of table "Device" */
["Device_select_column"]: GraphQLTypes["Device_select_column"];
	/** input type for updating data in table "Device" */
["Device_set_input"]: GraphQLTypes["Device_set_input"];
	/** Streaming cursor of the table "Device" */
["Device_stream_cursor_input"]: GraphQLTypes["Device_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Device_stream_cursor_value_input"]: GraphQLTypes["Device_stream_cursor_value_input"];
	/** update columns of table "Device" */
["Device_update_column"]: GraphQLTypes["Device_update_column"];
	["Device_updates"]: GraphQLTypes["Device_updates"];
	["EncryptedMessage"]: {
		message:string
};
	/** columns and relationships of "ExternalIdentifier" */
["ExternalIdentifier"]: {
		/** An object relationship */
	Person:ModelTypes["Person"],
	androidIntercomDigest?:string,
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	iosIntercomDigest?:string,
	personId:ModelTypes["uuid"],
	sendbirdToken?:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "ExternalIdentifier". All fields are combined with a logical 'AND'. */
["ExternalIdentifier_bool_exp"]: GraphQLTypes["ExternalIdentifier_bool_exp"];
	/** Ordering options when selecting data from "ExternalIdentifier". */
["ExternalIdentifier_order_by"]: GraphQLTypes["ExternalIdentifier_order_by"];
	/** select columns of table "ExternalIdentifier" */
["ExternalIdentifier_select_column"]: GraphQLTypes["ExternalIdentifier_select_column"];
	/** Streaming cursor of the table "ExternalIdentifier" */
["ExternalIdentifier_stream_cursor_input"]: GraphQLTypes["ExternalIdentifier_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ExternalIdentifier_stream_cursor_value_input"]: GraphQLTypes["ExternalIdentifier_stream_cursor_value_input"];
	/** Definition of a service area */
["GeographicRegion"]: {
		/** An array relationship */
	GeographicRegionHandymen:ModelTypes["GeographicRegionHandyman"][],
	/** An array relationship */
	GeographicRegionNeighborhoods:ModelTypes["GeographicRegionNeighborhood"][],
	/** An array relationship */
	Homes:ModelTypes["Home"][],
	availableStartingAt:ModelTypes["timestamptz"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	name:string,
	servicedZipCodes:string[],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Handymen assigned to a geographic region */
["GeographicRegionHandyman"]: {
		/** An object relationship */
	GeographicRegion:ModelTypes["GeographicRegion"],
	/** An object relationship */
	Handyman:ModelTypes["Person"],
	createdAt:ModelTypes["timestamptz"],
	geographicRegionId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	isPrimary:boolean,
	personId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_aggregate_order_by"]: GraphQLTypes["GeographicRegionHandyman_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
["GeographicRegionHandyman_bool_exp"]: GraphQLTypes["GeographicRegionHandyman_bool_exp"];
	/** order by max() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_max_order_by"]: GraphQLTypes["GeographicRegionHandyman_max_order_by"];
	/** order by min() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_min_order_by"]: GraphQLTypes["GeographicRegionHandyman_min_order_by"];
	/** Ordering options when selecting data from "GeographicRegionHandyman". */
["GeographicRegionHandyman_order_by"]: GraphQLTypes["GeographicRegionHandyman_order_by"];
	/** select columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_select_column"]: GraphQLTypes["GeographicRegionHandyman_select_column"];
	/** Streaming cursor of the table "GeographicRegionHandyman" */
["GeographicRegionHandyman_stream_cursor_input"]: GraphQLTypes["GeographicRegionHandyman_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["GeographicRegionHandyman_stream_cursor_value_input"]: GraphQLTypes["GeographicRegionHandyman_stream_cursor_value_input"];
	/** Separates large population zip codes into regions. Used for first time onboarding. */
["GeographicRegionNeighborhood"]: {
		/** An object relationship */
	GeographicRegion:ModelTypes["GeographicRegion"],
	createdAt:ModelTypes["timestamptz"],
	geographicRegionId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	name:string,
	updatedAt:ModelTypes["timestamptz"],
	zipCode:string
};
	/** order by aggregate values of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_aggregate_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
["GeographicRegionNeighborhood_bool_exp"]: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"];
	/** order by max() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_max_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_max_order_by"];
	/** order by min() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_min_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_min_order_by"];
	/** Ordering options when selecting data from "GeographicRegionNeighborhood". */
["GeographicRegionNeighborhood_order_by"]: GraphQLTypes["GeographicRegionNeighborhood_order_by"];
	/** select columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_select_column"]: GraphQLTypes["GeographicRegionNeighborhood_select_column"];
	/** Streaming cursor of the table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_stream_cursor_input"]: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["GeographicRegionNeighborhood_stream_cursor_value_input"]: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_value_input"];
	/** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
["GeographicRegion_bool_exp"]: GraphQLTypes["GeographicRegion_bool_exp"];
	["GeographicRegion_by_zipCode_args"]: GraphQLTypes["GeographicRegion_by_zipCode_args"];
	/** Ordering options when selecting data from "GeographicRegion". */
["GeographicRegion_order_by"]: GraphQLTypes["GeographicRegion_order_by"];
	/** select columns of table "GeographicRegion" */
["GeographicRegion_select_column"]: GraphQLTypes["GeographicRegion_select_column"];
	/** Streaming cursor of the table "GeographicRegion" */
["GeographicRegion_stream_cursor_input"]: GraphQLTypes["GeographicRegion_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["GeographicRegion_stream_cursor_value_input"]: GraphQLTypes["GeographicRegion_stream_cursor_value_input"];
	/** columns and relationships of "Home" */
["Home"]: {
		/** An array relationship */
	AcceptedReferrals:ModelTypes["Referral"][],
	/** An array relationship */
	AppointmentGrades:ModelTypes["AppointmentGrade"][],
	/** An array relationship */
	Appointments:ModelTypes["Appointment"][],
	/** An aggregate relationship */
	Appointments_aggregate:ModelTypes["Appointment_aggregate"],
	/** An object relationship */
	GeographicRegion?:ModelTypes["GeographicRegion"],
	/** An array relationship */
	HomeInventoryItems:ModelTypes["HomeInventoryItem"][],
	/** An array relationship */
	InvitedReferrals:ModelTypes["Referral"][],
	/** An array relationship */
	PersonHomes:ModelTypes["PersonHome"][],
	/** An object relationship */
	SchedulingRegion?:ModelTypes["Region"],
	/** An array relationship */
	ServiceOfferResponses:ModelTypes["ServiceOfferResponse"][],
	/** An array relationship */
	StripePaymentMethods:ModelTypes["StripePaymentMethod"][],
	/** An array relationship */
	StripeSubscriptions:ModelTypes["StripeSubscription"][],
	/** An array relationship */
	Tasks:ModelTypes["Task"][],
	/** An aggregate relationship */
	Tasks_aggregate:ModelTypes["Task_aggregate"],
	/** If the home is currently an active member of the service */
	activeMember:boolean,
	city:string,
	createdAt:ModelTypes["timestamptz"],
	geographicRegionId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	location?:ModelTypes["geography"],
	referralCode:string,
	schedulingRegionId?:ModelTypes["uuid"],
	sendbirdChannelId?:string,
	state:string,
	status:ModelTypes["HomeStatus_enum"],
	streetAddress:string,
	stripeDefaultSourcePresent:boolean,
	stripeId?:string,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?:string,
	stripePromoCode?:string,
	/** IANA time zone */
	timeZone:string,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?:ModelTypes["date"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?:ModelTypes["date"],
	updatedAt:ModelTypes["timestamptz"],
	wifiCredentialsE?:string,
	zipCode:string
};
	/** columns and relationships of "HomeInventoryItem" */
["HomeInventoryItem"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	/** An array relationship */
	HomeInventoryItemAttachments:ModelTypes["HomeInventoryItemAttachment"][],
	/** An object relationship */
	HomeInventoryItemCategory?:ModelTypes["HomeInventoryItemCategory"],
	createdAt:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	homeInventoryItemCategoryId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	isArchived:boolean,
	lastServicedAt?:ModelTypes["timestamptz"],
	name:string,
	notes?:string,
	serviceFrequency?:number,
	serviceProvider:ModelTypes["HomeInventoryServiceProvider_enum"],
	serviceType:ModelTypes["HomeInventoryServiceType_enum"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** columns and relationships of "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment"]: {
		/** An object relationship */
	Attachment:ModelTypes["Attachment"],
	/** An object relationship */
	HomeInventoryItem:ModelTypes["HomeInventoryItem"],
	/** An object relationship */
	HomeInventoryItemCategoryPrompt?:ModelTypes["HomeInventoryItemCategoryPrompt"],
	attachmentId:ModelTypes["uuid"],
	homeInventoryCategoryPromptId?:ModelTypes["uuid"],
	homeInventoryItemId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	rank:number
};
	/** order by aggregate values of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_aggregate_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_arr_rel_insert_input"]: GraphQLTypes["HomeInventoryItemAttachment_arr_rel_insert_input"];
	/** order by avg() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_avg_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_avg_order_by"];
	/** Boolean expression to filter rows from the table "HomeInventoryItemAttachment". All fields are combined with a logical 'AND'. */
["HomeInventoryItemAttachment_bool_exp"]: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"];
	/** unique or primary key constraints on table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_constraint"]: GraphQLTypes["HomeInventoryItemAttachment_constraint"];
	/** input type for incrementing numeric columns in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_inc_input"]: GraphQLTypes["HomeInventoryItemAttachment_inc_input"];
	/** input type for inserting data into table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_insert_input"]: GraphQLTypes["HomeInventoryItemAttachment_insert_input"];
	/** order by max() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_max_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_max_order_by"];
	/** order by min() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_min_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_min_order_by"];
	/** response of any mutation on the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["HomeInventoryItemAttachment"][]
};
	/** on_conflict condition type for table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_on_conflict"]: GraphQLTypes["HomeInventoryItemAttachment_on_conflict"];
	/** Ordering options when selecting data from "HomeInventoryItemAttachment". */
["HomeInventoryItemAttachment_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_order_by"];
	/** primary key columns input for table: HomeInventoryItemAttachment */
["HomeInventoryItemAttachment_pk_columns_input"]: GraphQLTypes["HomeInventoryItemAttachment_pk_columns_input"];
	/** select columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_select_column"]: GraphQLTypes["HomeInventoryItemAttachment_select_column"];
	/** input type for updating data in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_set_input"]: GraphQLTypes["HomeInventoryItemAttachment_set_input"];
	/** order by stddev() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_stddev_order_by"];
	/** order by stddev_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_pop_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_samp_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_stddev_samp_order_by"];
	/** Streaming cursor of the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stream_cursor_input"]: GraphQLTypes["HomeInventoryItemAttachment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemAttachment_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryItemAttachment_stream_cursor_value_input"];
	/** order by sum() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_sum_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_sum_order_by"];
	/** update columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_update_column"]: GraphQLTypes["HomeInventoryItemAttachment_update_column"];
	["HomeInventoryItemAttachment_updates"]: GraphQLTypes["HomeInventoryItemAttachment_updates"];
	/** order by var_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_pop_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_var_pop_order_by"];
	/** order by var_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_samp_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_var_samp_order_by"];
	/** order by variance() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_variance_order_by"]: GraphQLTypes["HomeInventoryItemAttachment_variance_order_by"];
	/** columns and relationships of "HomeInventoryItemCategory" */
["HomeInventoryItemCategory"]: {
		/** An array relationship */
	HomeInventoryItemCategoryIcons:ModelTypes["HomeInventoryItemCategoryIcon"][],
	/** An array relationship */
	HomeInventoryItemCategoryPrompts:ModelTypes["HomeInventoryItemCategoryPrompt"][],
	/** An array relationship */
	HomeInventoryItems:ModelTypes["HomeInventoryItem"][],
	description?:string,
	id:ModelTypes["uuid"],
	serviceDescription?:string,
	serviceFrequency?:number,
	servicePartName?:string,
	servicePartPartyResponsible?:ModelTypes["TaskPartPartyResponsible_enum"],
	servicePartsRequired:boolean,
	serviceProvider:ModelTypes["HomeInventoryServiceProvider_enum"],
	serviceType:ModelTypes["HomeInventoryServiceType_enum"]
};
	/** columns and relationships of "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon"]: {
		/** An object relationship */
	HomeInventoryItemCategory?:ModelTypes["HomeInventoryItemCategory"],
	/** An object relationship */
	ImageAssetAttachment:ModelTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment:ModelTypes["Attachment"],
	createdAt:ModelTypes["timestamptz"],
	friendlyAssetId:string,
	homeInventoryItemCategoryId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	imageAssetAttachmentId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"],
	vectorAssetAttachmentId:ModelTypes["uuid"]
};
	/** order by aggregate values of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_aggregate_order_by"]: GraphQLTypes["HomeInventoryItemCategoryIcon_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryIcon". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryIcon_bool_exp"]: GraphQLTypes["HomeInventoryItemCategoryIcon_bool_exp"];
	/** order by max() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_max_order_by"]: GraphQLTypes["HomeInventoryItemCategoryIcon_max_order_by"];
	/** order by min() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_min_order_by"]: GraphQLTypes["HomeInventoryItemCategoryIcon_min_order_by"];
	/** Ordering options when selecting data from "HomeInventoryItemCategoryIcon". */
["HomeInventoryItemCategoryIcon_order_by"]: GraphQLTypes["HomeInventoryItemCategoryIcon_order_by"];
	/** select columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_select_column"]: GraphQLTypes["HomeInventoryItemCategoryIcon_select_column"];
	/** Streaming cursor of the table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_stream_cursor_input"]: GraphQLTypes["HomeInventoryItemCategoryIcon_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryIcon_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryItemCategoryIcon_stream_cursor_value_input"];
	/** columns and relationships of "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt"]: {
		/** An object relationship */
	ExampleAttachment:ModelTypes["Attachment"],
	/** An array relationship */
	HomeInventoryItemAttachments:ModelTypes["HomeInventoryItemAttachment"][],
	/** An object relationship */
	HomeInventoryItemCategory?:ModelTypes["HomeInventoryItemCategory"],
	body:string,
	createdAt:ModelTypes["timestamptz"],
	defaultCaption:string,
	exampleAttachmentId:ModelTypes["uuid"],
	friendlyAssetId:string,
	homeInventoryItemCategoryId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	inputHint:string,
	rank:number,
	title:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_aggregate_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_aggregate_order_by"];
	/** order by avg() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_avg_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_avg_order_by"];
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryPrompt". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryPrompt_bool_exp"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"];
	/** order by max() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_max_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_max_order_by"];
	/** order by min() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_min_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_min_order_by"];
	/** Ordering options when selecting data from "HomeInventoryItemCategoryPrompt". */
["HomeInventoryItemCategoryPrompt_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_order_by"];
	/** select columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_select_column"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_select_column"];
	/** order by stddev() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_order_by"];
	/** order by stddev_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"];
	/** Streaming cursor of the table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stream_cursor_input"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"];
	/** order by sum() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_sum_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_sum_order_by"];
	/** order by var_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_pop_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_var_pop_order_by"];
	/** order by var_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_samp_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_var_samp_order_by"];
	/** order by variance() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_variance_order_by"]: GraphQLTypes["HomeInventoryItemCategoryPrompt_variance_order_by"];
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategory". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategory_bool_exp"]: GraphQLTypes["HomeInventoryItemCategory_bool_exp"];
	/** Ordering options when selecting data from "HomeInventoryItemCategory". */
["HomeInventoryItemCategory_order_by"]: GraphQLTypes["HomeInventoryItemCategory_order_by"];
	/** select columns of table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_select_column"]: GraphQLTypes["HomeInventoryItemCategory_select_column"];
	/** Streaming cursor of the table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_stream_cursor_input"]: GraphQLTypes["HomeInventoryItemCategory_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategory_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryItemCategory_stream_cursor_value_input"];
	/** order by aggregate values of table "HomeInventoryItem" */
["HomeInventoryItem_aggregate_order_by"]: GraphQLTypes["HomeInventoryItem_aggregate_order_by"];
	/** order by avg() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_avg_order_by"]: GraphQLTypes["HomeInventoryItem_avg_order_by"];
	/** Boolean expression to filter rows from the table "HomeInventoryItem". All fields are combined with a logical 'AND'. */
["HomeInventoryItem_bool_exp"]: GraphQLTypes["HomeInventoryItem_bool_exp"];
	/** unique or primary key constraints on table "HomeInventoryItem" */
["HomeInventoryItem_constraint"]: GraphQLTypes["HomeInventoryItem_constraint"];
	/** input type for incrementing numeric columns in table "HomeInventoryItem" */
["HomeInventoryItem_inc_input"]: GraphQLTypes["HomeInventoryItem_inc_input"];
	/** input type for inserting data into table "HomeInventoryItem" */
["HomeInventoryItem_insert_input"]: GraphQLTypes["HomeInventoryItem_insert_input"];
	/** order by max() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_max_order_by"]: GraphQLTypes["HomeInventoryItem_max_order_by"];
	/** order by min() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_min_order_by"]: GraphQLTypes["HomeInventoryItem_min_order_by"];
	/** response of any mutation on the table "HomeInventoryItem" */
["HomeInventoryItem_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["HomeInventoryItem"][]
};
	/** input type for inserting object relation for remote table "HomeInventoryItem" */
["HomeInventoryItem_obj_rel_insert_input"]: GraphQLTypes["HomeInventoryItem_obj_rel_insert_input"];
	/** on_conflict condition type for table "HomeInventoryItem" */
["HomeInventoryItem_on_conflict"]: GraphQLTypes["HomeInventoryItem_on_conflict"];
	/** Ordering options when selecting data from "HomeInventoryItem". */
["HomeInventoryItem_order_by"]: GraphQLTypes["HomeInventoryItem_order_by"];
	/** primary key columns input for table: HomeInventoryItem */
["HomeInventoryItem_pk_columns_input"]: GraphQLTypes["HomeInventoryItem_pk_columns_input"];
	/** select columns of table "HomeInventoryItem" */
["HomeInventoryItem_select_column"]: GraphQLTypes["HomeInventoryItem_select_column"];
	/** input type for updating data in table "HomeInventoryItem" */
["HomeInventoryItem_set_input"]: GraphQLTypes["HomeInventoryItem_set_input"];
	/** order by stddev() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_order_by"]: GraphQLTypes["HomeInventoryItem_stddev_order_by"];
	/** order by stddev_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_pop_order_by"]: GraphQLTypes["HomeInventoryItem_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_samp_order_by"]: GraphQLTypes["HomeInventoryItem_stddev_samp_order_by"];
	/** Streaming cursor of the table "HomeInventoryItem" */
["HomeInventoryItem_stream_cursor_input"]: GraphQLTypes["HomeInventoryItem_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItem_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryItem_stream_cursor_value_input"];
	/** order by sum() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_sum_order_by"]: GraphQLTypes["HomeInventoryItem_sum_order_by"];
	/** update columns of table "HomeInventoryItem" */
["HomeInventoryItem_update_column"]: GraphQLTypes["HomeInventoryItem_update_column"];
	["HomeInventoryItem_updates"]: GraphQLTypes["HomeInventoryItem_updates"];
	/** order by var_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_pop_order_by"]: GraphQLTypes["HomeInventoryItem_var_pop_order_by"];
	/** order by var_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_samp_order_by"]: GraphQLTypes["HomeInventoryItem_var_samp_order_by"];
	/** order by variance() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_variance_order_by"]: GraphQLTypes["HomeInventoryItem_variance_order_by"];
	/** columns and relationships of "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule"]: {
		/** An object relationship */
	Home?:ModelTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?:ModelTypes["HomeInventoryItem"],
	dueBy?:ModelTypes["timestamptz"],
	homeId?:ModelTypes["uuid"],
	homeInventoryItemId?:ModelTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryMaintenanceSchedule". All fields are combined with a logical 'AND'. */
["HomeInventoryMaintenanceSchedule_bool_exp"]: GraphQLTypes["HomeInventoryMaintenanceSchedule_bool_exp"];
	/** Ordering options when selecting data from "HomeInventoryMaintenanceSchedule". */
["HomeInventoryMaintenanceSchedule_order_by"]: GraphQLTypes["HomeInventoryMaintenanceSchedule_order_by"];
	/** select columns of table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_select_column"]: GraphQLTypes["HomeInventoryMaintenanceSchedule_select_column"];
	/** Streaming cursor of the table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_stream_cursor_input"]: GraphQLTypes["HomeInventoryMaintenanceSchedule_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"];
	/** Enum of the provider for a HomeInventoryItemCategory or HomeInventoryItem */
["HomeInventoryServiceProvider"]: {
		description:string,
	value:string
};
	/** Boolean expression to filter rows from the table "HomeInventoryServiceProvider". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceProvider_bool_exp"]: GraphQLTypes["HomeInventoryServiceProvider_bool_exp"];
	["HomeInventoryServiceProvider_enum"]: GraphQLTypes["HomeInventoryServiceProvider_enum"];
	/** Boolean expression to compare columns of type "HomeInventoryServiceProvider_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceProvider_enum_comparison_exp"]: GraphQLTypes["HomeInventoryServiceProvider_enum_comparison_exp"];
	/** Ordering options when selecting data from "HomeInventoryServiceProvider". */
["HomeInventoryServiceProvider_order_by"]: GraphQLTypes["HomeInventoryServiceProvider_order_by"];
	/** select columns of table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_select_column"]: GraphQLTypes["HomeInventoryServiceProvider_select_column"];
	/** Streaming cursor of the table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_stream_cursor_input"]: GraphQLTypes["HomeInventoryServiceProvider_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceProvider_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryServiceProvider_stream_cursor_value_input"];
	/** Enum of the type of service which a HomeInventoryItemCategory or HomeInventoryItem need during maintenance */
["HomeInventoryServiceType"]: {
		description:string,
	value:string
};
	/** Boolean expression to filter rows from the table "HomeInventoryServiceType". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceType_bool_exp"]: GraphQLTypes["HomeInventoryServiceType_bool_exp"];
	["HomeInventoryServiceType_enum"]: GraphQLTypes["HomeInventoryServiceType_enum"];
	/** Boolean expression to compare columns of type "HomeInventoryServiceType_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceType_enum_comparison_exp"]: GraphQLTypes["HomeInventoryServiceType_enum_comparison_exp"];
	/** Ordering options when selecting data from "HomeInventoryServiceType". */
["HomeInventoryServiceType_order_by"]: GraphQLTypes["HomeInventoryServiceType_order_by"];
	/** select columns of table "HomeInventoryServiceType" */
["HomeInventoryServiceType_select_column"]: GraphQLTypes["HomeInventoryServiceType_select_column"];
	/** Streaming cursor of the table "HomeInventoryServiceType" */
["HomeInventoryServiceType_stream_cursor_input"]: GraphQLTypes["HomeInventoryServiceType_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceType_stream_cursor_value_input"]: GraphQLTypes["HomeInventoryServiceType_stream_cursor_value_input"];
	["HomeStatus_enum"]: GraphQLTypes["HomeStatus_enum"];
	/** Boolean expression to compare columns of type "HomeStatus_enum". All fields are combined with logical 'AND'. */
["HomeStatus_enum_comparison_exp"]: GraphQLTypes["HomeStatus_enum_comparison_exp"];
	/** columns and relationships of "HomeWaitlist" */
["HomeWaitlist"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	createdAt:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	["HomeWaitlistReason_enum"]: GraphQLTypes["HomeWaitlistReason_enum"];
	/** Boolean expression to filter rows from the table "HomeWaitlist". All fields are combined with a logical 'AND'. */
["HomeWaitlist_bool_exp"]: GraphQLTypes["HomeWaitlist_bool_exp"];
	/** unique or primary key constraints on table "HomeWaitlist" */
["HomeWaitlist_constraint"]: GraphQLTypes["HomeWaitlist_constraint"];
	/** input type for inserting data into table "HomeWaitlist" */
["HomeWaitlist_insert_input"]: GraphQLTypes["HomeWaitlist_insert_input"];
	/** response of any mutation on the table "HomeWaitlist" */
["HomeWaitlist_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["HomeWaitlist"][]
};
	/** on_conflict condition type for table "HomeWaitlist" */
["HomeWaitlist_on_conflict"]: GraphQLTypes["HomeWaitlist_on_conflict"];
	/** Ordering options when selecting data from "HomeWaitlist". */
["HomeWaitlist_order_by"]: GraphQLTypes["HomeWaitlist_order_by"];
	/** select columns of table "HomeWaitlist" */
["HomeWaitlist_select_column"]: GraphQLTypes["HomeWaitlist_select_column"];
	/** Streaming cursor of the table "HomeWaitlist" */
["HomeWaitlist_stream_cursor_input"]: GraphQLTypes["HomeWaitlist_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["HomeWaitlist_stream_cursor_value_input"]: GraphQLTypes["HomeWaitlist_stream_cursor_value_input"];
	/** placeholder for update columns of table "HomeWaitlist" (current role has no relevant permissions) */
["HomeWaitlist_update_column"]: GraphQLTypes["HomeWaitlist_update_column"];
	/** order by aggregate values of table "Home" */
["Home_aggregate_order_by"]: GraphQLTypes["Home_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "Home". All fields are combined with a logical 'AND'. */
["Home_bool_exp"]: GraphQLTypes["Home_bool_exp"];
	/** order by max() on columns of table "Home" */
["Home_max_order_by"]: GraphQLTypes["Home_max_order_by"];
	/** order by min() on columns of table "Home" */
["Home_min_order_by"]: GraphQLTypes["Home_min_order_by"];
	/** response of any mutation on the table "Home" */
["Home_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Home"][]
};
	/** Ordering options when selecting data from "Home". */
["Home_order_by"]: GraphQLTypes["Home_order_by"];
	/** primary key columns input for table: Home */
["Home_pk_columns_input"]: GraphQLTypes["Home_pk_columns_input"];
	/** select columns of table "Home" */
["Home_select_column"]: GraphQLTypes["Home_select_column"];
	/** input type for updating data in table "Home" */
["Home_set_input"]: GraphQLTypes["Home_set_input"];
	/** Streaming cursor of the table "Home" */
["Home_stream_cursor_input"]: GraphQLTypes["Home_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Home_stream_cursor_value_input"]: GraphQLTypes["Home_stream_cursor_value_input"];
	["Home_updates"]: GraphQLTypes["Home_updates"];
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: GraphQLTypes["Int_comparison_exp"];
	/** columns and relationships of "MarketingLead" */
["MarketingLead"]: {
		id:ModelTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
["MarketingLead_bool_exp"]: GraphQLTypes["MarketingLead_bool_exp"];
	/** response of any mutation on the table "MarketingLead" */
["MarketingLead_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["MarketingLead"][]
};
	/** Ordering options when selecting data from "MarketingLead". */
["MarketingLead_order_by"]: GraphQLTypes["MarketingLead_order_by"];
	/** primary key columns input for table: MarketingLead */
["MarketingLead_pk_columns_input"]: GraphQLTypes["MarketingLead_pk_columns_input"];
	/** select columns of table "MarketingLead" */
["MarketingLead_select_column"]: GraphQLTypes["MarketingLead_select_column"];
	/** input type for updating data in table "MarketingLead" */
["MarketingLead_set_input"]: GraphQLTypes["MarketingLead_set_input"];
	/** Streaming cursor of the table "MarketingLead" */
["MarketingLead_stream_cursor_input"]: GraphQLTypes["MarketingLead_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["MarketingLead_stream_cursor_value_input"]: GraphQLTypes["MarketingLead_stream_cursor_value_input"];
	["MarketingLead_updates"]: GraphQLTypes["MarketingLead_updates"];
	/** Service area for one handyman */
["Neighborhood"]: {
		/** An array relationship */
	NeighborhoodRegions:ModelTypes["NeighborhoodRegion"][],
	/** An object relationship */
	Person:ModelTypes["Person"],
	availableStartingAt?:ModelTypes["timestamptz"],
	createdAt:ModelTypes["timestamptz"],
	displayName?:string,
	handymanPersonId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"],
	zipCode:string
};
	/** columns and relationships of "NeighborhoodRegion" */
["NeighborhoodRegion"]: {
		/** An object relationship */
	Neighborhood:ModelTypes["Neighborhood"],
	/** An object relationship */
	Region:ModelTypes["Region"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	neighborhoodId:ModelTypes["uuid"],
	regionId:ModelTypes["uuid"],
	type:ModelTypes["NeighborhoodRegionType_enum"],
	updatedAt:ModelTypes["timestamptz"]
};
	["NeighborhoodRegionType_enum"]: GraphQLTypes["NeighborhoodRegionType_enum"];
	/** Boolean expression to compare columns of type "NeighborhoodRegionType_enum". All fields are combined with logical 'AND'. */
["NeighborhoodRegionType_enum_comparison_exp"]: GraphQLTypes["NeighborhoodRegionType_enum_comparison_exp"];
	/** order by aggregate values of table "NeighborhoodRegion" */
["NeighborhoodRegion_aggregate_order_by"]: GraphQLTypes["NeighborhoodRegion_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "NeighborhoodRegion". All fields are combined with a logical 'AND'. */
["NeighborhoodRegion_bool_exp"]: GraphQLTypes["NeighborhoodRegion_bool_exp"];
	/** order by max() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_max_order_by"]: GraphQLTypes["NeighborhoodRegion_max_order_by"];
	/** order by min() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_min_order_by"]: GraphQLTypes["NeighborhoodRegion_min_order_by"];
	/** Ordering options when selecting data from "NeighborhoodRegion". */
["NeighborhoodRegion_order_by"]: GraphQLTypes["NeighborhoodRegion_order_by"];
	/** select columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_select_column"]: GraphQLTypes["NeighborhoodRegion_select_column"];
	/** Streaming cursor of the table "NeighborhoodRegion" */
["NeighborhoodRegion_stream_cursor_input"]: GraphQLTypes["NeighborhoodRegion_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["NeighborhoodRegion_stream_cursor_value_input"]: GraphQLTypes["NeighborhoodRegion_stream_cursor_value_input"];
	/** order by aggregate values of table "Neighborhood" */
["Neighborhood_aggregate_order_by"]: GraphQLTypes["Neighborhood_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "Neighborhood". All fields are combined with a logical 'AND'. */
["Neighborhood_bool_exp"]: GraphQLTypes["Neighborhood_bool_exp"];
	/** order by max() on columns of table "Neighborhood" */
["Neighborhood_max_order_by"]: GraphQLTypes["Neighborhood_max_order_by"];
	/** order by min() on columns of table "Neighborhood" */
["Neighborhood_min_order_by"]: GraphQLTypes["Neighborhood_min_order_by"];
	/** Ordering options when selecting data from "Neighborhood". */
["Neighborhood_order_by"]: GraphQLTypes["Neighborhood_order_by"];
	/** select columns of table "Neighborhood" */
["Neighborhood_select_column"]: GraphQLTypes["Neighborhood_select_column"];
	/** Streaming cursor of the table "Neighborhood" */
["Neighborhood_stream_cursor_input"]: GraphQLTypes["Neighborhood_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Neighborhood_stream_cursor_value_input"]: GraphQLTypes["Neighborhood_stream_cursor_value_input"];
	/** columns and relationships of "Person" */
["Person"]: {
		/** An array relationship */
	AppointmentGrades:ModelTypes["AppointmentGrade"][],
	/** An array relationship */
	AppointmentProviders:ModelTypes["AppointmentProvider"][],
	/** An aggregate relationship */
	AppointmentProviders_aggregate:ModelTypes["AppointmentProvider_aggregate"],
	/** An array relationship */
	AppointmentReports:ModelTypes["AppointmentReport"][],
	/** An array relationship */
	Availability:ModelTypes["Availability"][],
	/** An array relationship */
	CreatedAppointmentReports:ModelTypes["AppointmentReport"][],
	/** An array relationship */
	CreatedAppointments:ModelTypes["Appointment"][],
	/** An aggregate relationship */
	CreatedAppointments_aggregate:ModelTypes["Appointment_aggregate"],
	/** An array relationship */
	CreatedAttachments:ModelTypes["Attachment"][],
	/** An array relationship */
	CreatedTasks:ModelTypes["Task"][],
	/** An aggregate relationship */
	CreatedTasks_aggregate:ModelTypes["Task_aggregate"],
	/** An array relationship */
	Devices:ModelTypes["Device"][],
	/** An aggregate relationship */
	Devices_aggregate:ModelTypes["Device_aggregate"],
	/** An object relationship */
	ExternalIdentifiers?:ModelTypes["ExternalIdentifier"],
	/** An array relationship */
	Neighborhoods:ModelTypes["Neighborhood"][],
	/** An array relationship */
	PersonBiographyItems:ModelTypes["PersonBiographyItem"][],
	/** An array relationship */
	PersonHomes:ModelTypes["PersonHome"][],
	/** An object relationship */
	PrimaryHome?:ModelTypes["Home"],
	/** An object relationship */
	ProfilePicture?:ModelTypes["Attachment"],
	/** An array relationship */
	TaskComments:ModelTypes["TaskComment"][],
	/** An aggregate relationship */
	TaskComments_aggregate:ModelTypes["TaskComment_aggregate"],
	/** An array relationship */
	TaskUpdateLogs:ModelTypes["TaskUpdateLog"][],
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate:ModelTypes["TaskUpdateLog_aggregate"],
	biography?:string,
	contactPreference?:ModelTypes["PersonContactPreference_enum"],
	/** IANA Time Zone */
	currentTimeZone:string,
	email?:string,
	firstName:string,
	id:ModelTypes["uuid"],
	lastName:string,
	phoneNumber:string,
	type:ModelTypes["PersonType_enum"]
};
	/** columns and relationships of "PersonBiographyItem" */
["PersonBiographyItem"]: {
		/** An object relationship */
	Person:ModelTypes["Person"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	personId:ModelTypes["uuid"],
	rank:number,
	title:string,
	updatedAt:ModelTypes["timestamptz"],
	value:string
};
	/** order by aggregate values of table "PersonBiographyItem" */
["PersonBiographyItem_aggregate_order_by"]: GraphQLTypes["PersonBiographyItem_aggregate_order_by"];
	/** input type for inserting array relation for remote table "PersonBiographyItem" */
["PersonBiographyItem_arr_rel_insert_input"]: GraphQLTypes["PersonBiographyItem_arr_rel_insert_input"];
	/** order by avg() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_avg_order_by"]: GraphQLTypes["PersonBiographyItem_avg_order_by"];
	/** Boolean expression to filter rows from the table "PersonBiographyItem". All fields are combined with a logical 'AND'. */
["PersonBiographyItem_bool_exp"]: GraphQLTypes["PersonBiographyItem_bool_exp"];
	/** unique or primary key constraints on table "PersonBiographyItem" */
["PersonBiographyItem_constraint"]: GraphQLTypes["PersonBiographyItem_constraint"];
	/** input type for incrementing numeric columns in table "PersonBiographyItem" */
["PersonBiographyItem_inc_input"]: GraphQLTypes["PersonBiographyItem_inc_input"];
	/** input type for inserting data into table "PersonBiographyItem" */
["PersonBiographyItem_insert_input"]: GraphQLTypes["PersonBiographyItem_insert_input"];
	/** order by max() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_max_order_by"]: GraphQLTypes["PersonBiographyItem_max_order_by"];
	/** order by min() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_min_order_by"]: GraphQLTypes["PersonBiographyItem_min_order_by"];
	/** response of any mutation on the table "PersonBiographyItem" */
["PersonBiographyItem_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["PersonBiographyItem"][]
};
	/** on_conflict condition type for table "PersonBiographyItem" */
["PersonBiographyItem_on_conflict"]: GraphQLTypes["PersonBiographyItem_on_conflict"];
	/** Ordering options when selecting data from "PersonBiographyItem". */
["PersonBiographyItem_order_by"]: GraphQLTypes["PersonBiographyItem_order_by"];
	/** primary key columns input for table: PersonBiographyItem */
["PersonBiographyItem_pk_columns_input"]: GraphQLTypes["PersonBiographyItem_pk_columns_input"];
	/** select columns of table "PersonBiographyItem" */
["PersonBiographyItem_select_column"]: GraphQLTypes["PersonBiographyItem_select_column"];
	/** input type for updating data in table "PersonBiographyItem" */
["PersonBiographyItem_set_input"]: GraphQLTypes["PersonBiographyItem_set_input"];
	/** order by stddev() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_order_by"]: GraphQLTypes["PersonBiographyItem_stddev_order_by"];
	/** order by stddev_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_pop_order_by"]: GraphQLTypes["PersonBiographyItem_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_samp_order_by"]: GraphQLTypes["PersonBiographyItem_stddev_samp_order_by"];
	/** Streaming cursor of the table "PersonBiographyItem" */
["PersonBiographyItem_stream_cursor_input"]: GraphQLTypes["PersonBiographyItem_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["PersonBiographyItem_stream_cursor_value_input"]: GraphQLTypes["PersonBiographyItem_stream_cursor_value_input"];
	/** order by sum() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_sum_order_by"]: GraphQLTypes["PersonBiographyItem_sum_order_by"];
	/** update columns of table "PersonBiographyItem" */
["PersonBiographyItem_update_column"]: GraphQLTypes["PersonBiographyItem_update_column"];
	["PersonBiographyItem_updates"]: GraphQLTypes["PersonBiographyItem_updates"];
	/** order by var_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_pop_order_by"]: GraphQLTypes["PersonBiographyItem_var_pop_order_by"];
	/** order by var_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_samp_order_by"]: GraphQLTypes["PersonBiographyItem_var_samp_order_by"];
	/** order by variance() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_variance_order_by"]: GraphQLTypes["PersonBiographyItem_variance_order_by"];
	["PersonContactPreference_enum"]: GraphQLTypes["PersonContactPreference_enum"];
	/** Boolean expression to compare columns of type "PersonContactPreference_enum". All fields are combined with logical 'AND'. */
["PersonContactPreference_enum_comparison_exp"]: GraphQLTypes["PersonContactPreference_enum_comparison_exp"];
	/** columns and relationships of "PersonHome" */
["PersonHome"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	/** An object relationship */
	Person:ModelTypes["Person"],
	homeId:ModelTypes["uuid"],
	personId:ModelTypes["uuid"]
};
	/** order by aggregate values of table "PersonHome" */
["PersonHome_aggregate_order_by"]: GraphQLTypes["PersonHome_aggregate_order_by"];
	/** input type for inserting array relation for remote table "PersonHome" */
["PersonHome_arr_rel_insert_input"]: GraphQLTypes["PersonHome_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "PersonHome". All fields are combined with a logical 'AND'. */
["PersonHome_bool_exp"]: GraphQLTypes["PersonHome_bool_exp"];
	/** unique or primary key constraints on table "PersonHome" */
["PersonHome_constraint"]: GraphQLTypes["PersonHome_constraint"];
	/** input type for inserting data into table "PersonHome" */
["PersonHome_insert_input"]: GraphQLTypes["PersonHome_insert_input"];
	/** order by max() on columns of table "PersonHome" */
["PersonHome_max_order_by"]: GraphQLTypes["PersonHome_max_order_by"];
	/** order by min() on columns of table "PersonHome" */
["PersonHome_min_order_by"]: GraphQLTypes["PersonHome_min_order_by"];
	/** response of any mutation on the table "PersonHome" */
["PersonHome_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["PersonHome"][]
};
	/** on_conflict condition type for table "PersonHome" */
["PersonHome_on_conflict"]: GraphQLTypes["PersonHome_on_conflict"];
	/** Ordering options when selecting data from "PersonHome". */
["PersonHome_order_by"]: GraphQLTypes["PersonHome_order_by"];
	/** select columns of table "PersonHome" */
["PersonHome_select_column"]: GraphQLTypes["PersonHome_select_column"];
	/** Streaming cursor of the table "PersonHome" */
["PersonHome_stream_cursor_input"]: GraphQLTypes["PersonHome_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["PersonHome_stream_cursor_value_input"]: GraphQLTypes["PersonHome_stream_cursor_value_input"];
	/** placeholder for update columns of table "PersonHome" (current role has no relevant permissions) */
["PersonHome_update_column"]: GraphQLTypes["PersonHome_update_column"];
	["PersonType_enum"]: GraphQLTypes["PersonType_enum"];
	/** Boolean expression to compare columns of type "PersonType_enum". All fields are combined with logical 'AND'. */
["PersonType_enum_comparison_exp"]: GraphQLTypes["PersonType_enum_comparison_exp"];
	/** aggregated selection of "Person" */
["Person_aggregate"]: {
		aggregate?:ModelTypes["Person_aggregate_fields"],
	nodes:ModelTypes["Person"][]
};
	["Person_aggregate_bool_exp"]: GraphQLTypes["Person_aggregate_bool_exp"];
	["Person_aggregate_bool_exp_count"]: GraphQLTypes["Person_aggregate_bool_exp_count"];
	/** aggregate fields of "Person" */
["Person_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["Person_max_fields"],
	min?:ModelTypes["Person_min_fields"]
};
	/** order by aggregate values of table "Person" */
["Person_aggregate_order_by"]: GraphQLTypes["Person_aggregate_order_by"];
	/** input type for inserting array relation for remote table "Person" */
["Person_arr_rel_insert_input"]: GraphQLTypes["Person_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
["Person_bool_exp"]: GraphQLTypes["Person_bool_exp"];
	/** unique or primary key constraints on table "Person" */
["Person_constraint"]: GraphQLTypes["Person_constraint"];
	/** input type for inserting data into table "Person" */
["Person_insert_input"]: GraphQLTypes["Person_insert_input"];
	/** aggregate max on columns */
["Person_max_fields"]: {
		biography?:string,
	/** IANA Time Zone */
	currentTimeZone?:string,
	email?:string,
	firstName?:string,
	id?:ModelTypes["uuid"],
	lastName?:string,
	phoneNumber?:string
};
	/** order by max() on columns of table "Person" */
["Person_max_order_by"]: GraphQLTypes["Person_max_order_by"];
	/** aggregate min on columns */
["Person_min_fields"]: {
		biography?:string,
	/** IANA Time Zone */
	currentTimeZone?:string,
	email?:string,
	firstName?:string,
	id?:ModelTypes["uuid"],
	lastName?:string,
	phoneNumber?:string
};
	/** order by min() on columns of table "Person" */
["Person_min_order_by"]: GraphQLTypes["Person_min_order_by"];
	/** response of any mutation on the table "Person" */
["Person_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Person"][]
};
	/** input type for inserting object relation for remote table "Person" */
["Person_obj_rel_insert_input"]: GraphQLTypes["Person_obj_rel_insert_input"];
	/** on_conflict condition type for table "Person" */
["Person_on_conflict"]: GraphQLTypes["Person_on_conflict"];
	/** Ordering options when selecting data from "Person". */
["Person_order_by"]: GraphQLTypes["Person_order_by"];
	/** primary key columns input for table: Person */
["Person_pk_columns_input"]: GraphQLTypes["Person_pk_columns_input"];
	/** select columns of table "Person" */
["Person_select_column"]: GraphQLTypes["Person_select_column"];
	/** input type for updating data in table "Person" */
["Person_set_input"]: GraphQLTypes["Person_set_input"];
	/** Streaming cursor of the table "Person" */
["Person_stream_cursor_input"]: GraphQLTypes["Person_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Person_stream_cursor_value_input"]: GraphQLTypes["Person_stream_cursor_value_input"];
	/** update columns of table "Person" */
["Person_update_column"]: GraphQLTypes["Person_update_column"];
	["Person_updates"]: GraphQLTypes["Person_updates"];
	/** Tracks referrals between homes */
["Referral"]: {
		/** An object relationship */
	Accepted:ModelTypes["Home"],
	/** An object relationship */
	Invited:ModelTypes["Home"],
	/** An object relationship */
	ReferralProgram?:ModelTypes["ReferralProgram"],
	acceptedById:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter:number,
	id:ModelTypes["uuid"],
	invitedById:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Currently active and historical referral programs */
["ReferralProgram"]: {
		creditAmount:number,
	id:ModelTypes["uuid"],
	isActive:boolean
};
	/** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
["ReferralProgram_bool_exp"]: GraphQLTypes["ReferralProgram_bool_exp"];
	/** Ordering options when selecting data from "ReferralProgram". */
["ReferralProgram_order_by"]: GraphQLTypes["ReferralProgram_order_by"];
	/** select columns of table "ReferralProgram" */
["ReferralProgram_select_column"]: GraphQLTypes["ReferralProgram_select_column"];
	/** Streaming cursor of the table "ReferralProgram" */
["ReferralProgram_stream_cursor_input"]: GraphQLTypes["ReferralProgram_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ReferralProgram_stream_cursor_value_input"]: GraphQLTypes["ReferralProgram_stream_cursor_value_input"];
	/** order by aggregate values of table "Referral" */
["Referral_aggregate_order_by"]: GraphQLTypes["Referral_aggregate_order_by"];
	/** order by avg() on columns of table "Referral" */
["Referral_avg_order_by"]: GraphQLTypes["Referral_avg_order_by"];
	/** Boolean expression to filter rows from the table "Referral". All fields are combined with a logical 'AND'. */
["Referral_bool_exp"]: GraphQLTypes["Referral_bool_exp"];
	/** unique or primary key constraints on table "Referral" */
["Referral_constraint"]: GraphQLTypes["Referral_constraint"];
	/** input type for inserting data into table "Referral" */
["Referral_insert_input"]: GraphQLTypes["Referral_insert_input"];
	/** order by max() on columns of table "Referral" */
["Referral_max_order_by"]: GraphQLTypes["Referral_max_order_by"];
	/** order by min() on columns of table "Referral" */
["Referral_min_order_by"]: GraphQLTypes["Referral_min_order_by"];
	/** response of any mutation on the table "Referral" */
["Referral_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Referral"][]
};
	/** on_conflict condition type for table "Referral" */
["Referral_on_conflict"]: GraphQLTypes["Referral_on_conflict"];
	/** Ordering options when selecting data from "Referral". */
["Referral_order_by"]: GraphQLTypes["Referral_order_by"];
	/** select columns of table "Referral" */
["Referral_select_column"]: GraphQLTypes["Referral_select_column"];
	/** order by stddev() on columns of table "Referral" */
["Referral_stddev_order_by"]: GraphQLTypes["Referral_stddev_order_by"];
	/** order by stddev_pop() on columns of table "Referral" */
["Referral_stddev_pop_order_by"]: GraphQLTypes["Referral_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "Referral" */
["Referral_stddev_samp_order_by"]: GraphQLTypes["Referral_stddev_samp_order_by"];
	/** Streaming cursor of the table "Referral" */
["Referral_stream_cursor_input"]: GraphQLTypes["Referral_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Referral_stream_cursor_value_input"]: GraphQLTypes["Referral_stream_cursor_value_input"];
	/** order by sum() on columns of table "Referral" */
["Referral_sum_order_by"]: GraphQLTypes["Referral_sum_order_by"];
	/** placeholder for update columns of table "Referral" (current role has no relevant permissions) */
["Referral_update_column"]: GraphQLTypes["Referral_update_column"];
	/** order by var_pop() on columns of table "Referral" */
["Referral_var_pop_order_by"]: GraphQLTypes["Referral_var_pop_order_by"];
	/** order by var_samp() on columns of table "Referral" */
["Referral_var_samp_order_by"]: GraphQLTypes["Referral_var_samp_order_by"];
	/** order by variance() on columns of table "Referral" */
["Referral_variance_order_by"]: GraphQLTypes["Referral_variance_order_by"];
	/** Hierarchical groupings of Neighborhood */
["Region"]: {
		/** An array relationship */
	NeighborhoodRegions:ModelTypes["NeighborhoodRegion"][],
	/** An array relationship */
	SchedulingHomes:ModelTypes["Home"][],
	createdAt:ModelTypes["timestamptz"],
	/** Friendly name for the regions shown in UI */
	displayName:string,
	id:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "Region". All fields are combined with a logical 'AND'. */
["Region_bool_exp"]: GraphQLTypes["Region_bool_exp"];
	/** Ordering options when selecting data from "Region". */
["Region_order_by"]: GraphQLTypes["Region_order_by"];
	/** select columns of table "Region" */
["Region_select_column"]: GraphQLTypes["Region_select_column"];
	/** Streaming cursor of the table "Region" */
["Region_stream_cursor_input"]: GraphQLTypes["Region_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Region_stream_cursor_value_input"]: GraphQLTypes["Region_stream_cursor_value_input"];
	/** Schedule of events offered to members */
["ServiceOffer"]: {
		/** An array relationship */
	ServiceOfferResponses:ModelTypes["ServiceOfferResponse"][],
	/** An object relationship */
	ServiceOfferType:ModelTypes["ServiceOfferType"],
	additionalChargeAmount:number,
	beginsAtDate:ModelTypes["date"],
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?:ModelTypes["timetz"],
	confirmBy:ModelTypes["date"],
	/** Description about the additional charge for the service */
	costExplaination?:string,
	createdAt:ModelTypes["timestamptz"],
	description:string,
	endsAtDate:ModelTypes["date"],
	endsAtTime?:ModelTypes["timetz"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?:number,
	id:ModelTypes["uuid"],
	requiresAdditionalCharge:boolean,
	requiresConfirmation:boolean,
	scheduleBy:ModelTypes["date"],
	title:string,
	type:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** RSVP status of a home to a service offering */
["ServiceOfferResponse"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	/** An object relationship */
	ServiceOffer:ModelTypes["ServiceOffer"],
	/** An object relationship */
	ServiceOfferResponseStatus:ModelTypes["ServiceOfferResponseStatus"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	serviceOfferId:ModelTypes["uuid"],
	status:ModelTypes["ServiceOfferResponseStatus_enum"]
};
	/** columns and relationships of "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus"]: {
		/** An array relationship */
	ServiceOfferResponses:ModelTypes["ServiceOfferResponse"][],
	description?:string,
	value:string
};
	/** Boolean expression to filter rows from the table "ServiceOfferResponseStatus". All fields are combined with a logical 'AND'. */
["ServiceOfferResponseStatus_bool_exp"]: GraphQLTypes["ServiceOfferResponseStatus_bool_exp"];
	["ServiceOfferResponseStatus_enum"]: GraphQLTypes["ServiceOfferResponseStatus_enum"];
	/** Boolean expression to compare columns of type "ServiceOfferResponseStatus_enum". All fields are combined with logical 'AND'. */
["ServiceOfferResponseStatus_enum_comparison_exp"]: GraphQLTypes["ServiceOfferResponseStatus_enum_comparison_exp"];
	/** Ordering options when selecting data from "ServiceOfferResponseStatus". */
["ServiceOfferResponseStatus_order_by"]: GraphQLTypes["ServiceOfferResponseStatus_order_by"];
	/** select columns of table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_select_column"]: GraphQLTypes["ServiceOfferResponseStatus_select_column"];
	/** Streaming cursor of the table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_stream_cursor_input"]: GraphQLTypes["ServiceOfferResponseStatus_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponseStatus_stream_cursor_value_input"]: GraphQLTypes["ServiceOfferResponseStatus_stream_cursor_value_input"];
	/** order by aggregate values of table "ServiceOfferResponse" */
["ServiceOfferResponse_aggregate_order_by"]: GraphQLTypes["ServiceOfferResponse_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "ServiceOfferResponse". All fields are combined with a logical 'AND'. */
["ServiceOfferResponse_bool_exp"]: GraphQLTypes["ServiceOfferResponse_bool_exp"];
	/** unique or primary key constraints on table "ServiceOfferResponse" */
["ServiceOfferResponse_constraint"]: GraphQLTypes["ServiceOfferResponse_constraint"];
	/** input type for inserting data into table "ServiceOfferResponse" */
["ServiceOfferResponse_insert_input"]: GraphQLTypes["ServiceOfferResponse_insert_input"];
	/** order by max() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_max_order_by"]: GraphQLTypes["ServiceOfferResponse_max_order_by"];
	/** order by min() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_min_order_by"]: GraphQLTypes["ServiceOfferResponse_min_order_by"];
	/** response of any mutation on the table "ServiceOfferResponse" */
["ServiceOfferResponse_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["ServiceOfferResponse"][]
};
	/** on_conflict condition type for table "ServiceOfferResponse" */
["ServiceOfferResponse_on_conflict"]: GraphQLTypes["ServiceOfferResponse_on_conflict"];
	/** Ordering options when selecting data from "ServiceOfferResponse". */
["ServiceOfferResponse_order_by"]: GraphQLTypes["ServiceOfferResponse_order_by"];
	/** primary key columns input for table: ServiceOfferResponse */
["ServiceOfferResponse_pk_columns_input"]: GraphQLTypes["ServiceOfferResponse_pk_columns_input"];
	/** select columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_select_column"]: GraphQLTypes["ServiceOfferResponse_select_column"];
	/** input type for updating data in table "ServiceOfferResponse" */
["ServiceOfferResponse_set_input"]: GraphQLTypes["ServiceOfferResponse_set_input"];
	/** Streaming cursor of the table "ServiceOfferResponse" */
["ServiceOfferResponse_stream_cursor_input"]: GraphQLTypes["ServiceOfferResponse_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponse_stream_cursor_value_input"]: GraphQLTypes["ServiceOfferResponse_stream_cursor_value_input"];
	/** update columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_update_column"]: GraphQLTypes["ServiceOfferResponse_update_column"];
	["ServiceOfferResponse_updates"]: GraphQLTypes["ServiceOfferResponse_updates"];
	/** columns and relationships of "ServiceOfferType" */
["ServiceOfferType"]: {
		/** An array relationship */
	ServiceOffers:ModelTypes["ServiceOffer"][],
	description?:string,
	value:string
};
	/** Boolean expression to filter rows from the table "ServiceOfferType". All fields are combined with a logical 'AND'. */
["ServiceOfferType_bool_exp"]: GraphQLTypes["ServiceOfferType_bool_exp"];
	/** Ordering options when selecting data from "ServiceOfferType". */
["ServiceOfferType_order_by"]: GraphQLTypes["ServiceOfferType_order_by"];
	/** select columns of table "ServiceOfferType" */
["ServiceOfferType_select_column"]: GraphQLTypes["ServiceOfferType_select_column"];
	/** Streaming cursor of the table "ServiceOfferType" */
["ServiceOfferType_stream_cursor_input"]: GraphQLTypes["ServiceOfferType_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceOfferType_stream_cursor_value_input"]: GraphQLTypes["ServiceOfferType_stream_cursor_value_input"];
	/** order by aggregate values of table "ServiceOffer" */
["ServiceOffer_aggregate_order_by"]: GraphQLTypes["ServiceOffer_aggregate_order_by"];
	/** order by avg() on columns of table "ServiceOffer" */
["ServiceOffer_avg_order_by"]: GraphQLTypes["ServiceOffer_avg_order_by"];
	/** Boolean expression to filter rows from the table "ServiceOffer". All fields are combined with a logical 'AND'. */
["ServiceOffer_bool_exp"]: GraphQLTypes["ServiceOffer_bool_exp"];
	/** order by max() on columns of table "ServiceOffer" */
["ServiceOffer_max_order_by"]: GraphQLTypes["ServiceOffer_max_order_by"];
	/** order by min() on columns of table "ServiceOffer" */
["ServiceOffer_min_order_by"]: GraphQLTypes["ServiceOffer_min_order_by"];
	/** Ordering options when selecting data from "ServiceOffer". */
["ServiceOffer_order_by"]: GraphQLTypes["ServiceOffer_order_by"];
	/** select columns of table "ServiceOffer" */
["ServiceOffer_select_column"]: GraphQLTypes["ServiceOffer_select_column"];
	/** order by stddev() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_order_by"]: GraphQLTypes["ServiceOffer_stddev_order_by"];
	/** order by stddev_pop() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_pop_order_by"]: GraphQLTypes["ServiceOffer_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_samp_order_by"]: GraphQLTypes["ServiceOffer_stddev_samp_order_by"];
	/** Streaming cursor of the table "ServiceOffer" */
["ServiceOffer_stream_cursor_input"]: GraphQLTypes["ServiceOffer_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceOffer_stream_cursor_value_input"]: GraphQLTypes["ServiceOffer_stream_cursor_value_input"];
	/** order by sum() on columns of table "ServiceOffer" */
["ServiceOffer_sum_order_by"]: GraphQLTypes["ServiceOffer_sum_order_by"];
	/** order by var_pop() on columns of table "ServiceOffer" */
["ServiceOffer_var_pop_order_by"]: GraphQLTypes["ServiceOffer_var_pop_order_by"];
	/** order by var_samp() on columns of table "ServiceOffer" */
["ServiceOffer_var_samp_order_by"]: GraphQLTypes["ServiceOffer_var_samp_order_by"];
	/** order by variance() on columns of table "ServiceOffer" */
["ServiceOffer_variance_order_by"]: GraphQLTypes["ServiceOffer_variance_order_by"];
	/** Organization providing service to a home */
["ServiceProvider"]: {
		createdAt:ModelTypes["timestamptz"],
	email?:string,
	id:ModelTypes["uuid"],
	name:string,
	phoneNumber?:string,
	updatedAt:ModelTypes["timestamptz"]
};
	/** columns and relationships of "ServiceProviderRequest" */
["ServiceProviderRequest"]: {
		/** An object relationship */
	Appointment?:ModelTypes["Appointment"],
	/** An object relationship */
	ServiceProvider?:ModelTypes["ServiceProvider"],
	/** An object relationship */
	Task?:ModelTypes["Task"],
	id:ModelTypes["uuid"],
	stage?:ModelTypes["ServiceProviderRequestStage_enum"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ModelTypes["timestamptz"],
	taskId?:ModelTypes["uuid"]
};
	/** columns and relationships of "ServiceProviderRequestStage" */
["ServiceProviderRequestStage"]: {
		description:string,
	value:string
};
	/** Boolean expression to filter rows from the table "ServiceProviderRequestStage". All fields are combined with a logical 'AND'. */
["ServiceProviderRequestStage_bool_exp"]: GraphQLTypes["ServiceProviderRequestStage_bool_exp"];
	["ServiceProviderRequestStage_enum"]: GraphQLTypes["ServiceProviderRequestStage_enum"];
	/** Boolean expression to compare columns of type "ServiceProviderRequestStage_enum". All fields are combined with logical 'AND'. */
["ServiceProviderRequestStage_enum_comparison_exp"]: GraphQLTypes["ServiceProviderRequestStage_enum_comparison_exp"];
	/** Ordering options when selecting data from "ServiceProviderRequestStage". */
["ServiceProviderRequestStage_order_by"]: GraphQLTypes["ServiceProviderRequestStage_order_by"];
	/** select columns of table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_select_column"]: GraphQLTypes["ServiceProviderRequestStage_select_column"];
	/** Streaming cursor of the table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_stream_cursor_input"]: GraphQLTypes["ServiceProviderRequestStage_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequestStage_stream_cursor_value_input"]: GraphQLTypes["ServiceProviderRequestStage_stream_cursor_value_input"];
	/** order by aggregate values of table "ServiceProviderRequest" */
["ServiceProviderRequest_aggregate_order_by"]: GraphQLTypes["ServiceProviderRequest_aggregate_order_by"];
	/** input type for inserting array relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_arr_rel_insert_input"]: GraphQLTypes["ServiceProviderRequest_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "ServiceProviderRequest". All fields are combined with a logical 'AND'. */
["ServiceProviderRequest_bool_exp"]: GraphQLTypes["ServiceProviderRequest_bool_exp"];
	/** unique or primary key constraints on table "ServiceProviderRequest" */
["ServiceProviderRequest_constraint"]: GraphQLTypes["ServiceProviderRequest_constraint"];
	/** input type for inserting data into table "ServiceProviderRequest" */
["ServiceProviderRequest_insert_input"]: GraphQLTypes["ServiceProviderRequest_insert_input"];
	/** order by max() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_max_order_by"]: GraphQLTypes["ServiceProviderRequest_max_order_by"];
	/** order by min() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_min_order_by"]: GraphQLTypes["ServiceProviderRequest_min_order_by"];
	/** response of any mutation on the table "ServiceProviderRequest" */
["ServiceProviderRequest_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["ServiceProviderRequest"][]
};
	/** input type for inserting object relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_obj_rel_insert_input"]: GraphQLTypes["ServiceProviderRequest_obj_rel_insert_input"];
	/** on_conflict condition type for table "ServiceProviderRequest" */
["ServiceProviderRequest_on_conflict"]: GraphQLTypes["ServiceProviderRequest_on_conflict"];
	/** Ordering options when selecting data from "ServiceProviderRequest". */
["ServiceProviderRequest_order_by"]: GraphQLTypes["ServiceProviderRequest_order_by"];
	/** select columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_select_column"]: GraphQLTypes["ServiceProviderRequest_select_column"];
	/** Streaming cursor of the table "ServiceProviderRequest" */
["ServiceProviderRequest_stream_cursor_input"]: GraphQLTypes["ServiceProviderRequest_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequest_stream_cursor_value_input"]: GraphQLTypes["ServiceProviderRequest_stream_cursor_value_input"];
	/** placeholder for update columns of table "ServiceProviderRequest" (current role has no relevant permissions) */
["ServiceProviderRequest_update_column"]: GraphQLTypes["ServiceProviderRequest_update_column"];
	/** Boolean expression to filter rows from the table "ServiceProvider". All fields are combined with a logical 'AND'. */
["ServiceProvider_bool_exp"]: GraphQLTypes["ServiceProvider_bool_exp"];
	/** Ordering options when selecting data from "ServiceProvider". */
["ServiceProvider_order_by"]: GraphQLTypes["ServiceProvider_order_by"];
	/** select columns of table "ServiceProvider" */
["ServiceProvider_select_column"]: GraphQLTypes["ServiceProvider_select_column"];
	/** Streaming cursor of the table "ServiceProvider" */
["ServiceProvider_stream_cursor_input"]: GraphQLTypes["ServiceProvider_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["ServiceProvider_stream_cursor_value_input"]: GraphQLTypes["ServiceProvider_stream_cursor_value_input"];
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_array_comparison_exp"]: GraphQLTypes["String_array_comparison_exp"];
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: GraphQLTypes["String_comparison_exp"];
	/** Tracks Stripe PaymentMethods for a Home */
["StripePaymentMethod"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	createdAt:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	paymentMethodId:string
};
	/** order by aggregate values of table "StripePaymentMethod" */
["StripePaymentMethod_aggregate_order_by"]: GraphQLTypes["StripePaymentMethod_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "StripePaymentMethod". All fields are combined with a logical 'AND'. */
["StripePaymentMethod_bool_exp"]: GraphQLTypes["StripePaymentMethod_bool_exp"];
	/** order by max() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_max_order_by"]: GraphQLTypes["StripePaymentMethod_max_order_by"];
	/** order by min() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_min_order_by"]: GraphQLTypes["StripePaymentMethod_min_order_by"];
	/** Ordering options when selecting data from "StripePaymentMethod". */
["StripePaymentMethod_order_by"]: GraphQLTypes["StripePaymentMethod_order_by"];
	/** select columns of table "StripePaymentMethod" */
["StripePaymentMethod_select_column"]: GraphQLTypes["StripePaymentMethod_select_column"];
	/** Streaming cursor of the table "StripePaymentMethod" */
["StripePaymentMethod_stream_cursor_input"]: GraphQLTypes["StripePaymentMethod_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["StripePaymentMethod_stream_cursor_value_input"]: GraphQLTypes["StripePaymentMethod_stream_cursor_value_input"];
	/** columns and relationships of "StripeSubscription" */
["StripeSubscription"]: {
		/** An object relationship */
	Home:ModelTypes["Home"],
	canceledAt?:ModelTypes["timestamptz"],
	createdAt?:ModelTypes["timestamptz"],
	homeId:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	interval:string,
	status:ModelTypes["StripeSubscriptionStatus_enum"],
	subscriptionId:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	["StripeSubscriptionStatus_enum"]: GraphQLTypes["StripeSubscriptionStatus_enum"];
	/** Boolean expression to compare columns of type "StripeSubscriptionStatus_enum". All fields are combined with logical 'AND'. */
["StripeSubscriptionStatus_enum_comparison_exp"]: GraphQLTypes["StripeSubscriptionStatus_enum_comparison_exp"];
	/** order by aggregate values of table "StripeSubscription" */
["StripeSubscription_aggregate_order_by"]: GraphQLTypes["StripeSubscription_aggregate_order_by"];
	/** Boolean expression to filter rows from the table "StripeSubscription". All fields are combined with a logical 'AND'. */
["StripeSubscription_bool_exp"]: GraphQLTypes["StripeSubscription_bool_exp"];
	/** order by max() on columns of table "StripeSubscription" */
["StripeSubscription_max_order_by"]: GraphQLTypes["StripeSubscription_max_order_by"];
	/** order by min() on columns of table "StripeSubscription" */
["StripeSubscription_min_order_by"]: GraphQLTypes["StripeSubscription_min_order_by"];
	/** Ordering options when selecting data from "StripeSubscription". */
["StripeSubscription_order_by"]: GraphQLTypes["StripeSubscription_order_by"];
	/** select columns of table "StripeSubscription" */
["StripeSubscription_select_column"]: GraphQLTypes["StripeSubscription_select_column"];
	/** Streaming cursor of the table "StripeSubscription" */
["StripeSubscription_stream_cursor_input"]: GraphQLTypes["StripeSubscription_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["StripeSubscription_stream_cursor_value_input"]: GraphQLTypes["StripeSubscription_stream_cursor_value_input"];
	["Stripe_Coupon"]: {
		amountOff:number,
	duration:ModelTypes["Stripe_Coupon_Duration"],
	durationInMonths?:number,
	id:string,
	name:string,
	percentOff:number
};
	["Stripe_Coupon_Duration"]: GraphQLTypes["Stripe_Coupon_Duration"];
	["Stripe_FindPromoCodeResult"]: {
		coupon?:ModelTypes["Stripe_Coupon"]
};
	["Stripe_Portal"]: {
		url:string
};
	["Stripe_SubscriptionProduct"]: {
		description:string,
	id:string,
	name:string,
	prices:ModelTypes["Stripe_SubscriptionProductPrice"][]
};
	["Stripe_SubscriptionProductPrice"]: {
		/** Pricing amount in cents */
	amount:number,
	id:string,
	interval?:string,
	nickname:string
};
	/** columns and relationships of "Task" */
["Task"]: {
		/** An object relationship */
	Appointment?:ModelTypes["Appointment"],
	/** An array relationship */
	Comments:ModelTypes["TaskComment"][],
	/** An aggregate relationship */
	Comments_aggregate:ModelTypes["TaskComment_aggregate"],
	/** An object relationship */
	CreatedBy:ModelTypes["Person"],
	/** An object relationship */
	Home:ModelTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?:ModelTypes["HomeInventoryItem"],
	/** An object relationship */
	LastUpdatedBy?:ModelTypes["Person"],
	/** An array relationship */
	ServiceProviderRequests:ModelTypes["ServiceProviderRequest"][],
	/** An array relationship */
	TaskAttachments:ModelTypes["TaskAttachment"][],
	/** An array relationship */
	TaskParts:ModelTypes["TaskPart"][],
	/** An array relationship */
	TaskUpdateLogs:ModelTypes["TaskUpdateLog"][],
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate:ModelTypes["TaskUpdateLog_aggregate"],
	appointmentId?:ModelTypes["uuid"],
	completedAt?:ModelTypes["timestamptz"],
	createdAt:ModelTypes["timestamptz"],
	createdById:ModelTypes["uuid"],
	description?:string,
	dueAt?:ModelTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	homeId:ModelTypes["uuid"],
	homeInventoryItemId?:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	lastUpdatedById?:ModelTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?:string,
	parentTaskId?:ModelTypes["uuid"],
	partsRequired:boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel:number,
	requiresOperationalReview:boolean,
	status:ModelTypes["TaskStatus_enum"],
	thirdPartyRequired:boolean,
	title:string,
	type:ModelTypes["TaskType_enum"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** columns and relationships of "TaskAttachment" */
["TaskAttachment"]: {
		/** An object relationship */
	Attachment:ModelTypes["Attachment"],
	/** An object relationship */
	Task:ModelTypes["Task"],
	attachmentId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	rank:number,
	taskId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "TaskAttachment" */
["TaskAttachment_aggregate_order_by"]: GraphQLTypes["TaskAttachment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "TaskAttachment" */
["TaskAttachment_arr_rel_insert_input"]: GraphQLTypes["TaskAttachment_arr_rel_insert_input"];
	/** order by avg() on columns of table "TaskAttachment" */
["TaskAttachment_avg_order_by"]: GraphQLTypes["TaskAttachment_avg_order_by"];
	/** Boolean expression to filter rows from the table "TaskAttachment". All fields are combined with a logical 'AND'. */
["TaskAttachment_bool_exp"]: GraphQLTypes["TaskAttachment_bool_exp"];
	/** unique or primary key constraints on table "TaskAttachment" */
["TaskAttachment_constraint"]: GraphQLTypes["TaskAttachment_constraint"];
	/** input type for incrementing numeric columns in table "TaskAttachment" */
["TaskAttachment_inc_input"]: GraphQLTypes["TaskAttachment_inc_input"];
	/** input type for inserting data into table "TaskAttachment" */
["TaskAttachment_insert_input"]: GraphQLTypes["TaskAttachment_insert_input"];
	/** order by max() on columns of table "TaskAttachment" */
["TaskAttachment_max_order_by"]: GraphQLTypes["TaskAttachment_max_order_by"];
	/** order by min() on columns of table "TaskAttachment" */
["TaskAttachment_min_order_by"]: GraphQLTypes["TaskAttachment_min_order_by"];
	/** response of any mutation on the table "TaskAttachment" */
["TaskAttachment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["TaskAttachment"][]
};
	/** on_conflict condition type for table "TaskAttachment" */
["TaskAttachment_on_conflict"]: GraphQLTypes["TaskAttachment_on_conflict"];
	/** Ordering options when selecting data from "TaskAttachment". */
["TaskAttachment_order_by"]: GraphQLTypes["TaskAttachment_order_by"];
	/** primary key columns input for table: TaskAttachment */
["TaskAttachment_pk_columns_input"]: GraphQLTypes["TaskAttachment_pk_columns_input"];
	/** select columns of table "TaskAttachment" */
["TaskAttachment_select_column"]: GraphQLTypes["TaskAttachment_select_column"];
	/** input type for updating data in table "TaskAttachment" */
["TaskAttachment_set_input"]: GraphQLTypes["TaskAttachment_set_input"];
	/** order by stddev() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_order_by"]: GraphQLTypes["TaskAttachment_stddev_order_by"];
	/** order by stddev_pop() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_pop_order_by"]: GraphQLTypes["TaskAttachment_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_samp_order_by"]: GraphQLTypes["TaskAttachment_stddev_samp_order_by"];
	/** Streaming cursor of the table "TaskAttachment" */
["TaskAttachment_stream_cursor_input"]: GraphQLTypes["TaskAttachment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskAttachment_stream_cursor_value_input"]: GraphQLTypes["TaskAttachment_stream_cursor_value_input"];
	/** order by sum() on columns of table "TaskAttachment" */
["TaskAttachment_sum_order_by"]: GraphQLTypes["TaskAttachment_sum_order_by"];
	/** update columns of table "TaskAttachment" */
["TaskAttachment_update_column"]: GraphQLTypes["TaskAttachment_update_column"];
	["TaskAttachment_updates"]: GraphQLTypes["TaskAttachment_updates"];
	/** order by var_pop() on columns of table "TaskAttachment" */
["TaskAttachment_var_pop_order_by"]: GraphQLTypes["TaskAttachment_var_pop_order_by"];
	/** order by var_samp() on columns of table "TaskAttachment" */
["TaskAttachment_var_samp_order_by"]: GraphQLTypes["TaskAttachment_var_samp_order_by"];
	/** order by variance() on columns of table "TaskAttachment" */
["TaskAttachment_variance_order_by"]: GraphQLTypes["TaskAttachment_variance_order_by"];
	/** columns and relationships of "TaskComment" */
["TaskComment"]: {
		/** An object relationship */
	Person?:ModelTypes["Person"],
	/** An object relationship */
	Task?:ModelTypes["Task"],
	body?:string,
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	taskId?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** aggregated selection of "TaskComment" */
["TaskComment_aggregate"]: {
		aggregate?:ModelTypes["TaskComment_aggregate_fields"],
	nodes:ModelTypes["TaskComment"][]
};
	["TaskComment_aggregate_bool_exp"]: GraphQLTypes["TaskComment_aggregate_bool_exp"];
	["TaskComment_aggregate_bool_exp_count"]: GraphQLTypes["TaskComment_aggregate_bool_exp_count"];
	/** aggregate fields of "TaskComment" */
["TaskComment_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["TaskComment_max_fields"],
	min?:ModelTypes["TaskComment_min_fields"]
};
	/** order by aggregate values of table "TaskComment" */
["TaskComment_aggregate_order_by"]: GraphQLTypes["TaskComment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "TaskComment" */
["TaskComment_arr_rel_insert_input"]: GraphQLTypes["TaskComment_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "TaskComment". All fields are combined with a logical 'AND'. */
["TaskComment_bool_exp"]: GraphQLTypes["TaskComment_bool_exp"];
	/** input type for inserting data into table "TaskComment" */
["TaskComment_insert_input"]: GraphQLTypes["TaskComment_insert_input"];
	/** aggregate max on columns */
["TaskComment_max_fields"]: {
		body?:string,
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	taskId?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "TaskComment" */
["TaskComment_max_order_by"]: GraphQLTypes["TaskComment_max_order_by"];
	/** aggregate min on columns */
["TaskComment_min_fields"]: {
		body?:string,
	createdAt?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	personId?:ModelTypes["uuid"],
	taskId?:ModelTypes["uuid"],
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "TaskComment" */
["TaskComment_min_order_by"]: GraphQLTypes["TaskComment_min_order_by"];
	/** response of any mutation on the table "TaskComment" */
["TaskComment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["TaskComment"][]
};
	/** Ordering options when selecting data from "TaskComment". */
["TaskComment_order_by"]: GraphQLTypes["TaskComment_order_by"];
	/** select columns of table "TaskComment" */
["TaskComment_select_column"]: GraphQLTypes["TaskComment_select_column"];
	/** input type for updating data in table "TaskComment" */
["TaskComment_set_input"]: GraphQLTypes["TaskComment_set_input"];
	/** Streaming cursor of the table "TaskComment" */
["TaskComment_stream_cursor_input"]: GraphQLTypes["TaskComment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskComment_stream_cursor_value_input"]: GraphQLTypes["TaskComment_stream_cursor_value_input"];
	["TaskComment_updates"]: GraphQLTypes["TaskComment_updates"];
	/** Tracks status of parts required for a Task */
["TaskPart"]: {
		/** An object relationship */
	ActiveAgent?:ModelTypes["Person"],
	/** An object relationship */
	LastUpdatedBy?:ModelTypes["Person"],
	/** An object relationship */
	OrderedBy?:ModelTypes["Person"],
	/** An object relationship */
	Task:ModelTypes["Task"],
	createdAt:ModelTypes["timestamptz"],
	estimatedDeliveryBy?:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	/** When member asks to procure part. */
	memberStartedRequestAt?:ModelTypes["timestamptz"],
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?:ModelTypes["timestamptz"],
	/** When the part came into posession by member or operations. */
	partAcquiredAt?:ModelTypes["timestamptz"],
	partDeliveredAt?:ModelTypes["timestamptz"],
	partDeliveryLocation?:ModelTypes["TaskPartDeliveryLocation_enum"],
	partName?:string,
	/** If the part is in the companies or member's posession. */
	partOnHand:boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?:ModelTypes["TaskPartPartyResponsible_enum"],
	/** Internal member who purchased part. */
	personOrderedId?:ModelTypes["uuid"],
	requiresReview:boolean,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?:ModelTypes["timestamptz"],
	status?:ModelTypes["TaskPartStatus_enum"],
	updatedAt:ModelTypes["timestamptz"]
};
	["TaskPartDeliveryLocation_enum"]: GraphQLTypes["TaskPartDeliveryLocation_enum"];
	/** Boolean expression to compare columns of type "TaskPartDeliveryLocation_enum". All fields are combined with logical 'AND'. */
["TaskPartDeliveryLocation_enum_comparison_exp"]: GraphQLTypes["TaskPartDeliveryLocation_enum_comparison_exp"];
	/** columns and relationships of "TaskPartPartyResponsible" */
["TaskPartPartyResponsible"]: {
		description?:string,
	value:string
};
	/** Boolean expression to filter rows from the table "TaskPartPartyResponsible". All fields are combined with a logical 'AND'. */
["TaskPartPartyResponsible_bool_exp"]: GraphQLTypes["TaskPartPartyResponsible_bool_exp"];
	["TaskPartPartyResponsible_enum"]: GraphQLTypes["TaskPartPartyResponsible_enum"];
	/** Boolean expression to compare columns of type "TaskPartPartyResponsible_enum". All fields are combined with logical 'AND'. */
["TaskPartPartyResponsible_enum_comparison_exp"]: GraphQLTypes["TaskPartPartyResponsible_enum_comparison_exp"];
	/** Ordering options when selecting data from "TaskPartPartyResponsible". */
["TaskPartPartyResponsible_order_by"]: GraphQLTypes["TaskPartPartyResponsible_order_by"];
	/** select columns of table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_select_column"]: GraphQLTypes["TaskPartPartyResponsible_select_column"];
	/** Streaming cursor of the table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_stream_cursor_input"]: GraphQLTypes["TaskPartPartyResponsible_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskPartPartyResponsible_stream_cursor_value_input"]: GraphQLTypes["TaskPartPartyResponsible_stream_cursor_value_input"];
	/** columns and relationships of "TaskPartStatus" */
["TaskPartStatus"]: {
		description:string,
	value:string
};
	/** Boolean expression to filter rows from the table "TaskPartStatus". All fields are combined with a logical 'AND'. */
["TaskPartStatus_bool_exp"]: GraphQLTypes["TaskPartStatus_bool_exp"];
	["TaskPartStatus_enum"]: GraphQLTypes["TaskPartStatus_enum"];
	/** Boolean expression to compare columns of type "TaskPartStatus_enum". All fields are combined with logical 'AND'. */
["TaskPartStatus_enum_comparison_exp"]: GraphQLTypes["TaskPartStatus_enum_comparison_exp"];
	/** Ordering options when selecting data from "TaskPartStatus". */
["TaskPartStatus_order_by"]: GraphQLTypes["TaskPartStatus_order_by"];
	/** select columns of table "TaskPartStatus" */
["TaskPartStatus_select_column"]: GraphQLTypes["TaskPartStatus_select_column"];
	/** Streaming cursor of the table "TaskPartStatus" */
["TaskPartStatus_stream_cursor_input"]: GraphQLTypes["TaskPartStatus_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskPartStatus_stream_cursor_value_input"]: GraphQLTypes["TaskPartStatus_stream_cursor_value_input"];
	/** order by aggregate values of table "TaskPart" */
["TaskPart_aggregate_order_by"]: GraphQLTypes["TaskPart_aggregate_order_by"];
	/** input type for inserting array relation for remote table "TaskPart" */
["TaskPart_arr_rel_insert_input"]: GraphQLTypes["TaskPart_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "TaskPart". All fields are combined with a logical 'AND'. */
["TaskPart_bool_exp"]: GraphQLTypes["TaskPart_bool_exp"];
	/** unique or primary key constraints on table "TaskPart" */
["TaskPart_constraint"]: GraphQLTypes["TaskPart_constraint"];
	/** input type for inserting data into table "TaskPart" */
["TaskPart_insert_input"]: GraphQLTypes["TaskPart_insert_input"];
	/** order by max() on columns of table "TaskPart" */
["TaskPart_max_order_by"]: GraphQLTypes["TaskPart_max_order_by"];
	/** order by min() on columns of table "TaskPart" */
["TaskPart_min_order_by"]: GraphQLTypes["TaskPart_min_order_by"];
	/** response of any mutation on the table "TaskPart" */
["TaskPart_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["TaskPart"][]
};
	/** input type for inserting object relation for remote table "TaskPart" */
["TaskPart_obj_rel_insert_input"]: GraphQLTypes["TaskPart_obj_rel_insert_input"];
	/** on_conflict condition type for table "TaskPart" */
["TaskPart_on_conflict"]: GraphQLTypes["TaskPart_on_conflict"];
	/** Ordering options when selecting data from "TaskPart". */
["TaskPart_order_by"]: GraphQLTypes["TaskPart_order_by"];
	/** primary key columns input for table: TaskPart */
["TaskPart_pk_columns_input"]: GraphQLTypes["TaskPart_pk_columns_input"];
	/** select columns of table "TaskPart" */
["TaskPart_select_column"]: GraphQLTypes["TaskPart_select_column"];
	/** input type for updating data in table "TaskPart" */
["TaskPart_set_input"]: GraphQLTypes["TaskPart_set_input"];
	/** Streaming cursor of the table "TaskPart" */
["TaskPart_stream_cursor_input"]: GraphQLTypes["TaskPart_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskPart_stream_cursor_value_input"]: GraphQLTypes["TaskPart_stream_cursor_value_input"];
	/** update columns of table "TaskPart" */
["TaskPart_update_column"]: GraphQLTypes["TaskPart_update_column"];
	["TaskPart_updates"]: GraphQLTypes["TaskPart_updates"];
	["TaskStatus_enum"]: GraphQLTypes["TaskStatus_enum"];
	/** Boolean expression to compare columns of type "TaskStatus_enum". All fields are combined with logical 'AND'. */
["TaskStatus_enum_comparison_exp"]: GraphQLTypes["TaskStatus_enum_comparison_exp"];
	["TaskType_enum"]: GraphQLTypes["TaskType_enum"];
	/** Boolean expression to compare columns of type "TaskType_enum". All fields are combined with logical 'AND'. */
["TaskType_enum_comparison_exp"]: GraphQLTypes["TaskType_enum_comparison_exp"];
	/** Data points at a moment in time about a Task */
["TaskUpdateLog"]: {
		/** An object relationship */
	AppointmentReport?:ModelTypes["AppointmentReport"],
	/** An object relationship */
	Author:ModelTypes["Person"],
	/** An object relationship */
	CreatedBy:ModelTypes["Person"],
	/** An object relationship */
	ServiceProviderRequest?:ModelTypes["ServiceProviderRequest"],
	/** An object relationship */
	Task:ModelTypes["Task"],
	/** An object relationship */
	TaskPart?:ModelTypes["TaskPart"],
	/** An array relationship */
	TaskUpdateLogAttachments:ModelTypes["TaskUpdateLogAttachment"][],
	appointmentReportId?:ModelTypes["uuid"],
	authorId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	createdById:ModelTypes["uuid"],
	id:ModelTypes["uuid"],
	notes?:string,
	/** DEPRECATED */
	partsDescription?:string,
	/** DEPRECATED */
	partsRequired?:boolean,
	ranOutOfTime?:boolean,
	serviceProviderRequestId?:ModelTypes["uuid"],
	status?:ModelTypes["TaskStatus_enum"],
	taskId:ModelTypes["uuid"],
	taskPartId?:ModelTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?:string,
	/** DEPRECATED */
	thirdPartyRequired?:boolean,
	updatedAt:ModelTypes["timestamptz"]
};
	/** Links file uploads to task updates */
["TaskUpdateLogAttachment"]: {
		/** An object relationship */
	Attachment:ModelTypes["Attachment"],
	/** An object relationship */
	TaskUpdateLog:ModelTypes["TaskUpdateLog"],
	attachmentId:ModelTypes["uuid"],
	createdAt:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	rank:number,
	taskUpdateLogId:ModelTypes["uuid"],
	updatedAt:ModelTypes["timestamptz"]
};
	/** order by aggregate values of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_aggregate_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_aggregate_order_by"];
	/** input type for inserting array relation for remote table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_arr_rel_insert_input"]: GraphQLTypes["TaskUpdateLogAttachment_arr_rel_insert_input"];
	/** order by avg() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_avg_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_avg_order_by"];
	/** Boolean expression to filter rows from the table "TaskUpdateLogAttachment". All fields are combined with a logical 'AND'. */
["TaskUpdateLogAttachment_bool_exp"]: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"];
	/** unique or primary key constraints on table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_constraint"]: GraphQLTypes["TaskUpdateLogAttachment_constraint"];
	/** input type for incrementing numeric columns in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_inc_input"]: GraphQLTypes["TaskUpdateLogAttachment_inc_input"];
	/** input type for inserting data into table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_insert_input"]: GraphQLTypes["TaskUpdateLogAttachment_insert_input"];
	/** order by max() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_max_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_max_order_by"];
	/** order by min() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_min_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_min_order_by"];
	/** response of any mutation on the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["TaskUpdateLogAttachment"][]
};
	/** on_conflict condition type for table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_on_conflict"]: GraphQLTypes["TaskUpdateLogAttachment_on_conflict"];
	/** Ordering options when selecting data from "TaskUpdateLogAttachment". */
["TaskUpdateLogAttachment_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_order_by"];
	/** primary key columns input for table: TaskUpdateLogAttachment */
["TaskUpdateLogAttachment_pk_columns_input"]: GraphQLTypes["TaskUpdateLogAttachment_pk_columns_input"];
	/** select columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_select_column"]: GraphQLTypes["TaskUpdateLogAttachment_select_column"];
	/** input type for updating data in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_set_input"]: GraphQLTypes["TaskUpdateLogAttachment_set_input"];
	/** order by stddev() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_stddev_order_by"];
	/** order by stddev_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_pop_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_stddev_pop_order_by"];
	/** order by stddev_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_samp_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_stddev_samp_order_by"];
	/** Streaming cursor of the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stream_cursor_input"]: GraphQLTypes["TaskUpdateLogAttachment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLogAttachment_stream_cursor_value_input"]: GraphQLTypes["TaskUpdateLogAttachment_stream_cursor_value_input"];
	/** order by sum() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_sum_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_sum_order_by"];
	/** update columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_update_column"]: GraphQLTypes["TaskUpdateLogAttachment_update_column"];
	["TaskUpdateLogAttachment_updates"]: GraphQLTypes["TaskUpdateLogAttachment_updates"];
	/** order by var_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_pop_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_var_pop_order_by"];
	/** order by var_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_samp_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_var_samp_order_by"];
	/** order by variance() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_variance_order_by"]: GraphQLTypes["TaskUpdateLogAttachment_variance_order_by"];
	/** aggregated selection of "TaskUpdateLog" */
["TaskUpdateLog_aggregate"]: {
		aggregate?:ModelTypes["TaskUpdateLog_aggregate_fields"],
	nodes:ModelTypes["TaskUpdateLog"][]
};
	["TaskUpdateLog_aggregate_bool_exp"]: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp"];
	["TaskUpdateLog_aggregate_bool_exp_bool_and"]: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_bool_and"];
	["TaskUpdateLog_aggregate_bool_exp_bool_or"]: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_bool_or"];
	["TaskUpdateLog_aggregate_bool_exp_count"]: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_count"];
	/** aggregate fields of "TaskUpdateLog" */
["TaskUpdateLog_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["TaskUpdateLog_max_fields"],
	min?:ModelTypes["TaskUpdateLog_min_fields"]
};
	/** order by aggregate values of table "TaskUpdateLog" */
["TaskUpdateLog_aggregate_order_by"]: GraphQLTypes["TaskUpdateLog_aggregate_order_by"];
	/** input type for inserting array relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_arr_rel_insert_input"]: GraphQLTypes["TaskUpdateLog_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "TaskUpdateLog". All fields are combined with a logical 'AND'. */
["TaskUpdateLog_bool_exp"]: GraphQLTypes["TaskUpdateLog_bool_exp"];
	/** unique or primary key constraints on table "TaskUpdateLog" */
["TaskUpdateLog_constraint"]: GraphQLTypes["TaskUpdateLog_constraint"];
	/** input type for inserting data into table "TaskUpdateLog" */
["TaskUpdateLog_insert_input"]: GraphQLTypes["TaskUpdateLog_insert_input"];
	/** aggregate max on columns */
["TaskUpdateLog_max_fields"]: {
		appointmentReportId?:ModelTypes["uuid"],
	authorId?:ModelTypes["uuid"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	notes?:string,
	/** DEPRECATED */
	partsDescription?:string,
	serviceProviderRequestId?:ModelTypes["uuid"],
	taskId?:ModelTypes["uuid"],
	taskPartId?:ModelTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_max_order_by"]: GraphQLTypes["TaskUpdateLog_max_order_by"];
	/** aggregate min on columns */
["TaskUpdateLog_min_fields"]: {
		appointmentReportId?:ModelTypes["uuid"],
	authorId?:ModelTypes["uuid"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	notes?:string,
	/** DEPRECATED */
	partsDescription?:string,
	serviceProviderRequestId?:ModelTypes["uuid"],
	taskId?:ModelTypes["uuid"],
	taskPartId?:ModelTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_min_order_by"]: GraphQLTypes["TaskUpdateLog_min_order_by"];
	/** response of any mutation on the table "TaskUpdateLog" */
["TaskUpdateLog_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["TaskUpdateLog"][]
};
	/** input type for inserting object relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_obj_rel_insert_input"]: GraphQLTypes["TaskUpdateLog_obj_rel_insert_input"];
	/** on_conflict condition type for table "TaskUpdateLog" */
["TaskUpdateLog_on_conflict"]: GraphQLTypes["TaskUpdateLog_on_conflict"];
	/** Ordering options when selecting data from "TaskUpdateLog". */
["TaskUpdateLog_order_by"]: GraphQLTypes["TaskUpdateLog_order_by"];
	/** primary key columns input for table: TaskUpdateLog */
["TaskUpdateLog_pk_columns_input"]: GraphQLTypes["TaskUpdateLog_pk_columns_input"];
	/** select columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column"]: GraphQLTypes["TaskUpdateLog_select_column"];
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "TaskUpdateLog" */
["TaskUpdateLog_set_input"]: GraphQLTypes["TaskUpdateLog_set_input"];
	/** Streaming cursor of the table "TaskUpdateLog" */
["TaskUpdateLog_stream_cursor_input"]: GraphQLTypes["TaskUpdateLog_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLog_stream_cursor_value_input"]: GraphQLTypes["TaskUpdateLog_stream_cursor_value_input"];
	/** update columns of table "TaskUpdateLog" */
["TaskUpdateLog_update_column"]: GraphQLTypes["TaskUpdateLog_update_column"];
	["TaskUpdateLog_updates"]: GraphQLTypes["TaskUpdateLog_updates"];
	/** aggregated selection of "Task" */
["Task_aggregate"]: {
		aggregate?:ModelTypes["Task_aggregate_fields"],
	nodes:ModelTypes["Task"][]
};
	["Task_aggregate_bool_exp"]: GraphQLTypes["Task_aggregate_bool_exp"];
	["Task_aggregate_bool_exp_bool_and"]: GraphQLTypes["Task_aggregate_bool_exp_bool_and"];
	["Task_aggregate_bool_exp_bool_or"]: GraphQLTypes["Task_aggregate_bool_exp_bool_or"];
	["Task_aggregate_bool_exp_count"]: GraphQLTypes["Task_aggregate_bool_exp_count"];
	/** aggregate fields of "Task" */
["Task_aggregate_fields"]: {
		avg?:ModelTypes["Task_avg_fields"],
	count:number,
	max?:ModelTypes["Task_max_fields"],
	min?:ModelTypes["Task_min_fields"],
	stddev?:ModelTypes["Task_stddev_fields"],
	stddev_pop?:ModelTypes["Task_stddev_pop_fields"],
	stddev_samp?:ModelTypes["Task_stddev_samp_fields"],
	sum?:ModelTypes["Task_sum_fields"],
	var_pop?:ModelTypes["Task_var_pop_fields"],
	var_samp?:ModelTypes["Task_var_samp_fields"],
	variance?:ModelTypes["Task_variance_fields"]
};
	/** order by aggregate values of table "Task" */
["Task_aggregate_order_by"]: GraphQLTypes["Task_aggregate_order_by"];
	/** input type for inserting array relation for remote table "Task" */
["Task_arr_rel_insert_input"]: GraphQLTypes["Task_arr_rel_insert_input"];
	/** aggregate avg on columns */
["Task_avg_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by avg() on columns of table "Task" */
["Task_avg_order_by"]: GraphQLTypes["Task_avg_order_by"];
	/** Boolean expression to filter rows from the table "Task". All fields are combined with a logical 'AND'. */
["Task_bool_exp"]: GraphQLTypes["Task_bool_exp"];
	/** unique or primary key constraints on table "Task" */
["Task_constraint"]: GraphQLTypes["Task_constraint"];
	/** input type for incrementing numeric columns in table "Task" */
["Task_inc_input"]: GraphQLTypes["Task_inc_input"];
	/** input type for inserting data into table "Task" */
["Task_insert_input"]: GraphQLTypes["Task_insert_input"];
	/** aggregate max on columns */
["Task_max_fields"]: {
		appointmentId?:ModelTypes["uuid"],
	completedAt?:ModelTypes["timestamptz"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	description?:string,
	dueAt?:ModelTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	homeId?:ModelTypes["uuid"],
	homeInventoryItemId?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	lastUpdatedById?:ModelTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?:string,
	parentTaskId?:ModelTypes["uuid"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number,
	title?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "Task" */
["Task_max_order_by"]: GraphQLTypes["Task_max_order_by"];
	/** aggregate min on columns */
["Task_min_fields"]: {
		appointmentId?:ModelTypes["uuid"],
	completedAt?:ModelTypes["timestamptz"],
	createdAt?:ModelTypes["timestamptz"],
	createdById?:ModelTypes["uuid"],
	description?:string,
	dueAt?:ModelTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	homeId?:ModelTypes["uuid"],
	homeInventoryItemId?:ModelTypes["uuid"],
	id?:ModelTypes["uuid"],
	lastUpdatedById?:ModelTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?:string,
	parentTaskId?:ModelTypes["uuid"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number,
	title?:string,
	updatedAt?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "Task" */
["Task_min_order_by"]: GraphQLTypes["Task_min_order_by"];
	/** response of any mutation on the table "Task" */
["Task_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["Task"][]
};
	/** input type for inserting object relation for remote table "Task" */
["Task_obj_rel_insert_input"]: GraphQLTypes["Task_obj_rel_insert_input"];
	/** on_conflict condition type for table "Task" */
["Task_on_conflict"]: GraphQLTypes["Task_on_conflict"];
	/** Ordering options when selecting data from "Task". */
["Task_order_by"]: GraphQLTypes["Task_order_by"];
	/** primary key columns input for table: Task */
["Task_pk_columns_input"]: GraphQLTypes["Task_pk_columns_input"];
	/** select columns of table "Task" */
["Task_select_column"]: GraphQLTypes["Task_select_column"];
	/** select "Task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "Task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "Task" */
["Task_set_input"]: GraphQLTypes["Task_set_input"];
	/** aggregate stddev on columns */
["Task_stddev_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by stddev() on columns of table "Task" */
["Task_stddev_order_by"]: GraphQLTypes["Task_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["Task_stddev_pop_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by stddev_pop() on columns of table "Task" */
["Task_stddev_pop_order_by"]: GraphQLTypes["Task_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["Task_stddev_samp_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by stddev_samp() on columns of table "Task" */
["Task_stddev_samp_order_by"]: GraphQLTypes["Task_stddev_samp_order_by"];
	/** Streaming cursor of the table "Task" */
["Task_stream_cursor_input"]: GraphQLTypes["Task_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["Task_stream_cursor_value_input"]: GraphQLTypes["Task_stream_cursor_value_input"];
	/** aggregate sum on columns */
["Task_sum_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by sum() on columns of table "Task" */
["Task_sum_order_by"]: GraphQLTypes["Task_sum_order_by"];
	/** update columns of table "Task" */
["Task_update_column"]: GraphQLTypes["Task_update_column"];
	["Task_updates"]: GraphQLTypes["Task_updates"];
	/** aggregate var_pop on columns */
["Task_var_pop_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by var_pop() on columns of table "Task" */
["Task_var_pop_order_by"]: GraphQLTypes["Task_var_pop_order_by"];
	/** aggregate var_samp on columns */
["Task_var_samp_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by var_samp() on columns of table "Task" */
["Task_var_samp_order_by"]: GraphQLTypes["Task_var_samp_order_by"];
	/** aggregate variance on columns */
["Task_variance_fields"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?:number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?:number
};
	/** order by variance() on columns of table "Task" */
["Task_variance_order_by"]: GraphQLTypes["Task_variance_order_by"];
	/** columns and relationships of "TimeZone" */
["TimeZone"]: {
		abbrev?:string,
	is_dst?:boolean,
	name?:string,
	utc_offset?:ModelTypes["interval"]
};
	/** Boolean expression to filter rows from the table "TimeZone". All fields are combined with a logical 'AND'. */
["TimeZone_bool_exp"]: GraphQLTypes["TimeZone_bool_exp"];
	/** Ordering options when selecting data from "TimeZone". */
["TimeZone_order_by"]: GraphQLTypes["TimeZone_order_by"];
	/** select columns of table "TimeZone" */
["TimeZone_select_column"]: GraphQLTypes["TimeZone_select_column"];
	/** Streaming cursor of the table "TimeZone" */
["TimeZone_stream_cursor_input"]: GraphQLTypes["TimeZone_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["TimeZone_stream_cursor_value_input"]: GraphQLTypes["TimeZone_stream_cursor_value_input"];
	["create_Stripe_SetupIntent_output"]: {
		ephemeralKey:string,
	publishableKey:string,
	setupIntentClientSecret:string
};
	["create_Stripe_SubscriptionIntent_input"]: GraphQLTypes["create_Stripe_SubscriptionIntent_input"];
	["create_Stripe_SubscriptionIntent_output"]: {
		ephemeralKey?:string,
	paymentIntentClientSecret?:string,
	publishableKey:string
};
	/** ordering argument of a cursor */
["cursor_ordering"]: GraphQLTypes["cursor_ordering"];
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: GraphQLTypes["date_comparison_exp"];
	["find_Person_by_phone_args"]: GraphQLTypes["find_Person_by_phone_args"];
	/** columns and relationships of "find_Person_response" */
["find_Person_response"]: {
		present?:boolean
};
	/** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
["find_Person_response_bool_exp"]: GraphQLTypes["find_Person_response_bool_exp"];
	/** Ordering options when selecting data from "find_Person_response". */
["find_Person_response_order_by"]: GraphQLTypes["find_Person_response_order_by"];
	/** select columns of table "find_Person_response" */
["find_Person_response_select_column"]: GraphQLTypes["find_Person_response_select_column"];
	/** Streaming cursor of the table "find_Person_response" */
["find_Person_response_stream_cursor_input"]: GraphQLTypes["find_Person_response_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["find_Person_response_stream_cursor_value_input"]: GraphQLTypes["find_Person_response_stream_cursor_value_input"];
	["geography"]:any;
	["geography_cast_exp"]: GraphQLTypes["geography_cast_exp"];
	/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
["geography_comparison_exp"]: GraphQLTypes["geography_comparison_exp"];
	["geometry"]:any;
	["geometry_cast_exp"]: GraphQLTypes["geometry_cast_exp"];
	/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
["geometry_comparison_exp"]: GraphQLTypes["geometry_comparison_exp"];
	["interval"]:any;
	/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
["interval_comparison_exp"]: GraphQLTypes["interval_comparison_exp"];
	["jsonb"]:any;
	["jsonb_cast_exp"]: GraphQLTypes["jsonb_cast_exp"];
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: GraphQLTypes["jsonb_comparison_exp"];
	/** mutation root */
["mutation_root"]: {
		create_Stripe_Portal?:ModelTypes["Stripe_Portal"],
	/** create_Stripe_SetupIntent */
	create_Stripe_SetupIntent:ModelTypes["create_Stripe_SetupIntent_output"],
	/** Creates a Stripe PaymentIntent for a payment subscription */
	create_Stripe_SubscriptionIntent:ModelTypes["create_Stripe_SubscriptionIntent_output"],
	/** delete data from the table: "Appointment" */
	delete_Appointment?:ModelTypes["Appointment_mutation_response"],
	/** delete data from the table: "AppointmentProvider" */
	delete_AppointmentProvider?:ModelTypes["AppointmentProvider_mutation_response"],
	/** delete single row from the table: "AppointmentProvider" */
	delete_AppointmentProvider_by_pk?:ModelTypes["AppointmentProvider"],
	/** delete single row from the table: "Appointment" */
	delete_Appointment_by_pk?:ModelTypes["Appointment"],
	/** delete data from the table: "Attachment" */
	delete_Attachment?:ModelTypes["Attachment_mutation_response"],
	/** delete single row from the table: "Attachment" */
	delete_Attachment_by_pk?:ModelTypes["Attachment"],
	/** delete data from the table: "HomeInventoryItemAttachment" */
	delete_HomeInventoryItemAttachment?:ModelTypes["HomeInventoryItemAttachment_mutation_response"],
	/** delete single row from the table: "HomeInventoryItemAttachment" */
	delete_HomeInventoryItemAttachment_by_pk?:ModelTypes["HomeInventoryItemAttachment"],
	/** delete data from the table: "Task" */
	delete_Task?:ModelTypes["Task_mutation_response"],
	/** delete data from the table: "TaskAttachment" */
	delete_TaskAttachment?:ModelTypes["TaskAttachment_mutation_response"],
	/** delete single row from the table: "TaskAttachment" */
	delete_TaskAttachment_by_pk?:ModelTypes["TaskAttachment"],
	/** delete single row from the table: "Task" */
	delete_Task_by_pk?:ModelTypes["Task"],
	/** encryptMessageHomeowner */
	encryptDataHomeowner?:ModelTypes["EncryptedMessage"],
	/** insert data into the table: "Appointment" */
	insert_Appointment?:ModelTypes["Appointment_mutation_response"],
	/** insert data into the table: "AppointmentGrade" */
	insert_AppointmentGrade?:ModelTypes["AppointmentGrade_mutation_response"],
	/** insert data into the table: "AppointmentGradeSelection" */
	insert_AppointmentGradeSelection?:ModelTypes["AppointmentGradeSelection_mutation_response"],
	/** insert a single row into the table: "AppointmentGradeSelection" */
	insert_AppointmentGradeSelection_one?:ModelTypes["AppointmentGradeSelection"],
	/** insert a single row into the table: "AppointmentGrade" */
	insert_AppointmentGrade_one?:ModelTypes["AppointmentGrade"],
	/** insert data into the table: "AppointmentProvider" */
	insert_AppointmentProvider?:ModelTypes["AppointmentProvider_mutation_response"],
	/** insert a single row into the table: "AppointmentProvider" */
	insert_AppointmentProvider_one?:ModelTypes["AppointmentProvider"],
	/** insert a single row into the table: "Appointment" */
	insert_Appointment_one?:ModelTypes["Appointment"],
	/** insert data into the table: "Attachment" */
	insert_Attachment?:ModelTypes["Attachment_mutation_response"],
	/** insert a single row into the table: "Attachment" */
	insert_Attachment_one?:ModelTypes["Attachment"],
	/** insert data into the table: "AvailabilityResult" */
	insert_AvailabilityResult?:ModelTypes["AvailabilityResult_mutation_response"],
	/** insert a single row into the table: "AvailabilityResult" */
	insert_AvailabilityResult_one?:ModelTypes["AvailabilityResult"],
	/** insert data into the table: "DataDeletionRequest" */
	insert_DataDeletionRequest?:ModelTypes["DataDeletionRequest_mutation_response"],
	/** insert a single row into the table: "DataDeletionRequest" */
	insert_DataDeletionRequest_one?:ModelTypes["DataDeletionRequest"],
	/** insert data into the table: "Device" */
	insert_Device?:ModelTypes["Device_mutation_response"],
	/** insert a single row into the table: "Device" */
	insert_Device_one?:ModelTypes["Device"],
	/** insert data into the table: "HomeInventoryItem" */
	insert_HomeInventoryItem?:ModelTypes["HomeInventoryItem_mutation_response"],
	/** insert data into the table: "HomeInventoryItemAttachment" */
	insert_HomeInventoryItemAttachment?:ModelTypes["HomeInventoryItemAttachment_mutation_response"],
	/** insert a single row into the table: "HomeInventoryItemAttachment" */
	insert_HomeInventoryItemAttachment_one?:ModelTypes["HomeInventoryItemAttachment"],
	/** insert a single row into the table: "HomeInventoryItem" */
	insert_HomeInventoryItem_one?:ModelTypes["HomeInventoryItem"],
	/** insert data into the table: "HomeWaitlist" */
	insert_HomeWaitlist?:ModelTypes["HomeWaitlist_mutation_response"],
	/** insert a single row into the table: "HomeWaitlist" */
	insert_HomeWaitlist_one?:ModelTypes["HomeWaitlist"],
	/** insert data into the table: "Person" */
	insert_Person?:ModelTypes["Person_mutation_response"],
	/** insert data into the table: "PersonBiographyItem" */
	insert_PersonBiographyItem?:ModelTypes["PersonBiographyItem_mutation_response"],
	/** insert a single row into the table: "PersonBiographyItem" */
	insert_PersonBiographyItem_one?:ModelTypes["PersonBiographyItem"],
	/** insert data into the table: "PersonHome" */
	insert_PersonHome?:ModelTypes["PersonHome_mutation_response"],
	/** insert a single row into the table: "PersonHome" */
	insert_PersonHome_one?:ModelTypes["PersonHome"],
	/** insert a single row into the table: "Person" */
	insert_Person_one?:ModelTypes["Person"],
	/** insert data into the table: "Referral" */
	insert_Referral?:ModelTypes["Referral_mutation_response"],
	/** insert a single row into the table: "Referral" */
	insert_Referral_one?:ModelTypes["Referral"],
	/** insert data into the table: "ServiceOfferResponse" */
	insert_ServiceOfferResponse?:ModelTypes["ServiceOfferResponse_mutation_response"],
	/** insert a single row into the table: "ServiceOfferResponse" */
	insert_ServiceOfferResponse_one?:ModelTypes["ServiceOfferResponse"],
	/** insert data into the table: "ServiceProviderRequest" */
	insert_ServiceProviderRequest?:ModelTypes["ServiceProviderRequest_mutation_response"],
	/** insert a single row into the table: "ServiceProviderRequest" */
	insert_ServiceProviderRequest_one?:ModelTypes["ServiceProviderRequest"],
	/** insert data into the table: "Task" */
	insert_Task?:ModelTypes["Task_mutation_response"],
	/** insert data into the table: "TaskAttachment" */
	insert_TaskAttachment?:ModelTypes["TaskAttachment_mutation_response"],
	/** insert a single row into the table: "TaskAttachment" */
	insert_TaskAttachment_one?:ModelTypes["TaskAttachment"],
	/** insert data into the table: "TaskComment" */
	insert_TaskComment?:ModelTypes["TaskComment_mutation_response"],
	/** insert a single row into the table: "TaskComment" */
	insert_TaskComment_one?:ModelTypes["TaskComment"],
	/** insert data into the table: "TaskPart" */
	insert_TaskPart?:ModelTypes["TaskPart_mutation_response"],
	/** insert a single row into the table: "TaskPart" */
	insert_TaskPart_one?:ModelTypes["TaskPart"],
	/** insert data into the table: "TaskUpdateLog" */
	insert_TaskUpdateLog?:ModelTypes["TaskUpdateLog_mutation_response"],
	/** insert data into the table: "TaskUpdateLogAttachment" */
	insert_TaskUpdateLogAttachment?:ModelTypes["TaskUpdateLogAttachment_mutation_response"],
	/** insert a single row into the table: "TaskUpdateLogAttachment" */
	insert_TaskUpdateLogAttachment_one?:ModelTypes["TaskUpdateLogAttachment"],
	/** insert a single row into the table: "TaskUpdateLog" */
	insert_TaskUpdateLog_one?:ModelTypes["TaskUpdateLog"],
	/** insert a single row into the table: "Task" */
	insert_Task_one?:ModelTypes["Task"],
	/** update data of the table: "Appointment" */
	update_Appointment?:ModelTypes["Appointment_mutation_response"],
	/** update single row of the table: "Appointment" */
	update_Appointment_by_pk?:ModelTypes["Appointment"],
	/** update multiples rows of table: "Appointment" */
	update_Appointment_many?:(ModelTypes["Appointment_mutation_response"] | undefined)[],
	/** update data of the table: "Attachment" */
	update_Attachment?:ModelTypes["Attachment_mutation_response"],
	/** update single row of the table: "Attachment" */
	update_Attachment_by_pk?:ModelTypes["Attachment"],
	/** update multiples rows of table: "Attachment" */
	update_Attachment_many?:(ModelTypes["Attachment_mutation_response"] | undefined)[],
	/** update data of the table: "AvailabilityResult" */
	update_AvailabilityResult?:ModelTypes["AvailabilityResult_mutation_response"],
	/** update multiples rows of table: "AvailabilityResult" */
	update_AvailabilityResult_many?:(ModelTypes["AvailabilityResult_mutation_response"] | undefined)[],
	/** update data of the table: "Device" */
	update_Device?:ModelTypes["Device_mutation_response"],
	/** update single row of the table: "Device" */
	update_Device_by_pk?:ModelTypes["Device"],
	/** update multiples rows of table: "Device" */
	update_Device_many?:(ModelTypes["Device_mutation_response"] | undefined)[],
	/** update data of the table: "Home" */
	update_Home?:ModelTypes["Home_mutation_response"],
	/** update data of the table: "HomeInventoryItem" */
	update_HomeInventoryItem?:ModelTypes["HomeInventoryItem_mutation_response"],
	/** update data of the table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment?:ModelTypes["HomeInventoryItemAttachment_mutation_response"],
	/** update single row of the table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment_by_pk?:ModelTypes["HomeInventoryItemAttachment"],
	/** update multiples rows of table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment_many?:(ModelTypes["HomeInventoryItemAttachment_mutation_response"] | undefined)[],
	/** update single row of the table: "HomeInventoryItem" */
	update_HomeInventoryItem_by_pk?:ModelTypes["HomeInventoryItem"],
	/** update multiples rows of table: "HomeInventoryItem" */
	update_HomeInventoryItem_many?:(ModelTypes["HomeInventoryItem_mutation_response"] | undefined)[],
	/** update single row of the table: "Home" */
	update_Home_by_pk?:ModelTypes["Home"],
	/** update multiples rows of table: "Home" */
	update_Home_many?:(ModelTypes["Home_mutation_response"] | undefined)[],
	/** update data of the table: "MarketingLead" */
	update_MarketingLead?:ModelTypes["MarketingLead_mutation_response"],
	/** update single row of the table: "MarketingLead" */
	update_MarketingLead_by_pk?:ModelTypes["MarketingLead"],
	/** update multiples rows of table: "MarketingLead" */
	update_MarketingLead_many?:(ModelTypes["MarketingLead_mutation_response"] | undefined)[],
	/** update data of the table: "Person" */
	update_Person?:ModelTypes["Person_mutation_response"],
	/** update data of the table: "PersonBiographyItem" */
	update_PersonBiographyItem?:ModelTypes["PersonBiographyItem_mutation_response"],
	/** update single row of the table: "PersonBiographyItem" */
	update_PersonBiographyItem_by_pk?:ModelTypes["PersonBiographyItem"],
	/** update multiples rows of table: "PersonBiographyItem" */
	update_PersonBiographyItem_many?:(ModelTypes["PersonBiographyItem_mutation_response"] | undefined)[],
	/** update single row of the table: "Person" */
	update_Person_by_pk?:ModelTypes["Person"],
	/** update multiples rows of table: "Person" */
	update_Person_many?:(ModelTypes["Person_mutation_response"] | undefined)[],
	/** update data of the table: "ServiceOfferResponse" */
	update_ServiceOfferResponse?:ModelTypes["ServiceOfferResponse_mutation_response"],
	/** update single row of the table: "ServiceOfferResponse" */
	update_ServiceOfferResponse_by_pk?:ModelTypes["ServiceOfferResponse"],
	/** update multiples rows of table: "ServiceOfferResponse" */
	update_ServiceOfferResponse_many?:(ModelTypes["ServiceOfferResponse_mutation_response"] | undefined)[],
	/** update data of the table: "Task" */
	update_Task?:ModelTypes["Task_mutation_response"],
	/** update data of the table: "TaskAttachment" */
	update_TaskAttachment?:ModelTypes["TaskAttachment_mutation_response"],
	/** update single row of the table: "TaskAttachment" */
	update_TaskAttachment_by_pk?:ModelTypes["TaskAttachment"],
	/** update multiples rows of table: "TaskAttachment" */
	update_TaskAttachment_many?:(ModelTypes["TaskAttachment_mutation_response"] | undefined)[],
	/** update data of the table: "TaskComment" */
	update_TaskComment?:ModelTypes["TaskComment_mutation_response"],
	/** update multiples rows of table: "TaskComment" */
	update_TaskComment_many?:(ModelTypes["TaskComment_mutation_response"] | undefined)[],
	/** update data of the table: "TaskPart" */
	update_TaskPart?:ModelTypes["TaskPart_mutation_response"],
	/** update single row of the table: "TaskPart" */
	update_TaskPart_by_pk?:ModelTypes["TaskPart"],
	/** update multiples rows of table: "TaskPart" */
	update_TaskPart_many?:(ModelTypes["TaskPart_mutation_response"] | undefined)[],
	/** update data of the table: "TaskUpdateLog" */
	update_TaskUpdateLog?:ModelTypes["TaskUpdateLog_mutation_response"],
	/** update data of the table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment?:ModelTypes["TaskUpdateLogAttachment_mutation_response"],
	/** update single row of the table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment_by_pk?:ModelTypes["TaskUpdateLogAttachment"],
	/** update multiples rows of table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment_many?:(ModelTypes["TaskUpdateLogAttachment_mutation_response"] | undefined)[],
	/** update single row of the table: "TaskUpdateLog" */
	update_TaskUpdateLog_by_pk?:ModelTypes["TaskUpdateLog"],
	/** update multiples rows of table: "TaskUpdateLog" */
	update_TaskUpdateLog_many?:(ModelTypes["TaskUpdateLog_mutation_response"] | undefined)[],
	/** update single row of the table: "Task" */
	update_Task_by_pk?:ModelTypes["Task"],
	/** update multiples rows of table: "Task" */
	update_Task_many?:(ModelTypes["Task_mutation_response"] | undefined)[]
};
	/** column ordering options */
["order_by"]: GraphQLTypes["order_by"];
	["query_root"]: {
		/** fetch data from the table: "Appointment" */
	Appointment:ModelTypes["Appointment"][],
	/** fetch data from the table: "AppointmentGrade" */
	AppointmentGrade:ModelTypes["AppointmentGrade"][],
	/** fetch data from the table: "AppointmentGradeSelection" */
	AppointmentGradeSelection:ModelTypes["AppointmentGradeSelection"][],
	/** fetch data from the table: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory:ModelTypes["AppointmentGradeSelectionCategory"][],
	/** fetch data from the table: "AppointmentGradeSelectionCategory" using primary key columns */
	AppointmentGradeSelectionCategory_by_pk?:ModelTypes["AppointmentGradeSelectionCategory"],
	/** fetch data from the table: "AppointmentGradeSelection" using primary key columns */
	AppointmentGradeSelection_by_pk?:ModelTypes["AppointmentGradeSelection"],
	/** fetch data from the table: "AppointmentGrade" using primary key columns */
	AppointmentGrade_by_pk?:ModelTypes["AppointmentGrade"],
	/** fetch data from the table: "AppointmentProvider" */
	AppointmentProvider:ModelTypes["AppointmentProvider"][],
	/** fetch aggregated fields from the table: "AppointmentProvider" */
	AppointmentProvider_aggregate:ModelTypes["AppointmentProvider_aggregate"],
	/** fetch data from the table: "AppointmentProvider" using primary key columns */
	AppointmentProvider_by_pk?:ModelTypes["AppointmentProvider"],
	/** fetch data from the table: "AppointmentReport" */
	AppointmentReport:ModelTypes["AppointmentReport"][],
	/** fetch data from the table: "AppointmentReport" using primary key columns */
	AppointmentReport_by_pk?:ModelTypes["AppointmentReport"],
	/** fetch aggregated fields from the table: "Appointment" */
	Appointment_aggregate:ModelTypes["Appointment_aggregate"],
	/** fetch data from the table: "Appointment" using primary key columns */
	Appointment_by_pk?:ModelTypes["Appointment"],
	/** fetch data from the table: "Attachment" */
	Attachment:ModelTypes["Attachment"][],
	/** fetch data from the table: "Attachment" using primary key columns */
	Attachment_by_pk?:ModelTypes["Attachment"],
	/** An array relationship */
	Availability:ModelTypes["Availability"][],
	/** fetch data from the table: "AvailabilityResult" */
	AvailabilityResult:ModelTypes["AvailabilityResult"][],
	/** fetch data from the table: "AvailableAppointment" */
	AvailableAppointment:ModelTypes["AvailableAppointment"][],
	/** fetch data from the table: "ClientRelease" */
	ClientRelease:ModelTypes["ClientRelease"][],
	/** fetch data from the table: "ClientRelease" using primary key columns */
	ClientRelease_by_pk?:ModelTypes["ClientRelease"],
	/** fetch data from the table: "ClientType" */
	ClientType:ModelTypes["ClientType"][],
	/** fetch data from the table: "ClientType" using primary key columns */
	ClientType_by_pk?:ModelTypes["ClientType"],
	/** fetch data from the table: "ContentBlock" */
	ContentBlock:ModelTypes["ContentBlock"][],
	/** fetch data from the table: "ContentBlock" using primary key columns */
	ContentBlock_by_pk?:ModelTypes["ContentBlock"],
	/** fetch data from the table: "DataDeletionRequest" */
	DataDeletionRequest:ModelTypes["DataDeletionRequest"][],
	/** fetch data from the table: "DataDeletionRequest" using primary key columns */
	DataDeletionRequest_by_pk?:ModelTypes["DataDeletionRequest"],
	/** fetch data from the table: "Device" */
	Device:ModelTypes["Device"][],
	/** fetch aggregated fields from the table: "Device" */
	Device_aggregate:ModelTypes["Device_aggregate"],
	/** fetch data from the table: "Device" using primary key columns */
	Device_by_pk?:ModelTypes["Device"],
	/** fetch data from the table: "ExternalIdentifier" */
	ExternalIdentifier:ModelTypes["ExternalIdentifier"][],
	/** fetch data from the table: "ExternalIdentifier" using primary key columns */
	ExternalIdentifier_by_pk?:ModelTypes["ExternalIdentifier"],
	/** fetch data from the table: "GeographicRegion" */
	GeographicRegion:ModelTypes["GeographicRegion"][],
	/** fetch data from the table: "GeographicRegionHandyman" */
	GeographicRegionHandyman:ModelTypes["GeographicRegionHandyman"][],
	/** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
	GeographicRegionHandyman_by_pk?:ModelTypes["GeographicRegionHandyman"],
	/** fetch data from the table: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood:ModelTypes["GeographicRegionNeighborhood"][],
	/** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
	GeographicRegionNeighborhood_by_pk?:ModelTypes["GeographicRegionNeighborhood"],
	/** fetch data from the table: "GeographicRegion" using primary key columns */
	GeographicRegion_by_pk?:ModelTypes["GeographicRegion"],
	/** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
	GeographicRegion_by_zipCode:ModelTypes["GeographicRegion"][],
	/** fetch data from the table: "Home" */
	Home:ModelTypes["Home"][],
	/** fetch data from the table: "HomeInventoryItem" */
	HomeInventoryItem:ModelTypes["HomeInventoryItem"][],
	/** fetch data from the table: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment:ModelTypes["HomeInventoryItemAttachment"][],
	/** fetch data from the table: "HomeInventoryItemAttachment" using primary key columns */
	HomeInventoryItemAttachment_by_pk?:ModelTypes["HomeInventoryItemAttachment"],
	/** fetch data from the table: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory:ModelTypes["HomeInventoryItemCategory"][],
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon:ModelTypes["HomeInventoryItemCategoryIcon"][],
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" using primary key columns */
	HomeInventoryItemCategoryIcon_by_pk?:ModelTypes["HomeInventoryItemCategoryIcon"],
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt:ModelTypes["HomeInventoryItemCategoryPrompt"][],
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" using primary key columns */
	HomeInventoryItemCategoryPrompt_by_pk?:ModelTypes["HomeInventoryItemCategoryPrompt"],
	/** fetch data from the table: "HomeInventoryItemCategory" using primary key columns */
	HomeInventoryItemCategory_by_pk?:ModelTypes["HomeInventoryItemCategory"],
	/** fetch data from the table: "HomeInventoryItem" using primary key columns */
	HomeInventoryItem_by_pk?:ModelTypes["HomeInventoryItem"],
	/** fetch data from the table: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule:ModelTypes["HomeInventoryMaintenanceSchedule"][],
	/** fetch data from the table: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider:ModelTypes["HomeInventoryServiceProvider"][],
	/** fetch data from the table: "HomeInventoryServiceProvider" using primary key columns */
	HomeInventoryServiceProvider_by_pk?:ModelTypes["HomeInventoryServiceProvider"],
	/** fetch data from the table: "HomeInventoryServiceType" */
	HomeInventoryServiceType:ModelTypes["HomeInventoryServiceType"][],
	/** fetch data from the table: "HomeInventoryServiceType" using primary key columns */
	HomeInventoryServiceType_by_pk?:ModelTypes["HomeInventoryServiceType"],
	/** fetch data from the table: "HomeWaitlist" */
	HomeWaitlist:ModelTypes["HomeWaitlist"][],
	/** fetch data from the table: "HomeWaitlist" using primary key columns */
	HomeWaitlist_by_pk?:ModelTypes["HomeWaitlist"],
	/** fetch data from the table: "Home" using primary key columns */
	Home_by_pk?:ModelTypes["Home"],
	/** fetch data from the table: "MarketingLead" */
	MarketingLead:ModelTypes["MarketingLead"][],
	/** fetch data from the table: "MarketingLead" using primary key columns */
	MarketingLead_by_pk?:ModelTypes["MarketingLead"],
	/** fetch data from the table: "Neighborhood" */
	Neighborhood:ModelTypes["Neighborhood"][],
	/** fetch data from the table: "NeighborhoodRegion" */
	NeighborhoodRegion:ModelTypes["NeighborhoodRegion"][],
	/** fetch data from the table: "NeighborhoodRegion" using primary key columns */
	NeighborhoodRegion_by_pk?:ModelTypes["NeighborhoodRegion"],
	/** fetch data from the table: "Neighborhood" using primary key columns */
	Neighborhood_by_pk?:ModelTypes["Neighborhood"],
	/** fetch data from the table: "Person" */
	Person:ModelTypes["Person"][],
	/** fetch data from the table: "PersonBiographyItem" */
	PersonBiographyItem:ModelTypes["PersonBiographyItem"][],
	/** fetch data from the table: "PersonBiographyItem" using primary key columns */
	PersonBiographyItem_by_pk?:ModelTypes["PersonBiographyItem"],
	/** fetch data from the table: "PersonHome" */
	PersonHome:ModelTypes["PersonHome"][],
	/** fetch aggregated fields from the table: "Person" */
	Person_aggregate:ModelTypes["Person_aggregate"],
	/** fetch data from the table: "Person" using primary key columns */
	Person_by_pk?:ModelTypes["Person"],
	/** fetch data from the table: "Referral" */
	Referral:ModelTypes["Referral"][],
	/** fetch data from the table: "ReferralProgram" */
	ReferralProgram:ModelTypes["ReferralProgram"][],
	/** fetch data from the table: "ReferralProgram" using primary key columns */
	ReferralProgram_by_pk?:ModelTypes["ReferralProgram"],
	/** fetch data from the table: "Referral" using primary key columns */
	Referral_by_pk?:ModelTypes["Referral"],
	/** fetch data from the table: "Region" */
	Region:ModelTypes["Region"][],
	/** fetch data from the table: "Region" using primary key columns */
	Region_by_pk?:ModelTypes["Region"],
	/** fetch data from the table: "ServiceOffer" */
	ServiceOffer:ModelTypes["ServiceOffer"][],
	/** fetch data from the table: "ServiceOfferResponse" */
	ServiceOfferResponse:ModelTypes["ServiceOfferResponse"][],
	/** fetch data from the table: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus:ModelTypes["ServiceOfferResponseStatus"][],
	/** fetch data from the table: "ServiceOfferResponseStatus" using primary key columns */
	ServiceOfferResponseStatus_by_pk?:ModelTypes["ServiceOfferResponseStatus"],
	/** fetch data from the table: "ServiceOfferResponse" using primary key columns */
	ServiceOfferResponse_by_pk?:ModelTypes["ServiceOfferResponse"],
	/** fetch data from the table: "ServiceOfferType" */
	ServiceOfferType:ModelTypes["ServiceOfferType"][],
	/** fetch data from the table: "ServiceOfferType" using primary key columns */
	ServiceOfferType_by_pk?:ModelTypes["ServiceOfferType"],
	/** fetch data from the table: "ServiceOffer" using primary key columns */
	ServiceOffer_by_pk?:ModelTypes["ServiceOffer"],
	/** fetch data from the table: "ServiceProvider" */
	ServiceProvider:ModelTypes["ServiceProvider"][],
	/** fetch data from the table: "ServiceProviderRequest" */
	ServiceProviderRequest:ModelTypes["ServiceProviderRequest"][],
	/** fetch data from the table: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage:ModelTypes["ServiceProviderRequestStage"][],
	/** fetch data from the table: "ServiceProviderRequestStage" using primary key columns */
	ServiceProviderRequestStage_by_pk?:ModelTypes["ServiceProviderRequestStage"],
	/** fetch data from the table: "ServiceProviderRequest" using primary key columns */
	ServiceProviderRequest_by_pk?:ModelTypes["ServiceProviderRequest"],
	/** fetch data from the table: "ServiceProvider" using primary key columns */
	ServiceProvider_by_pk?:ModelTypes["ServiceProvider"],
	/** fetch data from the table: "StripePaymentMethod" */
	StripePaymentMethod:ModelTypes["StripePaymentMethod"][],
	/** fetch data from the table: "StripePaymentMethod" using primary key columns */
	StripePaymentMethod_by_pk?:ModelTypes["StripePaymentMethod"],
	/** fetch data from the table: "StripeSubscription" */
	StripeSubscription:ModelTypes["StripeSubscription"][],
	/** fetch data from the table: "StripeSubscription" using primary key columns */
	StripeSubscription_by_pk?:ModelTypes["StripeSubscription"],
	/** fetch data from the table: "Task" */
	Task:ModelTypes["Task"][],
	/** fetch data from the table: "TaskAttachment" */
	TaskAttachment:ModelTypes["TaskAttachment"][],
	/** fetch data from the table: "TaskAttachment" using primary key columns */
	TaskAttachment_by_pk?:ModelTypes["TaskAttachment"],
	/** fetch data from the table: "TaskComment" */
	TaskComment:ModelTypes["TaskComment"][],
	/** fetch aggregated fields from the table: "TaskComment" */
	TaskComment_aggregate:ModelTypes["TaskComment_aggregate"],
	/** fetch data from the table: "TaskPart" */
	TaskPart:ModelTypes["TaskPart"][],
	/** fetch data from the table: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible:ModelTypes["TaskPartPartyResponsible"][],
	/** fetch data from the table: "TaskPartPartyResponsible" using primary key columns */
	TaskPartPartyResponsible_by_pk?:ModelTypes["TaskPartPartyResponsible"],
	/** fetch data from the table: "TaskPartStatus" */
	TaskPartStatus:ModelTypes["TaskPartStatus"][],
	/** fetch data from the table: "TaskPartStatus" using primary key columns */
	TaskPartStatus_by_pk?:ModelTypes["TaskPartStatus"],
	/** fetch data from the table: "TaskPart" using primary key columns */
	TaskPart_by_pk?:ModelTypes["TaskPart"],
	/** fetch data from the table: "TaskUpdateLog" */
	TaskUpdateLog:ModelTypes["TaskUpdateLog"][],
	/** fetch data from the table: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment:ModelTypes["TaskUpdateLogAttachment"][],
	/** fetch data from the table: "TaskUpdateLogAttachment" using primary key columns */
	TaskUpdateLogAttachment_by_pk?:ModelTypes["TaskUpdateLogAttachment"],
	/** fetch aggregated fields from the table: "TaskUpdateLog" */
	TaskUpdateLog_aggregate:ModelTypes["TaskUpdateLog_aggregate"],
	/** fetch data from the table: "TaskUpdateLog" using primary key columns */
	TaskUpdateLog_by_pk?:ModelTypes["TaskUpdateLog"],
	/** fetch aggregated fields from the table: "Task" */
	Task_aggregate:ModelTypes["Task_aggregate"],
	/** fetch data from the table: "Task" using primary key columns */
	Task_by_pk?:ModelTypes["Task"],
	/** fetch data from the table: "TimeZone" */
	TimeZone:ModelTypes["TimeZone"][],
	check_Stripe_PromoCode:ModelTypes["Stripe_FindPromoCodeResult"],
	/** decryptMessageHomeowner */
	decryptMessageHomeowner?:ModelTypes["DecryptedMessage"],
	/** execute function "find_Person_by_phone" which returns "find_Person_response" */
	find_Person_by_phone?:ModelTypes["find_Person_response"],
	/** fetch data from the table: "find_Person_response" */
	find_Person_response:ModelTypes["find_Person_response"][],
	/** find_Stripe_SubscriptionProducts */
	find_Stripe_SubscriptionProducts:ModelTypes["Stripe_SubscriptionProduct"][]
};
	["smallint"]:any;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: GraphQLTypes["smallint_comparison_exp"];
	["st_d_within_geography_input"]: GraphQLTypes["st_d_within_geography_input"];
	["st_d_within_input"]: GraphQLTypes["st_d_within_input"];
	["subscription_root"]: {
		/** fetch data from the table: "Appointment" */
	Appointment:ModelTypes["Appointment"][],
	/** fetch data from the table: "AppointmentGrade" */
	AppointmentGrade:ModelTypes["AppointmentGrade"][],
	/** fetch data from the table: "AppointmentGradeSelection" */
	AppointmentGradeSelection:ModelTypes["AppointmentGradeSelection"][],
	/** fetch data from the table: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory:ModelTypes["AppointmentGradeSelectionCategory"][],
	/** fetch data from the table: "AppointmentGradeSelectionCategory" using primary key columns */
	AppointmentGradeSelectionCategory_by_pk?:ModelTypes["AppointmentGradeSelectionCategory"],
	/** fetch data from the table in a streaming manner: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory_stream:ModelTypes["AppointmentGradeSelectionCategory"][],
	/** fetch data from the table: "AppointmentGradeSelection" using primary key columns */
	AppointmentGradeSelection_by_pk?:ModelTypes["AppointmentGradeSelection"],
	/** fetch data from the table in a streaming manner: "AppointmentGradeSelection" */
	AppointmentGradeSelection_stream:ModelTypes["AppointmentGradeSelection"][],
	/** fetch data from the table: "AppointmentGrade" using primary key columns */
	AppointmentGrade_by_pk?:ModelTypes["AppointmentGrade"],
	/** fetch data from the table in a streaming manner: "AppointmentGrade" */
	AppointmentGrade_stream:ModelTypes["AppointmentGrade"][],
	/** fetch data from the table: "AppointmentProvider" */
	AppointmentProvider:ModelTypes["AppointmentProvider"][],
	/** fetch aggregated fields from the table: "AppointmentProvider" */
	AppointmentProvider_aggregate:ModelTypes["AppointmentProvider_aggregate"],
	/** fetch data from the table: "AppointmentProvider" using primary key columns */
	AppointmentProvider_by_pk?:ModelTypes["AppointmentProvider"],
	/** fetch data from the table in a streaming manner: "AppointmentProvider" */
	AppointmentProvider_stream:ModelTypes["AppointmentProvider"][],
	/** fetch data from the table: "AppointmentReport" */
	AppointmentReport:ModelTypes["AppointmentReport"][],
	/** fetch data from the table: "AppointmentReport" using primary key columns */
	AppointmentReport_by_pk?:ModelTypes["AppointmentReport"],
	/** fetch data from the table in a streaming manner: "AppointmentReport" */
	AppointmentReport_stream:ModelTypes["AppointmentReport"][],
	/** fetch aggregated fields from the table: "Appointment" */
	Appointment_aggregate:ModelTypes["Appointment_aggregate"],
	/** fetch data from the table: "Appointment" using primary key columns */
	Appointment_by_pk?:ModelTypes["Appointment"],
	/** fetch data from the table in a streaming manner: "Appointment" */
	Appointment_stream:ModelTypes["Appointment"][],
	/** fetch data from the table: "Attachment" */
	Attachment:ModelTypes["Attachment"][],
	/** fetch data from the table: "Attachment" using primary key columns */
	Attachment_by_pk?:ModelTypes["Attachment"],
	/** fetch data from the table in a streaming manner: "Attachment" */
	Attachment_stream:ModelTypes["Attachment"][],
	/** An array relationship */
	Availability:ModelTypes["Availability"][],
	/** fetch data from the table: "AvailabilityResult" */
	AvailabilityResult:ModelTypes["AvailabilityResult"][],
	/** fetch data from the table in a streaming manner: "AvailabilityResult" */
	AvailabilityResult_stream:ModelTypes["AvailabilityResult"][],
	/** fetch data from the table in a streaming manner: "Availability" */
	Availability_stream:ModelTypes["Availability"][],
	/** fetch data from the table: "AvailableAppointment" */
	AvailableAppointment:ModelTypes["AvailableAppointment"][],
	/** fetch data from the table in a streaming manner: "AvailableAppointment" */
	AvailableAppointment_stream:ModelTypes["AvailableAppointment"][],
	/** fetch data from the table: "ClientRelease" */
	ClientRelease:ModelTypes["ClientRelease"][],
	/** fetch data from the table: "ClientRelease" using primary key columns */
	ClientRelease_by_pk?:ModelTypes["ClientRelease"],
	/** fetch data from the table in a streaming manner: "ClientRelease" */
	ClientRelease_stream:ModelTypes["ClientRelease"][],
	/** fetch data from the table: "ClientType" */
	ClientType:ModelTypes["ClientType"][],
	/** fetch data from the table: "ClientType" using primary key columns */
	ClientType_by_pk?:ModelTypes["ClientType"],
	/** fetch data from the table in a streaming manner: "ClientType" */
	ClientType_stream:ModelTypes["ClientType"][],
	/** fetch data from the table: "ContentBlock" */
	ContentBlock:ModelTypes["ContentBlock"][],
	/** fetch data from the table: "ContentBlock" using primary key columns */
	ContentBlock_by_pk?:ModelTypes["ContentBlock"],
	/** fetch data from the table in a streaming manner: "ContentBlock" */
	ContentBlock_stream:ModelTypes["ContentBlock"][],
	/** fetch data from the table: "DataDeletionRequest" */
	DataDeletionRequest:ModelTypes["DataDeletionRequest"][],
	/** fetch data from the table: "DataDeletionRequest" using primary key columns */
	DataDeletionRequest_by_pk?:ModelTypes["DataDeletionRequest"],
	/** fetch data from the table in a streaming manner: "DataDeletionRequest" */
	DataDeletionRequest_stream:ModelTypes["DataDeletionRequest"][],
	/** fetch data from the table: "Device" */
	Device:ModelTypes["Device"][],
	/** fetch aggregated fields from the table: "Device" */
	Device_aggregate:ModelTypes["Device_aggregate"],
	/** fetch data from the table: "Device" using primary key columns */
	Device_by_pk?:ModelTypes["Device"],
	/** fetch data from the table in a streaming manner: "Device" */
	Device_stream:ModelTypes["Device"][],
	/** fetch data from the table: "ExternalIdentifier" */
	ExternalIdentifier:ModelTypes["ExternalIdentifier"][],
	/** fetch data from the table: "ExternalIdentifier" using primary key columns */
	ExternalIdentifier_by_pk?:ModelTypes["ExternalIdentifier"],
	/** fetch data from the table in a streaming manner: "ExternalIdentifier" */
	ExternalIdentifier_stream:ModelTypes["ExternalIdentifier"][],
	/** fetch data from the table: "GeographicRegion" */
	GeographicRegion:ModelTypes["GeographicRegion"][],
	/** fetch data from the table: "GeographicRegionHandyman" */
	GeographicRegionHandyman:ModelTypes["GeographicRegionHandyman"][],
	/** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
	GeographicRegionHandyman_by_pk?:ModelTypes["GeographicRegionHandyman"],
	/** fetch data from the table in a streaming manner: "GeographicRegionHandyman" */
	GeographicRegionHandyman_stream:ModelTypes["GeographicRegionHandyman"][],
	/** fetch data from the table: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood:ModelTypes["GeographicRegionNeighborhood"][],
	/** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
	GeographicRegionNeighborhood_by_pk?:ModelTypes["GeographicRegionNeighborhood"],
	/** fetch data from the table in a streaming manner: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood_stream:ModelTypes["GeographicRegionNeighborhood"][],
	/** fetch data from the table: "GeographicRegion" using primary key columns */
	GeographicRegion_by_pk?:ModelTypes["GeographicRegion"],
	/** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
	GeographicRegion_by_zipCode:ModelTypes["GeographicRegion"][],
	/** fetch data from the table in a streaming manner: "GeographicRegion" */
	GeographicRegion_stream:ModelTypes["GeographicRegion"][],
	/** fetch data from the table: "Home" */
	Home:ModelTypes["Home"][],
	/** fetch data from the table: "HomeInventoryItem" */
	HomeInventoryItem:ModelTypes["HomeInventoryItem"][],
	/** fetch data from the table: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment:ModelTypes["HomeInventoryItemAttachment"][],
	/** fetch data from the table: "HomeInventoryItemAttachment" using primary key columns */
	HomeInventoryItemAttachment_by_pk?:ModelTypes["HomeInventoryItemAttachment"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment_stream:ModelTypes["HomeInventoryItemAttachment"][],
	/** fetch data from the table: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory:ModelTypes["HomeInventoryItemCategory"][],
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon:ModelTypes["HomeInventoryItemCategoryIcon"][],
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" using primary key columns */
	HomeInventoryItemCategoryIcon_by_pk?:ModelTypes["HomeInventoryItemCategoryIcon"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon_stream:ModelTypes["HomeInventoryItemCategoryIcon"][],
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt:ModelTypes["HomeInventoryItemCategoryPrompt"][],
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" using primary key columns */
	HomeInventoryItemCategoryPrompt_by_pk?:ModelTypes["HomeInventoryItemCategoryPrompt"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt_stream:ModelTypes["HomeInventoryItemCategoryPrompt"][],
	/** fetch data from the table: "HomeInventoryItemCategory" using primary key columns */
	HomeInventoryItemCategory_by_pk?:ModelTypes["HomeInventoryItemCategory"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory_stream:ModelTypes["HomeInventoryItemCategory"][],
	/** fetch data from the table: "HomeInventoryItem" using primary key columns */
	HomeInventoryItem_by_pk?:ModelTypes["HomeInventoryItem"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItem" */
	HomeInventoryItem_stream:ModelTypes["HomeInventoryItem"][],
	/** fetch data from the table: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule:ModelTypes["HomeInventoryMaintenanceSchedule"][],
	/** fetch data from the table in a streaming manner: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule_stream:ModelTypes["HomeInventoryMaintenanceSchedule"][],
	/** fetch data from the table: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider:ModelTypes["HomeInventoryServiceProvider"][],
	/** fetch data from the table: "HomeInventoryServiceProvider" using primary key columns */
	HomeInventoryServiceProvider_by_pk?:ModelTypes["HomeInventoryServiceProvider"],
	/** fetch data from the table in a streaming manner: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider_stream:ModelTypes["HomeInventoryServiceProvider"][],
	/** fetch data from the table: "HomeInventoryServiceType" */
	HomeInventoryServiceType:ModelTypes["HomeInventoryServiceType"][],
	/** fetch data from the table: "HomeInventoryServiceType" using primary key columns */
	HomeInventoryServiceType_by_pk?:ModelTypes["HomeInventoryServiceType"],
	/** fetch data from the table in a streaming manner: "HomeInventoryServiceType" */
	HomeInventoryServiceType_stream:ModelTypes["HomeInventoryServiceType"][],
	/** fetch data from the table: "HomeWaitlist" */
	HomeWaitlist:ModelTypes["HomeWaitlist"][],
	/** fetch data from the table: "HomeWaitlist" using primary key columns */
	HomeWaitlist_by_pk?:ModelTypes["HomeWaitlist"],
	/** fetch data from the table in a streaming manner: "HomeWaitlist" */
	HomeWaitlist_stream:ModelTypes["HomeWaitlist"][],
	/** fetch data from the table: "Home" using primary key columns */
	Home_by_pk?:ModelTypes["Home"],
	/** fetch data from the table in a streaming manner: "Home" */
	Home_stream:ModelTypes["Home"][],
	/** fetch data from the table: "MarketingLead" */
	MarketingLead:ModelTypes["MarketingLead"][],
	/** fetch data from the table: "MarketingLead" using primary key columns */
	MarketingLead_by_pk?:ModelTypes["MarketingLead"],
	/** fetch data from the table in a streaming manner: "MarketingLead" */
	MarketingLead_stream:ModelTypes["MarketingLead"][],
	/** fetch data from the table: "Neighborhood" */
	Neighborhood:ModelTypes["Neighborhood"][],
	/** fetch data from the table: "NeighborhoodRegion" */
	NeighborhoodRegion:ModelTypes["NeighborhoodRegion"][],
	/** fetch data from the table: "NeighborhoodRegion" using primary key columns */
	NeighborhoodRegion_by_pk?:ModelTypes["NeighborhoodRegion"],
	/** fetch data from the table in a streaming manner: "NeighborhoodRegion" */
	NeighborhoodRegion_stream:ModelTypes["NeighborhoodRegion"][],
	/** fetch data from the table: "Neighborhood" using primary key columns */
	Neighborhood_by_pk?:ModelTypes["Neighborhood"],
	/** fetch data from the table in a streaming manner: "Neighborhood" */
	Neighborhood_stream:ModelTypes["Neighborhood"][],
	/** fetch data from the table: "Person" */
	Person:ModelTypes["Person"][],
	/** fetch data from the table: "PersonBiographyItem" */
	PersonBiographyItem:ModelTypes["PersonBiographyItem"][],
	/** fetch data from the table: "PersonBiographyItem" using primary key columns */
	PersonBiographyItem_by_pk?:ModelTypes["PersonBiographyItem"],
	/** fetch data from the table in a streaming manner: "PersonBiographyItem" */
	PersonBiographyItem_stream:ModelTypes["PersonBiographyItem"][],
	/** fetch data from the table: "PersonHome" */
	PersonHome:ModelTypes["PersonHome"][],
	/** fetch data from the table in a streaming manner: "PersonHome" */
	PersonHome_stream:ModelTypes["PersonHome"][],
	/** fetch aggregated fields from the table: "Person" */
	Person_aggregate:ModelTypes["Person_aggregate"],
	/** fetch data from the table: "Person" using primary key columns */
	Person_by_pk?:ModelTypes["Person"],
	/** fetch data from the table in a streaming manner: "Person" */
	Person_stream:ModelTypes["Person"][],
	/** fetch data from the table: "Referral" */
	Referral:ModelTypes["Referral"][],
	/** fetch data from the table: "ReferralProgram" */
	ReferralProgram:ModelTypes["ReferralProgram"][],
	/** fetch data from the table: "ReferralProgram" using primary key columns */
	ReferralProgram_by_pk?:ModelTypes["ReferralProgram"],
	/** fetch data from the table in a streaming manner: "ReferralProgram" */
	ReferralProgram_stream:ModelTypes["ReferralProgram"][],
	/** fetch data from the table: "Referral" using primary key columns */
	Referral_by_pk?:ModelTypes["Referral"],
	/** fetch data from the table in a streaming manner: "Referral" */
	Referral_stream:ModelTypes["Referral"][],
	/** fetch data from the table: "Region" */
	Region:ModelTypes["Region"][],
	/** fetch data from the table: "Region" using primary key columns */
	Region_by_pk?:ModelTypes["Region"],
	/** fetch data from the table in a streaming manner: "Region" */
	Region_stream:ModelTypes["Region"][],
	/** fetch data from the table: "ServiceOffer" */
	ServiceOffer:ModelTypes["ServiceOffer"][],
	/** fetch data from the table: "ServiceOfferResponse" */
	ServiceOfferResponse:ModelTypes["ServiceOfferResponse"][],
	/** fetch data from the table: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus:ModelTypes["ServiceOfferResponseStatus"][],
	/** fetch data from the table: "ServiceOfferResponseStatus" using primary key columns */
	ServiceOfferResponseStatus_by_pk?:ModelTypes["ServiceOfferResponseStatus"],
	/** fetch data from the table in a streaming manner: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus_stream:ModelTypes["ServiceOfferResponseStatus"][],
	/** fetch data from the table: "ServiceOfferResponse" using primary key columns */
	ServiceOfferResponse_by_pk?:ModelTypes["ServiceOfferResponse"],
	/** fetch data from the table in a streaming manner: "ServiceOfferResponse" */
	ServiceOfferResponse_stream:ModelTypes["ServiceOfferResponse"][],
	/** fetch data from the table: "ServiceOfferType" */
	ServiceOfferType:ModelTypes["ServiceOfferType"][],
	/** fetch data from the table: "ServiceOfferType" using primary key columns */
	ServiceOfferType_by_pk?:ModelTypes["ServiceOfferType"],
	/** fetch data from the table in a streaming manner: "ServiceOfferType" */
	ServiceOfferType_stream:ModelTypes["ServiceOfferType"][],
	/** fetch data from the table: "ServiceOffer" using primary key columns */
	ServiceOffer_by_pk?:ModelTypes["ServiceOffer"],
	/** fetch data from the table in a streaming manner: "ServiceOffer" */
	ServiceOffer_stream:ModelTypes["ServiceOffer"][],
	/** fetch data from the table: "ServiceProvider" */
	ServiceProvider:ModelTypes["ServiceProvider"][],
	/** fetch data from the table: "ServiceProviderRequest" */
	ServiceProviderRequest:ModelTypes["ServiceProviderRequest"][],
	/** fetch data from the table: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage:ModelTypes["ServiceProviderRequestStage"][],
	/** fetch data from the table: "ServiceProviderRequestStage" using primary key columns */
	ServiceProviderRequestStage_by_pk?:ModelTypes["ServiceProviderRequestStage"],
	/** fetch data from the table in a streaming manner: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage_stream:ModelTypes["ServiceProviderRequestStage"][],
	/** fetch data from the table: "ServiceProviderRequest" using primary key columns */
	ServiceProviderRequest_by_pk?:ModelTypes["ServiceProviderRequest"],
	/** fetch data from the table in a streaming manner: "ServiceProviderRequest" */
	ServiceProviderRequest_stream:ModelTypes["ServiceProviderRequest"][],
	/** fetch data from the table: "ServiceProvider" using primary key columns */
	ServiceProvider_by_pk?:ModelTypes["ServiceProvider"],
	/** fetch data from the table in a streaming manner: "ServiceProvider" */
	ServiceProvider_stream:ModelTypes["ServiceProvider"][],
	/** fetch data from the table: "StripePaymentMethod" */
	StripePaymentMethod:ModelTypes["StripePaymentMethod"][],
	/** fetch data from the table: "StripePaymentMethod" using primary key columns */
	StripePaymentMethod_by_pk?:ModelTypes["StripePaymentMethod"],
	/** fetch data from the table in a streaming manner: "StripePaymentMethod" */
	StripePaymentMethod_stream:ModelTypes["StripePaymentMethod"][],
	/** fetch data from the table: "StripeSubscription" */
	StripeSubscription:ModelTypes["StripeSubscription"][],
	/** fetch data from the table: "StripeSubscription" using primary key columns */
	StripeSubscription_by_pk?:ModelTypes["StripeSubscription"],
	/** fetch data from the table in a streaming manner: "StripeSubscription" */
	StripeSubscription_stream:ModelTypes["StripeSubscription"][],
	/** fetch data from the table: "Task" */
	Task:ModelTypes["Task"][],
	/** fetch data from the table: "TaskAttachment" */
	TaskAttachment:ModelTypes["TaskAttachment"][],
	/** fetch data from the table: "TaskAttachment" using primary key columns */
	TaskAttachment_by_pk?:ModelTypes["TaskAttachment"],
	/** fetch data from the table in a streaming manner: "TaskAttachment" */
	TaskAttachment_stream:ModelTypes["TaskAttachment"][],
	/** fetch data from the table: "TaskComment" */
	TaskComment:ModelTypes["TaskComment"][],
	/** fetch aggregated fields from the table: "TaskComment" */
	TaskComment_aggregate:ModelTypes["TaskComment_aggregate"],
	/** fetch data from the table in a streaming manner: "TaskComment" */
	TaskComment_stream:ModelTypes["TaskComment"][],
	/** fetch data from the table: "TaskPart" */
	TaskPart:ModelTypes["TaskPart"][],
	/** fetch data from the table: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible:ModelTypes["TaskPartPartyResponsible"][],
	/** fetch data from the table: "TaskPartPartyResponsible" using primary key columns */
	TaskPartPartyResponsible_by_pk?:ModelTypes["TaskPartPartyResponsible"],
	/** fetch data from the table in a streaming manner: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible_stream:ModelTypes["TaskPartPartyResponsible"][],
	/** fetch data from the table: "TaskPartStatus" */
	TaskPartStatus:ModelTypes["TaskPartStatus"][],
	/** fetch data from the table: "TaskPartStatus" using primary key columns */
	TaskPartStatus_by_pk?:ModelTypes["TaskPartStatus"],
	/** fetch data from the table in a streaming manner: "TaskPartStatus" */
	TaskPartStatus_stream:ModelTypes["TaskPartStatus"][],
	/** fetch data from the table: "TaskPart" using primary key columns */
	TaskPart_by_pk?:ModelTypes["TaskPart"],
	/** fetch data from the table in a streaming manner: "TaskPart" */
	TaskPart_stream:ModelTypes["TaskPart"][],
	/** fetch data from the table: "TaskUpdateLog" */
	TaskUpdateLog:ModelTypes["TaskUpdateLog"][],
	/** fetch data from the table: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment:ModelTypes["TaskUpdateLogAttachment"][],
	/** fetch data from the table: "TaskUpdateLogAttachment" using primary key columns */
	TaskUpdateLogAttachment_by_pk?:ModelTypes["TaskUpdateLogAttachment"],
	/** fetch data from the table in a streaming manner: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment_stream:ModelTypes["TaskUpdateLogAttachment"][],
	/** fetch aggregated fields from the table: "TaskUpdateLog" */
	TaskUpdateLog_aggregate:ModelTypes["TaskUpdateLog_aggregate"],
	/** fetch data from the table: "TaskUpdateLog" using primary key columns */
	TaskUpdateLog_by_pk?:ModelTypes["TaskUpdateLog"],
	/** fetch data from the table in a streaming manner: "TaskUpdateLog" */
	TaskUpdateLog_stream:ModelTypes["TaskUpdateLog"][],
	/** fetch aggregated fields from the table: "Task" */
	Task_aggregate:ModelTypes["Task_aggregate"],
	/** fetch data from the table: "Task" using primary key columns */
	Task_by_pk?:ModelTypes["Task"],
	/** fetch data from the table in a streaming manner: "Task" */
	Task_stream:ModelTypes["Task"][],
	/** fetch data from the table: "TimeZone" */
	TimeZone:ModelTypes["TimeZone"][],
	/** fetch data from the table in a streaming manner: "TimeZone" */
	TimeZone_stream:ModelTypes["TimeZone"][],
	/** execute function "find_Person_by_phone" which returns "find_Person_response" */
	find_Person_by_phone?:ModelTypes["find_Person_response"],
	/** fetch data from the table: "find_Person_response" */
	find_Person_response:ModelTypes["find_Person_response"][],
	/** fetch data from the table in a streaming manner: "find_Person_response" */
	find_Person_response_stream:ModelTypes["find_Person_response"][]
};
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: GraphQLTypes["timestamptz_comparison_exp"];
	["timetz"]:any;
	/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
["timetz_comparison_exp"]: GraphQLTypes["timetz_comparison_exp"];
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: GraphQLTypes["uuid_comparison_exp"]
    }

export type GraphQLTypes = {
    /** columns and relationships of "Appointment" */
["Appointment"]: {
	__typename: "Appointment",
	/** An array relationship */
	AppointmentGrades: Array<GraphQLTypes["AppointmentGrade"]>,
	/** An array relationship */
	AppointmentProviders: Array<GraphQLTypes["AppointmentProvider"]>,
	/** An aggregate relationship */
	AppointmentProviders_aggregate: GraphQLTypes["AppointmentProvider_aggregate"],
	/** An object relationship */
	AppointmentReport?: GraphQLTypes["AppointmentReport"],
	/** An object relationship */
	CreatedBy: GraphQLTypes["Person"],
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An array relationship */
	Tasks: Array<GraphQLTypes["Task"]>,
	/** An aggregate relationship */
	Tasks_aggregate: GraphQLTypes["Task_aggregate"],
	beginsAt: GraphQLTypes["timestamptz"],
	createdAt: GraphQLTypes["timestamptz"],
	createdById: GraphQLTypes["uuid"],
	endsAt: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	/** When true, beginsAt and endsAt define a window of time, not an exact range */
	isWindowTimes: boolean,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Homeowner review of a completed Appointment */
["AppointmentGrade"]: {
	__typename: "AppointmentGrade",
	/** An object relationship */
	Appointment: GraphQLTypes["Appointment"],
	/** An array relationship */
	AppointmentGradeSelections: Array<GraphQLTypes["AppointmentGradeSelection"]>,
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	appointmentId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	negativeComments?: string,
	personId: GraphQLTypes["uuid"],
	positiveComments?: string,
	score: GraphQLTypes["smallint"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Person selected categories of feedback for an AppointmentGrade */
["AppointmentGradeSelection"]: {
	__typename: "AppointmentGradeSelection",
	/** An object relationship */
	AppointmentGrade: GraphQLTypes["AppointmentGrade"],
	/** An object relationship */
	AppointmentGradeSelectionCategory: GraphQLTypes["AppointmentGradeSelectionCategory"],
	appointmentGradeId: GraphQLTypes["uuid"],
	appointmentGradeSelectionCategoryId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Defines the options presented when asking for feedback from a homeowner */
["AppointmentGradeSelectionCategory"]: {
	__typename: "AppointmentGradeSelectionCategory",
	/** An object relationship */
	ImageAssetAttachment: GraphQLTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment: GraphQLTypes["Attachment"],
	createdAt: GraphQLTypes["timestamptz"],
	description?: string,
	/** Text label displayed to the person for this category. */
	displayText: string,
	/** Human readable unique identifier */
	friendlyAssetId: string,
	id: GraphQLTypes["uuid"],
	imageAssetAttachmentId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"],
	vectorAssetAttachmentId: GraphQLTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "AppointmentGradeSelectionCategory". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelectionCategory_bool_exp"]: {
		ImageAssetAttachment?: GraphQLTypes["Attachment_bool_exp"],
	VectorAssetAttachment?: GraphQLTypes["Attachment_bool_exp"],
	_and?: Array<GraphQLTypes["AppointmentGradeSelectionCategory_bool_exp"]>,
	_not?: GraphQLTypes["AppointmentGradeSelectionCategory_bool_exp"],
	_or?: Array<GraphQLTypes["AppointmentGradeSelectionCategory_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	displayText?: GraphQLTypes["String_comparison_exp"],
	friendlyAssetId?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	imageAssetAttachmentId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	vectorAssetAttachmentId?: GraphQLTypes["uuid_comparison_exp"]
};
	/** Ordering options when selecting data from "AppointmentGradeSelectionCategory". */
["AppointmentGradeSelectionCategory_order_by"]: {
		ImageAssetAttachment?: GraphQLTypes["Attachment_order_by"],
	VectorAssetAttachment?: GraphQLTypes["Attachment_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	displayText?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	imageAssetAttachmentId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	vectorAssetAttachmentId?: GraphQLTypes["order_by"]
};
	/** select columns of table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_select_column"]: AppointmentGradeSelectionCategory_select_column;
	/** Streaming cursor of the table "AppointmentGradeSelectionCategory" */
["AppointmentGradeSelectionCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AppointmentGradeSelectionCategory_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelectionCategory_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	description?: string,
	/** Text label displayed to the person for this category. */
	displayText?: string,
	/** Human readable unique identifier */
	friendlyAssetId?: string,
	id?: GraphQLTypes["uuid"],
	imageAssetAttachmentId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"],
	vectorAssetAttachmentId?: GraphQLTypes["uuid"]
};
	/** order by aggregate values of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["AppointmentGradeSelection_max_order_by"],
	min?: GraphQLTypes["AppointmentGradeSelection_min_order_by"]
};
	/** input type for inserting array relation for remote table "AppointmentGradeSelection" */
["AppointmentGradeSelection_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["AppointmentGradeSelection_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["AppointmentGradeSelection_on_conflict"]
};
	/** Boolean expression to filter rows from the table "AppointmentGradeSelection". All fields are combined with a logical 'AND'. */
["AppointmentGradeSelection_bool_exp"]: {
		AppointmentGrade?: GraphQLTypes["AppointmentGrade_bool_exp"],
	AppointmentGradeSelectionCategory?: GraphQLTypes["AppointmentGradeSelectionCategory_bool_exp"],
	_and?: Array<GraphQLTypes["AppointmentGradeSelection_bool_exp"]>,
	_not?: GraphQLTypes["AppointmentGradeSelection_bool_exp"],
	_or?: Array<GraphQLTypes["AppointmentGradeSelection_bool_exp"]>,
	appointmentGradeId?: GraphQLTypes["uuid_comparison_exp"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "AppointmentGradeSelection" */
["AppointmentGradeSelection_constraint"]: AppointmentGradeSelection_constraint;
	/** input type for inserting data into table "AppointmentGradeSelection" */
["AppointmentGradeSelection_insert_input"]: {
		AppointmentGrade?: GraphQLTypes["AppointmentGrade_obj_rel_insert_input"],
	appointmentGradeId?: GraphQLTypes["uuid"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["uuid"],
	/** True means selection is a category that went well. */
	isPositive?: boolean
};
	/** order by max() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_max_order_by"]: {
		appointmentGradeId?: GraphQLTypes["order_by"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_min_order_by"]: {
		appointmentGradeId?: GraphQLTypes["order_by"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_mutation_response"]: {
	__typename: "AppointmentGradeSelection_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["AppointmentGradeSelection"]>
};
	/** on_conflict condition type for table "AppointmentGradeSelection" */
["AppointmentGradeSelection_on_conflict"]: {
		constraint: GraphQLTypes["AppointmentGradeSelection_constraint"],
	update_columns: Array<GraphQLTypes["AppointmentGradeSelection_update_column"]>,
	where?: GraphQLTypes["AppointmentGradeSelection_bool_exp"]
};
	/** Ordering options when selecting data from "AppointmentGradeSelection". */
["AppointmentGradeSelection_order_by"]: {
		AppointmentGrade?: GraphQLTypes["AppointmentGrade_order_by"],
	AppointmentGradeSelectionCategory?: GraphQLTypes["AppointmentGradeSelectionCategory_order_by"],
	appointmentGradeId?: GraphQLTypes["order_by"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "AppointmentGradeSelection" */
["AppointmentGradeSelection_select_column"]: AppointmentGradeSelection_select_column;
	/** Streaming cursor of the table "AppointmentGradeSelection" */
["AppointmentGradeSelection_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AppointmentGradeSelection_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGradeSelection_stream_cursor_value_input"]: {
		appointmentGradeId?: GraphQLTypes["uuid"],
	appointmentGradeSelectionCategoryId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** placeholder for update columns of table "AppointmentGradeSelection" (current role has no relevant permissions) */
["AppointmentGradeSelection_update_column"]: AppointmentGradeSelection_update_column;
	/** order by aggregate values of table "AppointmentGrade" */
["AppointmentGrade_aggregate_order_by"]: {
		avg?: GraphQLTypes["AppointmentGrade_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["AppointmentGrade_max_order_by"],
	min?: GraphQLTypes["AppointmentGrade_min_order_by"],
	stddev?: GraphQLTypes["AppointmentGrade_stddev_order_by"],
	stddev_pop?: GraphQLTypes["AppointmentGrade_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["AppointmentGrade_stddev_samp_order_by"],
	sum?: GraphQLTypes["AppointmentGrade_sum_order_by"],
	var_pop?: GraphQLTypes["AppointmentGrade_var_pop_order_by"],
	var_samp?: GraphQLTypes["AppointmentGrade_var_samp_order_by"],
	variance?: GraphQLTypes["AppointmentGrade_variance_order_by"]
};
	/** input type for inserting array relation for remote table "AppointmentGrade" */
["AppointmentGrade_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["AppointmentGrade_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["AppointmentGrade_on_conflict"]
};
	/** order by avg() on columns of table "AppointmentGrade" */
["AppointmentGrade_avg_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "AppointmentGrade". All fields are combined with a logical 'AND'. */
["AppointmentGrade_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	AppointmentGradeSelections?: GraphQLTypes["AppointmentGradeSelection_bool_exp"],
	Home?: GraphQLTypes["Home_bool_exp"],
	Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["AppointmentGrade_bool_exp"]>,
	_not?: GraphQLTypes["AppointmentGrade_bool_exp"],
	_or?: Array<GraphQLTypes["AppointmentGrade_bool_exp"]>,
	appointmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	negativeComments?: GraphQLTypes["String_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	positiveComments?: GraphQLTypes["String_comparison_exp"],
	score?: GraphQLTypes["smallint_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "AppointmentGrade" */
["AppointmentGrade_constraint"]: AppointmentGrade_constraint;
	/** input type for inserting data into table "AppointmentGrade" */
["AppointmentGrade_insert_input"]: {
		Appointment?: GraphQLTypes["Appointment_obj_rel_insert_input"],
	AppointmentGradeSelections?: GraphQLTypes["AppointmentGradeSelection_arr_rel_insert_input"],
	Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	appointmentId?: GraphQLTypes["uuid"],
	negativeComments?: string,
	positiveComments?: string,
	score?: GraphQLTypes["smallint"]
};
	/** order by max() on columns of table "AppointmentGrade" */
["AppointmentGrade_max_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	negativeComments?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	positiveComments?: GraphQLTypes["order_by"],
	score?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "AppointmentGrade" */
["AppointmentGrade_min_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	negativeComments?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	positiveComments?: GraphQLTypes["order_by"],
	score?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "AppointmentGrade" */
["AppointmentGrade_mutation_response"]: {
	__typename: "AppointmentGrade_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["AppointmentGrade"]>
};
	/** input type for inserting object relation for remote table "AppointmentGrade" */
["AppointmentGrade_obj_rel_insert_input"]: {
		data: GraphQLTypes["AppointmentGrade_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["AppointmentGrade_on_conflict"]
};
	/** on_conflict condition type for table "AppointmentGrade" */
["AppointmentGrade_on_conflict"]: {
		constraint: GraphQLTypes["AppointmentGrade_constraint"],
	update_columns: Array<GraphQLTypes["AppointmentGrade_update_column"]>,
	where?: GraphQLTypes["AppointmentGrade_bool_exp"]
};
	/** Ordering options when selecting data from "AppointmentGrade". */
["AppointmentGrade_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	AppointmentGradeSelections_aggregate?: GraphQLTypes["AppointmentGradeSelection_aggregate_order_by"],
	Home?: GraphQLTypes["Home_order_by"],
	Person?: GraphQLTypes["Person_order_by"],
	appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	negativeComments?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	positiveComments?: GraphQLTypes["order_by"],
	score?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "AppointmentGrade" */
["AppointmentGrade_select_column"]: AppointmentGrade_select_column;
	/** order by stddev() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_pop_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_stddev_samp_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "AppointmentGrade" */
["AppointmentGrade_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AppointmentGrade_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AppointmentGrade_stream_cursor_value_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	negativeComments?: string,
	personId?: GraphQLTypes["uuid"],
	positiveComments?: string,
	score?: GraphQLTypes["smallint"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "AppointmentGrade" */
["AppointmentGrade_sum_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** placeholder for update columns of table "AppointmentGrade" (current role has no relevant permissions) */
["AppointmentGrade_update_column"]: AppointmentGrade_update_column;
	/** order by var_pop() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_pop_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "AppointmentGrade" */
["AppointmentGrade_var_samp_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "AppointmentGrade" */
["AppointmentGrade_variance_order_by"]: {
		score?: GraphQLTypes["order_by"]
};
	/** Associates people and organizations that provide service for an Appointment */
["AppointmentProvider"]: {
	__typename: "AppointmentProvider",
	/** An object relationship */
	Appointment: GraphQLTypes["Appointment"],
	/** An object relationship */
	Person?: GraphQLTypes["Person"],
	/** An object relationship */
	ServiceProvider: GraphQLTypes["ServiceProvider"],
	appointmentId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	serviceProviderId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "AppointmentProvider" */
["AppointmentProvider_aggregate"]: {
	__typename: "AppointmentProvider_aggregate",
	aggregate?: GraphQLTypes["AppointmentProvider_aggregate_fields"],
	nodes: Array<GraphQLTypes["AppointmentProvider"]>
};
	["AppointmentProvider_aggregate_bool_exp"]: {
		count?: GraphQLTypes["AppointmentProvider_aggregate_bool_exp_count"]
};
	["AppointmentProvider_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["AppointmentProvider_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["AppointmentProvider_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "AppointmentProvider" */
["AppointmentProvider_aggregate_fields"]: {
	__typename: "AppointmentProvider_aggregate_fields",
	count: number,
	max?: GraphQLTypes["AppointmentProvider_max_fields"],
	min?: GraphQLTypes["AppointmentProvider_min_fields"]
};
	/** order by aggregate values of table "AppointmentProvider" */
["AppointmentProvider_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["AppointmentProvider_max_order_by"],
	min?: GraphQLTypes["AppointmentProvider_min_order_by"]
};
	/** input type for inserting array relation for remote table "AppointmentProvider" */
["AppointmentProvider_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["AppointmentProvider_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["AppointmentProvider_on_conflict"]
};
	/** Boolean expression to filter rows from the table "AppointmentProvider". All fields are combined with a logical 'AND'. */
["AppointmentProvider_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	Person?: GraphQLTypes["Person_bool_exp"],
	ServiceProvider?: GraphQLTypes["ServiceProvider_bool_exp"],
	_and?: Array<GraphQLTypes["AppointmentProvider_bool_exp"]>,
	_not?: GraphQLTypes["AppointmentProvider_bool_exp"],
	_or?: Array<GraphQLTypes["AppointmentProvider_bool_exp"]>,
	appointmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	serviceProviderId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "AppointmentProvider" */
["AppointmentProvider_constraint"]: AppointmentProvider_constraint;
	/** input type for inserting data into table "AppointmentProvider" */
["AppointmentProvider_insert_input"]: {
		Appointment?: GraphQLTypes["Appointment_obj_rel_insert_input"],
	Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	appointmentId?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	serviceProviderId?: GraphQLTypes["uuid"]
};
	/** aggregate max on columns */
["AppointmentProvider_max_fields"]: {
	__typename: "AppointmentProvider_max_fields",
	appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	serviceProviderId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "AppointmentProvider" */
["AppointmentProvider_max_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	serviceProviderId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["AppointmentProvider_min_fields"]: {
	__typename: "AppointmentProvider_min_fields",
	appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	serviceProviderId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "AppointmentProvider" */
["AppointmentProvider_min_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	serviceProviderId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "AppointmentProvider" */
["AppointmentProvider_mutation_response"]: {
	__typename: "AppointmentProvider_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["AppointmentProvider"]>
};
	/** on_conflict condition type for table "AppointmentProvider" */
["AppointmentProvider_on_conflict"]: {
		constraint: GraphQLTypes["AppointmentProvider_constraint"],
	update_columns: Array<GraphQLTypes["AppointmentProvider_update_column"]>,
	where?: GraphQLTypes["AppointmentProvider_bool_exp"]
};
	/** Ordering options when selecting data from "AppointmentProvider". */
["AppointmentProvider_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	Person?: GraphQLTypes["Person_order_by"],
	ServiceProvider?: GraphQLTypes["ServiceProvider_order_by"],
	appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	serviceProviderId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "AppointmentProvider" */
["AppointmentProvider_select_column"]: AppointmentProvider_select_column;
	/** Streaming cursor of the table "AppointmentProvider" */
["AppointmentProvider_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AppointmentProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AppointmentProvider_stream_cursor_value_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	serviceProviderId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** placeholder for update columns of table "AppointmentProvider" (current role has no relevant permissions) */
["AppointmentProvider_update_column"]: AppointmentProvider_update_column;
	/** Information about work completed in an appointment */
["AppointmentReport"]: {
	__typename: "AppointmentReport",
	/** An object relationship */
	Appointment: GraphQLTypes["Appointment"],
	/** An object relationship */
	CreatedBy: GraphQLTypes["Person"],
	/** An object relationship */
	ReviewedBy?: GraphQLTypes["Person"],
	/** An array relationship */
	TaskUpdateLogs: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate: GraphQLTypes["TaskUpdateLog_aggregate"],
	appointmentId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	createdById: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	reviewCompletedAt?: GraphQLTypes["timestamptz"],
	reviewedById?: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "AppointmentReport" */
["AppointmentReport_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["AppointmentReport_max_order_by"],
	min?: GraphQLTypes["AppointmentReport_min_order_by"]
};
	/** Boolean expression to filter rows from the table "AppointmentReport". All fields are combined with a logical 'AND'. */
["AppointmentReport_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	CreatedBy?: GraphQLTypes["Person_bool_exp"],
	ReviewedBy?: GraphQLTypes["Person_bool_exp"],
	TaskUpdateLogs?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp"],
	_and?: Array<GraphQLTypes["AppointmentReport_bool_exp"]>,
	_not?: GraphQLTypes["AppointmentReport_bool_exp"],
	_or?: Array<GraphQLTypes["AppointmentReport_bool_exp"]>,
	appointmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdById?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	reviewCompletedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	reviewedById?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "AppointmentReport" */
["AppointmentReport_max_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reviewCompletedAt?: GraphQLTypes["order_by"],
	reviewedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "AppointmentReport" */
["AppointmentReport_min_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reviewCompletedAt?: GraphQLTypes["order_by"],
	reviewedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "AppointmentReport". */
["AppointmentReport_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	CreatedBy?: GraphQLTypes["Person_order_by"],
	ReviewedBy?: GraphQLTypes["Person_order_by"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_order_by"],
	appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reviewCompletedAt?: GraphQLTypes["order_by"],
	reviewedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "AppointmentReport" */
["AppointmentReport_select_column"]: AppointmentReport_select_column;
	/** Streaming cursor of the table "AppointmentReport" */
["AppointmentReport_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AppointmentReport_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AppointmentReport_stream_cursor_value_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	reviewCompletedAt?: GraphQLTypes["timestamptz"],
	reviewedById?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "Appointment" */
["Appointment_aggregate"]: {
	__typename: "Appointment_aggregate",
	aggregate?: GraphQLTypes["Appointment_aggregate_fields"],
	nodes: Array<GraphQLTypes["Appointment"]>
};
	["Appointment_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["Appointment_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["Appointment_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["Appointment_aggregate_bool_exp_count"]
};
	["Appointment_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["Appointment_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["Appointment_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["Appointment_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["Appointment_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["Appointment_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["Appointment_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Appointment" */
["Appointment_aggregate_fields"]: {
	__typename: "Appointment_aggregate_fields",
	count: number,
	max?: GraphQLTypes["Appointment_max_fields"],
	min?: GraphQLTypes["Appointment_min_fields"]
};
	/** order by aggregate values of table "Appointment" */
["Appointment_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Appointment_max_order_by"],
	min?: GraphQLTypes["Appointment_min_order_by"]
};
	/** input type for inserting array relation for remote table "Appointment" */
["Appointment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["Appointment_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["Appointment_on_conflict"]
};
	/** Boolean expression to filter rows from the table "Appointment". All fields are combined with a logical 'AND'. */
["Appointment_bool_exp"]: {
		AppointmentGrades?: GraphQLTypes["AppointmentGrade_bool_exp"],
	AppointmentProviders?: GraphQLTypes["AppointmentProvider_bool_exp"],
	AppointmentProviders_aggregate?: GraphQLTypes["AppointmentProvider_aggregate_bool_exp"],
	AppointmentReport?: GraphQLTypes["AppointmentReport_bool_exp"],
	CreatedBy?: GraphQLTypes["Person_bool_exp"],
	Home?: GraphQLTypes["Home_bool_exp"],
	Tasks?: GraphQLTypes["Task_bool_exp"],
	Tasks_aggregate?: GraphQLTypes["Task_aggregate_bool_exp"],
	_and?: Array<GraphQLTypes["Appointment_bool_exp"]>,
	_not?: GraphQLTypes["Appointment_bool_exp"],
	_or?: Array<GraphQLTypes["Appointment_bool_exp"]>,
	beginsAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdById?: GraphQLTypes["uuid_comparison_exp"],
	endsAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	isWindowTimes?: GraphQLTypes["Boolean_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "Appointment" */
["Appointment_constraint"]: Appointment_constraint;
	/** input type for inserting data into table "Appointment" */
["Appointment_insert_input"]: {
		AppointmentGrades?: GraphQLTypes["AppointmentGrade_arr_rel_insert_input"],
	AppointmentProviders?: GraphQLTypes["AppointmentProvider_arr_rel_insert_input"],
	CreatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	Tasks?: GraphQLTypes["Task_arr_rel_insert_input"],
	beginsAt?: GraphQLTypes["timestamptz"],
	endsAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	/** When true, automatically create an AppointmentProvider with the default Person assigned to the Home */
	usesDefaultProvider?: boolean
};
	/** aggregate max on columns */
["Appointment_max_fields"]: {
	__typename: "Appointment_max_fields",
	beginsAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	endsAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "Appointment" */
["Appointment_max_order_by"]: {
		beginsAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["Appointment_min_fields"]: {
	__typename: "Appointment_min_fields",
	beginsAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	endsAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "Appointment" */
["Appointment_min_order_by"]: {
		beginsAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Appointment" */
["Appointment_mutation_response"]: {
	__typename: "Appointment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Appointment"]>
};
	/** input type for inserting object relation for remote table "Appointment" */
["Appointment_obj_rel_insert_input"]: {
		data: GraphQLTypes["Appointment_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["Appointment_on_conflict"]
};
	/** on_conflict condition type for table "Appointment" */
["Appointment_on_conflict"]: {
		constraint: GraphQLTypes["Appointment_constraint"],
	update_columns: Array<GraphQLTypes["Appointment_update_column"]>,
	where?: GraphQLTypes["Appointment_bool_exp"]
};
	/** Ordering options when selecting data from "Appointment". */
["Appointment_order_by"]: {
		AppointmentGrades_aggregate?: GraphQLTypes["AppointmentGrade_aggregate_order_by"],
	AppointmentProviders_aggregate?: GraphQLTypes["AppointmentProvider_aggregate_order_by"],
	AppointmentReport?: GraphQLTypes["AppointmentReport_order_by"],
	CreatedBy?: GraphQLTypes["Person_order_by"],
	Home?: GraphQLTypes["Home_order_by"],
	Tasks_aggregate?: GraphQLTypes["Task_aggregate_order_by"],
	beginsAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	isWindowTimes?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Appointment */
["Appointment_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "Appointment" */
["Appointment_select_column"]: Appointment_select_column;
	/** select "Appointment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns"]: Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "Appointment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Appointment" */
["Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns"]: Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "Appointment" */
["Appointment_set_input"]: {
		beginsAt?: GraphQLTypes["timestamptz"],
	endsAt?: GraphQLTypes["timestamptz"]
};
	/** Streaming cursor of the table "Appointment" */
["Appointment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Appointment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Appointment_stream_cursor_value_input"]: {
		beginsAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	endsAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	/** When true, beginsAt and endsAt define a window of time, not an exact range */
	isWindowTimes?: boolean,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** update columns of table "Appointment" */
["Appointment_update_column"]: Appointment_update_column;
	["Appointment_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Appointment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Appointment_bool_exp"]
};
	/** File attachments to any other object. */
["Attachment"]: {
	__typename: "Attachment",
	/** An object relationship */
	CreatedBy: GraphQLTypes["Person"],
	/** An array relationship */
	HomeInventoryItemAttachments: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** An array relationship */
	ProfilePictureFor: Array<GraphQLTypes["Person"]>,
	/** An aggregate relationship */
	ProfilePictureFor_aggregate: GraphQLTypes["Person_aggregate"],
	/** An array relationship */
	TaskAttachments: Array<GraphQLTypes["TaskAttachment"]>,
	/** An array relationship */
	TaskUpdateLogAttachments: Array<GraphQLTypes["TaskUpdateLogAttachment"]>,
	/** User provided caption */
	caption?: string,
	contentType?: string,
	createdAt: GraphQLTypes["timestamptz"],
	createdById: GraphQLTypes["uuid"],
	/** Not auto generated, must be provided */
	id: GraphQLTypes["uuid"],
	publicUrl?: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "Attachment" */
["Attachment_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Attachment_max_order_by"],
	min?: GraphQLTypes["Attachment_min_order_by"]
};
	/** input type for inserting array relation for remote table "Attachment" */
["Attachment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["Attachment_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["Attachment_on_conflict"]
};
	/** Boolean expression to filter rows from the table "Attachment". All fields are combined with a logical 'AND'. */
["Attachment_bool_exp"]: {
		CreatedBy?: GraphQLTypes["Person_bool_exp"],
	HomeInventoryItemAttachments?: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"],
	ProfilePictureFor?: GraphQLTypes["Person_bool_exp"],
	ProfilePictureFor_aggregate?: GraphQLTypes["Person_aggregate_bool_exp"],
	TaskAttachments?: GraphQLTypes["TaskAttachment_bool_exp"],
	TaskUpdateLogAttachments?: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"],
	_and?: Array<GraphQLTypes["Attachment_bool_exp"]>,
	_not?: GraphQLTypes["Attachment_bool_exp"],
	_or?: Array<GraphQLTypes["Attachment_bool_exp"]>,
	caption?: GraphQLTypes["String_comparison_exp"],
	contentType?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdById?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	publicUrl?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "Attachment" */
["Attachment_constraint"]: Attachment_constraint;
	/** input type for inserting data into table "Attachment" */
["Attachment_insert_input"]: {
		CreatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	HomeInventoryItemAttachments?: GraphQLTypes["HomeInventoryItemAttachment_arr_rel_insert_input"],
	ProfilePictureFor?: GraphQLTypes["Person_arr_rel_insert_input"],
	TaskAttachments?: GraphQLTypes["TaskAttachment_arr_rel_insert_input"],
	TaskUpdateLogAttachments?: GraphQLTypes["TaskUpdateLogAttachment_arr_rel_insert_input"],
	/** User provided caption */
	caption?: string,
	/** Not auto generated, must be provided */
	id?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "Attachment" */
["Attachment_max_order_by"]: {
		/** User provided caption */
	caption?: GraphQLTypes["order_by"],
	contentType?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	/** Not auto generated, must be provided */
	id?: GraphQLTypes["order_by"],
	publicUrl?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "Attachment" */
["Attachment_min_order_by"]: {
		/** User provided caption */
	caption?: GraphQLTypes["order_by"],
	contentType?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	/** Not auto generated, must be provided */
	id?: GraphQLTypes["order_by"],
	publicUrl?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Attachment" */
["Attachment_mutation_response"]: {
	__typename: "Attachment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Attachment"]>
};
	/** input type for inserting object relation for remote table "Attachment" */
["Attachment_obj_rel_insert_input"]: {
		data: GraphQLTypes["Attachment_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["Attachment_on_conflict"]
};
	/** on_conflict condition type for table "Attachment" */
["Attachment_on_conflict"]: {
		constraint: GraphQLTypes["Attachment_constraint"],
	update_columns: Array<GraphQLTypes["Attachment_update_column"]>,
	where?: GraphQLTypes["Attachment_bool_exp"]
};
	/** Ordering options when selecting data from "Attachment". */
["Attachment_order_by"]: {
		CreatedBy?: GraphQLTypes["Person_order_by"],
	HomeInventoryItemAttachments_aggregate?: GraphQLTypes["HomeInventoryItemAttachment_aggregate_order_by"],
	ProfilePictureFor_aggregate?: GraphQLTypes["Person_aggregate_order_by"],
	TaskAttachments_aggregate?: GraphQLTypes["TaskAttachment_aggregate_order_by"],
	TaskUpdateLogAttachments_aggregate?: GraphQLTypes["TaskUpdateLogAttachment_aggregate_order_by"],
	caption?: GraphQLTypes["order_by"],
	contentType?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	publicUrl?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Attachment */
["Attachment_pk_columns_input"]: {
		/** Not auto generated, must be provided */
	id: GraphQLTypes["uuid"]
};
	/** select columns of table "Attachment" */
["Attachment_select_column"]: Attachment_select_column;
	/** input type for updating data in table "Attachment" */
["Attachment_set_input"]: {
		/** User provided caption */
	caption?: string,
	/** Not auto generated, must be provided */
	id?: GraphQLTypes["uuid"]
};
	/** Streaming cursor of the table "Attachment" */
["Attachment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Attachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Attachment_stream_cursor_value_input"]: {
		/** User provided caption */
	caption?: string,
	contentType?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	/** Not auto generated, must be provided */
	id?: GraphQLTypes["uuid"],
	publicUrl?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** update columns of table "Attachment" */
["Attachment_update_column"]: Attachment_update_column;
	["Attachment_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Attachment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Attachment_bool_exp"]
};
	/** columns and relationships of "Availability" */
["Availability"]: {
	__typename: "Availability",
	/** An object relationship */
	Person?: GraphQLTypes["Person"],
	beginsAt?: GraphQLTypes["timestamptz"],
	endsAt?: GraphQLTypes["timestamptz"],
	personId?: GraphQLTypes["uuid"],
	serviceArea?: GraphQLTypes["geometry"]
};
	/** Result of an Availability query by the perspective of a Home */
["AvailabilityResult"]: {
	__typename: "AvailabilityResult",
	/** An object relationship */
	Appointment?: GraphQLTypes["Appointment"],
	/** An object relationship */
	Home?: GraphQLTypes["Home"],
	appointmentId?: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	creationSource?: GraphQLTypes["CreationSourceType_enum"],
	data: GraphQLTypes["jsonb"],
	homeId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	source: GraphQLTypes["AvailabilityResultSource_enum"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	["AvailabilityResultSource_enum"]: AvailabilityResultSource_enum;
	/** Boolean expression to compare columns of type "AvailabilityResultSource_enum". All fields are combined with logical 'AND'. */
["AvailabilityResultSource_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["AvailabilityResultSource_enum"],
	_in?: Array<GraphQLTypes["AvailabilityResultSource_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["AvailabilityResultSource_enum"],
	_nin?: Array<GraphQLTypes["AvailabilityResultSource_enum"]>
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_append_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** Boolean expression to filter rows from the table "AvailabilityResult". All fields are combined with a logical 'AND'. */
["AvailabilityResult_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	Home?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["AvailabilityResult_bool_exp"]>,
	_not?: GraphQLTypes["AvailabilityResult_bool_exp"],
	_or?: Array<GraphQLTypes["AvailabilityResult_bool_exp"]>,
	appointmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	creationSource?: GraphQLTypes["CreationSourceType_enum_comparison_exp"],
	data?: GraphQLTypes["jsonb_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	source?: GraphQLTypes["AvailabilityResultSource_enum_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["AvailabilityResult_delete_at_path_input"]: {
		data?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["AvailabilityResult_delete_elem_input"]: {
		data?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["AvailabilityResult_delete_key_input"]: {
		data?: string
};
	/** input type for inserting data into table "AvailabilityResult" */
["AvailabilityResult_insert_input"]: {
		Appointment?: GraphQLTypes["Appointment_obj_rel_insert_input"],
	appointmentId?: GraphQLTypes["uuid"],
	creationSource?: GraphQLTypes["CreationSourceType_enum"],
	data?: GraphQLTypes["jsonb"],
	source?: GraphQLTypes["AvailabilityResultSource_enum"]
};
	/** response of any mutation on the table "AvailabilityResult" */
["AvailabilityResult_mutation_response"]: {
	__typename: "AvailabilityResult_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["AvailabilityResult"]>
};
	/** Ordering options when selecting data from "AvailabilityResult". */
["AvailabilityResult_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	Home?: GraphQLTypes["Home_order_by"],
	appointmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	creationSource?: GraphQLTypes["order_by"],
	data?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	source?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["AvailabilityResult_prepend_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** select columns of table "AvailabilityResult" */
["AvailabilityResult_select_column"]: AvailabilityResult_select_column;
	/** input type for updating data in table "AvailabilityResult" */
["AvailabilityResult_set_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	data?: GraphQLTypes["jsonb"],
	source?: GraphQLTypes["AvailabilityResultSource_enum"]
};
	/** Streaming cursor of the table "AvailabilityResult" */
["AvailabilityResult_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AvailabilityResult_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AvailabilityResult_stream_cursor_value_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	creationSource?: GraphQLTypes["CreationSourceType_enum"],
	data?: GraphQLTypes["jsonb"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	source?: GraphQLTypes["AvailabilityResultSource_enum"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	["AvailabilityResult_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: GraphQLTypes["AvailabilityResult_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: GraphQLTypes["AvailabilityResult_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: GraphQLTypes["AvailabilityResult_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: GraphQLTypes["AvailabilityResult_delete_key_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: GraphQLTypes["AvailabilityResult_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["AvailabilityResult_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["AvailabilityResult_bool_exp"]
};
	/** order by aggregate values of table "Availability" */
["Availability_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Availability_max_order_by"],
	min?: GraphQLTypes["Availability_min_order_by"]
};
	/** Boolean expression to filter rows from the table "Availability". All fields are combined with a logical 'AND'. */
["Availability_bool_exp"]: {
		Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["Availability_bool_exp"]>,
	_not?: GraphQLTypes["Availability_bool_exp"],
	_or?: Array<GraphQLTypes["Availability_bool_exp"]>,
	beginsAt?: GraphQLTypes["timestamptz_comparison_exp"],
	endsAt?: GraphQLTypes["timestamptz_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	serviceArea?: GraphQLTypes["geometry_comparison_exp"]
};
	/** order by max() on columns of table "Availability" */
["Availability_max_order_by"]: {
		beginsAt?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "Availability" */
["Availability_min_order_by"]: {
		beginsAt?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "Availability". */
["Availability_order_by"]: {
		Person?: GraphQLTypes["Person_order_by"],
	beginsAt?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	serviceArea?: GraphQLTypes["order_by"]
};
	/** select columns of table "Availability" */
["Availability_select_column"]: Availability_select_column;
	/** Streaming cursor of the table "Availability" */
["Availability_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Availability_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Availability_stream_cursor_value_input"]: {
		beginsAt?: GraphQLTypes["timestamptz"],
	endsAt?: GraphQLTypes["timestamptz"],
	personId?: GraphQLTypes["uuid"],
	serviceArea?: GraphQLTypes["geometry"]
};
	/** columns and relationships of "AvailableAppointment" */
["AvailableAppointment"]: {
	__typename: "AvailableAppointment",
	beginsAt: GraphQLTypes["timestamptz"],
	endsAt: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "AvailableAppointment". All fields are combined with a logical 'AND'. */
["AvailableAppointment_bool_exp"]: {
		_and?: Array<GraphQLTypes["AvailableAppointment_bool_exp"]>,
	_not?: GraphQLTypes["AvailableAppointment_bool_exp"],
	_or?: Array<GraphQLTypes["AvailableAppointment_bool_exp"]>,
	beginsAt?: GraphQLTypes["timestamptz_comparison_exp"],
	endsAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** Ordering options when selecting data from "AvailableAppointment". */
["AvailableAppointment_order_by"]: {
		beginsAt?: GraphQLTypes["order_by"],
	endsAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "AvailableAppointment" */
["AvailableAppointment_select_column"]: AvailableAppointment_select_column;
	/** Streaming cursor of the table "AvailableAppointment" */
["AvailableAppointment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["AvailableAppointment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["AvailableAppointment_stream_cursor_value_input"]: {
		beginsAt?: GraphQLTypes["timestamptz"],
	endsAt?: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
		_eq?: boolean,
	_gt?: boolean,
	_gte?: boolean,
	_in?: Array<boolean>,
	_is_null?: boolean,
	_lt?: boolean,
	_lte?: boolean,
	_neq?: boolean,
	_nin?: Array<boolean>
};
	/** columns and relationships of "ClientRelease" */
["ClientRelease"]: {
	__typename: "ClientRelease",
	build: string,
	client: GraphQLTypes["ClientType_enum"],
	createdAt: GraphQLTypes["timestamptz"],
	expirationDate?: GraphQLTypes["timestamptz"],
	expirationMessage?: string,
	id: GraphQLTypes["uuid"],
	releaseDate: GraphQLTypes["timestamptz"],
	updatedAt: GraphQLTypes["timestamptz"],
	version: string
};
	/** Boolean expression to filter rows from the table "ClientRelease". All fields are combined with a logical 'AND'. */
["ClientRelease_bool_exp"]: {
		_and?: Array<GraphQLTypes["ClientRelease_bool_exp"]>,
	_not?: GraphQLTypes["ClientRelease_bool_exp"],
	_or?: Array<GraphQLTypes["ClientRelease_bool_exp"]>,
	build?: GraphQLTypes["String_comparison_exp"],
	client?: GraphQLTypes["ClientType_enum_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	expirationDate?: GraphQLTypes["timestamptz_comparison_exp"],
	expirationMessage?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	releaseDate?: GraphQLTypes["timestamptz_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	version?: GraphQLTypes["String_comparison_exp"]
};
	/** Ordering options when selecting data from "ClientRelease". */
["ClientRelease_order_by"]: {
		build?: GraphQLTypes["order_by"],
	client?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	expirationDate?: GraphQLTypes["order_by"],
	expirationMessage?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	releaseDate?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	version?: GraphQLTypes["order_by"]
};
	/** select columns of table "ClientRelease" */
["ClientRelease_select_column"]: ClientRelease_select_column;
	/** Streaming cursor of the table "ClientRelease" */
["ClientRelease_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ClientRelease_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ClientRelease_stream_cursor_value_input"]: {
		build?: string,
	client?: GraphQLTypes["ClientType_enum"],
	createdAt?: GraphQLTypes["timestamptz"],
	expirationDate?: GraphQLTypes["timestamptz"],
	expirationMessage?: string,
	id?: GraphQLTypes["uuid"],
	releaseDate?: GraphQLTypes["timestamptz"],
	updatedAt?: GraphQLTypes["timestamptz"],
	version?: string
};
	/** columns and relationships of "ClientType" */
["ClientType"]: {
	__typename: "ClientType",
	description?: string,
	value: string
};
	/** Boolean expression to filter rows from the table "ClientType". All fields are combined with a logical 'AND'. */
["ClientType_bool_exp"]: {
		_and?: Array<GraphQLTypes["ClientType_bool_exp"]>,
	_not?: GraphQLTypes["ClientType_bool_exp"],
	_or?: Array<GraphQLTypes["ClientType_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["ClientType_enum"]: ClientType_enum;
	/** Boolean expression to compare columns of type "ClientType_enum". All fields are combined with logical 'AND'. */
["ClientType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["ClientType_enum"],
	_in?: Array<GraphQLTypes["ClientType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["ClientType_enum"],
	_nin?: Array<GraphQLTypes["ClientType_enum"]>
};
	/** Ordering options when selecting data from "ClientType". */
["ClientType_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "ClientType" */
["ClientType_select_column"]: ClientType_select_column;
	/** Streaming cursor of the table "ClientType" */
["ClientType_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ClientType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ClientType_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** columns and relationships of "ContentBlock" */
["ContentBlock"]: {
	__typename: "ContentBlock",
	body: string,
	key: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "ContentBlock". All fields are combined with a logical 'AND'. */
["ContentBlock_bool_exp"]: {
		_and?: Array<GraphQLTypes["ContentBlock_bool_exp"]>,
	_not?: GraphQLTypes["ContentBlock_bool_exp"],
	_or?: Array<GraphQLTypes["ContentBlock_bool_exp"]>,
	body?: GraphQLTypes["String_comparison_exp"],
	key?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** Ordering options when selecting data from "ContentBlock". */
["ContentBlock_order_by"]: {
		body?: GraphQLTypes["order_by"],
	key?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "ContentBlock" */
["ContentBlock_select_column"]: ContentBlock_select_column;
	/** Streaming cursor of the table "ContentBlock" */
["ContentBlock_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ContentBlock_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ContentBlock_stream_cursor_value_input"]: {
		body?: string,
	key?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	["CreationSourceType_enum"]: CreationSourceType_enum;
	/** Boolean expression to compare columns of type "CreationSourceType_enum". All fields are combined with logical 'AND'. */
["CreationSourceType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["CreationSourceType_enum"],
	_in?: Array<GraphQLTypes["CreationSourceType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["CreationSourceType_enum"],
	_nin?: Array<GraphQLTypes["CreationSourceType_enum"]>
};
	/** Tracks a request from a Person to delete all of their data */
["DataDeletionRequest"]: {
	__typename: "DataDeletionRequest",
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	personId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "DataDeletionRequest". All fields are combined with a logical 'AND'. */
["DataDeletionRequest_bool_exp"]: {
		_and?: Array<GraphQLTypes["DataDeletionRequest_bool_exp"]>,
	_not?: GraphQLTypes["DataDeletionRequest_bool_exp"],
	_or?: Array<GraphQLTypes["DataDeletionRequest_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "DataDeletionRequest" */
["DataDeletionRequest_constraint"]: DataDeletionRequest_constraint;
	/** input type for inserting data into table "DataDeletionRequest" */
["DataDeletionRequest_insert_input"]: {
		id?: GraphQLTypes["uuid"]
};
	/** response of any mutation on the table "DataDeletionRequest" */
["DataDeletionRequest_mutation_response"]: {
	__typename: "DataDeletionRequest_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["DataDeletionRequest"]>
};
	/** on_conflict condition type for table "DataDeletionRequest" */
["DataDeletionRequest_on_conflict"]: {
		constraint: GraphQLTypes["DataDeletionRequest_constraint"],
	update_columns: Array<GraphQLTypes["DataDeletionRequest_update_column"]>,
	where?: GraphQLTypes["DataDeletionRequest_bool_exp"]
};
	/** Ordering options when selecting data from "DataDeletionRequest". */
["DataDeletionRequest_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "DataDeletionRequest" */
["DataDeletionRequest_select_column"]: DataDeletionRequest_select_column;
	/** Streaming cursor of the table "DataDeletionRequest" */
["DataDeletionRequest_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["DataDeletionRequest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["DataDeletionRequest_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** placeholder for update columns of table "DataDeletionRequest" (current role has no relevant permissions) */
["DataDeletionRequest_update_column"]: DataDeletionRequest_update_column;
	["DecryptedMessage"]: {
	__typename: "DecryptedMessage",
	data: string
};
	/** Tracks devices used by a Person */
["Device"]: {
	__typename: "Device",
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	appBuildNumber: string,
	brand: string,
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	model: string,
	osVersion: string,
	personId: GraphQLTypes["uuid"],
	type: GraphQLTypes["DeviceType_enum"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	["DeviceType_enum"]: DeviceType_enum;
	/** Boolean expression to compare columns of type "DeviceType_enum". All fields are combined with logical 'AND'. */
["DeviceType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["DeviceType_enum"],
	_in?: Array<GraphQLTypes["DeviceType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["DeviceType_enum"],
	_nin?: Array<GraphQLTypes["DeviceType_enum"]>
};
	/** aggregated selection of "Device" */
["Device_aggregate"]: {
	__typename: "Device_aggregate",
	aggregate?: GraphQLTypes["Device_aggregate_fields"],
	nodes: Array<GraphQLTypes["Device"]>
};
	["Device_aggregate_bool_exp"]: {
		count?: GraphQLTypes["Device_aggregate_bool_exp_count"]
};
	["Device_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["Device_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["Device_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Device" */
["Device_aggregate_fields"]: {
	__typename: "Device_aggregate_fields",
	count: number,
	max?: GraphQLTypes["Device_max_fields"],
	min?: GraphQLTypes["Device_min_fields"]
};
	/** order by aggregate values of table "Device" */
["Device_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Device_max_order_by"],
	min?: GraphQLTypes["Device_min_order_by"]
};
	/** input type for inserting array relation for remote table "Device" */
["Device_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["Device_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["Device_on_conflict"]
};
	/** Boolean expression to filter rows from the table "Device". All fields are combined with a logical 'AND'. */
["Device_bool_exp"]: {
		Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["Device_bool_exp"]>,
	_not?: GraphQLTypes["Device_bool_exp"],
	_or?: Array<GraphQLTypes["Device_bool_exp"]>,
	appBuildNumber?: GraphQLTypes["String_comparison_exp"],
	brand?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	model?: GraphQLTypes["String_comparison_exp"],
	osVersion?: GraphQLTypes["String_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	type?: GraphQLTypes["DeviceType_enum_comparison_exp"],
	uniqueIdentifier?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "Device" */
["Device_constraint"]: Device_constraint;
	/** input type for inserting data into table "Device" */
["Device_insert_input"]: {
		Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	appBuildNumber?: string,
	brand?: string,
	model?: string,
	osVersion?: string,
	type?: GraphQLTypes["DeviceType_enum"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: string
};
	/** aggregate max on columns */
["Device_max_fields"]: {
	__typename: "Device_max_fields",
	appBuildNumber?: string,
	brand?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	model?: string,
	osVersion?: string,
	personId?: GraphQLTypes["uuid"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "Device" */
["Device_max_order_by"]: {
		appBuildNumber?: GraphQLTypes["order_by"],
	brand?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	model?: GraphQLTypes["order_by"],
	osVersion?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["Device_min_fields"]: {
	__typename: "Device_min_fields",
	appBuildNumber?: string,
	brand?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	model?: string,
	osVersion?: string,
	personId?: GraphQLTypes["uuid"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "Device" */
["Device_min_order_by"]: {
		appBuildNumber?: GraphQLTypes["order_by"],
	brand?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	model?: GraphQLTypes["order_by"],
	osVersion?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Device" */
["Device_mutation_response"]: {
	__typename: "Device_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Device"]>
};
	/** on_conflict condition type for table "Device" */
["Device_on_conflict"]: {
		constraint: GraphQLTypes["Device_constraint"],
	update_columns: Array<GraphQLTypes["Device_update_column"]>,
	where?: GraphQLTypes["Device_bool_exp"]
};
	/** Ordering options when selecting data from "Device". */
["Device_order_by"]: {
		Person?: GraphQLTypes["Person_order_by"],
	appBuildNumber?: GraphQLTypes["order_by"],
	brand?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	model?: GraphQLTypes["order_by"],
	osVersion?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	uniqueIdentifier?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Device */
["Device_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "Device" */
["Device_select_column"]: Device_select_column;
	/** input type for updating data in table "Device" */
["Device_set_input"]: {
		appBuildNumber?: string,
	brand?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	model?: string,
	osVersion?: string,
	type?: GraphQLTypes["DeviceType_enum"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: string
};
	/** Streaming cursor of the table "Device" */
["Device_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Device_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Device_stream_cursor_value_input"]: {
		appBuildNumber?: string,
	brand?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	model?: string,
	osVersion?: string,
	personId?: GraphQLTypes["uuid"],
	type?: GraphQLTypes["DeviceType_enum"],
	/** Vendor provided identifier which tracks individual devices per person. */
	uniqueIdentifier?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** update columns of table "Device" */
["Device_update_column"]: Device_update_column;
	["Device_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Device_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Device_bool_exp"]
};
	["EncryptedMessage"]: {
	__typename: "EncryptedMessage",
	message: string
};
	/** columns and relationships of "ExternalIdentifier" */
["ExternalIdentifier"]: {
	__typename: "ExternalIdentifier",
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	androidIntercomDigest?: string,
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	iosIntercomDigest?: string,
	personId: GraphQLTypes["uuid"],
	sendbirdToken?: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "ExternalIdentifier". All fields are combined with a logical 'AND'. */
["ExternalIdentifier_bool_exp"]: {
		Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["ExternalIdentifier_bool_exp"]>,
	_not?: GraphQLTypes["ExternalIdentifier_bool_exp"],
	_or?: Array<GraphQLTypes["ExternalIdentifier_bool_exp"]>,
	androidIntercomDigest?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	iosIntercomDigest?: GraphQLTypes["String_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	sendbirdToken?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** Ordering options when selecting data from "ExternalIdentifier". */
["ExternalIdentifier_order_by"]: {
		Person?: GraphQLTypes["Person_order_by"],
	androidIntercomDigest?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	iosIntercomDigest?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	sendbirdToken?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "ExternalIdentifier" */
["ExternalIdentifier_select_column"]: ExternalIdentifier_select_column;
	/** Streaming cursor of the table "ExternalIdentifier" */
["ExternalIdentifier_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ExternalIdentifier_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ExternalIdentifier_stream_cursor_value_input"]: {
		androidIntercomDigest?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	iosIntercomDigest?: string,
	personId?: GraphQLTypes["uuid"],
	sendbirdToken?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** Definition of a service area */
["GeographicRegion"]: {
	__typename: "GeographicRegion",
	/** An array relationship */
	GeographicRegionHandymen: Array<GraphQLTypes["GeographicRegionHandyman"]>,
	/** An array relationship */
	GeographicRegionNeighborhoods: Array<GraphQLTypes["GeographicRegionNeighborhood"]>,
	/** An array relationship */
	Homes: Array<GraphQLTypes["Home"]>,
	availableStartingAt: GraphQLTypes["timestamptz"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	name: string,
	servicedZipCodes: Array<string>,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Handymen assigned to a geographic region */
["GeographicRegionHandyman"]: {
	__typename: "GeographicRegionHandyman",
	/** An object relationship */
	GeographicRegion: GraphQLTypes["GeographicRegion"],
	/** An object relationship */
	Handyman: GraphQLTypes["Person"],
	createdAt: GraphQLTypes["timestamptz"],
	geographicRegionId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	isPrimary: boolean,
	personId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["GeographicRegionHandyman_max_order_by"],
	min?: GraphQLTypes["GeographicRegionHandyman_min_order_by"]
};
	/** Boolean expression to filter rows from the table "GeographicRegionHandyman". All fields are combined with a logical 'AND'. */
["GeographicRegionHandyman_bool_exp"]: {
		GeographicRegion?: GraphQLTypes["GeographicRegion_bool_exp"],
	Handyman?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["GeographicRegionHandyman_bool_exp"]>,
	_not?: GraphQLTypes["GeographicRegionHandyman_bool_exp"],
	_or?: Array<GraphQLTypes["GeographicRegionHandyman_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	geographicRegionId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	isPrimary?: GraphQLTypes["Boolean_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "GeographicRegionHandyman". */
["GeographicRegionHandyman_order_by"]: {
		GeographicRegion?: GraphQLTypes["GeographicRegion_order_by"],
	Handyman?: GraphQLTypes["Person_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	isPrimary?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "GeographicRegionHandyman" */
["GeographicRegionHandyman_select_column"]: GeographicRegionHandyman_select_column;
	/** Streaming cursor of the table "GeographicRegionHandyman" */
["GeographicRegionHandyman_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["GeographicRegionHandyman_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegionHandyman_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	geographicRegionId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	isPrimary?: boolean,
	personId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** Separates large population zip codes into regions. Used for first time onboarding. */
["GeographicRegionNeighborhood"]: {
	__typename: "GeographicRegionNeighborhood",
	/** An object relationship */
	GeographicRegion: GraphQLTypes["GeographicRegion"],
	createdAt: GraphQLTypes["timestamptz"],
	geographicRegionId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	name: string,
	updatedAt: GraphQLTypes["timestamptz"],
	zipCode: string
};
	/** order by aggregate values of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["GeographicRegionNeighborhood_max_order_by"],
	min?: GraphQLTypes["GeographicRegionNeighborhood_min_order_by"]
};
	/** Boolean expression to filter rows from the table "GeographicRegionNeighborhood". All fields are combined with a logical 'AND'. */
["GeographicRegionNeighborhood_bool_exp"]: {
		GeographicRegion?: GraphQLTypes["GeographicRegion_bool_exp"],
	_and?: Array<GraphQLTypes["GeographicRegionNeighborhood_bool_exp"]>,
	_not?: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"],
	_or?: Array<GraphQLTypes["GeographicRegionNeighborhood_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	geographicRegionId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	zipCode?: GraphQLTypes["String_comparison_exp"]
};
	/** order by max() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "GeographicRegionNeighborhood". */
["GeographicRegionNeighborhood_order_by"]: {
		GeographicRegion?: GraphQLTypes["GeographicRegion_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** select columns of table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_select_column"]: GeographicRegionNeighborhood_select_column;
	/** Streaming cursor of the table "GeographicRegionNeighborhood" */
["GeographicRegionNeighborhood_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["GeographicRegionNeighborhood_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegionNeighborhood_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	geographicRegionId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	name?: string,
	updatedAt?: GraphQLTypes["timestamptz"],
	zipCode?: string
};
	/** Boolean expression to filter rows from the table "GeographicRegion". All fields are combined with a logical 'AND'. */
["GeographicRegion_bool_exp"]: {
		GeographicRegionHandymen?: GraphQLTypes["GeographicRegionHandyman_bool_exp"],
	GeographicRegionNeighborhoods?: GraphQLTypes["GeographicRegionNeighborhood_bool_exp"],
	Homes?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["GeographicRegion_bool_exp"]>,
	_not?: GraphQLTypes["GeographicRegion_bool_exp"],
	_or?: Array<GraphQLTypes["GeographicRegion_bool_exp"]>,
	availableStartingAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	servicedZipCodes?: GraphQLTypes["String_array_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	["GeographicRegion_by_zipCode_args"]: {
		zipCode?: string
};
	/** Ordering options when selecting data from "GeographicRegion". */
["GeographicRegion_order_by"]: {
		GeographicRegionHandymen_aggregate?: GraphQLTypes["GeographicRegionHandyman_aggregate_order_by"],
	GeographicRegionNeighborhoods_aggregate?: GraphQLTypes["GeographicRegionNeighborhood_aggregate_order_by"],
	Homes_aggregate?: GraphQLTypes["Home_aggregate_order_by"],
	availableStartingAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	servicedZipCodes?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "GeographicRegion" */
["GeographicRegion_select_column"]: GeographicRegion_select_column;
	/** Streaming cursor of the table "GeographicRegion" */
["GeographicRegion_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["GeographicRegion_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["GeographicRegion_stream_cursor_value_input"]: {
		availableStartingAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	name?: string,
	servicedZipCodes?: Array<string>,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** columns and relationships of "Home" */
["Home"]: {
	__typename: "Home",
	/** An array relationship */
	AcceptedReferrals: Array<GraphQLTypes["Referral"]>,
	/** An array relationship */
	AppointmentGrades: Array<GraphQLTypes["AppointmentGrade"]>,
	/** An array relationship */
	Appointments: Array<GraphQLTypes["Appointment"]>,
	/** An aggregate relationship */
	Appointments_aggregate: GraphQLTypes["Appointment_aggregate"],
	/** An object relationship */
	GeographicRegion?: GraphQLTypes["GeographicRegion"],
	/** An array relationship */
	HomeInventoryItems: Array<GraphQLTypes["HomeInventoryItem"]>,
	/** An array relationship */
	InvitedReferrals: Array<GraphQLTypes["Referral"]>,
	/** An array relationship */
	PersonHomes: Array<GraphQLTypes["PersonHome"]>,
	/** An object relationship */
	SchedulingRegion?: GraphQLTypes["Region"],
	/** An array relationship */
	ServiceOfferResponses: Array<GraphQLTypes["ServiceOfferResponse"]>,
	/** An array relationship */
	StripePaymentMethods: Array<GraphQLTypes["StripePaymentMethod"]>,
	/** An array relationship */
	StripeSubscriptions: Array<GraphQLTypes["StripeSubscription"]>,
	/** An array relationship */
	Tasks: Array<GraphQLTypes["Task"]>,
	/** An aggregate relationship */
	Tasks_aggregate: GraphQLTypes["Task_aggregate"],
	/** If the home is currently an active member of the service */
	activeMember: boolean,
	city: string,
	createdAt: GraphQLTypes["timestamptz"],
	geographicRegionId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	location?: GraphQLTypes["geography"],
	referralCode: string,
	schedulingRegionId?: GraphQLTypes["uuid"],
	sendbirdChannelId?: string,
	state: string,
	status: GraphQLTypes["HomeStatus_enum"],
	streetAddress: string,
	stripeDefaultSourcePresent: boolean,
	stripeId?: string,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?: string,
	stripePromoCode?: string,
	/** IANA time zone */
	timeZone: string,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?: GraphQLTypes["date"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?: GraphQLTypes["date"],
	updatedAt: GraphQLTypes["timestamptz"],
	wifiCredentialsE?: string,
	zipCode: string
};
	/** columns and relationships of "HomeInventoryItem" */
["HomeInventoryItem"]: {
	__typename: "HomeInventoryItem",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An array relationship */
	HomeInventoryItemAttachments: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** An object relationship */
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory"],
	createdAt: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	isArchived: boolean,
	lastServicedAt?: GraphQLTypes["timestamptz"],
	name: string,
	notes?: string,
	serviceFrequency?: number,
	serviceProvider: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType: GraphQLTypes["HomeInventoryServiceType_enum"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** columns and relationships of "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment"]: {
	__typename: "HomeInventoryItemAttachment",
	/** An object relationship */
	Attachment: GraphQLTypes["Attachment"],
	/** An object relationship */
	HomeInventoryItem: GraphQLTypes["HomeInventoryItem"],
	/** An object relationship */
	HomeInventoryItemCategoryPrompt?: GraphQLTypes["HomeInventoryItemCategoryPrompt"],
	attachmentId: GraphQLTypes["uuid"],
	homeInventoryCategoryPromptId?: GraphQLTypes["uuid"],
	homeInventoryItemId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	rank: number
};
	/** order by aggregate values of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_aggregate_order_by"]: {
		avg?: GraphQLTypes["HomeInventoryItemAttachment_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["HomeInventoryItemAttachment_max_order_by"],
	min?: GraphQLTypes["HomeInventoryItemAttachment_min_order_by"],
	stddev?: GraphQLTypes["HomeInventoryItemAttachment_stddev_order_by"],
	stddev_pop?: GraphQLTypes["HomeInventoryItemAttachment_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["HomeInventoryItemAttachment_stddev_samp_order_by"],
	sum?: GraphQLTypes["HomeInventoryItemAttachment_sum_order_by"],
	var_pop?: GraphQLTypes["HomeInventoryItemAttachment_var_pop_order_by"],
	var_samp?: GraphQLTypes["HomeInventoryItemAttachment_var_samp_order_by"],
	variance?: GraphQLTypes["HomeInventoryItemAttachment_variance_order_by"]
};
	/** input type for inserting array relation for remote table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["HomeInventoryItemAttachment_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["HomeInventoryItemAttachment_on_conflict"]
};
	/** order by avg() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_avg_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemAttachment". All fields are combined with a logical 'AND'. */
["HomeInventoryItemAttachment_bool_exp"]: {
		Attachment?: GraphQLTypes["Attachment_bool_exp"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	HomeInventoryItemCategoryPrompt?: GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryItemAttachment_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryItemAttachment_bool_exp"]>,
	attachmentId?: GraphQLTypes["uuid_comparison_exp"],
	homeInventoryCategoryPromptId?: GraphQLTypes["uuid_comparison_exp"],
	homeInventoryItemId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_constraint"]: HomeInventoryItemAttachment_constraint;
	/** input type for incrementing numeric columns in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_inc_input"]: {
		rank?: number
};
	/** input type for inserting data into table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_insert_input"]: {
		Attachment?: GraphQLTypes["Attachment_obj_rel_insert_input"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_obj_rel_insert_input"],
	attachmentId?: GraphQLTypes["uuid"],
	homeInventoryCategoryPromptId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	rank?: number
};
	/** order by max() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_max_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	homeInventoryCategoryPromptId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_min_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	homeInventoryCategoryPromptId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_mutation_response"]: {
	__typename: "HomeInventoryItemAttachment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["HomeInventoryItemAttachment"]>
};
	/** on_conflict condition type for table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_on_conflict"]: {
		constraint: GraphQLTypes["HomeInventoryItemAttachment_constraint"],
	update_columns: Array<GraphQLTypes["HomeInventoryItemAttachment_update_column"]>,
	where?: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"]
};
	/** Ordering options when selecting data from "HomeInventoryItemAttachment". */
["HomeInventoryItemAttachment_order_by"]: {
		Attachment?: GraphQLTypes["Attachment_order_by"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_order_by"],
	HomeInventoryItemCategoryPrompt?: GraphQLTypes["HomeInventoryItemCategoryPrompt_order_by"],
	attachmentId?: GraphQLTypes["order_by"],
	homeInventoryCategoryPromptId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: HomeInventoryItemAttachment */
["HomeInventoryItemAttachment_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_select_column"]: HomeInventoryItemAttachment_select_column;
	/** input type for updating data in table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_set_input"]: {
		attachmentId?: GraphQLTypes["uuid"],
	homeInventoryCategoryPromptId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	rank?: number
};
	/** order by stddev() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stddev_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryItemAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemAttachment_stream_cursor_value_input"]: {
		attachmentId?: GraphQLTypes["uuid"],
	homeInventoryCategoryPromptId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	rank?: number
};
	/** order by sum() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_sum_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** update columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_update_column"]: HomeInventoryItemAttachment_update_column;
	["HomeInventoryItemAttachment_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["HomeInventoryItemAttachment_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["HomeInventoryItemAttachment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_var_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "HomeInventoryItemAttachment" */
["HomeInventoryItemAttachment_variance_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "HomeInventoryItemCategory" */
["HomeInventoryItemCategory"]: {
	__typename: "HomeInventoryItemCategory",
	/** An array relationship */
	HomeInventoryItemCategoryIcons: Array<GraphQLTypes["HomeInventoryItemCategoryIcon"]>,
	/** An array relationship */
	HomeInventoryItemCategoryPrompts: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt"]>,
	/** An array relationship */
	HomeInventoryItems: Array<GraphQLTypes["HomeInventoryItem"]>,
	description?: string,
	id: GraphQLTypes["uuid"],
	serviceDescription?: string,
	serviceFrequency?: number,
	servicePartName?: string,
	servicePartPartyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	servicePartsRequired: boolean,
	serviceProvider: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType: GraphQLTypes["HomeInventoryServiceType_enum"]
};
	/** columns and relationships of "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon"]: {
	__typename: "HomeInventoryItemCategoryIcon",
	/** An object relationship */
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory"],
	/** An object relationship */
	ImageAssetAttachment: GraphQLTypes["Attachment"],
	/** An object relationship */
	VectorAssetAttachment: GraphQLTypes["Attachment"],
	createdAt: GraphQLTypes["timestamptz"],
	friendlyAssetId: string,
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	imageAssetAttachmentId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"],
	vectorAssetAttachmentId: GraphQLTypes["uuid"]
};
	/** order by aggregate values of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["HomeInventoryItemCategoryIcon_max_order_by"],
	min?: GraphQLTypes["HomeInventoryItemCategoryIcon_min_order_by"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryIcon". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryIcon_bool_exp"]: {
		HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_bool_exp"],
	ImageAssetAttachment?: GraphQLTypes["Attachment_bool_exp"],
	VectorAssetAttachment?: GraphQLTypes["Attachment_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryItemCategoryIcon_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryItemCategoryIcon_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryItemCategoryIcon_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	friendlyAssetId?: GraphQLTypes["String_comparison_exp"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	imageAssetAttachmentId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	vectorAssetAttachmentId?: GraphQLTypes["uuid_comparison_exp"]
};
	/** order by max() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	imageAssetAttachmentId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	vectorAssetAttachmentId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	imageAssetAttachmentId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	vectorAssetAttachmentId?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "HomeInventoryItemCategoryIcon". */
["HomeInventoryItemCategoryIcon_order_by"]: {
		HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_order_by"],
	ImageAssetAttachment?: GraphQLTypes["Attachment_order_by"],
	VectorAssetAttachment?: GraphQLTypes["Attachment_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	imageAssetAttachmentId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	vectorAssetAttachmentId?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_select_column"]: HomeInventoryItemCategoryIcon_select_column;
	/** Streaming cursor of the table "HomeInventoryItemCategoryIcon" */
["HomeInventoryItemCategoryIcon_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryItemCategoryIcon_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryIcon_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	friendlyAssetId?: string,
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	imageAssetAttachmentId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"],
	vectorAssetAttachmentId?: GraphQLTypes["uuid"]
};
	/** columns and relationships of "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt"]: {
	__typename: "HomeInventoryItemCategoryPrompt",
	/** An object relationship */
	ExampleAttachment: GraphQLTypes["Attachment"],
	/** An array relationship */
	HomeInventoryItemAttachments: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** An object relationship */
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory"],
	body: string,
	createdAt: GraphQLTypes["timestamptz"],
	defaultCaption: string,
	exampleAttachmentId: GraphQLTypes["uuid"],
	friendlyAssetId: string,
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	inputHint: string,
	rank: number,
	title: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_aggregate_order_by"]: {
		avg?: GraphQLTypes["HomeInventoryItemCategoryPrompt_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["HomeInventoryItemCategoryPrompt_max_order_by"],
	min?: GraphQLTypes["HomeInventoryItemCategoryPrompt_min_order_by"],
	stddev?: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_order_by"],
	stddev_pop?: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"],
	sum?: GraphQLTypes["HomeInventoryItemCategoryPrompt_sum_order_by"],
	var_pop?: GraphQLTypes["HomeInventoryItemCategoryPrompt_var_pop_order_by"],
	var_samp?: GraphQLTypes["HomeInventoryItemCategoryPrompt_var_samp_order_by"],
	variance?: GraphQLTypes["HomeInventoryItemCategoryPrompt_variance_order_by"]
};
	/** order by avg() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_avg_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategoryPrompt". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategoryPrompt_bool_exp"]: {
		ExampleAttachment?: GraphQLTypes["Attachment_bool_exp"],
	HomeInventoryItemAttachments?: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"],
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"]>,
	body?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	defaultCaption?: GraphQLTypes["String_comparison_exp"],
	exampleAttachmentId?: GraphQLTypes["uuid_comparison_exp"],
	friendlyAssetId?: GraphQLTypes["String_comparison_exp"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	inputHint?: GraphQLTypes["String_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_max_order_by"]: {
		body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	defaultCaption?: GraphQLTypes["order_by"],
	exampleAttachmentId?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	inputHint?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_min_order_by"]: {
		body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	defaultCaption?: GraphQLTypes["order_by"],
	exampleAttachmentId?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	inputHint?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "HomeInventoryItemCategoryPrompt". */
["HomeInventoryItemCategoryPrompt_order_by"]: {
		ExampleAttachment?: GraphQLTypes["Attachment_order_by"],
	HomeInventoryItemAttachments_aggregate?: GraphQLTypes["HomeInventoryItemAttachment_aggregate_order_by"],
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_order_by"],
	body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	defaultCaption?: GraphQLTypes["order_by"],
	exampleAttachmentId?: GraphQLTypes["order_by"],
	friendlyAssetId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	inputHint?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_select_column"]: HomeInventoryItemCategoryPrompt_select_column;
	/** order by stddev() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stddev_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategoryPrompt_stream_cursor_value_input"]: {
		body?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	defaultCaption?: string,
	exampleAttachmentId?: GraphQLTypes["uuid"],
	friendlyAssetId?: string,
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	inputHint?: string,
	rank?: number,
	title?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_sum_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_pop() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_var_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "HomeInventoryItemCategoryPrompt" */
["HomeInventoryItemCategoryPrompt_variance_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryItemCategory". All fields are combined with a logical 'AND'. */
["HomeInventoryItemCategory_bool_exp"]: {
		HomeInventoryItemCategoryIcons?: GraphQLTypes["HomeInventoryItemCategoryIcon_bool_exp"],
	HomeInventoryItemCategoryPrompts?: GraphQLTypes["HomeInventoryItemCategoryPrompt_bool_exp"],
	HomeInventoryItems?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryItemCategory_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryItemCategory_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryItemCategory_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	serviceDescription?: GraphQLTypes["String_comparison_exp"],
	serviceFrequency?: GraphQLTypes["Int_comparison_exp"],
	servicePartName?: GraphQLTypes["String_comparison_exp"],
	servicePartPartyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum_comparison_exp"],
	servicePartsRequired?: GraphQLTypes["Boolean_comparison_exp"],
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum_comparison_exp"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum_comparison_exp"]
};
	/** Ordering options when selecting data from "HomeInventoryItemCategory". */
["HomeInventoryItemCategory_order_by"]: {
		HomeInventoryItemCategoryIcons_aggregate?: GraphQLTypes["HomeInventoryItemCategoryIcon_aggregate_order_by"],
	HomeInventoryItemCategoryPrompts_aggregate?: GraphQLTypes["HomeInventoryItemCategoryPrompt_aggregate_order_by"],
	HomeInventoryItems_aggregate?: GraphQLTypes["HomeInventoryItem_aggregate_order_by"],
	description?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	serviceDescription?: GraphQLTypes["order_by"],
	serviceFrequency?: GraphQLTypes["order_by"],
	servicePartName?: GraphQLTypes["order_by"],
	servicePartPartyResponsible?: GraphQLTypes["order_by"],
	servicePartsRequired?: GraphQLTypes["order_by"],
	serviceProvider?: GraphQLTypes["order_by"],
	serviceType?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_select_column"]: HomeInventoryItemCategory_select_column;
	/** Streaming cursor of the table "HomeInventoryItemCategory" */
["HomeInventoryItemCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryItemCategory_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItemCategory_stream_cursor_value_input"]: {
		description?: string,
	id?: GraphQLTypes["uuid"],
	serviceDescription?: string,
	serviceFrequency?: number,
	servicePartName?: string,
	servicePartPartyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	servicePartsRequired?: boolean,
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum"]
};
	/** order by aggregate values of table "HomeInventoryItem" */
["HomeInventoryItem_aggregate_order_by"]: {
		avg?: GraphQLTypes["HomeInventoryItem_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["HomeInventoryItem_max_order_by"],
	min?: GraphQLTypes["HomeInventoryItem_min_order_by"],
	stddev?: GraphQLTypes["HomeInventoryItem_stddev_order_by"],
	stddev_pop?: GraphQLTypes["HomeInventoryItem_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["HomeInventoryItem_stddev_samp_order_by"],
	sum?: GraphQLTypes["HomeInventoryItem_sum_order_by"],
	var_pop?: GraphQLTypes["HomeInventoryItem_var_pop_order_by"],
	var_samp?: GraphQLTypes["HomeInventoryItem_var_samp_order_by"],
	variance?: GraphQLTypes["HomeInventoryItem_variance_order_by"]
};
	/** order by avg() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_avg_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryItem". All fields are combined with a logical 'AND'. */
["HomeInventoryItem_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	HomeInventoryItemAttachments?: GraphQLTypes["HomeInventoryItemAttachment_bool_exp"],
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryItem_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryItem_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	isArchived?: GraphQLTypes["Boolean_comparison_exp"],
	lastServicedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	notes?: GraphQLTypes["String_comparison_exp"],
	serviceFrequency?: GraphQLTypes["Int_comparison_exp"],
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum_comparison_exp"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "HomeInventoryItem" */
["HomeInventoryItem_constraint"]: HomeInventoryItem_constraint;
	/** input type for incrementing numeric columns in table "HomeInventoryItem" */
["HomeInventoryItem_inc_input"]: {
		serviceFrequency?: number
};
	/** input type for inserting data into table "HomeInventoryItem" */
["HomeInventoryItem_insert_input"]: {
		HomeInventoryItemAttachments?: GraphQLTypes["HomeInventoryItemAttachment_arr_rel_insert_input"],
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	isArchived?: boolean,
	lastServicedAt?: GraphQLTypes["timestamptz"],
	name?: string,
	notes?: string,
	serviceFrequency?: number,
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum"]
};
	/** order by max() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastServicedAt?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	serviceFrequency?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastServicedAt?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	serviceFrequency?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "HomeInventoryItem" */
["HomeInventoryItem_mutation_response"]: {
	__typename: "HomeInventoryItem_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["HomeInventoryItem"]>
};
	/** input type for inserting object relation for remote table "HomeInventoryItem" */
["HomeInventoryItem_obj_rel_insert_input"]: {
		data: GraphQLTypes["HomeInventoryItem_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["HomeInventoryItem_on_conflict"]
};
	/** on_conflict condition type for table "HomeInventoryItem" */
["HomeInventoryItem_on_conflict"]: {
		constraint: GraphQLTypes["HomeInventoryItem_constraint"],
	update_columns: Array<GraphQLTypes["HomeInventoryItem_update_column"]>,
	where?: GraphQLTypes["HomeInventoryItem_bool_exp"]
};
	/** Ordering options when selecting data from "HomeInventoryItem". */
["HomeInventoryItem_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	HomeInventoryItemAttachments_aggregate?: GraphQLTypes["HomeInventoryItemAttachment_aggregate_order_by"],
	HomeInventoryItemCategory?: GraphQLTypes["HomeInventoryItemCategory_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemCategoryId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	isArchived?: GraphQLTypes["order_by"],
	lastServicedAt?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	serviceFrequency?: GraphQLTypes["order_by"],
	serviceProvider?: GraphQLTypes["order_by"],
	serviceType?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: HomeInventoryItem */
["HomeInventoryItem_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "HomeInventoryItem" */
["HomeInventoryItem_select_column"]: HomeInventoryItem_select_column;
	/** input type for updating data in table "HomeInventoryItem" */
["HomeInventoryItem_set_input"]: {
		homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	isArchived?: boolean,
	lastServicedAt?: GraphQLTypes["timestamptz"],
	name?: string,
	notes?: string,
	serviceFrequency?: number,
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum"]
};
	/** order by stddev() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_pop_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_stddev_samp_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "HomeInventoryItem" */
["HomeInventoryItem_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryItem_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryItem_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemCategoryId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	isArchived?: boolean,
	lastServicedAt?: GraphQLTypes["timestamptz"],
	name?: string,
	notes?: string,
	serviceFrequency?: number,
	serviceProvider?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	serviceType?: GraphQLTypes["HomeInventoryServiceType_enum"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_sum_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** update columns of table "HomeInventoryItem" */
["HomeInventoryItem_update_column"]: HomeInventoryItem_update_column;
	["HomeInventoryItem_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["HomeInventoryItem_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["HomeInventoryItem_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["HomeInventoryItem_bool_exp"]
};
	/** order by var_pop() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_pop_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_var_samp_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "HomeInventoryItem" */
["HomeInventoryItem_variance_order_by"]: {
		serviceFrequency?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule"]: {
	__typename: "HomeInventoryMaintenanceSchedule",
	/** An object relationship */
	Home?: GraphQLTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem"],
	dueBy?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "HomeInventoryMaintenanceSchedule". All fields are combined with a logical 'AND'. */
["HomeInventoryMaintenanceSchedule_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	_and?: Array<GraphQLTypes["HomeInventoryMaintenanceSchedule_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryMaintenanceSchedule_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryMaintenanceSchedule_bool_exp"]>,
	dueBy?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	homeInventoryItemId?: GraphQLTypes["uuid_comparison_exp"]
};
	/** Ordering options when selecting data from "HomeInventoryMaintenanceSchedule". */
["HomeInventoryMaintenanceSchedule_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_order_by"],
	dueBy?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_select_column"]: HomeInventoryMaintenanceSchedule_select_column;
	/** Streaming cursor of the table "HomeInventoryMaintenanceSchedule" */
["HomeInventoryMaintenanceSchedule_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryMaintenanceSchedule_stream_cursor_value_input"]: {
		dueBy?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"]
};
	/** Enum of the provider for a HomeInventoryItemCategory or HomeInventoryItem */
["HomeInventoryServiceProvider"]: {
	__typename: "HomeInventoryServiceProvider",
	description: string,
	value: string
};
	/** Boolean expression to filter rows from the table "HomeInventoryServiceProvider". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceProvider_bool_exp"]: {
		_and?: Array<GraphQLTypes["HomeInventoryServiceProvider_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryServiceProvider_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryServiceProvider_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["HomeInventoryServiceProvider_enum"]: HomeInventoryServiceProvider_enum;
	/** Boolean expression to compare columns of type "HomeInventoryServiceProvider_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceProvider_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	_in?: Array<GraphQLTypes["HomeInventoryServiceProvider_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["HomeInventoryServiceProvider_enum"],
	_nin?: Array<GraphQLTypes["HomeInventoryServiceProvider_enum"]>
};
	/** Ordering options when selecting data from "HomeInventoryServiceProvider". */
["HomeInventoryServiceProvider_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_select_column"]: HomeInventoryServiceProvider_select_column;
	/** Streaming cursor of the table "HomeInventoryServiceProvider" */
["HomeInventoryServiceProvider_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryServiceProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceProvider_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** Enum of the type of service which a HomeInventoryItemCategory or HomeInventoryItem need during maintenance */
["HomeInventoryServiceType"]: {
	__typename: "HomeInventoryServiceType",
	description: string,
	value: string
};
	/** Boolean expression to filter rows from the table "HomeInventoryServiceType". All fields are combined with a logical 'AND'. */
["HomeInventoryServiceType_bool_exp"]: {
		_and?: Array<GraphQLTypes["HomeInventoryServiceType_bool_exp"]>,
	_not?: GraphQLTypes["HomeInventoryServiceType_bool_exp"],
	_or?: Array<GraphQLTypes["HomeInventoryServiceType_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["HomeInventoryServiceType_enum"]: HomeInventoryServiceType_enum;
	/** Boolean expression to compare columns of type "HomeInventoryServiceType_enum". All fields are combined with logical 'AND'. */
["HomeInventoryServiceType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["HomeInventoryServiceType_enum"],
	_in?: Array<GraphQLTypes["HomeInventoryServiceType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["HomeInventoryServiceType_enum"],
	_nin?: Array<GraphQLTypes["HomeInventoryServiceType_enum"]>
};
	/** Ordering options when selecting data from "HomeInventoryServiceType". */
["HomeInventoryServiceType_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeInventoryServiceType" */
["HomeInventoryServiceType_select_column"]: HomeInventoryServiceType_select_column;
	/** Streaming cursor of the table "HomeInventoryServiceType" */
["HomeInventoryServiceType_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeInventoryServiceType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeInventoryServiceType_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	["HomeStatus_enum"]: HomeStatus_enum;
	/** Boolean expression to compare columns of type "HomeStatus_enum". All fields are combined with logical 'AND'. */
["HomeStatus_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["HomeStatus_enum"],
	_in?: Array<GraphQLTypes["HomeStatus_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["HomeStatus_enum"],
	_nin?: Array<GraphQLTypes["HomeStatus_enum"]>
};
	/** columns and relationships of "HomeWaitlist" */
["HomeWaitlist"]: {
	__typename: "HomeWaitlist",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	createdAt: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	["HomeWaitlistReason_enum"]: HomeWaitlistReason_enum;
	/** Boolean expression to filter rows from the table "HomeWaitlist". All fields are combined with a logical 'AND'. */
["HomeWaitlist_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["HomeWaitlist_bool_exp"]>,
	_not?: GraphQLTypes["HomeWaitlist_bool_exp"],
	_or?: Array<GraphQLTypes["HomeWaitlist_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "HomeWaitlist" */
["HomeWaitlist_constraint"]: HomeWaitlist_constraint;
	/** input type for inserting data into table "HomeWaitlist" */
["HomeWaitlist_insert_input"]: {
		id?: GraphQLTypes["uuid"],
	reason?: GraphQLTypes["HomeWaitlistReason_enum"]
};
	/** response of any mutation on the table "HomeWaitlist" */
["HomeWaitlist_mutation_response"]: {
	__typename: "HomeWaitlist_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["HomeWaitlist"]>
};
	/** on_conflict condition type for table "HomeWaitlist" */
["HomeWaitlist_on_conflict"]: {
		constraint: GraphQLTypes["HomeWaitlist_constraint"],
	update_columns: Array<GraphQLTypes["HomeWaitlist_update_column"]>,
	where?: GraphQLTypes["HomeWaitlist_bool_exp"]
};
	/** Ordering options when selecting data from "HomeWaitlist". */
["HomeWaitlist_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "HomeWaitlist" */
["HomeWaitlist_select_column"]: HomeWaitlist_select_column;
	/** Streaming cursor of the table "HomeWaitlist" */
["HomeWaitlist_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["HomeWaitlist_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["HomeWaitlist_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** placeholder for update columns of table "HomeWaitlist" (current role has no relevant permissions) */
["HomeWaitlist_update_column"]: HomeWaitlist_update_column;
	/** order by aggregate values of table "Home" */
["Home_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Home_max_order_by"],
	min?: GraphQLTypes["Home_min_order_by"]
};
	/** Boolean expression to filter rows from the table "Home". All fields are combined with a logical 'AND'. */
["Home_bool_exp"]: {
		AcceptedReferrals?: GraphQLTypes["Referral_bool_exp"],
	AppointmentGrades?: GraphQLTypes["AppointmentGrade_bool_exp"],
	Appointments?: GraphQLTypes["Appointment_bool_exp"],
	Appointments_aggregate?: GraphQLTypes["Appointment_aggregate_bool_exp"],
	GeographicRegion?: GraphQLTypes["GeographicRegion_bool_exp"],
	HomeInventoryItems?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	InvitedReferrals?: GraphQLTypes["Referral_bool_exp"],
	PersonHomes?: GraphQLTypes["PersonHome_bool_exp"],
	SchedulingRegion?: GraphQLTypes["Region_bool_exp"],
	ServiceOfferResponses?: GraphQLTypes["ServiceOfferResponse_bool_exp"],
	StripePaymentMethods?: GraphQLTypes["StripePaymentMethod_bool_exp"],
	StripeSubscriptions?: GraphQLTypes["StripeSubscription_bool_exp"],
	Tasks?: GraphQLTypes["Task_bool_exp"],
	Tasks_aggregate?: GraphQLTypes["Task_aggregate_bool_exp"],
	_and?: Array<GraphQLTypes["Home_bool_exp"]>,
	_not?: GraphQLTypes["Home_bool_exp"],
	_or?: Array<GraphQLTypes["Home_bool_exp"]>,
	activeMember?: GraphQLTypes["Boolean_comparison_exp"],
	city?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	geographicRegionId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	location?: GraphQLTypes["geography_comparison_exp"],
	referralCode?: GraphQLTypes["String_comparison_exp"],
	schedulingRegionId?: GraphQLTypes["uuid_comparison_exp"],
	sendbirdChannelId?: GraphQLTypes["String_comparison_exp"],
	state?: GraphQLTypes["String_comparison_exp"],
	status?: GraphQLTypes["HomeStatus_enum_comparison_exp"],
	streetAddress?: GraphQLTypes["String_comparison_exp"],
	stripeDefaultSourcePresent?: GraphQLTypes["Boolean_comparison_exp"],
	stripeId?: GraphQLTypes["String_comparison_exp"],
	stripePriceId?: GraphQLTypes["String_comparison_exp"],
	stripePromoCode?: GraphQLTypes["String_comparison_exp"],
	timeZone?: GraphQLTypes["String_comparison_exp"],
	unavailableEndingAt?: GraphQLTypes["date_comparison_exp"],
	unavailableStartingAt?: GraphQLTypes["date_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	wifiCredentialsE?: GraphQLTypes["String_comparison_exp"],
	zipCode?: GraphQLTypes["String_comparison_exp"]
};
	/** order by max() on columns of table "Home" */
["Home_max_order_by"]: {
		city?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	referralCode?: GraphQLTypes["order_by"],
	schedulingRegionId?: GraphQLTypes["order_by"],
	sendbirdChannelId?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	streetAddress?: GraphQLTypes["order_by"],
	stripeId?: GraphQLTypes["order_by"],
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?: GraphQLTypes["order_by"],
	stripePromoCode?: GraphQLTypes["order_by"],
	/** IANA time zone */
	timeZone?: GraphQLTypes["order_by"],
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?: GraphQLTypes["order_by"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	wifiCredentialsE?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "Home" */
["Home_min_order_by"]: {
		city?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	referralCode?: GraphQLTypes["order_by"],
	schedulingRegionId?: GraphQLTypes["order_by"],
	sendbirdChannelId?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	streetAddress?: GraphQLTypes["order_by"],
	stripeId?: GraphQLTypes["order_by"],
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?: GraphQLTypes["order_by"],
	stripePromoCode?: GraphQLTypes["order_by"],
	/** IANA time zone */
	timeZone?: GraphQLTypes["order_by"],
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?: GraphQLTypes["order_by"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	wifiCredentialsE?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Home" */
["Home_mutation_response"]: {
	__typename: "Home_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Home"]>
};
	/** Ordering options when selecting data from "Home". */
["Home_order_by"]: {
		AcceptedReferrals_aggregate?: GraphQLTypes["Referral_aggregate_order_by"],
	AppointmentGrades_aggregate?: GraphQLTypes["AppointmentGrade_aggregate_order_by"],
	Appointments_aggregate?: GraphQLTypes["Appointment_aggregate_order_by"],
	GeographicRegion?: GraphQLTypes["GeographicRegion_order_by"],
	HomeInventoryItems_aggregate?: GraphQLTypes["HomeInventoryItem_aggregate_order_by"],
	InvitedReferrals_aggregate?: GraphQLTypes["Referral_aggregate_order_by"],
	PersonHomes_aggregate?: GraphQLTypes["PersonHome_aggregate_order_by"],
	SchedulingRegion?: GraphQLTypes["Region_order_by"],
	ServiceOfferResponses_aggregate?: GraphQLTypes["ServiceOfferResponse_aggregate_order_by"],
	StripePaymentMethods_aggregate?: GraphQLTypes["StripePaymentMethod_aggregate_order_by"],
	StripeSubscriptions_aggregate?: GraphQLTypes["StripeSubscription_aggregate_order_by"],
	Tasks_aggregate?: GraphQLTypes["Task_aggregate_order_by"],
	activeMember?: GraphQLTypes["order_by"],
	city?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	geographicRegionId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	location?: GraphQLTypes["order_by"],
	referralCode?: GraphQLTypes["order_by"],
	schedulingRegionId?: GraphQLTypes["order_by"],
	sendbirdChannelId?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	streetAddress?: GraphQLTypes["order_by"],
	stripeDefaultSourcePresent?: GraphQLTypes["order_by"],
	stripeId?: GraphQLTypes["order_by"],
	stripePriceId?: GraphQLTypes["order_by"],
	stripePromoCode?: GraphQLTypes["order_by"],
	timeZone?: GraphQLTypes["order_by"],
	unavailableEndingAt?: GraphQLTypes["order_by"],
	unavailableStartingAt?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	wifiCredentialsE?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Home */
["Home_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "Home" */
["Home_select_column"]: Home_select_column;
	/** input type for updating data in table "Home" */
["Home_set_input"]: {
		city?: string,
	/** Where the homeowners reported they learned of the service. */
	organicSource?: string,
	state?: string,
	streetAddress?: string,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?: string,
	stripePromoCode?: string,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?: GraphQLTypes["date"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?: GraphQLTypes["date"],
	wifiCredentialsE?: string,
	zipCode?: string
};
	/** Streaming cursor of the table "Home" */
["Home_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Home_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Home_stream_cursor_value_input"]: {
		/** If the home is currently an active member of the service */
	activeMember?: boolean,
	city?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	geographicRegionId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	location?: GraphQLTypes["geography"],
	referralCode?: string,
	schedulingRegionId?: GraphQLTypes["uuid"],
	sendbirdChannelId?: string,
	state?: string,
	status?: GraphQLTypes["HomeStatus_enum"],
	streetAddress?: string,
	stripeDefaultSourcePresent?: boolean,
	stripeId?: string,
	/** Stripe Price object id of the currently selected Stripe price. */
	stripePriceId?: string,
	stripePromoCode?: string,
	/** IANA time zone */
	timeZone?: string,
	/** Ending date of when the home members are unavailable */
	unavailableEndingAt?: GraphQLTypes["date"],
	/** Starting date of when the home members are unavailable */
	unavailableStartingAt?: GraphQLTypes["date"],
	updatedAt?: GraphQLTypes["timestamptz"],
	wifiCredentialsE?: string,
	zipCode?: string
};
	["Home_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Home_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Home_bool_exp"]
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
		_eq?: number,
	_gt?: number,
	_gte?: number,
	_in?: Array<number>,
	_is_null?: boolean,
	_lt?: number,
	_lte?: number,
	_neq?: number,
	_nin?: Array<number>
};
	/** columns and relationships of "MarketingLead" */
["MarketingLead"]: {
	__typename: "MarketingLead",
	id: GraphQLTypes["uuid"]
};
	/** Boolean expression to filter rows from the table "MarketingLead". All fields are combined with a logical 'AND'. */
["MarketingLead_bool_exp"]: {
		_and?: Array<GraphQLTypes["MarketingLead_bool_exp"]>,
	_not?: GraphQLTypes["MarketingLead_bool_exp"],
	_or?: Array<GraphQLTypes["MarketingLead_bool_exp"]>,
	id?: GraphQLTypes["uuid_comparison_exp"]
};
	/** response of any mutation on the table "MarketingLead" */
["MarketingLead_mutation_response"]: {
	__typename: "MarketingLead_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["MarketingLead"]>
};
	/** Ordering options when selecting data from "MarketingLead". */
["MarketingLead_order_by"]: {
		id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: MarketingLead */
["MarketingLead_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "MarketingLead" */
["MarketingLead_select_column"]: MarketingLead_select_column;
	/** input type for updating data in table "MarketingLead" */
["MarketingLead_set_input"]: {
		city?: string,
	email?: string,
	firstAppointmentBeginsAt?: GraphQLTypes["timestamptz"],
	firstAppointmentEndsAt?: GraphQLTypes["timestamptz"],
	firstName?: string,
	homeAccountCreated?: boolean,
	lastName?: string,
	phoneNumber?: string,
	phoneNumberVerified?: boolean,
	state?: string,
	streetAddress?: string,
	zipCode?: string
};
	/** Streaming cursor of the table "MarketingLead" */
["MarketingLead_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["MarketingLead_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["MarketingLead_stream_cursor_value_input"]: {
		id?: GraphQLTypes["uuid"]
};
	["MarketingLead_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["MarketingLead_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["MarketingLead_bool_exp"]
};
	/** Service area for one handyman */
["Neighborhood"]: {
	__typename: "Neighborhood",
	/** An array relationship */
	NeighborhoodRegions: Array<GraphQLTypes["NeighborhoodRegion"]>,
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	availableStartingAt?: GraphQLTypes["timestamptz"],
	createdAt: GraphQLTypes["timestamptz"],
	displayName?: string,
	handymanPersonId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"],
	zipCode: string
};
	/** columns and relationships of "NeighborhoodRegion" */
["NeighborhoodRegion"]: {
	__typename: "NeighborhoodRegion",
	/** An object relationship */
	Neighborhood: GraphQLTypes["Neighborhood"],
	/** An object relationship */
	Region: GraphQLTypes["Region"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	neighborhoodId: GraphQLTypes["uuid"],
	regionId: GraphQLTypes["uuid"],
	type: GraphQLTypes["NeighborhoodRegionType_enum"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	["NeighborhoodRegionType_enum"]: NeighborhoodRegionType_enum;
	/** Boolean expression to compare columns of type "NeighborhoodRegionType_enum". All fields are combined with logical 'AND'. */
["NeighborhoodRegionType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["NeighborhoodRegionType_enum"],
	_in?: Array<GraphQLTypes["NeighborhoodRegionType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["NeighborhoodRegionType_enum"],
	_nin?: Array<GraphQLTypes["NeighborhoodRegionType_enum"]>
};
	/** order by aggregate values of table "NeighborhoodRegion" */
["NeighborhoodRegion_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["NeighborhoodRegion_max_order_by"],
	min?: GraphQLTypes["NeighborhoodRegion_min_order_by"]
};
	/** Boolean expression to filter rows from the table "NeighborhoodRegion". All fields are combined with a logical 'AND'. */
["NeighborhoodRegion_bool_exp"]: {
		Neighborhood?: GraphQLTypes["Neighborhood_bool_exp"],
	Region?: GraphQLTypes["Region_bool_exp"],
	_and?: Array<GraphQLTypes["NeighborhoodRegion_bool_exp"]>,
	_not?: GraphQLTypes["NeighborhoodRegion_bool_exp"],
	_or?: Array<GraphQLTypes["NeighborhoodRegion_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	neighborhoodId?: GraphQLTypes["uuid_comparison_exp"],
	regionId?: GraphQLTypes["uuid_comparison_exp"],
	type?: GraphQLTypes["NeighborhoodRegionType_enum_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	neighborhoodId?: GraphQLTypes["order_by"],
	regionId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	neighborhoodId?: GraphQLTypes["order_by"],
	regionId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "NeighborhoodRegion". */
["NeighborhoodRegion_order_by"]: {
		Neighborhood?: GraphQLTypes["Neighborhood_order_by"],
	Region?: GraphQLTypes["Region_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	neighborhoodId?: GraphQLTypes["order_by"],
	regionId?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "NeighborhoodRegion" */
["NeighborhoodRegion_select_column"]: NeighborhoodRegion_select_column;
	/** Streaming cursor of the table "NeighborhoodRegion" */
["NeighborhoodRegion_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["NeighborhoodRegion_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["NeighborhoodRegion_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	neighborhoodId?: GraphQLTypes["uuid"],
	regionId?: GraphQLTypes["uuid"],
	type?: GraphQLTypes["NeighborhoodRegionType_enum"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "Neighborhood" */
["Neighborhood_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Neighborhood_max_order_by"],
	min?: GraphQLTypes["Neighborhood_min_order_by"]
};
	/** Boolean expression to filter rows from the table "Neighborhood". All fields are combined with a logical 'AND'. */
["Neighborhood_bool_exp"]: {
		NeighborhoodRegions?: GraphQLTypes["NeighborhoodRegion_bool_exp"],
	Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["Neighborhood_bool_exp"]>,
	_not?: GraphQLTypes["Neighborhood_bool_exp"],
	_or?: Array<GraphQLTypes["Neighborhood_bool_exp"]>,
	availableStartingAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	displayName?: GraphQLTypes["String_comparison_exp"],
	handymanPersonId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	zipCode?: GraphQLTypes["String_comparison_exp"]
};
	/** order by max() on columns of table "Neighborhood" */
["Neighborhood_max_order_by"]: {
		availableStartingAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	displayName?: GraphQLTypes["order_by"],
	handymanPersonId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "Neighborhood" */
["Neighborhood_min_order_by"]: {
		availableStartingAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	displayName?: GraphQLTypes["order_by"],
	handymanPersonId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "Neighborhood". */
["Neighborhood_order_by"]: {
		NeighborhoodRegions_aggregate?: GraphQLTypes["NeighborhoodRegion_aggregate_order_by"],
	Person?: GraphQLTypes["Person_order_by"],
	availableStartingAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	displayName?: GraphQLTypes["order_by"],
	handymanPersonId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	zipCode?: GraphQLTypes["order_by"]
};
	/** select columns of table "Neighborhood" */
["Neighborhood_select_column"]: Neighborhood_select_column;
	/** Streaming cursor of the table "Neighborhood" */
["Neighborhood_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Neighborhood_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Neighborhood_stream_cursor_value_input"]: {
		availableStartingAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	displayName?: string,
	handymanPersonId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"],
	zipCode?: string
};
	/** columns and relationships of "Person" */
["Person"]: {
	__typename: "Person",
	/** An array relationship */
	AppointmentGrades: Array<GraphQLTypes["AppointmentGrade"]>,
	/** An array relationship */
	AppointmentProviders: Array<GraphQLTypes["AppointmentProvider"]>,
	/** An aggregate relationship */
	AppointmentProviders_aggregate: GraphQLTypes["AppointmentProvider_aggregate"],
	/** An array relationship */
	AppointmentReports: Array<GraphQLTypes["AppointmentReport"]>,
	/** An array relationship */
	Availability: Array<GraphQLTypes["Availability"]>,
	/** An array relationship */
	CreatedAppointmentReports: Array<GraphQLTypes["AppointmentReport"]>,
	/** An array relationship */
	CreatedAppointments: Array<GraphQLTypes["Appointment"]>,
	/** An aggregate relationship */
	CreatedAppointments_aggregate: GraphQLTypes["Appointment_aggregate"],
	/** An array relationship */
	CreatedAttachments: Array<GraphQLTypes["Attachment"]>,
	/** An array relationship */
	CreatedTasks: Array<GraphQLTypes["Task"]>,
	/** An aggregate relationship */
	CreatedTasks_aggregate: GraphQLTypes["Task_aggregate"],
	/** An array relationship */
	Devices: Array<GraphQLTypes["Device"]>,
	/** An aggregate relationship */
	Devices_aggregate: GraphQLTypes["Device_aggregate"],
	/** An object relationship */
	ExternalIdentifiers?: GraphQLTypes["ExternalIdentifier"],
	/** An array relationship */
	Neighborhoods: Array<GraphQLTypes["Neighborhood"]>,
	/** An array relationship */
	PersonBiographyItems: Array<GraphQLTypes["PersonBiographyItem"]>,
	/** An array relationship */
	PersonHomes: Array<GraphQLTypes["PersonHome"]>,
	/** An object relationship */
	PrimaryHome?: GraphQLTypes["Home"],
	/** An object relationship */
	ProfilePicture?: GraphQLTypes["Attachment"],
	/** An array relationship */
	TaskComments: Array<GraphQLTypes["TaskComment"]>,
	/** An aggregate relationship */
	TaskComments_aggregate: GraphQLTypes["TaskComment_aggregate"],
	/** An array relationship */
	TaskUpdateLogs: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate: GraphQLTypes["TaskUpdateLog_aggregate"],
	biography?: string,
	contactPreference?: GraphQLTypes["PersonContactPreference_enum"],
	/** IANA Time Zone */
	currentTimeZone: string,
	email?: string,
	firstName: string,
	id: GraphQLTypes["uuid"],
	lastName: string,
	phoneNumber: string,
	type: GraphQLTypes["PersonType_enum"]
};
	/** columns and relationships of "PersonBiographyItem" */
["PersonBiographyItem"]: {
	__typename: "PersonBiographyItem",
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	personId: GraphQLTypes["uuid"],
	rank: number,
	title: string,
	updatedAt: GraphQLTypes["timestamptz"],
	value: string
};
	/** order by aggregate values of table "PersonBiographyItem" */
["PersonBiographyItem_aggregate_order_by"]: {
		avg?: GraphQLTypes["PersonBiographyItem_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["PersonBiographyItem_max_order_by"],
	min?: GraphQLTypes["PersonBiographyItem_min_order_by"],
	stddev?: GraphQLTypes["PersonBiographyItem_stddev_order_by"],
	stddev_pop?: GraphQLTypes["PersonBiographyItem_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["PersonBiographyItem_stddev_samp_order_by"],
	sum?: GraphQLTypes["PersonBiographyItem_sum_order_by"],
	var_pop?: GraphQLTypes["PersonBiographyItem_var_pop_order_by"],
	var_samp?: GraphQLTypes["PersonBiographyItem_var_samp_order_by"],
	variance?: GraphQLTypes["PersonBiographyItem_variance_order_by"]
};
	/** input type for inserting array relation for remote table "PersonBiographyItem" */
["PersonBiographyItem_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["PersonBiographyItem_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["PersonBiographyItem_on_conflict"]
};
	/** order by avg() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_avg_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "PersonBiographyItem". All fields are combined with a logical 'AND'. */
["PersonBiographyItem_bool_exp"]: {
		Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["PersonBiographyItem_bool_exp"]>,
	_not?: GraphQLTypes["PersonBiographyItem_bool_exp"],
	_or?: Array<GraphQLTypes["PersonBiographyItem_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "PersonBiographyItem" */
["PersonBiographyItem_constraint"]: PersonBiographyItem_constraint;
	/** input type for incrementing numeric columns in table "PersonBiographyItem" */
["PersonBiographyItem_inc_input"]: {
		rank?: number
};
	/** input type for inserting data into table "PersonBiographyItem" */
["PersonBiographyItem_insert_input"]: {
		Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	rank?: number,
	title?: string,
	value?: string
};
	/** order by max() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "PersonBiographyItem" */
["PersonBiographyItem_mutation_response"]: {
	__typename: "PersonBiographyItem_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["PersonBiographyItem"]>
};
	/** on_conflict condition type for table "PersonBiographyItem" */
["PersonBiographyItem_on_conflict"]: {
		constraint: GraphQLTypes["PersonBiographyItem_constraint"],
	update_columns: Array<GraphQLTypes["PersonBiographyItem_update_column"]>,
	where?: GraphQLTypes["PersonBiographyItem_bool_exp"]
};
	/** Ordering options when selecting data from "PersonBiographyItem". */
["PersonBiographyItem_order_by"]: {
		Person?: GraphQLTypes["Person_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: PersonBiographyItem */
["PersonBiographyItem_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "PersonBiographyItem" */
["PersonBiographyItem_select_column"]: PersonBiographyItem_select_column;
	/** input type for updating data in table "PersonBiographyItem" */
["PersonBiographyItem_set_input"]: {
		rank?: number,
	title?: string,
	value?: string
};
	/** order by stddev() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_stddev_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "PersonBiographyItem" */
["PersonBiographyItem_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["PersonBiographyItem_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["PersonBiographyItem_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	rank?: number,
	title?: string,
	updatedAt?: GraphQLTypes["timestamptz"],
	value?: string
};
	/** order by sum() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_sum_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** update columns of table "PersonBiographyItem" */
["PersonBiographyItem_update_column"]: PersonBiographyItem_update_column;
	["PersonBiographyItem_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["PersonBiographyItem_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["PersonBiographyItem_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["PersonBiographyItem_bool_exp"]
};
	/** order by var_pop() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_var_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "PersonBiographyItem" */
["PersonBiographyItem_variance_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	["PersonContactPreference_enum"]: PersonContactPreference_enum;
	/** Boolean expression to compare columns of type "PersonContactPreference_enum". All fields are combined with logical 'AND'. */
["PersonContactPreference_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["PersonContactPreference_enum"],
	_in?: Array<GraphQLTypes["PersonContactPreference_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["PersonContactPreference_enum"],
	_nin?: Array<GraphQLTypes["PersonContactPreference_enum"]>
};
	/** columns and relationships of "PersonHome" */
["PersonHome"]: {
	__typename: "PersonHome",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An object relationship */
	Person: GraphQLTypes["Person"],
	homeId: GraphQLTypes["uuid"],
	personId: GraphQLTypes["uuid"]
};
	/** order by aggregate values of table "PersonHome" */
["PersonHome_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["PersonHome_max_order_by"],
	min?: GraphQLTypes["PersonHome_min_order_by"]
};
	/** input type for inserting array relation for remote table "PersonHome" */
["PersonHome_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["PersonHome_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["PersonHome_on_conflict"]
};
	/** Boolean expression to filter rows from the table "PersonHome". All fields are combined with a logical 'AND'. */
["PersonHome_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	Person?: GraphQLTypes["Person_bool_exp"],
	_and?: Array<GraphQLTypes["PersonHome_bool_exp"]>,
	_not?: GraphQLTypes["PersonHome_bool_exp"],
	_or?: Array<GraphQLTypes["PersonHome_bool_exp"]>,
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"]
};
	/** unique or primary key constraints on table "PersonHome" */
["PersonHome_constraint"]: PersonHome_constraint;
	/** input type for inserting data into table "PersonHome" */
["PersonHome_insert_input"]: {
		Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	personId?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "PersonHome" */
["PersonHome_max_order_by"]: {
		homeId?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "PersonHome" */
["PersonHome_min_order_by"]: {
		homeId?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "PersonHome" */
["PersonHome_mutation_response"]: {
	__typename: "PersonHome_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["PersonHome"]>
};
	/** on_conflict condition type for table "PersonHome" */
["PersonHome_on_conflict"]: {
		constraint: GraphQLTypes["PersonHome_constraint"],
	update_columns: Array<GraphQLTypes["PersonHome_update_column"]>,
	where?: GraphQLTypes["PersonHome_bool_exp"]
};
	/** Ordering options when selecting data from "PersonHome". */
["PersonHome_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	Person?: GraphQLTypes["Person_order_by"],
	homeId?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"]
};
	/** select columns of table "PersonHome" */
["PersonHome_select_column"]: PersonHome_select_column;
	/** Streaming cursor of the table "PersonHome" */
["PersonHome_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["PersonHome_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["PersonHome_stream_cursor_value_input"]: {
		homeId?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"]
};
	/** placeholder for update columns of table "PersonHome" (current role has no relevant permissions) */
["PersonHome_update_column"]: PersonHome_update_column;
	["PersonType_enum"]: PersonType_enum;
	/** Boolean expression to compare columns of type "PersonType_enum". All fields are combined with logical 'AND'. */
["PersonType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["PersonType_enum"],
	_in?: Array<GraphQLTypes["PersonType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["PersonType_enum"],
	_nin?: Array<GraphQLTypes["PersonType_enum"]>
};
	/** aggregated selection of "Person" */
["Person_aggregate"]: {
	__typename: "Person_aggregate",
	aggregate?: GraphQLTypes["Person_aggregate_fields"],
	nodes: Array<GraphQLTypes["Person"]>
};
	["Person_aggregate_bool_exp"]: {
		count?: GraphQLTypes["Person_aggregate_bool_exp_count"]
};
	["Person_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["Person_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["Person_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Person" */
["Person_aggregate_fields"]: {
	__typename: "Person_aggregate_fields",
	count: number,
	max?: GraphQLTypes["Person_max_fields"],
	min?: GraphQLTypes["Person_min_fields"]
};
	/** order by aggregate values of table "Person" */
["Person_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Person_max_order_by"],
	min?: GraphQLTypes["Person_min_order_by"]
};
	/** input type for inserting array relation for remote table "Person" */
["Person_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["Person_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["Person_on_conflict"]
};
	/** Boolean expression to filter rows from the table "Person". All fields are combined with a logical 'AND'. */
["Person_bool_exp"]: {
		AppointmentGrades?: GraphQLTypes["AppointmentGrade_bool_exp"],
	AppointmentProviders?: GraphQLTypes["AppointmentProvider_bool_exp"],
	AppointmentProviders_aggregate?: GraphQLTypes["AppointmentProvider_aggregate_bool_exp"],
	AppointmentReports?: GraphQLTypes["AppointmentReport_bool_exp"],
	Availability?: GraphQLTypes["Availability_bool_exp"],
	CreatedAppointmentReports?: GraphQLTypes["AppointmentReport_bool_exp"],
	CreatedAppointments?: GraphQLTypes["Appointment_bool_exp"],
	CreatedAppointments_aggregate?: GraphQLTypes["Appointment_aggregate_bool_exp"],
	CreatedAttachments?: GraphQLTypes["Attachment_bool_exp"],
	CreatedTasks?: GraphQLTypes["Task_bool_exp"],
	CreatedTasks_aggregate?: GraphQLTypes["Task_aggregate_bool_exp"],
	Devices?: GraphQLTypes["Device_bool_exp"],
	Devices_aggregate?: GraphQLTypes["Device_aggregate_bool_exp"],
	ExternalIdentifiers?: GraphQLTypes["ExternalIdentifier_bool_exp"],
	Neighborhoods?: GraphQLTypes["Neighborhood_bool_exp"],
	PersonBiographyItems?: GraphQLTypes["PersonBiographyItem_bool_exp"],
	PersonHomes?: GraphQLTypes["PersonHome_bool_exp"],
	PrimaryHome?: GraphQLTypes["Home_bool_exp"],
	ProfilePicture?: GraphQLTypes["Attachment_bool_exp"],
	TaskComments?: GraphQLTypes["TaskComment_bool_exp"],
	TaskComments_aggregate?: GraphQLTypes["TaskComment_aggregate_bool_exp"],
	TaskUpdateLogs?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp"],
	_and?: Array<GraphQLTypes["Person_bool_exp"]>,
	_not?: GraphQLTypes["Person_bool_exp"],
	_or?: Array<GraphQLTypes["Person_bool_exp"]>,
	biography?: GraphQLTypes["String_comparison_exp"],
	contactPreference?: GraphQLTypes["PersonContactPreference_enum_comparison_exp"],
	currentTimeZone?: GraphQLTypes["String_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	firstName?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	lastName?: GraphQLTypes["String_comparison_exp"],
	phoneNumber?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["PersonType_enum_comparison_exp"]
};
	/** unique or primary key constraints on table "Person" */
["Person_constraint"]: Person_constraint;
	/** input type for inserting data into table "Person" */
["Person_insert_input"]: {
		AppointmentGrades?: GraphQLTypes["AppointmentGrade_arr_rel_insert_input"],
	AppointmentProviders?: GraphQLTypes["AppointmentProvider_arr_rel_insert_input"],
	CreatedAppointments?: GraphQLTypes["Appointment_arr_rel_insert_input"],
	CreatedAttachments?: GraphQLTypes["Attachment_arr_rel_insert_input"],
	CreatedTasks?: GraphQLTypes["Task_arr_rel_insert_input"],
	Devices?: GraphQLTypes["Device_arr_rel_insert_input"],
	PersonBiographyItems?: GraphQLTypes["PersonBiographyItem_arr_rel_insert_input"],
	PersonHomes?: GraphQLTypes["PersonHome_arr_rel_insert_input"],
	ProfilePicture?: GraphQLTypes["Attachment_obj_rel_insert_input"],
	TaskComments?: GraphQLTypes["TaskComment_arr_rel_insert_input"],
	TaskUpdateLogs?: GraphQLTypes["TaskUpdateLog_arr_rel_insert_input"],
	/** IANA Time Zone */
	currentTimeZone?: string,
	email?: string,
	firstName?: string,
	lastName?: string,
	phoneNumber?: string
};
	/** aggregate max on columns */
["Person_max_fields"]: {
	__typename: "Person_max_fields",
	biography?: string,
	/** IANA Time Zone */
	currentTimeZone?: string,
	email?: string,
	firstName?: string,
	id?: GraphQLTypes["uuid"],
	lastName?: string,
	phoneNumber?: string
};
	/** order by max() on columns of table "Person" */
["Person_max_order_by"]: {
		biography?: GraphQLTypes["order_by"],
	/** IANA Time Zone */
	currentTimeZone?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	firstName?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastName?: GraphQLTypes["order_by"],
	phoneNumber?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["Person_min_fields"]: {
	__typename: "Person_min_fields",
	biography?: string,
	/** IANA Time Zone */
	currentTimeZone?: string,
	email?: string,
	firstName?: string,
	id?: GraphQLTypes["uuid"],
	lastName?: string,
	phoneNumber?: string
};
	/** order by min() on columns of table "Person" */
["Person_min_order_by"]: {
		biography?: GraphQLTypes["order_by"],
	/** IANA Time Zone */
	currentTimeZone?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	firstName?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastName?: GraphQLTypes["order_by"],
	phoneNumber?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Person" */
["Person_mutation_response"]: {
	__typename: "Person_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Person"]>
};
	/** input type for inserting object relation for remote table "Person" */
["Person_obj_rel_insert_input"]: {
		data: GraphQLTypes["Person_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["Person_on_conflict"]
};
	/** on_conflict condition type for table "Person" */
["Person_on_conflict"]: {
		constraint: GraphQLTypes["Person_constraint"],
	update_columns: Array<GraphQLTypes["Person_update_column"]>,
	where?: GraphQLTypes["Person_bool_exp"]
};
	/** Ordering options when selecting data from "Person". */
["Person_order_by"]: {
		AppointmentGrades_aggregate?: GraphQLTypes["AppointmentGrade_aggregate_order_by"],
	AppointmentProviders_aggregate?: GraphQLTypes["AppointmentProvider_aggregate_order_by"],
	AppointmentReports_aggregate?: GraphQLTypes["AppointmentReport_aggregate_order_by"],
	Availability_aggregate?: GraphQLTypes["Availability_aggregate_order_by"],
	CreatedAppointmentReports_aggregate?: GraphQLTypes["AppointmentReport_aggregate_order_by"],
	CreatedAppointments_aggregate?: GraphQLTypes["Appointment_aggregate_order_by"],
	CreatedAttachments_aggregate?: GraphQLTypes["Attachment_aggregate_order_by"],
	CreatedTasks_aggregate?: GraphQLTypes["Task_aggregate_order_by"],
	Devices_aggregate?: GraphQLTypes["Device_aggregate_order_by"],
	ExternalIdentifiers?: GraphQLTypes["ExternalIdentifier_order_by"],
	Neighborhoods_aggregate?: GraphQLTypes["Neighborhood_aggregate_order_by"],
	PersonBiographyItems_aggregate?: GraphQLTypes["PersonBiographyItem_aggregate_order_by"],
	PersonHomes_aggregate?: GraphQLTypes["PersonHome_aggregate_order_by"],
	PrimaryHome?: GraphQLTypes["Home_order_by"],
	ProfilePicture?: GraphQLTypes["Attachment_order_by"],
	TaskComments_aggregate?: GraphQLTypes["TaskComment_aggregate_order_by"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_order_by"],
	biography?: GraphQLTypes["order_by"],
	contactPreference?: GraphQLTypes["order_by"],
	currentTimeZone?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	firstName?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastName?: GraphQLTypes["order_by"],
	phoneNumber?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Person */
["Person_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "Person" */
["Person_select_column"]: Person_select_column;
	/** input type for updating data in table "Person" */
["Person_set_input"]: {
		biography?: string,
	contactPreference?: GraphQLTypes["PersonContactPreference_enum"],
	/** IANA Time Zone */
	currentTimeZone?: string,
	email?: string,
	firstName?: string,
	lastName?: string,
	phoneNumber?: string,
	profilePictureId?: GraphQLTypes["uuid"]
};
	/** Streaming cursor of the table "Person" */
["Person_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Person_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Person_stream_cursor_value_input"]: {
		biography?: string,
	contactPreference?: GraphQLTypes["PersonContactPreference_enum"],
	/** IANA Time Zone */
	currentTimeZone?: string,
	email?: string,
	firstName?: string,
	id?: GraphQLTypes["uuid"],
	lastName?: string,
	phoneNumber?: string,
	type?: GraphQLTypes["PersonType_enum"]
};
	/** update columns of table "Person" */
["Person_update_column"]: Person_update_column;
	["Person_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Person_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Person_bool_exp"]
};
	/** Tracks referrals between homes */
["Referral"]: {
	__typename: "Referral",
	/** An object relationship */
	Accepted: GraphQLTypes["Home"],
	/** An object relationship */
	Invited: GraphQLTypes["Home"],
	/** An object relationship */
	ReferralProgram?: GraphQLTypes["ReferralProgram"],
	acceptedById: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter: number,
	id: GraphQLTypes["uuid"],
	invitedById: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Currently active and historical referral programs */
["ReferralProgram"]: {
	__typename: "ReferralProgram",
	creditAmount: number,
	id: GraphQLTypes["uuid"],
	isActive: boolean
};
	/** Boolean expression to filter rows from the table "ReferralProgram". All fields are combined with a logical 'AND'. */
["ReferralProgram_bool_exp"]: {
		_and?: Array<GraphQLTypes["ReferralProgram_bool_exp"]>,
	_not?: GraphQLTypes["ReferralProgram_bool_exp"],
	_or?: Array<GraphQLTypes["ReferralProgram_bool_exp"]>,
	creditAmount?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	isActive?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** Ordering options when selecting data from "ReferralProgram". */
["ReferralProgram_order_by"]: {
		creditAmount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	isActive?: GraphQLTypes["order_by"]
};
	/** select columns of table "ReferralProgram" */
["ReferralProgram_select_column"]: ReferralProgram_select_column;
	/** Streaming cursor of the table "ReferralProgram" */
["ReferralProgram_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ReferralProgram_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ReferralProgram_stream_cursor_value_input"]: {
		creditAmount?: number,
	id?: GraphQLTypes["uuid"],
	isActive?: boolean
};
	/** order by aggregate values of table "Referral" */
["Referral_aggregate_order_by"]: {
		avg?: GraphQLTypes["Referral_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Referral_max_order_by"],
	min?: GraphQLTypes["Referral_min_order_by"],
	stddev?: GraphQLTypes["Referral_stddev_order_by"],
	stddev_pop?: GraphQLTypes["Referral_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["Referral_stddev_samp_order_by"],
	sum?: GraphQLTypes["Referral_sum_order_by"],
	var_pop?: GraphQLTypes["Referral_var_pop_order_by"],
	var_samp?: GraphQLTypes["Referral_var_samp_order_by"],
	variance?: GraphQLTypes["Referral_variance_order_by"]
};
	/** order by avg() on columns of table "Referral" */
["Referral_avg_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "Referral". All fields are combined with a logical 'AND'. */
["Referral_bool_exp"]: {
		Accepted?: GraphQLTypes["Home_bool_exp"],
	Invited?: GraphQLTypes["Home_bool_exp"],
	ReferralProgram?: GraphQLTypes["ReferralProgram_bool_exp"],
	_and?: Array<GraphQLTypes["Referral_bool_exp"]>,
	_not?: GraphQLTypes["Referral_bool_exp"],
	_or?: Array<GraphQLTypes["Referral_bool_exp"]>,
	acceptedById?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	creditAppliedToInviter?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	invitedById?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "Referral" */
["Referral_constraint"]: Referral_constraint;
	/** input type for inserting data into table "Referral" */
["Referral_insert_input"]: {
		invitedById?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "Referral" */
["Referral_max_order_by"]: {
		acceptedById?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	invitedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "Referral" */
["Referral_min_order_by"]: {
		acceptedById?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	invitedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Referral" */
["Referral_mutation_response"]: {
	__typename: "Referral_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Referral"]>
};
	/** on_conflict condition type for table "Referral" */
["Referral_on_conflict"]: {
		constraint: GraphQLTypes["Referral_constraint"],
	update_columns: Array<GraphQLTypes["Referral_update_column"]>,
	where?: GraphQLTypes["Referral_bool_exp"]
};
	/** Ordering options when selecting data from "Referral". */
["Referral_order_by"]: {
		Accepted?: GraphQLTypes["Home_order_by"],
	Invited?: GraphQLTypes["Home_order_by"],
	ReferralProgram?: GraphQLTypes["ReferralProgram_order_by"],
	acceptedById?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	creditAppliedToInviter?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	invitedById?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "Referral" */
["Referral_select_column"]: Referral_select_column;
	/** order by stddev() on columns of table "Referral" */
["Referral_stddev_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "Referral" */
["Referral_stddev_pop_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "Referral" */
["Referral_stddev_samp_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "Referral" */
["Referral_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Referral_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Referral_stream_cursor_value_input"]: {
		acceptedById?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: number,
	id?: GraphQLTypes["uuid"],
	invitedById?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "Referral" */
["Referral_sum_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** placeholder for update columns of table "Referral" (current role has no relevant permissions) */
["Referral_update_column"]: Referral_update_column;
	/** order by var_pop() on columns of table "Referral" */
["Referral_var_pop_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "Referral" */
["Referral_var_samp_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "Referral" */
["Referral_variance_order_by"]: {
		/** Amount of credit successfully applied to the invitation sender for this referral, in cents. */
	creditAppliedToInviter?: GraphQLTypes["order_by"]
};
	/** Hierarchical groupings of Neighborhood */
["Region"]: {
	__typename: "Region",
	/** An array relationship */
	NeighborhoodRegions: Array<GraphQLTypes["NeighborhoodRegion"]>,
	/** An array relationship */
	SchedulingHomes: Array<GraphQLTypes["Home"]>,
	createdAt: GraphQLTypes["timestamptz"],
	/** Friendly name for the regions shown in UI */
	displayName: string,
	id: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to filter rows from the table "Region". All fields are combined with a logical 'AND'. */
["Region_bool_exp"]: {
		NeighborhoodRegions?: GraphQLTypes["NeighborhoodRegion_bool_exp"],
	SchedulingHomes?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["Region_bool_exp"]>,
	_not?: GraphQLTypes["Region_bool_exp"],
	_or?: Array<GraphQLTypes["Region_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	displayName?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** Ordering options when selecting data from "Region". */
["Region_order_by"]: {
		NeighborhoodRegions_aggregate?: GraphQLTypes["NeighborhoodRegion_aggregate_order_by"],
	SchedulingHomes_aggregate?: GraphQLTypes["Home_aggregate_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	displayName?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "Region" */
["Region_select_column"]: Region_select_column;
	/** Streaming cursor of the table "Region" */
["Region_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Region_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Region_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	/** Friendly name for the regions shown in UI */
	displayName?: string,
	id?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** Schedule of events offered to members */
["ServiceOffer"]: {
	__typename: "ServiceOffer",
	/** An array relationship */
	ServiceOfferResponses: Array<GraphQLTypes["ServiceOfferResponse"]>,
	/** An object relationship */
	ServiceOfferType: GraphQLTypes["ServiceOfferType"],
	additionalChargeAmount: number,
	beginsAtDate: GraphQLTypes["date"],
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?: GraphQLTypes["timetz"],
	confirmBy: GraphQLTypes["date"],
	/** Description about the additional charge for the service */
	costExplaination?: string,
	createdAt: GraphQLTypes["timestamptz"],
	description: string,
	endsAtDate: GraphQLTypes["date"],
	endsAtTime?: GraphQLTypes["timetz"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: number,
	id: GraphQLTypes["uuid"],
	requiresAdditionalCharge: boolean,
	requiresConfirmation: boolean,
	scheduleBy: GraphQLTypes["date"],
	title: string,
	type: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** RSVP status of a home to a service offering */
["ServiceOfferResponse"]: {
	__typename: "ServiceOfferResponse",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An object relationship */
	ServiceOffer: GraphQLTypes["ServiceOffer"],
	/** An object relationship */
	ServiceOfferResponseStatus: GraphQLTypes["ServiceOfferResponseStatus"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	serviceOfferId: GraphQLTypes["uuid"],
	status: GraphQLTypes["ServiceOfferResponseStatus_enum"]
};
	/** columns and relationships of "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus"]: {
	__typename: "ServiceOfferResponseStatus",
	/** An array relationship */
	ServiceOfferResponses: Array<GraphQLTypes["ServiceOfferResponse"]>,
	description?: string,
	value: string
};
	/** Boolean expression to filter rows from the table "ServiceOfferResponseStatus". All fields are combined with a logical 'AND'. */
["ServiceOfferResponseStatus_bool_exp"]: {
		ServiceOfferResponses?: GraphQLTypes["ServiceOfferResponse_bool_exp"],
	_and?: Array<GraphQLTypes["ServiceOfferResponseStatus_bool_exp"]>,
	_not?: GraphQLTypes["ServiceOfferResponseStatus_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceOfferResponseStatus_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["ServiceOfferResponseStatus_enum"]: ServiceOfferResponseStatus_enum;
	/** Boolean expression to compare columns of type "ServiceOfferResponseStatus_enum". All fields are combined with logical 'AND'. */
["ServiceOfferResponseStatus_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["ServiceOfferResponseStatus_enum"],
	_in?: Array<GraphQLTypes["ServiceOfferResponseStatus_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["ServiceOfferResponseStatus_enum"],
	_nin?: Array<GraphQLTypes["ServiceOfferResponseStatus_enum"]>
};
	/** Ordering options when selecting data from "ServiceOfferResponseStatus". */
["ServiceOfferResponseStatus_order_by"]: {
		ServiceOfferResponses_aggregate?: GraphQLTypes["ServiceOfferResponse_aggregate_order_by"],
	description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_select_column"]: ServiceOfferResponseStatus_select_column;
	/** Streaming cursor of the table "ServiceOfferResponseStatus" */
["ServiceOfferResponseStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceOfferResponseStatus_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponseStatus_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** order by aggregate values of table "ServiceOfferResponse" */
["ServiceOfferResponse_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["ServiceOfferResponse_max_order_by"],
	min?: GraphQLTypes["ServiceOfferResponse_min_order_by"]
};
	/** Boolean expression to filter rows from the table "ServiceOfferResponse". All fields are combined with a logical 'AND'. */
["ServiceOfferResponse_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	ServiceOffer?: GraphQLTypes["ServiceOffer_bool_exp"],
	ServiceOfferResponseStatus?: GraphQLTypes["ServiceOfferResponseStatus_bool_exp"],
	_and?: Array<GraphQLTypes["ServiceOfferResponse_bool_exp"]>,
	_not?: GraphQLTypes["ServiceOfferResponse_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceOfferResponse_bool_exp"]>,
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	serviceOfferId?: GraphQLTypes["uuid_comparison_exp"],
	status?: GraphQLTypes["ServiceOfferResponseStatus_enum_comparison_exp"]
};
	/** unique or primary key constraints on table "ServiceOfferResponse" */
["ServiceOfferResponse_constraint"]: ServiceOfferResponse_constraint;
	/** input type for inserting data into table "ServiceOfferResponse" */
["ServiceOfferResponse_insert_input"]: {
		homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	serviceOfferId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["ServiceOfferResponseStatus_enum"]
};
	/** order by max() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_max_order_by"]: {
		homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	serviceOfferId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_min_order_by"]: {
		homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	serviceOfferId?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "ServiceOfferResponse" */
["ServiceOfferResponse_mutation_response"]: {
	__typename: "ServiceOfferResponse_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["ServiceOfferResponse"]>
};
	/** on_conflict condition type for table "ServiceOfferResponse" */
["ServiceOfferResponse_on_conflict"]: {
		constraint: GraphQLTypes["ServiceOfferResponse_constraint"],
	update_columns: Array<GraphQLTypes["ServiceOfferResponse_update_column"]>,
	where?: GraphQLTypes["ServiceOfferResponse_bool_exp"]
};
	/** Ordering options when selecting data from "ServiceOfferResponse". */
["ServiceOfferResponse_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	ServiceOffer?: GraphQLTypes["ServiceOffer_order_by"],
	ServiceOfferResponseStatus?: GraphQLTypes["ServiceOfferResponseStatus_order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	serviceOfferId?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: ServiceOfferResponse */
["ServiceOfferResponse_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_select_column"]: ServiceOfferResponse_select_column;
	/** input type for updating data in table "ServiceOfferResponse" */
["ServiceOfferResponse_set_input"]: {
		status?: GraphQLTypes["ServiceOfferResponseStatus_enum"]
};
	/** Streaming cursor of the table "ServiceOfferResponse" */
["ServiceOfferResponse_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceOfferResponse_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferResponse_stream_cursor_value_input"]: {
		homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	serviceOfferId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["ServiceOfferResponseStatus_enum"]
};
	/** update columns of table "ServiceOfferResponse" */
["ServiceOfferResponse_update_column"]: ServiceOfferResponse_update_column;
	["ServiceOfferResponse_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["ServiceOfferResponse_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["ServiceOfferResponse_bool_exp"]
};
	/** columns and relationships of "ServiceOfferType" */
["ServiceOfferType"]: {
	__typename: "ServiceOfferType",
	/** An array relationship */
	ServiceOffers: Array<GraphQLTypes["ServiceOffer"]>,
	description?: string,
	value: string
};
	/** Boolean expression to filter rows from the table "ServiceOfferType". All fields are combined with a logical 'AND'. */
["ServiceOfferType_bool_exp"]: {
		ServiceOffers?: GraphQLTypes["ServiceOffer_bool_exp"],
	_and?: Array<GraphQLTypes["ServiceOfferType_bool_exp"]>,
	_not?: GraphQLTypes["ServiceOfferType_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceOfferType_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	/** Ordering options when selecting data from "ServiceOfferType". */
["ServiceOfferType_order_by"]: {
		ServiceOffers_aggregate?: GraphQLTypes["ServiceOffer_aggregate_order_by"],
	description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceOfferType" */
["ServiceOfferType_select_column"]: ServiceOfferType_select_column;
	/** Streaming cursor of the table "ServiceOfferType" */
["ServiceOfferType_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceOfferType_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceOfferType_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** order by aggregate values of table "ServiceOffer" */
["ServiceOffer_aggregate_order_by"]: {
		avg?: GraphQLTypes["ServiceOffer_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["ServiceOffer_max_order_by"],
	min?: GraphQLTypes["ServiceOffer_min_order_by"],
	stddev?: GraphQLTypes["ServiceOffer_stddev_order_by"],
	stddev_pop?: GraphQLTypes["ServiceOffer_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["ServiceOffer_stddev_samp_order_by"],
	sum?: GraphQLTypes["ServiceOffer_sum_order_by"],
	var_pop?: GraphQLTypes["ServiceOffer_var_pop_order_by"],
	var_samp?: GraphQLTypes["ServiceOffer_var_samp_order_by"],
	variance?: GraphQLTypes["ServiceOffer_variance_order_by"]
};
	/** order by avg() on columns of table "ServiceOffer" */
["ServiceOffer_avg_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "ServiceOffer". All fields are combined with a logical 'AND'. */
["ServiceOffer_bool_exp"]: {
		ServiceOfferResponses?: GraphQLTypes["ServiceOfferResponse_bool_exp"],
	ServiceOfferType?: GraphQLTypes["ServiceOfferType_bool_exp"],
	_and?: Array<GraphQLTypes["ServiceOffer_bool_exp"]>,
	_not?: GraphQLTypes["ServiceOffer_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceOffer_bool_exp"]>,
	additionalChargeAmount?: GraphQLTypes["Int_comparison_exp"],
	beginsAtDate?: GraphQLTypes["date_comparison_exp"],
	beginsAtTime?: GraphQLTypes["timetz_comparison_exp"],
	confirmBy?: GraphQLTypes["date_comparison_exp"],
	costExplaination?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	endsAtDate?: GraphQLTypes["date_comparison_exp"],
	endsAtTime?: GraphQLTypes["timetz_comparison_exp"],
	estimatedDuration?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	requiresAdditionalCharge?: GraphQLTypes["Boolean_comparison_exp"],
	requiresConfirmation?: GraphQLTypes["Boolean_comparison_exp"],
	scheduleBy?: GraphQLTypes["date_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "ServiceOffer" */
["ServiceOffer_max_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	beginsAtDate?: GraphQLTypes["order_by"],
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?: GraphQLTypes["order_by"],
	confirmBy?: GraphQLTypes["order_by"],
	/** Description about the additional charge for the service */
	costExplaination?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	endsAtDate?: GraphQLTypes["order_by"],
	endsAtTime?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	scheduleBy?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "ServiceOffer" */
["ServiceOffer_min_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	beginsAtDate?: GraphQLTypes["order_by"],
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?: GraphQLTypes["order_by"],
	confirmBy?: GraphQLTypes["order_by"],
	/** Description about the additional charge for the service */
	costExplaination?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	endsAtDate?: GraphQLTypes["order_by"],
	endsAtTime?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	scheduleBy?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "ServiceOffer". */
["ServiceOffer_order_by"]: {
		ServiceOfferResponses_aggregate?: GraphQLTypes["ServiceOfferResponse_aggregate_order_by"],
	ServiceOfferType?: GraphQLTypes["ServiceOfferType_order_by"],
	additionalChargeAmount?: GraphQLTypes["order_by"],
	beginsAtDate?: GraphQLTypes["order_by"],
	beginsAtTime?: GraphQLTypes["order_by"],
	confirmBy?: GraphQLTypes["order_by"],
	costExplaination?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	endsAtDate?: GraphQLTypes["order_by"],
	endsAtTime?: GraphQLTypes["order_by"],
	estimatedDuration?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	requiresAdditionalCharge?: GraphQLTypes["order_by"],
	requiresConfirmation?: GraphQLTypes["order_by"],
	scheduleBy?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceOffer" */
["ServiceOffer_select_column"]: ServiceOffer_select_column;
	/** order by stddev() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_pop_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "ServiceOffer" */
["ServiceOffer_stddev_samp_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "ServiceOffer" */
["ServiceOffer_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceOffer_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceOffer_stream_cursor_value_input"]: {
		additionalChargeAmount?: number,
	beginsAtDate?: GraphQLTypes["date"],
	/** Present for events occurring on a distinct moment in time */
	beginsAtTime?: GraphQLTypes["timetz"],
	confirmBy?: GraphQLTypes["date"],
	/** Description about the additional charge for the service */
	costExplaination?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	description?: string,
	endsAtDate?: GraphQLTypes["date"],
	endsAtTime?: GraphQLTypes["timetz"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: number,
	id?: GraphQLTypes["uuid"],
	requiresAdditionalCharge?: boolean,
	requiresConfirmation?: boolean,
	scheduleBy?: GraphQLTypes["date"],
	title?: string,
	type?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "ServiceOffer" */
["ServiceOffer_sum_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** order by var_pop() on columns of table "ServiceOffer" */
["ServiceOffer_var_pop_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "ServiceOffer" */
["ServiceOffer_var_samp_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "ServiceOffer" */
["ServiceOffer_variance_order_by"]: {
		additionalChargeAmount?: GraphQLTypes["order_by"],
	/** Expected time to complete, in minutes. */
	estimatedDuration?: GraphQLTypes["order_by"]
};
	/** Organization providing service to a home */
["ServiceProvider"]: {
	__typename: "ServiceProvider",
	createdAt: GraphQLTypes["timestamptz"],
	email?: string,
	id: GraphQLTypes["uuid"],
	name: string,
	phoneNumber?: string,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** columns and relationships of "ServiceProviderRequest" */
["ServiceProviderRequest"]: {
	__typename: "ServiceProviderRequest",
	/** An object relationship */
	Appointment?: GraphQLTypes["Appointment"],
	/** An object relationship */
	ServiceProvider?: GraphQLTypes["ServiceProvider"],
	/** An object relationship */
	Task?: GraphQLTypes["Task"],
	id: GraphQLTypes["uuid"],
	stage?: GraphQLTypes["ServiceProviderRequestStage_enum"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["timestamptz"],
	taskId?: GraphQLTypes["uuid"]
};
	/** columns and relationships of "ServiceProviderRequestStage" */
["ServiceProviderRequestStage"]: {
	__typename: "ServiceProviderRequestStage",
	description: string,
	value: string
};
	/** Boolean expression to filter rows from the table "ServiceProviderRequestStage". All fields are combined with a logical 'AND'. */
["ServiceProviderRequestStage_bool_exp"]: {
		_and?: Array<GraphQLTypes["ServiceProviderRequestStage_bool_exp"]>,
	_not?: GraphQLTypes["ServiceProviderRequestStage_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceProviderRequestStage_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["ServiceProviderRequestStage_enum"]: ServiceProviderRequestStage_enum;
	/** Boolean expression to compare columns of type "ServiceProviderRequestStage_enum". All fields are combined with logical 'AND'. */
["ServiceProviderRequestStage_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["ServiceProviderRequestStage_enum"],
	_in?: Array<GraphQLTypes["ServiceProviderRequestStage_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["ServiceProviderRequestStage_enum"],
	_nin?: Array<GraphQLTypes["ServiceProviderRequestStage_enum"]>
};
	/** Ordering options when selecting data from "ServiceProviderRequestStage". */
["ServiceProviderRequestStage_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_select_column"]: ServiceProviderRequestStage_select_column;
	/** Streaming cursor of the table "ServiceProviderRequestStage" */
["ServiceProviderRequestStage_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceProviderRequestStage_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequestStage_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** order by aggregate values of table "ServiceProviderRequest" */
["ServiceProviderRequest_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["ServiceProviderRequest_max_order_by"],
	min?: GraphQLTypes["ServiceProviderRequest_min_order_by"]
};
	/** input type for inserting array relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["ServiceProviderRequest_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["ServiceProviderRequest_on_conflict"]
};
	/** Boolean expression to filter rows from the table "ServiceProviderRequest". All fields are combined with a logical 'AND'. */
["ServiceProviderRequest_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	ServiceProvider?: GraphQLTypes["ServiceProvider_bool_exp"],
	Task?: GraphQLTypes["Task_bool_exp"],
	_and?: Array<GraphQLTypes["ServiceProviderRequest_bool_exp"]>,
	_not?: GraphQLTypes["ServiceProviderRequest_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceProviderRequest_bool_exp"]>,
	id?: GraphQLTypes["uuid_comparison_exp"],
	stage?: GraphQLTypes["ServiceProviderRequestStage_enum_comparison_exp"],
	startedProcessingRequestAt?: GraphQLTypes["timestamptz_comparison_exp"],
	taskId?: GraphQLTypes["uuid_comparison_exp"]
};
	/** unique or primary key constraints on table "ServiceProviderRequest" */
["ServiceProviderRequest_constraint"]: ServiceProviderRequest_constraint;
	/** input type for inserting data into table "ServiceProviderRequest" */
["ServiceProviderRequest_insert_input"]: {
		Appointment?: GraphQLTypes["Appointment_obj_rel_insert_input"],
	Task?: GraphQLTypes["Task_obj_rel_insert_input"],
	taskId?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "ServiceProviderRequest" */
["ServiceProviderRequest_mutation_response"]: {
	__typename: "ServiceProviderRequest_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["ServiceProviderRequest"]>
};
	/** input type for inserting object relation for remote table "ServiceProviderRequest" */
["ServiceProviderRequest_obj_rel_insert_input"]: {
		data: GraphQLTypes["ServiceProviderRequest_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["ServiceProviderRequest_on_conflict"]
};
	/** on_conflict condition type for table "ServiceProviderRequest" */
["ServiceProviderRequest_on_conflict"]: {
		constraint: GraphQLTypes["ServiceProviderRequest_constraint"],
	update_columns: Array<GraphQLTypes["ServiceProviderRequest_update_column"]>,
	where?: GraphQLTypes["ServiceProviderRequest_bool_exp"]
};
	/** Ordering options when selecting data from "ServiceProviderRequest". */
["ServiceProviderRequest_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	ServiceProvider?: GraphQLTypes["ServiceProvider_order_by"],
	Task?: GraphQLTypes["Task_order_by"],
	id?: GraphQLTypes["order_by"],
	stage?: GraphQLTypes["order_by"],
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceProviderRequest" */
["ServiceProviderRequest_select_column"]: ServiceProviderRequest_select_column;
	/** Streaming cursor of the table "ServiceProviderRequest" */
["ServiceProviderRequest_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceProviderRequest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceProviderRequest_stream_cursor_value_input"]: {
		id?: GraphQLTypes["uuid"],
	stage?: GraphQLTypes["ServiceProviderRequestStage_enum"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["timestamptz"],
	taskId?: GraphQLTypes["uuid"]
};
	/** placeholder for update columns of table "ServiceProviderRequest" (current role has no relevant permissions) */
["ServiceProviderRequest_update_column"]: ServiceProviderRequest_update_column;
	/** Boolean expression to filter rows from the table "ServiceProvider". All fields are combined with a logical 'AND'. */
["ServiceProvider_bool_exp"]: {
		_and?: Array<GraphQLTypes["ServiceProvider_bool_exp"]>,
	_not?: GraphQLTypes["ServiceProvider_bool_exp"],
	_or?: Array<GraphQLTypes["ServiceProvider_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	phoneNumber?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** Ordering options when selecting data from "ServiceProvider". */
["ServiceProvider_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	phoneNumber?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "ServiceProvider" */
["ServiceProvider_select_column"]: ServiceProvider_select_column;
	/** Streaming cursor of the table "ServiceProvider" */
["ServiceProvider_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["ServiceProvider_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["ServiceProvider_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: GraphQLTypes["uuid"],
	name?: string,
	phoneNumber?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_array_comparison_exp"]: {
		/** is the array contained in the given array value */
	_contained_in?: Array<string>,
	/** does the array contain the given value */
	_contains?: Array<string>,
	_eq?: Array<string>,
	_gt?: Array<string>,
	_gte?: Array<string>,
	_in: Array<string>,
	_is_null?: boolean,
	_lt?: Array<string>,
	_lte?: Array<string>,
	_neq?: Array<string>,
	_nin: Array<string>
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
		_eq?: string,
	_gt?: string,
	_gte?: string,
	/** does the column match the given case-insensitive pattern */
	_ilike?: string,
	_in?: Array<string>,
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: string,
	_is_null?: boolean,
	/** does the column match the given pattern */
	_like?: string,
	_lt?: string,
	_lte?: string,
	_neq?: string,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: string,
	_nin?: Array<string>,
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: string,
	/** does the column NOT match the given pattern */
	_nlike?: string,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: string,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: string,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: string,
	/** does the column match the given SQL regular expression */
	_similar?: string
};
	/** Tracks Stripe PaymentMethods for a Home */
["StripePaymentMethod"]: {
	__typename: "StripePaymentMethod",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	createdAt: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	paymentMethodId: string
};
	/** order by aggregate values of table "StripePaymentMethod" */
["StripePaymentMethod_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["StripePaymentMethod_max_order_by"],
	min?: GraphQLTypes["StripePaymentMethod_min_order_by"]
};
	/** Boolean expression to filter rows from the table "StripePaymentMethod". All fields are combined with a logical 'AND'. */
["StripePaymentMethod_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["StripePaymentMethod_bool_exp"]>,
	_not?: GraphQLTypes["StripePaymentMethod_bool_exp"],
	_or?: Array<GraphQLTypes["StripePaymentMethod_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	paymentMethodId?: GraphQLTypes["String_comparison_exp"]
};
	/** order by max() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	paymentMethodId?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "StripePaymentMethod" */
["StripePaymentMethod_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	paymentMethodId?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "StripePaymentMethod". */
["StripePaymentMethod_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	paymentMethodId?: GraphQLTypes["order_by"]
};
	/** select columns of table "StripePaymentMethod" */
["StripePaymentMethod_select_column"]: StripePaymentMethod_select_column;
	/** Streaming cursor of the table "StripePaymentMethod" */
["StripePaymentMethod_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["StripePaymentMethod_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["StripePaymentMethod_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	paymentMethodId?: string
};
	/** columns and relationships of "StripeSubscription" */
["StripeSubscription"]: {
	__typename: "StripeSubscription",
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	canceledAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	homeId: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	interval: string,
	status: GraphQLTypes["StripeSubscriptionStatus_enum"],
	subscriptionId: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	["StripeSubscriptionStatus_enum"]: StripeSubscriptionStatus_enum;
	/** Boolean expression to compare columns of type "StripeSubscriptionStatus_enum". All fields are combined with logical 'AND'. */
["StripeSubscriptionStatus_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["StripeSubscriptionStatus_enum"],
	_in?: Array<GraphQLTypes["StripeSubscriptionStatus_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["StripeSubscriptionStatus_enum"],
	_nin?: Array<GraphQLTypes["StripeSubscriptionStatus_enum"]>
};
	/** order by aggregate values of table "StripeSubscription" */
["StripeSubscription_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["StripeSubscription_max_order_by"],
	min?: GraphQLTypes["StripeSubscription_min_order_by"]
};
	/** Boolean expression to filter rows from the table "StripeSubscription". All fields are combined with a logical 'AND'. */
["StripeSubscription_bool_exp"]: {
		Home?: GraphQLTypes["Home_bool_exp"],
	_and?: Array<GraphQLTypes["StripeSubscription_bool_exp"]>,
	_not?: GraphQLTypes["StripeSubscription_bool_exp"],
	_or?: Array<GraphQLTypes["StripeSubscription_bool_exp"]>,
	canceledAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	interval?: GraphQLTypes["String_comparison_exp"],
	status?: GraphQLTypes["StripeSubscriptionStatus_enum_comparison_exp"],
	subscriptionId?: GraphQLTypes["String_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** order by max() on columns of table "StripeSubscription" */
["StripeSubscription_max_order_by"]: {
		canceledAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	interval?: GraphQLTypes["order_by"],
	subscriptionId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "StripeSubscription" */
["StripeSubscription_min_order_by"]: {
		canceledAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	interval?: GraphQLTypes["order_by"],
	subscriptionId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** Ordering options when selecting data from "StripeSubscription". */
["StripeSubscription_order_by"]: {
		Home?: GraphQLTypes["Home_order_by"],
	canceledAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	interval?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	subscriptionId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "StripeSubscription" */
["StripeSubscription_select_column"]: StripeSubscription_select_column;
	/** Streaming cursor of the table "StripeSubscription" */
["StripeSubscription_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["StripeSubscription_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["StripeSubscription_stream_cursor_value_input"]: {
		canceledAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	interval?: string,
	status?: GraphQLTypes["StripeSubscriptionStatus_enum"],
	subscriptionId?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	["Stripe_Coupon"]: {
	__typename: "Stripe_Coupon",
	amountOff: number,
	duration: GraphQLTypes["Stripe_Coupon_Duration"],
	durationInMonths?: number,
	id: string,
	name: string,
	percentOff: number
};
	["Stripe_Coupon_Duration"]: Stripe_Coupon_Duration;
	["Stripe_FindPromoCodeResult"]: {
	__typename: "Stripe_FindPromoCodeResult",
	coupon?: GraphQLTypes["Stripe_Coupon"]
};
	["Stripe_Portal"]: {
	__typename: "Stripe_Portal",
	url: string
};
	["Stripe_SubscriptionProduct"]: {
	__typename: "Stripe_SubscriptionProduct",
	description: string,
	id: string,
	name: string,
	prices: Array<GraphQLTypes["Stripe_SubscriptionProductPrice"]>
};
	["Stripe_SubscriptionProductPrice"]: {
	__typename: "Stripe_SubscriptionProductPrice",
	/** Pricing amount in cents */
	amount: number,
	id: string,
	interval?: string,
	nickname: string
};
	/** columns and relationships of "Task" */
["Task"]: {
	__typename: "Task",
	/** An object relationship */
	Appointment?: GraphQLTypes["Appointment"],
	/** An array relationship */
	Comments: Array<GraphQLTypes["TaskComment"]>,
	/** An aggregate relationship */
	Comments_aggregate: GraphQLTypes["TaskComment_aggregate"],
	/** An object relationship */
	CreatedBy: GraphQLTypes["Person"],
	/** An object relationship */
	Home: GraphQLTypes["Home"],
	/** An object relationship */
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem"],
	/** An object relationship */
	LastUpdatedBy?: GraphQLTypes["Person"],
	/** An array relationship */
	ServiceProviderRequests: Array<GraphQLTypes["ServiceProviderRequest"]>,
	/** An array relationship */
	TaskAttachments: Array<GraphQLTypes["TaskAttachment"]>,
	/** An array relationship */
	TaskParts: Array<GraphQLTypes["TaskPart"]>,
	/** An array relationship */
	TaskUpdateLogs: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** An aggregate relationship */
	TaskUpdateLogs_aggregate: GraphQLTypes["TaskUpdateLog_aggregate"],
	appointmentId?: GraphQLTypes["uuid"],
	completedAt?: GraphQLTypes["timestamptz"],
	createdAt: GraphQLTypes["timestamptz"],
	createdById: GraphQLTypes["uuid"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	homeId: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	lastUpdatedById?: GraphQLTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: string,
	parentTaskId?: GraphQLTypes["uuid"],
	partsRequired: boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel: number,
	requiresOperationalReview: boolean,
	status: GraphQLTypes["TaskStatus_enum"],
	thirdPartyRequired: boolean,
	title: string,
	type: GraphQLTypes["TaskType_enum"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** columns and relationships of "TaskAttachment" */
["TaskAttachment"]: {
	__typename: "TaskAttachment",
	/** An object relationship */
	Attachment: GraphQLTypes["Attachment"],
	/** An object relationship */
	Task: GraphQLTypes["Task"],
	attachmentId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	rank: number,
	taskId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "TaskAttachment" */
["TaskAttachment_aggregate_order_by"]: {
		avg?: GraphQLTypes["TaskAttachment_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["TaskAttachment_max_order_by"],
	min?: GraphQLTypes["TaskAttachment_min_order_by"],
	stddev?: GraphQLTypes["TaskAttachment_stddev_order_by"],
	stddev_pop?: GraphQLTypes["TaskAttachment_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["TaskAttachment_stddev_samp_order_by"],
	sum?: GraphQLTypes["TaskAttachment_sum_order_by"],
	var_pop?: GraphQLTypes["TaskAttachment_var_pop_order_by"],
	var_samp?: GraphQLTypes["TaskAttachment_var_samp_order_by"],
	variance?: GraphQLTypes["TaskAttachment_variance_order_by"]
};
	/** input type for inserting array relation for remote table "TaskAttachment" */
["TaskAttachment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TaskAttachment_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskAttachment_on_conflict"]
};
	/** order by avg() on columns of table "TaskAttachment" */
["TaskAttachment_avg_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "TaskAttachment". All fields are combined with a logical 'AND'. */
["TaskAttachment_bool_exp"]: {
		Attachment?: GraphQLTypes["Attachment_bool_exp"],
	Task?: GraphQLTypes["Task_bool_exp"],
	_and?: Array<GraphQLTypes["TaskAttachment_bool_exp"]>,
	_not?: GraphQLTypes["TaskAttachment_bool_exp"],
	_or?: Array<GraphQLTypes["TaskAttachment_bool_exp"]>,
	attachmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	taskId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "TaskAttachment" */
["TaskAttachment_constraint"]: TaskAttachment_constraint;
	/** input type for incrementing numeric columns in table "TaskAttachment" */
["TaskAttachment_inc_input"]: {
		rank?: number
};
	/** input type for inserting data into table "TaskAttachment" */
["TaskAttachment_insert_input"]: {
		Attachment?: GraphQLTypes["Attachment_obj_rel_insert_input"],
	Task?: GraphQLTypes["Task_obj_rel_insert_input"],
	attachmentId?: GraphQLTypes["uuid"],
	rank?: number,
	taskId?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "TaskAttachment" */
["TaskAttachment_max_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "TaskAttachment" */
["TaskAttachment_min_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "TaskAttachment" */
["TaskAttachment_mutation_response"]: {
	__typename: "TaskAttachment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["TaskAttachment"]>
};
	/** on_conflict condition type for table "TaskAttachment" */
["TaskAttachment_on_conflict"]: {
		constraint: GraphQLTypes["TaskAttachment_constraint"],
	update_columns: Array<GraphQLTypes["TaskAttachment_update_column"]>,
	where?: GraphQLTypes["TaskAttachment_bool_exp"]
};
	/** Ordering options when selecting data from "TaskAttachment". */
["TaskAttachment_order_by"]: {
		Attachment?: GraphQLTypes["Attachment_order_by"],
	Task?: GraphQLTypes["Task_order_by"],
	attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: TaskAttachment */
["TaskAttachment_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "TaskAttachment" */
["TaskAttachment_select_column"]: TaskAttachment_select_column;
	/** input type for updating data in table "TaskAttachment" */
["TaskAttachment_set_input"]: {
		rank?: number
};
	/** order by stddev() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "TaskAttachment" */
["TaskAttachment_stddev_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "TaskAttachment" */
["TaskAttachment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskAttachment_stream_cursor_value_input"]: {
		attachmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rank?: number,
	taskId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "TaskAttachment" */
["TaskAttachment_sum_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** update columns of table "TaskAttachment" */
["TaskAttachment_update_column"]: TaskAttachment_update_column;
	["TaskAttachment_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["TaskAttachment_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["TaskAttachment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["TaskAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "TaskAttachment" */
["TaskAttachment_var_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "TaskAttachment" */
["TaskAttachment_var_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "TaskAttachment" */
["TaskAttachment_variance_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "TaskComment" */
["TaskComment"]: {
	__typename: "TaskComment",
	/** An object relationship */
	Person?: GraphQLTypes["Person"],
	/** An object relationship */
	Task?: GraphQLTypes["Task"],
	body?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "TaskComment" */
["TaskComment_aggregate"]: {
	__typename: "TaskComment_aggregate",
	aggregate?: GraphQLTypes["TaskComment_aggregate_fields"],
	nodes: Array<GraphQLTypes["TaskComment"]>
};
	["TaskComment_aggregate_bool_exp"]: {
		count?: GraphQLTypes["TaskComment_aggregate_bool_exp_count"]
};
	["TaskComment_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["TaskComment_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["TaskComment_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "TaskComment" */
["TaskComment_aggregate_fields"]: {
	__typename: "TaskComment_aggregate_fields",
	count: number,
	max?: GraphQLTypes["TaskComment_max_fields"],
	min?: GraphQLTypes["TaskComment_min_fields"]
};
	/** order by aggregate values of table "TaskComment" */
["TaskComment_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["TaskComment_max_order_by"],
	min?: GraphQLTypes["TaskComment_min_order_by"]
};
	/** input type for inserting array relation for remote table "TaskComment" */
["TaskComment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TaskComment_insert_input"]>
};
	/** Boolean expression to filter rows from the table "TaskComment". All fields are combined with a logical 'AND'. */
["TaskComment_bool_exp"]: {
		Person?: GraphQLTypes["Person_bool_exp"],
	Task?: GraphQLTypes["Task_bool_exp"],
	_and?: Array<GraphQLTypes["TaskComment_bool_exp"]>,
	_not?: GraphQLTypes["TaskComment_bool_exp"],
	_or?: Array<GraphQLTypes["TaskComment_bool_exp"]>,
	body?: GraphQLTypes["String_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	personId?: GraphQLTypes["uuid_comparison_exp"],
	taskId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** input type for inserting data into table "TaskComment" */
["TaskComment_insert_input"]: {
		Person?: GraphQLTypes["Person_obj_rel_insert_input"],
	Task?: GraphQLTypes["Task_obj_rel_insert_input"],
	body?: string,
	taskId?: GraphQLTypes["uuid"]
};
	/** aggregate max on columns */
["TaskComment_max_fields"]: {
	__typename: "TaskComment_max_fields",
	body?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "TaskComment" */
["TaskComment_max_order_by"]: {
		body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["TaskComment_min_fields"]: {
	__typename: "TaskComment_min_fields",
	body?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "TaskComment" */
["TaskComment_min_order_by"]: {
		body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "TaskComment" */
["TaskComment_mutation_response"]: {
	__typename: "TaskComment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["TaskComment"]>
};
	/** Ordering options when selecting data from "TaskComment". */
["TaskComment_order_by"]: {
		Person?: GraphQLTypes["Person_order_by"],
	Task?: GraphQLTypes["Task_order_by"],
	body?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	personId?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** select columns of table "TaskComment" */
["TaskComment_select_column"]: TaskComment_select_column;
	/** input type for updating data in table "TaskComment" */
["TaskComment_set_input"]: {
		body?: string
};
	/** Streaming cursor of the table "TaskComment" */
["TaskComment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskComment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskComment_stream_cursor_value_input"]: {
		body?: string,
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	personId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	["TaskComment_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["TaskComment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["TaskComment_bool_exp"]
};
	/** Tracks status of parts required for a Task */
["TaskPart"]: {
	__typename: "TaskPart",
	/** An object relationship */
	ActiveAgent?: GraphQLTypes["Person"],
	/** An object relationship */
	LastUpdatedBy?: GraphQLTypes["Person"],
	/** An object relationship */
	OrderedBy?: GraphQLTypes["Person"],
	/** An object relationship */
	Task: GraphQLTypes["Task"],
	createdAt: GraphQLTypes["timestamptz"],
	estimatedDeliveryBy?: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	/** When member asks to procure part. */
	memberStartedRequestAt?: GraphQLTypes["timestamptz"],
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?: GraphQLTypes["timestamptz"],
	/** When the part came into posession by member or operations. */
	partAcquiredAt?: GraphQLTypes["timestamptz"],
	partDeliveredAt?: GraphQLTypes["timestamptz"],
	partDeliveryLocation?: GraphQLTypes["TaskPartDeliveryLocation_enum"],
	partName?: string,
	/** If the part is in the companies or member's posession. */
	partOnHand: boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["uuid"],
	requiresReview: boolean,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["timestamptz"],
	status?: GraphQLTypes["TaskPartStatus_enum"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	["TaskPartDeliveryLocation_enum"]: TaskPartDeliveryLocation_enum;
	/** Boolean expression to compare columns of type "TaskPartDeliveryLocation_enum". All fields are combined with logical 'AND'. */
["TaskPartDeliveryLocation_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["TaskPartDeliveryLocation_enum"],
	_in?: Array<GraphQLTypes["TaskPartDeliveryLocation_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["TaskPartDeliveryLocation_enum"],
	_nin?: Array<GraphQLTypes["TaskPartDeliveryLocation_enum"]>
};
	/** columns and relationships of "TaskPartPartyResponsible" */
["TaskPartPartyResponsible"]: {
	__typename: "TaskPartPartyResponsible",
	description?: string,
	value: string
};
	/** Boolean expression to filter rows from the table "TaskPartPartyResponsible". All fields are combined with a logical 'AND'. */
["TaskPartPartyResponsible_bool_exp"]: {
		_and?: Array<GraphQLTypes["TaskPartPartyResponsible_bool_exp"]>,
	_not?: GraphQLTypes["TaskPartPartyResponsible_bool_exp"],
	_or?: Array<GraphQLTypes["TaskPartPartyResponsible_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["TaskPartPartyResponsible_enum"]: TaskPartPartyResponsible_enum;
	/** Boolean expression to compare columns of type "TaskPartPartyResponsible_enum". All fields are combined with logical 'AND'. */
["TaskPartPartyResponsible_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	_in?: Array<GraphQLTypes["TaskPartPartyResponsible_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	_nin?: Array<GraphQLTypes["TaskPartPartyResponsible_enum"]>
};
	/** Ordering options when selecting data from "TaskPartPartyResponsible". */
["TaskPartPartyResponsible_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_select_column"]: TaskPartPartyResponsible_select_column;
	/** Streaming cursor of the table "TaskPartPartyResponsible" */
["TaskPartPartyResponsible_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskPartPartyResponsible_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskPartPartyResponsible_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** columns and relationships of "TaskPartStatus" */
["TaskPartStatus"]: {
	__typename: "TaskPartStatus",
	description: string,
	value: string
};
	/** Boolean expression to filter rows from the table "TaskPartStatus". All fields are combined with a logical 'AND'. */
["TaskPartStatus_bool_exp"]: {
		_and?: Array<GraphQLTypes["TaskPartStatus_bool_exp"]>,
	_not?: GraphQLTypes["TaskPartStatus_bool_exp"],
	_or?: Array<GraphQLTypes["TaskPartStatus_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	["TaskPartStatus_enum"]: TaskPartStatus_enum;
	/** Boolean expression to compare columns of type "TaskPartStatus_enum". All fields are combined with logical 'AND'. */
["TaskPartStatus_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["TaskPartStatus_enum"],
	_in?: Array<GraphQLTypes["TaskPartStatus_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["TaskPartStatus_enum"],
	_nin?: Array<GraphQLTypes["TaskPartStatus_enum"]>
};
	/** Ordering options when selecting data from "TaskPartStatus". */
["TaskPartStatus_order_by"]: {
		description?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** select columns of table "TaskPartStatus" */
["TaskPartStatus_select_column"]: TaskPartStatus_select_column;
	/** Streaming cursor of the table "TaskPartStatus" */
["TaskPartStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskPartStatus_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskPartStatus_stream_cursor_value_input"]: {
		description?: string,
	value?: string
};
	/** order by aggregate values of table "TaskPart" */
["TaskPart_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["TaskPart_max_order_by"],
	min?: GraphQLTypes["TaskPart_min_order_by"]
};
	/** input type for inserting array relation for remote table "TaskPart" */
["TaskPart_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TaskPart_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskPart_on_conflict"]
};
	/** Boolean expression to filter rows from the table "TaskPart". All fields are combined with a logical 'AND'. */
["TaskPart_bool_exp"]: {
		ActiveAgent?: GraphQLTypes["Person_bool_exp"],
	LastUpdatedBy?: GraphQLTypes["Person_bool_exp"],
	OrderedBy?: GraphQLTypes["Person_bool_exp"],
	Task?: GraphQLTypes["Task_bool_exp"],
	_and?: Array<GraphQLTypes["TaskPart_bool_exp"]>,
	_not?: GraphQLTypes["TaskPart_bool_exp"],
	_or?: Array<GraphQLTypes["TaskPart_bool_exp"]>,
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	estimatedDeliveryBy?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	memberStartedRequestAt?: GraphQLTypes["timestamptz_comparison_exp"],
	orderedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	partAcquiredAt?: GraphQLTypes["timestamptz_comparison_exp"],
	partDeliveredAt?: GraphQLTypes["timestamptz_comparison_exp"],
	partDeliveryLocation?: GraphQLTypes["TaskPartDeliveryLocation_enum_comparison_exp"],
	partName?: GraphQLTypes["String_comparison_exp"],
	partOnHand?: GraphQLTypes["Boolean_comparison_exp"],
	partyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum_comparison_exp"],
	personOrderedId?: GraphQLTypes["uuid_comparison_exp"],
	requiresReview?: GraphQLTypes["Boolean_comparison_exp"],
	startedProcessingRequestAt?: GraphQLTypes["timestamptz_comparison_exp"],
	status?: GraphQLTypes["TaskPartStatus_enum_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "TaskPart" */
["TaskPart_constraint"]: TaskPart_constraint;
	/** input type for inserting data into table "TaskPart" */
["TaskPart_insert_input"]: {
		ActiveAgent?: GraphQLTypes["Person_obj_rel_insert_input"],
	LastUpdatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	OrderedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	Task?: GraphQLTypes["Task_obj_rel_insert_input"],
	id?: GraphQLTypes["uuid"],
	partName?: string,
	/** If the part is in the companies or member's posession. */
	partOnHand?: boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "TaskPart" */
["TaskPart_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	estimatedDeliveryBy?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	/** When member asks to procure part. */
	memberStartedRequestAt?: GraphQLTypes["order_by"],
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?: GraphQLTypes["order_by"],
	/** When the part came into posession by member or operations. */
	partAcquiredAt?: GraphQLTypes["order_by"],
	partDeliveredAt?: GraphQLTypes["order_by"],
	partName?: GraphQLTypes["order_by"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["order_by"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "TaskPart" */
["TaskPart_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"],
	estimatedDeliveryBy?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	/** When member asks to procure part. */
	memberStartedRequestAt?: GraphQLTypes["order_by"],
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?: GraphQLTypes["order_by"],
	/** When the part came into posession by member or operations. */
	partAcquiredAt?: GraphQLTypes["order_by"],
	partDeliveredAt?: GraphQLTypes["order_by"],
	partName?: GraphQLTypes["order_by"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["order_by"],
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "TaskPart" */
["TaskPart_mutation_response"]: {
	__typename: "TaskPart_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["TaskPart"]>
};
	/** input type for inserting object relation for remote table "TaskPart" */
["TaskPart_obj_rel_insert_input"]: {
		data: GraphQLTypes["TaskPart_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskPart_on_conflict"]
};
	/** on_conflict condition type for table "TaskPart" */
["TaskPart_on_conflict"]: {
		constraint: GraphQLTypes["TaskPart_constraint"],
	update_columns: Array<GraphQLTypes["TaskPart_update_column"]>,
	where?: GraphQLTypes["TaskPart_bool_exp"]
};
	/** Ordering options when selecting data from "TaskPart". */
["TaskPart_order_by"]: {
		ActiveAgent?: GraphQLTypes["Person_order_by"],
	LastUpdatedBy?: GraphQLTypes["Person_order_by"],
	OrderedBy?: GraphQLTypes["Person_order_by"],
	Task?: GraphQLTypes["Task_order_by"],
	createdAt?: GraphQLTypes["order_by"],
	estimatedDeliveryBy?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	memberStartedRequestAt?: GraphQLTypes["order_by"],
	orderedAt?: GraphQLTypes["order_by"],
	partAcquiredAt?: GraphQLTypes["order_by"],
	partDeliveredAt?: GraphQLTypes["order_by"],
	partDeliveryLocation?: GraphQLTypes["order_by"],
	partName?: GraphQLTypes["order_by"],
	partOnHand?: GraphQLTypes["order_by"],
	partyResponsible?: GraphQLTypes["order_by"],
	personOrderedId?: GraphQLTypes["order_by"],
	requiresReview?: GraphQLTypes["order_by"],
	startedProcessingRequestAt?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: TaskPart */
["TaskPart_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "TaskPart" */
["TaskPart_select_column"]: TaskPart_select_column;
	/** input type for updating data in table "TaskPart" */
["TaskPart_set_input"]: {
		id?: GraphQLTypes["uuid"],
	partName?: string,
	/** If the part is in the companies or member's posession. */
	partOnHand?: boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["uuid"]
};
	/** Streaming cursor of the table "TaskPart" */
["TaskPart_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskPart_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskPart_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"],
	estimatedDeliveryBy?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	/** When member asks to procure part. */
	memberStartedRequestAt?: GraphQLTypes["timestamptz"],
	/** Date part ordered, or date purchased at a physical store. */
	orderedAt?: GraphQLTypes["timestamptz"],
	/** When the part came into posession by member or operations. */
	partAcquiredAt?: GraphQLTypes["timestamptz"],
	partDeliveredAt?: GraphQLTypes["timestamptz"],
	partDeliveryLocation?: GraphQLTypes["TaskPartDeliveryLocation_enum"],
	partName?: string,
	/** If the part is in the companies or member's posession. */
	partOnHand?: boolean,
	/** Who is responsible for procuring the part. */
	partyResponsible?: GraphQLTypes["TaskPartPartyResponsible_enum"],
	/** Internal member who purchased part. */
	personOrderedId?: GraphQLTypes["uuid"],
	requiresReview?: boolean,
	/** When internal operations first started working on the request. */
	startedProcessingRequestAt?: GraphQLTypes["timestamptz"],
	status?: GraphQLTypes["TaskPartStatus_enum"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** update columns of table "TaskPart" */
["TaskPart_update_column"]: TaskPart_update_column;
	["TaskPart_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["TaskPart_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["TaskPart_bool_exp"]
};
	["TaskStatus_enum"]: TaskStatus_enum;
	/** Boolean expression to compare columns of type "TaskStatus_enum". All fields are combined with logical 'AND'. */
["TaskStatus_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["TaskStatus_enum"],
	_in?: Array<GraphQLTypes["TaskStatus_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["TaskStatus_enum"],
	_nin?: Array<GraphQLTypes["TaskStatus_enum"]>
};
	["TaskType_enum"]: TaskType_enum;
	/** Boolean expression to compare columns of type "TaskType_enum". All fields are combined with logical 'AND'. */
["TaskType_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["TaskType_enum"],
	_in?: Array<GraphQLTypes["TaskType_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["TaskType_enum"],
	_nin?: Array<GraphQLTypes["TaskType_enum"]>
};
	/** Data points at a moment in time about a Task */
["TaskUpdateLog"]: {
	__typename: "TaskUpdateLog",
	/** An object relationship */
	AppointmentReport?: GraphQLTypes["AppointmentReport"],
	/** An object relationship */
	Author: GraphQLTypes["Person"],
	/** An object relationship */
	CreatedBy: GraphQLTypes["Person"],
	/** An object relationship */
	ServiceProviderRequest?: GraphQLTypes["ServiceProviderRequest"],
	/** An object relationship */
	Task: GraphQLTypes["Task"],
	/** An object relationship */
	TaskPart?: GraphQLTypes["TaskPart"],
	/** An array relationship */
	TaskUpdateLogAttachments: Array<GraphQLTypes["TaskUpdateLogAttachment"]>,
	appointmentReportId?: GraphQLTypes["uuid"],
	authorId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	createdById: GraphQLTypes["uuid"],
	id: GraphQLTypes["uuid"],
	notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	/** DEPRECATED */
	partsRequired?: boolean,
	ranOutOfTime?: boolean,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["TaskStatus_enum"],
	taskId: GraphQLTypes["uuid"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	/** DEPRECATED */
	thirdPartyRequired?: boolean,
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** Links file uploads to task updates */
["TaskUpdateLogAttachment"]: {
	__typename: "TaskUpdateLogAttachment",
	/** An object relationship */
	Attachment: GraphQLTypes["Attachment"],
	/** An object relationship */
	TaskUpdateLog: GraphQLTypes["TaskUpdateLog"],
	attachmentId: GraphQLTypes["uuid"],
	createdAt: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	rank: number,
	taskUpdateLogId: GraphQLTypes["uuid"],
	updatedAt: GraphQLTypes["timestamptz"]
};
	/** order by aggregate values of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_aggregate_order_by"]: {
		avg?: GraphQLTypes["TaskUpdateLogAttachment_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["TaskUpdateLogAttachment_max_order_by"],
	min?: GraphQLTypes["TaskUpdateLogAttachment_min_order_by"],
	stddev?: GraphQLTypes["TaskUpdateLogAttachment_stddev_order_by"],
	stddev_pop?: GraphQLTypes["TaskUpdateLogAttachment_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["TaskUpdateLogAttachment_stddev_samp_order_by"],
	sum?: GraphQLTypes["TaskUpdateLogAttachment_sum_order_by"],
	var_pop?: GraphQLTypes["TaskUpdateLogAttachment_var_pop_order_by"],
	var_samp?: GraphQLTypes["TaskUpdateLogAttachment_var_samp_order_by"],
	variance?: GraphQLTypes["TaskUpdateLogAttachment_variance_order_by"]
};
	/** input type for inserting array relation for remote table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TaskUpdateLogAttachment_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskUpdateLogAttachment_on_conflict"]
};
	/** order by avg() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_avg_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "TaskUpdateLogAttachment". All fields are combined with a logical 'AND'. */
["TaskUpdateLogAttachment_bool_exp"]: {
		Attachment?: GraphQLTypes["Attachment_bool_exp"],
	TaskUpdateLog?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	_and?: Array<GraphQLTypes["TaskUpdateLogAttachment_bool_exp"]>,
	_not?: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"],
	_or?: Array<GraphQLTypes["TaskUpdateLogAttachment_bool_exp"]>,
	attachmentId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	taskUpdateLogId?: GraphQLTypes["uuid_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_constraint"]: TaskUpdateLogAttachment_constraint;
	/** input type for incrementing numeric columns in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_inc_input"]: {
		rank?: number
};
	/** input type for inserting data into table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_insert_input"]: {
		Attachment?: GraphQLTypes["Attachment_obj_rel_insert_input"],
	TaskUpdateLog?: GraphQLTypes["TaskUpdateLog_obj_rel_insert_input"],
	attachmentId?: GraphQLTypes["uuid"],
	rank?: number,
	taskUpdateLogId?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_max_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskUpdateLogId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** order by min() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_min_order_by"]: {
		attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskUpdateLogId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_mutation_response"]: {
	__typename: "TaskUpdateLogAttachment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["TaskUpdateLogAttachment"]>
};
	/** on_conflict condition type for table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_on_conflict"]: {
		constraint: GraphQLTypes["TaskUpdateLogAttachment_constraint"],
	update_columns: Array<GraphQLTypes["TaskUpdateLogAttachment_update_column"]>,
	where?: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"]
};
	/** Ordering options when selecting data from "TaskUpdateLogAttachment". */
["TaskUpdateLogAttachment_order_by"]: {
		Attachment?: GraphQLTypes["Attachment_order_by"],
	TaskUpdateLog?: GraphQLTypes["TaskUpdateLog_order_by"],
	attachmentId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	taskUpdateLogId?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: TaskUpdateLogAttachment */
["TaskUpdateLogAttachment_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_select_column"]: TaskUpdateLogAttachment_select_column;
	/** input type for updating data in table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_set_input"]: {
		rank?: number
};
	/** order by stddev() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by stddev_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stddev_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskUpdateLogAttachment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLogAttachment_stream_cursor_value_input"]: {
		attachmentId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rank?: number,
	taskUpdateLogId?: GraphQLTypes["uuid"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by sum() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_sum_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** update columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_update_column"]: TaskUpdateLogAttachment_update_column;
	["TaskUpdateLogAttachment_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["TaskUpdateLogAttachment_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["TaskUpdateLogAttachment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"]
};
	/** order by var_pop() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_pop_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by var_samp() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_var_samp_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** order by variance() on columns of table "TaskUpdateLogAttachment" */
["TaskUpdateLogAttachment_variance_order_by"]: {
		rank?: GraphQLTypes["order_by"]
};
	/** aggregated selection of "TaskUpdateLog" */
["TaskUpdateLog_aggregate"]: {
	__typename: "TaskUpdateLog_aggregate",
	aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_fields"],
	nodes: Array<GraphQLTypes["TaskUpdateLog"]>
};
	["TaskUpdateLog_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp_count"]
};
	["TaskUpdateLog_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["TaskUpdateLog_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["TaskUpdateLog_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["TaskUpdateLog_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "TaskUpdateLog" */
["TaskUpdateLog_aggregate_fields"]: {
	__typename: "TaskUpdateLog_aggregate_fields",
	count: number,
	max?: GraphQLTypes["TaskUpdateLog_max_fields"],
	min?: GraphQLTypes["TaskUpdateLog_min_fields"]
};
	/** order by aggregate values of table "TaskUpdateLog" */
["TaskUpdateLog_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["TaskUpdateLog_max_order_by"],
	min?: GraphQLTypes["TaskUpdateLog_min_order_by"]
};
	/** input type for inserting array relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TaskUpdateLog_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskUpdateLog_on_conflict"]
};
	/** Boolean expression to filter rows from the table "TaskUpdateLog". All fields are combined with a logical 'AND'. */
["TaskUpdateLog_bool_exp"]: {
		AppointmentReport?: GraphQLTypes["AppointmentReport_bool_exp"],
	Author?: GraphQLTypes["Person_bool_exp"],
	CreatedBy?: GraphQLTypes["Person_bool_exp"],
	ServiceProviderRequest?: GraphQLTypes["ServiceProviderRequest_bool_exp"],
	Task?: GraphQLTypes["Task_bool_exp"],
	TaskPart?: GraphQLTypes["TaskPart_bool_exp"],
	TaskUpdateLogAttachments?: GraphQLTypes["TaskUpdateLogAttachment_bool_exp"],
	_and?: Array<GraphQLTypes["TaskUpdateLog_bool_exp"]>,
	_not?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	_or?: Array<GraphQLTypes["TaskUpdateLog_bool_exp"]>,
	appointmentReportId?: GraphQLTypes["uuid_comparison_exp"],
	authorId?: GraphQLTypes["uuid_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdById?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	notes?: GraphQLTypes["String_comparison_exp"],
	partsDescription?: GraphQLTypes["String_comparison_exp"],
	partsRequired?: GraphQLTypes["Boolean_comparison_exp"],
	ranOutOfTime?: GraphQLTypes["Boolean_comparison_exp"],
	serviceProviderRequestId?: GraphQLTypes["uuid_comparison_exp"],
	status?: GraphQLTypes["TaskStatus_enum_comparison_exp"],
	taskId?: GraphQLTypes["uuid_comparison_exp"],
	taskPartId?: GraphQLTypes["uuid_comparison_exp"],
	thirdPartyDescription?: GraphQLTypes["String_comparison_exp"],
	thirdPartyRequired?: GraphQLTypes["Boolean_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "TaskUpdateLog" */
["TaskUpdateLog_constraint"]: TaskUpdateLog_constraint;
	/** input type for inserting data into table "TaskUpdateLog" */
["TaskUpdateLog_insert_input"]: {
		Author?: GraphQLTypes["Person_obj_rel_insert_input"],
	CreatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	ServiceProviderRequest?: GraphQLTypes["ServiceProviderRequest_obj_rel_insert_input"],
	Task?: GraphQLTypes["Task_obj_rel_insert_input"],
	TaskPart?: GraphQLTypes["TaskPart_obj_rel_insert_input"],
	TaskUpdateLogAttachments?: GraphQLTypes["TaskUpdateLogAttachment_arr_rel_insert_input"],
	notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	/** DEPRECATED */
	partsRequired?: boolean,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["TaskStatus_enum"],
	taskId?: GraphQLTypes["uuid"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	/** DEPRECATED */
	thirdPartyRequired?: boolean
};
	/** aggregate max on columns */
["TaskUpdateLog_max_fields"]: {
	__typename: "TaskUpdateLog_max_fields",
	appointmentReportId?: GraphQLTypes["uuid"],
	authorId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_max_order_by"]: {
		appointmentReportId?: GraphQLTypes["order_by"],
	authorId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	/** DEPRECATED */
	partsDescription?: GraphQLTypes["order_by"],
	serviceProviderRequestId?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	taskPartId?: GraphQLTypes["order_by"],
	/** DEPRECATED */
	thirdPartyDescription?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["TaskUpdateLog_min_fields"]: {
	__typename: "TaskUpdateLog_min_fields",
	appointmentReportId?: GraphQLTypes["uuid"],
	authorId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	taskId?: GraphQLTypes["uuid"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "TaskUpdateLog" */
["TaskUpdateLog_min_order_by"]: {
		appointmentReportId?: GraphQLTypes["order_by"],
	authorId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	/** DEPRECATED */
	partsDescription?: GraphQLTypes["order_by"],
	serviceProviderRequestId?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	taskPartId?: GraphQLTypes["order_by"],
	/** DEPRECATED */
	thirdPartyDescription?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "TaskUpdateLog" */
["TaskUpdateLog_mutation_response"]: {
	__typename: "TaskUpdateLog_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["TaskUpdateLog"]>
};
	/** input type for inserting object relation for remote table "TaskUpdateLog" */
["TaskUpdateLog_obj_rel_insert_input"]: {
		data: GraphQLTypes["TaskUpdateLog_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["TaskUpdateLog_on_conflict"]
};
	/** on_conflict condition type for table "TaskUpdateLog" */
["TaskUpdateLog_on_conflict"]: {
		constraint: GraphQLTypes["TaskUpdateLog_constraint"],
	update_columns: Array<GraphQLTypes["TaskUpdateLog_update_column"]>,
	where?: GraphQLTypes["TaskUpdateLog_bool_exp"]
};
	/** Ordering options when selecting data from "TaskUpdateLog". */
["TaskUpdateLog_order_by"]: {
		AppointmentReport?: GraphQLTypes["AppointmentReport_order_by"],
	Author?: GraphQLTypes["Person_order_by"],
	CreatedBy?: GraphQLTypes["Person_order_by"],
	ServiceProviderRequest?: GraphQLTypes["ServiceProviderRequest_order_by"],
	Task?: GraphQLTypes["Task_order_by"],
	TaskPart?: GraphQLTypes["TaskPart_order_by"],
	TaskUpdateLogAttachments_aggregate?: GraphQLTypes["TaskUpdateLogAttachment_aggregate_order_by"],
	appointmentReportId?: GraphQLTypes["order_by"],
	authorId?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	partsDescription?: GraphQLTypes["order_by"],
	partsRequired?: GraphQLTypes["order_by"],
	ranOutOfTime?: GraphQLTypes["order_by"],
	serviceProviderRequestId?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	taskId?: GraphQLTypes["order_by"],
	taskPartId?: GraphQLTypes["order_by"],
	thirdPartyDescription?: GraphQLTypes["order_by"],
	thirdPartyRequired?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: TaskUpdateLog */
["TaskUpdateLog_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column"]: TaskUpdateLog_select_column;
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns"]: TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TaskUpdateLog" */
["TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns"]: TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TaskUpdateLog" */
["TaskUpdateLog_set_input"]: {
		notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	/** DEPRECATED */
	partsRequired?: boolean,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["TaskStatus_enum"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	/** DEPRECATED */
	thirdPartyRequired?: boolean
};
	/** Streaming cursor of the table "TaskUpdateLog" */
["TaskUpdateLog_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TaskUpdateLog_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TaskUpdateLog_stream_cursor_value_input"]: {
		appointmentReportId?: GraphQLTypes["uuid"],
	authorId?: GraphQLTypes["uuid"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	notes?: string,
	/** DEPRECATED */
	partsDescription?: string,
	/** DEPRECATED */
	partsRequired?: boolean,
	ranOutOfTime?: boolean,
	serviceProviderRequestId?: GraphQLTypes["uuid"],
	status?: GraphQLTypes["TaskStatus_enum"],
	taskId?: GraphQLTypes["uuid"],
	taskPartId?: GraphQLTypes["uuid"],
	/** DEPRECATED */
	thirdPartyDescription?: string,
	/** DEPRECATED */
	thirdPartyRequired?: boolean,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** update columns of table "TaskUpdateLog" */
["TaskUpdateLog_update_column"]: TaskUpdateLog_update_column;
	["TaskUpdateLog_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["TaskUpdateLog_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["TaskUpdateLog_bool_exp"]
};
	/** aggregated selection of "Task" */
["Task_aggregate"]: {
	__typename: "Task_aggregate",
	aggregate?: GraphQLTypes["Task_aggregate_fields"],
	nodes: Array<GraphQLTypes["Task"]>
};
	["Task_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["Task_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["Task_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["Task_aggregate_bool_exp_count"]
};
	["Task_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["Task_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["Task_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["Task_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["Task_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["Task_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["Task_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "Task" */
["Task_aggregate_fields"]: {
	__typename: "Task_aggregate_fields",
	avg?: GraphQLTypes["Task_avg_fields"],
	count: number,
	max?: GraphQLTypes["Task_max_fields"],
	min?: GraphQLTypes["Task_min_fields"],
	stddev?: GraphQLTypes["Task_stddev_fields"],
	stddev_pop?: GraphQLTypes["Task_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["Task_stddev_samp_fields"],
	sum?: GraphQLTypes["Task_sum_fields"],
	var_pop?: GraphQLTypes["Task_var_pop_fields"],
	var_samp?: GraphQLTypes["Task_var_samp_fields"],
	variance?: GraphQLTypes["Task_variance_fields"]
};
	/** order by aggregate values of table "Task" */
["Task_aggregate_order_by"]: {
		avg?: GraphQLTypes["Task_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["Task_max_order_by"],
	min?: GraphQLTypes["Task_min_order_by"],
	stddev?: GraphQLTypes["Task_stddev_order_by"],
	stddev_pop?: GraphQLTypes["Task_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["Task_stddev_samp_order_by"],
	sum?: GraphQLTypes["Task_sum_order_by"],
	var_pop?: GraphQLTypes["Task_var_pop_order_by"],
	var_samp?: GraphQLTypes["Task_var_samp_order_by"],
	variance?: GraphQLTypes["Task_variance_order_by"]
};
	/** input type for inserting array relation for remote table "Task" */
["Task_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["Task_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["Task_on_conflict"]
};
	/** aggregate avg on columns */
["Task_avg_fields"]: {
	__typename: "Task_avg_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by avg() on columns of table "Task" */
["Task_avg_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "Task". All fields are combined with a logical 'AND'. */
["Task_bool_exp"]: {
		Appointment?: GraphQLTypes["Appointment_bool_exp"],
	Comments?: GraphQLTypes["TaskComment_bool_exp"],
	Comments_aggregate?: GraphQLTypes["TaskComment_aggregate_bool_exp"],
	CreatedBy?: GraphQLTypes["Person_bool_exp"],
	Home?: GraphQLTypes["Home_bool_exp"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_bool_exp"],
	LastUpdatedBy?: GraphQLTypes["Person_bool_exp"],
	ServiceProviderRequests?: GraphQLTypes["ServiceProviderRequest_bool_exp"],
	TaskAttachments?: GraphQLTypes["TaskAttachment_bool_exp"],
	TaskParts?: GraphQLTypes["TaskPart_bool_exp"],
	TaskUpdateLogs?: GraphQLTypes["TaskUpdateLog_bool_exp"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_bool_exp"],
	_and?: Array<GraphQLTypes["Task_bool_exp"]>,
	_not?: GraphQLTypes["Task_bool_exp"],
	_or?: Array<GraphQLTypes["Task_bool_exp"]>,
	appointmentId?: GraphQLTypes["uuid_comparison_exp"],
	completedAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdAt?: GraphQLTypes["timestamptz_comparison_exp"],
	createdById?: GraphQLTypes["uuid_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	dueAt?: GraphQLTypes["timestamptz_comparison_exp"],
	estimatedCompletionTime?: GraphQLTypes["Int_comparison_exp"],
	homeId?: GraphQLTypes["uuid_comparison_exp"],
	homeInventoryItemId?: GraphQLTypes["uuid_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	lastUpdatedById?: GraphQLTypes["uuid_comparison_exp"],
	notes?: GraphQLTypes["String_comparison_exp"],
	parentTaskId?: GraphQLTypes["uuid_comparison_exp"],
	partsRequired?: GraphQLTypes["Boolean_comparison_exp"],
	priorityLevel?: GraphQLTypes["Int_comparison_exp"],
	requiresOperationalReview?: GraphQLTypes["Boolean_comparison_exp"],
	status?: GraphQLTypes["TaskStatus_enum_comparison_exp"],
	thirdPartyRequired?: GraphQLTypes["Boolean_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["TaskType_enum_comparison_exp"],
	updatedAt?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "Task" */
["Task_constraint"]: Task_constraint;
	/** input type for incrementing numeric columns in table "Task" */
["Task_inc_input"]: {
		/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** input type for inserting data into table "Task" */
["Task_insert_input"]: {
		Appointment?: GraphQLTypes["Appointment_obj_rel_insert_input"],
	Comments?: GraphQLTypes["TaskComment_arr_rel_insert_input"],
	CreatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_obj_rel_insert_input"],
	LastUpdatedBy?: GraphQLTypes["Person_obj_rel_insert_input"],
	ServiceProviderRequests?: GraphQLTypes["ServiceProviderRequest_arr_rel_insert_input"],
	TaskAttachments?: GraphQLTypes["TaskAttachment_arr_rel_insert_input"],
	TaskParts?: GraphQLTypes["TaskPart_arr_rel_insert_input"],
	TaskUpdateLogs?: GraphQLTypes["TaskUpdateLog_arr_rel_insert_input"],
	appointmentId?: GraphQLTypes["uuid"],
	completedAt?: GraphQLTypes["timestamptz"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	parentTaskId?: GraphQLTypes["uuid"],
	requiresOperationalReview?: boolean,
	title?: string,
	type?: GraphQLTypes["TaskType_enum"]
};
	/** aggregate max on columns */
["Task_max_fields"]: {
	__typename: "Task_max_fields",
	appointmentId?: GraphQLTypes["uuid"],
	completedAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	lastUpdatedById?: GraphQLTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: string,
	parentTaskId?: GraphQLTypes["uuid"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number,
	title?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "Task" */
["Task_max_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	completedAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	dueAt?: GraphQLTypes["order_by"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastUpdatedById?: GraphQLTypes["order_by"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: GraphQLTypes["order_by"],
	parentTaskId?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["Task_min_fields"]: {
	__typename: "Task_min_fields",
	appointmentId?: GraphQLTypes["uuid"],
	completedAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	lastUpdatedById?: GraphQLTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: string,
	parentTaskId?: GraphQLTypes["uuid"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number,
	title?: string,
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "Task" */
["Task_min_order_by"]: {
		appointmentId?: GraphQLTypes["order_by"],
	completedAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	dueAt?: GraphQLTypes["order_by"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastUpdatedById?: GraphQLTypes["order_by"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: GraphQLTypes["order_by"],
	parentTaskId?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "Task" */
["Task_mutation_response"]: {
	__typename: "Task_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["Task"]>
};
	/** input type for inserting object relation for remote table "Task" */
["Task_obj_rel_insert_input"]: {
		data: GraphQLTypes["Task_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["Task_on_conflict"]
};
	/** on_conflict condition type for table "Task" */
["Task_on_conflict"]: {
		constraint: GraphQLTypes["Task_constraint"],
	update_columns: Array<GraphQLTypes["Task_update_column"]>,
	where?: GraphQLTypes["Task_bool_exp"]
};
	/** Ordering options when selecting data from "Task". */
["Task_order_by"]: {
		Appointment?: GraphQLTypes["Appointment_order_by"],
	Comments_aggregate?: GraphQLTypes["TaskComment_aggregate_order_by"],
	CreatedBy?: GraphQLTypes["Person_order_by"],
	Home?: GraphQLTypes["Home_order_by"],
	HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_order_by"],
	LastUpdatedBy?: GraphQLTypes["Person_order_by"],
	ServiceProviderRequests_aggregate?: GraphQLTypes["ServiceProviderRequest_aggregate_order_by"],
	TaskAttachments_aggregate?: GraphQLTypes["TaskAttachment_aggregate_order_by"],
	TaskParts_aggregate?: GraphQLTypes["TaskPart_aggregate_order_by"],
	TaskUpdateLogs_aggregate?: GraphQLTypes["TaskUpdateLog_aggregate_order_by"],
	appointmentId?: GraphQLTypes["order_by"],
	completedAt?: GraphQLTypes["order_by"],
	createdAt?: GraphQLTypes["order_by"],
	createdById?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	dueAt?: GraphQLTypes["order_by"],
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	homeId?: GraphQLTypes["order_by"],
	homeInventoryItemId?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lastUpdatedById?: GraphQLTypes["order_by"],
	notes?: GraphQLTypes["order_by"],
	parentTaskId?: GraphQLTypes["order_by"],
	partsRequired?: GraphQLTypes["order_by"],
	priorityLevel?: GraphQLTypes["order_by"],
	requiresOperationalReview?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	thirdPartyRequired?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updatedAt?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: Task */
["Task_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "Task" */
["Task_select_column"]: Task_select_column;
	/** select "Task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns"]: Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "Task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Task" */
["Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns"]: Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "Task" */
["Task_set_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: string,
	parentTaskId?: GraphQLTypes["uuid"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number,
	requiresOperationalReview?: boolean,
	status?: GraphQLTypes["TaskStatus_enum"],
	title?: string
};
	/** aggregate stddev on columns */
["Task_stddev_fields"]: {
	__typename: "Task_stddev_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by stddev() on columns of table "Task" */
["Task_stddev_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["Task_stddev_pop_fields"]: {
	__typename: "Task_stddev_pop_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by stddev_pop() on columns of table "Task" */
["Task_stddev_pop_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["Task_stddev_samp_fields"]: {
	__typename: "Task_stddev_samp_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by stddev_samp() on columns of table "Task" */
["Task_stddev_samp_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "Task" */
["Task_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["Task_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["Task_stream_cursor_value_input"]: {
		appointmentId?: GraphQLTypes["uuid"],
	completedAt?: GraphQLTypes["timestamptz"],
	createdAt?: GraphQLTypes["timestamptz"],
	createdById?: GraphQLTypes["uuid"],
	description?: string,
	dueAt?: GraphQLTypes["timestamptz"],
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	homeId?: GraphQLTypes["uuid"],
	homeInventoryItemId?: GraphQLTypes["uuid"],
	id?: GraphQLTypes["uuid"],
	lastUpdatedById?: GraphQLTypes["uuid"],
	/** Additional unstructured data about the Task. Internal use only */
	notes?: string,
	parentTaskId?: GraphQLTypes["uuid"],
	partsRequired?: boolean,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number,
	requiresOperationalReview?: boolean,
	status?: GraphQLTypes["TaskStatus_enum"],
	thirdPartyRequired?: boolean,
	title?: string,
	type?: GraphQLTypes["TaskType_enum"],
	updatedAt?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["Task_sum_fields"]: {
	__typename: "Task_sum_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by sum() on columns of table "Task" */
["Task_sum_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** update columns of table "Task" */
["Task_update_column"]: Task_update_column;
	["Task_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["Task_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["Task_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["Task_bool_exp"]
};
	/** aggregate var_pop on columns */
["Task_var_pop_fields"]: {
	__typename: "Task_var_pop_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by var_pop() on columns of table "Task" */
["Task_var_pop_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["Task_var_samp_fields"]: {
	__typename: "Task_var_samp_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by var_samp() on columns of table "Task" */
["Task_var_samp_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["Task_variance_fields"]: {
	__typename: "Task_variance_fields",
	/** Value is number of minutes. */
	estimatedCompletionTime?: number,
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: number
};
	/** order by variance() on columns of table "Task" */
["Task_variance_order_by"]: {
		/** Value is number of minutes. */
	estimatedCompletionTime?: GraphQLTypes["order_by"],
	/** The importance of the task. Zero means unspecified. Higher numbers are higher priority. */
	priorityLevel?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "TimeZone" */
["TimeZone"]: {
	__typename: "TimeZone",
	abbrev?: string,
	is_dst?: boolean,
	name?: string,
	utc_offset?: GraphQLTypes["interval"]
};
	/** Boolean expression to filter rows from the table "TimeZone". All fields are combined with a logical 'AND'. */
["TimeZone_bool_exp"]: {
		_and?: Array<GraphQLTypes["TimeZone_bool_exp"]>,
	_not?: GraphQLTypes["TimeZone_bool_exp"],
	_or?: Array<GraphQLTypes["TimeZone_bool_exp"]>,
	abbrev?: GraphQLTypes["String_comparison_exp"],
	is_dst?: GraphQLTypes["Boolean_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	utc_offset?: GraphQLTypes["interval_comparison_exp"]
};
	/** Ordering options when selecting data from "TimeZone". */
["TimeZone_order_by"]: {
		abbrev?: GraphQLTypes["order_by"],
	is_dst?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	utc_offset?: GraphQLTypes["order_by"]
};
	/** select columns of table "TimeZone" */
["TimeZone_select_column"]: TimeZone_select_column;
	/** Streaming cursor of the table "TimeZone" */
["TimeZone_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["TimeZone_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["TimeZone_stream_cursor_value_input"]: {
		abbrev?: string,
	is_dst?: boolean,
	name?: string,
	utc_offset?: GraphQLTypes["interval"]
};
	["create_Stripe_SetupIntent_output"]: {
	__typename: "create_Stripe_SetupIntent_output",
	ephemeralKey: string,
	publishableKey: string,
	setupIntentClientSecret: string
};
	["create_Stripe_SubscriptionIntent_input"]: {
		couponId?: string,
	priceId: string
};
	["create_Stripe_SubscriptionIntent_output"]: {
	__typename: "create_Stripe_SubscriptionIntent_output",
	ephemeralKey?: string,
	paymentIntentClientSecret?: string,
	publishableKey: string
};
	/** ordering argument of a cursor */
["cursor_ordering"]: cursor_ordering;
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
		_eq?: GraphQLTypes["date"],
	_gt?: GraphQLTypes["date"],
	_gte?: GraphQLTypes["date"],
	_in?: Array<GraphQLTypes["date"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["date"],
	_lte?: GraphQLTypes["date"],
	_neq?: GraphQLTypes["date"],
	_nin?: Array<GraphQLTypes["date"]>
};
	["find_Person_by_phone_args"]: {
		number?: string
};
	/** columns and relationships of "find_Person_response" */
["find_Person_response"]: {
	__typename: "find_Person_response",
	present?: boolean
};
	/** Boolean expression to filter rows from the table "find_Person_response". All fields are combined with a logical 'AND'. */
["find_Person_response_bool_exp"]: {
		_and?: Array<GraphQLTypes["find_Person_response_bool_exp"]>,
	_not?: GraphQLTypes["find_Person_response_bool_exp"],
	_or?: Array<GraphQLTypes["find_Person_response_bool_exp"]>,
	present?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** Ordering options when selecting data from "find_Person_response". */
["find_Person_response_order_by"]: {
		present?: GraphQLTypes["order_by"]
};
	/** select columns of table "find_Person_response" */
["find_Person_response_select_column"]: find_Person_response_select_column;
	/** Streaming cursor of the table "find_Person_response" */
["find_Person_response_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["find_Person_response_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["find_Person_response_stream_cursor_value_input"]: {
		present?: boolean
};
	["geography"]:any;
	["geography_cast_exp"]: {
		geometry?: GraphQLTypes["geometry_comparison_exp"]
};
	/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
["geography_comparison_exp"]: {
		_cast?: GraphQLTypes["geography_cast_exp"],
	_eq?: GraphQLTypes["geography"],
	_gt?: GraphQLTypes["geography"],
	_gte?: GraphQLTypes["geography"],
	_in?: Array<GraphQLTypes["geography"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["geography"],
	_lte?: GraphQLTypes["geography"],
	_neq?: GraphQLTypes["geography"],
	_nin?: Array<GraphQLTypes["geography"]>,
	/** is the column within a given distance from the given geography value */
	_st_d_within?: GraphQLTypes["st_d_within_geography_input"],
	/** does the column spatially intersect the given geography value */
	_st_intersects?: GraphQLTypes["geography"]
};
	["geometry"]:any;
	["geometry_cast_exp"]: {
		geography?: GraphQLTypes["geography_comparison_exp"]
};
	/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
["geometry_comparison_exp"]: {
		_cast?: GraphQLTypes["geometry_cast_exp"],
	_eq?: GraphQLTypes["geometry"],
	_gt?: GraphQLTypes["geometry"],
	_gte?: GraphQLTypes["geometry"],
	_in?: Array<GraphQLTypes["geometry"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["geometry"],
	_lte?: GraphQLTypes["geometry"],
	_neq?: GraphQLTypes["geometry"],
	_nin?: Array<GraphQLTypes["geometry"]>,
	/** is the column within a given 3D distance from the given geometry value */
	_st_3d_d_within?: GraphQLTypes["st_d_within_input"],
	/** does the column spatially intersect the given geometry value in 3D */
	_st_3d_intersects?: GraphQLTypes["geometry"],
	/** does the column contain the given geometry value */
	_st_contains?: GraphQLTypes["geometry"],
	/** does the column cross the given geometry value */
	_st_crosses?: GraphQLTypes["geometry"],
	/** is the column within a given distance from the given geometry value */
	_st_d_within?: GraphQLTypes["st_d_within_input"],
	/** is the column equal to given geometry value (directionality is ignored) */
	_st_equals?: GraphQLTypes["geometry"],
	/** does the column spatially intersect the given geometry value */
	_st_intersects?: GraphQLTypes["geometry"],
	/** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
	_st_overlaps?: GraphQLTypes["geometry"],
	/** does the column have atleast one point in common with the given geometry value */
	_st_touches?: GraphQLTypes["geometry"],
	/** is the column contained in the given geometry value */
	_st_within?: GraphQLTypes["geometry"]
};
	["interval"]:any;
	/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
["interval_comparison_exp"]: {
		_eq?: GraphQLTypes["interval"],
	_gt?: GraphQLTypes["interval"],
	_gte?: GraphQLTypes["interval"],
	_in?: Array<GraphQLTypes["interval"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["interval"],
	_lte?: GraphQLTypes["interval"],
	_neq?: GraphQLTypes["interval"],
	_nin?: Array<GraphQLTypes["interval"]>
};
	["jsonb"]:any;
	["jsonb_cast_exp"]: {
		String?: GraphQLTypes["String_comparison_exp"]
};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
		_cast?: GraphQLTypes["jsonb_cast_exp"],
	/** is the column contained in the given json value */
	_contained_in?: GraphQLTypes["jsonb"],
	/** does the column contain the given json value at the top level */
	_contains?: GraphQLTypes["jsonb"],
	_eq?: GraphQLTypes["jsonb"],
	_gt?: GraphQLTypes["jsonb"],
	_gte?: GraphQLTypes["jsonb"],
	/** does the string exist as a top-level key in the column */
	_has_key?: string,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: Array<string>,
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: Array<string>,
	_in?: Array<GraphQLTypes["jsonb"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["jsonb"],
	_lte?: GraphQLTypes["jsonb"],
	_neq?: GraphQLTypes["jsonb"],
	_nin?: Array<GraphQLTypes["jsonb"]>
};
	/** mutation root */
["mutation_root"]: {
	__typename: "mutation_root",
	create_Stripe_Portal?: GraphQLTypes["Stripe_Portal"],
	/** create_Stripe_SetupIntent */
	create_Stripe_SetupIntent: GraphQLTypes["create_Stripe_SetupIntent_output"],
	/** Creates a Stripe PaymentIntent for a payment subscription */
	create_Stripe_SubscriptionIntent: GraphQLTypes["create_Stripe_SubscriptionIntent_output"],
	/** delete data from the table: "Appointment" */
	delete_Appointment?: GraphQLTypes["Appointment_mutation_response"],
	/** delete data from the table: "AppointmentProvider" */
	delete_AppointmentProvider?: GraphQLTypes["AppointmentProvider_mutation_response"],
	/** delete single row from the table: "AppointmentProvider" */
	delete_AppointmentProvider_by_pk?: GraphQLTypes["AppointmentProvider"],
	/** delete single row from the table: "Appointment" */
	delete_Appointment_by_pk?: GraphQLTypes["Appointment"],
	/** delete data from the table: "Attachment" */
	delete_Attachment?: GraphQLTypes["Attachment_mutation_response"],
	/** delete single row from the table: "Attachment" */
	delete_Attachment_by_pk?: GraphQLTypes["Attachment"],
	/** delete data from the table: "HomeInventoryItemAttachment" */
	delete_HomeInventoryItemAttachment?: GraphQLTypes["HomeInventoryItemAttachment_mutation_response"],
	/** delete single row from the table: "HomeInventoryItemAttachment" */
	delete_HomeInventoryItemAttachment_by_pk?: GraphQLTypes["HomeInventoryItemAttachment"],
	/** delete data from the table: "Task" */
	delete_Task?: GraphQLTypes["Task_mutation_response"],
	/** delete data from the table: "TaskAttachment" */
	delete_TaskAttachment?: GraphQLTypes["TaskAttachment_mutation_response"],
	/** delete single row from the table: "TaskAttachment" */
	delete_TaskAttachment_by_pk?: GraphQLTypes["TaskAttachment"],
	/** delete single row from the table: "Task" */
	delete_Task_by_pk?: GraphQLTypes["Task"],
	/** encryptMessageHomeowner */
	encryptDataHomeowner?: GraphQLTypes["EncryptedMessage"],
	/** insert data into the table: "Appointment" */
	insert_Appointment?: GraphQLTypes["Appointment_mutation_response"],
	/** insert data into the table: "AppointmentGrade" */
	insert_AppointmentGrade?: GraphQLTypes["AppointmentGrade_mutation_response"],
	/** insert data into the table: "AppointmentGradeSelection" */
	insert_AppointmentGradeSelection?: GraphQLTypes["AppointmentGradeSelection_mutation_response"],
	/** insert a single row into the table: "AppointmentGradeSelection" */
	insert_AppointmentGradeSelection_one?: GraphQLTypes["AppointmentGradeSelection"],
	/** insert a single row into the table: "AppointmentGrade" */
	insert_AppointmentGrade_one?: GraphQLTypes["AppointmentGrade"],
	/** insert data into the table: "AppointmentProvider" */
	insert_AppointmentProvider?: GraphQLTypes["AppointmentProvider_mutation_response"],
	/** insert a single row into the table: "AppointmentProvider" */
	insert_AppointmentProvider_one?: GraphQLTypes["AppointmentProvider"],
	/** insert a single row into the table: "Appointment" */
	insert_Appointment_one?: GraphQLTypes["Appointment"],
	/** insert data into the table: "Attachment" */
	insert_Attachment?: GraphQLTypes["Attachment_mutation_response"],
	/** insert a single row into the table: "Attachment" */
	insert_Attachment_one?: GraphQLTypes["Attachment"],
	/** insert data into the table: "AvailabilityResult" */
	insert_AvailabilityResult?: GraphQLTypes["AvailabilityResult_mutation_response"],
	/** insert a single row into the table: "AvailabilityResult" */
	insert_AvailabilityResult_one?: GraphQLTypes["AvailabilityResult"],
	/** insert data into the table: "DataDeletionRequest" */
	insert_DataDeletionRequest?: GraphQLTypes["DataDeletionRequest_mutation_response"],
	/** insert a single row into the table: "DataDeletionRequest" */
	insert_DataDeletionRequest_one?: GraphQLTypes["DataDeletionRequest"],
	/** insert data into the table: "Device" */
	insert_Device?: GraphQLTypes["Device_mutation_response"],
	/** insert a single row into the table: "Device" */
	insert_Device_one?: GraphQLTypes["Device"],
	/** insert data into the table: "HomeInventoryItem" */
	insert_HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_mutation_response"],
	/** insert data into the table: "HomeInventoryItemAttachment" */
	insert_HomeInventoryItemAttachment?: GraphQLTypes["HomeInventoryItemAttachment_mutation_response"],
	/** insert a single row into the table: "HomeInventoryItemAttachment" */
	insert_HomeInventoryItemAttachment_one?: GraphQLTypes["HomeInventoryItemAttachment"],
	/** insert a single row into the table: "HomeInventoryItem" */
	insert_HomeInventoryItem_one?: GraphQLTypes["HomeInventoryItem"],
	/** insert data into the table: "HomeWaitlist" */
	insert_HomeWaitlist?: GraphQLTypes["HomeWaitlist_mutation_response"],
	/** insert a single row into the table: "HomeWaitlist" */
	insert_HomeWaitlist_one?: GraphQLTypes["HomeWaitlist"],
	/** insert data into the table: "Person" */
	insert_Person?: GraphQLTypes["Person_mutation_response"],
	/** insert data into the table: "PersonBiographyItem" */
	insert_PersonBiographyItem?: GraphQLTypes["PersonBiographyItem_mutation_response"],
	/** insert a single row into the table: "PersonBiographyItem" */
	insert_PersonBiographyItem_one?: GraphQLTypes["PersonBiographyItem"],
	/** insert data into the table: "PersonHome" */
	insert_PersonHome?: GraphQLTypes["PersonHome_mutation_response"],
	/** insert a single row into the table: "PersonHome" */
	insert_PersonHome_one?: GraphQLTypes["PersonHome"],
	/** insert a single row into the table: "Person" */
	insert_Person_one?: GraphQLTypes["Person"],
	/** insert data into the table: "Referral" */
	insert_Referral?: GraphQLTypes["Referral_mutation_response"],
	/** insert a single row into the table: "Referral" */
	insert_Referral_one?: GraphQLTypes["Referral"],
	/** insert data into the table: "ServiceOfferResponse" */
	insert_ServiceOfferResponse?: GraphQLTypes["ServiceOfferResponse_mutation_response"],
	/** insert a single row into the table: "ServiceOfferResponse" */
	insert_ServiceOfferResponse_one?: GraphQLTypes["ServiceOfferResponse"],
	/** insert data into the table: "ServiceProviderRequest" */
	insert_ServiceProviderRequest?: GraphQLTypes["ServiceProviderRequest_mutation_response"],
	/** insert a single row into the table: "ServiceProviderRequest" */
	insert_ServiceProviderRequest_one?: GraphQLTypes["ServiceProviderRequest"],
	/** insert data into the table: "Task" */
	insert_Task?: GraphQLTypes["Task_mutation_response"],
	/** insert data into the table: "TaskAttachment" */
	insert_TaskAttachment?: GraphQLTypes["TaskAttachment_mutation_response"],
	/** insert a single row into the table: "TaskAttachment" */
	insert_TaskAttachment_one?: GraphQLTypes["TaskAttachment"],
	/** insert data into the table: "TaskComment" */
	insert_TaskComment?: GraphQLTypes["TaskComment_mutation_response"],
	/** insert a single row into the table: "TaskComment" */
	insert_TaskComment_one?: GraphQLTypes["TaskComment"],
	/** insert data into the table: "TaskPart" */
	insert_TaskPart?: GraphQLTypes["TaskPart_mutation_response"],
	/** insert a single row into the table: "TaskPart" */
	insert_TaskPart_one?: GraphQLTypes["TaskPart"],
	/** insert data into the table: "TaskUpdateLog" */
	insert_TaskUpdateLog?: GraphQLTypes["TaskUpdateLog_mutation_response"],
	/** insert data into the table: "TaskUpdateLogAttachment" */
	insert_TaskUpdateLogAttachment?: GraphQLTypes["TaskUpdateLogAttachment_mutation_response"],
	/** insert a single row into the table: "TaskUpdateLogAttachment" */
	insert_TaskUpdateLogAttachment_one?: GraphQLTypes["TaskUpdateLogAttachment"],
	/** insert a single row into the table: "TaskUpdateLog" */
	insert_TaskUpdateLog_one?: GraphQLTypes["TaskUpdateLog"],
	/** insert a single row into the table: "Task" */
	insert_Task_one?: GraphQLTypes["Task"],
	/** update data of the table: "Appointment" */
	update_Appointment?: GraphQLTypes["Appointment_mutation_response"],
	/** update single row of the table: "Appointment" */
	update_Appointment_by_pk?: GraphQLTypes["Appointment"],
	/** update multiples rows of table: "Appointment" */
	update_Appointment_many?: Array<GraphQLTypes["Appointment_mutation_response"] | undefined>,
	/** update data of the table: "Attachment" */
	update_Attachment?: GraphQLTypes["Attachment_mutation_response"],
	/** update single row of the table: "Attachment" */
	update_Attachment_by_pk?: GraphQLTypes["Attachment"],
	/** update multiples rows of table: "Attachment" */
	update_Attachment_many?: Array<GraphQLTypes["Attachment_mutation_response"] | undefined>,
	/** update data of the table: "AvailabilityResult" */
	update_AvailabilityResult?: GraphQLTypes["AvailabilityResult_mutation_response"],
	/** update multiples rows of table: "AvailabilityResult" */
	update_AvailabilityResult_many?: Array<GraphQLTypes["AvailabilityResult_mutation_response"] | undefined>,
	/** update data of the table: "Device" */
	update_Device?: GraphQLTypes["Device_mutation_response"],
	/** update single row of the table: "Device" */
	update_Device_by_pk?: GraphQLTypes["Device"],
	/** update multiples rows of table: "Device" */
	update_Device_many?: Array<GraphQLTypes["Device_mutation_response"] | undefined>,
	/** update data of the table: "Home" */
	update_Home?: GraphQLTypes["Home_mutation_response"],
	/** update data of the table: "HomeInventoryItem" */
	update_HomeInventoryItem?: GraphQLTypes["HomeInventoryItem_mutation_response"],
	/** update data of the table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment?: GraphQLTypes["HomeInventoryItemAttachment_mutation_response"],
	/** update single row of the table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment_by_pk?: GraphQLTypes["HomeInventoryItemAttachment"],
	/** update multiples rows of table: "HomeInventoryItemAttachment" */
	update_HomeInventoryItemAttachment_many?: Array<GraphQLTypes["HomeInventoryItemAttachment_mutation_response"] | undefined>,
	/** update single row of the table: "HomeInventoryItem" */
	update_HomeInventoryItem_by_pk?: GraphQLTypes["HomeInventoryItem"],
	/** update multiples rows of table: "HomeInventoryItem" */
	update_HomeInventoryItem_many?: Array<GraphQLTypes["HomeInventoryItem_mutation_response"] | undefined>,
	/** update single row of the table: "Home" */
	update_Home_by_pk?: GraphQLTypes["Home"],
	/** update multiples rows of table: "Home" */
	update_Home_many?: Array<GraphQLTypes["Home_mutation_response"] | undefined>,
	/** update data of the table: "MarketingLead" */
	update_MarketingLead?: GraphQLTypes["MarketingLead_mutation_response"],
	/** update single row of the table: "MarketingLead" */
	update_MarketingLead_by_pk?: GraphQLTypes["MarketingLead"],
	/** update multiples rows of table: "MarketingLead" */
	update_MarketingLead_many?: Array<GraphQLTypes["MarketingLead_mutation_response"] | undefined>,
	/** update data of the table: "Person" */
	update_Person?: GraphQLTypes["Person_mutation_response"],
	/** update data of the table: "PersonBiographyItem" */
	update_PersonBiographyItem?: GraphQLTypes["PersonBiographyItem_mutation_response"],
	/** update single row of the table: "PersonBiographyItem" */
	update_PersonBiographyItem_by_pk?: GraphQLTypes["PersonBiographyItem"],
	/** update multiples rows of table: "PersonBiographyItem" */
	update_PersonBiographyItem_many?: Array<GraphQLTypes["PersonBiographyItem_mutation_response"] | undefined>,
	/** update single row of the table: "Person" */
	update_Person_by_pk?: GraphQLTypes["Person"],
	/** update multiples rows of table: "Person" */
	update_Person_many?: Array<GraphQLTypes["Person_mutation_response"] | undefined>,
	/** update data of the table: "ServiceOfferResponse" */
	update_ServiceOfferResponse?: GraphQLTypes["ServiceOfferResponse_mutation_response"],
	/** update single row of the table: "ServiceOfferResponse" */
	update_ServiceOfferResponse_by_pk?: GraphQLTypes["ServiceOfferResponse"],
	/** update multiples rows of table: "ServiceOfferResponse" */
	update_ServiceOfferResponse_many?: Array<GraphQLTypes["ServiceOfferResponse_mutation_response"] | undefined>,
	/** update data of the table: "Task" */
	update_Task?: GraphQLTypes["Task_mutation_response"],
	/** update data of the table: "TaskAttachment" */
	update_TaskAttachment?: GraphQLTypes["TaskAttachment_mutation_response"],
	/** update single row of the table: "TaskAttachment" */
	update_TaskAttachment_by_pk?: GraphQLTypes["TaskAttachment"],
	/** update multiples rows of table: "TaskAttachment" */
	update_TaskAttachment_many?: Array<GraphQLTypes["TaskAttachment_mutation_response"] | undefined>,
	/** update data of the table: "TaskComment" */
	update_TaskComment?: GraphQLTypes["TaskComment_mutation_response"],
	/** update multiples rows of table: "TaskComment" */
	update_TaskComment_many?: Array<GraphQLTypes["TaskComment_mutation_response"] | undefined>,
	/** update data of the table: "TaskPart" */
	update_TaskPart?: GraphQLTypes["TaskPart_mutation_response"],
	/** update single row of the table: "TaskPart" */
	update_TaskPart_by_pk?: GraphQLTypes["TaskPart"],
	/** update multiples rows of table: "TaskPart" */
	update_TaskPart_many?: Array<GraphQLTypes["TaskPart_mutation_response"] | undefined>,
	/** update data of the table: "TaskUpdateLog" */
	update_TaskUpdateLog?: GraphQLTypes["TaskUpdateLog_mutation_response"],
	/** update data of the table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment?: GraphQLTypes["TaskUpdateLogAttachment_mutation_response"],
	/** update single row of the table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment_by_pk?: GraphQLTypes["TaskUpdateLogAttachment"],
	/** update multiples rows of table: "TaskUpdateLogAttachment" */
	update_TaskUpdateLogAttachment_many?: Array<GraphQLTypes["TaskUpdateLogAttachment_mutation_response"] | undefined>,
	/** update single row of the table: "TaskUpdateLog" */
	update_TaskUpdateLog_by_pk?: GraphQLTypes["TaskUpdateLog"],
	/** update multiples rows of table: "TaskUpdateLog" */
	update_TaskUpdateLog_many?: Array<GraphQLTypes["TaskUpdateLog_mutation_response"] | undefined>,
	/** update single row of the table: "Task" */
	update_Task_by_pk?: GraphQLTypes["Task"],
	/** update multiples rows of table: "Task" */
	update_Task_many?: Array<GraphQLTypes["Task_mutation_response"] | undefined>
};
	/** column ordering options */
["order_by"]: order_by;
	["query_root"]: {
	__typename: "query_root",
	/** fetch data from the table: "Appointment" */
	Appointment: Array<GraphQLTypes["Appointment"]>,
	/** fetch data from the table: "AppointmentGrade" */
	AppointmentGrade: Array<GraphQLTypes["AppointmentGrade"]>,
	/** fetch data from the table: "AppointmentGradeSelection" */
	AppointmentGradeSelection: Array<GraphQLTypes["AppointmentGradeSelection"]>,
	/** fetch data from the table: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory: Array<GraphQLTypes["AppointmentGradeSelectionCategory"]>,
	/** fetch data from the table: "AppointmentGradeSelectionCategory" using primary key columns */
	AppointmentGradeSelectionCategory_by_pk?: GraphQLTypes["AppointmentGradeSelectionCategory"],
	/** fetch data from the table: "AppointmentGradeSelection" using primary key columns */
	AppointmentGradeSelection_by_pk?: GraphQLTypes["AppointmentGradeSelection"],
	/** fetch data from the table: "AppointmentGrade" using primary key columns */
	AppointmentGrade_by_pk?: GraphQLTypes["AppointmentGrade"],
	/** fetch data from the table: "AppointmentProvider" */
	AppointmentProvider: Array<GraphQLTypes["AppointmentProvider"]>,
	/** fetch aggregated fields from the table: "AppointmentProvider" */
	AppointmentProvider_aggregate: GraphQLTypes["AppointmentProvider_aggregate"],
	/** fetch data from the table: "AppointmentProvider" using primary key columns */
	AppointmentProvider_by_pk?: GraphQLTypes["AppointmentProvider"],
	/** fetch data from the table: "AppointmentReport" */
	AppointmentReport: Array<GraphQLTypes["AppointmentReport"]>,
	/** fetch data from the table: "AppointmentReport" using primary key columns */
	AppointmentReport_by_pk?: GraphQLTypes["AppointmentReport"],
	/** fetch aggregated fields from the table: "Appointment" */
	Appointment_aggregate: GraphQLTypes["Appointment_aggregate"],
	/** fetch data from the table: "Appointment" using primary key columns */
	Appointment_by_pk?: GraphQLTypes["Appointment"],
	/** fetch data from the table: "Attachment" */
	Attachment: Array<GraphQLTypes["Attachment"]>,
	/** fetch data from the table: "Attachment" using primary key columns */
	Attachment_by_pk?: GraphQLTypes["Attachment"],
	/** An array relationship */
	Availability: Array<GraphQLTypes["Availability"]>,
	/** fetch data from the table: "AvailabilityResult" */
	AvailabilityResult: Array<GraphQLTypes["AvailabilityResult"]>,
	/** fetch data from the table: "AvailableAppointment" */
	AvailableAppointment: Array<GraphQLTypes["AvailableAppointment"]>,
	/** fetch data from the table: "ClientRelease" */
	ClientRelease: Array<GraphQLTypes["ClientRelease"]>,
	/** fetch data from the table: "ClientRelease" using primary key columns */
	ClientRelease_by_pk?: GraphQLTypes["ClientRelease"],
	/** fetch data from the table: "ClientType" */
	ClientType: Array<GraphQLTypes["ClientType"]>,
	/** fetch data from the table: "ClientType" using primary key columns */
	ClientType_by_pk?: GraphQLTypes["ClientType"],
	/** fetch data from the table: "ContentBlock" */
	ContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	/** fetch data from the table: "ContentBlock" using primary key columns */
	ContentBlock_by_pk?: GraphQLTypes["ContentBlock"],
	/** fetch data from the table: "DataDeletionRequest" */
	DataDeletionRequest: Array<GraphQLTypes["DataDeletionRequest"]>,
	/** fetch data from the table: "DataDeletionRequest" using primary key columns */
	DataDeletionRequest_by_pk?: GraphQLTypes["DataDeletionRequest"],
	/** fetch data from the table: "Device" */
	Device: Array<GraphQLTypes["Device"]>,
	/** fetch aggregated fields from the table: "Device" */
	Device_aggregate: GraphQLTypes["Device_aggregate"],
	/** fetch data from the table: "Device" using primary key columns */
	Device_by_pk?: GraphQLTypes["Device"],
	/** fetch data from the table: "ExternalIdentifier" */
	ExternalIdentifier: Array<GraphQLTypes["ExternalIdentifier"]>,
	/** fetch data from the table: "ExternalIdentifier" using primary key columns */
	ExternalIdentifier_by_pk?: GraphQLTypes["ExternalIdentifier"],
	/** fetch data from the table: "GeographicRegion" */
	GeographicRegion: Array<GraphQLTypes["GeographicRegion"]>,
	/** fetch data from the table: "GeographicRegionHandyman" */
	GeographicRegionHandyman: Array<GraphQLTypes["GeographicRegionHandyman"]>,
	/** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
	GeographicRegionHandyman_by_pk?: GraphQLTypes["GeographicRegionHandyman"],
	/** fetch data from the table: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood: Array<GraphQLTypes["GeographicRegionNeighborhood"]>,
	/** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
	GeographicRegionNeighborhood_by_pk?: GraphQLTypes["GeographicRegionNeighborhood"],
	/** fetch data from the table: "GeographicRegion" using primary key columns */
	GeographicRegion_by_pk?: GraphQLTypes["GeographicRegion"],
	/** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
	GeographicRegion_by_zipCode: Array<GraphQLTypes["GeographicRegion"]>,
	/** fetch data from the table: "Home" */
	Home: Array<GraphQLTypes["Home"]>,
	/** fetch data from the table: "HomeInventoryItem" */
	HomeInventoryItem: Array<GraphQLTypes["HomeInventoryItem"]>,
	/** fetch data from the table: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** fetch data from the table: "HomeInventoryItemAttachment" using primary key columns */
	HomeInventoryItemAttachment_by_pk?: GraphQLTypes["HomeInventoryItemAttachment"],
	/** fetch data from the table: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory: Array<GraphQLTypes["HomeInventoryItemCategory"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon: Array<GraphQLTypes["HomeInventoryItemCategoryIcon"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" using primary key columns */
	HomeInventoryItemCategoryIcon_by_pk?: GraphQLTypes["HomeInventoryItemCategoryIcon"],
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" using primary key columns */
	HomeInventoryItemCategoryPrompt_by_pk?: GraphQLTypes["HomeInventoryItemCategoryPrompt"],
	/** fetch data from the table: "HomeInventoryItemCategory" using primary key columns */
	HomeInventoryItemCategory_by_pk?: GraphQLTypes["HomeInventoryItemCategory"],
	/** fetch data from the table: "HomeInventoryItem" using primary key columns */
	HomeInventoryItem_by_pk?: GraphQLTypes["HomeInventoryItem"],
	/** fetch data from the table: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule: Array<GraphQLTypes["HomeInventoryMaintenanceSchedule"]>,
	/** fetch data from the table: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider: Array<GraphQLTypes["HomeInventoryServiceProvider"]>,
	/** fetch data from the table: "HomeInventoryServiceProvider" using primary key columns */
	HomeInventoryServiceProvider_by_pk?: GraphQLTypes["HomeInventoryServiceProvider"],
	/** fetch data from the table: "HomeInventoryServiceType" */
	HomeInventoryServiceType: Array<GraphQLTypes["HomeInventoryServiceType"]>,
	/** fetch data from the table: "HomeInventoryServiceType" using primary key columns */
	HomeInventoryServiceType_by_pk?: GraphQLTypes["HomeInventoryServiceType"],
	/** fetch data from the table: "HomeWaitlist" */
	HomeWaitlist: Array<GraphQLTypes["HomeWaitlist"]>,
	/** fetch data from the table: "HomeWaitlist" using primary key columns */
	HomeWaitlist_by_pk?: GraphQLTypes["HomeWaitlist"],
	/** fetch data from the table: "Home" using primary key columns */
	Home_by_pk?: GraphQLTypes["Home"],
	/** fetch data from the table: "MarketingLead" */
	MarketingLead: Array<GraphQLTypes["MarketingLead"]>,
	/** fetch data from the table: "MarketingLead" using primary key columns */
	MarketingLead_by_pk?: GraphQLTypes["MarketingLead"],
	/** fetch data from the table: "Neighborhood" */
	Neighborhood: Array<GraphQLTypes["Neighborhood"]>,
	/** fetch data from the table: "NeighborhoodRegion" */
	NeighborhoodRegion: Array<GraphQLTypes["NeighborhoodRegion"]>,
	/** fetch data from the table: "NeighborhoodRegion" using primary key columns */
	NeighborhoodRegion_by_pk?: GraphQLTypes["NeighborhoodRegion"],
	/** fetch data from the table: "Neighborhood" using primary key columns */
	Neighborhood_by_pk?: GraphQLTypes["Neighborhood"],
	/** fetch data from the table: "Person" */
	Person: Array<GraphQLTypes["Person"]>,
	/** fetch data from the table: "PersonBiographyItem" */
	PersonBiographyItem: Array<GraphQLTypes["PersonBiographyItem"]>,
	/** fetch data from the table: "PersonBiographyItem" using primary key columns */
	PersonBiographyItem_by_pk?: GraphQLTypes["PersonBiographyItem"],
	/** fetch data from the table: "PersonHome" */
	PersonHome: Array<GraphQLTypes["PersonHome"]>,
	/** fetch aggregated fields from the table: "Person" */
	Person_aggregate: GraphQLTypes["Person_aggregate"],
	/** fetch data from the table: "Person" using primary key columns */
	Person_by_pk?: GraphQLTypes["Person"],
	/** fetch data from the table: "Referral" */
	Referral: Array<GraphQLTypes["Referral"]>,
	/** fetch data from the table: "ReferralProgram" */
	ReferralProgram: Array<GraphQLTypes["ReferralProgram"]>,
	/** fetch data from the table: "ReferralProgram" using primary key columns */
	ReferralProgram_by_pk?: GraphQLTypes["ReferralProgram"],
	/** fetch data from the table: "Referral" using primary key columns */
	Referral_by_pk?: GraphQLTypes["Referral"],
	/** fetch data from the table: "Region" */
	Region: Array<GraphQLTypes["Region"]>,
	/** fetch data from the table: "Region" using primary key columns */
	Region_by_pk?: GraphQLTypes["Region"],
	/** fetch data from the table: "ServiceOffer" */
	ServiceOffer: Array<GraphQLTypes["ServiceOffer"]>,
	/** fetch data from the table: "ServiceOfferResponse" */
	ServiceOfferResponse: Array<GraphQLTypes["ServiceOfferResponse"]>,
	/** fetch data from the table: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus: Array<GraphQLTypes["ServiceOfferResponseStatus"]>,
	/** fetch data from the table: "ServiceOfferResponseStatus" using primary key columns */
	ServiceOfferResponseStatus_by_pk?: GraphQLTypes["ServiceOfferResponseStatus"],
	/** fetch data from the table: "ServiceOfferResponse" using primary key columns */
	ServiceOfferResponse_by_pk?: GraphQLTypes["ServiceOfferResponse"],
	/** fetch data from the table: "ServiceOfferType" */
	ServiceOfferType: Array<GraphQLTypes["ServiceOfferType"]>,
	/** fetch data from the table: "ServiceOfferType" using primary key columns */
	ServiceOfferType_by_pk?: GraphQLTypes["ServiceOfferType"],
	/** fetch data from the table: "ServiceOffer" using primary key columns */
	ServiceOffer_by_pk?: GraphQLTypes["ServiceOffer"],
	/** fetch data from the table: "ServiceProvider" */
	ServiceProvider: Array<GraphQLTypes["ServiceProvider"]>,
	/** fetch data from the table: "ServiceProviderRequest" */
	ServiceProviderRequest: Array<GraphQLTypes["ServiceProviderRequest"]>,
	/** fetch data from the table: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage: Array<GraphQLTypes["ServiceProviderRequestStage"]>,
	/** fetch data from the table: "ServiceProviderRequestStage" using primary key columns */
	ServiceProviderRequestStage_by_pk?: GraphQLTypes["ServiceProviderRequestStage"],
	/** fetch data from the table: "ServiceProviderRequest" using primary key columns */
	ServiceProviderRequest_by_pk?: GraphQLTypes["ServiceProviderRequest"],
	/** fetch data from the table: "ServiceProvider" using primary key columns */
	ServiceProvider_by_pk?: GraphQLTypes["ServiceProvider"],
	/** fetch data from the table: "StripePaymentMethod" */
	StripePaymentMethod: Array<GraphQLTypes["StripePaymentMethod"]>,
	/** fetch data from the table: "StripePaymentMethod" using primary key columns */
	StripePaymentMethod_by_pk?: GraphQLTypes["StripePaymentMethod"],
	/** fetch data from the table: "StripeSubscription" */
	StripeSubscription: Array<GraphQLTypes["StripeSubscription"]>,
	/** fetch data from the table: "StripeSubscription" using primary key columns */
	StripeSubscription_by_pk?: GraphQLTypes["StripeSubscription"],
	/** fetch data from the table: "Task" */
	Task: Array<GraphQLTypes["Task"]>,
	/** fetch data from the table: "TaskAttachment" */
	TaskAttachment: Array<GraphQLTypes["TaskAttachment"]>,
	/** fetch data from the table: "TaskAttachment" using primary key columns */
	TaskAttachment_by_pk?: GraphQLTypes["TaskAttachment"],
	/** fetch data from the table: "TaskComment" */
	TaskComment: Array<GraphQLTypes["TaskComment"]>,
	/** fetch aggregated fields from the table: "TaskComment" */
	TaskComment_aggregate: GraphQLTypes["TaskComment_aggregate"],
	/** fetch data from the table: "TaskPart" */
	TaskPart: Array<GraphQLTypes["TaskPart"]>,
	/** fetch data from the table: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible: Array<GraphQLTypes["TaskPartPartyResponsible"]>,
	/** fetch data from the table: "TaskPartPartyResponsible" using primary key columns */
	TaskPartPartyResponsible_by_pk?: GraphQLTypes["TaskPartPartyResponsible"],
	/** fetch data from the table: "TaskPartStatus" */
	TaskPartStatus: Array<GraphQLTypes["TaskPartStatus"]>,
	/** fetch data from the table: "TaskPartStatus" using primary key columns */
	TaskPartStatus_by_pk?: GraphQLTypes["TaskPartStatus"],
	/** fetch data from the table: "TaskPart" using primary key columns */
	TaskPart_by_pk?: GraphQLTypes["TaskPart"],
	/** fetch data from the table: "TaskUpdateLog" */
	TaskUpdateLog: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** fetch data from the table: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment: Array<GraphQLTypes["TaskUpdateLogAttachment"]>,
	/** fetch data from the table: "TaskUpdateLogAttachment" using primary key columns */
	TaskUpdateLogAttachment_by_pk?: GraphQLTypes["TaskUpdateLogAttachment"],
	/** fetch aggregated fields from the table: "TaskUpdateLog" */
	TaskUpdateLog_aggregate: GraphQLTypes["TaskUpdateLog_aggregate"],
	/** fetch data from the table: "TaskUpdateLog" using primary key columns */
	TaskUpdateLog_by_pk?: GraphQLTypes["TaskUpdateLog"],
	/** fetch aggregated fields from the table: "Task" */
	Task_aggregate: GraphQLTypes["Task_aggregate"],
	/** fetch data from the table: "Task" using primary key columns */
	Task_by_pk?: GraphQLTypes["Task"],
	/** fetch data from the table: "TimeZone" */
	TimeZone: Array<GraphQLTypes["TimeZone"]>,
	check_Stripe_PromoCode: GraphQLTypes["Stripe_FindPromoCodeResult"],
	/** decryptMessageHomeowner */
	decryptMessageHomeowner?: GraphQLTypes["DecryptedMessage"],
	/** execute function "find_Person_by_phone" which returns "find_Person_response" */
	find_Person_by_phone?: GraphQLTypes["find_Person_response"],
	/** fetch data from the table: "find_Person_response" */
	find_Person_response: Array<GraphQLTypes["find_Person_response"]>,
	/** find_Stripe_SubscriptionProducts */
	find_Stripe_SubscriptionProducts: Array<GraphQLTypes["Stripe_SubscriptionProduct"]>
};
	["smallint"]:any;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: {
		_eq?: GraphQLTypes["smallint"],
	_gt?: GraphQLTypes["smallint"],
	_gte?: GraphQLTypes["smallint"],
	_in?: Array<GraphQLTypes["smallint"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["smallint"],
	_lte?: GraphQLTypes["smallint"],
	_neq?: GraphQLTypes["smallint"],
	_nin?: Array<GraphQLTypes["smallint"]>
};
	["st_d_within_geography_input"]: {
		distance: number,
	from: GraphQLTypes["geography"],
	use_spheroid?: boolean
};
	["st_d_within_input"]: {
		distance: number,
	from: GraphQLTypes["geometry"]
};
	["subscription_root"]: {
	__typename: "subscription_root",
	/** fetch data from the table: "Appointment" */
	Appointment: Array<GraphQLTypes["Appointment"]>,
	/** fetch data from the table: "AppointmentGrade" */
	AppointmentGrade: Array<GraphQLTypes["AppointmentGrade"]>,
	/** fetch data from the table: "AppointmentGradeSelection" */
	AppointmentGradeSelection: Array<GraphQLTypes["AppointmentGradeSelection"]>,
	/** fetch data from the table: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory: Array<GraphQLTypes["AppointmentGradeSelectionCategory"]>,
	/** fetch data from the table: "AppointmentGradeSelectionCategory" using primary key columns */
	AppointmentGradeSelectionCategory_by_pk?: GraphQLTypes["AppointmentGradeSelectionCategory"],
	/** fetch data from the table in a streaming manner: "AppointmentGradeSelectionCategory" */
	AppointmentGradeSelectionCategory_stream: Array<GraphQLTypes["AppointmentGradeSelectionCategory"]>,
	/** fetch data from the table: "AppointmentGradeSelection" using primary key columns */
	AppointmentGradeSelection_by_pk?: GraphQLTypes["AppointmentGradeSelection"],
	/** fetch data from the table in a streaming manner: "AppointmentGradeSelection" */
	AppointmentGradeSelection_stream: Array<GraphQLTypes["AppointmentGradeSelection"]>,
	/** fetch data from the table: "AppointmentGrade" using primary key columns */
	AppointmentGrade_by_pk?: GraphQLTypes["AppointmentGrade"],
	/** fetch data from the table in a streaming manner: "AppointmentGrade" */
	AppointmentGrade_stream: Array<GraphQLTypes["AppointmentGrade"]>,
	/** fetch data from the table: "AppointmentProvider" */
	AppointmentProvider: Array<GraphQLTypes["AppointmentProvider"]>,
	/** fetch aggregated fields from the table: "AppointmentProvider" */
	AppointmentProvider_aggregate: GraphQLTypes["AppointmentProvider_aggregate"],
	/** fetch data from the table: "AppointmentProvider" using primary key columns */
	AppointmentProvider_by_pk?: GraphQLTypes["AppointmentProvider"],
	/** fetch data from the table in a streaming manner: "AppointmentProvider" */
	AppointmentProvider_stream: Array<GraphQLTypes["AppointmentProvider"]>,
	/** fetch data from the table: "AppointmentReport" */
	AppointmentReport: Array<GraphQLTypes["AppointmentReport"]>,
	/** fetch data from the table: "AppointmentReport" using primary key columns */
	AppointmentReport_by_pk?: GraphQLTypes["AppointmentReport"],
	/** fetch data from the table in a streaming manner: "AppointmentReport" */
	AppointmentReport_stream: Array<GraphQLTypes["AppointmentReport"]>,
	/** fetch aggregated fields from the table: "Appointment" */
	Appointment_aggregate: GraphQLTypes["Appointment_aggregate"],
	/** fetch data from the table: "Appointment" using primary key columns */
	Appointment_by_pk?: GraphQLTypes["Appointment"],
	/** fetch data from the table in a streaming manner: "Appointment" */
	Appointment_stream: Array<GraphQLTypes["Appointment"]>,
	/** fetch data from the table: "Attachment" */
	Attachment: Array<GraphQLTypes["Attachment"]>,
	/** fetch data from the table: "Attachment" using primary key columns */
	Attachment_by_pk?: GraphQLTypes["Attachment"],
	/** fetch data from the table in a streaming manner: "Attachment" */
	Attachment_stream: Array<GraphQLTypes["Attachment"]>,
	/** An array relationship */
	Availability: Array<GraphQLTypes["Availability"]>,
	/** fetch data from the table: "AvailabilityResult" */
	AvailabilityResult: Array<GraphQLTypes["AvailabilityResult"]>,
	/** fetch data from the table in a streaming manner: "AvailabilityResult" */
	AvailabilityResult_stream: Array<GraphQLTypes["AvailabilityResult"]>,
	/** fetch data from the table in a streaming manner: "Availability" */
	Availability_stream: Array<GraphQLTypes["Availability"]>,
	/** fetch data from the table: "AvailableAppointment" */
	AvailableAppointment: Array<GraphQLTypes["AvailableAppointment"]>,
	/** fetch data from the table in a streaming manner: "AvailableAppointment" */
	AvailableAppointment_stream: Array<GraphQLTypes["AvailableAppointment"]>,
	/** fetch data from the table: "ClientRelease" */
	ClientRelease: Array<GraphQLTypes["ClientRelease"]>,
	/** fetch data from the table: "ClientRelease" using primary key columns */
	ClientRelease_by_pk?: GraphQLTypes["ClientRelease"],
	/** fetch data from the table in a streaming manner: "ClientRelease" */
	ClientRelease_stream: Array<GraphQLTypes["ClientRelease"]>,
	/** fetch data from the table: "ClientType" */
	ClientType: Array<GraphQLTypes["ClientType"]>,
	/** fetch data from the table: "ClientType" using primary key columns */
	ClientType_by_pk?: GraphQLTypes["ClientType"],
	/** fetch data from the table in a streaming manner: "ClientType" */
	ClientType_stream: Array<GraphQLTypes["ClientType"]>,
	/** fetch data from the table: "ContentBlock" */
	ContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	/** fetch data from the table: "ContentBlock" using primary key columns */
	ContentBlock_by_pk?: GraphQLTypes["ContentBlock"],
	/** fetch data from the table in a streaming manner: "ContentBlock" */
	ContentBlock_stream: Array<GraphQLTypes["ContentBlock"]>,
	/** fetch data from the table: "DataDeletionRequest" */
	DataDeletionRequest: Array<GraphQLTypes["DataDeletionRequest"]>,
	/** fetch data from the table: "DataDeletionRequest" using primary key columns */
	DataDeletionRequest_by_pk?: GraphQLTypes["DataDeletionRequest"],
	/** fetch data from the table in a streaming manner: "DataDeletionRequest" */
	DataDeletionRequest_stream: Array<GraphQLTypes["DataDeletionRequest"]>,
	/** fetch data from the table: "Device" */
	Device: Array<GraphQLTypes["Device"]>,
	/** fetch aggregated fields from the table: "Device" */
	Device_aggregate: GraphQLTypes["Device_aggregate"],
	/** fetch data from the table: "Device" using primary key columns */
	Device_by_pk?: GraphQLTypes["Device"],
	/** fetch data from the table in a streaming manner: "Device" */
	Device_stream: Array<GraphQLTypes["Device"]>,
	/** fetch data from the table: "ExternalIdentifier" */
	ExternalIdentifier: Array<GraphQLTypes["ExternalIdentifier"]>,
	/** fetch data from the table: "ExternalIdentifier" using primary key columns */
	ExternalIdentifier_by_pk?: GraphQLTypes["ExternalIdentifier"],
	/** fetch data from the table in a streaming manner: "ExternalIdentifier" */
	ExternalIdentifier_stream: Array<GraphQLTypes["ExternalIdentifier"]>,
	/** fetch data from the table: "GeographicRegion" */
	GeographicRegion: Array<GraphQLTypes["GeographicRegion"]>,
	/** fetch data from the table: "GeographicRegionHandyman" */
	GeographicRegionHandyman: Array<GraphQLTypes["GeographicRegionHandyman"]>,
	/** fetch data from the table: "GeographicRegionHandyman" using primary key columns */
	GeographicRegionHandyman_by_pk?: GraphQLTypes["GeographicRegionHandyman"],
	/** fetch data from the table in a streaming manner: "GeographicRegionHandyman" */
	GeographicRegionHandyman_stream: Array<GraphQLTypes["GeographicRegionHandyman"]>,
	/** fetch data from the table: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood: Array<GraphQLTypes["GeographicRegionNeighborhood"]>,
	/** fetch data from the table: "GeographicRegionNeighborhood" using primary key columns */
	GeographicRegionNeighborhood_by_pk?: GraphQLTypes["GeographicRegionNeighborhood"],
	/** fetch data from the table in a streaming manner: "GeographicRegionNeighborhood" */
	GeographicRegionNeighborhood_stream: Array<GraphQLTypes["GeographicRegionNeighborhood"]>,
	/** fetch data from the table: "GeographicRegion" using primary key columns */
	GeographicRegion_by_pk?: GraphQLTypes["GeographicRegion"],
	/** execute function "GeographicRegion_by_zipCode" which returns "GeographicRegion" */
	GeographicRegion_by_zipCode: Array<GraphQLTypes["GeographicRegion"]>,
	/** fetch data from the table in a streaming manner: "GeographicRegion" */
	GeographicRegion_stream: Array<GraphQLTypes["GeographicRegion"]>,
	/** fetch data from the table: "Home" */
	Home: Array<GraphQLTypes["Home"]>,
	/** fetch data from the table: "HomeInventoryItem" */
	HomeInventoryItem: Array<GraphQLTypes["HomeInventoryItem"]>,
	/** fetch data from the table: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** fetch data from the table: "HomeInventoryItemAttachment" using primary key columns */
	HomeInventoryItemAttachment_by_pk?: GraphQLTypes["HomeInventoryItemAttachment"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemAttachment" */
	HomeInventoryItemAttachment_stream: Array<GraphQLTypes["HomeInventoryItemAttachment"]>,
	/** fetch data from the table: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory: Array<GraphQLTypes["HomeInventoryItemCategory"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon: Array<GraphQLTypes["HomeInventoryItemCategoryIcon"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryIcon" using primary key columns */
	HomeInventoryItemCategoryIcon_by_pk?: GraphQLTypes["HomeInventoryItemCategoryIcon"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategoryIcon" */
	HomeInventoryItemCategoryIcon_stream: Array<GraphQLTypes["HomeInventoryItemCategoryIcon"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt"]>,
	/** fetch data from the table: "HomeInventoryItemCategoryPrompt" using primary key columns */
	HomeInventoryItemCategoryPrompt_by_pk?: GraphQLTypes["HomeInventoryItemCategoryPrompt"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategoryPrompt" */
	HomeInventoryItemCategoryPrompt_stream: Array<GraphQLTypes["HomeInventoryItemCategoryPrompt"]>,
	/** fetch data from the table: "HomeInventoryItemCategory" using primary key columns */
	HomeInventoryItemCategory_by_pk?: GraphQLTypes["HomeInventoryItemCategory"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItemCategory" */
	HomeInventoryItemCategory_stream: Array<GraphQLTypes["HomeInventoryItemCategory"]>,
	/** fetch data from the table: "HomeInventoryItem" using primary key columns */
	HomeInventoryItem_by_pk?: GraphQLTypes["HomeInventoryItem"],
	/** fetch data from the table in a streaming manner: "HomeInventoryItem" */
	HomeInventoryItem_stream: Array<GraphQLTypes["HomeInventoryItem"]>,
	/** fetch data from the table: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule: Array<GraphQLTypes["HomeInventoryMaintenanceSchedule"]>,
	/** fetch data from the table in a streaming manner: "HomeInventoryMaintenanceSchedule" */
	HomeInventoryMaintenanceSchedule_stream: Array<GraphQLTypes["HomeInventoryMaintenanceSchedule"]>,
	/** fetch data from the table: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider: Array<GraphQLTypes["HomeInventoryServiceProvider"]>,
	/** fetch data from the table: "HomeInventoryServiceProvider" using primary key columns */
	HomeInventoryServiceProvider_by_pk?: GraphQLTypes["HomeInventoryServiceProvider"],
	/** fetch data from the table in a streaming manner: "HomeInventoryServiceProvider" */
	HomeInventoryServiceProvider_stream: Array<GraphQLTypes["HomeInventoryServiceProvider"]>,
	/** fetch data from the table: "HomeInventoryServiceType" */
	HomeInventoryServiceType: Array<GraphQLTypes["HomeInventoryServiceType"]>,
	/** fetch data from the table: "HomeInventoryServiceType" using primary key columns */
	HomeInventoryServiceType_by_pk?: GraphQLTypes["HomeInventoryServiceType"],
	/** fetch data from the table in a streaming manner: "HomeInventoryServiceType" */
	HomeInventoryServiceType_stream: Array<GraphQLTypes["HomeInventoryServiceType"]>,
	/** fetch data from the table: "HomeWaitlist" */
	HomeWaitlist: Array<GraphQLTypes["HomeWaitlist"]>,
	/** fetch data from the table: "HomeWaitlist" using primary key columns */
	HomeWaitlist_by_pk?: GraphQLTypes["HomeWaitlist"],
	/** fetch data from the table in a streaming manner: "HomeWaitlist" */
	HomeWaitlist_stream: Array<GraphQLTypes["HomeWaitlist"]>,
	/** fetch data from the table: "Home" using primary key columns */
	Home_by_pk?: GraphQLTypes["Home"],
	/** fetch data from the table in a streaming manner: "Home" */
	Home_stream: Array<GraphQLTypes["Home"]>,
	/** fetch data from the table: "MarketingLead" */
	MarketingLead: Array<GraphQLTypes["MarketingLead"]>,
	/** fetch data from the table: "MarketingLead" using primary key columns */
	MarketingLead_by_pk?: GraphQLTypes["MarketingLead"],
	/** fetch data from the table in a streaming manner: "MarketingLead" */
	MarketingLead_stream: Array<GraphQLTypes["MarketingLead"]>,
	/** fetch data from the table: "Neighborhood" */
	Neighborhood: Array<GraphQLTypes["Neighborhood"]>,
	/** fetch data from the table: "NeighborhoodRegion" */
	NeighborhoodRegion: Array<GraphQLTypes["NeighborhoodRegion"]>,
	/** fetch data from the table: "NeighborhoodRegion" using primary key columns */
	NeighborhoodRegion_by_pk?: GraphQLTypes["NeighborhoodRegion"],
	/** fetch data from the table in a streaming manner: "NeighborhoodRegion" */
	NeighborhoodRegion_stream: Array<GraphQLTypes["NeighborhoodRegion"]>,
	/** fetch data from the table: "Neighborhood" using primary key columns */
	Neighborhood_by_pk?: GraphQLTypes["Neighborhood"],
	/** fetch data from the table in a streaming manner: "Neighborhood" */
	Neighborhood_stream: Array<GraphQLTypes["Neighborhood"]>,
	/** fetch data from the table: "Person" */
	Person: Array<GraphQLTypes["Person"]>,
	/** fetch data from the table: "PersonBiographyItem" */
	PersonBiographyItem: Array<GraphQLTypes["PersonBiographyItem"]>,
	/** fetch data from the table: "PersonBiographyItem" using primary key columns */
	PersonBiographyItem_by_pk?: GraphQLTypes["PersonBiographyItem"],
	/** fetch data from the table in a streaming manner: "PersonBiographyItem" */
	PersonBiographyItem_stream: Array<GraphQLTypes["PersonBiographyItem"]>,
	/** fetch data from the table: "PersonHome" */
	PersonHome: Array<GraphQLTypes["PersonHome"]>,
	/** fetch data from the table in a streaming manner: "PersonHome" */
	PersonHome_stream: Array<GraphQLTypes["PersonHome"]>,
	/** fetch aggregated fields from the table: "Person" */
	Person_aggregate: GraphQLTypes["Person_aggregate"],
	/** fetch data from the table: "Person" using primary key columns */
	Person_by_pk?: GraphQLTypes["Person"],
	/** fetch data from the table in a streaming manner: "Person" */
	Person_stream: Array<GraphQLTypes["Person"]>,
	/** fetch data from the table: "Referral" */
	Referral: Array<GraphQLTypes["Referral"]>,
	/** fetch data from the table: "ReferralProgram" */
	ReferralProgram: Array<GraphQLTypes["ReferralProgram"]>,
	/** fetch data from the table: "ReferralProgram" using primary key columns */
	ReferralProgram_by_pk?: GraphQLTypes["ReferralProgram"],
	/** fetch data from the table in a streaming manner: "ReferralProgram" */
	ReferralProgram_stream: Array<GraphQLTypes["ReferralProgram"]>,
	/** fetch data from the table: "Referral" using primary key columns */
	Referral_by_pk?: GraphQLTypes["Referral"],
	/** fetch data from the table in a streaming manner: "Referral" */
	Referral_stream: Array<GraphQLTypes["Referral"]>,
	/** fetch data from the table: "Region" */
	Region: Array<GraphQLTypes["Region"]>,
	/** fetch data from the table: "Region" using primary key columns */
	Region_by_pk?: GraphQLTypes["Region"],
	/** fetch data from the table in a streaming manner: "Region" */
	Region_stream: Array<GraphQLTypes["Region"]>,
	/** fetch data from the table: "ServiceOffer" */
	ServiceOffer: Array<GraphQLTypes["ServiceOffer"]>,
	/** fetch data from the table: "ServiceOfferResponse" */
	ServiceOfferResponse: Array<GraphQLTypes["ServiceOfferResponse"]>,
	/** fetch data from the table: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus: Array<GraphQLTypes["ServiceOfferResponseStatus"]>,
	/** fetch data from the table: "ServiceOfferResponseStatus" using primary key columns */
	ServiceOfferResponseStatus_by_pk?: GraphQLTypes["ServiceOfferResponseStatus"],
	/** fetch data from the table in a streaming manner: "ServiceOfferResponseStatus" */
	ServiceOfferResponseStatus_stream: Array<GraphQLTypes["ServiceOfferResponseStatus"]>,
	/** fetch data from the table: "ServiceOfferResponse" using primary key columns */
	ServiceOfferResponse_by_pk?: GraphQLTypes["ServiceOfferResponse"],
	/** fetch data from the table in a streaming manner: "ServiceOfferResponse" */
	ServiceOfferResponse_stream: Array<GraphQLTypes["ServiceOfferResponse"]>,
	/** fetch data from the table: "ServiceOfferType" */
	ServiceOfferType: Array<GraphQLTypes["ServiceOfferType"]>,
	/** fetch data from the table: "ServiceOfferType" using primary key columns */
	ServiceOfferType_by_pk?: GraphQLTypes["ServiceOfferType"],
	/** fetch data from the table in a streaming manner: "ServiceOfferType" */
	ServiceOfferType_stream: Array<GraphQLTypes["ServiceOfferType"]>,
	/** fetch data from the table: "ServiceOffer" using primary key columns */
	ServiceOffer_by_pk?: GraphQLTypes["ServiceOffer"],
	/** fetch data from the table in a streaming manner: "ServiceOffer" */
	ServiceOffer_stream: Array<GraphQLTypes["ServiceOffer"]>,
	/** fetch data from the table: "ServiceProvider" */
	ServiceProvider: Array<GraphQLTypes["ServiceProvider"]>,
	/** fetch data from the table: "ServiceProviderRequest" */
	ServiceProviderRequest: Array<GraphQLTypes["ServiceProviderRequest"]>,
	/** fetch data from the table: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage: Array<GraphQLTypes["ServiceProviderRequestStage"]>,
	/** fetch data from the table: "ServiceProviderRequestStage" using primary key columns */
	ServiceProviderRequestStage_by_pk?: GraphQLTypes["ServiceProviderRequestStage"],
	/** fetch data from the table in a streaming manner: "ServiceProviderRequestStage" */
	ServiceProviderRequestStage_stream: Array<GraphQLTypes["ServiceProviderRequestStage"]>,
	/** fetch data from the table: "ServiceProviderRequest" using primary key columns */
	ServiceProviderRequest_by_pk?: GraphQLTypes["ServiceProviderRequest"],
	/** fetch data from the table in a streaming manner: "ServiceProviderRequest" */
	ServiceProviderRequest_stream: Array<GraphQLTypes["ServiceProviderRequest"]>,
	/** fetch data from the table: "ServiceProvider" using primary key columns */
	ServiceProvider_by_pk?: GraphQLTypes["ServiceProvider"],
	/** fetch data from the table in a streaming manner: "ServiceProvider" */
	ServiceProvider_stream: Array<GraphQLTypes["ServiceProvider"]>,
	/** fetch data from the table: "StripePaymentMethod" */
	StripePaymentMethod: Array<GraphQLTypes["StripePaymentMethod"]>,
	/** fetch data from the table: "StripePaymentMethod" using primary key columns */
	StripePaymentMethod_by_pk?: GraphQLTypes["StripePaymentMethod"],
	/** fetch data from the table in a streaming manner: "StripePaymentMethod" */
	StripePaymentMethod_stream: Array<GraphQLTypes["StripePaymentMethod"]>,
	/** fetch data from the table: "StripeSubscription" */
	StripeSubscription: Array<GraphQLTypes["StripeSubscription"]>,
	/** fetch data from the table: "StripeSubscription" using primary key columns */
	StripeSubscription_by_pk?: GraphQLTypes["StripeSubscription"],
	/** fetch data from the table in a streaming manner: "StripeSubscription" */
	StripeSubscription_stream: Array<GraphQLTypes["StripeSubscription"]>,
	/** fetch data from the table: "Task" */
	Task: Array<GraphQLTypes["Task"]>,
	/** fetch data from the table: "TaskAttachment" */
	TaskAttachment: Array<GraphQLTypes["TaskAttachment"]>,
	/** fetch data from the table: "TaskAttachment" using primary key columns */
	TaskAttachment_by_pk?: GraphQLTypes["TaskAttachment"],
	/** fetch data from the table in a streaming manner: "TaskAttachment" */
	TaskAttachment_stream: Array<GraphQLTypes["TaskAttachment"]>,
	/** fetch data from the table: "TaskComment" */
	TaskComment: Array<GraphQLTypes["TaskComment"]>,
	/** fetch aggregated fields from the table: "TaskComment" */
	TaskComment_aggregate: GraphQLTypes["TaskComment_aggregate"],
	/** fetch data from the table in a streaming manner: "TaskComment" */
	TaskComment_stream: Array<GraphQLTypes["TaskComment"]>,
	/** fetch data from the table: "TaskPart" */
	TaskPart: Array<GraphQLTypes["TaskPart"]>,
	/** fetch data from the table: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible: Array<GraphQLTypes["TaskPartPartyResponsible"]>,
	/** fetch data from the table: "TaskPartPartyResponsible" using primary key columns */
	TaskPartPartyResponsible_by_pk?: GraphQLTypes["TaskPartPartyResponsible"],
	/** fetch data from the table in a streaming manner: "TaskPartPartyResponsible" */
	TaskPartPartyResponsible_stream: Array<GraphQLTypes["TaskPartPartyResponsible"]>,
	/** fetch data from the table: "TaskPartStatus" */
	TaskPartStatus: Array<GraphQLTypes["TaskPartStatus"]>,
	/** fetch data from the table: "TaskPartStatus" using primary key columns */
	TaskPartStatus_by_pk?: GraphQLTypes["TaskPartStatus"],
	/** fetch data from the table in a streaming manner: "TaskPartStatus" */
	TaskPartStatus_stream: Array<GraphQLTypes["TaskPartStatus"]>,
	/** fetch data from the table: "TaskPart" using primary key columns */
	TaskPart_by_pk?: GraphQLTypes["TaskPart"],
	/** fetch data from the table in a streaming manner: "TaskPart" */
	TaskPart_stream: Array<GraphQLTypes["TaskPart"]>,
	/** fetch data from the table: "TaskUpdateLog" */
	TaskUpdateLog: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** fetch data from the table: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment: Array<GraphQLTypes["TaskUpdateLogAttachment"]>,
	/** fetch data from the table: "TaskUpdateLogAttachment" using primary key columns */
	TaskUpdateLogAttachment_by_pk?: GraphQLTypes["TaskUpdateLogAttachment"],
	/** fetch data from the table in a streaming manner: "TaskUpdateLogAttachment" */
	TaskUpdateLogAttachment_stream: Array<GraphQLTypes["TaskUpdateLogAttachment"]>,
	/** fetch aggregated fields from the table: "TaskUpdateLog" */
	TaskUpdateLog_aggregate: GraphQLTypes["TaskUpdateLog_aggregate"],
	/** fetch data from the table: "TaskUpdateLog" using primary key columns */
	TaskUpdateLog_by_pk?: GraphQLTypes["TaskUpdateLog"],
	/** fetch data from the table in a streaming manner: "TaskUpdateLog" */
	TaskUpdateLog_stream: Array<GraphQLTypes["TaskUpdateLog"]>,
	/** fetch aggregated fields from the table: "Task" */
	Task_aggregate: GraphQLTypes["Task_aggregate"],
	/** fetch data from the table: "Task" using primary key columns */
	Task_by_pk?: GraphQLTypes["Task"],
	/** fetch data from the table in a streaming manner: "Task" */
	Task_stream: Array<GraphQLTypes["Task"]>,
	/** fetch data from the table: "TimeZone" */
	TimeZone: Array<GraphQLTypes["TimeZone"]>,
	/** fetch data from the table in a streaming manner: "TimeZone" */
	TimeZone_stream: Array<GraphQLTypes["TimeZone"]>,
	/** execute function "find_Person_by_phone" which returns "find_Person_response" */
	find_Person_by_phone?: GraphQLTypes["find_Person_response"],
	/** fetch data from the table: "find_Person_response" */
	find_Person_response: Array<GraphQLTypes["find_Person_response"]>,
	/** fetch data from the table in a streaming manner: "find_Person_response" */
	find_Person_response_stream: Array<GraphQLTypes["find_Person_response"]>
};
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamptz"],
	_gt?: GraphQLTypes["timestamptz"],
	_gte?: GraphQLTypes["timestamptz"],
	_in?: Array<GraphQLTypes["timestamptz"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timestamptz"],
	_lte?: GraphQLTypes["timestamptz"],
	_neq?: GraphQLTypes["timestamptz"],
	_nin?: Array<GraphQLTypes["timestamptz"]>
};
	["timetz"]:any;
	/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
["timetz_comparison_exp"]: {
		_eq?: GraphQLTypes["timetz"],
	_gt?: GraphQLTypes["timetz"],
	_gte?: GraphQLTypes["timetz"],
	_in?: Array<GraphQLTypes["timetz"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timetz"],
	_lte?: GraphQLTypes["timetz"],
	_neq?: GraphQLTypes["timetz"],
	_nin?: Array<GraphQLTypes["timetz"]>
};
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
		_eq?: GraphQLTypes["uuid"],
	_gt?: GraphQLTypes["uuid"],
	_gte?: GraphQLTypes["uuid"],
	_in?: Array<GraphQLTypes["uuid"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["uuid"],
	_lte?: GraphQLTypes["uuid"],
	_neq?: GraphQLTypes["uuid"],
	_nin?: Array<GraphQLTypes["uuid"]>
}
    }
/** select columns of table "AppointmentGradeSelectionCategory" */
export const enum AppointmentGradeSelectionCategory_select_column {
	createdAt = "createdAt",
	description = "description",
	displayText = "displayText",
	friendlyAssetId = "friendlyAssetId",
	id = "id",
	imageAssetAttachmentId = "imageAssetAttachmentId",
	updatedAt = "updatedAt",
	vectorAssetAttachmentId = "vectorAssetAttachmentId"
}
/** unique or primary key constraints on table "AppointmentGradeSelection" */
export const enum AppointmentGradeSelection_constraint {
	AppointmentGradeSelection_appointmentGradeId_appointmentGradeSe = "AppointmentGradeSelection_appointmentGradeId_appointmentGradeSe",
	AppointmentGradeSelection_pkey = "AppointmentGradeSelection_pkey"
}
/** select columns of table "AppointmentGradeSelection" */
export const enum AppointmentGradeSelection_select_column {
	appointmentGradeId = "appointmentGradeId",
	appointmentGradeSelectionCategoryId = "appointmentGradeSelectionCategoryId",
	createdAt = "createdAt",
	id = "id",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "AppointmentGradeSelection" (current role has no relevant permissions) */
export const enum AppointmentGradeSelection_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** unique or primary key constraints on table "AppointmentGrade" */
export const enum AppointmentGrade_constraint {
	AppointmentGrade_personId_appointmentId_key = "AppointmentGrade_personId_appointmentId_key",
	AppointmentGrade_pkey = "AppointmentGrade_pkey"
}
/** select columns of table "AppointmentGrade" */
export const enum AppointmentGrade_select_column {
	appointmentId = "appointmentId",
	createdAt = "createdAt",
	homeId = "homeId",
	id = "id",
	negativeComments = "negativeComments",
	personId = "personId",
	positiveComments = "positiveComments",
	score = "score",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "AppointmentGrade" (current role has no relevant permissions) */
export const enum AppointmentGrade_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** unique or primary key constraints on table "AppointmentProvider" */
export const enum AppointmentProvider_constraint {
	AppointmentProvider_appointmentId_personId_key = "AppointmentProvider_appointmentId_personId_key",
	AppointmentProvider_pkey = "AppointmentProvider_pkey"
}
/** select columns of table "AppointmentProvider" */
export const enum AppointmentProvider_select_column {
	appointmentId = "appointmentId",
	createdAt = "createdAt",
	id = "id",
	personId = "personId",
	serviceProviderId = "serviceProviderId",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "AppointmentProvider" (current role has no relevant permissions) */
export const enum AppointmentProvider_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** select columns of table "AppointmentReport" */
export const enum AppointmentReport_select_column {
	appointmentId = "appointmentId",
	createdAt = "createdAt",
	createdById = "createdById",
	id = "id",
	reviewCompletedAt = "reviewCompletedAt",
	reviewedById = "reviewedById",
	updatedAt = "updatedAt"
}
/** unique or primary key constraints on table "Appointment" */
export const enum Appointment_constraint {
	Appointment_pkey = "Appointment_pkey"
}
/** select columns of table "Appointment" */
export const enum Appointment_select_column {
	beginsAt = "beginsAt",
	createdAt = "createdAt",
	createdById = "createdById",
	endsAt = "endsAt",
	homeId = "homeId",
	id = "id",
	isWindowTimes = "isWindowTimes",
	updatedAt = "updatedAt"
}
/** select "Appointment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Appointment" */
export const enum Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns {
	isWindowTimes = "isWindowTimes"
}
/** select "Appointment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Appointment" */
export const enum Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns {
	isWindowTimes = "isWindowTimes"
}
/** update columns of table "Appointment" */
export const enum Appointment_update_column {
	beginsAt = "beginsAt",
	endsAt = "endsAt"
}
/** unique or primary key constraints on table "Attachment" */
export const enum Attachment_constraint {
	Attachment_pkey = "Attachment_pkey",
	Attachment_publicUrl_key = "Attachment_publicUrl_key"
}
/** select columns of table "Attachment" */
export const enum Attachment_select_column {
	caption = "caption",
	contentType = "contentType",
	createdAt = "createdAt",
	createdById = "createdById",
	id = "id",
	publicUrl = "publicUrl",
	updatedAt = "updatedAt"
}
/** update columns of table "Attachment" */
export const enum Attachment_update_column {
	caption = "caption",
	id = "id"
}
export const enum AvailabilityResultSource_enum {
	newAppointment = "newAppointment",
	newCustomer = "newCustomer",
	reschedule = "reschedule"
}
/** select columns of table "AvailabilityResult" */
export const enum AvailabilityResult_select_column {
	appointmentId = "appointmentId",
	createdAt = "createdAt",
	creationSource = "creationSource",
	data = "data",
	homeId = "homeId",
	id = "id",
	source = "source",
	updatedAt = "updatedAt"
}
/** select columns of table "Availability" */
export const enum Availability_select_column {
	beginsAt = "beginsAt",
	endsAt = "endsAt",
	personId = "personId",
	serviceArea = "serviceArea"
}
/** select columns of table "AvailableAppointment" */
export const enum AvailableAppointment_select_column {
	beginsAt = "beginsAt",
	endsAt = "endsAt"
}
/** select columns of table "ClientRelease" */
export const enum ClientRelease_select_column {
	build = "build",
	client = "client",
	createdAt = "createdAt",
	expirationDate = "expirationDate",
	expirationMessage = "expirationMessage",
	id = "id",
	releaseDate = "releaseDate",
	updatedAt = "updatedAt",
	version = "version"
}
export const enum ClientType_enum {
	androidHomeowner = "androidHomeowner",
	iosHandyperson = "iosHandyperson",
	iosHomeowner = "iosHomeowner",
	onboardingWeb = "onboardingWeb"
}
/** select columns of table "ClientType" */
export const enum ClientType_select_column {
	description = "description",
	value = "value"
}
/** select columns of table "ContentBlock" */
export const enum ContentBlock_select_column {
	body = "body",
	key = "key",
	updatedAt = "updatedAt"
}
export const enum CreationSourceType_enum {
	admin = "admin",
	android = "android",
	ios = "ios",
	web = "web"
}
/** unique or primary key constraints on table "DataDeletionRequest" */
export const enum DataDeletionRequest_constraint {
	DataDeletionRequest_personId_key = "DataDeletionRequest_personId_key",
	DataDeletionRequest_pkey = "DataDeletionRequest_pkey"
}
/** select columns of table "DataDeletionRequest" */
export const enum DataDeletionRequest_select_column {
	createdAt = "createdAt",
	id = "id",
	personId = "personId",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "DataDeletionRequest" (current role has no relevant permissions) */
export const enum DataDeletionRequest_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
export const enum DeviceType_enum {
	android = "android",
	apple = "apple"
}
/** unique or primary key constraints on table "Device" */
export const enum Device_constraint {
	Device_personId_uniqueIdentifier_key = "Device_personId_uniqueIdentifier_key",
	Device_pkey = "Device_pkey"
}
/** select columns of table "Device" */
export const enum Device_select_column {
	appBuildNumber = "appBuildNumber",
	brand = "brand",
	createdAt = "createdAt",
	id = "id",
	model = "model",
	osVersion = "osVersion",
	personId = "personId",
	type = "type",
	uniqueIdentifier = "uniqueIdentifier",
	updatedAt = "updatedAt"
}
/** update columns of table "Device" */
export const enum Device_update_column {
	appBuildNumber = "appBuildNumber",
	brand = "brand",
	createdAt = "createdAt",
	model = "model",
	osVersion = "osVersion",
	type = "type",
	uniqueIdentifier = "uniqueIdentifier"
}
/** select columns of table "ExternalIdentifier" */
export const enum ExternalIdentifier_select_column {
	androidIntercomDigest = "androidIntercomDigest",
	createdAt = "createdAt",
	id = "id",
	iosIntercomDigest = "iosIntercomDigest",
	personId = "personId",
	sendbirdToken = "sendbirdToken",
	updatedAt = "updatedAt"
}
/** select columns of table "GeographicRegionHandyman" */
export const enum GeographicRegionHandyman_select_column {
	createdAt = "createdAt",
	geographicRegionId = "geographicRegionId",
	id = "id",
	isPrimary = "isPrimary",
	personId = "personId",
	updatedAt = "updatedAt"
}
/** select columns of table "GeographicRegionNeighborhood" */
export const enum GeographicRegionNeighborhood_select_column {
	createdAt = "createdAt",
	geographicRegionId = "geographicRegionId",
	id = "id",
	name = "name",
	updatedAt = "updatedAt",
	zipCode = "zipCode"
}
/** select columns of table "GeographicRegion" */
export const enum GeographicRegion_select_column {
	availableStartingAt = "availableStartingAt",
	createdAt = "createdAt",
	id = "id",
	name = "name",
	servicedZipCodes = "servicedZipCodes",
	updatedAt = "updatedAt"
}
/** unique or primary key constraints on table "HomeInventoryItemAttachment" */
export const enum HomeInventoryItemAttachment_constraint {
	HomeInventoryItemAttachment_pkey = "HomeInventoryItemAttachment_pkey"
}
/** select columns of table "HomeInventoryItemAttachment" */
export const enum HomeInventoryItemAttachment_select_column {
	attachmentId = "attachmentId",
	homeInventoryCategoryPromptId = "homeInventoryCategoryPromptId",
	homeInventoryItemId = "homeInventoryItemId",
	id = "id",
	rank = "rank"
}
/** update columns of table "HomeInventoryItemAttachment" */
export const enum HomeInventoryItemAttachment_update_column {
	attachmentId = "attachmentId",
	homeInventoryCategoryPromptId = "homeInventoryCategoryPromptId",
	homeInventoryItemId = "homeInventoryItemId",
	rank = "rank"
}
/** select columns of table "HomeInventoryItemCategoryIcon" */
export const enum HomeInventoryItemCategoryIcon_select_column {
	createdAt = "createdAt",
	friendlyAssetId = "friendlyAssetId",
	homeInventoryItemCategoryId = "homeInventoryItemCategoryId",
	id = "id",
	imageAssetAttachmentId = "imageAssetAttachmentId",
	updatedAt = "updatedAt",
	vectorAssetAttachmentId = "vectorAssetAttachmentId"
}
/** select columns of table "HomeInventoryItemCategoryPrompt" */
export const enum HomeInventoryItemCategoryPrompt_select_column {
	body = "body",
	createdAt = "createdAt",
	defaultCaption = "defaultCaption",
	exampleAttachmentId = "exampleAttachmentId",
	friendlyAssetId = "friendlyAssetId",
	homeInventoryItemCategoryId = "homeInventoryItemCategoryId",
	id = "id",
	inputHint = "inputHint",
	rank = "rank",
	title = "title",
	updatedAt = "updatedAt"
}
/** select columns of table "HomeInventoryItemCategory" */
export const enum HomeInventoryItemCategory_select_column {
	description = "description",
	id = "id",
	serviceDescription = "serviceDescription",
	serviceFrequency = "serviceFrequency",
	servicePartName = "servicePartName",
	servicePartPartyResponsible = "servicePartPartyResponsible",
	servicePartsRequired = "servicePartsRequired",
	serviceProvider = "serviceProvider",
	serviceType = "serviceType"
}
/** unique or primary key constraints on table "HomeInventoryItem" */
export const enum HomeInventoryItem_constraint {
	HomeInventoryItem_pkey = "HomeInventoryItem_pkey"
}
/** select columns of table "HomeInventoryItem" */
export const enum HomeInventoryItem_select_column {
	createdAt = "createdAt",
	homeId = "homeId",
	homeInventoryItemCategoryId = "homeInventoryItemCategoryId",
	id = "id",
	isArchived = "isArchived",
	lastServicedAt = "lastServicedAt",
	name = "name",
	notes = "notes",
	serviceFrequency = "serviceFrequency",
	serviceProvider = "serviceProvider",
	serviceType = "serviceType",
	updatedAt = "updatedAt"
}
/** update columns of table "HomeInventoryItem" */
export const enum HomeInventoryItem_update_column {
	homeInventoryItemCategoryId = "homeInventoryItemCategoryId",
	isArchived = "isArchived",
	lastServicedAt = "lastServicedAt",
	name = "name",
	notes = "notes",
	serviceFrequency = "serviceFrequency",
	serviceProvider = "serviceProvider",
	serviceType = "serviceType"
}
/** select columns of table "HomeInventoryMaintenanceSchedule" */
export const enum HomeInventoryMaintenanceSchedule_select_column {
	dueBy = "dueBy",
	homeId = "homeId",
	homeInventoryItemId = "homeInventoryItemId"
}
export const enum HomeInventoryServiceProvider_enum {
	handyperson = "handyperson",
	specialist = "specialist"
}
/** select columns of table "HomeInventoryServiceProvider" */
export const enum HomeInventoryServiceProvider_select_column {
	description = "description",
	value = "value"
}
export const enum HomeInventoryServiceType_enum {
	maintain = "maintain",
	replace = "replace"
}
/** select columns of table "HomeInventoryServiceType" */
export const enum HomeInventoryServiceType_select_column {
	description = "description",
	value = "value"
}
export const enum HomeStatus_enum {
	active = "active",
	canceled = "canceled",
	new = "new",
	paused = "paused"
}
export const enum HomeWaitlistReason_enum {
	noAvailability = "noAvailability",
	noServiceArea = "noServiceArea"
}
/** unique or primary key constraints on table "HomeWaitlist" */
export const enum HomeWaitlist_constraint {
	HomeWaitlist_homeId_key = "HomeWaitlist_homeId_key",
	HomeWaitlist_pkey = "HomeWaitlist_pkey"
}
/** select columns of table "HomeWaitlist" */
export const enum HomeWaitlist_select_column {
	createdAt = "createdAt",
	homeId = "homeId",
	id = "id",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "HomeWaitlist" (current role has no relevant permissions) */
export const enum HomeWaitlist_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** select columns of table "Home" */
export const enum Home_select_column {
	activeMember = "activeMember",
	city = "city",
	createdAt = "createdAt",
	geographicRegionId = "geographicRegionId",
	id = "id",
	location = "location",
	referralCode = "referralCode",
	schedulingRegionId = "schedulingRegionId",
	sendbirdChannelId = "sendbirdChannelId",
	state = "state",
	status = "status",
	streetAddress = "streetAddress",
	stripeDefaultSourcePresent = "stripeDefaultSourcePresent",
	stripeId = "stripeId",
	stripePriceId = "stripePriceId",
	stripePromoCode = "stripePromoCode",
	timeZone = "timeZone",
	unavailableEndingAt = "unavailableEndingAt",
	unavailableStartingAt = "unavailableStartingAt",
	updatedAt = "updatedAt",
	wifiCredentialsE = "wifiCredentialsE",
	zipCode = "zipCode"
}
/** select columns of table "MarketingLead" */
export const enum MarketingLead_select_column {
	id = "id"
}
export const enum NeighborhoodRegionType_enum {
	pricing = "pricing",
	scheduling = "scheduling"
}
/** select columns of table "NeighborhoodRegion" */
export const enum NeighborhoodRegion_select_column {
	createdAt = "createdAt",
	id = "id",
	neighborhoodId = "neighborhoodId",
	regionId = "regionId",
	type = "type",
	updatedAt = "updatedAt"
}
/** select columns of table "Neighborhood" */
export const enum Neighborhood_select_column {
	availableStartingAt = "availableStartingAt",
	createdAt = "createdAt",
	displayName = "displayName",
	handymanPersonId = "handymanPersonId",
	id = "id",
	updatedAt = "updatedAt",
	zipCode = "zipCode"
}
/** unique or primary key constraints on table "PersonBiographyItem" */
export const enum PersonBiographyItem_constraint {
	PersonBiographyItem_pkey = "PersonBiographyItem_pkey"
}
/** select columns of table "PersonBiographyItem" */
export const enum PersonBiographyItem_select_column {
	createdAt = "createdAt",
	id = "id",
	personId = "personId",
	rank = "rank",
	title = "title",
	updatedAt = "updatedAt",
	value = "value"
}
/** update columns of table "PersonBiographyItem" */
export const enum PersonBiographyItem_update_column {
	rank = "rank",
	title = "title",
	value = "value"
}
export const enum PersonContactPreference_enum {
	app = "app",
	email = "email",
	phone = "phone",
	text = "text"
}
/** unique or primary key constraints on table "PersonHome" */
export const enum PersonHome_constraint {
	PersonHome_personId_homeId_key = "PersonHome_personId_homeId_key",
	PersonHome_pkey = "PersonHome_pkey"
}
/** select columns of table "PersonHome" */
export const enum PersonHome_select_column {
	homeId = "homeId",
	personId = "personId"
}
/** placeholder for update columns of table "PersonHome" (current role has no relevant permissions) */
export const enum PersonHome_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
export const enum PersonType_enum {
	handyman = "handyman",
	homeowner = "homeowner",
	inactiveHandyman = "inactiveHandyman",
	inactiveOps = "inactiveOps",
	ops = "ops",
	vendor = "vendor"
}
/** unique or primary key constraints on table "Person" */
export const enum Person_constraint {
	Person_phoneNumber_key = "Person_phoneNumber_key",
	Person_pkey = "Person_pkey"
}
/** select columns of table "Person" */
export const enum Person_select_column {
	biography = "biography",
	contactPreference = "contactPreference",
	currentTimeZone = "currentTimeZone",
	email = "email",
	firstName = "firstName",
	id = "id",
	lastName = "lastName",
	phoneNumber = "phoneNumber",
	type = "type"
}
/** update columns of table "Person" */
export const enum Person_update_column {
	biography = "biography",
	contactPreference = "contactPreference",
	currentTimeZone = "currentTimeZone",
	email = "email",
	firstName = "firstName",
	lastName = "lastName",
	phoneNumber = "phoneNumber",
	profilePictureId = "profilePictureId"
}
/** select columns of table "ReferralProgram" */
export const enum ReferralProgram_select_column {
	creditAmount = "creditAmount",
	id = "id",
	isActive = "isActive"
}
/** unique or primary key constraints on table "Referral" */
export const enum Referral_constraint {
	Referral_acceptedById_key = "Referral_acceptedById_key",
	Referral_pkey = "Referral_pkey"
}
/** select columns of table "Referral" */
export const enum Referral_select_column {
	acceptedById = "acceptedById",
	createdAt = "createdAt",
	creditAppliedToInviter = "creditAppliedToInviter",
	id = "id",
	invitedById = "invitedById",
	updatedAt = "updatedAt"
}
/** placeholder for update columns of table "Referral" (current role has no relevant permissions) */
export const enum Referral_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** select columns of table "Region" */
export const enum Region_select_column {
	createdAt = "createdAt",
	displayName = "displayName",
	id = "id",
	updatedAt = "updatedAt"
}
export const enum ServiceOfferResponseStatus_enum {
	accepted = "accepted",
	declined = "declined"
}
/** select columns of table "ServiceOfferResponseStatus" */
export const enum ServiceOfferResponseStatus_select_column {
	description = "description",
	value = "value"
}
/** unique or primary key constraints on table "ServiceOfferResponse" */
export const enum ServiceOfferResponse_constraint {
	ServiceOfferResponse_pkey = "ServiceOfferResponse_pkey",
	ServiceOfferResponse_serviceOfferId_homeId_key = "ServiceOfferResponse_serviceOfferId_homeId_key"
}
/** select columns of table "ServiceOfferResponse" */
export const enum ServiceOfferResponse_select_column {
	homeId = "homeId",
	id = "id",
	serviceOfferId = "serviceOfferId",
	status = "status"
}
/** update columns of table "ServiceOfferResponse" */
export const enum ServiceOfferResponse_update_column {
	status = "status"
}
/** select columns of table "ServiceOfferType" */
export const enum ServiceOfferType_select_column {
	description = "description",
	value = "value"
}
/** select columns of table "ServiceOffer" */
export const enum ServiceOffer_select_column {
	additionalChargeAmount = "additionalChargeAmount",
	beginsAtDate = "beginsAtDate",
	beginsAtTime = "beginsAtTime",
	confirmBy = "confirmBy",
	costExplaination = "costExplaination",
	createdAt = "createdAt",
	description = "description",
	endsAtDate = "endsAtDate",
	endsAtTime = "endsAtTime",
	estimatedDuration = "estimatedDuration",
	id = "id",
	requiresAdditionalCharge = "requiresAdditionalCharge",
	requiresConfirmation = "requiresConfirmation",
	scheduleBy = "scheduleBy",
	title = "title",
	type = "type",
	updatedAt = "updatedAt"
}
export const enum ServiceProviderRequestStage_enum {
	archive = "archive",
	assignVendor = "assignVendor",
	contactMember = "contactMember",
	duplicateRequest = "duplicateRequest",
	invoiceReceived = "invoiceReceived",
	memberBilled = "memberBilled",
	oldIgnore = "oldIgnore",
	onsiteEstimateScheduled = "onsiteEstimateScheduled",
	processing = "processing",
	quoteApproved = "quoteApproved",
	quoteReceived = "quoteReceived",
	quoteRejected = "quoteRejected",
	quoteSentToMember = "quoteSentToMember",
	repairsCompleted = "repairsCompleted",
	repairsScheduled = "repairsScheduled",
	requested = "requested",
	submitted = "submitted",
	taskClosed = "taskClosed",
	taskCompleted = "taskCompleted",
	taskRejected = "taskRejected",
	taskScheduled = "taskScheduled",
	test = "test",
	vendorConfirmed = "vendorConfirmed"
}
/** select columns of table "ServiceProviderRequestStage" */
export const enum ServiceProviderRequestStage_select_column {
	description = "description",
	value = "value"
}
/** unique or primary key constraints on table "ServiceProviderRequest" */
export const enum ServiceProviderRequest_constraint {
	ServiceProviderRequest_pkey = "ServiceProviderRequest_pkey"
}
/** select columns of table "ServiceProviderRequest" */
export const enum ServiceProviderRequest_select_column {
	id = "id",
	stage = "stage",
	startedProcessingRequestAt = "startedProcessingRequestAt",
	taskId = "taskId"
}
/** placeholder for update columns of table "ServiceProviderRequest" (current role has no relevant permissions) */
export const enum ServiceProviderRequest_update_column {
	_PLACEHOLDER = "_PLACEHOLDER"
}
/** select columns of table "ServiceProvider" */
export const enum ServiceProvider_select_column {
	createdAt = "createdAt",
	email = "email",
	id = "id",
	name = "name",
	phoneNumber = "phoneNumber",
	updatedAt = "updatedAt"
}
/** select columns of table "StripePaymentMethod" */
export const enum StripePaymentMethod_select_column {
	createdAt = "createdAt",
	homeId = "homeId",
	id = "id",
	paymentMethodId = "paymentMethodId"
}
export const enum StripeSubscriptionStatus_enum {
	active = "active",
	canceled = "canceled",
	incomplete = "incomplete",
	incomplete_expired = "incomplete_expired",
	past_due = "past_due",
	paused = "paused",
	trialing = "trialing",
	unpaid = "unpaid"
}
/** select columns of table "StripeSubscription" */
export const enum StripeSubscription_select_column {
	canceledAt = "canceledAt",
	createdAt = "createdAt",
	homeId = "homeId",
	id = "id",
	interval = "interval",
	status = "status",
	subscriptionId = "subscriptionId",
	updatedAt = "updatedAt"
}
export const enum Stripe_Coupon_Duration {
	forever = "forever",
	once = "once",
	repeating = "repeating"
}
/** unique or primary key constraints on table "TaskAttachment" */
export const enum TaskAttachment_constraint {
	TaskAttachment_pkey = "TaskAttachment_pkey",
	TaskAttachment_taskId_attachmentId_key = "TaskAttachment_taskId_attachmentId_key"
}
/** select columns of table "TaskAttachment" */
export const enum TaskAttachment_select_column {
	attachmentId = "attachmentId",
	createdAt = "createdAt",
	id = "id",
	rank = "rank",
	taskId = "taskId",
	updatedAt = "updatedAt"
}
/** update columns of table "TaskAttachment" */
export const enum TaskAttachment_update_column {
	rank = "rank"
}
/** select columns of table "TaskComment" */
export const enum TaskComment_select_column {
	body = "body",
	createdAt = "createdAt",
	id = "id",
	personId = "personId",
	taskId = "taskId",
	updatedAt = "updatedAt"
}
export const enum TaskPartDeliveryLocation_enum {
	amazonLocker = "amazonLocker",
	hq = "hq",
	memberHome = "memberHome",
	retailStore = "retailStore"
}
export const enum TaskPartPartyResponsible_enum {
	handyman = "handyman",
	homeowner = "homeowner",
	hq = "hq"
}
/** select columns of table "TaskPartPartyResponsible" */
export const enum TaskPartPartyResponsible_select_column {
	description = "description",
	value = "value"
}
export const enum TaskPartStatus_enum {
	backordered = "backordered",
	delivered = "delivered",
	discontinued = "discontinued",
	installed = "installed",
	memberApproved = "memberApproved",
	memberRejected = "memberRejected",
	ordered = "ordered",
	pendingMemberApproval = "pendingMemberApproval",
	processing = "processing",
	requiresMemberApproval = "requiresMemberApproval",
	requiresReplacement = "requiresReplacement",
	researching = "researching",
	shipped = "shipped",
	unavailable = "unavailable"
}
/** select columns of table "TaskPartStatus" */
export const enum TaskPartStatus_select_column {
	description = "description",
	value = "value"
}
/** unique or primary key constraints on table "TaskPart" */
export const enum TaskPart_constraint {
	TaskPartQueue_pkey = "TaskPartQueue_pkey"
}
/** select columns of table "TaskPart" */
export const enum TaskPart_select_column {
	createdAt = "createdAt",
	estimatedDeliveryBy = "estimatedDeliveryBy",
	id = "id",
	memberStartedRequestAt = "memberStartedRequestAt",
	orderedAt = "orderedAt",
	partAcquiredAt = "partAcquiredAt",
	partDeliveredAt = "partDeliveredAt",
	partDeliveryLocation = "partDeliveryLocation",
	partName = "partName",
	partOnHand = "partOnHand",
	partyResponsible = "partyResponsible",
	personOrderedId = "personOrderedId",
	requiresReview = "requiresReview",
	startedProcessingRequestAt = "startedProcessingRequestAt",
	status = "status",
	updatedAt = "updatedAt"
}
/** update columns of table "TaskPart" */
export const enum TaskPart_update_column {
	id = "id",
	partName = "partName",
	partOnHand = "partOnHand",
	partyResponsible = "partyResponsible",
	personOrderedId = "personOrderedId"
}
export const enum TaskStatus_enum {
	archive = "archive",
	completed = "completed",
	inProgress = "inProgress",
	todo = "todo"
}
export const enum TaskType_enum {
	firstVisit = "firstVisit",
	handymanVisit = "handymanVisit",
	initialMaintenanceInventory = "initialMaintenanceInventory",
	maintenance = "maintenance",
	perk = "perk",
	vendorVisit = "vendorVisit",
	walkthrough = "walkthrough"
}
/** unique or primary key constraints on table "TaskUpdateLogAttachment" */
export const enum TaskUpdateLogAttachment_constraint {
	TaskUpdateLogAttachment_attachmentId_taskUpdateLogId_key = "TaskUpdateLogAttachment_attachmentId_taskUpdateLogId_key",
	TaskUpdateLogAttachment_pkey = "TaskUpdateLogAttachment_pkey"
}
/** select columns of table "TaskUpdateLogAttachment" */
export const enum TaskUpdateLogAttachment_select_column {
	attachmentId = "attachmentId",
	createdAt = "createdAt",
	id = "id",
	rank = "rank",
	taskUpdateLogId = "taskUpdateLogId",
	updatedAt = "updatedAt"
}
/** update columns of table "TaskUpdateLogAttachment" */
export const enum TaskUpdateLogAttachment_update_column {
	rank = "rank"
}
/** unique or primary key constraints on table "TaskUpdateLog" */
export const enum TaskUpdateLog_constraint {
	TaskUpdateLog_pkey = "TaskUpdateLog_pkey"
}
/** select columns of table "TaskUpdateLog" */
export const enum TaskUpdateLog_select_column {
	appointmentReportId = "appointmentReportId",
	authorId = "authorId",
	createdAt = "createdAt",
	createdById = "createdById",
	id = "id",
	notes = "notes",
	partsDescription = "partsDescription",
	partsRequired = "partsRequired",
	ranOutOfTime = "ranOutOfTime",
	serviceProviderRequestId = "serviceProviderRequestId",
	status = "status",
	taskId = "taskId",
	taskPartId = "taskPartId",
	thirdPartyDescription = "thirdPartyDescription",
	thirdPartyRequired = "thirdPartyRequired",
	updatedAt = "updatedAt"
}
/** select "TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TaskUpdateLog" */
export const enum TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns {
	partsRequired = "partsRequired",
	ranOutOfTime = "ranOutOfTime",
	thirdPartyRequired = "thirdPartyRequired"
}
/** select "TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TaskUpdateLog" */
export const enum TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns {
	partsRequired = "partsRequired",
	ranOutOfTime = "ranOutOfTime",
	thirdPartyRequired = "thirdPartyRequired"
}
/** update columns of table "TaskUpdateLog" */
export const enum TaskUpdateLog_update_column {
	notes = "notes",
	partsDescription = "partsDescription",
	partsRequired = "partsRequired",
	serviceProviderRequestId = "serviceProviderRequestId",
	status = "status",
	taskPartId = "taskPartId",
	thirdPartyDescription = "thirdPartyDescription",
	thirdPartyRequired = "thirdPartyRequired"
}
/** unique or primary key constraints on table "Task" */
export const enum Task_constraint {
	Task_pkey = "Task_pkey"
}
/** select columns of table "Task" */
export const enum Task_select_column {
	appointmentId = "appointmentId",
	completedAt = "completedAt",
	createdAt = "createdAt",
	createdById = "createdById",
	description = "description",
	dueAt = "dueAt",
	estimatedCompletionTime = "estimatedCompletionTime",
	homeId = "homeId",
	homeInventoryItemId = "homeInventoryItemId",
	id = "id",
	lastUpdatedById = "lastUpdatedById",
	notes = "notes",
	parentTaskId = "parentTaskId",
	partsRequired = "partsRequired",
	priorityLevel = "priorityLevel",
	requiresOperationalReview = "requiresOperationalReview",
	status = "status",
	thirdPartyRequired = "thirdPartyRequired",
	title = "title",
	type = "type",
	updatedAt = "updatedAt"
}
/** select "Task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Task" */
export const enum Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns {
	partsRequired = "partsRequired",
	requiresOperationalReview = "requiresOperationalReview",
	thirdPartyRequired = "thirdPartyRequired"
}
/** select "Task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Task" */
export const enum Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns {
	partsRequired = "partsRequired",
	requiresOperationalReview = "requiresOperationalReview",
	thirdPartyRequired = "thirdPartyRequired"
}
/** update columns of table "Task" */
export const enum Task_update_column {
	appointmentId = "appointmentId",
	description = "description",
	dueAt = "dueAt",
	homeInventoryItemId = "homeInventoryItemId",
	notes = "notes",
	parentTaskId = "parentTaskId",
	priorityLevel = "priorityLevel",
	requiresOperationalReview = "requiresOperationalReview",
	status = "status",
	title = "title"
}
/** select columns of table "TimeZone" */
export const enum TimeZone_select_column {
	abbrev = "abbrev",
	is_dst = "is_dst",
	name = "name",
	utc_offset = "utc_offset"
}
/** ordering argument of a cursor */
export const enum cursor_ordering {
	ASC = "ASC",
	DESC = "DESC"
}
/** select columns of table "find_Person_response" */
export const enum find_Person_response_select_column {
	present = "present"
}
/** column ordering options */
export const enum order_by {
	asc = "asc",
	asc_nulls_first = "asc_nulls_first",
	asc_nulls_last = "asc_nulls_last",
	desc = "desc",
	desc_nulls_first = "desc_nulls_first",
	desc_nulls_last = "desc_nulls_last"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "query_root",
    "mutation": "mutation_root",
    "subscription": "subscription_root"
}

export type GenericOperation<O> = O extends 'query'
  ? "query_root"
  : O extends 'mutation'
  ? "mutation_root"
  : "subscription_root"

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('https://apini.honeyhomes.com/v1/graphql')