import { abstractView } from "./AbstractView";
import {
  checkForUTMParameters,
  saveReferralData,
  saveUserAddress,
  saveUserZipCode,
  saveZipCodeStatus,
  storeUserRegionId,
} from "../helpers/UserData";
import {
  availableDateForRegion,
  geographicRegionsForZipCode,
} from "../schema/operations";
import { navigateTo } from "../helpers/Router";
import { saveRegions } from "../helpers/Utils";
import { DateTime } from "luxon";

export function zipCodeRouter() {
  checkForUTMParameters();
  const view = abstractView("ZipCodeRouter");
  view.onload = () => {
    const params = new URLSearchParams(window.location.search);

    const zipCode = params.get("zip");
    const streetAddress = params.get("streetAddress");
    const city = params.get("city");
    const state = params.get("state");
    const streetLine = params.get("streetLine");

    if (streetAddress && city && state && streetLine && zipCode) {
      saveUserAddress(
        JSON.stringify({ streetAddress, city, state, streetLine, zipCode }),
      );
    }

    if (params.has("referredBy") && params.has("referredById")) {
      const referredBy = params.get("referredBy");
      const referredById = params.get("referredById");
      if (referredBy && referredById) {
        saveReferralData({
          referredById: referredById,
          referredBy: referredBy,
        });
      }
    }

    if (zipCode) {
      saveUserZipCode(zipCode);
      void (async () => {
        try {
          const result = await availableDateForRegion(zipCode);
          if (result.ok) {
            const option = result.val;
            // if a datetime has not been returned...
            if (option.none) {
              // ...then there are no plans to be active in this zip code at this time
              saveZipCodeStatus("waitlisted");
              navigateTo("/waitlist-personal-info");
            } else {
              const now = DateTime.now();
              if (option.val <= now) {
                saveZipCodeStatus("live");
              } else {
                saveZipCodeStatus("upcoming");
              }
            }
          } else {
            console.error(result.val);
          }
        } catch (error) {
          console.error(error);
        }

        const regionsResult = await geographicRegionsForZipCode(zipCode);
        if (regionsResult.err) {
          console.error(regionsResult.val);
          return;
        }

        // if this area is not being serviced yet...
        if (regionsResult.val.length === 0) {
          saveZipCodeStatus("waitlisted");
          navigateTo("/waitlist-personal-info");
        } else {
          saveRegions(regionsResult.val);
          if (regionsResult.val.length === 1) {
            storeUserRegionId(regionsResult.val[0].id);
          }
          if (regionsResult.val.length > 1) {
            navigateTo("/neighborhood");
          } else {
            navigateTo("/personal-info");
          }
        }
      })();
    } else {
      navigateTo("/");
    }
  };
  return view;
}
