import * as Sentry from "@sentry/browser";
import { Ok, Result, Err } from "ts-results";
import * as operations from "../schema/operations";
import * as user from "./UserData";
import * as firebase from "../helpers/Firebase";
import { navigateTo } from "./Router";
import * as utils from "./Utils";
import { strings } from "./Strings";
import { subscriptionPrices } from "./Stripe";
import {
  stripeSubscriptionProducts,
  StripeSubscription,
  ContactPreference,
} from "../schema/operations";

export async function processFormSubmission(form: HTMLFormElement) {
  if (form.matches(".form__zip-code")) {
    processZipCodeForm(form);
  } else if (form.matches(".neighborhood-selection")) {
    setNeighborhood(form);
  } else if (form.matches(".source-selection")) {
    await setUserSource(form);
  } else if (form.matches(".personal-info")) {
    await processPersonalInfo(form);
  } else if (form.matches(".verify-phone-number")) {
    await validateOTP();
  } else if (form.matches(".contact-preference-selection")) {
    const contactPreferenceElement = form.querySelector(
      ".selected",
    ) as HTMLElement;
    if (contactPreferenceElement) {
      await setContactPreference(contactPreferenceElement);
    }
  }
}

function processZipCodeForm(form: HTMLFormElement) {
  utils.startLoading("zip-code-button");

  let zipCode =
    (form.querySelector("#zipCode") as HTMLInputElement).value ?? "";
  zipCode = zipCode.split("-")[0] ?? "";

  if (zipCode) {
    user.saveUserZipCode(zipCode);
  }

  navigateTo(`/zip-code-router?zip=${zipCode}`);
}

async function processPersonalInfo(form: HTMLFormElement) {
  const buttonID = "sign-up-button";
  const buttonText = utils.startLoading(buttonID);

  try {
    await user.storeUserData(form);

    const zipCodeStatusOption = user.getZipCodeStatus();
    if (zipCodeStatusOption.some) {
      const zipCodeStatus = zipCodeStatusOption.val;
      if (zipCodeStatus === "waitlisted") {
        // ...then go to the Verify Phone Number view
        const userData = user.getUserData();
        if (userData) {
          const signInAttempt = await firebase.attemptSignin(
            firebase.firebaseAuth,
            userData.phoneNumber,
            buttonID,
          );
          if (signInAttempt.ok) {
            //P1.1
            navigateTo("/waitlist-verify-phone-number");
          } else {
            utils.stopLoading(buttonID, buttonText, strings.genericError);
          }
        }
      } else {
        // ...else go to the Schedule Appointments view
        const userData = user.getUserData();
        if (
          userData &&
          userData.streetAddress &&
          userData.city &&
          userData.state
        ) {
          try {
            const { Geocoder } = (await google.maps.importLibrary(
              "geocoding",
            )) as google.maps.GeocodingLibrary;
            const geocoder = new Geocoder();
            await geocoder.geocode(
              {
                address: `${userData.streetAddress}, ${userData.city}, ${userData.state}`,
              },
              async (results) => {
                if (results) {
                  const latitude = results[0].geometry.location.lat();
                  const longitude = results[0].geometry.location.lng();
                  if (userData && userData.organicSource) {
                    const handyman = user.getHandyMan();
                    if (handyman) {
                      await searchForAppointments(handyman.id, {
                        latitude,
                        longitude,
                      }).catch(() => {
                        utils.stopLoading(
                          buttonID,
                          buttonText,
                          strings.appointmentLookupError,
                        );
                      });
                    } else {
                      navigateTo("/schedule-appointment");
                      console.error(
                        "Missing handyman or location data. Redirecting to schedule appointment page.",
                      );
                    }
                  } else {
                    navigateTo(`/discovery?lat=${latitude}&long=${longitude}`);
                  }
                } else {
                  utils.stopLoading(
                    buttonID,
                    buttonText,
                    strings.handyManError,
                  );
                }
              },
            );
          } catch (error) {
            console.log(error);
            throw error;
          }
        } else {
          utils.stopLoading(buttonID, buttonText, strings.genericError);
        }
      }
    } else {
      utils.stopLoading(buttonID, buttonText, strings.genericError);
    }
  } catch (error) {
    console.log(error);
    const userAddress = user.getUserAddress();
    if (userAddress) {
      console.log(`User Addresss ${JSON.stringify(userAddress)} `);
    }
    Sentry.captureException(error);
  }
}

async function validateOTP() {
  const code = (document.getElementById("verificationCode") as HTMLInputElement)
    .value;
  if (code && firebase.otpConfirmation) {
    const buttonValue = utils.startLoading("verify-number");
    const confirmResponse = await firebase.confirmOTP(
      firebase.otpConfirmation,
      code,
    );
    if (confirmResponse.ok) {
      const marketingLeadIdOption = user.getMarketingLeadId();
      if (marketingLeadIdOption.some) {
        const userDetails = user.getUserData();
        if (userDetails) {
          const marketingLeadId = marketingLeadIdOption.val;
          let marketingLeadData: operations.MarketingLead = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            phoneNumber: userDetails.phoneNumber,
            email: userDetails.email,
            streetAddress: userDetails.streetAddress,
            city: userDetails.city,
            state: userDetails.state,
            zipCode: userDetails.zipCode,
            organicSource: userDetails.organicSource,
            phoneNumberVerified: true,
          };
          const appointmentDetailsOption = user.getAppointmentDetails();
          if (appointmentDetailsOption.some) {
            marketingLeadData.firstAppointmentEndsAt =
              appointmentDetailsOption.val[1];
            marketingLeadData.firstAppointmentBeginsAt =
              appointmentDetailsOption.val[0];
          }
          const utmParameterDataOption = user.getUTMParameterData();
          if (utmParameterDataOption.some) {
            marketingLeadData = {
              ...marketingLeadData,
              ...utmParameterDataOption.val,
            };
          }
          await operations.updateMarketingLead(
            marketingLeadId,
            marketingLeadData,
          );
        }
      }
      await determinePathAfterPhoneVerification(
        confirmResponse.val,
        "verify-number",
        buttonValue,
      );
    } else {
      switch (confirmResponse.val._tag) {
        case "CodeExpiredError":
        case "InvalidCodeError": {
          utils.stopLoading(
            "verify-number",
            buttonValue,
            strings[confirmResponse.val._tag],
          );
          break;
        }
        default: {
          Sentry.captureException(confirmResponse.val);
          utils.stopLoading(
            "verify-number",
            buttonValue,
            strings.phoneConfirmError,
          );
          break;
        }
      }
    }
  } else {
    // Missing optConfirmation
    utils.displayErrorMessage(strings.missingotpConfirmError);
  }
}

async function setUserSource(form: HTMLFormElement) {
  const organicSource = utils.selectedOptionFromSingleChoiceList(form);
  const buttonID = "submitSource";
  const buttonText = utils.startLoading(buttonID);
  const qs = new URLSearchParams(window.location.search);
  const marketingLeadIdOption = user.getMarketingLeadId();
  const userDetails = user.getUserData();
  if (userDetails) {
    user.saveUserData({ ...userDetails, organicSource });
  } else {
    console.warn("Unable to set user source on user data.");
  }
  if (marketingLeadIdOption.some && userDetails) {
    const marketingLeadId = marketingLeadIdOption.val;
    let marketingLeadData: operations.MarketingLead = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phoneNumber: userDetails.phoneNumber,
      email: userDetails.email,
      streetAddress: userDetails.streetAddress,
      city: userDetails.city,
      state: userDetails.state,
      zipCode: userDetails.zipCode,
      organicSource,
    };
    const utmParameterDataOption = user.getUTMParameterData();
    if (utmParameterDataOption.some) {
      marketingLeadData = {
        ...marketingLeadData,
        ...utmParameterDataOption.val,
      };
    }
    await operations.updateMarketingLead(marketingLeadId, marketingLeadData);
  } else {
    console.warn("Unable to set user source");
  }
  const handyman = user.getHandyMan();
  if (handyman && qs.has("lat") && qs.has("long")) {
    const latitude = Number(qs.get("lat"));
    const longitude = Number(qs.get("long"));
    await searchForAppointments(handyman.id, {
      latitude,
      longitude,
    }).catch(() => {
      utils.stopLoading(buttonID, buttonText, strings.appointmentLookupError);
    });
  } else {
    navigateTo("/schedule-appointment");
    console.error(
      "Missing handyman or location data. Redirecting to schedule appointment page.",
    );
  }
}

function setNeighborhood(form: HTMLFormElement) {
  utils.startLoading("submitNeighborhood");
  utils.selectNeighborhood(form);
  const zipCodeStatusOption = user.getZipCodeStatus();
  if (zipCodeStatusOption.some && zipCodeStatusOption.val === "waitlisted") {
    navigateTo("/waitlist-personal-info");
  } else {
    navigateTo("/personal-info");
  }
}

async function createHomeOwnerAndAddToWaitingList(
  buttonId: string,
  buttonText: string | undefined,
): Promise<void> {
  await createHomeOwner((result) => {
    if (result.ok) {
      addToWaitingList(result.val).catch(() => {
        utils.stopLoading(buttonId, buttonText, strings.waitingListError);
      });
    } else {
      Sentry.captureException(result.val);
      utils.stopLoading(buttonId, buttonText, strings.accountCreationError);
    }
  });
}

async function createHomeOwner(
  callback: (res: Result<string, Error>) => void,
): Promise<void> {
  const userData = user.getUserData();
  const token = firebase.getAccessToken();
  if (userData && token) {
    const createUserResult = await operations.createPersonAndHome(
      token,
      utils.createPersonParamsFrom(userData),
    );
    if (createUserResult.ok) {
      // update homeAccountCreated field in db
      const marketingLeadIdOption = user.getMarketingLeadId();
      if (marketingLeadIdOption.some) {
        const userDetails = user.getUserData();
        if (userDetails) {
          const marketingLeadId = marketingLeadIdOption.val;
          let marketingLeadData: operations.MarketingLead = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            phoneNumber: userDetails.phoneNumber,
            email: userDetails.email,
            streetAddress: userDetails.streetAddress,
            city: userDetails.city,
            state: userDetails.state,
            zipCode: userDetails.zipCode,
            organicSource: userDetails.organicSource,
            phoneNumberVerified: true,
            homeAccountCreated: true,
          };
          const appointmentDetailsOption = user.getAppointmentDetails();
          if (appointmentDetailsOption.some) {
            marketingLeadData.firstAppointmentEndsAt =
              appointmentDetailsOption.val[1];
            marketingLeadData.firstAppointmentBeginsAt =
              appointmentDetailsOption.val[0];
          }
          const utmParameterDataOption = user.getUTMParameterData();
          if (utmParameterDataOption.some) {
            marketingLeadData = {
              ...marketingLeadData,
              ...utmParameterDataOption.val,
            };
          }
          await operations.updateMarketingLead(
            marketingLeadId,
            marketingLeadData,
          );
        }
      }
      user.storePersonAndHomeIds(createUserResult.val);

      await firebase.tokenTransition((res) => {
        if (res.ok) {
          firebase.firebaseAuth.currentUser
            ?.getIdToken(true)
            .then((homeOwnerToken) => {
              firebase.saveAccessToken(homeOwnerToken);
              callback(Ok(homeOwnerToken));
            })
            .catch((error) => {
              callback(Err(error));
            });
        } else {
          callback(operations.normalizeError(res.val));
        }
      });
    } else {
      Sentry.captureException(createUserResult.val);
      callback(operations.normalizeError(createUserResult.val));
    }
  }
}

async function searchForAppointments(
  handymanId: string,
  locationObj: { latitude: number; longitude: number },
): Promise<Result<void, Error>> {
  const appointments = await operations.availableAppointmentTimes(
    handymanId,
    locationObj,
  );
  if (appointments.ok) {
    if (appointments.val.availabilityResultId.some) {
      utils.saveAvailabilityResultId(appointments.val.availabilityResultId.val);
    }
    if (appointments.val.availableSlots.length === 0) {
      //P2B
      navigateTo("/no-appointments");
    } else {
      utils.saveAvailableSlots(appointments.val.availableSlots);
      //P2
      navigateTo("/schedule-appointment");
    }
    return Ok.EMPTY;
  } else {
    Sentry.captureException(appointments.val);
    return operations.normalizeError(appointments.val);
  }
}

async function addToWaitingList(
  accessToken: string,
): Promise<Result<void, Error>> {
  const waitingList = await operations.addToWaitlist(
    "noServiceArea",
    accessToken,
  );
  if (waitingList.ok) {
    //P4C
    navigateTo("/waiting-list");
    return Ok.EMPTY;
  } else {
    Sentry.captureException(waitingList.val);
    return operations.normalizeError(waitingList.val);
  }
}

export async function resendOTP() {
  const userData = user.getUserData();
  if (userData) {
    const spinner = document.getElementById("resend-loader") as HTMLDivElement;
    if (spinner.classList.contains("hidden")) {
      spinner.classList.remove("hidden");
      const res = await firebase.attemptSignin(
        firebase.firebaseAuth,
        userData.phoneNumber,
        "recaptcha-container",
      );
      if (!res.ok) {
        Sentry.captureException(res.val);
      }
      spinner.classList.add("hidden");
    }
  } else {
    utils.displayErrorMessage(strings.userDetailsError);
  }
}

async function determinePathAfterPhoneVerification(
  accessToken: string,
  buttonId: string,
  buttonText: string | undefined,
): Promise<void> {
  let userData = user.getUserData();
  if (!userData) {
    utils.stopLoading(buttonId, buttonText, strings.userDetailsError);
    return;
  }

  // 1. First check if its an existing customer
  const phoneNumberExistsResult = await operations.phoneNumberExists(
    userData.phoneNumber,
    accessToken,
  );
  if (phoneNumberExistsResult.err) {
    utils.stopLoading(buttonId, buttonText, strings.phoneVerifyError);
    return;
  }
  const userState = user.getUserState();
  let userRebookingAppt = false;
  if (userState.some) {
    userRebookingAppt = userState.val === user.USER_STATES.REBOOKING;
  }
  if (phoneNumberExistsResult.val && !userRebookingAppt) {
    //P4D
    navigateTo("/existing-customer");
    return;
  } else {
    user.saveUserState(null);
    const zipCodeStatusOption = user.getZipCodeStatus();
    if (zipCodeStatusOption.some) {
      const zipCodeStatus = zipCodeStatusOption.val;
      if (zipCodeStatus === "waitlisted") {
        await createHomeOwnerAndAddToWaitingList(buttonId, buttonText);
      } else {
        const handleUserConfirmation = async () => {
          const tokens = utils.getTokens();
          if (tokens) {
            const contactPreferenceOption = user.getContactPreference();
            if (contactPreferenceOption.some) {
              const contactPreference = contactPreferenceOption.val;
              const setPreference = await operations.setContactPreference(
                contactPreference as ContactPreference,
                tokens.personId,
                tokens.accessToken,
              );
              if (!setPreference.ok) {
                utils.stopLoading(
                  buttonId,
                  buttonText,
                  strings.storeContactPreferenceError,
                );
              }
              navigateTo(
                `/no-appointments-confirmation?type=${contactPreference}`,
              );
              return;
            }

            const timesOption = user.getAppointmentDetails();
            if (timesOption.some) {
              const times = timesOption.val;
              const apptResult = await registerAppointment(
                tokens,
                times,
                buttonId,
                buttonText,
              );
              if (!apptResult.ok) {
                return;
              }
            }

            // refresh user data
            userData = user.getUserData();
            let prices = null;
            if (userData) {
              try {
                const token = firebase.getAccessToken();
                if (token) {
                  prices = await stripeSubscriptionProducts(token);
                }
                if (prices && prices.ok) {
                  const productName = "Honey Homes Membership";
                  const membershipPrices = prices.val.find(
                    (product: StripeSubscription) =>
                      product.name === productName,
                  );
                  if (membershipPrices) {
                    const subPrices = subscriptionPrices(
                      membershipPrices.prices,
                    );
                    if (subPrices) {
                      user.savePricingData(subPrices);
                    }
                  }
                }
              } catch (err) {
                Sentry.captureException(err);
              }
              if (!userData.appointmentStartTime) {
                navigateTo("/no-appointments-confirmation&type=phone");
                return;
              }
              navigateTo("/confirmation");
            }
          }
        };
        if (userRebookingAppt) {
          await handleUserConfirmation();
        } else {
          await createHomeOwner(async (result) => {
            if (!result.ok) {
              utils.stopLoading(
                buttonId,
                buttonText,
                strings.accountCreationError,
              );
            } else {
              if (userData?.referredById !== undefined) {
                await operations.createReferral(
                  userData.referredById,
                  result.val,
                );
              }
              await handleUserConfirmation();
            }
          });
        }
      }
    }
  }
}

export async function confirmAppointment(div: HTMLElement) {
  if (utils.divContainsALoader(div)) {
    // Confirm appointment request is already in flight
    return;
  }

  const container = document.querySelector(".selected");
  if (!container) {
    return;
  }

  const times = utils.appointmentTimesFrom(container as HTMLElement);
  if (!times) {
    utils.displayErrorMessage(strings.appointmentTimeError);
    return;
  }

  user.saveAppointmentDetails(times);

  const marketingLeadIdOption = user.getMarketingLeadId();
  if (marketingLeadIdOption.some) {
    const userDetails = user.getUserData();
    if (userDetails) {
      const marketingLeadId = marketingLeadIdOption.val;
      let marketingLeadData: operations.MarketingLead = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber,
        email: userDetails.email,
        streetAddress: userDetails.streetAddress,
        city: userDetails.city,
        state: userDetails.state,
        zipCode: userDetails.zipCode,
        organicSource: userDetails.organicSource,
      };
      marketingLeadData.firstAppointmentEndsAt = times[1];
      marketingLeadData.firstAppointmentBeginsAt = times[0];
      const utmParameterDataOption = user.getUTMParameterData();
      if (utmParameterDataOption.some) {
        marketingLeadData = {
          ...marketingLeadData,
          ...utmParameterDataOption.val,
        };
      }
      await operations.updateMarketingLead(marketingLeadId, marketingLeadData);
    }
  }

  const userData = user.getUserData();
  if (userData) {
    const buttonID = "confirm";
    const buttonValue = utils.startLoading(buttonID);
    const signInAttempt = await firebase.attemptSignin(
      firebase.firebaseAuth,
      userData.phoneNumber,
      "recaptcha-container",
    );
    if (signInAttempt.ok) {
      const zipCodeStatusOption = user.getZipCodeStatus();
      if (
        zipCodeStatusOption.some &&
        zipCodeStatusOption.val === "waitlisted"
      ) {
        navigateTo("/waitlist-verify-phone-number");
      } else {
        navigateTo("/verify-phone-number");
      }
    } else {
      Sentry.captureException(signInAttempt.val);
      utils.stopLoading(buttonID, buttonValue, strings.genericError);
    }
  }
}

async function registerAppointment(
  tokens: utils.Tokens,
  times: string[],
  buttonId: string,
  buttonText: string | undefined,
) {
  const createRes = await operations.createAppointment(
    tokens.homeId,
    times[0],
    times[1],
    tokens.accessToken,
  );
  if (createRes.ok) {
    user.storeAppointment(times[0], times[1]);
    const availabilityResultId = utils.getAvailabilityResultId();
    if (availabilityResultId) {
      const res = await operations.updateAvailabilityResult(
        availabilityResultId,
        createRes.val,
        tokens.accessToken,
      );
      return res;
    } else {
      return Ok.EMPTY;
    }
  } else {
    utils.stopLoading(buttonId, buttonText, strings.appointmentCreationError);
    if (createRes.val._tag === "OverlappingAppointmentError") {
      user.saveUserState(user.USER_STATES.REBOOKING);
      navigateTo("/schedule-appointment?error=overlapping-appointment");
    } else {
      Sentry.captureException(createRes.val);
    }
    return Err(createRes.val);
  }
}

export async function setContactPreference(element: HTMLElement) {
  const buttonID = "submitContactPreference";
  const buttonValue = utils.startLoading(buttonID);

  const type = element.getAttribute("data-type");
  if (!type) {
    utils.stopLoading(
      buttonID,
      buttonValue,
      strings.determineContactPreferenceError,
    );
    return;
  }

  user.saveContactPreference(type);

  const userData = user.getUserData();
  if (userData) {
    const signInAttempt = await firebase.attemptSignin(
      firebase.firebaseAuth,
      userData.phoneNumber,
      "recaptcha-container",
    );
    if (signInAttempt.ok) {
      const zipCodeStatusOption = user.getZipCodeStatus();
      if (
        zipCodeStatusOption.some &&
        zipCodeStatusOption.val === "waitlisted"
      ) {
        navigateTo("/waitlist-verify-phone-number");
      } else {
        navigateTo("/verify-phone-number");
      }
    } else {
      Sentry.captureException(signInAttempt.val);
      utils.stopLoading(buttonID, buttonValue, strings.genericError);
      return;
    }
  } else {
    utils.stopLoading(buttonID, buttonValue, strings.missingUserDataError);
    return;
  }
}
