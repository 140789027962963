import { templates } from "../main";

export function abstractView(
  templateName: string,
  templateData: { [key: string]: string | number | object } = {},
) {
  const rawTemplate = templates[templateName] ?? templates["NotFoundView"];
  let template = "";
  if (rawTemplate) {
    template = mergeTemplate(rawTemplate, templateData);
  } else {
    template = "There was an error.";
  }

  return {
    setTitle(title: string) {
      document.title = title;
    },
    getHtml() {
      return template;
    },
    onload() {
      return;
    },
  };
}

function mergeTemplate(
  template: string,
  data: { [key: string]: string | number | object },
): string {
  // Check for an included wiget, only handles a single include-widget per template.
  template = mergeWidgetsIfRequired(template);

  // Replace Variables
  for (const key in data) {
    const value = data[key];

    if (typeof value === "string") {
      template = populateTemplateVariable(template, key, value);
    }

    if (typeof value === "object") {
      template = populateLoopedTemplateVariable(template, key, value);
    }
  }

  // After processing all passed variables, remove any left over {{ vars }}
  const re = RegExp("{{.?.*?.?}}", "gi");
  template = template.replace(re, "");

  return template;
}

function populateTemplateVariable(
  template: string,
  key: string,
  value: string,
): string {
  // Simple string replace for passed variables, looks for {{ key }} to replace in the template
  const re = RegExp("{{.?" + key + ".?}}", "gi");
  template = template.replace(re, value);
  return template;
}

function populateLoopedTemplateVariable(
  template: string,
  key: string,
  data: object,
): string {
  const array = Object.values(data);

  // Get our loopable content
  const re = RegExp("{{.?loop " + key + ".?}}(.*){{.?end_loop.?}}", "gis");
  const match = re.exec(template);
  if (match) {
    const loopableContent = match[1];
    let builtHtml = "";
    // Iterate through the object keys and match those to the template variables, they can also be inner loops
    // which will recursive call populateLoopedTemplateVariable
    array.forEach((ele) => {
      let singleLoop = loopableContent;
      for (const objKey of Object.keys(ele)) {
        if (typeof ele[objKey as keyof typeof Object] === "object") {
          singleLoop = populateLoopedTemplateVariable(
            singleLoop,
            objKey,
            ele[objKey as keyof typeof ele],
          );
        } else {
          singleLoop = populateTemplateVariable(
            singleLoop,
            objKey,
            ele[objKey as keyof typeof ele],
          );
        }
      }
      builtHtml += singleLoop;
    });

    const replaceRE = RegExp(
      "{{.?loop " + key + ".?}}.*{{.?end_loop.?}}",
      "gis",
    );
    template = template.replace(replaceRE, builtHtml);
  }
  return template;
}

function mergeWidgetsIfRequired(template: string): string {
  const widgetsRE = RegExp("{{.?include-widget (\\w+).?}}", "gi");
  const widgetMatches = widgetsRE.exec(template);
  if (widgetMatches) {
    const widget = templates[widgetMatches[1]];
    if (widget) {
      template = template.replace(widgetsRE, widget);
    }
  }

  // Remove any left over {{ include-widget <Template> }} tags
  const re = RegExp("{{.?include-widget \\w+.?}}", "gi");
  template = template.replace(re, "");
  return template;
}
