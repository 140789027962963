import SmartySDK from "smartystreets-javascript-sdk";
import { Result, Ok, Err } from "ts-results";

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;

export type AddressSuggestion = {
  streetLine: string;
  city: string;
  state: string;
  zipcode: string;
  streetAddress: string;
  secondary?: string;
};

const key = "196298760893307022";
const credentials = new SmartyCore.SharedCredentials(key);

// The appropriate license values to be used for your subscriptions
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
  "us-autocomplete-pro-cloud",
]);
const client = clientBuilder.buildUsAutocompleteProClient();

// Documentation
// https://github.com/smarty/smartystreets-javascript-sdk/blob/master/examples/us_autocomplete_pro.js
export async function handleAddressLookup(
  address: string,
): Promise<Result<Array<AddressSuggestion>, Error>> {
  try {
    const lookup = new Lookup(address);
    lookup.maxResults = 5;
    // This has to be here because there's a bug with the
    // Smarty types that doesn't allow for this to be set
    // with the correct casing
    /* eslint-disable */
    /* @ts-ignore */
    lookup.prefer_geolocation = "true";
    const results = await client.send(lookup);
    const response = results.result;
    const addressSuggestions: AddressSuggestion[] = response.map(
      (suggestion) => ({
        streetLine: `${suggestion.streetLine}${suggestion.secondary && ", " + suggestion.secondary}`,
        city: suggestion.city,
        state: suggestion.state,
        zipcode: suggestion.zipcode,
        streetAddress: `${suggestion.streetLine}${suggestion.secondary && ", " + suggestion.secondary}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`,
        secondary: suggestion.secondary,
      }),
    );
    return Ok(addressSuggestions);
  } catch (err) {
    console.error(err);
    return Err(err as Error);
  }
}
