import { landingView } from "../views/LandingView";
import { phoneNumberVerificationView } from "../views/PhoneNumberVerificationView";
import { neighborhoodView } from "../views/NeighborhoodView";
import { notFoundView } from "../views/NotFoundView";
import { scheduleAppointmentView } from "../views/ScheduleAppointmentView";
import { waitListView } from "../views/WaitListView";
import { noAppointmentsView } from "../views/NoAppointmentsView";
import { noAppointmentsConfirmationView } from "../views/NoAppointmentsConfirmationView";
import { existingCustomerView } from "../views/ExistingCustomerView";
import { confirmationView } from "../views/ConfirmationView";
import { personalInfoView } from "../views/PersonalInfoView";
import { zipCodeRouter } from "../views/ZipCodeRouter";
import { hearAboutUsView } from "../views/HearAboutUsView";
import { getAccessToken } from "./Firebase";

// All the app routes, a P4 page is a page that is at the end of a user flow and wipes session storage
const routes = [
  { path: "/", view: landingView, pageRequiresAuth: false, isP4Page: false },
  {
    path: "/zip-code-router",
    view: zipCodeRouter,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/neighborhood",
    view: neighborhoodView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/personal-info",
    view: personalInfoView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/waitlist-personal-info",
    view: personalInfoView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/schedule-appointment",
    view: scheduleAppointmentView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/no-appointments",
    view: noAppointmentsView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/verify-phone-number",
    view: phoneNumberVerificationView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/discovery",
    view: hearAboutUsView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/waitlist-verify-phone-number",
    view: phoneNumberVerificationView,
    pageRequiresAuth: false,
    isP4Page: false,
  },
  {
    path: "/waiting-list",
    view: waitListView,
    pageRequiresAuth: true,
    isP4Page: true,
  },
  {
    path: "/no-appointments-confirmation",
    view: noAppointmentsConfirmationView,
    pageRequiresAuth: true,
    isP4Page: true,
  },
  {
    path: "/existing-customer",
    view: existingCustomerView,
    pageRequiresAuth: true,
    isP4Page: true,
  },
  {
    path: "/confirmation",
    view: confirmationView,
    pageRequiresAuth: true,
    isP4Page: true,
  },
];

export function navigateTo(url: string) {
  // Replace the current page state to track the next page the user views
  history.replaceState({ nextUrl: url }, "", location.pathname);
  // Add the next page to state
  history.pushState(null, "", url);
  router();
}

export function router(fromPopState = false) {
  const potentialMatches = routes.map((route) => {
    return {
      route: route,
      isMatch: location.pathname === route.path,
    };
  });

  let match = potentialMatches.find((potentialMatch) => potentialMatch.isMatch);

  if (!match) {
    match = {
      route: {
        path: "",
        view: notFoundView,
        pageRequiresAuth: false,
        isP4Page: false,
      },
      isMatch: true,
    };
  }
  // Route back to the landing page if user isn't allowed to view screen
  if (!userCanViewPage(match.route.pageRequiresAuth, fromPopState)) {
    navigateTo("/");
    return;
  }

  const view = match.route.view();
  const app = document.querySelector("#app");
  if (app) {
    app.innerHTML = view.getHtml();
    view.onload();
    window.scrollTo(0, 0);
  }
}

// Check if the page requires Auth or that the user hit the browser back button from a P4
// page, which is an end state of a user flow. We store the nextUrl in the page state in order
// to know the page the user is coming back from.
function userCanViewPage(
  pageRequiresAuth: boolean,
  fromPopState: boolean,
): boolean {
  let isReturnFromP4Screen = false;
  if (fromPopState && history.state !== null) {
    isReturnFromP4Screen = urlIsP4Page(history.state.nextUrl);
  }

  if (pageRequiresAuth || isReturnFromP4Screen) {
    const token = getAccessToken();
    return token !== null;
  }

  return true;
}

function urlIsP4Page(url: string): boolean {
  const route = routes.find((possibleRoute) => possibleRoute.path === url);
  if (!route) {
    return false;
  }
  return route.isP4Page;
}
