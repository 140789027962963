/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Appointment:{
		AppointmentGrades:{
			distinct_on:{
				type:"AppointmentGrade_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGrade_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProviders:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProviders_aggregate:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Tasks:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Tasks_aggregate:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppointmentGrade:{
		AppointmentGradeSelections:{
			distinct_on:{
				type:"AppointmentGradeSelection_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGradeSelection_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelection_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppointmentGradeSelectionCategory_bool_exp:{
		ImageAssetAttachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		VectorAssetAttachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AppointmentGradeSelectionCategory_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AppointmentGradeSelectionCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AppointmentGradeSelectionCategory_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayText:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelectionCategory_order_by:{
		ImageAssetAttachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		VectorAssetAttachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayText:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelectionCategory_select_column: "enum",
	AppointmentGradeSelectionCategory_stream_cursor_input:{
		initial_value:{
			type:"AppointmentGradeSelectionCategory_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelectionCategory_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"AppointmentGradeSelection_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"AppointmentGradeSelection_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_arr_rel_insert_input:{
		data:{
			type:"AppointmentGradeSelection_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"AppointmentGradeSelection_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_bool_exp:{
		AppointmentGrade:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGradeSelectionCategory:{
			type:"AppointmentGradeSelectionCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AppointmentGradeSelection_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AppointmentGradeSelection_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AppointmentGradeSelection_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentGradeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_constraint: "enum",
	AppointmentGradeSelection_insert_input:{
		AppointmentGrade:{
			type:"AppointmentGrade_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isPositive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_max_order_by:{
		appointmentGradeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_min_order_by:{
		appointmentGradeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_on_conflict:{
		constraint:{
			type:"AppointmentGradeSelection_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"AppointmentGradeSelection_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"AppointmentGradeSelection_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_order_by:{
		AppointmentGrade:{
			type:"AppointmentGrade_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGradeSelectionCategory:{
			type:"AppointmentGradeSelectionCategory_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_select_column: "enum",
	AppointmentGradeSelection_stream_cursor_input:{
		initial_value:{
			type:"AppointmentGradeSelection_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_stream_cursor_value_input:{
		appointmentGradeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentGradeSelectionCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGradeSelection_update_column: "enum",
	AppointmentGrade_aggregate_order_by:{
		avg:{
			type:"AppointmentGrade_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"AppointmentGrade_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"AppointmentGrade_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"AppointmentGrade_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"AppointmentGrade_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"AppointmentGrade_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"AppointmentGrade_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"AppointmentGrade_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"AppointmentGrade_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"AppointmentGrade_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_arr_rel_insert_input:{
		data:{
			type:"AppointmentGrade_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"AppointmentGrade_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_avg_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGradeSelections:{
			type:"AppointmentGradeSelection_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AppointmentGrade_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AppointmentGrade_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_constraint: "enum",
	AppointmentGrade_insert_input:{
		Appointment:{
			type:"Appointment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGradeSelections:{
			type:"AppointmentGradeSelection_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_max_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_min_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_obj_rel_insert_input:{
		data:{
			type:"AppointmentGrade_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"AppointmentGrade_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_on_conflict:{
		constraint:{
			type:"AppointmentGrade_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"AppointmentGrade_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGradeSelections_aggregate:{
			type:"AppointmentGradeSelection_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_select_column: "enum",
	AppointmentGrade_stddev_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_stddev_pop_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_stddev_samp_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_stream_cursor_input:{
		initial_value:{
			type:"AppointmentGrade_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_stream_cursor_value_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		negativeComments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		positiveComments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_sum_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_update_column: "enum",
	AppointmentGrade_var_pop_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_var_samp_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentGrade_variance_order_by:{
		score:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_aggregate_bool_exp:{
		count:{
			type:"AppointmentProvider_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_aggregate_bool_exp_count:{
		arguments:{
			type:"AppointmentProvider_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"AppointmentProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppointmentProvider_aggregate_fields:{
		count:{
			columns:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppointmentProvider_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"AppointmentProvider_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"AppointmentProvider_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_arr_rel_insert_input:{
		data:{
			type:"AppointmentProvider_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"AppointmentProvider_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProvider:{
			type:"ServiceProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AppointmentProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AppointmentProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AppointmentProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_constraint: "enum",
	AppointmentProvider_insert_input:{
		Appointment:{
			type:"Appointment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_max_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_min_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_on_conflict:{
		constraint:{
			type:"AppointmentProvider_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"AppointmentProvider_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"AppointmentProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProvider:{
			type:"ServiceProvider_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_select_column: "enum",
	AppointmentProvider_stream_cursor_input:{
		initial_value:{
			type:"AppointmentProvider_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_stream_cursor_value_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentProvider_update_column: "enum",
	AppointmentReport:{
		TaskUpdateLogs:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogs_aggregate:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AppointmentReport_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"AppointmentReport_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"AppointmentReport_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ReviewedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AppointmentReport_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AppointmentReport_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AppointmentReport_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewCompletedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewedById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_max_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewCompletedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_min_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewCompletedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ReviewedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewCompletedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_select_column: "enum",
	AppointmentReport_stream_cursor_input:{
		initial_value:{
			type:"AppointmentReport_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AppointmentReport_stream_cursor_value_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewCompletedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewedById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_aggregate_bool_exp:{
		bool_and:{
			type:"Appointment_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"Appointment_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Appointment_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Appointment_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Appointment_aggregate_bool_exp_count:{
		arguments:{
			type:"Appointment_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Appointment_aggregate_fields:{
		count:{
			columns:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Appointment_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Appointment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Appointment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_arr_rel_insert_input:{
		data:{
			type:"Appointment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"Appointment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_bool_exp:{
		AppointmentGrades:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders:{
			type:"AppointmentProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders_aggregate:{
			type:"AppointmentProvider_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentReport:{
			type:"AppointmentReport_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks_aggregate:{
			type:"Task_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Appointment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Appointment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		beginsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		isWindowTimes:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_constraint: "enum",
	Appointment_insert_input:{
		AppointmentGrades:{
			type:"AppointmentGrade_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders:{
			type:"AppointmentProvider_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks:{
			type:"Task_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		usesDefaultProvider:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_max_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_min_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_obj_rel_insert_input:{
		data:{
			type:"Appointment_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"Appointment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_on_conflict:{
		constraint:{
			type:"Appointment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Appointment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_order_by:{
		AppointmentGrades_aggregate:{
			type:"AppointmentGrade_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders_aggregate:{
			type:"AppointmentProvider_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentReport:{
			type:"AppointmentReport_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks_aggregate:{
			type:"Task_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		isWindowTimes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Appointment_select_column: "enum",
	Appointment_select_column_Appointment_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	Appointment_select_column_Appointment_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	Appointment_set_input:{
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_stream_cursor_input:{
		initial_value:{
			type:"Appointment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_stream_cursor_value_input:{
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isWindowTimes:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Appointment_update_column: "enum",
	Appointment_updates:{
		_set:{
			type:"Appointment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Attachment:{
		HomeInventoryItemAttachments:{
			distinct_on:{
				type:"HomeInventoryItemAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProfilePictureFor:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProfilePictureFor_aggregate:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachments:{
			distinct_on:{
				type:"TaskAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogAttachments:{
			distinct_on:{
				type:"TaskUpdateLogAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLogAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Attachment_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Attachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Attachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_arr_rel_insert_input:{
		data:{
			type:"Attachment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"Attachment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_bool_exp:{
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor_aggregate:{
			type:"Person_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments:{
			type:"TaskAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Attachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		caption:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_constraint: "enum",
	Attachment_insert_input:{
		CreatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments:{
			type:"HomeInventoryItemAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor:{
			type:"Person_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments:{
			type:"TaskAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments:{
			type:"TaskUpdateLogAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_max_order_by:{
		caption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_min_order_by:{
		caption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_obj_rel_insert_input:{
		data:{
			type:"Attachment_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"Attachment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_on_conflict:{
		constraint:{
			type:"Attachment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Attachment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_order_by:{
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments_aggregate:{
			type:"HomeInventoryItemAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePictureFor_aggregate:{
			type:"Person_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments_aggregate:{
			type:"TaskAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments_aggregate:{
			type:"TaskUpdateLogAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Attachment_select_column: "enum",
	Attachment_set_input:{
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_stream_cursor_input:{
		initial_value:{
			type:"Attachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_stream_cursor_value_input:{
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		publicUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Attachment_update_column: "enum",
	Attachment_updates:{
		_set:{
			type:"Attachment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AvailabilityResult:{
		data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	AvailabilityResultSource_enum: "enum",
	AvailabilityResultSource_enum_comparison_exp:{
		_eq:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"AvailabilityResultSource_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"AvailabilityResultSource_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AvailabilityResult_append_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"AvailabilityResult_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AvailabilityResult_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AvailabilityResult_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		creationSource:{
			type:"CreationSourceType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"AvailabilityResultSource_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_delete_at_path_input:{
		data:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AvailabilityResult_delete_elem_input:{
		data:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_delete_key_input:{
		data:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_insert_input:{
		Appointment:{
			type:"Appointment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		creationSource:{
			type:"CreationSourceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creationSource:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_prepend_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_select_column: "enum",
	AvailabilityResult_set_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_stream_cursor_input:{
		initial_value:{
			type:"AvailabilityResult_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_stream_cursor_value_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		creationSource:{
			type:"CreationSourceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"AvailabilityResultSource_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailabilityResult_updates:{
		_append:{
			type:"AvailabilityResult_append_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_at_path:{
			type:"AvailabilityResult_delete_at_path_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_elem:{
			type:"AvailabilityResult_delete_elem_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_key:{
			type:"AvailabilityResult_delete_key_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_prepend:{
			type:"AvailabilityResult_prepend_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"AvailabilityResult_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"AvailabilityResult_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Availability_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Availability_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Availability_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Availability_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Availability_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Availability_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		beginsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"geometry_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_max_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_min_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_select_column: "enum",
	Availability_stream_cursor_input:{
		initial_value:{
			type:"Availability_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Availability_stream_cursor_value_input:{
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceArea:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailableAppointment_bool_exp:{
		_and:{
			type:"AvailableAppointment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"AvailableAppointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"AvailableAppointment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		beginsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailableAppointment_order_by:{
		beginsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailableAppointment_select_column: "enum",
	AvailableAppointment_stream_cursor_input:{
		initial_value:{
			type:"AvailableAppointment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvailableAppointment_stream_cursor_value_input:{
		beginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Boolean_comparison_exp:{
		_eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ClientRelease_bool_exp:{
		_and:{
			type:"ClientRelease_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ClientRelease_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ClientRelease_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		build:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		client:{
			type:"ClientType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationDate:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationMessage:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		releaseDate:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientRelease_order_by:{
		build:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		client:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationMessage:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		releaseDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientRelease_select_column: "enum",
	ClientRelease_stream_cursor_input:{
		initial_value:{
			type:"ClientRelease_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientRelease_stream_cursor_value_input:{
		build:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		client:{
			type:"ClientType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationDate:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expirationMessage:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		releaseDate:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientType_bool_exp:{
		_and:{
			type:"ClientType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ClientType_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ClientType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientType_enum: "enum",
	ClientType_enum_comparison_exp:{
		_eq:{
			type:"ClientType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"ClientType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"ClientType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"ClientType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ClientType_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientType_select_column: "enum",
	ClientType_stream_cursor_input:{
		initial_value:{
			type:"ClientType_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ClientType_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_bool_exp:{
		_and:{
			type:"ContentBlock_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ContentBlock_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ContentBlock_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		body:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_select_column: "enum",
	ContentBlock_stream_cursor_input:{
		initial_value:{
			type:"ContentBlock_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlock_stream_cursor_value_input:{
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CreationSourceType_enum: "enum",
	CreationSourceType_enum_comparison_exp:{
		_eq:{
			type:"CreationSourceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"CreationSourceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"CreationSourceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"CreationSourceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	DataDeletionRequest_bool_exp:{
		_and:{
			type:"DataDeletionRequest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"DataDeletionRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"DataDeletionRequest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_constraint: "enum",
	DataDeletionRequest_insert_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_on_conflict:{
		constraint:{
			type:"DataDeletionRequest_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"DataDeletionRequest_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"DataDeletionRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_select_column: "enum",
	DataDeletionRequest_stream_cursor_input:{
		initial_value:{
			type:"DataDeletionRequest_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DataDeletionRequest_update_column: "enum",
	DeviceType_enum: "enum",
	DeviceType_enum_comparison_exp:{
		_eq:{
			type:"DeviceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"DeviceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"DeviceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"DeviceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Device_aggregate_bool_exp:{
		count:{
			type:"Device_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_aggregate_bool_exp_count:{
		arguments:{
			type:"Device_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Device_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Device_aggregate_fields:{
		count:{
			columns:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Device_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Device_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Device_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_arr_rel_insert_input:{
		data:{
			type:"Device_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"Device_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Device_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Device_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Device_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appBuildNumber:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"DeviceType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_constraint: "enum",
	Device_insert_input:{
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appBuildNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"DeviceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_max_order_by:{
		appBuildNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_min_order_by:{
		appBuildNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_on_conflict:{
		constraint:{
			type:"Device_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Device_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Device_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appBuildNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Device_select_column: "enum",
	Device_set_input:{
		appBuildNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"DeviceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_stream_cursor_input:{
		initial_value:{
			type:"Device_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_stream_cursor_value_input:{
		appBuildNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		brand:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		model:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		osVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"DeviceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		uniqueIdentifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Device_update_column: "enum",
	Device_updates:{
		_set:{
			type:"Device_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Device_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ExternalIdentifier_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ExternalIdentifier_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ExternalIdentifier_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ExternalIdentifier_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		androidIntercomDigest:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		iosIntercomDigest:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdToken:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ExternalIdentifier_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		androidIntercomDigest:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		iosIntercomDigest:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdToken:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ExternalIdentifier_select_column: "enum",
	ExternalIdentifier_stream_cursor_input:{
		initial_value:{
			type:"ExternalIdentifier_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ExternalIdentifier_stream_cursor_value_input:{
		androidIntercomDigest:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		iosIntercomDigest:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion:{
		GeographicRegionHandymen:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhoods:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Homes:{
			distinct_on:{
				type:"Home_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Home_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GeographicRegionHandyman_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionHandyman_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionHandyman_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionHandyman_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		isPrimary:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Handyman:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		isPrimary:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_select_column: "enum",
	GeographicRegionHandyman_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionHandyman_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionHandyman_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isPrimary:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"GeographicRegionNeighborhood_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"GeographicRegionNeighborhood_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_bool_exp:{
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_order_by:{
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_select_column: "enum",
	GeographicRegionNeighborhood_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegionNeighborhood_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegionNeighborhood_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_bool_exp:{
		GeographicRegionHandymen:{
			type:"GeographicRegionHandyman_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods:{
			type:"GeographicRegionNeighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Homes:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"GeographicRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		availableStartingAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String_array_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_by_zipCode_args:{
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_order_by:{
		GeographicRegionHandymen_aggregate:{
			type:"GeographicRegionHandyman_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegionNeighborhoods_aggregate:{
			type:"GeographicRegionNeighborhood_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Homes_aggregate:{
			type:"Home_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_select_column: "enum",
	GeographicRegion_stream_cursor_input:{
		initial_value:{
			type:"GeographicRegion_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GeographicRegion_stream_cursor_value_input:{
		availableStartingAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicedZipCodes:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home:{
		AcceptedReferrals:{
			distinct_on:{
				type:"Referral_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Referral_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Referral_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGrades:{
			distinct_on:{
				type:"AppointmentGrade_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGrade_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Appointments:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Appointments_aggregate:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItems:{
			distinct_on:{
				type:"HomeInventoryItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvitedReferrals:{
			distinct_on:{
				type:"Referral_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Referral_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Referral_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonHomes:{
			distinct_on:{
				type:"PersonHome_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonHome_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonHome_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponses:{
			distinct_on:{
				type:"ServiceOfferResponse_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponse_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripePaymentMethods:{
			distinct_on:{
				type:"StripePaymentMethod_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripePaymentMethod_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripePaymentMethod_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripeSubscriptions:{
			distinct_on:{
				type:"StripeSubscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripeSubscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripeSubscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Tasks:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Tasks_aggregate:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HomeInventoryItem:{
		HomeInventoryItemAttachments:{
			distinct_on:{
				type:"HomeInventoryItemAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HomeInventoryItemAttachment_aggregate_order_by:{
		avg:{
			type:"HomeInventoryItemAttachment_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"HomeInventoryItemAttachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"HomeInventoryItemAttachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"HomeInventoryItemAttachment_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"HomeInventoryItemAttachment_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"HomeInventoryItemAttachment_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"HomeInventoryItemAttachment_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"HomeInventoryItemAttachment_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"HomeInventoryItemAttachment_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"HomeInventoryItemAttachment_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_arr_rel_insert_input:{
		data:{
			type:"HomeInventoryItemAttachment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"HomeInventoryItemAttachment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_avg_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_bool_exp:{
		Attachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategoryPrompt:{
			type:"HomeInventoryItemCategoryPrompt_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		attachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_constraint: "enum",
	HomeInventoryItemAttachment_inc_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_insert_input:{
		Attachment:{
			type:"Attachment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_max_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_min_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_on_conflict:{
		constraint:{
			type:"HomeInventoryItemAttachment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"HomeInventoryItemAttachment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_order_by:{
		Attachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategoryPrompt:{
			type:"HomeInventoryItemCategoryPrompt_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryItemAttachment_select_column: "enum",
	HomeInventoryItemAttachment_set_input:{
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_stddev_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_stddev_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_stddev_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryItemAttachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_stream_cursor_value_input:{
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryCategoryPromptId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_sum_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_update_column: "enum",
	HomeInventoryItemAttachment_updates:{
		_inc:{
			type:"HomeInventoryItemAttachment_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"HomeInventoryItemAttachment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryItemAttachment_var_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_var_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemAttachment_variance_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategory:{
		HomeInventoryItemCategoryIcons:{
			distinct_on:{
				type:"HomeInventoryItemCategoryIcon_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryIcon_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryIcon_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryPrompts:{
			distinct_on:{
				type:"HomeInventoryItemCategoryPrompt_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryPrompt_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryPrompt_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItems:{
			distinct_on:{
				type:"HomeInventoryItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HomeInventoryItemCategoryIcon_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"HomeInventoryItemCategoryIcon_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"HomeInventoryItemCategoryIcon_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_bool_exp:{
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ImageAssetAttachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		VectorAssetAttachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryItemCategoryIcon_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryItemCategoryIcon_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryItemCategoryIcon_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_order_by:{
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ImageAssetAttachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		VectorAssetAttachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_select_column: "enum",
	HomeInventoryItemCategoryIcon_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryItemCategoryIcon_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryIcon_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageAssetAttachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vectorAssetAttachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt:{
		HomeInventoryItemAttachments:{
			distinct_on:{
				type:"HomeInventoryItemAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	HomeInventoryItemCategoryPrompt_aggregate_order_by:{
		avg:{
			type:"HomeInventoryItemCategoryPrompt_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"HomeInventoryItemCategoryPrompt_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"HomeInventoryItemCategoryPrompt_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"HomeInventoryItemCategoryPrompt_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"HomeInventoryItemCategoryPrompt_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"HomeInventoryItemCategoryPrompt_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"HomeInventoryItemCategoryPrompt_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"HomeInventoryItemCategoryPrompt_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"HomeInventoryItemCategoryPrompt_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"HomeInventoryItemCategoryPrompt_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_avg_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_bool_exp:{
		ExampleAttachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryItemCategoryPrompt_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryItemCategoryPrompt_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryItemCategoryPrompt_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		body:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultCaption:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		exampleAttachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		inputHint:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_max_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultCaption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		exampleAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		inputHint:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_min_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultCaption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		exampleAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		inputHint:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_order_by:{
		ExampleAttachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments_aggregate:{
			type:"HomeInventoryItemAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultCaption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		exampleAttachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		inputHint:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_select_column: "enum",
	HomeInventoryItemCategoryPrompt_stddev_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_stddev_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_stddev_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryItemCategoryPrompt_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_stream_cursor_value_input:{
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultCaption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		exampleAttachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		friendlyAssetId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		inputHint:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_sum_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_var_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_var_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategoryPrompt_variance_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategory_bool_exp:{
		HomeInventoryItemCategoryIcons:{
			type:"HomeInventoryItemCategoryIcon_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategoryPrompts:{
			type:"HomeInventoryItemCategoryPrompt_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItems:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceDescription:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartPartyResponsible:{
			type:"TaskPartPartyResponsible_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartsRequired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategory_order_by:{
		HomeInventoryItemCategoryIcons_aggregate:{
			type:"HomeInventoryItemCategoryIcon_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategoryPrompts_aggregate:{
			type:"HomeInventoryItemCategoryPrompt_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItems_aggregate:{
			type:"HomeInventoryItem_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartPartyResponsible:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartsRequired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategory_select_column: "enum",
	HomeInventoryItemCategory_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryItemCategory_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItemCategory_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartPartyResponsible:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePartsRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_aggregate_order_by:{
		avg:{
			type:"HomeInventoryItem_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"HomeInventoryItem_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"HomeInventoryItem_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"HomeInventoryItem_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"HomeInventoryItem_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"HomeInventoryItem_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"HomeInventoryItem_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"HomeInventoryItem_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"HomeInventoryItem_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"HomeInventoryItem_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_avg_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments:{
			type:"HomeInventoryItemAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryItem_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryItem_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		isArchived:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_constraint: "enum",
	HomeInventoryItem_inc_input:{
		serviceFrequency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_insert_input:{
		HomeInventoryItemAttachments:{
			type:"HomeInventoryItemAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isArchived:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_obj_rel_insert_input:{
		data:{
			type:"HomeInventoryItem_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"HomeInventoryItem_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_on_conflict:{
		constraint:{
			type:"HomeInventoryItem_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"HomeInventoryItem_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemAttachments_aggregate:{
			type:"HomeInventoryItemAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItemCategory:{
			type:"HomeInventoryItemCategory_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		isArchived:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryItem_select_column: "enum",
	HomeInventoryItem_set_input:{
		homeInventoryItemCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isArchived:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_stddev_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_stddev_pop_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_stddev_samp_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryItem_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemCategoryId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isArchived:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastServicedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceFrequency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProvider:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceType:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_sum_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_update_column: "enum",
	HomeInventoryItem_updates:{
		_inc:{
			type:"HomeInventoryItem_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"HomeInventoryItem_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryItem_var_pop_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_var_samp_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryItem_variance_order_by:{
		serviceFrequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryMaintenanceSchedule_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeInventoryMaintenanceSchedule_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryMaintenanceSchedule_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryMaintenanceSchedule_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		dueBy:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryMaintenanceSchedule_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryMaintenanceSchedule_select_column: "enum",
	HomeInventoryMaintenanceSchedule_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryMaintenanceSchedule_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryMaintenanceSchedule_stream_cursor_value_input:{
		dueBy:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceProvider_bool_exp:{
		_and:{
			type:"HomeInventoryServiceProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryServiceProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryServiceProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceProvider_enum: "enum",
	HomeInventoryServiceProvider_enum_comparison_exp:{
		_eq:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"HomeInventoryServiceProvider_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"HomeInventoryServiceProvider_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"HomeInventoryServiceProvider_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryServiceProvider_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceProvider_select_column: "enum",
	HomeInventoryServiceProvider_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryServiceProvider_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceProvider_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceType_bool_exp:{
		_and:{
			type:"HomeInventoryServiceType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeInventoryServiceType_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeInventoryServiceType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceType_enum: "enum",
	HomeInventoryServiceType_enum_comparison_exp:{
		_eq:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"HomeInventoryServiceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"HomeInventoryServiceType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"HomeInventoryServiceType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	HomeInventoryServiceType_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceType_select_column: "enum",
	HomeInventoryServiceType_stream_cursor_input:{
		initial_value:{
			type:"HomeInventoryServiceType_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeInventoryServiceType_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeStatus_enum: "enum",
	HomeStatus_enum_comparison_exp:{
		_eq:{
			type:"HomeStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"HomeStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"HomeStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"HomeStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	HomeWaitlistReason_enum: "enum",
	HomeWaitlist_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"HomeWaitlist_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"HomeWaitlist_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"HomeWaitlist_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_constraint: "enum",
	HomeWaitlist_insert_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"HomeWaitlistReason_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_on_conflict:{
		constraint:{
			type:"HomeWaitlist_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"HomeWaitlist_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"HomeWaitlist_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_select_column: "enum",
	HomeWaitlist_stream_cursor_input:{
		initial_value:{
			type:"HomeWaitlist_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HomeWaitlist_update_column: "enum",
	Home_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Home_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Home_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_bool_exp:{
		AcceptedReferrals:{
			type:"Referral_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGrades:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Appointments:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Appointments_aggregate:{
			type:"Appointment_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegion:{
			type:"GeographicRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItems:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		InvitedReferrals:{
			type:"Referral_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonHomes:{
			type:"PersonHome_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		SchedulingRegion:{
			type:"Region_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferResponses:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		StripePaymentMethods:{
			type:"StripePaymentMethod_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		StripeSubscriptions:{
			type:"StripeSubscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks_aggregate:{
			type:"Task_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Home_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Home_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		activeMember:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"geography_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedulingRegionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdChannelId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"HomeStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeDefaultSourcePresent:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeZone:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_max_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedulingRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdChannelId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_min_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedulingRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdChannelId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_order_by:{
		AcceptedReferrals_aggregate:{
			type:"Referral_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentGrades_aggregate:{
			type:"AppointmentGrade_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Appointments_aggregate:{
			type:"Appointment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		GeographicRegion:{
			type:"GeographicRegion_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItems_aggregate:{
			type:"HomeInventoryItem_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		InvitedReferrals_aggregate:{
			type:"Referral_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonHomes_aggregate:{
			type:"PersonHome_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		SchedulingRegion:{
			type:"Region_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferResponses_aggregate:{
			type:"ServiceOfferResponse_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		StripePaymentMethods_aggregate:{
			type:"StripePaymentMethod_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		StripeSubscriptions_aggregate:{
			type:"StripeSubscription_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Tasks_aggregate:{
			type:"Task_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		activeMember:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedulingRegionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdChannelId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeDefaultSourcePresent:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Home_select_column: "enum",
	Home_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		organicSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_stream_cursor_input:{
		initial_value:{
			type:"Home_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_stream_cursor_value_input:{
		activeMember:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		geographicRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedulingRegionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		sendbirdChannelId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"HomeStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeDefaultSourcePresent:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePriceId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripePromoCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeZone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableEndingAt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableStartingAt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiCredentialsE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Home_updates:{
		_set:{
			type:"Home_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Int_comparison_exp:{
		_eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	MarketingLead_bool_exp:{
		_and:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"MarketingLead_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MarketingLead_select_column: "enum",
	MarketingLead_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentBeginsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstAppointmentEndsAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeAccountCreated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumberVerified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_input:{
		initial_value:{
			type:"MarketingLead_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingLead_updates:{
		_set:{
			type:"MarketingLead_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"MarketingLead_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Neighborhood:{
		NeighborhoodRegions:{
			distinct_on:{
				type:"NeighborhoodRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"NeighborhoodRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"NeighborhoodRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	NeighborhoodRegionType_enum: "enum",
	NeighborhoodRegionType_enum_comparison_exp:{
		_eq:{
			type:"NeighborhoodRegionType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"NeighborhoodRegionType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"NeighborhoodRegionType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"NeighborhoodRegionType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	NeighborhoodRegion_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"NeighborhoodRegion_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"NeighborhoodRegion_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_bool_exp:{
		Neighborhood:{
			type:"Neighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Region:{
			type:"Region_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"NeighborhoodRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"NeighborhoodRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"NeighborhoodRegion_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		neighborhoodId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"NeighborhoodRegionType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		neighborhoodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		neighborhoodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_order_by:{
		Neighborhood:{
			type:"Neighborhood_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Region:{
			type:"Region_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		neighborhoodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_select_column: "enum",
	NeighborhoodRegion_stream_cursor_input:{
		initial_value:{
			type:"NeighborhoodRegion_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NeighborhoodRegion_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		neighborhoodId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"NeighborhoodRegionType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Neighborhood_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Neighborhood_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_bool_exp:{
		NeighborhoodRegions:{
			type:"NeighborhoodRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Neighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Neighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Neighborhood_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		availableStartingAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		handymanPersonId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_max_order_by:{
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handymanPersonId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_min_order_by:{
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handymanPersonId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_order_by:{
		NeighborhoodRegions_aggregate:{
			type:"NeighborhoodRegion_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		availableStartingAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handymanPersonId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_select_column: "enum",
	Neighborhood_stream_cursor_input:{
		initial_value:{
			type:"Neighborhood_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Neighborhood_stream_cursor_value_input:{
		availableStartingAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		handymanPersonId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		zipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person:{
		AppointmentGrades:{
			distinct_on:{
				type:"AppointmentGrade_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGrade_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProviders:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProviders_aggregate:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentReports:{
			distinct_on:{
				type:"AppointmentReport_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentReport_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentReport_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedAppointmentReports:{
			distinct_on:{
				type:"AppointmentReport_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentReport_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentReport_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedAppointments:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedAppointments_aggregate:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedAttachments:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedTasks:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CreatedTasks_aggregate:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Devices:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Devices_aggregate:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Neighborhoods:{
			distinct_on:{
				type:"Neighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Neighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Neighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonBiographyItems:{
			distinct_on:{
				type:"PersonBiographyItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonBiographyItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonBiographyItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonHomes:{
			distinct_on:{
				type:"PersonHome_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonHome_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonHome_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComments:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComments_aggregate:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogs:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogs_aggregate:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PersonBiographyItem_aggregate_order_by:{
		avg:{
			type:"PersonBiographyItem_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"PersonBiographyItem_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"PersonBiographyItem_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"PersonBiographyItem_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"PersonBiographyItem_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"PersonBiographyItem_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"PersonBiographyItem_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"PersonBiographyItem_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"PersonBiographyItem_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"PersonBiographyItem_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_arr_rel_insert_input:{
		data:{
			type:"PersonBiographyItem_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"PersonBiographyItem_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_avg_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"PersonBiographyItem_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"PersonBiographyItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"PersonBiographyItem_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_constraint: "enum",
	PersonBiographyItem_inc_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_insert_input:{
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_on_conflict:{
		constraint:{
			type:"PersonBiographyItem_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"PersonBiographyItem_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"PersonBiographyItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	PersonBiographyItem_select_column: "enum",
	PersonBiographyItem_set_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_stddev_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_stddev_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_stddev_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_stream_cursor_input:{
		initial_value:{
			type:"PersonBiographyItem_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_sum_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_update_column: "enum",
	PersonBiographyItem_updates:{
		_inc:{
			type:"PersonBiographyItem_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"PersonBiographyItem_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"PersonBiographyItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	PersonBiographyItem_var_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_var_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonBiographyItem_variance_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonContactPreference_enum: "enum",
	PersonContactPreference_enum_comparison_exp:{
		_eq:{
			type:"PersonContactPreference_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"PersonContactPreference_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"PersonContactPreference_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"PersonContactPreference_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	PersonHome_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"PersonHome_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"PersonHome_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_arr_rel_insert_input:{
		data:{
			type:"PersonHome_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"PersonHome_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"PersonHome_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"PersonHome_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"PersonHome_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_constraint: "enum",
	PersonHome_insert_input:{
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_max_order_by:{
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_min_order_by:{
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_on_conflict:{
		constraint:{
			type:"PersonHome_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"PersonHome_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"PersonHome_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_select_column: "enum",
	PersonHome_stream_cursor_input:{
		initial_value:{
			type:"PersonHome_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_stream_cursor_value_input:{
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PersonHome_update_column: "enum",
	PersonType_enum: "enum",
	PersonType_enum_comparison_exp:{
		_eq:{
			type:"PersonType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"PersonType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"PersonType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"PersonType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Person_aggregate_bool_exp:{
		count:{
			type:"Person_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_aggregate_bool_exp_count:{
		arguments:{
			type:"Person_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Person_aggregate_fields:{
		count:{
			columns:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Person_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Person_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Person_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_arr_rel_insert_input:{
		data:{
			type:"Person_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"Person_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_bool_exp:{
		AppointmentGrades:{
			type:"AppointmentGrade_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders:{
			type:"AppointmentProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders_aggregate:{
			type:"AppointmentProvider_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentReports:{
			type:"AppointmentReport_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Availability:{
			type:"Availability_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointmentReports:{
			type:"AppointmentReport_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointments:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointments_aggregate:{
			type:"Appointment_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAttachments:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedTasks:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedTasks_aggregate:{
			type:"Task_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Devices:{
			type:"Device_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Devices_aggregate:{
			type:"Device_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ExternalIdentifiers:{
			type:"ExternalIdentifier_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Neighborhoods:{
			type:"Neighborhood_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonBiographyItems:{
			type:"PersonBiographyItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonHomes:{
			type:"PersonHome_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		PrimaryHome:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskComments:{
			type:"TaskComment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskComments_aggregate:{
			type:"TaskComment_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Person_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		biography:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactPreference:{
			type:"PersonContactPreference_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"PersonType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_constraint: "enum",
	Person_insert_input:{
		AppointmentGrades:{
			type:"AppointmentGrade_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders:{
			type:"AppointmentProvider_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointments:{
			type:"Appointment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAttachments:{
			type:"Attachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedTasks:{
			type:"Task_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Devices:{
			type:"Device_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonBiographyItems:{
			type:"PersonBiographyItem_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonHomes:{
			type:"PersonHome_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskComments:{
			type:"TaskComment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs:{
			type:"TaskUpdateLog_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_max_order_by:{
		biography:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_min_order_by:{
		biography:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_obj_rel_insert_input:{
		data:{
			type:"Person_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"Person_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_on_conflict:{
		constraint:{
			type:"Person_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Person_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_order_by:{
		AppointmentGrades_aggregate:{
			type:"AppointmentGrade_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentProviders_aggregate:{
			type:"AppointmentProvider_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		AppointmentReports_aggregate:{
			type:"AppointmentReport_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Availability_aggregate:{
			type:"Availability_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointmentReports_aggregate:{
			type:"AppointmentReport_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAppointments_aggregate:{
			type:"Appointment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedAttachments_aggregate:{
			type:"Attachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedTasks_aggregate:{
			type:"Task_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Devices_aggregate:{
			type:"Device_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ExternalIdentifiers:{
			type:"ExternalIdentifier_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Neighborhoods_aggregate:{
			type:"Neighborhood_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonBiographyItems_aggregate:{
			type:"PersonBiographyItem_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		PersonHomes_aggregate:{
			type:"PersonHome_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		PrimaryHome:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ProfilePicture:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskComments_aggregate:{
			type:"TaskComment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		biography:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactPreference:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Person_select_column: "enum",
	Person_set_input:{
		biography:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactPreference:{
			type:"PersonContactPreference_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		profilePictureId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_stream_cursor_input:{
		initial_value:{
			type:"Person_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_stream_cursor_value_input:{
		biography:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactPreference:{
			type:"PersonContactPreference_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentTimeZone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"PersonType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Person_update_column: "enum",
	Person_updates:{
		_set:{
			type:"Person_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ReferralProgram_bool_exp:{
		_and:{
			type:"ReferralProgram_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ReferralProgram_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ReferralProgram_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		creditAmount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		isActive:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_order_by:{
		creditAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		isActive:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_select_column: "enum",
	ReferralProgram_stream_cursor_input:{
		initial_value:{
			type:"ReferralProgram_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReferralProgram_stream_cursor_value_input:{
		creditAmount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		isActive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_aggregate_order_by:{
		avg:{
			type:"Referral_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Referral_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Referral_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"Referral_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"Referral_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"Referral_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"Referral_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"Referral_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"Referral_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"Referral_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_avg_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_bool_exp:{
		Accepted:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Invited:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ReferralProgram:{
			type:"ReferralProgram_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Referral_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Referral_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Referral_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		acceptedById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		creditAppliedToInviter:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		invitedById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_constraint: "enum",
	Referral_insert_input:{
		invitedById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_max_order_by:{
		acceptedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		invitedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_min_order_by:{
		acceptedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		invitedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_on_conflict:{
		constraint:{
			type:"Referral_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Referral_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Referral_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_order_by:{
		Accepted:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Invited:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ReferralProgram:{
			type:"ReferralProgram_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		acceptedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		invitedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_select_column: "enum",
	Referral_stddev_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_stddev_pop_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_stddev_samp_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_stream_cursor_input:{
		initial_value:{
			type:"Referral_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_stream_cursor_value_input:{
		acceptedById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		creditAppliedToInviter:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		invitedById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_sum_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_update_column: "enum",
	Referral_var_pop_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_var_samp_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Referral_variance_order_by:{
		creditAppliedToInviter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Region:{
		NeighborhoodRegions:{
			distinct_on:{
				type:"NeighborhoodRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"NeighborhoodRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"NeighborhoodRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SchedulingHomes:{
			distinct_on:{
				type:"Home_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Home_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Region_bool_exp:{
		NeighborhoodRegions:{
			type:"NeighborhoodRegion_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		SchedulingHomes:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Region_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Region_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Region_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Region_order_by:{
		NeighborhoodRegions_aggregate:{
			type:"NeighborhoodRegion_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		SchedulingHomes_aggregate:{
			type:"Home_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Region_select_column: "enum",
	Region_stream_cursor_input:{
		initial_value:{
			type:"Region_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Region_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer:{
		ServiceOfferResponses:{
			distinct_on:{
				type:"ServiceOfferResponse_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponse_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ServiceOfferResponseStatus:{
		ServiceOfferResponses:{
			distinct_on:{
				type:"ServiceOfferResponse_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponse_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ServiceOfferResponseStatus_bool_exp:{
		ServiceOfferResponses:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ServiceOfferResponseStatus_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceOfferResponseStatus_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceOfferResponseStatus_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponseStatus_enum: "enum",
	ServiceOfferResponseStatus_enum_comparison_exp:{
		_eq:{
			type:"ServiceOfferResponseStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"ServiceOfferResponseStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"ServiceOfferResponseStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"ServiceOfferResponseStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ServiceOfferResponseStatus_order_by:{
		ServiceOfferResponses_aggregate:{
			type:"ServiceOfferResponse_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponseStatus_select_column: "enum",
	ServiceOfferResponseStatus_stream_cursor_input:{
		initial_value:{
			type:"ServiceOfferResponseStatus_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponseStatus_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"ServiceOfferResponse_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"ServiceOfferResponse_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOffer:{
			type:"ServiceOffer_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferResponseStatus:{
			type:"ServiceOfferResponseStatus_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ServiceOfferResponse_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceOfferResponse_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"ServiceOfferResponseStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_constraint: "enum",
	ServiceOfferResponse_insert_input:{
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"ServiceOfferResponseStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_max_order_by:{
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_min_order_by:{
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_on_conflict:{
		constraint:{
			type:"ServiceOfferResponse_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"ServiceOfferResponse_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOffer:{
			type:"ServiceOffer_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferResponseStatus:{
			type:"ServiceOfferResponseStatus_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ServiceOfferResponse_select_column: "enum",
	ServiceOfferResponse_set_input:{
		status:{
			type:"ServiceOfferResponseStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_stream_cursor_input:{
		initial_value:{
			type:"ServiceOfferResponse_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_stream_cursor_value_input:{
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceOfferId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"ServiceOfferResponseStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferResponse_update_column: "enum",
	ServiceOfferResponse_updates:{
		_set:{
			type:"ServiceOfferResponse_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ServiceOfferType:{
		ServiceOffers:{
			distinct_on:{
				type:"ServiceOffer_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOffer_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOffer_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ServiceOfferType_bool_exp:{
		ServiceOffers:{
			type:"ServiceOffer_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ServiceOfferType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceOfferType_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceOfferType_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferType_order_by:{
		ServiceOffers_aggregate:{
			type:"ServiceOffer_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferType_select_column: "enum",
	ServiceOfferType_stream_cursor_input:{
		initial_value:{
			type:"ServiceOfferType_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOfferType_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_aggregate_order_by:{
		avg:{
			type:"ServiceOffer_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"ServiceOffer_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"ServiceOffer_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"ServiceOffer_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"ServiceOffer_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"ServiceOffer_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"ServiceOffer_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"ServiceOffer_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"ServiceOffer_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"ServiceOffer_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_avg_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_bool_exp:{
		ServiceOfferResponses:{
			type:"ServiceOfferResponse_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferType:{
			type:"ServiceOfferType_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ServiceOffer_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceOffer_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceOffer_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		additionalChargeAmount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtDate:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtTime:{
			type:"timetz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		confirmBy:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		costExplaination:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtDate:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtTime:{
			type:"timetz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresAdditionalCharge:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresConfirmation:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduleBy:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_max_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		confirmBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		costExplaination:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduleBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_min_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		confirmBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		costExplaination:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduleBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_order_by:{
		ServiceOfferResponses_aggregate:{
			type:"ServiceOfferResponse_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceOfferType:{
			type:"ServiceOfferType_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		confirmBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		costExplaination:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtDate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresAdditionalCharge:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresConfirmation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduleBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_select_column: "enum",
	ServiceOffer_stddev_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_stddev_pop_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_stddev_samp_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_stream_cursor_input:{
		initial_value:{
			type:"ServiceOffer_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_stream_cursor_value_input:{
		additionalChargeAmount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtDate:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		beginsAtTime:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		confirmBy:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		costExplaination:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtDate:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAtTime:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresAdditionalCharge:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresConfirmation:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduleBy:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_sum_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_var_pop_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_var_samp_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceOffer_variance_order_by:{
		additionalChargeAmount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDuration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequestStage_bool_exp:{
		_and:{
			type:"ServiceProviderRequestStage_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceProviderRequestStage_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceProviderRequestStage_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequestStage_enum: "enum",
	ServiceProviderRequestStage_enum_comparison_exp:{
		_eq:{
			type:"ServiceProviderRequestStage_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"ServiceProviderRequestStage_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"ServiceProviderRequestStage_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"ServiceProviderRequestStage_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	ServiceProviderRequestStage_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequestStage_select_column: "enum",
	ServiceProviderRequestStage_stream_cursor_input:{
		initial_value:{
			type:"ServiceProviderRequestStage_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequestStage_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"ServiceProviderRequest_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"ServiceProviderRequest_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_arr_rel_insert_input:{
		data:{
			type:"ServiceProviderRequest_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"ServiceProviderRequest_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProvider:{
			type:"ServiceProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"ServiceProviderRequest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceProviderRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceProviderRequest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stage:{
			type:"ServiceProviderRequestStage_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_constraint: "enum",
	ServiceProviderRequest_insert_input:{
		Appointment:{
			type:"Appointment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_obj_rel_insert_input:{
		data:{
			type:"ServiceProviderRequest_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"ServiceProviderRequest_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_on_conflict:{
		constraint:{
			type:"ServiceProviderRequest_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"ServiceProviderRequest_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"ServiceProviderRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProvider:{
			type:"ServiceProvider_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stage:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_select_column: "enum",
	ServiceProviderRequest_stream_cursor_input:{
		initial_value:{
			type:"ServiceProviderRequest_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_stream_cursor_value_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		stage:{
			type:"ServiceProviderRequestStage_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProviderRequest_update_column: "enum",
	ServiceProvider_bool_exp:{
		_and:{
			type:"ServiceProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"ServiceProvider_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"ServiceProvider_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProvider_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProvider_select_column: "enum",
	ServiceProvider_stream_cursor_input:{
		initial_value:{
			type:"ServiceProvider_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ServiceProvider_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	String_array_comparison_exp:{
		_contained_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_contains:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_eq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_gte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_lte:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_neq:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"StripePaymentMethod_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"StripePaymentMethod_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"StripePaymentMethod_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"StripePaymentMethod_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"StripePaymentMethod_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_select_column: "enum",
	StripePaymentMethod_stream_cursor_input:{
		initial_value:{
			type:"StripePaymentMethod_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripePaymentMethod_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscriptionStatus_enum: "enum",
	StripeSubscriptionStatus_enum_comparison_exp:{
		_eq:{
			type:"StripeSubscriptionStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"StripeSubscriptionStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"StripeSubscriptionStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"StripeSubscriptionStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	StripeSubscription_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"StripeSubscription_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"StripeSubscription_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_bool_exp:{
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"StripeSubscription_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"StripeSubscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"StripeSubscription_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		canceledAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		interval:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"StripeSubscriptionStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriptionId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_max_order_by:{
		canceledAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		interval:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriptionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_min_order_by:{
		canceledAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		interval:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriptionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_order_by:{
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		canceledAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		interval:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriptionId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_select_column: "enum",
	StripeSubscription_stream_cursor_input:{
		initial_value:{
			type:"StripeSubscription_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StripeSubscription_stream_cursor_value_input:{
		canceledAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		interval:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"StripeSubscriptionStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriptionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Stripe_Coupon_Duration: "enum",
	Task:{
		Comments:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Comments_aggregate:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequests:{
			distinct_on:{
				type:"ServiceProviderRequest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProviderRequest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachments:{
			distinct_on:{
				type:"TaskAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskParts:{
			distinct_on:{
				type:"TaskPart_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPart_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPart_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogs:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogs_aggregate:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TaskAttachment_aggregate_order_by:{
		avg:{
			type:"TaskAttachment_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"TaskAttachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"TaskAttachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"TaskAttachment_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"TaskAttachment_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"TaskAttachment_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"TaskAttachment_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"TaskAttachment_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"TaskAttachment_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"TaskAttachment_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_arr_rel_insert_input:{
		data:{
			type:"TaskAttachment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"TaskAttachment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_avg_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_bool_exp:{
		Attachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"TaskAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		attachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_constraint: "enum",
	TaskAttachment_inc_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_insert_input:{
		Attachment:{
			type:"Attachment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_max_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_min_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_on_conflict:{
		constraint:{
			type:"TaskAttachment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"TaskAttachment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"TaskAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_order_by:{
		Attachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskAttachment_select_column: "enum",
	TaskAttachment_set_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_stddev_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_stddev_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_stddev_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_stream_cursor_input:{
		initial_value:{
			type:"TaskAttachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_stream_cursor_value_input:{
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_sum_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_update_column: "enum",
	TaskAttachment_updates:{
		_inc:{
			type:"TaskAttachment_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"TaskAttachment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"TaskAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskAttachment_var_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_var_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskAttachment_variance_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_aggregate_bool_exp:{
		count:{
			type:"TaskComment_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_aggregate_bool_exp_count:{
		arguments:{
			type:"TaskComment_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"TaskComment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskComment_aggregate_fields:{
		count:{
			columns:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TaskComment_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"TaskComment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"TaskComment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_arr_rel_insert_input:{
		data:{
			type:"TaskComment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	TaskComment_bool_exp:{
		Person:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"TaskComment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskComment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskComment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		body:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_insert_input:{
		Person:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_max_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_min_order_by:{
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_order_by:{
		Person:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		body:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_select_column: "enum",
	TaskComment_set_input:{
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_stream_cursor_input:{
		initial_value:{
			type:"TaskComment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_stream_cursor_value_input:{
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		personId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskComment_updates:{
		_set:{
			type:"TaskComment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"TaskComment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskPartDeliveryLocation_enum: "enum",
	TaskPartDeliveryLocation_enum_comparison_exp:{
		_eq:{
			type:"TaskPartDeliveryLocation_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"TaskPartDeliveryLocation_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"TaskPartDeliveryLocation_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"TaskPartDeliveryLocation_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskPartPartyResponsible_bool_exp:{
		_and:{
			type:"TaskPartPartyResponsible_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskPartPartyResponsible_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskPartPartyResponsible_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartPartyResponsible_enum: "enum",
	TaskPartPartyResponsible_enum_comparison_exp:{
		_eq:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"TaskPartPartyResponsible_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"TaskPartPartyResponsible_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskPartPartyResponsible_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartPartyResponsible_select_column: "enum",
	TaskPartPartyResponsible_stream_cursor_input:{
		initial_value:{
			type:"TaskPartPartyResponsible_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartPartyResponsible_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartStatus_bool_exp:{
		_and:{
			type:"TaskPartStatus_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskPartStatus_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskPartStatus_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartStatus_enum: "enum",
	TaskPartStatus_enum_comparison_exp:{
		_eq:{
			type:"TaskPartStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"TaskPartStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"TaskPartStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"TaskPartStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskPartStatus_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartStatus_select_column: "enum",
	TaskPartStatus_stream_cursor_input:{
		initial_value:{
			type:"TaskPartStatus_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPartStatus_stream_cursor_value_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"TaskPart_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"TaskPart_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_arr_rel_insert_input:{
		data:{
			type:"TaskPart_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"TaskPart_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_bool_exp:{
		ActiveAgent:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		OrderedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"TaskPart_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskPart_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskPart_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDeliveryBy:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		memberStartedRequestAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partAcquiredAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveredAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveryLocation:{
			type:"TaskPartDeliveryLocation_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partOnHand:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partyResponsible:{
			type:"TaskPartPartyResponsible_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresReview:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskPartStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_constraint: "enum",
	TaskPart_insert_input:{
		ActiveAgent:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		OrderedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partOnHand:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		partyResponsible:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_max_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDeliveryBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		memberStartedRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partAcquiredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_min_order_by:{
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDeliveryBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		memberStartedRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partAcquiredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_obj_rel_insert_input:{
		data:{
			type:"TaskPart_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"TaskPart_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_on_conflict:{
		constraint:{
			type:"TaskPart_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"TaskPart_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"TaskPart_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_order_by:{
		ActiveAgent:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		OrderedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDeliveryBy:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		memberStartedRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partAcquiredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveredAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveryLocation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partOnHand:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partyResponsible:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresReview:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskPart_select_column: "enum",
	TaskPart_set_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partOnHand:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		partyResponsible:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_stream_cursor_input:{
		initial_value:{
			type:"TaskPart_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_stream_cursor_value_input:{
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedDeliveryBy:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		memberStartedRequestAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		partAcquiredAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveredAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		partDeliveryLocation:{
			type:"TaskPartDeliveryLocation_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		partName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partOnHand:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		partyResponsible:{
			type:"TaskPartPartyResponsible_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		personOrderedId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresReview:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		startedProcessingRequestAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskPartStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskPart_update_column: "enum",
	TaskPart_updates:{
		_set:{
			type:"TaskPart_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"TaskPart_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskStatus_enum: "enum",
	TaskStatus_enum_comparison_exp:{
		_eq:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"TaskStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"TaskStatus_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskType_enum: "enum",
	TaskType_enum_comparison_exp:{
		_eq:{
			type:"TaskType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"TaskType_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"TaskType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"TaskType_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLog:{
		TaskUpdateLogAttachments:{
			distinct_on:{
				type:"TaskUpdateLogAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLogAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TaskUpdateLogAttachment_aggregate_order_by:{
		avg:{
			type:"TaskUpdateLogAttachment_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"TaskUpdateLogAttachment_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"TaskUpdateLogAttachment_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"TaskUpdateLogAttachment_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"TaskUpdateLogAttachment_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"TaskUpdateLogAttachment_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"TaskUpdateLogAttachment_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"TaskUpdateLogAttachment_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"TaskUpdateLogAttachment_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"TaskUpdateLogAttachment_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_arr_rel_insert_input:{
		data:{
			type:"TaskUpdateLogAttachment_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"TaskUpdateLogAttachment_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_avg_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_bool_exp:{
		Attachment:{
			type:"Attachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLog:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		attachmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_constraint: "enum",
	TaskUpdateLogAttachment_inc_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_insert_input:{
		Attachment:{
			type:"Attachment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLog:{
			type:"TaskUpdateLog_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_max_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_min_order_by:{
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_on_conflict:{
		constraint:{
			type:"TaskUpdateLogAttachment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"TaskUpdateLogAttachment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_order_by:{
		Attachment:{
			type:"Attachment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLog:{
			type:"TaskUpdateLog_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLogAttachment_select_column: "enum",
	TaskUpdateLogAttachment_set_input:{
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_stddev_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_stddev_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_stddev_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_stream_cursor_input:{
		initial_value:{
			type:"TaskUpdateLogAttachment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_stream_cursor_value_input:{
		attachmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskUpdateLogId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_sum_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_update_column: "enum",
	TaskUpdateLogAttachment_updates:{
		_inc:{
			type:"TaskUpdateLogAttachment_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"TaskUpdateLogAttachment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLogAttachment_var_pop_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_var_samp_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLogAttachment_variance_order_by:{
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_aggregate_bool_exp:{
		bool_and:{
			type:"TaskUpdateLog_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"TaskUpdateLog_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"TaskUpdateLog_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLog_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLog_aggregate_bool_exp_count:{
		arguments:{
			type:"TaskUpdateLog_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLog_aggregate_fields:{
		count:{
			columns:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TaskUpdateLog_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"TaskUpdateLog_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"TaskUpdateLog_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_arr_rel_insert_input:{
		data:{
			type:"TaskUpdateLog_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"TaskUpdateLog_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_bool_exp:{
		AppointmentReport:{
			type:"AppointmentReport_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Author:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequest:{
			type:"ServiceProviderRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskPart:{
			type:"TaskPart_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments:{
			type:"TaskUpdateLogAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"TaskUpdateLog_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TaskUpdateLog_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentReportId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		authorId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ranOutOfTime:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_constraint: "enum",
	TaskUpdateLog_insert_input:{
		Author:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequest:{
			type:"ServiceProviderRequest_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskPart:{
			type:"TaskPart_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments:{
			type:"TaskUpdateLogAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_max_order_by:{
		appointmentReportId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		authorId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_min_order_by:{
		appointmentReportId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		authorId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_obj_rel_insert_input:{
		data:{
			type:"TaskUpdateLog_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"TaskUpdateLog_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_on_conflict:{
		constraint:{
			type:"TaskUpdateLog_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"TaskUpdateLog_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_order_by:{
		AppointmentReport:{
			type:"AppointmentReport_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Author:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequest:{
			type:"ServiceProviderRequest_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Task:{
			type:"Task_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskPart:{
			type:"TaskPart_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogAttachments_aggregate:{
			type:"TaskUpdateLogAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentReportId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		authorId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ranOutOfTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	TaskUpdateLog_select_column: "enum",
	TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	TaskUpdateLog_select_column_TaskUpdateLog_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	TaskUpdateLog_set_input:{
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_stream_cursor_input:{
		initial_value:{
			type:"TaskUpdateLog_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_stream_cursor_value_input:{
		appointmentReportId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		authorId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		ranOutOfTime:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceProviderRequestId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskPartId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TaskUpdateLog_update_column: "enum",
	TaskUpdateLog_updates:{
		_set:{
			type:"TaskUpdateLog_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_aggregate_bool_exp:{
		bool_and:{
			type:"Task_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"Task_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Task_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_aggregate_bool_exp_count:{
		arguments:{
			type:"Task_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_aggregate_fields:{
		count:{
			columns:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Task_aggregate_order_by:{
		avg:{
			type:"Task_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Task_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"Task_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"Task_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"Task_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"Task_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"Task_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"Task_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"Task_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"Task_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_arr_rel_insert_input:{
		data:{
			type:"Task_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"Task_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_avg_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_bool_exp:{
		Appointment:{
			type:"Appointment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Comments:{
			type:"TaskComment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Comments_aggregate:{
			type:"TaskComment_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequests:{
			type:"ServiceProviderRequest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments:{
			type:"TaskAttachment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskParts:{
			type:"TaskPart_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs:{
			type:"TaskUpdateLog_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"Task_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"Task_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		appointmentId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedCompletionTime:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastUpdatedById:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresOperationalReview:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"TaskType_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_constraint: "enum",
	Task_inc_input:{
		priorityLevel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_insert_input:{
		Appointment:{
			type:"Appointment_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		Comments:{
			type:"TaskComment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequests:{
			type:"ServiceProviderRequest_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments:{
			type:"TaskAttachment_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskParts:{
			type:"TaskPart_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs:{
			type:"TaskUpdateLog_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresOperationalReview:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"TaskType_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_max_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastUpdatedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_min_order_by:{
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastUpdatedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_obj_rel_insert_input:{
		data:{
			type:"Task_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"Task_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_on_conflict:{
		constraint:{
			type:"Task_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"Task_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_order_by:{
		Appointment:{
			type:"Appointment_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Comments_aggregate:{
			type:"TaskComment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		CreatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		Home:{
			type:"Home_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		HomeInventoryItem:{
			type:"HomeInventoryItem_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		LastUpdatedBy:{
			type:"Person_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ServiceProviderRequests_aggregate:{
			type:"ServiceProviderRequest_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskAttachments_aggregate:{
			type:"TaskAttachment_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskParts_aggregate:{
			type:"TaskPart_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TaskUpdateLogs_aggregate:{
			type:"TaskUpdateLog_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointmentId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastUpdatedById:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresOperationalReview:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_select_column: "enum",
	Task_select_column_Task_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	Task_select_column_Task_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	Task_set_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresOperationalReview:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_stddev_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_stddev_pop_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_stddev_samp_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_stream_cursor_input:{
		initial_value:{
			type:"Task_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_stream_cursor_value_input:{
		appointmentId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dueAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		estimatedCompletionTime:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		homeInventoryItemId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastUpdatedById:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentTaskId:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		partsRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresOperationalReview:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"TaskStatus_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		thirdPartyRequired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"TaskType_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_sum_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_update_column: "enum",
	Task_updates:{
		_inc:{
			type:"Task_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"Task_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"Task_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Task_var_pop_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_var_samp_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Task_variance_order_by:{
		estimatedCompletionTime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		priorityLevel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TimeZone_bool_exp:{
		_and:{
			type:"TimeZone_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"TimeZone_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"TimeZone_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		abbrev:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_dst:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		utc_offset:{
			type:"interval_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TimeZone_order_by:{
		abbrev:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_dst:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		utc_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TimeZone_select_column: "enum",
	TimeZone_stream_cursor_input:{
		initial_value:{
			type:"TimeZone_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TimeZone_stream_cursor_value_input:{
		abbrev:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_dst:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		utc_offset:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	create_Stripe_SubscriptionIntent_input:{
		couponId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		priceId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	cursor_ordering: "enum",
	date: "String",
	date_comparison_exp:{
		_eq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	find_Person_by_phone_args:{
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_bool_exp:{
		_and:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"find_Person_response_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"find_Person_response_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		present:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_order_by:{
		present:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_select_column: "enum",
	find_Person_response_stream_cursor_input:{
		initial_value:{
			type:"find_Person_response_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	find_Person_response_stream_cursor_value_input:{
		present:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geography: "String",
	geography_cast_exp:{
		geometry:{
			type:"geometry_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geography_comparison_exp:{
		_cast:{
			type:"geography_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"geography",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"geography",
			array:true,
			arrayRequired:false,
			required:true
		},
		_st_d_within:{
			type:"st_d_within_geography_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_intersects:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geometry: "String",
	geometry_cast_exp:{
		geography:{
			type:"geography_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	geometry_comparison_exp:{
		_cast:{
			type:"geometry_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"geometry",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"geometry",
			array:true,
			arrayRequired:false,
			required:true
		},
		_st_3d_d_within:{
			type:"st_d_within_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_3d_intersects:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_contains:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_crosses:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_d_within:{
			type:"st_d_within_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_equals:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_intersects:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_overlaps:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_touches:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		},
		_st_within:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	interval: "String",
	interval_comparison_exp:{
		_eq:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"interval",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"interval",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"interval",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	jsonb: "String",
	jsonb_cast_exp:{
		String:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	jsonb_comparison_exp:{
		_cast:{
			type:"jsonb_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_contained_in:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_contains:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_keys_all:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_has_keys_any:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	mutation_root:{
		create_Stripe_SubscriptionIntent:{
			object:{
				type:"create_Stripe_SubscriptionIntent_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Appointment:{
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_AppointmentProvider:{
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_AppointmentProvider_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Appointment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Attachment:{
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_HomeInventoryItemAttachment:{
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_HomeInventoryItemAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Task:{
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_TaskAttachment:{
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_TaskAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_Task_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		encryptDataHomeowner:{
			data:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_Appointment:{
			objects:{
				type:"Appointment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Appointment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentGrade:{
			objects:{
				type:"AppointmentGrade_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"AppointmentGrade_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentGradeSelection:{
			objects:{
				type:"AppointmentGradeSelection_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"AppointmentGradeSelection_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentGradeSelection_one:{
			object:{
				type:"AppointmentGradeSelection_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"AppointmentGradeSelection_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentGrade_one:{
			object:{
				type:"AppointmentGrade_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"AppointmentGrade_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentProvider:{
			objects:{
				type:"AppointmentProvider_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"AppointmentProvider_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AppointmentProvider_one:{
			object:{
				type:"AppointmentProvider_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"AppointmentProvider_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Appointment_one:{
			object:{
				type:"Appointment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Appointment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Attachment:{
			objects:{
				type:"Attachment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Attachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Attachment_one:{
			object:{
				type:"Attachment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Attachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_AvailabilityResult:{
			objects:{
				type:"AvailabilityResult_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_AvailabilityResult_one:{
			object:{
				type:"AvailabilityResult_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_DataDeletionRequest:{
			objects:{
				type:"DataDeletionRequest_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"DataDeletionRequest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_DataDeletionRequest_one:{
			object:{
				type:"DataDeletionRequest_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"DataDeletionRequest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Device:{
			objects:{
				type:"Device_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Device_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Device_one:{
			object:{
				type:"Device_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Device_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeInventoryItem:{
			objects:{
				type:"HomeInventoryItem_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"HomeInventoryItem_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeInventoryItemAttachment:{
			objects:{
				type:"HomeInventoryItemAttachment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"HomeInventoryItemAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeInventoryItemAttachment_one:{
			object:{
				type:"HomeInventoryItemAttachment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"HomeInventoryItemAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeInventoryItem_one:{
			object:{
				type:"HomeInventoryItem_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"HomeInventoryItem_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeWaitlist:{
			objects:{
				type:"HomeWaitlist_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"HomeWaitlist_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_HomeWaitlist_one:{
			object:{
				type:"HomeWaitlist_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"HomeWaitlist_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Person:{
			objects:{
				type:"Person_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Person_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_PersonBiographyItem:{
			objects:{
				type:"PersonBiographyItem_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"PersonBiographyItem_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_PersonBiographyItem_one:{
			object:{
				type:"PersonBiographyItem_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"PersonBiographyItem_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_PersonHome:{
			objects:{
				type:"PersonHome_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"PersonHome_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_PersonHome_one:{
			object:{
				type:"PersonHome_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"PersonHome_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Person_one:{
			object:{
				type:"Person_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Person_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Referral:{
			objects:{
				type:"Referral_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Referral_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Referral_one:{
			object:{
				type:"Referral_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Referral_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_ServiceOfferResponse:{
			objects:{
				type:"ServiceOfferResponse_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"ServiceOfferResponse_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_ServiceOfferResponse_one:{
			object:{
				type:"ServiceOfferResponse_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"ServiceOfferResponse_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_ServiceProviderRequest:{
			objects:{
				type:"ServiceProviderRequest_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"ServiceProviderRequest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_ServiceProviderRequest_one:{
			object:{
				type:"ServiceProviderRequest_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"ServiceProviderRequest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Task:{
			objects:{
				type:"Task_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"Task_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskAttachment:{
			objects:{
				type:"TaskAttachment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"TaskAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskAttachment_one:{
			object:{
				type:"TaskAttachment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"TaskAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskComment:{
			objects:{
				type:"TaskComment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_TaskComment_one:{
			object:{
				type:"TaskComment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_TaskPart:{
			objects:{
				type:"TaskPart_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"TaskPart_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskPart_one:{
			object:{
				type:"TaskPart_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"TaskPart_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskUpdateLog:{
			objects:{
				type:"TaskUpdateLog_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"TaskUpdateLog_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskUpdateLogAttachment:{
			objects:{
				type:"TaskUpdateLogAttachment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"TaskUpdateLogAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskUpdateLogAttachment_one:{
			object:{
				type:"TaskUpdateLogAttachment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"TaskUpdateLogAttachment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_TaskUpdateLog_one:{
			object:{
				type:"TaskUpdateLog_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"TaskUpdateLog_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_Task_one:{
			object:{
				type:"Task_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"Task_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		update_Appointment:{
			_set:{
				type:"Appointment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Appointment_by_pk:{
			_set:{
				type:"Appointment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Appointment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Appointment_many:{
			updates:{
				type:"Appointment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Attachment:{
			_set:{
				type:"Attachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Attachment_by_pk:{
			_set:{
				type:"Attachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Attachment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Attachment_many:{
			updates:{
				type:"Attachment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_AvailabilityResult:{
			_append:{
				type:"AvailabilityResult_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"AvailabilityResult_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"AvailabilityResult_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"AvailabilityResult_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"AvailabilityResult_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"AvailabilityResult_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_AvailabilityResult_many:{
			updates:{
				type:"AvailabilityResult_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Device:{
			_set:{
				type:"Device_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Device_by_pk:{
			_set:{
				type:"Device_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Device_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Device_many:{
			updates:{
				type:"Device_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Home:{
			_set:{
				type:"Home_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_HomeInventoryItem:{
			_inc:{
				type:"HomeInventoryItem_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"HomeInventoryItem_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_HomeInventoryItemAttachment:{
			_inc:{
				type:"HomeInventoryItemAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"HomeInventoryItemAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_HomeInventoryItemAttachment_by_pk:{
			_inc:{
				type:"HomeInventoryItemAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"HomeInventoryItemAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"HomeInventoryItemAttachment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_HomeInventoryItemAttachment_many:{
			updates:{
				type:"HomeInventoryItemAttachment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_HomeInventoryItem_by_pk:{
			_inc:{
				type:"HomeInventoryItem_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"HomeInventoryItem_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"HomeInventoryItem_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_HomeInventoryItem_many:{
			updates:{
				type:"HomeInventoryItem_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Home_by_pk:{
			_set:{
				type:"Home_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Home_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Home_many:{
			updates:{
				type:"Home_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_MarketingLead:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_by_pk:{
			_set:{
				type:"MarketingLead_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"MarketingLead_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_MarketingLead_many:{
			updates:{
				type:"MarketingLead_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Person:{
			_set:{
				type:"Person_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_PersonBiographyItem:{
			_inc:{
				type:"PersonBiographyItem_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"PersonBiographyItem_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"PersonBiographyItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_PersonBiographyItem_by_pk:{
			_inc:{
				type:"PersonBiographyItem_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"PersonBiographyItem_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"PersonBiographyItem_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_PersonBiographyItem_many:{
			updates:{
				type:"PersonBiographyItem_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Person_by_pk:{
			_set:{
				type:"Person_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Person_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Person_many:{
			updates:{
				type:"Person_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_ServiceOfferResponse:{
			_set:{
				type:"ServiceOfferResponse_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_ServiceOfferResponse_by_pk:{
			_set:{
				type:"ServiceOfferResponse_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"ServiceOfferResponse_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_ServiceOfferResponse_many:{
			updates:{
				type:"ServiceOfferResponse_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Task:{
			_inc:{
				type:"Task_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"Task_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskAttachment:{
			_inc:{
				type:"TaskAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"TaskAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskAttachment_by_pk:{
			_inc:{
				type:"TaskAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"TaskAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"TaskAttachment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskAttachment_many:{
			updates:{
				type:"TaskAttachment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_TaskComment:{
			_set:{
				type:"TaskComment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskComment_many:{
			updates:{
				type:"TaskComment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_TaskPart:{
			_set:{
				type:"TaskPart_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TaskPart_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskPart_by_pk:{
			_set:{
				type:"TaskPart_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"TaskPart_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskPart_many:{
			updates:{
				type:"TaskPart_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_TaskUpdateLog:{
			_set:{
				type:"TaskUpdateLog_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskUpdateLogAttachment:{
			_inc:{
				type:"TaskUpdateLogAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"TaskUpdateLogAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskUpdateLogAttachment_by_pk:{
			_inc:{
				type:"TaskUpdateLogAttachment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"TaskUpdateLogAttachment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"TaskUpdateLogAttachment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskUpdateLogAttachment_many:{
			updates:{
				type:"TaskUpdateLogAttachment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_TaskUpdateLog_by_pk:{
			_set:{
				type:"TaskUpdateLog_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"TaskUpdateLog_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_TaskUpdateLog_many:{
			updates:{
				type:"TaskUpdateLog_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_Task_by_pk:{
			_inc:{
				type:"Task_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"Task_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"Task_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_Task_many:{
			updates:{
				type:"Task_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		}
	},
	order_by: "enum",
	query_root:{
		Appointment:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGrade:{
			distinct_on:{
				type:"AppointmentGrade_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGrade_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelection:{
			distinct_on:{
				type:"AppointmentGradeSelection_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGradeSelection_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelection_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelectionCategory:{
			distinct_on:{
				type:"AppointmentGradeSelectionCategory_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGradeSelectionCategory_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelectionCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelectionCategory_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentGradeSelection_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentGrade_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentProvider:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProvider_aggregate:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProvider_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentReport:{
			distinct_on:{
				type:"AppointmentReport_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentReport_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentReport_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentReport_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Appointment_aggregate:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Appointment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult:{
			distinct_on:{
				type:"AvailabilityResult_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailabilityResult_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailableAppointment:{
			distinct_on:{
				type:"AvailableAppointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailableAppointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailableAppointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientRelease:{
			distinct_on:{
				type:"ClientRelease_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ClientRelease_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientRelease_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientRelease_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ClientType:{
			distinct_on:{
				type:"ClientType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ClientType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ContentBlock:{
			distinct_on:{
				type:"ContentBlock_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ContentBlock_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock_by_pk:{
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DataDeletionRequest:{
			distinct_on:{
				type:"DataDeletionRequest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"DataDeletionRequest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"DataDeletionRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DataDeletionRequest_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Device:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Device_aggregate:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Device_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ExternalIdentifier:{
			distinct_on:{
				type:"ExternalIdentifier_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ExternalIdentifier_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ExternalIdentifier_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ExternalIdentifier_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Home:{
			distinct_on:{
				type:"Home_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Home_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItem:{
			distinct_on:{
				type:"HomeInventoryItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemAttachment:{
			distinct_on:{
				type:"HomeInventoryItemAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategory:{
			distinct_on:{
				type:"HomeInventoryItemCategory_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategory_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryIcon:{
			distinct_on:{
				type:"HomeInventoryItemCategoryIcon_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryIcon_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryIcon_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryIcon_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategoryPrompt:{
			distinct_on:{
				type:"HomeInventoryItemCategoryPrompt_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryPrompt_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryPrompt_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryPrompt_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategory_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItem_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryMaintenanceSchedule:{
			distinct_on:{
				type:"HomeInventoryMaintenanceSchedule_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryMaintenanceSchedule_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryMaintenanceSchedule_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceProvider:{
			distinct_on:{
				type:"HomeInventoryServiceProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryServiceProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceProvider_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryServiceType:{
			distinct_on:{
				type:"HomeInventoryServiceType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryServiceType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeWaitlist:{
			distinct_on:{
				type:"HomeWaitlist_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeWaitlist_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeWaitlist_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeWaitlist_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Home_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Neighborhood:{
			distinct_on:{
				type:"Neighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Neighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Neighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		NeighborhoodRegion:{
			distinct_on:{
				type:"NeighborhoodRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"NeighborhoodRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"NeighborhoodRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		NeighborhoodRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Neighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonBiographyItem:{
			distinct_on:{
				type:"PersonBiographyItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonBiographyItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonBiographyItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonBiographyItem_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PersonHome:{
			distinct_on:{
				type:"PersonHome_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonHome_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonHome_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_aggregate:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Referral:{
			distinct_on:{
				type:"Referral_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Referral_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Referral_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram:{
			distinct_on:{
				type:"ReferralProgram_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ReferralProgram_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Referral_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Region:{
			distinct_on:{
				type:"Region_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Region_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Region_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Region_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOffer:{
			distinct_on:{
				type:"ServiceOffer_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOffer_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOffer_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponse:{
			distinct_on:{
				type:"ServiceOfferResponse_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponse_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponseStatus:{
			distinct_on:{
				type:"ServiceOfferResponseStatus_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponseStatus_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponseStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponseStatus_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOfferResponse_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOfferType:{
			distinct_on:{
				type:"ServiceOfferType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOffer_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProvider:{
			distinct_on:{
				type:"ServiceProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequest:{
			distinct_on:{
				type:"ServiceProviderRequest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProviderRequest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequestStage:{
			distinct_on:{
				type:"ServiceProviderRequestStage_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProviderRequestStage_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequestStage_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequestStage_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProviderRequest_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProvider_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		StripePaymentMethod:{
			distinct_on:{
				type:"StripePaymentMethod_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripePaymentMethod_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripePaymentMethod_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripePaymentMethod_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		StripeSubscription:{
			distinct_on:{
				type:"StripeSubscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripeSubscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripeSubscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripeSubscription_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Task:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachment:{
			distinct_on:{
				type:"TaskAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskComment:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComment_aggregate:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPart:{
			distinct_on:{
				type:"TaskPart_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPart_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPart_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartPartyResponsible:{
			distinct_on:{
				type:"TaskPartPartyResponsible_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPartPartyResponsible_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartPartyResponsible_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartPartyResponsible_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskPartStatus:{
			distinct_on:{
				type:"TaskPartStatus_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPartStatus_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartStatus_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskPart_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskUpdateLog:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogAttachment:{
			distinct_on:{
				type:"TaskUpdateLogAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLogAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskUpdateLog_aggregate:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLog_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Task_aggregate:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Task_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TimeZone:{
			distinct_on:{
				type:"TimeZone_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TimeZone_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TimeZone_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		check_Stripe_PromoCode:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		decryptMessageHomeowner:{
			message:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	smallint: "String",
	smallint_comparison_exp:{
		_eq:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"smallint",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"smallint",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	st_d_within_geography_input:{
		distance:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		from:{
			type:"geography",
			array:false,
			arrayRequired:false,
			required:true
		},
		use_spheroid:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	st_d_within_input:{
		distance:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		from:{
			type:"geometry",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	subscription_root:{
		Appointment:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGrade:{
			distinct_on:{
				type:"AppointmentGrade_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGrade_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelection:{
			distinct_on:{
				type:"AppointmentGradeSelection_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGradeSelection_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelection_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelectionCategory:{
			distinct_on:{
				type:"AppointmentGradeSelectionCategory_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentGradeSelectionCategory_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelectionCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelectionCategory_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentGradeSelectionCategory_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AppointmentGradeSelectionCategory_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelectionCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGradeSelection_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentGradeSelection_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AppointmentGradeSelection_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGradeSelection_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentGrade_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentGrade_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AppointmentGrade_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentGrade_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProvider:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProvider_aggregate:{
			distinct_on:{
				type:"AppointmentProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentProvider_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentProvider_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AppointmentProvider_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentReport:{
			distinct_on:{
				type:"AppointmentReport_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AppointmentReport_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentReport_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppointmentReport_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppointmentReport_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AppointmentReport_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AppointmentReport_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Appointment_aggregate:{
			distinct_on:{
				type:"Appointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Appointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Appointment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Appointment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Appointment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Appointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment:{
			distinct_on:{
				type:"Attachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Attachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Attachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Attachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Attachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Attachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Availability:{
			distinct_on:{
				type:"Availability_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Availability_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult:{
			distinct_on:{
				type:"AvailabilityResult_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailabilityResult_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailabilityResult_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AvailabilityResult_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailabilityResult_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Availability_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Availability_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Availability_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailableAppointment:{
			distinct_on:{
				type:"AvailableAppointment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"AvailableAppointment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailableAppointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AvailableAppointment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"AvailableAppointment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"AvailableAppointment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientRelease:{
			distinct_on:{
				type:"ClientRelease_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ClientRelease_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientRelease_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientRelease_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ClientRelease_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ClientRelease_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientRelease_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientType:{
			distinct_on:{
				type:"ClientType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ClientType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ClientType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ClientType_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ClientType_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ClientType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock:{
			distinct_on:{
				type:"ContentBlock_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ContentBlock_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ContentBlock_by_pk:{
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ContentBlock_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ContentBlock_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ContentBlock_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DataDeletionRequest:{
			distinct_on:{
				type:"DataDeletionRequest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"DataDeletionRequest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"DataDeletionRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DataDeletionRequest_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DataDeletionRequest_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"DataDeletionRequest_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"DataDeletionRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Device:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Device_aggregate:{
			distinct_on:{
				type:"Device_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Device_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Device_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Device_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Device_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Device_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ExternalIdentifier:{
			distinct_on:{
				type:"ExternalIdentifier_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ExternalIdentifier_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ExternalIdentifier_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ExternalIdentifier_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ExternalIdentifier_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ExternalIdentifier_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ExternalIdentifier_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion:{
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman:{
			distinct_on:{
				type:"GeographicRegionHandyman_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionHandyman_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionHandyman_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionHandyman_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionHandyman_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionHandyman_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood:{
			distinct_on:{
				type:"GeographicRegionNeighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegionNeighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegionNeighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegionNeighborhood_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegionNeighborhood_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegionNeighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GeographicRegion_by_zipCode:{
			args:{
				type:"GeographicRegion_by_zipCode_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"GeographicRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"GeographicRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GeographicRegion_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"GeographicRegion_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"GeographicRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Home:{
			distinct_on:{
				type:"Home_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Home_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItem:{
			distinct_on:{
				type:"HomeInventoryItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemAttachment:{
			distinct_on:{
				type:"HomeInventoryItemAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemAttachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryItemAttachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategory:{
			distinct_on:{
				type:"HomeInventoryItemCategory_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategory_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryIcon:{
			distinct_on:{
				type:"HomeInventoryItemCategoryIcon_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryIcon_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryIcon_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryIcon_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategoryIcon_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryItemCategoryIcon_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryIcon_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryPrompt:{
			distinct_on:{
				type:"HomeInventoryItemCategoryPrompt_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryItemCategoryPrompt_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryPrompt_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategoryPrompt_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategoryPrompt_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryItemCategoryPrompt_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategoryPrompt_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItemCategory_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItemCategory_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryItemCategory_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItemCategory_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryItem_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryItem_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryItem_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryMaintenanceSchedule:{
			distinct_on:{
				type:"HomeInventoryMaintenanceSchedule_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryMaintenanceSchedule_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryMaintenanceSchedule_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryMaintenanceSchedule_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryMaintenanceSchedule_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryMaintenanceSchedule_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceProvider:{
			distinct_on:{
				type:"HomeInventoryServiceProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryServiceProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceProvider_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryServiceProvider_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryServiceProvider_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceType:{
			distinct_on:{
				type:"HomeInventoryServiceType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeInventoryServiceType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeInventoryServiceType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeInventoryServiceType_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeInventoryServiceType_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeInventoryServiceType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeWaitlist:{
			distinct_on:{
				type:"HomeWaitlist_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"HomeWaitlist_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeWaitlist_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HomeWaitlist_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HomeWaitlist_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"HomeWaitlist_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"HomeWaitlist_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Home_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Home_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Home_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Home_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead:{
			distinct_on:{
				type:"MarketingLead_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"MarketingLead_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingLead_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MarketingLead_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"MarketingLead_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"MarketingLead_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Neighborhood:{
			distinct_on:{
				type:"Neighborhood_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Neighborhood_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Neighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		NeighborhoodRegion:{
			distinct_on:{
				type:"NeighborhoodRegion_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"NeighborhoodRegion_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"NeighborhoodRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		NeighborhoodRegion_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		NeighborhoodRegion_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"NeighborhoodRegion_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"NeighborhoodRegion_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Neighborhood_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Neighborhood_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Neighborhood_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Neighborhood_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonBiographyItem:{
			distinct_on:{
				type:"PersonBiographyItem_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonBiographyItem_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonBiographyItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonBiographyItem_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PersonBiographyItem_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"PersonBiographyItem_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonBiographyItem_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonHome:{
			distinct_on:{
				type:"PersonHome_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"PersonHome_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonHome_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PersonHome_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"PersonHome_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"PersonHome_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_aggregate:{
			distinct_on:{
				type:"Person_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Person_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Person_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Person_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Person_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Person_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Referral:{
			distinct_on:{
				type:"Referral_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Referral_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Referral_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram:{
			distinct_on:{
				type:"ReferralProgram_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ReferralProgram_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReferralProgram_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReferralProgram_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ReferralProgram_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ReferralProgram_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Referral_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Referral_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Referral_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Referral_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Region:{
			distinct_on:{
				type:"Region_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Region_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Region_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Region_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Region_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Region_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Region_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOffer:{
			distinct_on:{
				type:"ServiceOffer_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOffer_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOffer_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponse:{
			distinct_on:{
				type:"ServiceOfferResponse_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponse_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponseStatus:{
			distinct_on:{
				type:"ServiceOfferResponseStatus_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferResponseStatus_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponseStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponseStatus_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOfferResponseStatus_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceOfferResponseStatus_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponseStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferResponse_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOfferResponse_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceOfferResponse_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferResponse_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferType:{
			distinct_on:{
				type:"ServiceOfferType_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceOfferType_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOfferType_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOfferType_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceOfferType_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOfferType_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceOffer_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceOffer_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceOffer_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceOffer_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProvider:{
			distinct_on:{
				type:"ServiceProvider_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProvider_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequest:{
			distinct_on:{
				type:"ServiceProviderRequest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProviderRequest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequestStage:{
			distinct_on:{
				type:"ServiceProviderRequestStage_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"ServiceProviderRequestStage_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequestStage_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequestStage_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProviderRequestStage_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceProviderRequestStage_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequestStage_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProviderRequest_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProviderRequest_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceProviderRequest_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProviderRequest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceProvider_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceProvider_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"ServiceProvider_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"ServiceProvider_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripePaymentMethod:{
			distinct_on:{
				type:"StripePaymentMethod_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripePaymentMethod_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripePaymentMethod_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripePaymentMethod_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		StripePaymentMethod_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"StripePaymentMethod_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripePaymentMethod_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripeSubscription:{
			distinct_on:{
				type:"StripeSubscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"StripeSubscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripeSubscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		StripeSubscription_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		StripeSubscription_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"StripeSubscription_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"StripeSubscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Task:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachment:{
			distinct_on:{
				type:"TaskAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskAttachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskAttachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComment:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComment_aggregate:{
			distinct_on:{
				type:"TaskComment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskComment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskComment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskComment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskComment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPart:{
			distinct_on:{
				type:"TaskPart_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPart_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPart_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartPartyResponsible:{
			distinct_on:{
				type:"TaskPartPartyResponsible_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPartPartyResponsible_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartPartyResponsible_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartPartyResponsible_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskPartPartyResponsible_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskPartPartyResponsible_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartPartyResponsible_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartStatus:{
			distinct_on:{
				type:"TaskPartStatus_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskPartStatus_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPartStatus_by_pk:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskPartStatus_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskPartStatus_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPartStatus_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskPart_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskPart_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskPart_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskPart_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLog:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogAttachment:{
			distinct_on:{
				type:"TaskUpdateLogAttachment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLogAttachment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLogAttachment_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskUpdateLogAttachment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskUpdateLogAttachment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLogAttachment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLog_aggregate:{
			distinct_on:{
				type:"TaskUpdateLog_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TaskUpdateLog_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TaskUpdateLog_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TaskUpdateLog_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TaskUpdateLog_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TaskUpdateLog_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Task_aggregate:{
			distinct_on:{
				type:"Task_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"Task_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Task_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Task_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"Task_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"Task_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TimeZone:{
			distinct_on:{
				type:"TimeZone_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"TimeZone_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TimeZone_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TimeZone_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"TimeZone_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"TimeZone_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_by_phone:{
			args:{
				type:"find_Person_by_phone_args",
				array:false,
				arrayRequired:false,
				required:true
			},
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response:{
			distinct_on:{
				type:"find_Person_response_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"find_Person_response_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		find_Person_response_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"find_Person_response_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"find_Person_response_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	timetz: "String",
	timetz_comparison_exp:{
		_eq:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timetz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timetz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timetz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	Appointment:{
		AppointmentGrades:"AppointmentGrade",
		AppointmentProviders:"AppointmentProvider",
		AppointmentProviders_aggregate:"AppointmentProvider_aggregate",
		AppointmentReport:"AppointmentReport",
		CreatedBy:"Person",
		Home:"Home",
		Tasks:"Task",
		Tasks_aggregate:"Task_aggregate",
		beginsAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		endsAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		isWindowTimes:"Boolean",
		updatedAt:"timestamptz"
	},
	AppointmentGrade:{
		Appointment:"Appointment",
		AppointmentGradeSelections:"AppointmentGradeSelection",
		Home:"Home",
		Person:"Person",
		appointmentId:"uuid",
		createdAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		negativeComments:"String",
		personId:"uuid",
		positiveComments:"String",
		score:"smallint",
		updatedAt:"timestamptz"
	},
	AppointmentGradeSelection:{
		AppointmentGrade:"AppointmentGrade",
		AppointmentGradeSelectionCategory:"AppointmentGradeSelectionCategory",
		appointmentGradeId:"uuid",
		appointmentGradeSelectionCategoryId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		updatedAt:"timestamptz"
	},
	AppointmentGradeSelectionCategory:{
		ImageAssetAttachment:"Attachment",
		VectorAssetAttachment:"Attachment",
		createdAt:"timestamptz",
		description:"String",
		displayText:"String",
		friendlyAssetId:"String",
		id:"uuid",
		imageAssetAttachmentId:"uuid",
		updatedAt:"timestamptz",
		vectorAssetAttachmentId:"uuid"
	},
	AppointmentGradeSelection_mutation_response:{
		affected_rows:"Int",
		returning:"AppointmentGradeSelection"
	},
	AppointmentGrade_mutation_response:{
		affected_rows:"Int",
		returning:"AppointmentGrade"
	},
	AppointmentProvider:{
		Appointment:"Appointment",
		Person:"Person",
		ServiceProvider:"ServiceProvider",
		appointmentId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		serviceProviderId:"uuid",
		updatedAt:"timestamptz"
	},
	AppointmentProvider_aggregate:{
		aggregate:"AppointmentProvider_aggregate_fields",
		nodes:"AppointmentProvider"
	},
	AppointmentProvider_aggregate_fields:{
		count:"Int",
		max:"AppointmentProvider_max_fields",
		min:"AppointmentProvider_min_fields"
	},
	AppointmentProvider_max_fields:{
		appointmentId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		serviceProviderId:"uuid",
		updatedAt:"timestamptz"
	},
	AppointmentProvider_min_fields:{
		appointmentId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		serviceProviderId:"uuid",
		updatedAt:"timestamptz"
	},
	AppointmentProvider_mutation_response:{
		affected_rows:"Int",
		returning:"AppointmentProvider"
	},
	AppointmentReport:{
		Appointment:"Appointment",
		CreatedBy:"Person",
		ReviewedBy:"Person",
		TaskUpdateLogs:"TaskUpdateLog",
		TaskUpdateLogs_aggregate:"TaskUpdateLog_aggregate",
		appointmentId:"uuid",
		createdAt:"timestamptz",
		createdById:"uuid",
		id:"uuid",
		reviewCompletedAt:"timestamptz",
		reviewedById:"uuid",
		updatedAt:"timestamptz"
	},
	Appointment_aggregate:{
		aggregate:"Appointment_aggregate_fields",
		nodes:"Appointment"
	},
	Appointment_aggregate_fields:{
		count:"Int",
		max:"Appointment_max_fields",
		min:"Appointment_min_fields"
	},
	Appointment_max_fields:{
		beginsAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		endsAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz"
	},
	Appointment_min_fields:{
		beginsAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		endsAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz"
	},
	Appointment_mutation_response:{
		affected_rows:"Int",
		returning:"Appointment"
	},
	Attachment:{
		CreatedBy:"Person",
		HomeInventoryItemAttachments:"HomeInventoryItemAttachment",
		ProfilePictureFor:"Person",
		ProfilePictureFor_aggregate:"Person_aggregate",
		TaskAttachments:"TaskAttachment",
		TaskUpdateLogAttachments:"TaskUpdateLogAttachment",
		caption:"String",
		contentType:"String",
		createdAt:"timestamptz",
		createdById:"uuid",
		id:"uuid",
		publicUrl:"String",
		updatedAt:"timestamptz"
	},
	Attachment_mutation_response:{
		affected_rows:"Int",
		returning:"Attachment"
	},
	Availability:{
		Person:"Person",
		beginsAt:"timestamptz",
		endsAt:"timestamptz",
		personId:"uuid",
		serviceArea:"geometry"
	},
	AvailabilityResult:{
		Appointment:"Appointment",
		Home:"Home",
		appointmentId:"uuid",
		createdAt:"timestamptz",
		creationSource:"CreationSourceType_enum",
		data:"jsonb",
		homeId:"uuid",
		id:"uuid",
		source:"AvailabilityResultSource_enum",
		updatedAt:"timestamptz"
	},
	AvailabilityResult_mutation_response:{
		affected_rows:"Int",
		returning:"AvailabilityResult"
	},
	AvailableAppointment:{
		beginsAt:"timestamptz",
		endsAt:"timestamptz"
	},
	ClientRelease:{
		build:"String",
		client:"ClientType_enum",
		createdAt:"timestamptz",
		expirationDate:"timestamptz",
		expirationMessage:"String",
		id:"uuid",
		releaseDate:"timestamptz",
		updatedAt:"timestamptz",
		version:"String"
	},
	ClientType:{
		description:"String",
		value:"String"
	},
	ContentBlock:{
		body:"String",
		key:"String",
		updatedAt:"timestamptz"
	},
	DataDeletionRequest:{
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		updatedAt:"timestamptz"
	},
	DataDeletionRequest_mutation_response:{
		affected_rows:"Int",
		returning:"DataDeletionRequest"
	},
	DecryptedMessage:{
		data:"String"
	},
	Device:{
		Person:"Person",
		appBuildNumber:"String",
		brand:"String",
		createdAt:"timestamptz",
		id:"uuid",
		model:"String",
		osVersion:"String",
		personId:"uuid",
		type:"DeviceType_enum",
		uniqueIdentifier:"String",
		updatedAt:"timestamptz"
	},
	Device_aggregate:{
		aggregate:"Device_aggregate_fields",
		nodes:"Device"
	},
	Device_aggregate_fields:{
		count:"Int",
		max:"Device_max_fields",
		min:"Device_min_fields"
	},
	Device_max_fields:{
		appBuildNumber:"String",
		brand:"String",
		createdAt:"timestamptz",
		id:"uuid",
		model:"String",
		osVersion:"String",
		personId:"uuid",
		uniqueIdentifier:"String",
		updatedAt:"timestamptz"
	},
	Device_min_fields:{
		appBuildNumber:"String",
		brand:"String",
		createdAt:"timestamptz",
		id:"uuid",
		model:"String",
		osVersion:"String",
		personId:"uuid",
		uniqueIdentifier:"String",
		updatedAt:"timestamptz"
	},
	Device_mutation_response:{
		affected_rows:"Int",
		returning:"Device"
	},
	EncryptedMessage:{
		message:"String"
	},
	ExternalIdentifier:{
		Person:"Person",
		androidIntercomDigest:"String",
		createdAt:"timestamptz",
		id:"uuid",
		iosIntercomDigest:"String",
		personId:"uuid",
		sendbirdToken:"String",
		updatedAt:"timestamptz"
	},
	GeographicRegion:{
		GeographicRegionHandymen:"GeographicRegionHandyman",
		GeographicRegionNeighborhoods:"GeographicRegionNeighborhood",
		Homes:"Home",
		availableStartingAt:"timestamptz",
		createdAt:"timestamptz",
		id:"uuid",
		name:"String",
		servicedZipCodes:"String",
		updatedAt:"timestamptz"
	},
	GeographicRegionHandyman:{
		GeographicRegion:"GeographicRegion",
		Handyman:"Person",
		createdAt:"timestamptz",
		geographicRegionId:"uuid",
		id:"uuid",
		isPrimary:"Boolean",
		personId:"uuid",
		updatedAt:"timestamptz"
	},
	GeographicRegionNeighborhood:{
		GeographicRegion:"GeographicRegion",
		createdAt:"timestamptz",
		geographicRegionId:"uuid",
		id:"uuid",
		name:"String",
		updatedAt:"timestamptz",
		zipCode:"String"
	},
	Home:{
		AcceptedReferrals:"Referral",
		AppointmentGrades:"AppointmentGrade",
		Appointments:"Appointment",
		Appointments_aggregate:"Appointment_aggregate",
		GeographicRegion:"GeographicRegion",
		HomeInventoryItems:"HomeInventoryItem",
		InvitedReferrals:"Referral",
		PersonHomes:"PersonHome",
		SchedulingRegion:"Region",
		ServiceOfferResponses:"ServiceOfferResponse",
		StripePaymentMethods:"StripePaymentMethod",
		StripeSubscriptions:"StripeSubscription",
		Tasks:"Task",
		Tasks_aggregate:"Task_aggregate",
		activeMember:"Boolean",
		city:"String",
		createdAt:"timestamptz",
		geographicRegionId:"uuid",
		id:"uuid",
		location:"geography",
		referralCode:"String",
		schedulingRegionId:"uuid",
		sendbirdChannelId:"String",
		state:"String",
		status:"HomeStatus_enum",
		streetAddress:"String",
		stripeDefaultSourcePresent:"Boolean",
		stripeId:"String",
		stripePriceId:"String",
		stripePromoCode:"String",
		timeZone:"String",
		unavailableEndingAt:"date",
		unavailableStartingAt:"date",
		updatedAt:"timestamptz",
		wifiCredentialsE:"String",
		zipCode:"String"
	},
	HomeInventoryItem:{
		Home:"Home",
		HomeInventoryItemAttachments:"HomeInventoryItemAttachment",
		HomeInventoryItemCategory:"HomeInventoryItemCategory",
		createdAt:"timestamptz",
		homeId:"uuid",
		homeInventoryItemCategoryId:"uuid",
		id:"uuid",
		isArchived:"Boolean",
		lastServicedAt:"timestamptz",
		name:"String",
		notes:"String",
		serviceFrequency:"Int",
		serviceProvider:"HomeInventoryServiceProvider_enum",
		serviceType:"HomeInventoryServiceType_enum",
		updatedAt:"timestamptz"
	},
	HomeInventoryItemAttachment:{
		Attachment:"Attachment",
		HomeInventoryItem:"HomeInventoryItem",
		HomeInventoryItemCategoryPrompt:"HomeInventoryItemCategoryPrompt",
		attachmentId:"uuid",
		homeInventoryCategoryPromptId:"uuid",
		homeInventoryItemId:"uuid",
		id:"uuid",
		rank:"Int"
	},
	HomeInventoryItemAttachment_mutation_response:{
		affected_rows:"Int",
		returning:"HomeInventoryItemAttachment"
	},
	HomeInventoryItemCategory:{
		HomeInventoryItemCategoryIcons:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryPrompts:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItems:"HomeInventoryItem",
		description:"String",
		id:"uuid",
		serviceDescription:"String",
		serviceFrequency:"Int",
		servicePartName:"String",
		servicePartPartyResponsible:"TaskPartPartyResponsible_enum",
		servicePartsRequired:"Boolean",
		serviceProvider:"HomeInventoryServiceProvider_enum",
		serviceType:"HomeInventoryServiceType_enum"
	},
	HomeInventoryItemCategoryIcon:{
		HomeInventoryItemCategory:"HomeInventoryItemCategory",
		ImageAssetAttachment:"Attachment",
		VectorAssetAttachment:"Attachment",
		createdAt:"timestamptz",
		friendlyAssetId:"String",
		homeInventoryItemCategoryId:"uuid",
		id:"uuid",
		imageAssetAttachmentId:"uuid",
		updatedAt:"timestamptz",
		vectorAssetAttachmentId:"uuid"
	},
	HomeInventoryItemCategoryPrompt:{
		ExampleAttachment:"Attachment",
		HomeInventoryItemAttachments:"HomeInventoryItemAttachment",
		HomeInventoryItemCategory:"HomeInventoryItemCategory",
		body:"String",
		createdAt:"timestamptz",
		defaultCaption:"String",
		exampleAttachmentId:"uuid",
		friendlyAssetId:"String",
		homeInventoryItemCategoryId:"uuid",
		id:"uuid",
		inputHint:"String",
		rank:"Int",
		title:"String",
		updatedAt:"timestamptz"
	},
	HomeInventoryItem_mutation_response:{
		affected_rows:"Int",
		returning:"HomeInventoryItem"
	},
	HomeInventoryMaintenanceSchedule:{
		Home:"Home",
		HomeInventoryItem:"HomeInventoryItem",
		dueBy:"timestamptz",
		homeId:"uuid",
		homeInventoryItemId:"uuid"
	},
	HomeInventoryServiceProvider:{
		description:"String",
		value:"String"
	},
	HomeInventoryServiceType:{
		description:"String",
		value:"String"
	},
	HomeWaitlist:{
		Home:"Home",
		createdAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz"
	},
	HomeWaitlist_mutation_response:{
		affected_rows:"Int",
		returning:"HomeWaitlist"
	},
	Home_mutation_response:{
		affected_rows:"Int",
		returning:"Home"
	},
	MarketingLead:{
		id:"uuid"
	},
	MarketingLead_mutation_response:{
		affected_rows:"Int",
		returning:"MarketingLead"
	},
	Neighborhood:{
		NeighborhoodRegions:"NeighborhoodRegion",
		Person:"Person",
		availableStartingAt:"timestamptz",
		createdAt:"timestamptz",
		displayName:"String",
		handymanPersonId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz",
		zipCode:"String"
	},
	NeighborhoodRegion:{
		Neighborhood:"Neighborhood",
		Region:"Region",
		createdAt:"timestamptz",
		id:"uuid",
		neighborhoodId:"uuid",
		regionId:"uuid",
		type:"NeighborhoodRegionType_enum",
		updatedAt:"timestamptz"
	},
	Person:{
		AppointmentGrades:"AppointmentGrade",
		AppointmentProviders:"AppointmentProvider",
		AppointmentProviders_aggregate:"AppointmentProvider_aggregate",
		AppointmentReports:"AppointmentReport",
		Availability:"Availability",
		CreatedAppointmentReports:"AppointmentReport",
		CreatedAppointments:"Appointment",
		CreatedAppointments_aggregate:"Appointment_aggregate",
		CreatedAttachments:"Attachment",
		CreatedTasks:"Task",
		CreatedTasks_aggregate:"Task_aggregate",
		Devices:"Device",
		Devices_aggregate:"Device_aggregate",
		ExternalIdentifiers:"ExternalIdentifier",
		Neighborhoods:"Neighborhood",
		PersonBiographyItems:"PersonBiographyItem",
		PersonHomes:"PersonHome",
		PrimaryHome:"Home",
		ProfilePicture:"Attachment",
		TaskComments:"TaskComment",
		TaskComments_aggregate:"TaskComment_aggregate",
		TaskUpdateLogs:"TaskUpdateLog",
		TaskUpdateLogs_aggregate:"TaskUpdateLog_aggregate",
		biography:"String",
		contactPreference:"PersonContactPreference_enum",
		currentTimeZone:"String",
		email:"String",
		firstName:"String",
		id:"uuid",
		lastName:"String",
		phoneNumber:"String",
		type:"PersonType_enum"
	},
	PersonBiographyItem:{
		Person:"Person",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		rank:"Int",
		title:"String",
		updatedAt:"timestamptz",
		value:"String"
	},
	PersonBiographyItem_mutation_response:{
		affected_rows:"Int",
		returning:"PersonBiographyItem"
	},
	PersonHome:{
		Home:"Home",
		Person:"Person",
		homeId:"uuid",
		personId:"uuid"
	},
	PersonHome_mutation_response:{
		affected_rows:"Int",
		returning:"PersonHome"
	},
	Person_aggregate:{
		aggregate:"Person_aggregate_fields",
		nodes:"Person"
	},
	Person_aggregate_fields:{
		count:"Int",
		max:"Person_max_fields",
		min:"Person_min_fields"
	},
	Person_max_fields:{
		biography:"String",
		currentTimeZone:"String",
		email:"String",
		firstName:"String",
		id:"uuid",
		lastName:"String",
		phoneNumber:"String"
	},
	Person_min_fields:{
		biography:"String",
		currentTimeZone:"String",
		email:"String",
		firstName:"String",
		id:"uuid",
		lastName:"String",
		phoneNumber:"String"
	},
	Person_mutation_response:{
		affected_rows:"Int",
		returning:"Person"
	},
	Referral:{
		Accepted:"Home",
		Invited:"Home",
		ReferralProgram:"ReferralProgram",
		acceptedById:"uuid",
		createdAt:"timestamptz",
		creditAppliedToInviter:"Int",
		id:"uuid",
		invitedById:"uuid",
		updatedAt:"timestamptz"
	},
	ReferralProgram:{
		creditAmount:"Int",
		id:"uuid",
		isActive:"Boolean"
	},
	Referral_mutation_response:{
		affected_rows:"Int",
		returning:"Referral"
	},
	Region:{
		NeighborhoodRegions:"NeighborhoodRegion",
		SchedulingHomes:"Home",
		createdAt:"timestamptz",
		displayName:"String",
		id:"uuid",
		updatedAt:"timestamptz"
	},
	ServiceOffer:{
		ServiceOfferResponses:"ServiceOfferResponse",
		ServiceOfferType:"ServiceOfferType",
		additionalChargeAmount:"Int",
		beginsAtDate:"date",
		beginsAtTime:"timetz",
		confirmBy:"date",
		costExplaination:"String",
		createdAt:"timestamptz",
		description:"String",
		endsAtDate:"date",
		endsAtTime:"timetz",
		estimatedDuration:"Int",
		id:"uuid",
		requiresAdditionalCharge:"Boolean",
		requiresConfirmation:"Boolean",
		scheduleBy:"date",
		title:"String",
		type:"String",
		updatedAt:"timestamptz"
	},
	ServiceOfferResponse:{
		Home:"Home",
		ServiceOffer:"ServiceOffer",
		ServiceOfferResponseStatus:"ServiceOfferResponseStatus",
		homeId:"uuid",
		id:"uuid",
		serviceOfferId:"uuid",
		status:"ServiceOfferResponseStatus_enum"
	},
	ServiceOfferResponseStatus:{
		ServiceOfferResponses:"ServiceOfferResponse",
		description:"String",
		value:"String"
	},
	ServiceOfferResponse_mutation_response:{
		affected_rows:"Int",
		returning:"ServiceOfferResponse"
	},
	ServiceOfferType:{
		ServiceOffers:"ServiceOffer",
		description:"String",
		value:"String"
	},
	ServiceProvider:{
		createdAt:"timestamptz",
		email:"String",
		id:"uuid",
		name:"String",
		phoneNumber:"String",
		updatedAt:"timestamptz"
	},
	ServiceProviderRequest:{
		Appointment:"Appointment",
		ServiceProvider:"ServiceProvider",
		Task:"Task",
		id:"uuid",
		stage:"ServiceProviderRequestStage_enum",
		startedProcessingRequestAt:"timestamptz",
		taskId:"uuid"
	},
	ServiceProviderRequestStage:{
		description:"String",
		value:"String"
	},
	ServiceProviderRequest_mutation_response:{
		affected_rows:"Int",
		returning:"ServiceProviderRequest"
	},
	StripePaymentMethod:{
		Home:"Home",
		createdAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		paymentMethodId:"String"
	},
	StripeSubscription:{
		Home:"Home",
		canceledAt:"timestamptz",
		createdAt:"timestamptz",
		homeId:"uuid",
		id:"uuid",
		interval:"String",
		status:"StripeSubscriptionStatus_enum",
		subscriptionId:"String",
		updatedAt:"timestamptz"
	},
	Stripe_Coupon:{
		amountOff:"Int",
		duration:"Stripe_Coupon_Duration",
		durationInMonths:"Int",
		id:"String",
		name:"String",
		percentOff:"Int"
	},
	Stripe_FindPromoCodeResult:{
		coupon:"Stripe_Coupon"
	},
	Stripe_Portal:{
		url:"String"
	},
	Stripe_SubscriptionProduct:{
		description:"String",
		id:"String",
		name:"String",
		prices:"Stripe_SubscriptionProductPrice"
	},
	Stripe_SubscriptionProductPrice:{
		amount:"Int",
		id:"String",
		interval:"String",
		nickname:"String"
	},
	Task:{
		Appointment:"Appointment",
		Comments:"TaskComment",
		Comments_aggregate:"TaskComment_aggregate",
		CreatedBy:"Person",
		Home:"Home",
		HomeInventoryItem:"HomeInventoryItem",
		LastUpdatedBy:"Person",
		ServiceProviderRequests:"ServiceProviderRequest",
		TaskAttachments:"TaskAttachment",
		TaskParts:"TaskPart",
		TaskUpdateLogs:"TaskUpdateLog",
		TaskUpdateLogs_aggregate:"TaskUpdateLog_aggregate",
		appointmentId:"uuid",
		completedAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		description:"String",
		dueAt:"timestamptz",
		estimatedCompletionTime:"Int",
		homeId:"uuid",
		homeInventoryItemId:"uuid",
		id:"uuid",
		lastUpdatedById:"uuid",
		notes:"String",
		parentTaskId:"uuid",
		partsRequired:"Boolean",
		priorityLevel:"Int",
		requiresOperationalReview:"Boolean",
		status:"TaskStatus_enum",
		thirdPartyRequired:"Boolean",
		title:"String",
		type:"TaskType_enum",
		updatedAt:"timestamptz"
	},
	TaskAttachment:{
		Attachment:"Attachment",
		Task:"Task",
		attachmentId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		rank:"Int",
		taskId:"uuid",
		updatedAt:"timestamptz"
	},
	TaskAttachment_mutation_response:{
		affected_rows:"Int",
		returning:"TaskAttachment"
	},
	TaskComment:{
		Person:"Person",
		Task:"Task",
		body:"String",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		taskId:"uuid",
		updatedAt:"timestamptz"
	},
	TaskComment_aggregate:{
		aggregate:"TaskComment_aggregate_fields",
		nodes:"TaskComment"
	},
	TaskComment_aggregate_fields:{
		count:"Int",
		max:"TaskComment_max_fields",
		min:"TaskComment_min_fields"
	},
	TaskComment_max_fields:{
		body:"String",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		taskId:"uuid",
		updatedAt:"timestamptz"
	},
	TaskComment_min_fields:{
		body:"String",
		createdAt:"timestamptz",
		id:"uuid",
		personId:"uuid",
		taskId:"uuid",
		updatedAt:"timestamptz"
	},
	TaskComment_mutation_response:{
		affected_rows:"Int",
		returning:"TaskComment"
	},
	TaskPart:{
		ActiveAgent:"Person",
		LastUpdatedBy:"Person",
		OrderedBy:"Person",
		Task:"Task",
		createdAt:"timestamptz",
		estimatedDeliveryBy:"timestamptz",
		id:"uuid",
		memberStartedRequestAt:"timestamptz",
		orderedAt:"timestamptz",
		partAcquiredAt:"timestamptz",
		partDeliveredAt:"timestamptz",
		partDeliveryLocation:"TaskPartDeliveryLocation_enum",
		partName:"String",
		partOnHand:"Boolean",
		partyResponsible:"TaskPartPartyResponsible_enum",
		personOrderedId:"uuid",
		requiresReview:"Boolean",
		startedProcessingRequestAt:"timestamptz",
		status:"TaskPartStatus_enum",
		updatedAt:"timestamptz"
	},
	TaskPartPartyResponsible:{
		description:"String",
		value:"String"
	},
	TaskPartStatus:{
		description:"String",
		value:"String"
	},
	TaskPart_mutation_response:{
		affected_rows:"Int",
		returning:"TaskPart"
	},
	TaskUpdateLog:{
		AppointmentReport:"AppointmentReport",
		Author:"Person",
		CreatedBy:"Person",
		ServiceProviderRequest:"ServiceProviderRequest",
		Task:"Task",
		TaskPart:"TaskPart",
		TaskUpdateLogAttachments:"TaskUpdateLogAttachment",
		appointmentReportId:"uuid",
		authorId:"uuid",
		createdAt:"timestamptz",
		createdById:"uuid",
		id:"uuid",
		notes:"String",
		partsDescription:"String",
		partsRequired:"Boolean",
		ranOutOfTime:"Boolean",
		serviceProviderRequestId:"uuid",
		status:"TaskStatus_enum",
		taskId:"uuid",
		taskPartId:"uuid",
		thirdPartyDescription:"String",
		thirdPartyRequired:"Boolean",
		updatedAt:"timestamptz"
	},
	TaskUpdateLogAttachment:{
		Attachment:"Attachment",
		TaskUpdateLog:"TaskUpdateLog",
		attachmentId:"uuid",
		createdAt:"timestamptz",
		id:"uuid",
		rank:"Int",
		taskUpdateLogId:"uuid",
		updatedAt:"timestamptz"
	},
	TaskUpdateLogAttachment_mutation_response:{
		affected_rows:"Int",
		returning:"TaskUpdateLogAttachment"
	},
	TaskUpdateLog_aggregate:{
		aggregate:"TaskUpdateLog_aggregate_fields",
		nodes:"TaskUpdateLog"
	},
	TaskUpdateLog_aggregate_fields:{
		count:"Int",
		max:"TaskUpdateLog_max_fields",
		min:"TaskUpdateLog_min_fields"
	},
	TaskUpdateLog_max_fields:{
		appointmentReportId:"uuid",
		authorId:"uuid",
		createdAt:"timestamptz",
		createdById:"uuid",
		id:"uuid",
		notes:"String",
		partsDescription:"String",
		serviceProviderRequestId:"uuid",
		taskId:"uuid",
		taskPartId:"uuid",
		thirdPartyDescription:"String",
		updatedAt:"timestamptz"
	},
	TaskUpdateLog_min_fields:{
		appointmentReportId:"uuid",
		authorId:"uuid",
		createdAt:"timestamptz",
		createdById:"uuid",
		id:"uuid",
		notes:"String",
		partsDescription:"String",
		serviceProviderRequestId:"uuid",
		taskId:"uuid",
		taskPartId:"uuid",
		thirdPartyDescription:"String",
		updatedAt:"timestamptz"
	},
	TaskUpdateLog_mutation_response:{
		affected_rows:"Int",
		returning:"TaskUpdateLog"
	},
	Task_aggregate:{
		aggregate:"Task_aggregate_fields",
		nodes:"Task"
	},
	Task_aggregate_fields:{
		avg:"Task_avg_fields",
		count:"Int",
		max:"Task_max_fields",
		min:"Task_min_fields",
		stddev:"Task_stddev_fields",
		stddev_pop:"Task_stddev_pop_fields",
		stddev_samp:"Task_stddev_samp_fields",
		sum:"Task_sum_fields",
		var_pop:"Task_var_pop_fields",
		var_samp:"Task_var_samp_fields",
		variance:"Task_variance_fields"
	},
	Task_avg_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_max_fields:{
		appointmentId:"uuid",
		completedAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		description:"String",
		dueAt:"timestamptz",
		estimatedCompletionTime:"Int",
		homeId:"uuid",
		homeInventoryItemId:"uuid",
		id:"uuid",
		lastUpdatedById:"uuid",
		notes:"String",
		parentTaskId:"uuid",
		priorityLevel:"Int",
		title:"String",
		updatedAt:"timestamptz"
	},
	Task_min_fields:{
		appointmentId:"uuid",
		completedAt:"timestamptz",
		createdAt:"timestamptz",
		createdById:"uuid",
		description:"String",
		dueAt:"timestamptz",
		estimatedCompletionTime:"Int",
		homeId:"uuid",
		homeInventoryItemId:"uuid",
		id:"uuid",
		lastUpdatedById:"uuid",
		notes:"String",
		parentTaskId:"uuid",
		priorityLevel:"Int",
		title:"String",
		updatedAt:"timestamptz"
	},
	Task_mutation_response:{
		affected_rows:"Int",
		returning:"Task"
	},
	Task_stddev_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_stddev_pop_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_stddev_samp_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_sum_fields:{
		estimatedCompletionTime:"Int",
		priorityLevel:"Int"
	},
	Task_var_pop_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_var_samp_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	Task_variance_fields:{
		estimatedCompletionTime:"Float",
		priorityLevel:"Float"
	},
	TimeZone:{
		abbrev:"String",
		is_dst:"Boolean",
		name:"String",
		utc_offset:"interval"
	},
	create_Stripe_SetupIntent_output:{
		ephemeralKey:"String",
		publishableKey:"String",
		setupIntentClientSecret:"String"
	},
	create_Stripe_SubscriptionIntent_output:{
		ephemeralKey:"String",
		paymentIntentClientSecret:"String",
		publishableKey:"String"
	},
	find_Person_response:{
		present:"Boolean"
	},
	mutation_root:{
		create_Stripe_Portal:"Stripe_Portal",
		create_Stripe_SetupIntent:"create_Stripe_SetupIntent_output",
		create_Stripe_SubscriptionIntent:"create_Stripe_SubscriptionIntent_output",
		delete_Appointment:"Appointment_mutation_response",
		delete_AppointmentProvider:"AppointmentProvider_mutation_response",
		delete_AppointmentProvider_by_pk:"AppointmentProvider",
		delete_Appointment_by_pk:"Appointment",
		delete_Attachment:"Attachment_mutation_response",
		delete_Attachment_by_pk:"Attachment",
		delete_HomeInventoryItemAttachment:"HomeInventoryItemAttachment_mutation_response",
		delete_HomeInventoryItemAttachment_by_pk:"HomeInventoryItemAttachment",
		delete_Task:"Task_mutation_response",
		delete_TaskAttachment:"TaskAttachment_mutation_response",
		delete_TaskAttachment_by_pk:"TaskAttachment",
		delete_Task_by_pk:"Task",
		encryptDataHomeowner:"EncryptedMessage",
		insert_Appointment:"Appointment_mutation_response",
		insert_AppointmentGrade:"AppointmentGrade_mutation_response",
		insert_AppointmentGradeSelection:"AppointmentGradeSelection_mutation_response",
		insert_AppointmentGradeSelection_one:"AppointmentGradeSelection",
		insert_AppointmentGrade_one:"AppointmentGrade",
		insert_AppointmentProvider:"AppointmentProvider_mutation_response",
		insert_AppointmentProvider_one:"AppointmentProvider",
		insert_Appointment_one:"Appointment",
		insert_Attachment:"Attachment_mutation_response",
		insert_Attachment_one:"Attachment",
		insert_AvailabilityResult:"AvailabilityResult_mutation_response",
		insert_AvailabilityResult_one:"AvailabilityResult",
		insert_DataDeletionRequest:"DataDeletionRequest_mutation_response",
		insert_DataDeletionRequest_one:"DataDeletionRequest",
		insert_Device:"Device_mutation_response",
		insert_Device_one:"Device",
		insert_HomeInventoryItem:"HomeInventoryItem_mutation_response",
		insert_HomeInventoryItemAttachment:"HomeInventoryItemAttachment_mutation_response",
		insert_HomeInventoryItemAttachment_one:"HomeInventoryItemAttachment",
		insert_HomeInventoryItem_one:"HomeInventoryItem",
		insert_HomeWaitlist:"HomeWaitlist_mutation_response",
		insert_HomeWaitlist_one:"HomeWaitlist",
		insert_Person:"Person_mutation_response",
		insert_PersonBiographyItem:"PersonBiographyItem_mutation_response",
		insert_PersonBiographyItem_one:"PersonBiographyItem",
		insert_PersonHome:"PersonHome_mutation_response",
		insert_PersonHome_one:"PersonHome",
		insert_Person_one:"Person",
		insert_Referral:"Referral_mutation_response",
		insert_Referral_one:"Referral",
		insert_ServiceOfferResponse:"ServiceOfferResponse_mutation_response",
		insert_ServiceOfferResponse_one:"ServiceOfferResponse",
		insert_ServiceProviderRequest:"ServiceProviderRequest_mutation_response",
		insert_ServiceProviderRequest_one:"ServiceProviderRequest",
		insert_Task:"Task_mutation_response",
		insert_TaskAttachment:"TaskAttachment_mutation_response",
		insert_TaskAttachment_one:"TaskAttachment",
		insert_TaskComment:"TaskComment_mutation_response",
		insert_TaskComment_one:"TaskComment",
		insert_TaskPart:"TaskPart_mutation_response",
		insert_TaskPart_one:"TaskPart",
		insert_TaskUpdateLog:"TaskUpdateLog_mutation_response",
		insert_TaskUpdateLogAttachment:"TaskUpdateLogAttachment_mutation_response",
		insert_TaskUpdateLogAttachment_one:"TaskUpdateLogAttachment",
		insert_TaskUpdateLog_one:"TaskUpdateLog",
		insert_Task_one:"Task",
		update_Appointment:"Appointment_mutation_response",
		update_Appointment_by_pk:"Appointment",
		update_Appointment_many:"Appointment_mutation_response",
		update_Attachment:"Attachment_mutation_response",
		update_Attachment_by_pk:"Attachment",
		update_Attachment_many:"Attachment_mutation_response",
		update_AvailabilityResult:"AvailabilityResult_mutation_response",
		update_AvailabilityResult_many:"AvailabilityResult_mutation_response",
		update_Device:"Device_mutation_response",
		update_Device_by_pk:"Device",
		update_Device_many:"Device_mutation_response",
		update_Home:"Home_mutation_response",
		update_HomeInventoryItem:"HomeInventoryItem_mutation_response",
		update_HomeInventoryItemAttachment:"HomeInventoryItemAttachment_mutation_response",
		update_HomeInventoryItemAttachment_by_pk:"HomeInventoryItemAttachment",
		update_HomeInventoryItemAttachment_many:"HomeInventoryItemAttachment_mutation_response",
		update_HomeInventoryItem_by_pk:"HomeInventoryItem",
		update_HomeInventoryItem_many:"HomeInventoryItem_mutation_response",
		update_Home_by_pk:"Home",
		update_Home_many:"Home_mutation_response",
		update_MarketingLead:"MarketingLead_mutation_response",
		update_MarketingLead_by_pk:"MarketingLead",
		update_MarketingLead_many:"MarketingLead_mutation_response",
		update_Person:"Person_mutation_response",
		update_PersonBiographyItem:"PersonBiographyItem_mutation_response",
		update_PersonBiographyItem_by_pk:"PersonBiographyItem",
		update_PersonBiographyItem_many:"PersonBiographyItem_mutation_response",
		update_Person_by_pk:"Person",
		update_Person_many:"Person_mutation_response",
		update_ServiceOfferResponse:"ServiceOfferResponse_mutation_response",
		update_ServiceOfferResponse_by_pk:"ServiceOfferResponse",
		update_ServiceOfferResponse_many:"ServiceOfferResponse_mutation_response",
		update_Task:"Task_mutation_response",
		update_TaskAttachment:"TaskAttachment_mutation_response",
		update_TaskAttachment_by_pk:"TaskAttachment",
		update_TaskAttachment_many:"TaskAttachment_mutation_response",
		update_TaskComment:"TaskComment_mutation_response",
		update_TaskComment_many:"TaskComment_mutation_response",
		update_TaskPart:"TaskPart_mutation_response",
		update_TaskPart_by_pk:"TaskPart",
		update_TaskPart_many:"TaskPart_mutation_response",
		update_TaskUpdateLog:"TaskUpdateLog_mutation_response",
		update_TaskUpdateLogAttachment:"TaskUpdateLogAttachment_mutation_response",
		update_TaskUpdateLogAttachment_by_pk:"TaskUpdateLogAttachment",
		update_TaskUpdateLogAttachment_many:"TaskUpdateLogAttachment_mutation_response",
		update_TaskUpdateLog_by_pk:"TaskUpdateLog",
		update_TaskUpdateLog_many:"TaskUpdateLog_mutation_response",
		update_Task_by_pk:"Task",
		update_Task_many:"Task_mutation_response"
	},
	query_root:{
		Appointment:"Appointment",
		AppointmentGrade:"AppointmentGrade",
		AppointmentGradeSelection:"AppointmentGradeSelection",
		AppointmentGradeSelectionCategory:"AppointmentGradeSelectionCategory",
		AppointmentGradeSelectionCategory_by_pk:"AppointmentGradeSelectionCategory",
		AppointmentGradeSelection_by_pk:"AppointmentGradeSelection",
		AppointmentGrade_by_pk:"AppointmentGrade",
		AppointmentProvider:"AppointmentProvider",
		AppointmentProvider_aggregate:"AppointmentProvider_aggregate",
		AppointmentProvider_by_pk:"AppointmentProvider",
		AppointmentReport:"AppointmentReport",
		AppointmentReport_by_pk:"AppointmentReport",
		Appointment_aggregate:"Appointment_aggregate",
		Appointment_by_pk:"Appointment",
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		Availability:"Availability",
		AvailabilityResult:"AvailabilityResult",
		AvailableAppointment:"AvailableAppointment",
		ClientRelease:"ClientRelease",
		ClientRelease_by_pk:"ClientRelease",
		ClientType:"ClientType",
		ClientType_by_pk:"ClientType",
		ContentBlock:"ContentBlock",
		ContentBlock_by_pk:"ContentBlock",
		DataDeletionRequest:"DataDeletionRequest",
		DataDeletionRequest_by_pk:"DataDeletionRequest",
		Device:"Device",
		Device_aggregate:"Device_aggregate",
		Device_by_pk:"Device",
		ExternalIdentifier:"ExternalIdentifier",
		ExternalIdentifier_by_pk:"ExternalIdentifier",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		Home:"Home",
		HomeInventoryItem:"HomeInventoryItem",
		HomeInventoryItemAttachment:"HomeInventoryItemAttachment",
		HomeInventoryItemAttachment_by_pk:"HomeInventoryItemAttachment",
		HomeInventoryItemCategory:"HomeInventoryItemCategory",
		HomeInventoryItemCategoryIcon:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryIcon_by_pk:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryPrompt:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItemCategoryPrompt_by_pk:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItemCategory_by_pk:"HomeInventoryItemCategory",
		HomeInventoryItem_by_pk:"HomeInventoryItem",
		HomeInventoryMaintenanceSchedule:"HomeInventoryMaintenanceSchedule",
		HomeInventoryServiceProvider:"HomeInventoryServiceProvider",
		HomeInventoryServiceProvider_by_pk:"HomeInventoryServiceProvider",
		HomeInventoryServiceType:"HomeInventoryServiceType",
		HomeInventoryServiceType_by_pk:"HomeInventoryServiceType",
		HomeWaitlist:"HomeWaitlist",
		HomeWaitlist_by_pk:"HomeWaitlist",
		Home_by_pk:"Home",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		Neighborhood:"Neighborhood",
		NeighborhoodRegion:"NeighborhoodRegion",
		NeighborhoodRegion_by_pk:"NeighborhoodRegion",
		Neighborhood_by_pk:"Neighborhood",
		Person:"Person",
		PersonBiographyItem:"PersonBiographyItem",
		PersonBiographyItem_by_pk:"PersonBiographyItem",
		PersonHome:"PersonHome",
		Person_aggregate:"Person_aggregate",
		Person_by_pk:"Person",
		Referral:"Referral",
		ReferralProgram:"ReferralProgram",
		ReferralProgram_by_pk:"ReferralProgram",
		Referral_by_pk:"Referral",
		Region:"Region",
		Region_by_pk:"Region",
		ServiceOffer:"ServiceOffer",
		ServiceOfferResponse:"ServiceOfferResponse",
		ServiceOfferResponseStatus:"ServiceOfferResponseStatus",
		ServiceOfferResponseStatus_by_pk:"ServiceOfferResponseStatus",
		ServiceOfferResponse_by_pk:"ServiceOfferResponse",
		ServiceOfferType:"ServiceOfferType",
		ServiceOfferType_by_pk:"ServiceOfferType",
		ServiceOffer_by_pk:"ServiceOffer",
		ServiceProvider:"ServiceProvider",
		ServiceProviderRequest:"ServiceProviderRequest",
		ServiceProviderRequestStage:"ServiceProviderRequestStage",
		ServiceProviderRequestStage_by_pk:"ServiceProviderRequestStage",
		ServiceProviderRequest_by_pk:"ServiceProviderRequest",
		ServiceProvider_by_pk:"ServiceProvider",
		StripePaymentMethod:"StripePaymentMethod",
		StripePaymentMethod_by_pk:"StripePaymentMethod",
		StripeSubscription:"StripeSubscription",
		StripeSubscription_by_pk:"StripeSubscription",
		Task:"Task",
		TaskAttachment:"TaskAttachment",
		TaskAttachment_by_pk:"TaskAttachment",
		TaskComment:"TaskComment",
		TaskComment_aggregate:"TaskComment_aggregate",
		TaskPart:"TaskPart",
		TaskPartPartyResponsible:"TaskPartPartyResponsible",
		TaskPartPartyResponsible_by_pk:"TaskPartPartyResponsible",
		TaskPartStatus:"TaskPartStatus",
		TaskPartStatus_by_pk:"TaskPartStatus",
		TaskPart_by_pk:"TaskPart",
		TaskUpdateLog:"TaskUpdateLog",
		TaskUpdateLogAttachment:"TaskUpdateLogAttachment",
		TaskUpdateLogAttachment_by_pk:"TaskUpdateLogAttachment",
		TaskUpdateLog_aggregate:"TaskUpdateLog_aggregate",
		TaskUpdateLog_by_pk:"TaskUpdateLog",
		Task_aggregate:"Task_aggregate",
		Task_by_pk:"Task",
		TimeZone:"TimeZone",
		check_Stripe_PromoCode:"Stripe_FindPromoCodeResult",
		decryptMessageHomeowner:"DecryptedMessage",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response",
		find_Stripe_SubscriptionProducts:"Stripe_SubscriptionProduct"
	},
	subscription_root:{
		Appointment:"Appointment",
		AppointmentGrade:"AppointmentGrade",
		AppointmentGradeSelection:"AppointmentGradeSelection",
		AppointmentGradeSelectionCategory:"AppointmentGradeSelectionCategory",
		AppointmentGradeSelectionCategory_by_pk:"AppointmentGradeSelectionCategory",
		AppointmentGradeSelectionCategory_stream:"AppointmentGradeSelectionCategory",
		AppointmentGradeSelection_by_pk:"AppointmentGradeSelection",
		AppointmentGradeSelection_stream:"AppointmentGradeSelection",
		AppointmentGrade_by_pk:"AppointmentGrade",
		AppointmentGrade_stream:"AppointmentGrade",
		AppointmentProvider:"AppointmentProvider",
		AppointmentProvider_aggregate:"AppointmentProvider_aggregate",
		AppointmentProvider_by_pk:"AppointmentProvider",
		AppointmentProvider_stream:"AppointmentProvider",
		AppointmentReport:"AppointmentReport",
		AppointmentReport_by_pk:"AppointmentReport",
		AppointmentReport_stream:"AppointmentReport",
		Appointment_aggregate:"Appointment_aggregate",
		Appointment_by_pk:"Appointment",
		Appointment_stream:"Appointment",
		Attachment:"Attachment",
		Attachment_by_pk:"Attachment",
		Attachment_stream:"Attachment",
		Availability:"Availability",
		AvailabilityResult:"AvailabilityResult",
		AvailabilityResult_stream:"AvailabilityResult",
		Availability_stream:"Availability",
		AvailableAppointment:"AvailableAppointment",
		AvailableAppointment_stream:"AvailableAppointment",
		ClientRelease:"ClientRelease",
		ClientRelease_by_pk:"ClientRelease",
		ClientRelease_stream:"ClientRelease",
		ClientType:"ClientType",
		ClientType_by_pk:"ClientType",
		ClientType_stream:"ClientType",
		ContentBlock:"ContentBlock",
		ContentBlock_by_pk:"ContentBlock",
		ContentBlock_stream:"ContentBlock",
		DataDeletionRequest:"DataDeletionRequest",
		DataDeletionRequest_by_pk:"DataDeletionRequest",
		DataDeletionRequest_stream:"DataDeletionRequest",
		Device:"Device",
		Device_aggregate:"Device_aggregate",
		Device_by_pk:"Device",
		Device_stream:"Device",
		ExternalIdentifier:"ExternalIdentifier",
		ExternalIdentifier_by_pk:"ExternalIdentifier",
		ExternalIdentifier_stream:"ExternalIdentifier",
		GeographicRegion:"GeographicRegion",
		GeographicRegionHandyman:"GeographicRegionHandyman",
		GeographicRegionHandyman_by_pk:"GeographicRegionHandyman",
		GeographicRegionHandyman_stream:"GeographicRegionHandyman",
		GeographicRegionNeighborhood:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_by_pk:"GeographicRegionNeighborhood",
		GeographicRegionNeighborhood_stream:"GeographicRegionNeighborhood",
		GeographicRegion_by_pk:"GeographicRegion",
		GeographicRegion_by_zipCode:"GeographicRegion",
		GeographicRegion_stream:"GeographicRegion",
		Home:"Home",
		HomeInventoryItem:"HomeInventoryItem",
		HomeInventoryItemAttachment:"HomeInventoryItemAttachment",
		HomeInventoryItemAttachment_by_pk:"HomeInventoryItemAttachment",
		HomeInventoryItemAttachment_stream:"HomeInventoryItemAttachment",
		HomeInventoryItemCategory:"HomeInventoryItemCategory",
		HomeInventoryItemCategoryIcon:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryIcon_by_pk:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryIcon_stream:"HomeInventoryItemCategoryIcon",
		HomeInventoryItemCategoryPrompt:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItemCategoryPrompt_by_pk:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItemCategoryPrompt_stream:"HomeInventoryItemCategoryPrompt",
		HomeInventoryItemCategory_by_pk:"HomeInventoryItemCategory",
		HomeInventoryItemCategory_stream:"HomeInventoryItemCategory",
		HomeInventoryItem_by_pk:"HomeInventoryItem",
		HomeInventoryItem_stream:"HomeInventoryItem",
		HomeInventoryMaintenanceSchedule:"HomeInventoryMaintenanceSchedule",
		HomeInventoryMaintenanceSchedule_stream:"HomeInventoryMaintenanceSchedule",
		HomeInventoryServiceProvider:"HomeInventoryServiceProvider",
		HomeInventoryServiceProvider_by_pk:"HomeInventoryServiceProvider",
		HomeInventoryServiceProvider_stream:"HomeInventoryServiceProvider",
		HomeInventoryServiceType:"HomeInventoryServiceType",
		HomeInventoryServiceType_by_pk:"HomeInventoryServiceType",
		HomeInventoryServiceType_stream:"HomeInventoryServiceType",
		HomeWaitlist:"HomeWaitlist",
		HomeWaitlist_by_pk:"HomeWaitlist",
		HomeWaitlist_stream:"HomeWaitlist",
		Home_by_pk:"Home",
		Home_stream:"Home",
		MarketingLead:"MarketingLead",
		MarketingLead_by_pk:"MarketingLead",
		MarketingLead_stream:"MarketingLead",
		Neighborhood:"Neighborhood",
		NeighborhoodRegion:"NeighborhoodRegion",
		NeighborhoodRegion_by_pk:"NeighborhoodRegion",
		NeighborhoodRegion_stream:"NeighborhoodRegion",
		Neighborhood_by_pk:"Neighborhood",
		Neighborhood_stream:"Neighborhood",
		Person:"Person",
		PersonBiographyItem:"PersonBiographyItem",
		PersonBiographyItem_by_pk:"PersonBiographyItem",
		PersonBiographyItem_stream:"PersonBiographyItem",
		PersonHome:"PersonHome",
		PersonHome_stream:"PersonHome",
		Person_aggregate:"Person_aggregate",
		Person_by_pk:"Person",
		Person_stream:"Person",
		Referral:"Referral",
		ReferralProgram:"ReferralProgram",
		ReferralProgram_by_pk:"ReferralProgram",
		ReferralProgram_stream:"ReferralProgram",
		Referral_by_pk:"Referral",
		Referral_stream:"Referral",
		Region:"Region",
		Region_by_pk:"Region",
		Region_stream:"Region",
		ServiceOffer:"ServiceOffer",
		ServiceOfferResponse:"ServiceOfferResponse",
		ServiceOfferResponseStatus:"ServiceOfferResponseStatus",
		ServiceOfferResponseStatus_by_pk:"ServiceOfferResponseStatus",
		ServiceOfferResponseStatus_stream:"ServiceOfferResponseStatus",
		ServiceOfferResponse_by_pk:"ServiceOfferResponse",
		ServiceOfferResponse_stream:"ServiceOfferResponse",
		ServiceOfferType:"ServiceOfferType",
		ServiceOfferType_by_pk:"ServiceOfferType",
		ServiceOfferType_stream:"ServiceOfferType",
		ServiceOffer_by_pk:"ServiceOffer",
		ServiceOffer_stream:"ServiceOffer",
		ServiceProvider:"ServiceProvider",
		ServiceProviderRequest:"ServiceProviderRequest",
		ServiceProviderRequestStage:"ServiceProviderRequestStage",
		ServiceProviderRequestStage_by_pk:"ServiceProviderRequestStage",
		ServiceProviderRequestStage_stream:"ServiceProviderRequestStage",
		ServiceProviderRequest_by_pk:"ServiceProviderRequest",
		ServiceProviderRequest_stream:"ServiceProviderRequest",
		ServiceProvider_by_pk:"ServiceProvider",
		ServiceProvider_stream:"ServiceProvider",
		StripePaymentMethod:"StripePaymentMethod",
		StripePaymentMethod_by_pk:"StripePaymentMethod",
		StripePaymentMethod_stream:"StripePaymentMethod",
		StripeSubscription:"StripeSubscription",
		StripeSubscription_by_pk:"StripeSubscription",
		StripeSubscription_stream:"StripeSubscription",
		Task:"Task",
		TaskAttachment:"TaskAttachment",
		TaskAttachment_by_pk:"TaskAttachment",
		TaskAttachment_stream:"TaskAttachment",
		TaskComment:"TaskComment",
		TaskComment_aggregate:"TaskComment_aggregate",
		TaskComment_stream:"TaskComment",
		TaskPart:"TaskPart",
		TaskPartPartyResponsible:"TaskPartPartyResponsible",
		TaskPartPartyResponsible_by_pk:"TaskPartPartyResponsible",
		TaskPartPartyResponsible_stream:"TaskPartPartyResponsible",
		TaskPartStatus:"TaskPartStatus",
		TaskPartStatus_by_pk:"TaskPartStatus",
		TaskPartStatus_stream:"TaskPartStatus",
		TaskPart_by_pk:"TaskPart",
		TaskPart_stream:"TaskPart",
		TaskUpdateLog:"TaskUpdateLog",
		TaskUpdateLogAttachment:"TaskUpdateLogAttachment",
		TaskUpdateLogAttachment_by_pk:"TaskUpdateLogAttachment",
		TaskUpdateLogAttachment_stream:"TaskUpdateLogAttachment",
		TaskUpdateLog_aggregate:"TaskUpdateLog_aggregate",
		TaskUpdateLog_by_pk:"TaskUpdateLog",
		TaskUpdateLog_stream:"TaskUpdateLog",
		Task_aggregate:"Task_aggregate",
		Task_by_pk:"Task",
		Task_stream:"Task",
		TimeZone:"TimeZone",
		TimeZone_stream:"TimeZone",
		find_Person_by_phone:"find_Person_response",
		find_Person_response:"find_Person_response",
		find_Person_response_stream:"find_Person_response"
	}
}