import { Chain as guestChain, $ as templateTag } from "./guest";
import { Chain as homeownerChain } from "./homeowner";
import { Chain as publicChain } from "./public";
import { GraphQLError as GQLError } from "./public";

const API_URL = "https://apini.honeyhomes.com/v1/graphql";

type QueryTypes = "query" | "mutation" | "subscription";

export function guestClient(queryType: QueryTypes, jwt: string) {
  return guestChain(API_URL, {
    headers: {
      "apollographql-client-name": "onboarding-web",
      Authorization: `Bearer ${jwt}`,
    },
  })(queryType);
}

export function homeownerClient(queryType: QueryTypes, jwt: string) {
  return homeownerChain(API_URL, {
    headers: {
      "apollographql-client-name": "onboarding-web",
      Authorization: `Bearer ${jwt}`,
    },
  })(queryType);
}

export function publicClient(queryType: "query" | "mutation") {
  return publicChain(API_URL)(queryType);
}

export const $ = templateTag;
export const GraphQLError = GQLError;
